import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Iframe from 'react-iframe';
class VideoGuide extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <Fragment>
                <div className="how">
                    <div className="section">
                        <div className="section-inner">
                            <h3 className="heading">Découvrez toutes nos fonctionnaités</h3>
                            <div className="card-deck">
                                <div className="card">
                                    <div className="card-header embed-responsive embed-responsive-16by9">
                                        <Iframe url="https://www.youtube.com/embed/ssII_QruREI" 
                                            className="embed-responsive-item"
                                            allow="fullscreen"
                                        />
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title">Reçois une notification SMS à chaque réservation</h6>
                                        <p className="card-text"><small className="text-muted"><span className="fa fa-clock-o"></span> Durée : 0 minute 14 </small></p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}
export default withRouter(VideoGuide);