import React, { Component, Fragment } from 'react';
import expediteurs from './../../assets/img/other/expéditeurs.png';
import etoiles from './../../assets/img/other/etoiles.png';
import like from './../../assets/img/other/like.png';
import profil from './../../assets/img/other/profil.png';
import bouclier from './../../assets/img/other/bouclier.png';
import mobile from './../../assets/img/other/mobile.png';
import user16 from './../../assets/img/user/user00.png';

import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';
import { Helmet } from 'react-helmet';

class Trust extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Une communauté de confiance | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="trust">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/work" className="list-group-item list-group-item-action">Comment ça
                                            marche?</a>
                                        <a href="/trust" className="list-group-item list-group-item-action active">Gages
                                            de Confiance</a>
                                        <a href="/level" className="list-group-item list-group-item-action">Niveau
                                            d'expérience</a>
                                        <a href="/opinions" className="list-group-item list-group-item-action">Les
                                            avis</a>
                                    </div>

                                </div>
                                <div className="col-md-9">
                                    <div className="how">
                                        <div className="section">
                                            <div className="section-inner confiance">
                                                <h1 className="f-heading">Une communauté de Confiance</h1>
                                                <p> La confiance est une valeur pilier de notre communauté. Nous
                                                    travaillons à mettre en place les éléments qui la garantissent</p>
                                                <div className="row mar-top20">
                                                    <div className="col-md-4">
                                                        <img src={expediteurs} alt="MDN Logo" className="cover" />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <h2 className="f-heading-two color-blue mar-top0">Aidez ceux que
                                                            vous voudrez</h2>
                                                        <p className="color-text2">Sur le profil de nos membres,
                                                        découvrez combien d'amis ils ont sur Facebook. Choisissez
                                                            des membres chevronnés grâce aux niveaux d'expérience.</p>
                                                    </div>
                                                </div>
                                                <div className="row mar-top20">
                                                    <div className="col-md-8">
                                                        <h2 className="f-heading-two color-blue mar-top0">Consultez les
                                                            avis des autres membres</h2>
                                                        <p className="color-text2">Les autres membres vous conseillent
                                                        avec qui interagir à travers les avis. Choisissez les
                                                        expédieteurs/transporteurs qui ont les meilleures
                                                            réputations.</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <img src={etoiles} alt="MDN Logo" className="cover" />
                                                    </div>

                                                </div>
                                                <div className="row mar-top20">
                                                    <div className="col-md-4">
                                                        <img src={like} alt="Like/Unlike" className="cover" />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <h2 className="f-heading-two color-blue mar-top0">Découvrez les
                                                            autres membres</h2>
                                                        <p className="color-text2">Faites vous une idée sur les
                                                        personnes avec qui vous allez interagir grâce à leurs
                                                            préférences et description présentés sur leurs proofils.</p>
                                                    </div>
                                                </div>
                                                <div className="row mar-top20">
                                                    <div className="col-md-8">
                                                        <h2 className="f-heading-two color-blue mar-top0">Nous modérons
                                                            les profils</h2>
                                                        <p className="color-text2">Les profils, photos, avis et annonces
                                                        font l'objet d'une modération pour garantir confiance et
                                                            respect.</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <img src={profil} alt="Profil" className="cover" />
                                                    </div>

                                                </div>
                                                <div className="row mar-top20">
                                                    <div className="col-md-4">
                                                        <img src={bouclier} alt="bouclier" className="cover" />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <h2 className="f-heading-two color-blue mar-top0">Assurance</h2>
                                                        <p className="color-text2">En complément de l'assurance du
                                                        propriétaire du kolis, MonKolis comprend un service
                                                        d'assurance spécifique en partenariat avec une compagnie
                                                            d'assurance <a href="#" className="color-blue link">En
                                                                savoir plus...</a></p>
                                                    </div>
                                                </div>
                                                <div className="row mar-top20">
                                                    <div className="col-md-8">
                                                        <h4 className="color-blue mar-top0">Prenez contact avant
                                                            l'expédition'</h4>
                                                        <p className="color-text2">Utilisez notre messagerie sécurisée
                                                        avant l'expédition pour vous mettre d'accord sur le lieu de
                                                            rendez-vous ou régler d'autres détails.</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <img src={mobile} alt="Mobile" className="cover" />
                                                    </div>

                                                </div>
                                                <div className="row mar-top20">
                                                    <div className="col-md-3">
                                                        <img src={user16} alt="" className="cover" />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <h4 className="color-blue mar-top0">Vous pouvez compter sur
                                                            nous</h4>
                                                        <p className="color-text2">Notre équipe relations membres est
                                                        disponible tous les jours pour vous aider à utiliser au
                                                        mieux MonKolis ! Même les samedis, dimanches et jours
                                                        fériés.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}

export default Trust;