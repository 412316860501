import './../../../assets/css/main.css';
import './../../../assets/css/multiple.css';
import './../../../assets/css/dashboard.css';
import './../../../assets/css/modal.css';
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { UPDATE_TRAVEL_QUERY } from './../../../query/travelquery'
import { TRAVEL_URL } from '../../../Url';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import moment from 'moment';
import 'moment/min/locales'
import { Helmet } from "react-helmet";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
	geocodeByAddress,
	geocodeByPlaceId,
	getLatLng,
} from 'react-places-autocomplete';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function buildFileSelector() {
	const fileSelector = document.createElement('input');
	fileSelector.setAttribute('type', 'file');
	fileSelector.setAttribute('multiple', 'multiple');
	return fileSelector;
}

class UpdateTrip extends Component {
	constructor(props) {
		super(props);
		this.state = {
			requestIsOk: true,
			isLoaded: false,

			transport_way: "Avion",
			departure_date: "",
			arrival_date: "",
			departure_city: "",
			arrival_city: "",
			has_check_ticket: "",
			unity: "Kg",
			volume: "",
			max_weight_available: "",
			min_weight_booking: "",
			proposal_amount_by_kg: "",
			booking_deadline: "",
			hour_deadline: "",
			service_charge_amount: "",
			is_automatic_approval: false,
			transport_company: "",
			others_infos: "",
			take_off_place: "",
			recovery_place: "",
			status: "",
			devise: "EURO",
			prix_choisi: "",
			transportation_condition: "Voyage Personnel",

			//showVolume: false,

			file: new FileReader(),
			doc: '',

			lat_arrival:'',
			long_arrival:'',
			lat_departure:'',
			long_departure:'',
		};
		this.travel = this.props.props
		this.handleSubmit = this.handleSubmit.bind(this)

		this.handle_departure_date_change = this.handle_departure_date_change.bind(this)
		this.handle_departure_city_change = this.handle_departure_city_change.bind(this)
		this.handle_arrival_city_change = this.handle_arrival_city_change.bind(this)
		this.handle_has_check_ticket_change = this.handle_has_check_ticket_change.bind(this)
		this.handle_max_weight_available_change = this.handle_max_weight_available_change.bind(this)
		this.handle_min_weight_booking_change = this.handle_min_weight_booking_change.bind(this)
		this.handle_booking_deadline_change = this.handle_booking_deadline_change.bind(this)
		this.handle_service_charge_amount_change = this.handle_service_charge_amount_change.bind(this)
		this.handle_is_automatic_approval_change = this.handle_is_automatic_approval_change.bind(this)
		this.handle_transport_company_change = this.handle_transport_company_change.bind(this)
		this.handle_others_infos_change = this.handle_others_infos_change.bind(this)
		this.handle_take_off_place_change = this.handle_take_off_place_change.bind(this)
		this.handle_recovery_place_change = this.handle_recovery_place_change.bind(this)
		this.handle_status_change = this.handle_status_change.bind(this)
		this.handle_transport_way = this.handle_transport_way.bind(this)
		this.handle_arrival_date = this.handle_arrival_date.bind(this)
		this.handle_unity = this.handle_unity.bind(this)
		this.handle_volume = this.handle_volume.bind(this)
		this.handle_hour_deadline_change = this.handle_hour_deadline_change.bind(this)
		this.handle_devise = this.handle_devise.bind(this)
		this.handle_prix_choisi = this.handle_prix_choisi.bind(this)
		this.handle_transportation_condition_change = this.handle_transportation_condition_change.bind(this)
	}

	componentWillMount() {
		moment.locale('fr');
		
		this.idToken = getCookieConsentValue('idToken');
		this.setState({
			id_travel: this.travel.id_travel,
			has_check_ticket: this.travel.has_check_ticket,
			max_weight_available: this.travel.max_weight_available,
			min_weight_booking: this.travel.min_weight_booking,
			proposal_amount_by_kg: this.travel.proposal_amount_by_kg,
			is_automatic_approval: this.travel.is_automatic_approval === "Y" ? true : false,
			others_infos: this.travel.others_infos,
			take_off_place: this.travel.take_off_place,
			recovery_place: this.travel.recovery_place,
			status: this.travel.status,
			service_charge_amount: this.travel.service_charge_amount,
			transport_way: this.travel.transportation_type === undefined ? '' : this.travel.transportation_type,
			arrival_date: this.travel.arrival_date === undefined ? '' : new Date(this.travel.arrival_date),
			departure_date: this.travel.departure_date === undefined ? '' : new Date(this.travel.departure_date),
			departure_city: this.travel.departure_city === undefined ? '' : this.travel.departure_city,
			transport_company: this.travel.transport_company === undefined ? '' : this.travel.transport_company,
			arrival_city: this.travel.arrival_city === undefined ? '' : this.travel.arrival_city,
			unity: this.travel.measure_unit === undefined ? '' : this.travel.measure_unit,
			booking_deadline: this.travel.booking_deadline === undefined ? '' : new Date(this.travel.booking_deadline),
			volume: this.travel.volume === undefined ? "0" : this.travel.volume,
			hour_deadline: this.travel.booking_deadline === undefined ? '' : moment(this.travel.booking_deadline).format('LT'),
			showVolume: this.travel.measure_unit === 'Barrique' || this.travel.measure_unit === 'Carton' ? true : false,
			prix_choisi: this.travel.service_charge_amount,
			transportation_condition: this.travel.transportation_condition,
		});
	}

	////////handle file selector//////////
	componentDidMount() {
		this.fileSelector = buildFileSelector();
	}

	handleFileSelect = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			this.setState({
				file: file,
				doc: reader.result
			});
		}
		reader.readAsDataURL(file)
	}

	handle_departure_date_change(event) {
		this.setState({ departure_date: event });
		/* this.setState({ departure_date: event.target.value }); */
	}

	handle_hour_deadline_change(event) { this.setState({ hour_deadline: event.target.value }); }

	handle_transportation_condition_change(event) { this.setState({ transportation_condition: event.target.value }); }

	// Debut Ajout Ville by GooGle Place

	handle_departure_city_change = departure_city => {
		this.setState({ departure_city });
	};


	handle_arrival_city_change = arrival_city => {
		this.setState({ arrival_city });

	};


	handleSelectDepartureCity = (departure_city, placeId, suggestion) => {
		this.setState({ departure_city: suggestion.description });
		//this.getLatLngDeparture(suggestion.description);
	}

	getLatLngDeparture = async (city) => {
		await geocodeByAddress(city)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				this.setState({
					lat_departure: latLng.lat,
					long_departure: latLng.lng
				})
			})
			.catch(error => console.error('Error', error));

	}

	handleSelectArrivalCity = (handlearrivalCity, placeId, suggestion) => {
		this.setState({ arrival_city: suggestion.description });
		//this.getLatLngArrival(suggestion.description);
	}

	getLatLngArrival = async (city) =>{
		
		await geocodeByAddress(city)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				this.setState({
					lat_arrival: latLng.lat,
					long_arrival: latLng.lng
				})
			})

	}

	// Fin Ajout Ville by GooGle Place

	handle_has_check_ticket_change(event) { this.setState({ has_check_ticket: event.target.value }); }
	handle_max_weight_available_change(event) { this.setState({ max_weight_available: event.target.value }); }
	handle_min_weight_booking_change(event) { this.setState({ min_weight_booking: event.target.value }); }
	handle_booking_deadline_change(event) {
		this.setState({ booking_deadline: event });
		/* this.setState({ booking_deadline: event.target.value }); */
	}
	handle_service_charge_amount_change(event) {
		// if (parseFloat(parseFloat(event.target.value.replace(',', '.')) * 0.25) > 1) {
		// 	this.setState({
		// 		service_charge_amount: event.target.value,
		// 		proposal_amount_by_kg: parseFloat(parseFloat(event.target.value.replace(',', '.')) + (parseFloat(event.target.value.replace(',', '.')) * 0.25)).toFixed(2)
		// 	});
		// } else {
		// 	this.setState({
		// 		service_charge_amount: event.target.value,
		// 		proposal_amount_by_kg: parseFloat(parseFloat(event.target.value.replace(',', '.')) + (parseFloat(1))).toFixed(2)
		// 	})
		// }
	}
	handle_devise(event) { this.setState({ devise: event.target.value }); }

	handle_prix_choisi(event) {
		//{this.state.devise==="FCFA" ? this.state.service_charge_amount = parseFloat(this.state.prix_choisi/655.95).toFixed(2).toString() : this.state.service_charge_amount =this.state.prix_choisi}
		this.setState({ prix_choisi: event.target.value });
		if (this.state.devise === "FCFA") {
			this.setState({ service_charge_amount: parseFloat(event.target.value / 655.95).toFixed(2).toString() });
			if (parseFloat(parseFloat(event.target.value) / 655.95).toFixed(2) * 0.25 > 1) {
				this.setState({
					proposal_amount_by_kg: parseFloat(parseFloat(parseFloat(event.target.value) / 655.95) + parseFloat(parseFloat(parseFloat(event.target.value) / 655.95).toFixed(2) * 0.25)).toFixed(2)
				});

			} else {
				this.setState({
					proposal_amount_by_kg: parseFloat(parseFloat(event.target.value / 655.95).toFixed(2) + (parseFloat(1))).toFixed(2)
				})
			}

		}
		else {
			this.setState({ service_charge_amount: event.target.value });
			if (parseFloat(parseFloat(event.target.value) * 0.25) > 1) {
				this.setState({
					proposal_amount_by_kg: parseFloat(parseFloat(event.target.value) + (parseFloat(event.target.value) * 0.25)).toFixed(2)
				});
			} else {
				this.setState({
					proposal_amount_by_kg: parseFloat(parseFloat(event.target.value) + (parseFloat(1))).toFixed(2)
				})
			}
		}

	}
	handle_is_automatic_approval_change() { this.setState({ is_automatic_approval: !this.state.is_automatic_approval }); }
	handle_transport_company_change(event) { this.setState({ transport_company: event.target.value }); }
	handle_others_infos_change(event) { this.setState({ others_infos: event.target.value }); }
	handle_take_off_place_change(event) { this.setState({ take_off_place: event.target.value }); }
	handle_recovery_place_change(event) { this.setState({ recovery_place: event.target.value }); }
	handle_status_change(event) { this.setState({ status: event.target.value }); }
	handle_transport_way(event) { this.setState({ transport_way: event.target.value }); }
	handle_arrival_date(event) {
		this.setState({ arrival_date: event });
		// this.setState({ arrival_date: event.target.value }); 
	}
	handle_unity(event) {
		this.setState({
			unity: event.target.value
		});
		if (event.target.value === 'Barrique' || event.target.value === 'Carton') {
			this.setState({
				showVolume: true
			});
		} else {
			this.setState({
				showVolume: false
			});
		}
	}
	handle_volume(event) { this.setState({ volume: event.target.value }); }
	////

	handleSubmit = async (event) =>{
		event.preventDefault();
		await this.getLatLngArrival(this.state.arrival_city);
		await this.getLatLngDeparture(this.state.departure_city);
		if (this.state.doc !== '') {
			var datas = this.state.doc.split(';')
			var encodingfile = datas[1].split(',')
			var extension = datas[0].split('/')
		}
		if (encodingfile != undefined && encodingfile[1].length > 3145728) {
			alert('Veuillez sélectionner un document de moins de 3 Mb svp')
		} else {
			var VAR = JSON.stringify({
				"travel_proposal": {
					"id_travel": this.state.id_travel,
					"departure_date":  moment(this.state.departure_date).format("YYYY-MM-DD")+"T23:59",
					"departure_city": this.state.departure_city,
					"arrival_city": this.state.arrival_city,
					"has_check_ticket": this.state.transportation_condition === 'Fret' ? "N" : "E",
					"max_weight_available": this.state.max_weight_available,
					"min_weight_booking": this.state.min_weight_booking,
					"proposal_amount_by_kg": this.state.proposal_amount_by_kg,
					"booking_deadline": Moment(this.state.booking_deadline).format('YYYY-MM-DD') + 'T' + this.state.hour_deadline,
					"service_charge_amount": this.state.service_charge_amount,
					"is_automatic_approval": this.state.is_automatic_approval === true ? "Y" : "N",
					"transport_company": this.state.transport_company,
					"others_infos": this.state.others_infos,
					"take_off_place": this.state.take_off_place,
					"recovery_place": this.state.recovery_place,
					"measure_unit": this.state.unity,
					"volume": this.state.volume,
					"arrival_date": this.state.arrival_date,
					"transportation_type": this.state.transport_way,
					"transportation_condition": this.state.transportation_condition,
					"status": "1",
					"lat_arrival_city": this.state.lat_arrival.toString(),
					"lng_arrival_city": this.state.long_arrival.toString(),
					"lat_departure_city": this.state.lat_departure.toString(),
					"lng_departure_city": this.state.long_departure.toString(),
					"ticket_infos": {
						"encoding": encodingfile != undefined ? encodingfile[1] : null,
						"extension": extension != undefined ? extension[1] : null
					}
				}
			})
			this.updateTravel(UPDATE_TRAVEL_QUERY, VAR)
		}
	}

	updateTravel = async (query, variables) => {
		try {
			const response = await axios.post(TRAVEL_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken
					}
				}
			);
			// Set the data to the state
			//console.log(response.data)

			if (response.data.data.updateTravelProposal.status === 200) {
				alert('updtate ok')
				window.location.reload(false);
			}
			else {
				alert('La mise à jour de votre voyage a échoué')
			}
		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	render() {
		
		if (getCookieConsentValue('nbr_res') > 0) {
			return (
				<Fragment>
					<Helmet>
						<title>Modifier le trajet | Monkolis</title>
						<meta name="robots" content="noindex,follow" />
					</Helmet>

					<div className="mytrips">
						<form onSubmit={this.handleSubmit}>
							<h4 className="color-text2 mar-bot0">Détails du trajet</h4>
							<hr className="mar-top5" />
							<div className="form-row">
							<div className="form-group col-md-4" data-toggle="tooltip" title="Quel type de conditionnement?">
									<label for="uniTravel">Type de transport </label>
									<input className="form-control" id="uniTravel" value={this.state.transportation_condition} onChange={this.handle_transportation_condition_change} required="true" readOnly />
										
								</div>
								<div className="form-group col-md-4 col-6">
									<label for="moyenTravel">Moyen de transport </label>
									<input type="text" className="form-control" id="travelFirm" readOnly placeholder="moyen de transport" value={this.state.transport_way} />
								</div>
								<div className="form-group col-md-4 col-6">
									<label for="travelFirm">Compagnie de transport</label>
									<input type="text" className="form-control" id="travelFirm" readOnly placeholder="Compagnie de transport" value={this.state.transport_company} />
								</div>
							</div>

							<div className="form-row">
								<div className="form-group col-md-6 col-6">
									<label for="travelDepart">Ville de départ</label>
									<input type="text" className="form-control" id="travelDepart" placeholder="Ville départ" readOnly value={this.state.departure_city} />
								</div>
								<div className="form-group col-md-6 col-6">
									<label for="travelArrivee">Ville d'arrivée</label>
									<input type="text" className="form-control" id="travelArrivee" placeholder="Ville d'arrivée" readOnly value={this.state.arrival_city} />
								</div>
							</div>

							<div className="form-row">
								<div className="form-group col-md-6 col-6">
									<label for="travelDate">Date de départ</label>
									<input type="date" className="form-control" id="travelDate" placeholder="Date de départ" readOnly value={Moment(this.state.departure_date).format('YYYY-MM-DD')} />
								</div>
								<div className="form-group col-md-6 col-6">
									<label for="arriveDate">Date d'arrivée</label>
									<input type="date" className="form-control" id="arriveDate" placeholder="Date d'arrivée" readOnly value={Moment(this.state.arrival_date).format('YYYY-MM-DD')} />
								</div>
							</div>

							{this.state.transportation_condition === 'Fret' ? '' : 
								<div className="form-row">
								<div className="form-group col-md-6 col-6">
									<label for="travelTicket">Vérification du billet</label>
									<input className="fileInput" type="file" onChange={(e) => this.handleFileSelect(e)} accept=".jpg,.jpeg,.png,.pdf" />
									<small id="travelTicket" className="form-text text-muted">Pdf, jpeg, png de 3 Mo maximum</small>
								</div>
							</div>}

							<h4 className="color-text2 mar-bot0 mar-top20">Détails de transport</h4>
							<hr className="mar-top5" />

							<div className="form-row">
								<div className="form-group col-md-4 col-6" data-toggle="tooltip" title="Quel type de conditionnement?">
									<label for="uniTravel">Unité </label>
									<input type="text" className="form-control" id="volTravel" placeholder={this.state.unity === null ? 'Unité' : this.state.unity} readOnly value={this.state.unity} />
								</div>
								{this.travel.unity === "Barrique" || this.state.unity === 'Carton' ?
									<div className="form-group col-md-4" data-toggle="tooltip" title="Quel est le volume maximum de l'unité">
										<label for="volTravel">Volume (litres)</label>
										<input type="number" className="form-control" id="volTravel" placeholder="volume" readOnly value={this.state.volume} />
									</div> :
									null
								}
								<div className="form-group col-md-4 col-6" data-toggle="tooltip" title="Nombre d'unité disponible">
									<label for="qteTravel">Quantité disponible</label>
									<input type="number" min="1" className="form-control" id="qteTravel" placeholder={this.state.max_weight_available + " " + this.state.unity} value={this.state.max_weight_available} onChange={this.handle_max_weight_available_change} readOnly />
								</div>

							</div>

							<div className="form-row">
								<div className="form-group col-md-3 col-6" data-toggle="tooltip" title="Entrer le nombre minimum de kilo qu'un expéditeur doit réserver">
									<label for="travelWeightMin">Minimum à réserver</label>
									<input type="number" min="1" className="form-control" id="travelWeightMin" placeholder={this.state.min_weight_booking + " " + this.state.unity} value={this.state.min_weight_booking} onChange={this.handle_min_weight_booking_change} />
								</div>
								{/* <div className="form-group col-md-3 col-6" title="Entrer la date limite de réservation. Après cette date, votre voyage n'apparaitra plus dans les recherches">
                                <label for="travelDead">Deadline </label>
                                <input type="text" className="form-control" readOnly value={Moment(this.travel.booking_deadline).format('DD/MM/YYYY hh:mm')} />
                            </div> */}


								<div className="form-group col-md-4" data-toggle="tooltip" title="Entrer la date limite de réservation. Après cette date, votre voyage n'apparaitra plus dans les recherches">
									<label for="travelDead">Date limite de reservations </label>
									<input type="date" className="form-control" min={Moment(new Date()).format('YYYY-MM-DD')} max={Moment(this.state.departure_date).format('YYYY-MM-DD')} placeholder="Date" value={Moment(this.state.booking_deadline).format('YYYY-MM-DD')} onChange={this.handle_booking_deadline_change} />
								</div>
								<div className="form-group col-md-4" data-toggle="tooltip" title="Entrer la date limite de réservation. Après cette date, votre voyage n'apparaitra plus dans les recherches">
									<label for="travelDead">Heure limite de reservations </label>
									<input type="time" className="form-control" min="00:00" max="24:00" placeholder="Date" value={this.state.hour_deadline} onChange={this.handle_hour_deadline_change} required="true" />
								</div>

							</div>

							<div className="form-row">
								<div className="form-group col-md-6 col-6">
									<label for="travelTake">Lieu de dépôt</label>
									<input type="text" className="form-control" id="travelTake" placeholder={this.state.take_off_place} value={this.state.take_off_place} onChange={this.handle_take_off_place_change} />
								</div>
								<div className="form-group col-md-6 col-6">
									<label for="travelGive">Lieu de livraison</label>
									<input type="text" className="form-control" id="travelGive" placeholder={this.state.recovery_place} value={this.state.recovery_place} onChange={this.handle_recovery_place_change} />
								</div>
							</div>


							<h4 className="color-text2 mar-bot0 mar-top20">{this.state.unity === null ? "Tarification service (€)" : "Tarification service (€/" + this.state.unity + ")"}</h4>
							<hr className="mar-top5" />

							<div className="form-row">
								<div className="form-group col-md-4 col-6">
									<label for="travelPrice">Prix</label>
									<input type="number" min="1" className="form-control" id="travelPrice" placeholder={"5 €/" + this.state.unity} readOnly value={this.state.service_charge_amount} />
								</div>
								<div className="form-group col-md-4 col-6">
									<label for="travelGain">Frais de réservation</label>
									<input type="number" min="1" className="form-control" id="travelGain" placeholder={"1 €/" + this.state.unity} readOnly value={0/*parseFloat(this.travel.proposal_amount_by_kg - this.travel.service_charge_amount).toFixed(2)*/} />
								</div>
								<div className="form-group col-md-4 col-6">
									<label for="travelPriceTotal">Prix final</label>
									<input type="number" min="1" className="form-control" id="travelPriceTotal" placeholder={"6 €/" + this.state.unity} readOnly value={this.state.service_charge_amount/*parseFloat(this.travel.proposal_amount_by_kg).toFixed(2)*/} />
								</div>

							</div>

							<div className="form-row">
								<div className="col">
									<input id="checkbox5" type="checkbox" className="magic-checkbox" onChange={this.handle_is_automatic_approval_change} checked={this.state.is_automatic_approval == true} readOnly />
									<label for="checkbox5">J'accepte automatiquement les réservations</label>
								</div>
							</div>


							<h4 className="color-text2 mar-bot0 mar-top20">Autres informations</h4>
							<hr className="mar-top5" />

							<div className="form-group">
								<label for="exampleFormControlTextarea1">Laisser un message</label>
								<small id="travelTicket" className="form-text text-muted">
									Ne pas indiquer de <span className="fa fa-1x fa-phone-square"></span> Numéro de téléphone
          ou  <span className="fa fa-1x fa-facebook-square"></span> de détails de votre Facebook
         </small>
								<textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder={this.state.others_infos} value={this.state.others_infos} onChange={this.handle_others_infos_change}></textarea>
							</div>

							<input type="submit" className="btn btn-primary" value="Modifier mon trajet" /> &nbsp;
                        <input type="button" className="btn btn-outline-primary" value="Annuler la modification" onClick={() => window.location.reload(false)} />
							<br />
							<br />
						</form>
					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<Helmet>
						<title>Modifier le trajet | Monkolis</title>
						<meta name="robots" content="noindex,follow" />
					</Helmet>

					<div className="mytrips">
						<form onSubmit={this.handleSubmit}>
							<h4 className="color-text2 mar-bot0">Détails du trajet</h4>
							<hr className="mar-top5" />
							<div className="form-row">

								<div className="form-group col-md-4" data-toggle="tooltip" title="Quel type de conditionnement?">
									<label for="uniTravel">Type de transport </label>
									<select className="form-control" id="uniTravel" value={this.state.transportation_condition} onChange={this.handle_transportation_condition_change} required="true">
										<option selected>Voyage personnel</option>
										<option>Fret</option>
									</select>
								</div>
								<div className="form-group col-md-4">
									<label for="moyenTravel">Moyen de transport : </label>
									<select className="form-control" id="moyenTravel" value={this.state.transport_way} onChange={this.handle_transport_way} required="true" >
										<option selected>Avion</option>
										<option>Bâteau</option>
										<option>Voiture</option>
										<option>Train</option>
									</select>
								</div>
								<div className="form-group col-md-4">
									<label for="travelFirm">Compagnie de transport</label>
									<input type="text" className="form-control" id="travelFirm" placeholder="Compagnie de transport" value={this.state.transport_company} onChange={this.handle_transport_company_change} required="true" />
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col-md-6">
									<label for="travelDepart">Ville de départ</label>
									<PlacesAutocomplete
										value={this.state.departure_city}
										onChange={this.handle_departure_city_change}
										onSelect={this.handleSelectDepartureCity}
									>
										{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
											<div>
												<input
													{...getInputProps({
														placeholder: ' Ville de départ ...',
														className: 'location-search-input',
													})}
												/>
												<div className="autocomplete-dropdown-container">
													{loading && <div>Loading...</div>}
													{suggestions.map(suggestion => {
														const className = suggestion.active
															? 'suggestion-item--active'
															: 'suggestion-item';
														// inline style for demonstration purpose
														const style = suggestion.active
															? { backgroundColor: '#fafafa', cursor: 'pointer' }
															: { backgroundColor: '#ffffff', cursor: 'pointer' };
														return (
															<div
																{...getSuggestionItemProps(suggestion, {

																	style,
																})}
															>
																<span>{suggestion.description}</span>
															</div>
														);
													})}
												</div>
											</div>
										)}
									</PlacesAutocomplete>
									{/* <input type="text" className="form-control" id="travelDepart" placeholder="Ville départ" value={this.state.departure_city} onChange={this.handle_departure_city_change} required="true" /> */}
								</div>
								<div className="form-group col-md-6">
									<label for="travelArrivee">Ville d'arrivée</label>
									<PlacesAutocomplete
										value={this.state.arrival_city}
										onChange={this.handle_arrival_city_change}
										onSelect={this.handleSelectArrivalCity}
									>
										{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
											<div>
												<input
													{...getInputProps({
														placeholder: '  Ville d\'arrivée ...',
														className: 'location-search-input',
													})}
												/>
												<div className="autocomplete-dropdown-container">
													{loading && <div>Loading...</div>}
													{suggestions.map(suggestion => {
														const className = suggestion.active
															? 'suggestion-item--active'
															: 'suggestion-item';
														// inline style for demonstration purpose
														const style = suggestion.active
															? { backgroundColor: '#fafafa', cursor: 'pointer' }
															: { backgroundColor: '#ffffff', cursor: 'pointer' };
														return (
															<div
																{...getSuggestionItemProps(suggestion, {
																	style,
																})}
															>
																<span>{suggestion.description}</span>
															</div>
														);
													})}
												</div>
											</div>
										)}
									</PlacesAutocomplete>
									{/* <input type="text" className="form-control" id="travelArrivee" placeholder="Ville arrivée" value={this.state.arrival_city} onChange={this.handle_arrival_city_change} required="true" /> */}
								</div>
							</div>

							<div className="form-row">
								<div className="form-group col-md-6">
									<label for="travelDate">Date de départ</label>
									<div>
										<DatePicker
											selected={this.state.departure_date}
											onChange={this.handle_departure_date_change}
											dateFormat="dd/MM/yyyy"
											minDate={new Date()}
											required
										/>
									</div>
									{/* <input type="date" min={Moment(new Date()).format('YYYY-MM-DD')} className="form-control" id="travelDate" placeholder="Date" value={Moment(this.state.departure_date).format('YYYY-MM-DD')} onChange={this.handle_departure_date_change} required="true" /> */}
								</div>
								<div className="form-group col-md-6">
									<label for="arriveDate">Date d'arrivée</label>
									<div>
										<DatePicker
											selected={this.state.arrival_date}
											onChange={this.handle_arrival_date}
											dateFormat="dd/MM/yyyy"
											minDate={this.state.departure_date}
										/>
									</div>
									{/* <input type="date" min={this.state.departure_date} className="form-control" id="arriveDate" placeholder="Date d'arrivée" value={this.state.arrival_date} onChange={this.handle_arrival_date} required="true" /> */}
								</div>
							</div>

							{
								this.state.transportation_condition === 'Fret' ? '' :
								<div className="form-row">
								<div className="form-group col-md-6">
									<label for="travelTicket">Vérification du billet</label>
									<input className="btn fileInput" type="file" onChange={(e) => this.handleFileSelect(e)} accept=".jpg,.jpeg,.png,.pdf" />
									<small id="travelTicket" className="form-text text-muted">Pdf, jpeg, png de 3 Mo maximum</small>
								</div>
							</div>}

							<h4 className="color-text2 mar-bot0 mar-top20">Détails de transport</h4>
							<hr className="mar-top5" />

							<div className="form-row">
								<div className="form-group col-md-4" data-toggle="tooltip" title="Quel type de conditionnement?">
									<label for="uniTravel">Unité : </label>
									<select className="form-control" id="uniTravel" value={this.state.unity} onChange={this.handle_unity} required="true">
										<option selected>Kg</option>
										<option>Barrique</option>
										<option>Carton</option>
									</select>
								</div>
								{this.state.showVolume === true ?
									<div className="form-group col-md-4" data-toggle="tooltip" title="Quel est le volume maximum de l'unité">
										<label for="volTravel">Volume (litres)</label>
										<input type="number" min="0,1" className="form-control" id="volTravel" placeholder={"Volume de " + this.state.unity} value={this.state.volume} onChange={this.handle_volume} />
									</div> :
									null
								}
								<div className="form-group col-md-4" data-toggle="tooltip" title="Nombre d'unité disponible">
									<label for="qteTravel">Quantité disponible</label>
									<input type="number" min="1" className="form-control" id="qteTravel" placeholder={"Nombre de " + this.state.unity} value={this.state.max_weight_available} onChange={this.handle_max_weight_available_change} required="true" />
								</div>

							</div>

							<div className="form-row">
								<div className="form-group col-md-4" data-toggle="tooltip" title={"Entrer le nombre minimum de " + this.state.unity + " qu'un expéditeur doit réserver"}>
									<label for="travelWeightMin">Minimum à réserver</label>
									<input type="number" step="0.1" min="1" max={this.state.max_weight_available} className="form-control" id="travelWeightMin" placeholder={"Nombre de " + this.state.unity} value={this.state.min_weight_booking} onChange={this.handle_min_weight_booking_change} required="true" />
								</div>
								<div className="form-group col-md-4" data-toggle="tooltip" title="Entrer la date limite de réservation. Après cette date, votre voyage n'apparaitra plus dans les recherches">
									<label for="travelDead">Date limite de reservations </label>
									<div>
										<DatePicker
											selected={this.state.booking_deadline}
											onChange={this.handle_booking_deadline_change}
											dateFormat="dd/MM/yyyy H:mm"
											minDate={new Date()}
											maxDate={this.state.departure_date}
											showTimeSelect
											timeFormat="HH:mm"
										/>
									</div>
									{/* <input type="date" className="form-control" min={Moment(new Date()).format('YYYY-MM-DD')} max={this.state.departure_date} placeholder="Date" value={Moment(this.state.booking_deadline).format('YYYY-MM-DD')} onChange={this.handle_booking_deadline_change} required="true" /> */}
								</div>
								{/* <div className="form-group col-md-4" data-toggle="tooltip" title="Entrer la date limite de réservation. Après cette date, votre voyage n'apparaitra plus dans les recherches">
									<label for="travelDead">Heure limite de reservations </label>
									<input type="time" className="form-control" min="00:00" max="24:00" placeholder="Date" value={this.state.hour_deadline} onChange={this.handle_hour_deadline_change} required="true" />
								</div> */}
							</div>

							<div className="form-row">
								<div className="form-group col-md-6">
									<label for="travelTake">Lieu de dépôt</label>
									<input type="text" className="form-control" id="travelTake" placeholder="Paris, Près de la gare de Lyon" value={this.state.take_off_place} onChange={this.handle_take_off_place_change} required="true" />
								</div>
								<div className="form-group col-md-6">
									<label for="travelGive">Lieu de livraison</label>
									<input type="text" className="form-control" id="travelGive" placeholder="Directement à l'aéroport" value={this.state.recovery_place} onChange={this.handle_recovery_place_change} required="true" />
								</div>
							</div>

							<h4 className="color-text2 mar-bot0 mar-top20">Tarification service ({"€/" + this.state.unity})</h4>
							<hr className="mar-top5" />
							<div>
								<div className="form-row">
									<div className="form-group col-md-6">
										<label for="moyenTravel">Devise : </label>
										<select className="form-control" id="moyenTravel" value={this.state.devise} onChange={this.handle_devise} required="true" >
											<option selected>EURO</option>
											<option>FCFA</option>
										</select>
									</div>

									<div className="form-group col-md-6">
										<label for="travelGain">Prix ({this.state.devise === "EURO" ? "€" : this.state.devise} /{this.state.unity})</label>
										<input type="number" step="0.1" min="1" className="form-control" id="travelGain" placeholder={this.state.devise + "/" + this.state.unity} value={this.state.prix_choisi} onChange={this.handle_prix_choisi} required="true" />
									</div>
								</div>
								<div className="form-row">
									<div className="form-group col-md-4">
										<label for="travelGain">Prix</label>
										<input type="number" step="0.1" min="1" className="form-control" id="travelGain" readOnly placeholder={"€/" + this.state.unity} value={this.state.service_charge_amount} onChange={this.handle_service_charge_amount_change} required="true" />
									</div>

									<div className="form-group col-md-4">
										<label for="travelGain">Frais de réservation</label>
										<input type="number" min="1" className="form-control" id="travelGain" placeholder="1 €/Unité" readOnly value={0/*this.state.service_charge_amount * 0.25*/} />
									</div>
									<div className="form-group col-md-4">
										<label for="travelPrice">{"Prix final (€/" + this.state.unity + ")"}</label>
										<input type="number" min="1" className="form-control" id="travelPrice" placeholder={"€/" + this.state.unity} readOnly value={this.state.service_charge_amount/*this.state.proposal_amount_by_kg*/}
										/>
									</div>

								</div>
							</div>

							<div className="form-row">
								<div className="col-md-6 pad-top25">
									<input id="checkbox5" type="checkbox" className="magic-checkbox" onChange={this.handle_is_automatic_approval_change} />
									<label for="checkbox5">J'accepte automatiquement les réservations</label>
								</div>
							</div>


							<h4 className="color-text2 mar-bot0 mar-top20">Autres informations</h4>
							<hr className="mar-top5" />

							<div className="form-group">
								<label for="exampleFormControlTextarea1">Laisser un message</label>
								<small id="travelTicket" className="form-text text-muted">
									Ne pas indiquer de <span className="fa fa-1x fa-phone-square"> Numéro de téléphone </span>
                    ou	    <span className="fa fa-1x fa-facebook-square"> de détails de votre Facebook </span>
								</small>
								<textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.others_infos} onChange={this.handle_others_infos_change}></textarea>
							</div>
							<div className="mar-bot20 mar-top20" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
								<input id="checkbox6" type="checkbox" className="magic-checkbox" onChange={this.handletravelBack} />
								<label for="checkbox6">Je veux également publier mon trajet retour</label>
							</div>
							{this.state.showTravelBack ? this.renderTravelBack() : null}
							<input type="submit" className="btn btn-primary" value="Publier mon trajet" />
						</form>
					</div>
				</Fragment>
			);
		}
	}
}
export default withRouter(UpdateTrip);
