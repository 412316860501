import React, { Component } from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Moment from 'moment';
import { GET_CODEVALIDATION_QUERY } from '../../../query/queryprofil';
import { VERIFY_NUMBER_QUERY } from '../../../query/queryprofil';
import { UPLOAD_IDENTITY_QUERY } from '../../../query/queryprofil';
import { GET_CODEVALIDATION_URL } from '../../../Url';
import { VERIFY_NUMBER_URL } from '../../../Url';
import { UPLOAD_IDENTITY_URL } from '../../../Url';
import { TRAVEL_ACTIONS_URL, SECURE_COOKIES } from '../../../Url';
import { GET_DASHBOARD_INFOS_QUERY } from '../../../query/queryprofil';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';


class MyVerifications extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isLoaded: false,
			renderVerifyPhone: false,
			renderVerifymail: false,
			renderIdentity :  false, 
			phone_number: getCookieConsentValue('phone_number'),
			email: getCookieConsentValue('email'),
			renderVerifyPhoneClick: false,
			renderVerifymailClick: false,
			// phone_number: sessionStorage.getItem('phone_number'),
			// email: sessionStorage.getItem('email'),
			code_verification: '',
			itemsGetCode : [],
			itemsVerifyNumber: [],

			id_card_verified: '',
			phone_number_verified: '',
			email_verified: '',
			locality: '',

		};
		this.handleValidateWhatApssChange = this.handleValidateWhatApssChange.bind(this);
		this.handleValidateIdentityChnage = this.handleValidateIdentityChnage.bind(this);
		this.handleCodeChange = this.handleCodeChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitVerify = this.handleSubmitVerify.bind(this);
		this.handleGoToProfil = this.handleGoToProfil.bind(this);
		this.handleSubmitMail = this.handleSubmitMail.bind(this);
		this.handleValidateMailChange = this.handleValidateMailChange.bind(this);

	}

	componentWillMount() {
		
        this.idToken = getCookieConsentValue('idToken');
        this.photo = getCookieConsentValue('photo');
        this.name = getCookieConsentValue('given_name');
		this.birthdate = getCookieConsentValue('birthdate') 
		
        // this.idToken = sessionStorage.getItem('idToken');
        // this.photo = sessionStorage.getItem('photo');
        // this.name = sessionStorage.getItem('given_name');
        // this.birthdate = sessionStorage.getItem('birthdate') 
        this.getDashboardInfos(TRAVEL_ACTIONS_URL, this.idToken);
	}

	handleGoToProfil(event){
		this.props.history.push({
			state: { pages: 'in_progress' }
		});
		window.location.reload(false);
	}
	
	getDashboardInfos = async (url, idToken) => {
        try {
            const response = await axios.post(url,
                { "query": GET_DASHBOARD_INFOS_QUERY },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );

            // Log the response so we can look at it in the console
            if (response.data.data.getUserDashboard.status === 200) {
                this.setState(() => ({
					locality: response.data.data.getUserDashboard.user_dashboard.address === null ? '' : response.data.data.getUserDashboard.user_dashboard.address.locality !=="null" ? response.data.data.getUserDashboard.user_dashboard.address.locality :'',
                    id_card_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.id_card_verified,
                    phone_number_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.phone_number_verified,
                    email_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.email_verified,
					renderVerifyPhone: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.phone_number_verified === "true" ? false : true,
					email_verified: response.data.data.getUserDashboard.user_dashboard.verifications.email_verified ,
					renderVerifymail: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.email_verified === "true" ? false : true,
                }));
            }
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState({ error: error })
        }
    }

	//Get Code to valide WhatSapp number
	handleValidateWhatApssChange(event) {
		this.setState({ 
			renderVerifyPhone: !this.state.renderVerifyPhone,
			renderVerifyPhoneClick: !this.state.renderVerifyPhoneClick 
		})
			var VAR = JSON.stringify({
				"attribute_name": "phone_number"
			})
			this.getCode(GET_CODEVALIDATION_QUERY, VAR)
	}

	handleValidateMailChange(event) {
		this.setState({ 
			renderVerifymail: !this.state.renderVerifymail,
			renderVerifymailClick: !this.state.renderVerifymailClick 
		})
			var VAR = JSON.stringify({
				"attribute_name": "email"
			})
			this.getCode(GET_CODEVALIDATION_QUERY, VAR)
	}


	componentDidMount() {
		
        this.idToken = getCookieConsentValue('idToken');
		this.accessToken = getCookieConsentValue('accessToken')

	}


	getCode = async (query, variables) => {
		try {
			const response = await axios.post(GET_CODEVALIDATION_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken,
						'AccessToken': this.accessToken,
						'Content-Type': 'application/json'
					}
				}
			);
			this.setState(() => ({
				isLoaded: true,
				itemsGetCode: response.data.data
			}));

			if(this.state.itemsGetCode.getUserAttributeVerificationCode.status ===200)
			{ 
				
			}
			else if (this.state.itemsGetCode.getUserAttributeVerificationCode.status ===400)
			{
				
			}

		} catch (error) {
			console.log(error)
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}


	handleCodeChange(event) {
		this.setState({ code_verification: event.target.value });
	}

	//End of get Code

	//verify number
	handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		else {
			this.setState(() => ({
				validated: true
			}));
			var VAR = JSON.stringify({
				"attribute_name": "phone_number",
				"code": this.state.code_verification
			})
			this.verifyUserNumber(VERIFY_NUMBER_QUERY, VAR)
		}

	}

	verifyUserNumber = async (query, variables) => {
		try {
			const response = await axios.post(VERIFY_NUMBER_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken,
						'AccessToken': this.accessToken,
						'Content-Type': 'application/json'
					}
				}
			);
			this.setState(() => ({
				isLoaded: true,
				itemsVerifyNumber: response.data.data
			}));
            
			if(this.state.itemsVerifyNumber.verifyUserAttribute.status===200){
				alert("Code valide : Votre numéro de téléphone a été vérifié")

				this.setState({
					phone_number_verified : true,
					renderVerifyPhone: false
				})
				window.location.reload(false);
			}
			else if(this.state.itemsVerifyNumber.verifyUserAttribute.status === 400){
				if(this.state.itemsVerifyNumber.verifyUserAttribute.message==="CodeMismatchException|CodeMismatchException|Invalid verification code provided, please try again.")
				{
					alert("Code de validation invalide, merci de renseigner le bon code et réessayer svp")
				}
				else {
					alert("Echec de la verification de votre numero de telephone, Merci de réessayer")
				}
			}

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	handleSubmitMail(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		else {
			this.setState(() => ({
				validated: true
			}));
			var VAR = JSON.stringify({
				"attribute_name": "email",
				"code": this.state.code_verification
			})
			this.verifyMail(VERIFY_NUMBER_QUERY, VAR)
		}

	}

	verifyMail = async (query, variables) => {
		try {
			const response = await axios.post(VERIFY_NUMBER_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken,
						'AccessToken': this.accessToken,
						'Content-Type': 'application/json'
					}
				}
			);
			if(response.data.data.verifyUserAttribute.status===200){
				alert("Code valide : Votre email a été vérifié")

				this.setState({
					email_verified : true,
					renderVerifymail: false
				})
				window.location.reload(false);
			}
			else if(this.state.itemsVerifyNumber.verifyUserAttribute.status === 400){
				if(this.state.itemsVerifyNumber.verifyUserAttribute.message==="CodeMismatchException|CodeMismatchException|Invalid verification code provided, please try again.")
				{
					alert("Code de validation invalide, merci de renseigner le bon code et réessayer svp")
				}
				else {
					alert("Echec de la verification de votre mail, Merci de réessayer")
				}
			}

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
			console.log(error)
		}
	}


	handleValidateIdentityChnage(event) {
		//this.setState({ renderVerifyPhone: event.target.renderVerifyPhone })
		this.setState({ renderIdentity: !this.state.renderIdentity })
	}
	//verify identity
	handleFileSelect = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			this.setState({
				file: file,
				picture: reader.result
			});
		}
		reader.readAsDataURL(file)
	}

	handleSubmitVerify(event) {
		event.preventDefault();
		var datas = this.state.picture.split(';')
		var encodingfile = datas[1].split(',')
		var extension = datas[0].split('/')
		if (encodingfile[1].length > 3145728){
			alert('Veuillez sélectionner une photo de moins de 3 Mb svp')
		}else{
			var VAR = JSON.stringify({
				"file": {
					"encoding" : encodingfile[1],
					"extension" : extension[1],
				}
			});
			if(extension[1] === "jpg" || extension[1] === "jpeg" || extension[1] === "png" || extension[1] === "pdf" || extension[1] === "doc" || extension[1] === "docx"){
				window.confirm('Voulez-vous vraiment ajouter ce fichier')? this.uploadIdentity(UPLOAD_IDENTITY_QUERY, VAR) : window.close()
			}else{
				alert('Veuillez selectionner une photo au format PNG ou JPG')
			}
		}
	}


	uploadIdentity = async(query, variables)=>{
        try {
			const response = await axios.post(UPLOAD_IDENTITY_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken,
						'AccessToken': this.accessToken
					}
				}
			);

			

			this.setState(() => ({
				isLoaded: true,
				modifypicture: response.data.data
			}));
	       if(this.state.modifypicture.uploadIdCardUser.status=200){
			alert("votre fichier a bien été enrégistré, il sera verifié par l'équipe MonKolis.")
			Cookies.set('id_card_check_status', "in-progress" , { path: '/', secure: SECURE_COOKIES, expires: 1 });
		   }
           
            window.location.reload(false);
		} catch (error) {
			// If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('modification failed')
		}
    }

	render() {
		return (
			<div class="myprofile">
				<aside class="aside section verifs">
					<div class="section-inner">
						<h3 class="heading">Vérifications</h3>
						<div class="content">
							<p class="mar-bot30">
								Faites vérifier votre profil pour devenir un membre de confiance de notre communauté. Vous trouverez plus facilement des expéditeurs et transporteurs !
							</p>

							<h5 class="">Adresse e-mail {this.state.email_verified==="true"? <span class="fa fa-1x fa-check-square-o color-green"> </span> : <span class="fa fa-1x fa-close color-red"></span>} </h5>
							<p class="mar-bot0">
		Vous avez confirmé cette adresse : <span class="txt-bold">{this.state.email}</span> {/* <a onClick={this.handleGoToProfil} class="link color-blue">Modifier mon adresse e-mail</a> */}<br />
 								Il est important d'avoir une adresse e-mail confirmée pour que nous puissions vous contacter de manière totalement sécurisée.
							</p>
							<br />
							{this.state.renderVerifymail === true ? <button type="button" className="btn btn-theme2 txt-white" data-toggle="modal" data-target="#add-delete-alert" onClick={this.handleValidateMailChange} >Vérifier mon email</button>: ''}&nbsp;&nbsp;

							{this.state.renderVerifymailClick === true ?
								<div className="">
									<h5 className="color-text3">Validation de l'email</h5>
									<p className="txt-bold">Je saisis le code qui m'a été envoyé par email</p>

									<Form className="well" validated={this.state.validated} onSubmit={this.handleSubmitMail}>

										<div className="form-row">
											<div className="form-group">
												<label for="code" class="color-white"></label>
												<Form.Group controlId="validationCustom01">
													<Form.Control
														required
														type="text"
														placeholder=""
														defaultValue={this.state.code_verification}
														onChange={this.handleCodeChange}
													/>
													<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
												</Form.Group>
											</div>
											<div className="form-group col-md-8 pad-top20">
												<input type="submit" className="btn btn-theme2" value="Valider le code" />
											</div>
										</div>
									</Form>
									<p className="mar-bot0">
										Le code qui vous a été transmis est valable pendant 2h.<br />
									</p>
									<a href="#" className="link color-blue">Renvoyer le code</a>
								</div> : null}

							<h5 class="mar-top30">Téléphone (Whatsapp) {this.state.phone_number_verified==="true"? <span class="fa fa-1x fa-check-square-o color-green"> </span> : <span class="fa fa-1x fa-close color-red"></span>}  </h5>
							{this.state.phone_number === null || this.state.phone_number===undefined || this.state.phone_number==='null'|| this.state.phone_number==='undefined' ? <a onClick={this.handleGoToProfil} class="link color-blue">Veuillez enregistrer votre numéro de téléphone</a> :
							 <p class="mar-bot0">
								Vous avez enregistré ce numéro : <span class="txt-bold">{this.state.phone_number} </span> <a onClick={this.handleGoToProfil} class="link color-blue">Modifier mon numéro de phone</a><br />
 								Votre numéro ne sera partagé avec d'autres membres MonKolis qu'une fois la réservation confirmée.

								 <br />
							{this.state.renderVerifyPhone === true ? <button type="button" className="btn btn-theme2 txt-white" data-toggle="modal" data-target="#add-delete-alert" onClick={this.handleValidateWhatApssChange} >Vérifier mon numéro de téléphone</button>: ''}&nbsp;&nbsp;
							</p>
							
							}
							
							{/* <br />
							<a type="button" className="btn btn-theme2 txt-white" data-toggle="modal" data-target="#add-delete-alert" onClick={this.handleValidateWhatApssChange}>Valider mon numéro de téléphone</a> */}

							{this.state.renderVerifyPhoneClick === true ?
								<div className="">
									<h5 className="color-text3">Validation du numéro de téléphone</h5>
									<p className="txt-bold">Je saisis le code qui m'a été envoyé par sms</p>

									<Form className="well" validated={this.state.validated} onSubmit={this.handleSubmit}>

										<div className="form-row">
											<div className="form-group">
												<label for="code" class="color-white"></label>
												<Form.Group controlId="validationCustom01">
													<Form.Control
														required
														type="text"
														placeholder=""
														defaultValue={this.state.code_verification}
														onChange={this.handleCodeChange}
													/>
													<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
												</Form.Group>
											</div>
											<div className="form-group col-md-8 pad-top20">
												<input type="submit" className="btn btn-theme2" value="Valider le code" />
											</div>
										</div>
									</Form>
									<p className="mar-bot0">
										Le code qui vous a été transmis est valable pendant 2h.<br />
									</p>
									<a href="#" className="link color-blue">Renvoyer le code</a>
								</div> : null}

							
								

							<h5 class="mar-top30">Pièce d'identité  {this.state.id_card_verified==="true"? <span class="fa fa-1x fa-check-square-o color-green"> </span> : this.state.id_card_verified==="true" ? <span class="fa fa-1x fa-close color-red"></span> : 
							this.state.id_card_verified==="in-progress" ? <span class="fa fa-1x fa-spinner"></span> : '' } </h5>
							
							{this.state.id_card_verified==="in-progress" ? <div>
							<p class="mar-bot0">Pièce d'identité en cours de vérification </p> </div>: 
							<div>  
							</div>}

							{this.state.id_card_verified==="true"? <div>
							<p class="mar-bot0">Pièce d'identité validée </p> </div>: 
							<div>  
							</div>}
							
							{this.state.id_card_verified!=="true" && this.state.id_card_verified!=="in-progress" ? <div>  
							<p class="mar-bot0">
								Transmettez votre pièce d'idendité (passeport, carte nationale ou titre de séjour).
								Les autres membres seront plus enclin à travailler avec vous.<br />
								<b><i> <p className="color-red">*Veuillez vous assurer que les noms et prénoms mentionnés sur votre profil sont identiques à ceux figurant sur votre pièce d'identité en cours de validité</p></i> </b> <br /><br />
							</p>
							</div>: 
							<div>  
							</div>}

							{this.state.id_card_verified==="in-progress" ||this.state.id_card_verified==="true" ? '' : <button type="button" className="btn btn-theme2 txt-white" data-toggle="modal" data-target="#add-delete-alert" onClick={this.handleValidateIdentityChnage} >Télécharger ma pièce d'identité</button>} &nbsp;&nbsp;
							{/* <label> Bientôt disponible !</label>  */}

							{/* <a href="#" class="btn btn-theme2" OnChange={this.handleValidateIdentityChnage}>Télécharger ma pièce d'identité</a> */}

							{this.state.renderIdentity === true ?
							<Form className="well" validated={this.state.validated} onSubmit={this.handleSubmitVerify}>
								<Form.Group controlId="validationCustom01">
									<Form.Control
										required
										type="file"
										placeholder=""
										defaultValue={this.state.code_verification}
										onChange={(e) => this.handleFileSelect(e)}
										accept=".gif,.jpg,.jpeg,.png,.svg,.bmp,.pdf,.doc,.docx,.tiff"
									/>
									<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
									<small id="travelTicket" className="form-text text-muted">jpeg, png de 3 Mo maximum</small>
								</Form.Group>
								<input type="submit" className="btn btn-primary" value="Verifier" />
							</Form> : null}


							{/* <h5 class="mar-top30">Domicile <span class="color-text2">- Vérification en cours </span><span class="fa fa-1x fa-spinner color-text2"></span></h5>
							<p class="mar-bot0">
								Transmettez un justicatif d'adresse qui porte vos noms et prénoms ainsi que votre adresse tel que metionné dans votre profil (facture eau ou electrité, copie relevé bancaire, attestation d'hébergement).<br />
										                           	Votre adresse ne sera jamais commuiqué aux autres membres.<br />
							</p> */}


							{/* <h5 class="mar-top30">Compte Facebook  <span class="fa fa-1x fa-check-square-o color-green"></span></h5>
							<p class="mar-bot0">
								Votre compte Facebook étant connecté, les autres membres peuvent voir combien d'amis vous avez.<br /><br />
							</p>*/}

							{/* <h5 class="mar-top30">Compte Linkedin  <span class="fa fa-1x fa-close color-red"></span></h5>
							<p class="mar-bot0">
								Votre compte Linkedin étant connecté, les autres membres peuvent voir combien de contacts vous avez.<br /><br />
							</p> */}

							{/* <div class="row col-md-5">
								<a href="#" class="btn btn-social btn-linkedin"><i class="fa fa-linkedin"></i> Connecter mon Linkedin</a>
							</div> */}

						</div>
					</div>
				</aside>

			</div>



		);
	}
}
export default withRouter(MyVerifications);