import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';
import axios from 'axios';
import { EVALUATE_TRAVELER_QUERY } from '../../../query/queryexpeditions';
import { GET_EVALUATION_DATA_QUERY} from '../../../query/queryprofil';
import {TRAVELER_EVALUATION_QUERY}	from '../../../query/travelquery';
import { TRAVEL_ACTIONS_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Moment from 'moment';

import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/search.css';
import './../../../assets/css/modal.css';
import Modal from 'react-bootstrap/Modal';
import PHOTO from '../../../assets/img/user/user0.png';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import { Rating } from '@material-ui/lab';

class MyAvislaisses extends Component {
	constructor(props) {
		super(props);
		this.nbeElementOfPage = 3
		this.offsetInitial = 0
		this.state = {
			pageCount: 0,
			offset: this.offsetInitial,
			perPage: this.nbeElementOfPage,
			currentPage: 0,
			data: [],
			tmp: '',
			commentary: '',
			showEvalModal: false,
			nameTmp: '',
			marks: '5/5 Très bien',
			requester_proposals: [],
			datavalue: [],
			userRole : ''
		};

		this.handleCommentaryChange = this.handleCommentaryChange.bind(this);
		this.handleCloseEvalModal = this.handleCloseEvalModal.bind(this);
		this.handleShowEvalModal = this.handleShowEvalModal.bind(this);
		this.handleEvalRequest = this.handleEvalRequest.bind(this);
		this.handleChangeMakrs = this.handleChangeMakrs.bind(this);
	}

	handleChangeMakrs(event) {
		this.setState({ marks: event.target.value })
	}

	handleCommentaryChange(event) {
		this.setState({ commentary: event.target.value })
	}

	handleCloseEvalModal() {
		this.setState(
			{ showEvalModal: false }

		)
	}

	handleShowEvalModal(event, valueIdProposal, valueName,user_role) {
		event.preventDefault();
		this.setState({
			showEvalModal: !this.state.showEvalModal,
			tmp: valueIdProposal,
			nameTmp: valueName,
			userRole: user_role
		}
		)
	}

	componentWillMount() {
		
		this.idToken = getCookieConsentValue('idToken');
		this.userSub = getCookieConsentValue('userSub');
		//this.idToken = sessionStorage.getItem('idToken')
		this.getExpeditionsHistoric(TRAVEL_ACTIONS_URL, this.idToken);
	}

	getExpeditionsHistoric = async (url, idToken) => {
		try {
			const response = await axios.post(url,
				{ "query": GET_EVALUATION_DATA_QUERY },
				{
					'headers':
					{
						'Authorization': idToken
					}
				}
			);

			if (response.data.data.getEvaluationsSendReceivedAndWaitingByUser.status === 200) {
				this.setState(() => ({
					dataValue: response.data.data.getEvaluationsSendReceivedAndWaitingByUser.evaluations_send_receive_waiting.evaluations_waiting,
					pageCount: Math.ceil(response.data.data.getEvaluationsSendReceivedAndWaitingByUser.length / this.nbeElementOfPage),
					data: response.data.data.getEvaluationsSendReceivedAndWaitingByUser.evaluations_send_receive_waiting.length > this.nbeElementOfPage ? response.data.data.getEvaluationsSendReceivedAndWaitingByUser.evaluations_send_receive_waiting.slice(this.offsetInitial, this.nbeElementOfPage) : response.data.data.getEvaluationsSendReceivedAndWaitingByUser.evaluations_send_receive_waiting,
				}));
			}


		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	handleEvalRequest(event) {
		event.preventDefault();

		var VAR = JSON.stringify({
			"id_proposal": this.state.tmp,
			"marks": this.state.marks[0],
			"comment": this.state.commentary
		});
		if(this.state.userRole ==='REQUESTER')
		{
			this.evalRequest(TRAVELER_EVALUATION_QUERY, VAR)
			
		}
		else if(this.state.userRole  ==='TRAVELER'){
			this.evalRequest(EVALUATE_TRAVELER_QUERY, VAR)
		}
		else{
			alert("merci de contactez l'équipe MonKolis")
		}
		
	}

	evalRequest = async (query, variables) => {
		try {
			const response = await axios.post(TRAVEL_ACTIONS_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken
					}
				}
			);

			console.log(response.data)
			
			if(this.state.userRole  ==='REQUESTER' ){
				
				if (response.data.data.requesterProposalEvaluationByTraveler.status === 200) {
					alert('Votre évaluation a bien été prise en compte')
					window.location.reload(false);
				} else {
					alert('Echec lors de l\'evaluation')
				}
			}
			else if (this.state.userRole  ==='TRAVELER'){
				if (response.data.data.requesterProposalEvaluationTravel.status === 200) {
					alert('Votre évaluation a bien été prise en compte')
					window.location.reload(false);
				} else {
					alert('Echec lors de l\'evaluation')
				}
			}
			else{
				alert("merci de contactez l'équipe MonKolis")
			}
			

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
			alert('error in evaluation')
		}
	}

	loadHistoricData() {
		const elements = this.state.dataValue;
		this.setState({
			data: elements.slice(this.state.offset, this.state.offset + this.state.perPage),
			pageCount: Math.ceil(elements.length / this.state.perPage)
		});

	}

	handlePageClick = data => {
		let selected = data.selected;
		let offset = selected * this.state.perPage;

		this.setState({ currentPage: selected, offset: offset }, () => {
			this.loadHistoricData();
		});
	};

	render() {
		return (
			<div className="myprofile">
				<div className="aside section avis testimonials">
					<div className="section-inner">
						<h3 className="heading">Avis à laisser</h3>
						<div className="content">
							<p className="mar-bot0">Vous êtes prié d'évaluer les membres avec qui vous avez échangé des services à la fin de chaque transaction. Les commentaires laisses sont visibles ici et sur leur profil public.</p>
							<br />
							{this.state.data.length === 0 ?
								<div className="avis-alert">
									Vous n'avez pas d'avis à laisser
                    			</div>
								:
								<div className="">
									<p className="mar-bot0 txt-bold">Vous avez {this.state.data.evaluations_waiting.length} avis à laisser.</p>

									<div className="mar-top10">
										<div className="travel mar-bot10">
											<div className="travel-inner">

												{this.state.data.evaluations_waiting.map((value, index) => {
													return <div key={index} >
														<div className="row">
															<div className="col-lg-4 detail1">
																<div className="media mar-bot0">
																	<div className="media-left media-middle">
																		<div className="photo">
																			{
																				value.user_attributes.picture === null ?
																					<p><img src={PHOTO} className="media-object cover rounded" alt="User" /></p> :
																					<p><img src={value.user_attributes.picture} className="media-object cover rounded" alt="User" /></p>
																			}

																		</div>
																	</div>

																	<div className="media-body pad-left10">
																		<p className="color-text2">{value.user_role === 'REQUESTER' ? 'Expéditeur' : 'Transporteur'}</p>

																		<h5> {value.user_attributes.account_type === null || value.user_attributes.account_type === undefined || value.user_attributes.account_type === 'null' || value.user_attributes.account_type === 'undefined' || value.user_attributes.account_type === "Particulier" || value.user_attributes.account_type === "" ? value.user_attributes.given_name :
																			value.user_attributes.account_type_details.company_name === null || value.user_attributes.account_type_details.company_name === undefined || value.user_attributes.account_type_details.company_name === 'undefined' || value.user_attributes.account_type_details.company_name === 'null' || value.user_attributes.account_type_details.company_name === '' ? value.user_attributes.given_name : value.user_attributes.account_type_details.company_name}
																		</h5>

																	</div>
																</div>
															</div>
															<div className="col-lg-5 detail2">
																<h4 className="media-heading">{Moment(value.travel_infos.departure_date).format('D-MM-YYYY')}</h4>
																<p className="mar-bot0"><span className="town">{value.travel_infos.departure_city}</span > <i className="fa fa-long-arrow-right color-text2"><i> <span className="town">{value.travel_infos.arrival_city}</span></i></i></p>
															</div>

															<div className="col-lg-3 detail3 align-right">
																<p className="mar-bot0"><span className="weight">{value.weight_reserved}</span><span>kgs</span></p>
																{console.log("val",value.id_proposal)}
																<button className="btn btn-sm btn-theme2" data-toggle="modal" data-target="#modal-evaluate-trip" onClick={(e) => { this.handleShowEvalModal(e, value.id_proposal, value.user_attributes.given_name,value.user_role ) }} > <i className="fa fa-star"></i> Laisser un avis</button>
															</div>
														</div>

													</div>
													{/* fin du map */ }
												})}


												{/* <Modal show={this.state.showEvalModal} onHide={this.handleCloseEvalModal} centered>

													<Modal.Header closeButton>
														<Modal.Title>Evaluer {this.state.nameTmp}</Modal.Title>
													</Modal.Header>
													<Modal.Body>
														<span className="txt-bold">Choisissez l'adjectif qui résume le mieux votre expérience avec ce membre.</span>
														<br />
														<br />

														<div className="form-group">
															<label for="exampleFormControlTextarea1">Ma note</label>
															<select className="form-control" value={this.state.marks} onChange={this.handleChangeMakrs}>
																<option>5/5 Très bien</option>
																<option>4/5 Bien</option>
																<option>3/5 Moyen</option>
																<option>2/5 Déçu</option>
																<option>1/5 Très déçu</option>
															</select>
															<small id="travelTicket" className="form-text text-muted">
																Ne pas indiquer de <span className="fa fa-1x fa-phone-square"> Numéro de téléphone </span>
                    ou	    <span className="fa fa-1x fa-facebook-square"> de détails de votre Facebook </span>
															</small>
															<textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentaryChange}></textarea>
														 <label for="exampleFormControlTextarea1">Laisser un commentaire</label>
																<textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentaryChange}></textarea> 

														</div>
													</Modal.Body>
													<Modal.Footer>
														<button className="btn btn-outline-primary" onClick={this.handleCloseEvalModal}>
															Annuler
														</button>
														<button className="btn btn-primary" onClick={(e) => { this.handleEvalRequest(e) }}>
															Valider
														</button>
													</Modal.Footer>
												</Modal> */}


												<Modal show={this.state.showEvalModal} onHide={this.handleCloseEvalModal} centered>
													<Modal.Header closeButton>
														<Modal.Title>Evaluer {this.state.nameTmp}!</Modal.Title>
													</Modal.Header>
													<Modal.Body>
														<div className="form-group">
															<label for="exampleFormControlTextarea1">Votre note</label>
															<select className="form-control" value={this.state.marks} onChange={this.handleChangeMakrs}>
																<option>5/5 Très bien</option>
																<option>4/5 Bien</option>
																<option>3/5 Moyen</option>
																<option>2/5 Déçu</option>
																<option>1/5 Très déçu</option>
															</select>
															<label for="exampleFormControlTextarea"> Laisser votre avis </label>
															<textarea className="form-control" id="exampleFormControlTextarea" rows="3" value={this.state.commentary} onChange={this.handleCommentaryChange}></textarea>
														</div>

													</Modal.Body>
													<Modal.Footer>
														<button className="btn btn-outline-primary" onClick={this.handleCloseEvalModal}>Annuler </button>
														<button className="btn btn-primary" onClick={(e) => { this.handleEvalRequest(e) }}> Valider </button>
													</Modal.Footer>
												</Modal>

											</div>
										</div>
										<ReactPaginate
											previousLabel={'«'}
											nextLabel={'»'}
											breakLabel={'...'}
											breakClassName={'break-me'}
											pageCount={this.state.pageCount}
											marginPagesDisplayed={2}
											pageRangeDisplayed={10}
											onPageChange={this.handlePageClick}
											containerClassName={'pagination float-right'}
											pageClassName={'page-item'}
											pageLinkClassName={'page-link'}
											nextClassName={'page-item'}
											previousClassName={'page-item'}
											previousLinkClassName={'page-link'}
											nextLinkClassName={'page-link'}
											activeClassName={'active'}
											disabledClassName={'desabled'}
										/>
									</div>


									{/* <div className="modal fade" id="modal-evaluate-trip" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">

									</div> */}
									<br />
									<br />
									<br />
									{this.state.data.evaluations_send.map((value, index) => {
										return <div className="item" key={index} >

											{value.evaluation !== null ? <div>
												<div className="quote">
													{/* <StarRatings
														rating={value.evaluation.marks}
														starRatedColor="red"
														numberOfStars={5}
														name='rating'
														starDimension='20px'
														starSpacing='0,5px'
													/> */}

													<Rating
														name="hover-feedback"
														value={value.evaluation.marks}
														precision={0.5}
														readOnly
													/>

													<p>

														{value.evaluation.comment}</p>
												</div>
												{value.user_attributes.account_type === null || value.user_attributes.account_type === undefined || value.user_attributes.account_type === 'null' || value.user_attributes.account_type === 'undefined' || value.user_attributes.account_type === "Particulier" || value.user_attributes.account_type === "" ? <p class="source"><span class="name txt-bold">{value.user_attributes.given_name}</span></p> :
													value.user_attributes.account_type_details.company_name === null || value.user_attributes.account_type_details.company_name === undefined || value.user_attributes.account_type_details.company_name === 'undefined' || value.user_attributes.account_type_details.company_name === 'null' || value.user_attributes.account_type_details.company_name === '' ? value.user_attributes.given_name : value.user_attributes.account_type_details.company_name} <p><span class="date"> {value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}, {Moment(value.evaluation.evaluation_date).format('MMM-YYYY')}</span></p>
												{/* <p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}, {Moment(value.evaluation.evaluation_date).format('MMM-YYYY')}</span></p> */}
											</div>
												: <div>
													Vous n'avez pas laissé de commentaire
												<p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}</span></p>
												</div>
											}

										</div>
									})}

									<ReactPaginate
										previousLabel={'«'}
										nextLabel={'»'}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={this.state.pageCount}
										marginPagesDisplayed={2}
										pageRangeDisplayed={10}
										onPageChange={this.handlePageClick}
										containerClassName={'pagination float-right'}
										pageClassName={'page-item'}
										pageLinkClassName={'page-link'}
										nextClassName={'page-item'}
										previousClassName={'page-item'}
										previousLinkClassName={'page-link'}
										nextLinkClassName={'page-link'}
										activeClassName={'active'}
										disabledClassName={'desabled'}
									/>

								</div>
							}
						</div>
					</div>
				</div>
			</div>

		);
	}
}
export default withRouter(MyAvislaisses);
