import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Terms extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Conditions générales d'utilisation | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="terms">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/legales" className="list-group-item list-group-item-action">Mentions
                                            légales</a>
                                        <a href="/terms" className="list-group-item list-group-item-action active">Conditions
                                            générales</a>
                                        <a href="/privacy" className="list-group-item list-group-item-action">Politique
                                            de Confidentialité</a>
                                        <a href="/cookies" className="list-group-item list-group-item-action">Utilisation
                                            des cookies</a>
                                        <a href="/prohibited" class="list-group-item list-group-item-action">Produits interdits</a>
                                    </div>
                                </div>

                                <div className="col-sm-9">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Conditions générales d'utilisation</h1>
                                            <p>Version applicable à compter du 11 juin 2020</p>
                                            <h2 className="f-heading-three"> 1. Objet </h2>
                                            <p> La société TAMODE SAS (ci-après, <b>« MonKolis »</b>) édite une
                                                plateforme de cobagage (ci-après, « La Plateforme ») accessible sur un
                                                site internet notamment à l’adresse www.monkolis.com, sous forme
                                                d’application mobile ou sous divers réseaux sociaux et destinée à mettre
                                                en relation des transporteurs/voyageurs et des expéditeurs désirant
                                                recevoir ou envoyer des colis.</p>
                                            <p> Les présentes conditions générales d’utilisation ont pour objet
                                            d’encadrer l’accès et les modalités d’utilisation de la Plateforme. Nous
                                            vous invitons à en prendre attentivement connaissance. Vous comprenez et
                                            reconnaissez que MonKolis n’est partie à aucun accord, contrat ou
                                            relation contractuelle, de quelque nature que ce soit, conclu entre les
                                            Membres de sa Plateforme. En tant que plateforme de mise en relation,
                                            MonKolis est donc uniquement un tiers à la relation entre ses
                                                utilisateurs.</p>
                                            <p> En cliquant sur le bouton « Connexion avec Facebook », « Connexion avec
                                            Google » ou « Inscription avec un email », vous reconnaissez avoir pris
                                            connaissance et accepter l’intégralité des présentes conditions
                                                générales d’utilisation.</p>


                                            <span className="txt-bold">	2. Définitions </span> <br />
                                            <p> Dans les présentes,</p>

                                            <p><span className="txt-bold">« Annonce »</span> : désigne une annonce de
                                                transport déposée par un Transporteur sur la Plateforme; </p>

                                            <p><span className="txt-bold">« CGU »</span> : désigne les présentes
                                                Conditions Générales d’Utilisation ; </p>

                                            <p><span className="txt-bold">« Code de Dépôt »</span> : a la signification
                                                qui lui est donné à l’article 4.4 ci-dessous ; </p>

                                            <p><span className="txt-bold">« Code de Livraison »</span> : a la
                                                signification qui lui est donné à l’article 4.4 ci-dessous ; </p>

                                            <p><span className="txt-bold">« Colis »</span> : désigne le(s) bien(s)
                                                destiné(s) à être transporté(s). Les membres de la Plateforme doivent
                                                s’engager à n’expédier que des objets licites, et n’ayant aucun
                                                caractère de dangerosité ;</p>

                                            <p><span className="txt-bold">« Compte »</span> : désigne le compte qui doit
                                                être créé pour pouvoir devenir Membre et accéder à certains services
                                                proposés par la Plateforme ;</p>
                                            <p><span className="txt-bold">« Compte Facebook »</span> : a la
                                                signification qui lui est donné à l’article 3.4 ci-dessous ;</p>

                                            <p><span className="txt-bold">« Confirmation de dépôt »</span> : a la
                                                signification qui lui est donné à l’article 4.4 ci-dessous ; </p>

                                            <p><span className="txt-bold">« Confirmation de livraison »</span> : a la
                                                signification qui lui est donné à l’article 4.4 ci-dessous ; </p>

                                            <p><span className="txt-bold">« Confirmation de Réservation »</span> :
                                                désigne l’opération par laquelle est confirmée une réservation sur la
                                                Plateforme tel que détaillé dans l’article 4.2 ;</p>

                                            <p><span className="txt-bold">« Destinataire »</span> : désigne la personne
                                                choisie par l’Expéditeur pour récupérer le Colis sur le Lieu de
                                                livraison ;</p>

                                            <p><span className="txt-bold">« Espace »</span> : désigne l'espace réservée
                                                par un Expéditeur dans les capacités totales mentionnées par un
                                                Transporteur dans son Annonce. L’Espace peut être décliné en unité tels
                                                que le poids (kg), le volume (litres) ou en contenant tels que les
                                                barriques, cartons, sacs ou en une combinaison des deux tels que les
                                                barriques de 150 litres, les cartons de 30 litres. D’autres déclinaisons
                                                d’Espace non mentionnés dans les exemples listés pourront être mis à
                                                disposition sur la Plateforme ;</p>

                                            <p><span className="txt-bold">« Frais de réservation »</span> : a la
                                                signification qui lui est donnée à l’article 5.2 ci-dessous ;</p>

                                            <p><span className="txt-bold">« Frais de transport »</span> : désigne, pour
                                                un Trajet donné, la somme d’argent demandée par le Transporteur et
                                                acceptée par l'Expéditeur au titre de la rémunération du Transporteur
                                                pour le transport du Colis ;</p>

                                            <p><span className="txt-bold">« Lieu de dépôt »</span> : désigne le lieu
                                                convenu pour la remise du Colis au Transporteur ;</p>

                                            <p><span className="txt-bold">« Lieu de livraison »</span> : désigne le lieu
                                                convenu pour la livraison du Colis au Destinataire ; </p>

                                            <p><span className="txt-bold">« Membre »</span> : désigne toute personne
                                                physique ou morale ayant créé un Compte sur la Plateforme ;</p>

                                            <p><span className="txt-bold">« MonKolis »</span> : a la signification qui
                                                lui est donnée à l’article 1 ci-dessus ;</p>

                                            <p><span className="txt-bold">« Plateforme»</span> : a le sens qui lui est
                                                donné à l’article 1, ci-dessus ;</p>

                                            <p><span className="txt-bold">« Réservation »</span> : a le sens qui lui est
                                                donné à l’article 4.2, ci-dessous ;</p>

                                            <p><span className="txt-bold">« Services »</span> : désigne l’ensemble des
                                                services rendus par MonKolis par l’intermédiaire de la Plateforme ;</p>

                                            <p><span className="txt-bold">« Trajet » »</span> désigne le lieu de départ
                                                et le lieu darrivée proposé par un Transporteur pour le transport de
                                                Colis ;</p>

                                            <p><span className="txt-bold">« Transporteur »</span> : désigne toute
                                                personne physique ou morale ayant déposé une Annonce sur la Plateforme.
                                            </p>

                                            <h2 className="f-heading-three">3. Inscriptions sur MonKolis et création de
                                                compte</h2>

                                            <h3 className="f-heading-four">3.1. Condition d'inscription </h3>
                                            <p> L’utilisation du site MonKolis est réservée aux personnes physiques
                                            âgées de 18 ans ou plus et aux personnes morales. Toute inscription sur
                                            la Plateforme par une personne mineure est strictement interdite. En
                                            accédant, utilisant ou vous inscrivant sur la Plateforme, vous déclarez
                                                et garantissez avoir 18 ans ou plus.</p>

                                            <h3 className="f-heading-four">3.2. Création de compte</h3>
                                            <p> La Plateforme permet aux Membres de publier des Annonces pour le
                                            transport de Colis et consulter des Annonces ainsi que d’interagir entre
                                            eux pour la réservation d'Espace. Vous pouvez consulter les Annonces
                                            même si vous n’êtes pas inscrit sur la Plateforme. En revanche, vous ne
                                            pouvez ni publier une Annonce de transport de Colis ni réserver
                                            d'Espace, sans avoir, au préalable, créé un Compte et être devenu
                                                Membre.</p>

                                            <p className="txt-bold">Pour créer votre Compte, vous pouvez :</p>
                                            <p> (i) Soit remplir l’ensemble des champs obligatoires figurant sur le
                                                formulaire d’inscription ;</p>
                                            <p> (ii) Soit vous connecter, via notre Plateforme, à votre Compte Facebook
                                            ou Google. En utilisant une telle fonctionnalité, vous comprenez que
                                            MonKolis aura accès, publiera sur la Plateforme et conservera certaines
                                                informations de votre Compte Facebook ou Google. </p>
                                            <p> Pour vous inscrire sur la Plateforme, vous devez avoir lu et accepter
                                                les présentes CGU ainsi que la <a href="/privacy"
                                                    className="link color-blue">Politique
                                                    de Confidentialité</a>.</p>
                                            <p> A l’occasion de la création de votre Compte, peu importe la méthode
                                            choisie pour ce faire, vous vous engagez à fournir des informations
                                            personnelles exactes et conformes à la réalité et à les mettre à jour,
                                            par l’intermédiaire de votre profil ou en en avertissant MonKolis, afin
                                            d’en garantir la pertinence et l’exactitude tout au long de votre
                                                relation contractuelle avec MonKolis.</p>
                                            <p> En cas d’inscription par email, vous vous engagez à garder secret le mot
                                            de passe choisi lors de la création de votre Compte et à ne le
                                            communiquer à personne. En cas de perte ou divulgation de votre mot de
                                            passe, vous vous engagez à en informer sans délai MonKolis. Vous êtes
                                            seul responsable de l’utilisation faite de votre Compte par un tiers,
                                            tant que vous n’avez pas expressément notifié MonKolis de la perte,
                                            l’utilisation frauduleuse par un tiers ou la divulgation de votre mot de
                                                passe à un tiers.</p>
                                            <p> Vous vous engagez à ne pas créer ou utiliser, sous votre propre identité
                                                ou celle d’un tiers, d’autres Comptes que celui initialement créé.</p>

                                            <h3 className="f-heading-four">3.3. Vérification</h3>
                                            <p> MonKolis peut, à des fins de transparence, d’amélioration de la
                                            confiance, ou de prévention ou détection des fraudes, mettre en place un
                                            système de vérification de certaines des informations que vous
                                            fournissez sur votre profil. C’est notamment le cas lorsque vous
                                            renseignez votre numéro de téléphone ou nous fournissez une pièce
                                                d’identité.</p>
                                            <p> Vous reconnaissez et acceptez que toute référence sur la Plateforme à
                                            des informations dites « vérifiées » ou tout terme similaire, signifie
                                            uniquement qu’un Membre a réussi avec succès la procédure de
                                            vérification existante sur la Plateforme afin de vous fournir davantage
                                            d’informations sur le Membre avec lequel vous envisagez de voyager.
                                            MonKolis ne garantit ni la véracité, ni la fiabilité, ni la validité de
                                                l’information ayant fait l’objet de la procédure de vérification.</p>

                                            <h2 className="f-heading-three">4. Utilisation des Services</h2>

                                            <h3 className="f-heading-four">4.1. Publication des Annonces de Transport de
                                                Colis</h3>
                                            <p> En tant que Membre, et sous réserve que vous remplissiez les conditions
                                            ci-dessous, vous pouvez créer et publier des Annonces sur la Plateforme
                                            en indiquant des informations relative au Trajet que vous comptez
                                            effectuer (dates/heures et lieux de départ et d’arrivée, Espace
                                                disponible, moyen de transport, Frais de transport, etc.).</p>
                                            <p> Lors de la publication de votre Annonce, vous pouvez indiquer le moyen
                                            de transport que vous utiliserez pour acheminer le Colis. Les moyens de
                                            transport proposés sur la Plateforme sont l'avion, le bateau, la voiture
                                                et le train. </p>
                                            <p> Vous reconnaissez être le seul responsable du contenu de l’Annonce que
                                            vous publiez sur la Plateforme. En conséquence, vous déclarez et
                                            garantissez l’exactitude et la véracité de toute information contenue
                                            dans votre Annonce et vous engagez à effectuer le Trajet selon les
                                                modalités décrites dans votre Annonce.</p>
                                            <p>Votre Annonce sera publiée sur la Plateforme et donc visible des Membres
                                            et de tous visiteurs, même non Membre, effectuant une recherche sur la
                                            Plateforme ou sur le site internet des partenaires de MonKolis. MonKolis
                                            se réserve la possibilité, à sa seule discrétion et sans préavis, de ne
                                            pas publier ou retirer, à tout moment, toute Annonce qui ne serait pas
                                            conforme aux CGU ou qu’elle considérerait comme préjudiciable à son
                                                image, celle de la Plateforme ou celle des Services.</p>

                                            <h3 className="f-heading-four"> 4.2. Réservation d'Espace, de Barrique ou de
                                                Carton</h3>

                                            <p> MonKolis a mis en place un système de réservation d'Espace en ligne (la
                                                « Réservation ») pour les Annonces proposées sur la Plateforme.</p>

                                            <p> Lorsqu’un Expéditeur est intéressé par une Annonce, il peut effectuer
                                            une demande de Réservation en ligne. Cette demande de Réservation est
                                            (i) soit acceptée automatiquement (si le Transporteur a choisi cette
                                            option lors de la publication de son Annonce), (ii) soit acceptée
                                            manuellement par le Transporteur. Au moment de la Réservation,
                                            l'Expéditeur procède au paiement en ligne du montant des Frais de
                                            transport et des Frais de réservation afférents, le cas échéant. Après
                                            vérification du paiement par MonKolis et validation de la demande de
                                            Réservation par le Transporteur, le cas échéant, l'Expéditeur reçoit une
                                                confirmation de réservation (la « Confirmation de Réservation »).</p>
                                            <p> Si vous êtes un Transporteur et que vous avez choisi de gérer vous-mêmes
                                            les demandes de Réservation lors de la publication de votre Annonce,
                                            vous êtes tenu de répondre à toute demande de Réservation dans un
                                            certain délai. A défaut, la demande de Réservation expire
                                            automatiquement et l’Expéditeur est remboursé de l’intégralité des
                                            sommes versées au moment de la demande de Réservation, le cas
                                                échéant.</p>
                                            <p> A compter de la Confirmation de la Réservation, MonKolis vous transmet
                                            les coordonnées téléphoniques du Transporteur (si vous êtes Expéditeur)
                                            ou de l'Expéditeur ainsi que le Code de Dépôt (si vous êtes
                                            Transporteur). Vous êtes désormais seuls responsables de l’exécution du
                                                contrat vous liant à l’autre Membre.</p>

                                            <h3 className="f-heading-four">4.3. Caractère nominatif de la réservation
                                                d'Espace </h3>
                                            <p> Toute utilisation des Services, que ce soit en qualité d'Expéditeur ou
                                            de Transporteur, est nominative. Le Transporteur comme l'Expéditeur
                                                doivent correspondre à l’identité communiquée à MonKolis.</p>
                                            <p> Il est interdit de publier une Annonce pour un Transporteur autre que
                                            vous-même. Il est également interdit d’effectuer une réservation pour un
                                                Expéditeur autre que vous-même.</p>

                                            <h3 className="f-heading-four">4.4. Système d'avis </h3>
                                            <p> MonKolis a mis en place un système de code qui permet de confirmer la
                                            transmission du Colis de l’Expéditeur au Transporteur et du Transporteur
                                                au Destinataire.</p>
                                            <p> A compter de la Confirmation de la Réservation, MonKolis transmet les
                                            coordonnées téléphoniques de l'Expéditeur ainsi qu’un Code de Dépôt au
                                            Transporteur. Lors de la remise du Colis au Transporteur par
                                            l’Expéditeur, ce dernier récupère le Code de Dépôt qui lui est remis par
                                            le Transporteur. Ainsi l’Expéditeur pourra Confirmer le Dépôt du Colis
                                                en entrant le Code de Dépôt à l’endroit indiqué de son Compte.</p>
                                            <p> A compter de la Confirmation de Dépôt, MonKolis transmet un Code de
                                            Livraison à l’Expéditeur. Ce dernier le communique au Destinataire en
                                            charge de récupérer Son Colis sur le Lieu de Livraison. Lors de la
                                            livraison du Colis au Destinataire par le Transporteur, ce dernier
                                            récupère le Code de Livraison qui lui est remis par le Destinataire.
                                            Ainsi le Transporteur pourra Confirmer la Livraison du Colis en entrant
                                                le Code de Livraison à l’endroit indiqué de son Compte.</p>
                                            <h3 className="f-heading-four">4.5. Système d'avis</h3>
                                            <p>MonKolis vous encourage à laisser un avis sur un Transporteur (si vous
                                            êtes Expéditeur) qui a transporté vos Colis ou un Expéditeur (si vous
                                                êtes Transporteur) dont vous avez transporté les colis.</p>
                                            <p>Votre avis, ainsi que celui laissé par un autre Membre à votre égard ne
                                            sont visibles et publiés sur la Plateforme qu’immédiatement après que
                                                vous ayez, tous les deux, laissés un avis.</p>
                                            <p>Vous reconnaissez et acceptez que MonKolis se réserve la possibilité de
                                            ne pas publier ou supprimer tout avis, toute question, tout commentaire
                                            ou toute réponse dont elle jugerait le contenu contraire aux présentes
                                                CGU.</p>
                                            <p>MonKolis se réserve la possibilité de suspendre votre Compte, limiter
                                            votre accès aux Services ou résilier les présentes CGU dans le cas où
                                            (i) vous avez reçu au moins trois avis et (ii) la moyenne des avis que
                                                vous avez reçus est égale ou inférieure à 3.</p>

                                            <h3 className="f-heading-four">4.6. Mise à disposition d'un modèle de
                                                Contrat</h3>
                                            <p>Dans le cadre de la procédure de transaction en ligne, MonKolis met un
                                            contrat type à disposition des Membres. Ce contrat type doit être
                                            complété et signé par les Membres. Ce dernier inclut des informations
                                            sur la transaction telles que la nature et le contenu du colis, les
                                            Frais de transport, le trajet ainsi que tout autre élément récapitulant
                                            les différents éléments de la transaction conclue conformément aux
                                                conditions développées dans les CGU.</p>
                                            <p>Il s’agit d’un support mis à disposition par MonKolis à titre purement
                                            indicatif, dans le but d'assister les Membres et de faciliter les
                                            transactions. La responsabilité de MonKolis ne peut être retenue dans le
                                            cadre de la mise en œuvre de ce contrat type et son contenu, de même que
                                            toute condition d'emploi de ce dernier, chaque transaction pouvant
                                            nécessiter un support spécifique adapté et, le cas échéant, un conseil
                                                approprié.</p>
                                            <p>Les Membres sont invités à modifier le contrat type, si besoin, pour le
                                            rendre conforme à la transaction effectivement réalisée. Les Membres
                                            sont invités, à cet égard, à prendre conseil auprès d’un professionnel
                                            en cas de doute. La transaction entre Membres mis en relation par le
                                            biais de MonKolis ne peut se faire qu'au moyen de la procédure indiquée
                                            au présent article. Tout Membre demeure libre de choisir le Membre avec
                                                lequel il entend conclure une transaction.</p>

                                            <h2 className="f-heading-three">5. Conditions financières</h2>
                                            <p>L’accès et l’inscription à la Plateforme, de même que la recherche, la
                                            consultation et la publication d’Annonces sont gratuits. En revanche, la
                                                Réservation est payante dans les conditions décrites ci-dessous.</p>

                                            <h3 className="f-heading-four">5.1. Frais de transport</h3>
                                            <p> Dans le cadre d’une Annonce, le montant des Frais de transport est
                                            déterminé par vous, en tant que Transporteur, sous votre seule
                                            responsabilité. Le montant des Frais de transport que vous fixez par
                                                unité d’Espace n’est pas limité. </p>
                                            <p>Toutefois, pour les particuliers ayant des Annonces occasionnelles qui
                                            correspondent à des voyages réels qu’ils effectuent, vous vous engagez à
                                            limiter le montant des Frais de Transport que vous demandez à vos
                                            Expéditeurs aux frais que vous supportez réellement pour effectuer le
                                            Transport de Colis. A défaut, vous supporterez seul les risques de
                                            requalification de l’opération effectuée par l’intermédiaire de la
                                                Plateforme.</p>

                                            <h3 className="f-heading-four">5.2. Frais de réservation</h3>
                                            <p> MonKolis peut prélever, en contrepartie de l’utilisation de la
                                            Plateforme, des commissions à ses utilisateurs (ci-après, les “Frais de
                                            réservation”). L’utilisateur sera informé avant toute application des
                                                Frais de réservation le cas échéant.</p>
                                            <p> Les Frais de réservation nous permettent de faire fonctionner notre
                                            Plateforme, de financer l’assurance des Colis, de garantir la présence
                                            de notre équipe ou de poursuivre l’amélioration de la Plateforme pour
                                            vous donner toujours plus de satisfaction et répondre toujours mieux à
                                                vos besoins.</p>
                                            <p> Le montant des Frais de réservation pour une Réservation est compris
                                            entre 0 et 25 % (avec un minimum de 1€) des Frais de Réservation et est
                                            déterminé en fonction du montant total des Frais de transport pour une
                                            Réservation. Les Expéditeurs peuvent prendre connaissance de ces Frais
                                            de réservation sur la page de paiement avant de régler leur
                                                réservation.</p>

                                            <p> MonKolis se réserve le droit de modifier à tout moment les modalités de
                                            calcul des Frais de réservation. Ces modifications n’auront pas d’effet
                                            sur les montants des frais des réservations effectuées par les Membres
                                                avant la date de prise d’effet de ces modifications.</p>
                                            <p>Vous reconnaissez et acceptez que MonKolis puisse, à son entière
                                            discrétion, arrondir au chiffre inférieur ou supérieur les Frais de
                                                transport et les Frais de Réservation.</p>

                                            <h3 className="f-heading-four">5.3. Modalités de paiement et de reversement
                                                de la Participation aux Frais au Transporteur</h3>
                                            <h4 className="f-heading-four">5.3.1. Mandat d'encaissement</h4>
                                            <p> En utilisant la Plateforme en tant que Transporteur pour des Annonces,
                                            vous confiez à MonKolis un mandat d’encaissement du montant des Frais de
                                                transport en votre nom et pour votre compte.</p>
                                            <p>Par conséquent, dans le cadre d'une Annonce, et après acceptation
                                            manuelle ou automatique de la Réservation, MonKolis encaisse la totalité
                                            de la somme versée par l'Expéditeur. Les Frais de transport reçus par
                                            MonKolis sont déposés sur un compte dédié au paiement des Transporteurs.
                                            </p>
                                            <p>Vous reconnaissez et acceptez qu'aucune des sommes perçues par MonKolis
                                            au nom et pour le compte du Transporteur n'emporte droit à intérêts.
                                            Vous acceptez de répondre avec diligences à toute demande de MonKolis et
                                            plus généralement de toute autorité administrative ou judiciaire
                                            compétente en particulier en matière de prévention ou la lutte contre le
                                            blanchiment. Notamment, vous acceptez de fournir, sur simple demande,
                                                tout justificatif d'adresse et/ou d'identité utile.</p>
                                            <p>En l'absence de réponse de votre part à ces demandes, MonKolis pourra
                                            prendre toute mesure qui lui semblera appropriée notamment le gel des
                                            sommes versées et/ou la suspension de votre Compte et/ou la résiliation
                                                des présentes CGU.</p>

                                            <h4 className="f-heading-four">5.3.2. Versement des Frais de transport au
                                                Transporteur</h4>
                                            <p>Les Expéditeurs disposent d'un délai de 24 heures, dès la livraison du
                                            Colis, pour effectuer une réclamation à MonKolis. En l'absence de
                                            contestation de leur part dans cette période, MonKolis considère la
                                                Confirmation de Livraison comme acquise.</p>
                                            <p>A compter de la confirmation acquise de l’ensemble des expéditions liées
                                            à votre Annonce, vous disposez, en tant que Transporteur, sur votre
                                            compte d’un crédit exigible qui correspond au montant total des Frais de
                                            transport payé par les Expéditeurs au moment de la confirmation de la
                                                Réservation.</p>
                                            <p>Vous avez la possibilité, en tant que Transporteur, de donner
                                            instructions à MonKolis de vous verser l’argent sur votre compte
                                            bancaire (en renseignant sur votre Compte, au préalable, vos coordonnées
                                            bancaires). L’ordre de virement à votre nom sera transmis le premier
                                            jour ouvré suivant la mise à disposition sur votre profil des sommes
                                            concernées (sous réserve que MonKolis dispose de vos coordonnées
                                                bancaires).</p>

                                            <h2 className="f-heading-three">6. Finalité commerciale et non-commerciale
                                                de l’utilisation de la Plateforme</h2>
                                            <p>Vous vous engagez à n’utiliser les Services et la Plateforme que pour
                                            être mis en relation, à titre non professionnel et non commercial, avec
                                            des personnes souhaitant partager la place dans leur valise durant leur
                                            Trajet ou réserver un Espace dans le cadre d’un Trajet maritime ou
                                                aérien.</p>
                                            <p>Vous vous engagez à fournir à MonKolis, sur simple demande de la part de
                                            celle-ci, une copie de votre justificatif de transport et/ou tout autre
                                            document de nature à attester que vous êtes autorisé à effectuer votre
                                                trajet.</p>
                                            <p>En tant que particulier, vous vous engagez à n’utiliser et la Plateforme
                                            que pour être mis en relation, à titre non professionnel et non
                                            commercial, avec des personnes souhaitant envoyer des colis sur un
                                            Trajet en Cobagage. Ainsi, en tant que Transporteur, vous vous engagez à
                                            ne pas demander des Frais de transport supérieurs aux frais que vous
                                            supportez réellement et susceptible de vous faire générer un
                                                bénéfice.</p>
                                            <p>Les professionnels (auto-entrepreneur, entreprise, association) dont
                                            l’activité sur la Plateforme est commerciale avec pour but de leur faire
                                            générer du bénéfice sont libres de fixer les Frais de transport
                                                correspondant à leur stratégie commerciale.</p>
                                            <p>Dans tous les cas, il appartient aux Membres de déclarer les revenus
                                            générés par l’utilisation de la plateforme selon les lois et règlements
                                            en vigueur et de s’acquitter de l’ensemble des taxes et impôts résultant
                                                du transport de Colis le cas échéant.</p>

                                            <h2 className="f-heading-three">7. Politique d'annulation'</h2>
                                            <h3 className="f-heading-four">7.1. Modalités de remboursement en cas
                                                d'annulation</h3>
                                            <p>Seules les Annonces font l’objet de la présente politique d’annulation,
                                            MonKolis n’offrant aucune garantie, de quelque nature que ce soit, en
                                                cas d’annulation, pour quelque raison que ce soit. </p>
                                            <p>L’annulation d’une Annonce ou d’une Réservation par le Transporteur ou
                                            l’Expéditeur après la Confirmation de Réservation est soumise aux
                                            stipulations ci-après :
                                            </p><br />
                                            <strong>En cas d’annulation imputable au Conducteur</strong>
                                            <p>le Passager est remboursé de la totalité de la somme qu’il a versée
                                            (c’est-à-dire la Participation aux Frais et les Frais de Service
                                            afférents). C’est notamment le cas lorsque le Conducteur annule un
                                            Trajet en Covoiturage ou ne se rend pas au point de rendez-vous au plus
                                                tard 15 minutes après l’horaire convenu ;</p>
                                            <strong>En cas d’annulation imputable à l’Expéditeur : </strong>
                                            <ul className="mar-left10">
                                                <li>Si l’Expéditeur annule plus de 72 heures avant l’heure prévue pour
                                                la clôture des réservations telle que mentionnée dans l’Annonce,
                                                l’Expéditeur est remboursé du montant des Frais de transport. Les
                                                Frais de réservation demeurent acquis et le Transporteur ne reçoit
                                                aucune somme de quelque nature que ce soit ;
                                                </li>
                                                <li>Si l’Expéditeur annule moins de 72 heures et plus 24 heures avant
                                                l’heure prévue pour la clôture des réservations telle que mentionnée
                                                dans l’Annonce, l’Expéditeur est remboursé à hauteur de la moitié
                                                des Frais de transport, les Frais de réservation demeurent acquis à
                                                MonKolis et le Transporteur reçoit 50% des Frais de transport;
                                                </li>
                                                <li>Si l’Expéditeur annule moins de 24 heures avant l’heure prévue pour
                                                la clôture des réservations telle que mentionnée dans l’Annonce,
                                                l’Expéditeur ne reçoit aucun remboursement. les Frais de réservation
                                                demeurent acquis à MonKolis et le Transporteur reçoit l’intégralité
                                                des Frais de transport.
                                                </li>
                                                <li>Si l’Expéditeur annule après l’heure prévue pour la clôture des
                                                réservations, telle que mentionnée dans l’Annonce, ou s’il ne se
                                                présente pas au lieu de rendez-vous pour le dépôt du Colis au plus
                                                tard dans un délai de 15 minutes à compter de l’heure convenue,
                                                aucun remboursement n’est effectué. Le Transporteur est dédommagé à
                                                hauteur de la totalité des Frais de transport et les Frais de
                                                réservation sont conservés par MonKolis.
                                                </li>
                                            </ul>
                                            <p>Lorsque l’annulation intervient avant le départ et du fait de
                                            l’Expéditeur, l’Espaces annulé par l’Expéditeur pour ladite Annonce est
                                            de plein droit remis à la disposition d’autres Expéditeurs pouvant les
                                            réserver en ligne et en conséquence soumises aux conditions des
                                                présentes CGU.</p>
                                            <p>MonKolis apprécie à sa seule discrétion, sur la base des éléments à sa
                                            disposition, la légitimité des demandes de remboursement qu’elle
                                                reçoit.</p>

                                            <h3 className="f-heading-four">7.2. Droit de retraction</h3>
                                            <p>En acceptant les présentes CGU, vous acceptez expressément que le Contrat
                                            entre vous et MonKolis consistant en la mise en relation avec un autre
                                            Membre soit exécuté avant l’expiration du délai de rétractation dès la
                                            Confirmation de Réservation et renoncez expressément à votre droit de
                                            rétraction, conformément aux dispositions de l’article L.221-28 du Code
                                                de la consommation.</p>

                                            <h2 className="f-heading-three">8. Comportement des utilisateurs et des
                                                Membres de la Plateforme</h2>
                                            <h3 className="f-heading-four">8.1. Engagement de tous les utilisateurs de
                                                la Plateforme</h3>
                                            <p>Vous reconnaissez être seul responsable du respect de l’ensemble des
                                            lois, règlements et obligations applicables à votre utilisation de la
                                                Plateforme.</p>
                                            <p>Par ailleurs, en utilisant la Plateforme, vous vous engagez à :
                                                <ul className="mar-left10">
                                                    <li><b>(i)</b> ne transmettre à MonKolis (notamment lors de la
                                                        création ou la mise à jour de votre Compte) ou aux autres
                                                        Membres aucune information fausse, trompeuse, mensongère ou
                                                        frauduleuse ;
                                                    </li>
                                                    <li><b>(ii)</b> ne tenir aucun propos, n’avoir aucun comportement ou
                                                        ne publier sur la Plateforme aucun contenu à caractère
                                                        diffamatoire, injurieux, obscène, pornographique, vulgaire,
                                                        offensant, agressif, déplacé, violent, menaçant, harcelant,
                                                        raciste, xénophobe, à connotation sexuelle, incitant à la
                                                        violence, à la discrimination ou à la haine, encourageant les
                                                        activités ou l’usage de substances illégales ou, plus
                                                        généralement, contraires aux finalités de la Plateforme, de
                                                        nature à porter atteinte aux droits de MonKolis ou d’un tiers ou
                                                        contraires aux bonnes mœurs ;
                                                    </li>
                                                    <li><b>(iii)</b> ne pas porter atteinte aux droits et à l’image de
                                                        MonKolis, notamment à ses droits de propriété intellectuelle ;
                                                    </li>
                                                    <li><b>(iv)</b> ne pas ouvrir plus d’un Compte sur la Plateforme et
                                                        ne pas ouvrir de Compte au nom d’un tiers ;
                                                    </li>
                                                    <li><b>(v)</b> ne pas tenter de contourner le système de réservation
                                                        en ligne de la Plateforme, notamment en tentant de communiquer à
                                                        un autre Membre vos coordonnées afin de réaliser la réservation
                                                        en dehors de la Plateforme et ne pas payer les Frais de
                                                        réservation ;
                                                    </li>
                                                    <li><b>(vi)</b> ne pas contacter un autre Membre, notamment par
                                                        l’intermédiaire de la Plateforme, à une autre fin que celle de
                                                        définir les modalités du transport de Colis ;
                                                    </li>
                                                    <li><b>(vii)</b> ne pas accepter ou effectuer un paiement en dehors
                                                        de la Plateforme ;
                                                    </li>
                                                    <li><b>(viii)</b> vous conformer aux présentes CGU et à la Charte de
                                                        Confidentialité.
                                                    </li>
                                                </ul>
                                            </p>

                                            <h3 className="f-heading-four">8.2. Engagements des transporteurs</h3>
                                            <p>En outre, lorsque vous utilisez la Plateforme en tant que Transporteur,
                                                vous vous engagez à :</p>
                                            <ul className="mar-left10">
                                                <li><b>(i)</b> respecter l’ensemble des lois et réglementations
                                                    applicables au transport de Colis, notamment ;
                                                </li>
                                                <li><b>(ii)</b> vous assurez que les Colis que vous transportez sont
                                                    couverts à minima contre les risques de perte ou dégradation par
                                                    votre assurance ou celle de l’entreprise en charge du transport ;
                                                </li>
                                                <li><b>(iii)</b> publier des Annonces correspondant uniquement à des
                                                    Trajets réellement envisagés ;
                                                </li>
                                                <li><b>(iv)</b> respecter les horaires et lieux convenus avec les autres
                                                    Membres tels que les lieux de rendez-vous pour le dépôt et la
                                                    livraison des Colis ;
                                                </li>
                                                <li>(v) ne pas faire réserver plus d’Espace que celui indiqué dans
                                                l’Annonce;
                                                </li>
                                                <li><b>(vi)</b> communiquer à MonKolis lorsqu’il vous en fait la
                                                    demande, une copie de votre titre de voyage si vous êtes particulier
                                                    ou de votre titre transport si vous êtes professionnel
                                                    (auto-entrepreneur, entreprise, association), de votre passeport ou
                                                    tout autre document attestant de votre capacité à transporter des
                                                    Colis en tant que Transporteur sur la Plateforme ;
                                                </li>
                                                <li><b>(vii)</b> informer sans délais les Expéditeurs en cas
                                                    d’empêchement ou de modification de l’horaire indiqué dans votre
                                                    Annonce ;
                                                </li>
                                                <li><b>(viii)</b> attendre les Expéditeurs et les Destinataires sur les
                                                    lieux de rencontre convenus pour le dépôt et la livraison des Colis
                                                    au moins 30 minutes au-delà de l’heure convenue ;
                                                </li>
                                            </ul>

                                            <h3 className="f-heading-four">8.3. Engagements des Expéditeurs</h3>
                                            <p>Lorsque vous utilisez la Plateforme en tant que Passager dans le cadre
                                            d’un Trajet en Covoiturage, vous vous engagez à :
                                                <ul className="mar-left10">
                                                    <li><b>(i)</b> adopter une attitude convenable et rester courtois
                                                        tout au long de la transaction ;
                                                    </li>
                                                    <li><b>(ii)</b> en cas d’empêchement pour le Dépôt ou la Livraison
                                                        du Colis, en informer sans délai le Transporteur ;
                                                    </li>
                                                    <li><b>(iii)</b> attendre le Conducteur sur le Lieu de Dépôt ou le
                                                        Lieu de Livraison convenu au moins 30 minutes au-delà de l’heure
                                                        convenue ;
                                                    </li>
                                                    <li><b>(iv)</b> Communiquer à MonKolis lorsqu’il vous en fait la
                                                        demande, votre carte d’identité ou tout document de nature à
                                                        attester de votre identité ;
                                                    </li>
                                                    <li><b>(v)</b> ne mettre dans son Colis, aucun objet, marchandise,
                                                        substance, animal dont la nature, la possession ou le transport
                                                        est contraire aux dispositions légales en vigueur ;
                                                    </li>
                                                    <li><b>(vi)</b> mettre à disposition du Transporteur et de toute
                                                        autorité qui le solliciterait tout document de nature à
                                                        justifier la possession des éléments contenus dans le Colis ;
                                                    </li>
                                                    <li><b>(vii)</b> vous assurer d’être joignable par téléphone par le
                                                        Transporteur, au numéro enregistré sur votre profil, notamment
                                                        au point de rendez-vous.
                                                    </li>
                                                </ul>
                                            </p>

                                            <h2 className="f-heading-three">9. Suspension de comptes, limitation d’accès
                                                et résiliation</h2>
                                            <p>Vous avez la possibilité de mettre fin à votre relation contractuelle
                                            avec MonKolis à tout moment, sans frais et sans motif. Pour cela, il
                                            vous suffit de vous rendre dans l’onglet « Fermeture de compte » de
                                                votre page Profil.</p>
                                            <p>En cas de (i) violation de votre part des présentes CGU, notamment de vos
                                            obligations en tant que Membre mentionnées aux articles 6 et 8
                                            ci-dessus, (ii) de dépassement du seuil visé à l’article 4.5 ci-dessus
                                            ou si (iii) MonKolis a des raisons sérieuses de croire que ceci est
                                            nécessaire pour protéger sa sécurité et son intégrité, celles de ses
                                            Membres ou de tiers ou à des fins de prévention des fraudes ou
                                                d’enquêtes, MonKolis se réserve la possibilité de :</p>

                                            <p>
                                                <ul className="mar-left10">
                                                    <li><b>(i)</b> résilier, immédiatement et sans préavis, les
                                                        présentes CGU ; et/ou
                                                    </li>
                                                    <li><b>(ii)</b> empêcher la publication ou supprimer tout avis,
                                                        Annonce, messages, contenus, demande de Réservation, ou tout
                                                        contenu publié par vous sur la Plateforme ; et/ou
                                                    </li>
                                                    <li><b>(iii)</b> limiter votre accès et votre utilisation de la
                                                        Plateforme ; et/ou
                                                    </li>
                                                    <li><b>(iv)</b> suspendre de façon temporaire ou permanente votre
                                                        Compte.
                                                    </li>
                                                    <li>(v) Lorsque cela est nécessaire, vous serez notifié de la mise
                                                    en place d’une telle mesure afin de vous permettre de donner des
                                                    explications à MonKolis. MonKolis décidera, à sa seule
                                                    discrétion, de lever les mesures mises en place ou non.
                                                    </li>
                                                </ul>
                                            </p>

                                            <h2 className="f-heading-three">10. Donées personnelles</h2>
                                            <p>Dans le cadre de votre utilisation de la Plateforme, MonKolis est amené à
                                            collecter et traiter certaines de vos données personnelles. En utilisant
                                            la Plateforme et vous inscrivant en tant que Membre, vous reconnaissez
                                            et acceptez le traitement de vos données personnelles par MonKolis
                                                conformément à la loi applicable et aux stipulations de la <a
                                                    href="/privacy">Charte de Confidentialité</a>.</p>

                                            <h2 className="f-heading-three">11. Propriété intellectuelle</h2>
                                            <h3 className="f-heading-four">11.1. Contenu publié par MonKolis</h3>
                                            <p>Sous réserve des contenus fournis par ses Membres, MonKolis est seule
                                            titulaire de l’ensemble des droits de propriété intellectuelle afférents
                                            à la Plateforme, à son contenu (notamment les textes, images, dessins,
                                            logos, vidéos, sons, données, graphiques) ainsi qu’aux logiciels et
                                                bases de données assurant leur fonctionnement.</p>
                                            <p>MonKolis vous accorde une licence non exclusive, personnelle et non
                                            cessible d’utilisation de la Plateforme et des Services conformément aux
                                                finalités de la Plateforme et des Services. </p>
                                            <p>Vous vous interdisez toute autre utilisation ou exploitation de la
                                            Plateforme et des Services, et de leur contenu sans l’autorisation
                                            préalable écrite de MonKolis. Notamment, vous vous interdisez de :
                                                <ul className="mar-left10">
                                                    <li><b>(i)</b> reproduire, modifier, adapter, distribuer,
                                                        représenter publiquement, diffuser la Plateforme, les Services
                                                        et leur contenu, à l’exception de ce qui est expressément
                                                        autorisé par MonKolis;
                                                    </li>
                                                    <li><b>(ii)</b> décompiler, procéder à de l’ingénierie inverse de la
                                                        Plateforme ou des Services, sous réserve des exceptions prévues
                                                        par les textes en vigueur ;
                                                    </li>
                                                    <li><b>(iii)</b> extraire ou tenter d’extraire (notamment en
                                                        utilisant des robots d’aspiration de données ou tout autre outil
                                                        similaire de collecte de données) une partie substantielle des
                                                        données de la Plateforme.
                                                    </li>
                                                </ul>
                                            </p>

                                            <h3 className="f-heading-four">11.2. Contenu publié par vous sur la
                                                Plateforme</h3>
                                            <p>Afin de permettre la fourniture des Services et conformément à la
                                            finalité de la Plateforme, vous concédez à MonKolis une licence non
                                            exclusive d’utilisation des contenus et données que vous fournissez dans
                                            le cadre de votre utilisation des Services (ci-après, votre « Contenu
                                                Membre »). </p>
                                            <p>Afin de permettre à MonKolis la diffusion par réseau numérique et selon
                                            tout protocole de communication, (notamment Internet et réseau mobile),
                                            ainsi que la mise à disposition au public du contenu de la Plateforme,
                                            vous autorisez MonKolis, pour le monde entier et pour toute la durée de
                                            votre relation contractuelle avec MonKolis, à reproduire, représenter,
                                                adapter et traduire votre contenu Membre de la façon suivante :</p>
                                            <ul className="mar-left10">
                                                <li><b>(i)</b> vous autorisez MonKolis à reproduire tout ou partie de
                                                    votre Contenu Membre sur tout support d’enregistrement numérique,
                                                    connu ou inconnu à ce jour, et notamment sur tout serveur, disque
                                                    dur, carte mémoire, ou tout autre support équivalent, en tout format
                                                    et par tout procédé connu et inconnu à ce jour, dans la mesure
                                                    nécessaire à toute opération de stockage, sauvegarde, transmission
                                                    ou téléchargement lié au fonctionnement de la Plateforme et à la
                                                    fourniture du Service ;
                                                </li>
                                                <li><b>(ii)</b> vous autorisez MonKolis à adapter et traduire votre
                                                    Contenu Membre, ainsi qu’à reproduire ces adaptations sur tout
                                                    support numérique, actuel ou futur, stipulé au (i) ci-dessus, dans
                                                    le but de fournir les Services, notamment en différentes langues. Ce
                                                    droit comprend notamment la faculté de réaliser, dans le respect de
                                                    votre droit moral, des modifications de la mise en forme de votre
                                                    Contenu Membre aux fins de respecter la charte graphique de la
                                                    Plateforme et/ou de le rendre techniquement compatible en vue de sa
                                                    publication via la Plateforme.
                                                </li>
                                            </ul>
                                            <h2 className="f-heading-three">12. Rôle de MonKolis</h2>
                                            <p>La Plateforme constitue une plateforme en ligne de mise en relation sur
                                            laquelle les Membres peuvent créer et publier des Annonces de transport
                                            de Colis. Ces Annonces peuvent notamment être consultées par les autres
                                            Membres pour prendre connaissance des modalités du Trajet et, le cas
                                            échéant, réserver directement un Espace auprès du Membre ayant posté
                                                l’Annonce sur la Plateforme.</p>
                                            <p>En utilisant la Plateforme et en acceptant les présentes CGU, vous
                                            reconnaissez que MonKolis n’est partie à aucun accord conclu entre vous
                                            et les autres Membres en vue de transporter un Colis contre
                                                paiement.</p>
                                            <p>MonKolis n’a aucun contrôle sur le comportement de ses Membres et des
                                            utilisateurs de la Plateforme. Elle ne possède pas, n’exploite pas, ne
                                            fournit pas, ne gère pas les moyens de transports des Annonces, ni ne
                                                propose le moindre Trajet sur la Plateforme.</p>
                                            <p>Vous reconnaissez et acceptez que MonKolis ne contrôle ni la validité, ni
                                            la véracité, ni la légalité des Annonces, des Espaces et Trajets
                                            proposés. En sa qualité d’intermédiaire en transport, MonKolis ne
                                            fournit aucun service de transport et n’agit pas en qualité de
                                            transporteur, le rôle de MonKolis se limitant à faciliter l’accès à la
                                                Plateforme.</p>
                                            <p>Dans le cadre d’une Annonce, les Membres (Transporteurs et Expéditeurs)
                                            agissent sous leur seule et entière responsabilité, notamment
                                            conformément aux dispositions du code civil relatives au droit des
                                            obligations et à la responsabilité civile contractuelle (article 1101 et
                                                suivants du Code Civil).</p>
                                            <p>En sa qualité d’intermédiaire, MonKolis ne saurait voir sa responsabilité
                                            engagée au titre du transport effectif d’un Colis, et notamment du fait
                                                :</p>
                                            <ul className="mar-left10">
                                                <li><b>(iii)</b> d’informations erronées communiquées par le
                                                    Transporteur, dans son Annonce, ou par tout autre moyen, quant au
                                                    transport et à ses modalités ;
                                                </li>
                                                <li><b>(iv)</b> l’annulation ou la modification d’une Annonce par un
                                                    Membre ;
                                                </li>
                                                <li><b>(v)</b> le comportement de ses Membres pendant, avant, ou après
                                                    le transport du Colis.
                                                </li>
                                            </ul>
                                            <h2 className="f-heading-three">13. Fonctionnement disponibilité et
                                                fonctionnalités de la Plateforme</h2>
                                            <p>MonKolis s’efforcera, dans la mesure du possible, de maintenir la
                                            Plateforme accessible 7 jours sur 7 et 24 heures sur 24. Néanmoins,
                                            l’accès à la Plateforme pourra être temporairement suspendu, sans
                                            préavis, en raison d’opérations techniques de maintenance, de migration,
                                            de mises à jour ou en raison de pannes ou de contraintes liées au
                                                fonctionnement des réseaux.</p>
                                            <p>En outre, MonKolis se réserve le droit de modifier ou d’interrompre, à sa
                                            seule discrétion, de manière temporaire ou permanente, tout ou partie de
                                                l’accès à la Plateforme ou de ses fonctionnalités.</p>

                                            <h2 className="f-heading-three">14. Modification des CGU</h2>
                                            <p>Les présentes CGU expriment l’intégralité de l’accord entre vous et
                                            MonKolis relative à votre utilisation des Services. Tout autre document,
                                            notamment toute mention sur la Plateforme (FAQ, Blog, etc.), n’a qu’une
                                                valeur informative.</p>
                                            <p>MonKolis pourra être amenée à modifier les présentes Conditions Générales
                                            d’Utilisation afin de s’adapter à son environnement technologique et
                                            commercial et afin de se conformer à la réglementation en vigueur. Toute
                                            modification des présentes CGU sera publiée sur la Plateforme avec une
                                            mention de la date de mise à jour et vous sera notifiée par MonKolis
                                                avant son entrée en vigueur.</p>

                                            <h2 className="f-heading-three">15. Droit applicable - litige</h2>
                                            <p>Les présentes CGU sont rédigées en français et soumises à la loi
                                                française.</p>
                                            <p>Toutes contestations ou litiges relatifs, à l'interprétation, à
                                            l'exécution ou à la rupture des présentes CGU seront soumises à la
                                            compétence exclusive des Tribunaux du ressort de la Cour d’appel de
                                                Paris.</p>

                                            <h2 className="f-heading-three">16. Mentions légales</h2>
                                            <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour
                                            la confiance en l'économie numérique, il est précisé aux utilisateurs du
                                            Site l'identité des différents intervenants dans le cadre de sa
                                                réalisation et de son suivi.</p>
                                            <h4 className="f-heading-four">Editeur du site</h4>
                                            <p>Le site www.monkolis.com est édité par la société TAMODE SAS (Société en
                                            cours d’immatriculation) au capital de 10 000 euros, en cours
                                            d’immatriculation au Registre de Commerce et des Sociétés de Paris set
                                                ayant son siège social à Paris.</p>
                                            <p>Email <a href="mailto:hello@monkolis.com">hello@monkolis.com</a></p>

                                            <h4 className="f-heading-four">Responsable de publication</h4>
                                            <p>Monsieur Mobio Chrysostom, Président</p>

                                            <h4 className="f-heading-four">Hébergeur</h4>
                                            <p>Le site <b>www.monkolis.com</b> est hébergé par la société Amazon Web
                                                Services EMEA SARL, Succursale Française, 31 place des corolles, Tour
                                                Carpe Diem, 92400 Courbevoie, France. </p>
                                            <p>Le stockage des données personnelles des utilisateurs est exclusivement
                                            réalisé sur les centres de données ("clusters") de la société Amazon
                                            Inc, dont le siège social est situé 10 Terry Avenue North, Seattle, WA.
                                            Tous les clusters Amazon sur lesquels les données du Site sont stockées
                                                sont localisés dans des Etats membres de l'Union Européenne.</p>

                                            <h2 className="f-heading-three">17. Charte de confidentialité</h2>
                                            <p>Découvez plus sur notre <a href="/privacy">Charte de confidentialité</a>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Terms;