import React, { Component } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';
import { withRouter, Redirect } from 'react-router-dom';

class Payements extends Component {
  render() {
    return (
      <div className="faq-payments">
        <div className="section">
          <div className="section-inner">
            <h1 className="f-heading" id="top">Paiements sur MonKolis</h1>

            <a href="#1" className="link color-blue">→ Recevoir des paiements</a><br/>
            <a href="#2" className="link color-blue">→ Paiements en ligne</a><br/>
            <a href="#3" className="link color-blue">→ Remboursements</a><br/><br/>


            <h2 className="f-heading-two" id="1">Recevoir des paiements <br/></h2>

              <p>

                <span className="txt-bold">	Quand et comment recevoir mon paiement ?</span>	<br/>

                Nous procédons au virement 24 heures après la validation du dernier code de livraison.<br/><br/>

                Le virement devrait s’afficher sur votre compte bancaire sous 1 à 5 jours ouvrés (hors week-end et jours fériés).

                Assurez-vous d’avoir bien renseigné les informations relatives à votre compte bancaire dans la rubrique <a href="/dashboard/money" className="link">Coordonnées bancaires</a> depuis votre Tableau de bord. Pour que nous puissions procéder au virement, il est nécessaire que tous les champs requis soient remplis et que l’ensemble de vos informations soient enregistrées !

                <br/>
                <br/>
                <h3 className="f-heading-three">	Où est mon paiement ?</h3>	<br/>

                Nous procédons à votre paiement par virement 24 heures après la validation du dernier code de livraison. Le montant devrait s’afficher sur votre compte sous 1 à 5 jours ouvrés (hors week-end et jours fériés).
                <br/>
                Vous pouvez retrouvez l’ensemble des virements déjà effectués dans la section <a href="/dashboard/money" className="link">Historique de virements</a> depuis votre Tableau de bord.

                <br/>
                <br/>
                <h3 className="f-heading-three">	Comment modifier mes coordonnées bancaires ?</h3>	<br/>

                Vous pouvez modifier vos coordonnées bancaires depuis votre Tableau de bord dans la section <a href="/dashboard/money" className="link">Coordonnées bancaires</a>.
                <br/>
                Vous souhaitez recevoir vos paiements sur un autre compte que celui utilisé habituellement ? Pas de problème ! Quand vous changez les informations relatives à l’un de votre compte bancaire, c’est celui qui a été modifié en dernier qui devient votre compte par défaut.


                <br/>
                <br/>
                <h3 className="f-heading-three">	Je n'ai pas obtenu un code de livraison.</h3>	<br/>

                Si vous n'avez pas récupéré un code de livraison auprès de l'expéditeur lors de la livraison du kolis. N'hésitez pas à le contacter pour que celui-ci vous le communique.
                Le code de livraison est disponible dans la section des <a href="/dashboard/myexpeditions" className="link">Expéditions en cours </a> du Tableau de bord de votre expéditeur.
                <br/><br/>
                Si vous n'avez pas remis le code de dépôt à l'expéditeur lorsque ce dernier vous a remis son kolis, il n'aura pas accès à votre code de livraison.<br/>
                Le code de dépôt à mettre à l'expéditeur est disponible dans la section des <a href="/dashboard/mytrips" className="link">Trajets en cours </a> de votre Tableau de bord.<br/>
                L'expéditeur doit valider le code de dépôt qui vous a été envoyé lors de sa réservation pour voir votre code de livraison dans son Tableau de bord.

                <br/>
                <br/>

                <h3 className="f-heading-three">	Est-ce que MonKolis prend une commission sur mon paiement ?</h3>	<br/>

                Non, nous ne prenons aucune commission sur le montant que vous demandez pour votre tranpsport ! Si vous demandez 20 euros/kg, ce sont bien 20 euros multipliés par le nombre de kilos transportés qui vous seront reversés.
                <br/><br/>
                Les frais de réservation et de TVA (20%) sont payés par le passager pour chaque kg ou barrique réservé au moment de la réservation de son expédition.
                Nous considérons en effet que le tranporteur est celui qui amène l'offre sur le site et que nous ne pouvons lui reverser un montant différent de celui qu'il demande. Et même si nous faisions ce choix, la conséquence quasi certaine et immédiate serait que les transporteurs augmenteraient d'autant leur tarif afin de récupérer ce qu'ils voulaient initialement recevoir pour leur trajet, ce qui impliquerait une hausse immédiate des tarifs demandés.
                <br/><br/>
                Le service est donc totalement gratuit pour le transporteur.
                <br/>
                Toutefois, dans le cas ou des frais de virements internationaux étaient demandés par la banque lors du virement, ces derniers seraient à la charge du transporteur.
                <br/>
                </p>

                <a href="#top"className="link color-text2">Remonter</a> <br/><br/>


              <h2 className="f-heading-two" id="2">Paiements en ligne<br/></h2>

              <p>
              <span className="txt-bold">	À quel moment vais-je être débité ?</span>	<br/>

              Nous vous débitons une fois que votre demande de réservation a été acceptée par le transporteur.
<br/>
              Ainsi, si celui-ci a choisi d’activer l’acceptation automatique sur son trajet, vous êtes immédiatement débité. Sinon, nous attendons qu’il ait accepté votre demande.
<br/>
              Une fois la livraison effectuée, nous versons au transporteur le montant de votre réservation au bout de 48 heures après la confirmation de livraison du kolis.


              <br/>
              <br/>
              <span className="txt-bold">	Pourquoi mon compte a-t-il été débité alors que ma demande de réservation n'a pas abouti ?</span>	<br/>
              Rassurez-vous, votre demande de réservation n’ayant pas été acceptée par l'expéditeur, aucun débit n'a été enregistré. En revanche, vous voyez peut-être une demande d'autorisation correspondant au montant du transport sur votre relevé bancaire. Ne vous inquiétez pas, elle va bientôt disparaître !
                <br/><br/>
              Vous vous demandez peut-être ce qu'est une demande d'autorisation. Imaginez une conversation virtuelle entre votre banque et MonKolis. Quand vous faites une demande de réservation, nous vérifions que votre compte bancaire autorise le prélèvement. Votre banque met ensuite cet argent de côté afin que nous puissions vous prélever dès que le transporteur accepte votre réservation.
                <br/><br/>
              Pour rappel, aucun prélèvement n'est fait si un transporteur décline votre demande de réservation, ou si vous annulez votre demande avant qu'elle ne soit acceptée.
              <br/>
              <br/>

              <span className="txt-bold">	Le paiement en ligne est-il sécurisé ?</span>	<br/>

              Absolument ! Chez MonKolis, nous travaillons sans relâche pour que le paiement en ligne de vos réservations demeure ultra-sécurisé. Notre site internet, ainsi que nos applications mobiles, sont cryptés et sécurisés afin d’empêcher toute tentative de vol de données si MonKolis venait à en faire l’objet.
<br/><br/>
              Il existe deux moyens de s’assurer que le paiement sur notre site est sécurisé :
<br/>
              - La présence d’un petit cadenas dans la barre de votre navigateur
<br/>
              - La marque (https://) qui indique que l’URL est sécurisée
<br/><br/>
              Si toutefois vous n’étiez pas rassuré, vous pouvez toujours obtenir auprès de votre banque une carte de débit ou de crédit “virtuelle” dédiée au paiement en ligne.
              <br/>
              <br/>
              <span className="txt-bold">	Je n’arrive pas à payer par carte, pourquoi ?</span>	<br/>
              Si votre paiement par carte bancaire a été refusé, soyez tout d’abord assuré·e que nous n’avons prélevé aucun montant.
<br/><br/>
              Dans un premier temps, assurez-vous d'avoir bien renseigné les informations de votre carte bancaire. Si le paiement ne fonctionne toujours pas, il est possible que cet échec de paiement soit lié à un problème bancaire temporaire et vous devrez alors renouveler votre réservation.
<br/><br/>
              Si vous souhaitez toujours réserver le trajet évoqué, essayez de renouveler votre paiement depuis une autre plateforme de MonKolis :<br/>
              - avec un ordinateur en essayant un navigateur différent,<br/>
              - via le site mobile.

<br/><br/>
              Si le paiement ne fonctionne toujours pas, nous vous suggérons de contacter votre banque pour vérifier les conditions d’utilisation de votre carte bancaire.

<br/><br/>
              À ce jour, vous pouvez payer vos réservations avec des cartes de crédit Visa. Si vous ne possédez pas l’une de cette carte, n’hésitez pas à demander autour de vous, à un ami ou un membre de votre famille, par exemple. Des cartes prépayées rechargeables existent également dans le commerce.

              <br/>
              <br/>
              <span className="txt-bold">	À quoi correspondent les frais de réservation ?</span>	<br/>
              Les frais de réservation permettent à MonKolis de fonctionner, tout simplement. Ce sont eux qui nous permettent d’employer une équipe de collaborateurs passionnés, dédiés à faire grandir et satisfaire notre communauté.
              <br/><br/>
              Les frais de réservation nous permettent également d’améliorer sans cesse MonKolis pour vous garantir sécurité et performance sur toutes nos plateformes.

              <br/>
              <br/>
              <span className="txt-bold">	Comment sont calculés les frais de réservation ?</span>	<br/>

              Les frais de réservation sont calculés sur la base du montant choisi par le transporteur au moment de publier son trajet. Le montant visible dans les résultats de recherche prend en compte les frais de réservation, qui incluent eux-mêmes la TVA (20%).
<br/><br/>
              Le montant des frais de service pour une expédition est compris entre 0 et 25 % (avec un minimum de 1€ de Frais de Service par unité transportée) de la participation aux frais du transporteur et est calculé en fonction de différents facteurs, notamment les unités de  transport (kg, barrique ou carton), le prix, le statut du transporteur (particulier ou professionnel).
<br/><br/>
              Par exemple, pour une à 38€, les frais sont de 7€. Et pour un trajet à 15€, les frais sont d’un montant de 3€.
<br/><br/>
              Nous ne déduisons pas les frais de réservation du prix du transport. Le transporteur reçoit exactement le paiement correspondant au prix fixé de la création de son trajet.

              <br/>
              <br/>
              <span className="txt-bold">	Je n'ai pas reçu un code de livraison.</span>	<br/>

              Si vous n'avez pas récupéré un code de livraison auprès de l'expéditeur lors de la livraison du kolis. N'hésitez pas à le contacter pour que celui-ci vous le communique.
              Le code de livraison est disponible dans la section des <a href="/dashboard/myexpeditions" className="link">Expéditions en cours </a> du Tableau de bord de votre expéditeur.
              <br/><br/>
              Si vous n'avez pas remis le code de dépôt à l'expéditeur lorsque ce dernier vous a remis son kolis, il n'aura pas accès à votre code de livraison.<br/>
              Le code de dépôt à mettre à l'expéditeur est disponible dans la section des <a href="/dashboard/mytrips" className="link">Trajets en cours </a> de votre Tableau de bord.<br/>
              L'expéditeur doit valider le code de dépôt qui vous a été envoyé lors de sa réservation pour voir votre code de livraison dans son Tableau de bord.


              <br/>
              <br/>
              <h3 className="f-heading-three">	J’ai besoin d’une facture pour justifier mes dépenses</h3>	<br/>

              Votre facture est disponible dans votre compte, elle vous permet de justifier vos dépenses juste après votre réservation en ligne.
              Vous pouvez l’utiliser pour vos notes de frais. Vous pouvez la retrouver dans la section de vos <a href="#" className="link">expéditions </a>.
              <br/>
              <br/>


              Cette facture message contient toutes les informations nécessaires pour justifier vos dépenses :
              <br/>
              - Notre numéro de tva
<br/>
              - Les montants de la TVA (20%) et de nos frais de service, indiqués en chiffres

              <br/>

Si besoin, retrouvez <a href="#" className="link">l’historique de vos paiements</a> dans votre tableau de bord.

              </p>

              <a href="#top"className="link color-text2">Remonter</a> <br/><br/>


              <h3 className="f-heading-three" id="3">Remboursements<br/></h3>

              <p>
              <h3 className="f-heading-three">	Quelle est votre politique d’annulation ?</h3>	<br/>


        Quand peut-on bénéficier d’une compensation ou d’un remboursement ? Tout dépend de qui a décidé d’annuler le trajet et à quel moment l’annulation a eu lieu.
        <br/>
        <br/>
        - Que se passe-t-il si un expéditeur annule plus de 72 heures avant l’heure limite de réservation ?


                          <br/>

        Nous le remboursons intégralement à l’exception des frais de réservation.

                          <br/>
                          <br/>

        - Que se passe-t-il si un expéditeur annule entre 72 heures et 24 heures avant l’heure limite de réservation ?


                          <br/>
        Nous le remboursons à hauteur de 50 %, hors frais de réservation. Nous versons au transporteur 50 % du montant de la réservation également, car l’annulation intervient peu de temps avant le trajet.


                          <br/>
                          <br/>
        - Que se passe-t-il si un expéditeur annule moins de 24 heures avant l’heure limite de réservation ?


                          <br/>
        Nous versons tout de même au transporteur l’intégralité du montant de la réservation. Les transporteurs n’ont pas à subir des changements de dernière  minute, c’est pourquoi il est essentiel de penser à annuler sa réservation tôt.

                          <br/>
                          <br/>

        - Que se passe-t-il si le transporteur annule ?
                          <br/>

        Nous remboursons les expéditeurs intégralement, frais de réservation compris.


                          <br/>
                          <br/>
        - Que se passe-t-il si le transporteur n’a pas annulé le trajet alors qu’il ou elle aurait dû ?


                          <br/>
        Il est nécessaire que les expéditeurs nous disent que le trajet n’a pas eu lieu et nous apporte une explication détaillée.

                          <br/>
                          <br/>

        Nous laisserons le temps au transporteur pour confirmer ou contester cette explication.
        En cas de confirmation, nous remboursons intégralement les expéditeurs, frais de réservation compris. La somme remboursée sera la même que si le transporteur avait annulé directement.
        En cas de contestation, nous lui demanderons de nous donner sa propre version des faits. Notre équipe s’occupera alors de rembourser chacun et chacune en fonction de notre politique d’annulation. Surveillez régulièrement vos e-mails, nous pourrions avoir besoin de plus d’informations de votre part.


              <br/>
              <br/>
              <h3 className="f-heading-three">	Quand vais-je recevoir mon remboursement ?</h3>	<br/>

              Vous recevrez votre remboursement entre 1 et 5 jours ouvrés (hors week-ends et jours fériés) après l’annulation de votre réservation ou le traitement de votre réclamation.
              <br/>
              <br/>
              Nous vous remboursons toujours avec la carte ou le mode de paiement utilisé pour réserver.
              <br/>
              <br/>
              Pour rappel, nous vous remboursons toujours selon notre politique d'annulation (voir la question précédente).
              <br/>
              <br/>
              Les 5 jours ouvrés sont passés et vous n’avez toujours pas reçu votre remboursement ? Si vous utilisez une carte à débit différé, votre remboursement pourrait n’arriver qu’à la fin du mois. Votre argent peut également prendre un peu plus de temps à vous parvenir si vos comptes bancaires sont situés en dehors de l’UE.

              </p>

              <a href="#top"className="link color-text2">Remonter</a> <br/><br/>


          </div>
        </div>
      </div>

    );
  }
}

export default withRouter(Payements);