import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
// import './../../../assets/css/main.css';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/dashboard.css';
import './../../../assets/css/modal.css';
import MyPersoInformation from './mypersoinformation';
import MyPhoto from './myphoto';
import MyPreferences from './mypreferences';
import MyVerifications from './myverifications';
import MyAvisrecu from './myavisrecu';
import MyAvislaisses from './myavislaisses';
import MyNotification from './mynotification';
import MyBonsplans from './mybonsplans';
import MyMotdepasse from './mymotdepasse';
import MyFermeturedecompte from './myfermeturedecompte';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.pages = this.props
        if(this.pages.location.state !== undefined && this.pages.location.state.pages ==='verfications'){
			this.state = {
                isLoaded: false,
                error: [],
                renderPersoInformation: false,
                renderPhoto: false,
                renderPreferences: false,
                renderVerifications: true,
                renderAvisrecu: false,
                renderAvislaisses: false,
                renderNotification: false,
                renderBonsplans: false,
                renderMotdepasse: false,
                renderFermeturedecompte: false
            }
		}else if(this.pages.location.state !== undefined && this.pages.location.state.pages ==='avis_recus'){
            this.state = {
                isLoaded: false,
                error: [],
                renderPersoInformation: false,
                renderPhoto: false,
                renderPreferences: false,
                renderVerifications: false,
                renderAvisrecu: true,
                renderAvislaisses: false,
                renderNotification: false,
                renderBonsplans: false,
                renderMotdepasse: false,
                renderFermeturedecompte: false
            }
        }else if(this.pages.location.state !== undefined && this.pages.location.state.pages ==='profil'){
                this.state = {
                    isLoaded: false,
                    error: [],
                    renderPersoInformation: true,
                    renderPhoto: false,
                    renderPreferences: false,
                    renderVerifications: false,
                    renderAvisrecu: false,
                    renderAvislaisses: false,
                    renderNotification: false,
                    renderBonsplans: false,
                    renderMotdepasse: false,
                    renderFermeturedecompte: false
                }
            }else if(this.pages.location.state !== undefined && this.pages.location.state.pages ==='notif'){
                this.state = {
                    isLoaded: false,
                    error: [],
                    renderPersoInformation: false,
                    renderPhoto: false,
                    renderPreferences: false,
                    renderVerifications: false,
                    renderAvisrecu: false,
                    renderAvislaisses: false,
                    renderNotification: true,
                    renderBonsplans: false,
                    renderMotdepasse: false,
                    renderFermeturedecompte: false
                }
            }else{
            this.state = {
                isLoaded: false,
                error: [],
                renderPersoInformation: true,
                renderPhoto: false,
                renderPreferences: false,
                renderVerifications: false,
                renderAvisrecu: false,
                renderAvislaisses: false,
                renderNotification: false,
                renderBonsplans: false,
                renderMotdepasse: false,
                renderFermeturedecompte: false
            }
        }

        this.handlePersoInformationClick = this.handlePersoInformationClick.bind(this);
        this.handlePhotoClick = this.handlePhotoClick.bind(this);
        this.handlePreferencesClick = this.handlePreferencesClick.bind(this);
        this.handleVerificationsClick = this.handleVerificationsClick.bind(this);
        this.handleAvisrecuClick = this.handleAvisrecuClick.bind(this);
        this.handleAvislaissesClick = this.handleAvislaissesClick.bind(this);
        this.handleNotificationClick = this.handleNotificationClick.bind(this);
        this.handleBonsplansClick = this.handleBonsplansClick.bind(this);
        this.handleMotdepasseClick = this.handleMotdepasseClick.bind(this);
        this.handleFermeturedecompteClick = this.handleFermeturedecompteClick.bind(this);
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
    }



    handlePersoInformationClick(event) {
        this.setState({
            renderPersoInformation: true,
            renderPhoto: false,
            renderPreferences: false,
            renderVerifications: false,
            renderAvisrecu: false,
            renderAvislaisses: false,
            renderNotification: false,
            renderBonsplans: false,
            renderMotdepasse: false,
            renderFermeturedecompte: false

        })
    }

    handlePhotoClick(event) {
        this.setState({
            renderPersoInformation: false,
            renderPhoto: true,
            renderPreferences: false,
            renderVerifications: false,
            renderAvisrecu: false,
            renderAvislaisses: false,
            renderNotification: false,
            renderBonsplans: false,
            renderMotdepasse: false,
            renderFermeturedecompte: false
        })
    }

    handlePreferencesClick(event) {
        this.setState({
            renderPersoInformation: false,
            renderPhoto: false,
            renderPreferences: true,
            renderVerifications: false,
            renderAvisrecu: false,
            renderAvislaisses: false,
            renderNotification: false,
            renderBonsplans: false,
            renderMotdepasse: false,
            renderFermeturedecompte: false
        })
    }

    handleVerificationsClick(event) {
        this.setState({
            renderPersoInformation: false,
            renderPhoto: false,
            renderPreferences: false,
            renderVerifications: true,
            renderAvisrecu: false,
            renderAvislaisses: false,
            renderNotification: false,
            renderBonsplans: false,
            renderMotdepasse: false,
            renderFermeturedecompte: false
        })
    }

    handleAvisrecuClick(event) {
        this.setState({
            renderPersoInformation: false,
            renderPhoto: false,
            renderPreferences: false,
            renderVerifications: false,
            renderAvisrecu: true,
            renderAvislaisses: false,
            renderNotification: false,
            renderBonsplans: false,
            renderMotdepasse: false,
            renderFermeturedecompte: false
        })
    }

    handleAvislaissesClick(event) {
        this.setState({
            renderPersoInformation: false,
            renderPhoto: false,
            renderPreferences: false,
            renderVerifications: false,
            renderAvisrecu: false,
            renderAvislaisses: true,
            renderNotification: false,
            renderBonsplans: false,
            renderMotdepasse: false,
            renderFermeturedecompte: false
        })
    }

    handleNotificationClick(event) {
        this.setState({
            renderPersoInformation: false,
            renderPhoto: false,
            renderPreferences: false,
            renderVerifications: false,
            renderAvisrecu: false,
            renderAvislaisses: false,
            renderNotification: true,
            renderBonsplans: false,
            renderMotdepasse: false,
            renderFermeturedecompte: false
        })
    }

    handleBonsplansClick(event) {
        this.setState({
            renderPersoInformation: false,
            renderPhoto: false,
            renderPreferences: false,
            renderVerifications: false,
            renderAvisrecu: false,
            renderAvislaisses: false,
            renderNotification: false,
            renderBonsplans: true,
            renderMotdepasse: false,
            renderFermeturedecompte: false
        })
    }


    handleMotdepasseClick(event) {
        this.setState({
            renderPersoInformation: false,
            renderPhoto: false,
            renderPreferences: false,
            renderVerifications: false,
            renderAvisrecu: false,
            renderAvislaisses: false,
            renderNotification: false,
            renderBonsplans: false,
            renderMotdepasse: true,
            renderFermeturedecompte: false
        })
    }


    handleFermeturedecompteClick(event) {
        this.setState({
            renderPersoInformation: false,
            renderPhoto: false,
            renderPreferences: false,
            renderVerifications: false,
            renderAvisrecu: false,
            renderAvislaisses: false,
            renderNotification: false,
            renderBonsplans: false,
            renderMotdepasse: false,
            renderFermeturedecompte: true
        })
    }


    render() {
        
        return (
            <Fragment>
                <Helmet>
                    <title>Informations personnelles - Espace utilisateur| Monkolis</title>
                    <meta name="robots" content="noindex,follow" />
                </Helmet>

                <div className="myprofile">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            {getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? <Redirect to="/connexion" /> :
                                <div className="pad-top20 mar-bot20 d-sm-block" id="secondNav">
                                    <Nav variant="tabs" defaultActiveKey="/dashboard/myprofile">
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mydashboard" href="/dashboard/mydashboard">Tableau de bord</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mytrips" href="/dashboard/mytrips">Mes trajets</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myexpeditions" href="/dashboard/myexpeditions">Mes expéditions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mymessage" href="/dashboard/mymessage">Messages</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myalerts" href="/dashboard/myalerts">Alertes</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myprofile" href="/dashboard/myprofile">Profil</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href="/dashboard/money" href="/dashboard/money">Paiements</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <br />
                                    <div className="row mytravels">
                                        <div className="col-sm-3 mar-bot20">
                                            <div className="list-group mar-bot20">

                                                {
                                                    this.state.renderPersoInformation ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handlePersoInformationClick}>Informations personnelles</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handlePersoInformationClick}>Informations personnelles</a>
                                                }


                                                {
                                                    this.state.renderPhoto ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handlePhotoClick}>Photo / Logo</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handlePhotoClick}>Photo / Logo</a>
                                                }


                                                {
                                                    this.state.renderPreferences ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handlePreferencesClick}>Préferences</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handlePreferencesClick}>Préferences</a>
                                                }



                                                {
                                                    this.state.renderVerifications ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleVerificationsClick}>Vérifications</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleVerificationsClick}>Vérifications</a>
                                                }
                                            </div>
                                            <hr />
                                            <div className="list-group mar-bot20">
                                                {
                                                    this.state.renderAvisrecu ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleAvisrecuClick}>Avis reçus</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleAvisrecuClick}>Avis reçus</a>
                                                }

                                                {
                                                    this.state.renderAvislaisses ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleAvislaissesClick}>Avis laissés</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleAvislaissesClick}>Avis laissés</a>
                                                }
                                            </div>
                                            <hr />
                                            <div className="list-group mar-bot20">
                                                {
                                                    this.state.renderNotification ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleNotificationClick}>Notification</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleNotificationClick}>Notification</a>
                                                }


                                                {
                                                    this.state.renderBonsplans ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleBonsplansClick}>Bons plans</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleBonsplansClick}>Bons plans</a>
                                                }

                                            </div>
                                            <div className="list-group mar-bot20">
                                                {
                                                    this.state.renderMotdepasse ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleMotdepasseClick}>Mot de passe</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleMotdepasseClick}>Mot de passe</a>
                                                }


                                                {
                                                    this.state.renderFermeturedecompte ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleFermeturedecompteClick}>Fermeture de compte</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleFermeturedecompteClick}>Fermeture de compte</a>
                                                }
                                            </div>

                                        </div>
                                        <div className="col-sm-9">
                                            {this.state.renderPersoInformation ? <MyPersoInformation /> : null}
                                            {this.state.renderPhoto ? <MyPhoto /> : null}
                                            {this.state.renderPreferences ? <MyPreferences /> : null}
                                            {this.state.renderVerifications ? <MyVerifications /> : null}
                                            {this.state.renderAvisrecu ? <MyAvisrecu /> : null}
                                            {this.state.renderAvislaisses ? <MyAvislaisses /> : null}
                                            {this.state.renderNotification ? <MyNotification /> : null}
                                            {this.state.renderBonsplans ? <MyBonsplans /> : null}
                                            {this.state.renderMotdepasse ? <MyMotdepasse /> : null}
                                            {this.state.renderFermeturedecompte ? <MyFermeturedecompte /> : null}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
            
        );
    }
}
export default withRouter(MyProfile);