/* eslint-disable */

import React, { Component, Fragment} from 'react';
// import './../../../assets/css/main.css';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/dashboard.css';
import './../../../assets/css/modal.css';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import { TRAVEL_ACTIONS_URL } from '../../../Url';
import { GET_DASHBOARD_INFOS_QUERY } from '../../../query/queryprofil';
import DEFAULT_PHOTO from '../../../assets/img/user/user0.png';
import Moment from 'moment'
import StarRatings from 'react-star-ratings';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Nav from 'react-bootstrap/Nav';
import { Helmet } from 'react-helmet';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import _ from 'lodash';
import { Rating } from '@material-ui/lab';


class MyDashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            locality: '',
            description: '',
            id_card_verified: '',
            phone_number_verified: '',
            email_verified: '',
            user_create_date: '',
            iat: '',
            total_of_travel: '',
            total_of_requester: '',
            mark5: '',
            mark4: '',
            mark3: '',
            mark2: '',
            mark1: '',
            evaluations: [],
            level: '',
            progress_percent: '',

            user_data: '',
            stateAvis :0
        }
        this.handleCompleteVerification = this.handleCompleteVerification.bind(this);
        this.handleShowAvisRecus = this.handleShowAvisRecus.bind(this);
    }

    componentWillMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        this.photo = getCookieConsentValue('photo');
        this.name = getCookieConsentValue('given_name');
        this.birthdate = getCookieConsentValue('birthdate');
        this.account_type = getCookieConsentValue('account_type');
        this.company_name = getCookieConsentValue('company_name');
        this.getDashboardInfos(TRAVEL_ACTIONS_URL, this.idToken);
    }

    handleShowAvisRecus(event) {

        this.props.history.push({
            pathname: '/dashboard/myprofile',
            state: { pages: 'avis_recus' }
        });
    }

    handleCompleteVerification(event) {

        this.props.history.push({
            pathname: '/dashboard/myprofile',
            state: { pages: 'verfications' }
        });
    }

    getDashboardInfos = async (url, idToken) => {
        try {
            const response = await axios.post(url,
                { "query": GET_DASHBOARD_INFOS_QUERY },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );

            //console.log(response.data)
            // Log the response so we can look at it in the console
            // Set the data to the state

            if (response.data.data.getUserDashboard.status === 200) {
                this.setState(() => ({
                    locality: response.data.data.getUserDashboard.user_dashboard.address === null ? '' : response.data.data.getUserDashboard.user_dashboard.address.locality !== "null" ? response.data.data.getUserDashboard.user_dashboard.address.locality : '',
                    description: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.description !== "null" ? response.data.data.getUserDashboard.user_dashboard.description : '',
                    id_card_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.id_card_verified,
                    phone_number_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.phone_number_verified,
                    email_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.email_verified,
                    user_create_date: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.activities.user_create_date,
                    iat: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.activities.iat,
                    total_of_travel: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.activities.total_of_travel,
                    total_of_requester: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.activities.total_of_requester,
                    evaluations: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.evaluations_receive,
                    mark5: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '5') !== undefined ? response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '5').nbre : '0',
                    mark4: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '4') !== undefined ? response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '4').nbre : '0',
                    mark3: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '3') !== undefined ? response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '3').nbre : '0',
                    mark2: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '2') !== undefined ? response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '2').nbre : '0',
                    mark1: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '1') !== undefined ? response.data.data.getUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '1').nbre : '0',
                    progress_percent: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.activities.positifs_evaluation_receive_part,
                    level: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.activities.level_of_experience,

                    user_data: response.data.data.getUserDashboard.user_dashboard,
                }));
            }
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState({ error: error })
            console.log(this.state.error)
        }
    }

    render() {
        
        this.state.sumNbre = _.sumBy(this.state.user_data.resume_evaluation_receive, 'nbre'),
        this.state.sumMarks = _.sum(_.map(this.state.user_data.resume_evaluation_receive,  v => Number(v.marks) * v.nbre))
       
        { this.state.sumNbre !== 0 ? this.state.stateAvis = (parseFloat(this.state.sumMarks / this.state.sumNbre)) : this.state.stateAvis = 0 }
        return (
            <Fragment>
                <Helmet>
                    <title>Tableaux de board - Espace utilisateur | Monkolis</title>
                    <meta name="robots" content="noindex,follow" />
                </Helmet>

                <div className="mydashboard">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            {this.idToken === null ? <Redirect to="/connexion" /> :
                                <div className="pad-top20 mar-bot20 d-sm-block" id="secondNav">
                                    <Nav variant="tabs" defaultActiveKey="/dashboard/mydashboard" onSelect={this.navSelected}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mydashboard" href="/dashboard/mydashboard">Tableau de bord</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mytrips" href="/dashboard/mytrips">Mes trajets</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myexpeditions" href="/dashboard/myexpeditions">Mes expéditions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mymessage" href="/dashboard/mymessage">Messages</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myalerts" href="/dashboard/myalerts">Alertes</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myprofile" href="/dashboard/myprofile">Profil</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href="/dashboard/money">Paiements</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <br />
                                    <div className="row dashboard ">
                                        <div className="col-sm-4">
                                            <div className="about section">
                                                <div className="section-inner">
                                                    <div className="media">
                                                        <div className="media-left media-top">
                                                            <div className="photo">
                                                                {this.photo === 'null' || this.photo === undefined || this.photo === null || this.photo === "undefined" ?
                                                                    <img src={DEFAULT_PHOTO} alt="Photo Profil" className="cover rounded" /> :
                                                                    <img src={this.photo} alt="Photo Profile" className="cover rounded" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="media-body pad-left10">
                                                            {
                                                                this.account_type === null || this.account_type === undefined || this.account_type === 'null' || this.account_type === 'undefined' || this.account_type === "Particulier" || this.account_type === "" ?
                                                                    <h5 className="media-heading">{this.name}</h5>
                                                                    :
                                                                    this.company_name !== "" || this.company_name !== "null" || this.company_name !== "undefined" || this.company_name !== null || this.company_name !== undefined ?
                                                                        <h5 className="media-heading">{this.company_name}</h5>
                                                                        :
                                                                        <h5 className="media-heading">{this.name}</h5>

                                                            }
                                                            <p className="color-text2">{this.state.locality === null || this.state.locality === undefined || this.state.locality === "null" || this.state.locality === "undefined" || this.state.locality === "" ? "" : this.state.locality} {/* {this.birthdate !== 'null' || this.birthdate !== undefined || this.birthdate !== 'undefined' || this.birthdate !== null ? ", " + Moment(this.birthdate).format('DD/MM/YYYY') : ''} */}</p>
                                                        </div>
                                                    </div>
                                                    {

                                                        this.account_type === null || this.account_type === undefined || this.account_type === 'null' || this.account_type === 'undefined' || this.account_type === "Particulier" || this.account_type === "" ? '' :
                                                            <div>
                                                                <span class="badge badge-danger">Professionnel</span>
                                                            </div>
                                                    }
                                                    <div className="content mar-bot10">

                                                        <p> {
                                                            this.state.description === null || this.state.description === undefined || this.state.description === "null" || this.state.description === "undefined" || this.state.description === "" ?
                                                                <div>
                                                                    Vous n'avez pas encore rédigé de description <br />
                                                                    <a href="/dashboard/myprofile" class="link color-blue"><span class="fa fa-long-arrow-right"></span> Modifier ma description</a>
                                                                </div>
                                                                : this.state.description
                                                        }</p>

                                                    </div>

                                                    <hr />


                                                    <h3 className="heading">Vérifications</h3>


                                                    <div className="content">


                                                        <ul className="fa-ul txt-bold color-green">
                                                            {this.state.id_card_verified === 'true' ?
                                                                <li><i className="fa-li fa fa-check-square-o"></i>Pièce d'identité</li> :
                                                                <li className="color-red"><i class="fa-li fa fa-close"></i>Pièce d'identité</li>

                                                            }

                                                            {this.state.phone_number_verified !== 'true' ?
                                                                <li className="color-red"><i class="fa-li fa fa-close"></i>Téléphone</li> :
                                                                <li><i className="fa-li fa fa-check-square-o"></i>Téléphone</li>
                                                            }

                                                            {this.state.email_verified !== 'true' ?
                                                                <li className="color-red"><i class="fa-li fa fa-close"></i>Email</li> :
                                                                <li><i className="fa-li fa fa-check-square-o"></i>Email</li>
                                                            }

                                                            {/* <li class="color-facebook"><i class="fa-li fa fa-facebook-square"></i><p class="color-text2">1640 Amis</p></li> */}
                                                            {
                                                                this.state.id_card_verified !== 'true' || this.state.phone_number_verified !== 'true' || this.state.email_verified !== 'true' ?
                                                                    <a onClick={this.handleCompleteVerification} class="link color-blue"><span class="fa fa-long-arrow-right"></span> Compléter les vérifications</a>
                                                                    : null
                                                            }
                                                        </ul>

                                                    </div>

                                                    <hr />

                                                    <h3 className="heading">Activité</h3>
                                                    <div className="content">
                                                        <p className="mar-bot5">Membre depuis : {Moment(this.state.user_create_date).format('DD/MM/YYYY')} </p>
                                                        <p className="mar-bot5">Dernière connexion : {Moment(this.state.iat).format('DD/MM/YYYY')} </p>
                                                        <p className="mar-bot5">Nombre d'expéditions: {this.state.total_of_requester}</p>
                                                        <p className="mar-bot5">Nombre de trajets: {this.state.total_of_travel}</p>



                                                        <p>Synthèse des avis : <i>- {this.state.evaluations.length} avis</i>
                                                            <Rating
                                                                name="hover-feedback"
                                                                value={this.state.stateAvis}
                                                                precision={0.5}
                                                                readOnly
                                                            />
                                                            {/*   <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                    <i className="fa fa-star-half-o color-yellow" aria-hidden="true" ></i> */}

                                                        </p>


                                                    </div>


                                                    {/* <div class="mar-top40 mar-bot0">
                                            <a href="#" class="link color-red" data-toggle="modal" data-target="#modal-signal-user">Signaler cet utilisateur</a>
                                        </div> */}

                                                    <div className="modal fade" id="modal-signal-user" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" >
                                                        {/* <?php include 'modal/signal-user.php'; ?>	 */}
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="level section">

                                                <div className="section-inner">
                                                    <h3 className="heading">Niveau d'expérience</h3>
                                                    <div className="content">
                                                        <p className="intro">
                                                            Chaque membre dispose de son propre niveau d’expérience, niveau qui évolue en fonction de son ancienneté et de son activité sur le site. Les niveaux d’expérience vous aident à choisir la personne idéale pour votre trajet avec MonKolis.</p>

                                                        <ProgressBar animated now={this.state.progress_percent} label={this.state.level} />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="info aside section">
                                                <div className="section-inner">
                                                    <h3 className="heading">Synthèse des avis</h3>
                                                    <div className="content">
                                                        <div className="row ">
                                                            <div className="col-sm-3">
                                                                <p className="align-right">Très bien</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>

                                                                <i className=""> {this.state.mark5} avis</i>
                                                            </div>
                                                        </div>
                                                        <div className="row ">
                                                            <div className="col-sm-3">
                                                                <p className="align-right">Bien</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className=""> {this.state.mark4} avis</i>
                                                            </div>
                                                        </div>
                                                        <div className="row ">
                                                            <div className="col-sm-3">
                                                                <p className="align-right">Moyen</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className=""> {this.state.mark3} avis</i>
                                                            </div>
                                                        </div>
                                                        <div className="row ">
                                                            <div className="col-sm-3">
                                                                <p className="align-right">Déçu</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className=""> {this.state.mark2} avis</i>
                                                            </div>
                                                        </div>
                                                        <div className="row ">
                                                            <div className="col-sm-3">
                                                                <p className="align-right">Très déçu</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <i className=""> {this.state.mark1} avis</i>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="testimonials section">
                                                <div className="section-inner">
                                                    <h3 className="heading">Derniers avis reçus</h3>
                                                    <div className="content">
                                                        <div className="testimonials">
                                                            {this.state.evaluations.length != 0 ? this.state.evaluations.map((value, index) => {
                                                                return <div className="item" key={index} >

                                                                    {value.evaluation !== null ? <div>
                                                                        <div className="quote">
                                                                            {/* <StarRatings
                                                                                rating={value.evaluation.marks}
                                                                                starRatedColor="red"
                                                                                numberOfStars={5}
                                                                                name='rating'
                                                                                starDimension='20px'
                                                                                starSpacing='0,5px'
                                                                            /> */}

                                                                            <Rating
                                                                                name="hover-feedback"
                                                                                value={value.evaluation.marks}
                                                                                precision={0.5}
                                                                                readOnly
                                                                            />

                                                                            <p>

                                                                                {value.evaluation.comment}</p>
                                                                        </div>
                                                                        <p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}, {Moment(value.evaluation.evaluation_date).format('MMM-YYYY')}</span></p>
                                                                    </div>
                                                                        : <div>
                                                                            Vous n'avez pas laissé de commentaire
                                                                <p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}</span></p>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            }) : null}
                                                        </div>
                                                    </div>

                                                    <a onClick={this.handleShowAvisRecus} className="link color-blue"><span className="fa fa-long-arrow-right"></span> Voir tous les avis</a>

                                                </div>
                                            </div>
                                        </div>

                                    </div >
                                </div>
                            }
                        </div >
                    </div >
                </div >
            </Fragment >
        );
    }
}
export default withRouter(MyDashBoard);