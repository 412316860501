import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/annex.css';
import './../../../assets/css/modal.css';
import './../../../assets/css/blog.css';
import GUCCI from './../../../assets/img/blog/gucci.jpeg';

class PersonnalShopper extends Component {
  render() {
    return (

      <Fragment>
        <Helmet>
          <title>Blog | Monkolis</title>
          <meta name="robots" content="index,follow" />
        </Helmet>

        <div className="blog">
          <div className="main-box_content">
            <div className="container-fluid container-fluid-br headerwrap box-radius">
              <div className="blog-title">
              <h2 className="title">Blog MonKolis</h2>
                <hr></hr>
                <h5 className="txt-light">Histoires, conseils, secrets, astuces... On vous raconte tout !</h5>
              </div>
            </div>
            <div className="container-fluid container-fluid-pad container-fluid-br">
              <div className="row pad-top20 pad-bot20">
                <div className="col-sm-3">
                  <div className="list-group">
                    <li className="list-group-item bg-color-global txt-bold">Articles</li>
                    <a href="/blog/genesis" className="list-group-item list-group-item-action">La Génèse de MonKolis</a>
                    <a href="/blog/press-communication" className="list-group-item list-group-item-action">Communiqué de presse de lancement</a>
                    <a href="/blog/travel-to-Senegal" className="list-group-item list-group-item-action "> Visiter le Sénégal</a>
                    <a href="/blog/personnal-shopper" className="list-group-item list-group-item-action active"> Personnal Shopping</a>
                    <a href="/blog/covid" className="list-group-item list-group-item-action"> Les 3 destinations idéales à visiter pendant le COVID</a>
                    <a href="/blog/visit-Belgium" className="list-group-item list-group-item-action"> Visiter la Belgique</a>
                    <a href="/blog/monkolis-make-money" className="list-group-item list-group-item-action"> Gagner de l'argent avec Monkolis</a>
                    <a href="/blog/monkolis-mouve-out" className="list-group-item list-group-item-action"> Préparer son déménagement</a>
                    <a href="/blog/expedition" className="list-group-item list-group-item-action" > Expéditions à l'international</a>
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="section">
                    <div className="section-inner">
                    <h3 class="heading mar-bot0">LE PERSONAL SHOPPING</h3>
                    <img src={GUCCI} alt="Insérer une photo" className="cover rounded" />
                    <br/> <br/>
                <h3>Personal shopping, qu’est-ce que c’est ?</h3>
                <p>Le domaine de la mode a vu naître ces dernières années une tendance si incontournable que l’on se demande comment le monde a pu s’en passer jusque-là. Le personal shopping réservé au départ à une catégorie de personnes assez select est aujourd’hui un service de plus en plus accessible. MonKolis vous emmène aujourd’hui à la découverte de ce service désormais à votre portée.</p>

                <p>Le personal shopper est en pratique un conseiller de vente spécifiquement dédié à un client pour une période donnée. Le personal shopper peut être salarié d'un magasin, d'un service de conciergerie ou agir en "free-lance". Dans ce dernier cas, il est rémunéré par l'acheteur et / ou par une commission sur les ventes.<br />
                Les services de personal shopping sont surtout proposés, en général sur rendez-vous, par les grands magasins, par des marques de luxe pour leurs meilleurs clients. Ils accompagnent et conseillent le client au sein du point de vente ou se déplacent selon le domaine d'activité et le potentiel commercial du client. Ils exercent surtout dans le domaine du textile et de l'accessoire et jouent alors un rôle de coach / styliste.  On peut également en rencontrer dans d'autres domaines tel que la vente d'alcool très haut de gamme.</p>

                <h3>Le personal shopping chez MonKolis</h3>

                <p>MonKolis faisant de la mise en relation entre transporteurs et expéditeurs, a jugé nécessaire d’y associer un service de personal shopping à distance qui consistera à effectuer vos courses de par le monde et à vous les expédier chez vous. Les tendances modes, électroniques etc du monde entier vous seront désormais accessibles rien qu’en contactant l’un des différents personal shopper que la team MonKolis a sélectionné pour vous. 
                La mission des personals shoppers de MonKolis est de prendre en charge votre shopping à votre place, trouver rapidement la pièce ou le produit que vous recherchez et vous le faire parvenir le plus rapidement possible.</p>

                <h4>En pratique </h4> 
                1/ Accédez sur la liste des personals shoppers référencés sur MonKolis en <a href="https://www.monkolis.com" class="link"> cliquant ici </a>. <br />
                2/ Choisissez le personal shopper qui vous convient en fonction des différentes descriptions <br />
                3/ Contactez le personal shopper que vous avez choisi pour passer votre commande et régler les détails de la transaction <br />
                4/ Recevez vos commandes et attribuez une note au personal shopper en contactant MonKolis <br />

                <p>Vous êtes prêts à tester le service ? Rendez vous sur <a href="https://www.monkolis.com" class="link">www.monkolis.com</a> ou contactez la team MonKolis via nos différentes messageries Facebook, WhatsApp et Instagram.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}
export default PersonnalShopper;