export const CONNEXION_QUERY = `query ($username: String!, $password: String!){
  loginUser(username: $username, password:$password ){
    status
    message
    authentificated_user {
      idTtoken
      accessToken
      refreshToken
   },
   user {
    username
    userConfirmed
    userSub
    name
    given_name
    family_name
    email
    picture
    gender
    id_card_check_status
    account_type
    account_type_details {
      registration_number
      company_name
      company_country_name
    }
    address{
      street_address
      locality
      region
      postal_code
      country
    }
    phone_number
    city_of_residence
    additional_infos
    description
    groups
    birthdate
    contrib_cwf_balance
 }
 errors{
    param
    msg
  }
}
}
`


export const REGISTER_QUERY = `mutation($userinfos: userInput!) {
  registrateUser(userinfos: $userinfos) {
    status
    message
    user {
      username
      userConfirmed
      userSub
    }
    errors{
      param
      msg
    }
  }
}`


export const CONFIRMUSERCODE_QUERY = `mutation($username: String!, $confirmation_code: String!) {
  confirmAnAuthentificatedUser(username: $username, confirmation_code: $confirmation_code) {
    status
    operation
    message
    errors {
      param
      msg
    }
  }
}`

export const PASSWORDLOST_QUERY = `mutation ($username: String!) {
  forgottenPasswordUser(username: $username) {
    status
    operation
    message
    errors {
      param
      msg
    }
  }
}
`

export const NEW_PASSWORD_QUERY = `
  mutation($username: String!, $verification_code: String!, $new_password : String!) {
    confirmPasswordUser(username: $username, verification_code: $verification_code, new_password: $new_password) {
      status
      operation
      message
      errors {
        param
        msg
      }
    }
  }
`
export const RESEND_CONFIRM_CODE = `
query($username: String!) {
  resendConfirmationCodeUser(username: $username) {
    status
    operation
    message
    errors {
      param
      msg
    }
  }
}
`