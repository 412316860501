import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';
import './../../assets/css/blog.css';

class Blog extends Component {
  render() {
    return (

      <Fragment>
        <Helmet>
          <title>Blog | Monkolis</title>
          <meta name="robots" content="index,follow" />
        </Helmet>

        <div className="blog">
          <div className="main-box_content">
            <div className="container-fluid container-fluid-br headerwrap box-radius">
              <div className="blog-title">
                <h2 className="title">Blog MonKolis</h2>
                <hr></hr>
                <h5 className="txt-light">Histoires, conseils, secrets, astuces... On vous raconte tout !</h5>
              </div>
            </div>
            <div className="container-fluid container-fluid-pad container-fluid-br">
              <div className="row pad-top20 pad-bot20">
                <div className="col-sm-3">
                  <div className="list-group">
                    <li className="list-group-item bg-color-global txt-bold">Articles</li>
                    <a href="/blog/genesis" className="list-group-item list-group-item-action active">La Génèse de MonKolis</a>
                    <a href="/blog/press-communication" className="list-group-item list-group-item-action">Communiqué de presse de lancement</a>
                    <a href="/blog/travel-to-Senegal" className="list-group-item list-group-item-action"> Visiter le Sénégal</a>
                    <a href="/blog/personnal-shopper" className="list-group-item list-group-item-action"> Personnal Shopping</a>
                    <a href="/blog/covid" className="list-group-item list-group-item-action"> Les 3 destinations idéales à visiter pendant le COVID</a>
                    <a href="/blog/visit-Belgium" className="list-group-item list-group-item-action"> Visiter la Belgique</a>
                    <a href="/blog/monkolis-make-money" className="list-group-item list-group-item-action"> Gagner de l'argent avec Monkolis</a>
                    <a href="/blog/monkolis-mouve-out" className="list-group-item list-group-item-action"> Préparer son déménagement</a>
                    <a href="/blog/expedition" className="list-group-item list-group-item-action" > Expéditions à l'international</a>
                    {/* <a href="title2" className="list-group-item list-group-item-action">Article 2</a>
              <a href="title3" className="list-group-item list-group-item-action">Article 3</a>
              <a href="title4" className="list-group-item list-group-item-action">Article 4</a>
              <a href="title5" className="list-group-item list-group-item-action">Article 5</a>
              <a href="title6" className="list-group-item list-group-item-action">Article 6</a> */}
                  </div>
                </div>

                <div className="col-sm-9">
                  <div className="section">
                    <div className="section-inner">
                      <h3 className="heading">La Génèse de MonKolis</h3>
                      <p>
                        Fondée en janvier 2019 et basée à Paris, en France, MonKolis est une plate-forme communautaire de confiance permet d'envoyer et recevoir des kolis du pays.
                      </p>
                      <p>
                        Le transport de kolis à l'international n'aura jamais été aussi simple : nous mettons en relation des transporteurs et des expéditeurs qui veulent envoyer ou recevoir un kolis du pays. Grâce à notre service, le kolis de l'expéditeur arrive rapidement et en toute sureté tandis que le transporteur rentabilise son voyage et fait de belles rencontres. Tout le monde y gagne : les uns payent moins cher leur envoi et les autres remboursent une partie de leurs frais de transport ou augmentent leurs revenus, tout en rendant service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}
export default Blog;