import React, { Component } from 'react';
import Crow1 from './crow1';
import Crow2 from './crow2';
import './../../assets/css/crow.css';
import './../../assets/css/multiple.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

import { CONTRIBUTION_CAMPAIN_INFOS_QUERY } from './../../query/querycrowfunding';
import { OPEN_DATA_URL } from './../../Url';
import axios from 'axios';

class Crowfunding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infos: [],
    };

  }

  componentWillMount() {
    this.getCampainInfos(OPEN_DATA_URL)
  }

  getCampainInfos = async (url) => {
    try {
      const response = await axios.post(url,
        { "query": CONTRIBUTION_CAMPAIN_INFOS_QUERY },
      );

      // Log the response so we can look at it in the console
      //console.log(response.data)
      // Set the data to the state
      this.infos = response.data.data
      this.setState(() => ({
        isLoaded: true,
        infos: response.data.data,
      }));

    } catch (error) {
      // If there's an error, set the error to the state
      this.setState(() => ({ error }))
    }
  }

  render() {
    // PAGE TITLE
    document.title = 'CrowdFunding - Financer collectivement l\'envoi de vos kolis | Monkolis';

    //console.log(this.infos)
    return (
      <div className="application">
        <div className="crowdfunding">
          <div className="container-fluid container-fluid-pad container-fluid-br">
              {
                this.infos !== undefined ?
                  <div className="row pad-bot20">
                    <Crow1 data={this.infos} />
                    <Crow2 data={this.infos} />
                  </div>
                  : null
              }
          </div>
        </div>
      </div>
    );
  }
}
export default Crowfunding;