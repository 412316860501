import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple2.css';

class Legales extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Mentions légales | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="legales">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/legales" className="list-group-item list-group-item-action active">Mentions
                                            légales</a>
                                        <a href="/terms" className="list-group-item list-group-item-action">Conditions
                                            générales</a>
                                        <a href="/privacy" className="list-group-item list-group-item-action">Politique de
                                            Confidentialité</a>
                                        <a href="/cookies" className="list-group-item list-group-item-action">Utilisation
                                            des cookies</a>
                                        <a href="/prohibited" class="list-group-item list-group-item-action ">Produits interdits</a>
                                    </div>

                                </div>
                                <div className="col-sm-9">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Mentions légales</h1>
                                            <p> Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour
                                            la confiance en l'économie numérique, il est précisé aux utilisateurs du
                                            Site l'identité des différents intervenants dans le cadre de sa
                                            réalisation et de son suivi.
                                            </p>

                                            <h3 className="f-heading-three">Editeur du site</h3>
                                            <p>Le site www.monkolis.com est édité par la société TAMODE SAS (Société en
                                            cours d’immatriculation) au capital de 10 000 euros, en cours
                                            d’immatriculation au Registre de Commerce et des Sociétés de Paris set
                                                ayant son siège social à Paris.</p>
                                            <p>Email : <a href="mailto:hello@monkolis.com"
                                                className="color-blue">hello@monkolis.com</a></p>

                                            <h3 className="f-heading-three">Responsable de publication</h3>
                                            <p>Monsieur Mobio Chrysostom, Président</p>

                                            <h3 className="f-heading-three">Hébergeur</h3>
                                            <p>Le site www.monkolis.com est hébergé par la société Amazon Web Services
                                            EMEA SARL, Succursale Française, 31 place des corolles, Tour Carpe Diem,
                                            92400 Courbevoie, France. Le stockage des données personnelles des
                                            utilisateurs est exclusivement réalisé sur les centres de données
                                            ("clusters") de la société Amazon Inc, dont le siège social est situé 10
                                            Terry Avenue North, Seattle, WA. Tous les clusters Amazon sur lesquels
                                            les données du Site sont stockées sont localisés dans des Etats membres
                                                de l'Union Européenne</p>

                                            <h3 className="f-heading-three">Charte de confidentialité</h3>
                                            <p>Découvrez plus sur notre <a href="/privacy">charte de confidentialité</a>
                                            </p>


                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Legales;