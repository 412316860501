export const GET_ALERT_QUERY = `query {
    getUserExtraParameters {
      status
      message
      user_extra_parameters{
        id
        search_alerts{
          city_departure_name
          city_arrival_name
          departure_date_start
          departure_date_end
        }
      }
      errors{
        param
        msg
      }
    }
  }
  `  
export const ADD_ALERT_QUERY = `mutation($user_extra_parameters: UserExtraParametersInput!) {
    updateUserExtraParameters(user_extra_parameters: $user_extra_parameters) {
      status
      message
      user_extra_parameters{
        id
        search_alerts{
          city_departure_name
          city_arrival_name
          departure_date_start
          departure_date_end
        }
      }
      errors{
        param
        msg
      }
    }
  }
`  

export const DELETE_ALERT_QUERY = `mutation($user_extra_parameters: UserExtraParametersInput!) {
    removeUserExtraParameters(user_extra_parameters: $user_extra_parameters) {
      status
      message
      user_extra_parameters{
        id
        search_alerts{
          city_departure_name
          city_arrival_name
          departure_date_start
          departure_date_end
        }
      }
      errors{
        param
        msg
      }
    }
  }
`
