import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
// import './../../../assets/css/main.css';
import './../../../assets/css/multiple.css';
import './../../../assets/css/dashboard.css';
import './../../../assets/css/modal.css';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { UPDATE_BANK_INFOS_QUERY, GET_BANK_ACCOUNT_QUERY } from '../../../query/queryprofil';
import { USER_INFO_URL } from '../../../Url';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.countryList = countryList().getData()
        this.state = {
            countryList: this.countryList,
            error: [],
            account_infos: [],
            account_owner: '',
            country: '',
            countryTmp: '',
            bank_name: '',
            iban: '',
            bic: '',
            validated: false,
            msgAlertBody: '',
            msgAlertHeader: '',
            alertVariant: 'danger',
            showAlert: false,
        }

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleAccount_ownerChange = this.handleAccount_ownerChange.bind(this);
        this.handleBank_nameChange = this.handleBank_nameChange.bind(this);
        this.handleIbanChange = this.handleIbanChange.bind(this);
        this.handleBicchange = this.handleBicchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    componentWillMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
        this.getBankAccountInfos(GET_BANK_ACCOUNT_QUERY, this.idToken)
    }

    getBankAccountInfos = async (query, idToken) => {
        try {
            const response = await axios.post(USER_INFO_URL,
                { query },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );
        
            if (response.data.data.getUserExtraParameters.status === 200) {
                this.setState({
                    account_infos: response.data.data.getUserExtraParameters.user_extra_parameters.banks_details
                });
            }

            if (this.state.account_infos.length !== 0) {
                this.setState({
                    account_owner: response.data.data.getUserExtraParameters.user_extra_parameters.banks_details.titulaire,
                    bank_name: response.data.data.getUserExtraParameters.user_extra_parameters.banks_details.bank_name,
                    iban: response.data.data.getUserExtraParameters.user_extra_parameters.banks_details.iban,
                    bic: response.data.data.getUserExtraParameters.user_extra_parameters.banks_details.bic_or_swift,
                    countryTmp: { 'value': countryList().getValue(response.data.data.getUserExtraParameters.user_extra_parameters.banks_details.pays_domiciliation), 'label': response.data.data.getUserExtraParameters.user_extra_parameters.banks_details.pays_domiciliation },
                    country: response.data.data.getUserExtraParameters.user_extra_parameters.banks_details.pays_domiciliation
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleCountryChange(event) {
        this.setState({
            countryTmp: event,
            country: event.label
        });
    }


    handleAccount_ownerChange(event) {
        this.setState({ account_owner: event.target.value });
    }

    handleBank_nameChange(event) {
        this.setState({ bank_name: event.target.value });
    }

    handleIbanChange(event) {
        this.setState({ iban: event.target.value });
    }

    handleBicchange(event) {
        this.setState({ bic: event.target.value });
    }

    handleCloseAlert(event) {
        this.setState({ showAlert: false });
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            this.setState(() => ({
				validated: true
			}));
            var VAR = JSON.stringify({
                "user_extra_parameters": {
                    "banks_details": {
                        "bank_name": this.state.bank_name,
                        "iban": this.state.iban,
                        "bic_or_swift": this.state.bic,
                        "titulaire": this.state.account_owner,
                        "pays_domiciliation": this.state.country
                    }

                }
            });
            window.confirm('Modifier vos coordonnées bancaires ?') ? this.updateBankAccount(UPDATE_BANK_INFOS_QUERY, VAR) : window.close()


        }

    }



    updateBankAccount = async (query, variables) => {
        try {
            const response = await axios.post(USER_INFO_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            if (response.data.data.updateUserExtraParameters.status === 200) {
                this.setState(() => ({
					showAlert : true,
					msgAlertBody : 'Modification effectuée',
					msgAlertHeader : 'Done',
					alertVariant : 'success'
				}));
                alert('Modification effectuée');
                window.location.reload(false);
            } else {
                this.setState(() => ({
					showAlert : true,
					msgAlertBody : 'Modification échoué',
					msgAlertHeader : 'Error',
					alertVariant : 'danger'
				}));
                alert('Modification échoué');
            }



        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            console.log(error)
        }
    }

    render() {
        return (
            <div class="mymoney">

                <div class="section">
                    <div class="section-inner">
                        <h3 class="heading">Préférence de virements</h3>
                        <p>	Pour la phase de lancement de la plateforme MonKolis, le règlement des transactions s'effectue en direct entre l'expéditeur et le transporteur.</p>

                        {/*{this.state.showAlert ?
                            <Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert} dismissible>
                                <Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
                                <p>{this.state.msgAlertBody} </p>
                            </Alert> : ''}*/}

                        {/*<h5 class="">Compte bancaire <span class="fa fa-1x fa-bank"></span></h5>
                        <p>                           

                            Recevez votre argent directement sur votre compte bancaire.
                            Une fois le virement demandé, il faut compter de 1 à 5 jours ouvrés pour que les sommes soient visibles sur votre compte.

                        </p>*/}

                        {/*<Form class="" validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="PastPassword" class="">Pays de domiciliation</label>
                                    <Select
                                        options={this.state.countryList}
                                        value={this.state.countryTmp === 'null' ? '' : this.state.countryTmp}
                                        onChange={this.handleCountryChange}
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Titulaire du compte</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            defaultValue={this.state.account_owner}
                                            onChange={this.handleAccount_ownerChange}
                                        />
                                    </Form.Group>
                                    <label for="titulaire" class="">Titulaire du compte</label>
                                        <input type="text" class="form-control" id="titulaire" value={this.state.account_owner} onChange={this.handleAccount_ownerChange}/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Nom de la banque</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            defaultValue={this.state.bank_name}
                                            onChange={this.handleBank_nameChange}
                                        />
                                    </Form.Group>
                                    <label for="bank" class="">Nom de la banque</label>
                                    <input type="text" class="form-control" id="bank" value={this.state.bank_name} onChange={this.handleBank_nameChange} />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-9">
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>IBAN (lettres incluses)</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            defaultValue={this.state.iban}
                                            onChange={this.handleIbanChange}
                                        />
                                    </Form.Group>
                                    <label for="iban" class="">IBAN (lettres incluses)</label>
                                    <input type="text" class="form-control" id="iban" value={this.state.iban} onChange={this.handleIbanChange} />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-9">
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>BIC (ou SWIFT)</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            defaultValue={this.state.bic}
                                            onChange={this.handleBicchange}
                                        />
                                    </Form.Group>
                                    <label for="bic" class="">BIC (ou SWIFT)</label>
                                    <input type="text" class="form-control" id="bic" value={this.state.bic} onChange={this.handleBicchange} />
                                </div>
                            </div>
                            <input type="submit" className="btn btn-theme2" value="Enregistrer" />
                        </Form> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MyAccount);