import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Price extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Tarif du cobagage | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="price-infos">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/rules" className="list-group-item list-group-item-action">Charte de
                                            bonne conduite</a>
                                        <a href="/tips" data-toggle="tab"
                                            className="list-group-item list-group-item-action">Conseils prévention</a>
                                        <a href="/price" data-toggle="tab"
                                            className="list-group-item list-group-item-action active">Prix du
                                            cobagage</a>

                                    </div>

                                </div>
                                <div className="col-sm-9">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Prix du cobagage</h1>
                                            <p>MonKolis offre un service dans l’esprit de l’économie collaborative dont
                                            le but est de faire des économies au quotidien tout en contribuant au
                                                développement durable. </p>

                                            <p>Le transport de kolis à l'international n'aura jamais été aussi simple :
                                            nous mettons en relation des transporteurs et des expéditeurs qui
                                                veulent envoyer ou recevoir un kolis du pays.</p>
                                            <p>Grâce à notre service, le kolis de l'expéditeur arrive rapidement et en
                                            toute sureté tandis que le transporteur rentabilise son voyage et fait
                                            de belles rencontres. Tout le monde y gagne : les uns payent moins cher
                                            leur envoi et les autres remboursent une partie de leurs frais de
                                                transport ou augmentent leurs revenus, tout en rendant service.</p>

                                            <h2 className="f-heading-three">Le tarif du transport est librement fixé par
                                                le transporteur.</h2>
                                            <p>A ce tarif, MonKolis ajoute des frais de service qui lui servent à payer
                                            les différentes charges de fonctionnement de la plateforme et
                                            fonctionnement du service (hébergement, assurance...)
                                            </p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Price;