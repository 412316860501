export const SEARCHTRAVEL_QUERY =`
query ($search_travel_proposal: SearchTravelerProposalInput) {
  searchTravel(search_travel_proposal: $search_travel_proposal) {
    status
    message
    travel_proposals {
      id_travel
      id_user_proposal
      registration_date
      departure_date
      departure_city
      arrival_city
      lng_departure_city
      lat_departure_city
      lng_arrival_city
      lat_arrival_city
      has_check_ticket
      max_weight_available
      min_weight_booking
      proposal_amount_by_kg
      booking_deadline
      service_charge_amount
      is_automatic_approval
      transport_company
      others_infos
      take_off_place
      recovery_place
      status
      transportation_type
      transportation_condition
      measure_unit
      arrival_date
      volume
      weigth_available_info {
        weigth_available
        last_update_date
      }
      travel_type_info {
        type_info
        specific_info
        travel_id_depart
        travel_id_return
      }
      traveler_cancel_info {
        traveler_cancel_date
        send_mails_to_requesters
        comment
      }
      system_traveler_reimbursment_info {
        date
        reimbursment_date
        reimbursment_amount
        status
      }
      user_proposal_attributes {
        email_verified
        account_type
        account_type_details {
          registration_number
          company_name
          company_country_name
        }
        phone_number
        username
        name
        family_name
        given_name
        picture
        birthdate
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        email
        gender
        city_of_residence
        additional_infos
        description
        last_update_date
     }
    }
    errors{
      param
      msg
    }
  }
}
`

export const NOT_CONNECTED_SEARCHTRAVEL_QUERY =`
query ($search_travel_proposal: SearchTravelerProposalInput) {
  searchTravel(search_travel_proposal: $search_travel_proposal, limit: 3) {
    status
    message
    travel_proposals {
      id_travel
      id_user_proposal
      registration_date
      departure_date
      departure_city
      arrival_city
      has_check_ticket
      max_weight_available
      min_weight_booking
      proposal_amount_by_kg
      booking_deadline
      service_charge_amount
      is_automatic_approval
      transport_company
      others_infos
      take_off_place
      recovery_place
      measure_unit
      transportation_type
      volume
      arrival_date
      status
      weigth_available_info {
        weigth_available
        last_update_date
      }
      traveler_cancel_info {
        traveler_cancel_date
        send_mails_to_requesters
        comment
      }
      system_traveler_reimbursment_info {
        date
        reimbursment_date
        reimbursment_amount
        status
      }
      user_proposal_attributes {
        email_verified
        phone_number
        username
        name
        family_name
        given_name
        picture
        birthdate
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        email
        gender
        city_of_residence
        additional_infos
        description
        last_update_date
     }
    }
    errors{
      param
      msg
    }
  }
}
`
export const RESERVE_TRAVEL_QUERY = `mutation($requester_proposal: RequesterProposalInput!) {
  requestProposalTravel(requester_proposal: $requester_proposal) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_user_proposal
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      service_charge_amount
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     user_traveler_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
       measure_unit
     }
     package_received_delivered_infos {
       received_code
       received_code_setup_date
       received_code_validation_date
       delivered_code
       delivered_code_setup_date
       delivered_code_validation_date
     }
    }
    errors{
      param
      msg
    }
  }
}`