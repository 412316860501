import React, { Component } from 'react';

import './../../assets/css/multiple.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

import rencontre2 from './../../assets/img/other/rencontre2.png';
import proposition from './../../assets/img/other/proposition.png';
import camembert from './../../assets/img/other/Camembert.png';

import user2 from './../../assets/img/user/user2.jpg';
import user4 from './../../assets/img/user/user4.jpg';
import user7 from './../../assets/img/user/user7.jpg';
import user0 from './../../assets/img/user/user0.png';
import user16 from './../../assets/img/user/user16.jpg';
import user6 from './../../assets/img/user/user6.jpg';
import DEFAULT_PHOTO from '../../assets/img/user/user0.png';
import StarRatings from 'react-star-ratings';

class Crow1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infos: [],
    };
    this.datas = this.props.data.getDetailsAboutLastActiveCrowFundingCampaignInfos
  }

  render() {
    return (
      <div className="col-md-8">
        <section className="describe section">
          <div className="section-inner">

            <h3 className="title align-center txt-bold">Crowfunding - Double Cash Back</h3>

            <h4 className="color-text2 align-center">Donnez <span className="color-green">10 €</span>
              disposez du double <span className="color-green">20 €</span></h4>

            <img src={rencontre2} alt="Rencontre" className="mar" />
            <div className="share">
              <a type="btn" href="/" className="btn-facebook btn-sm"><i className="fa fa-thumbs-o-up"></i> J'aime <span>3 476</span></a>
              <a type="btn" href="/" className="btn-facebook btn-sm"><i className="fa fa-share"></i> Je partage</a>
              <a type="btn" href="/" className="btn-secondary btn-sm"><i className="fa fa-envelope"></i> Email</a>
            </div>
            <h6 className="title mar-bot0"><span className="fa fa-1x fa-book color-green"></span> A propos de MonKolis</h6>
            <hr></hr>
            <img src={proposition} alt="MDN Logo" className="proposition" />

            <p> Fondée en janvier 2019 et basée à Paris, en France, MonKolis est une plate-forme communautaire de confiance permet d'envoyer et recevoir des kolis du pays.
                    <br /><br /> MonKolis met en relation des voyageurs et des expéditeurs qui veulent envoyer ou recevoir un kolis du pays et grâce à notre service, le kolis de l'expéditeur arrive rapidement et en toute sureté tandis que le voyageur rentabilise son voyage et fait de belles rencontres.
                          </p>



            <a href="work" className="link color-blue"> Comment ça marche →</a><br />

            <h6 className="title mar-bot0 mar-top30"><span className="fa fa-1x fa-pie-chart color-green"></span> Utilisation des fonds</h6>
            <hr></hr>

            <p>  <span className="txt-bold">Notre envol dépend aujourd'hui de vous.</span> Cette campagne de préréservations nous permettra de poursuivre le développement de notre entreprise.

                      <br />
              Pendant toute la durée de la campagne, les versements que vous faites sont doublés et serviront à vos futures expéditions de kolis. C'est l'occasion pour vous de prendre part à un projet ambitieux.

                <br />

              Les montants collectés serviront à parfaire notre plateforme, à amplifier notre campagne de communication et à agrandir notre équipe.</p>

            <div className="row mar-top10">
              <div className="col-md-5">
                <img src={camembert} alt="MDN Logo" className="cover" />
              </div>
              <div className="col-md-7">
                <h6 className="color-blue mar-top0">Développement de la plateforme</h6>
                <p className="color-text2">Développement de la v2 du site monkolis.com et des applications mobiles</p>


                <h6 className="color-blue mar-top0">Recrutement de talents</h6>
                <p className="color-text2">Un stagiaire business developer, un alternant Chargé de communication et
                                          un développeur full stack</p>


                <h6 className="color-blue mar-top0">Campagne de marketing</h6>
                <p className="color-text2">Développement de supports de communicatio et publicité sur les réseaux sociaux.</p>
              </div>
            </div>

            <h6 className="title mar-bot0 mar-top30"><span className="fa fa-1x fa-child color-green"></span> Les porteurs du projet</h6>
            <hr></hr>

            <div className="row mar-top10 mar-bot20 porteur">
              <div className="col-md-4">
                <img src={user2} alt="Profil" className="cover rounded" />
                <h5 className="align-center mar-bot0 mar-top0">Demel Y.</h5>
              </div>
              <div className="col-md-4">
                <img src={user4} alt="Profil" className="cover rounded" />
                <h5 className="align-center mar-bot0 mar-top0"> Chryso M.</h5>
              </div>
              <div className="col-md-4">
                <img src={user7} alt="Profil" className="cover rounded" />
                <h5 className="align-center mar-bot0 mar-top0"> Cyrille T.</h5>
              </div>

            </div>

            <p> Notre histoire, c'est d'abord une belle histoire d'amitié et fraternité. <br /><br />Cela fait plus de 12 ans qu'on se connait et depuis les bancs de l'école d'ingénieur à Yamoussoukro jusqu'à nos bureaux respectifs à Paris, on n'a
              pas arreté de partager nos projets, nos ambitions... MonKolis n'en est qu'un parmi tant d'autres.
                    </p>

            <a href="founders" className="link color-blue"> Nous connaitre davantage →</a><br />

            <h6 className="title mar-bot0 mar-top30"><span className="fa fa-1x fa-group color-green"></span> Les participants (Mille fois Merci !!!)</h6>
            <hr></hr>

            <div className="row mar-top20 participants">
              {this.datas.contributions.map((value, index) => {
                return <div class="item" key={index} >
                  <div className="col-md-4">
                    <div className="media">
                      <div className="media-left">
                        {value.user_contributor_attributes.picture === 'null' || value.is_remain_anonymous_name === "Y"?
                          <img src={DEFAULT_PHOTO} alt="MDN Logo" className="cover rounded" /> :
                          <img src={value.user_contributor_attributes.picture} alt="MDN Logo" className="cover rounded" />
                        }

                      </div>
                      <div className="media-body mar-left5">
                        <h6 className="mar-top0 mar-bot5">{value.is_remain_anonymous_name === "N" ? value.user_contributor_attributes.given_name : "Anonyme"}</h6>
                        <p className="mar-bot0">{ value.is_remain_anonymous_amount === "N" ? value.transaction_amount + " €": "Anonyme"} </p>
                      </div>
                    </div>
                  </div>
                </div>
              })}

              <div className="col-md-4 jeparticipe">
                <a href="#top" className="link color-blue"> Je veux contribuer →</a><br />

              </div>
            </div>

            <h6 className="title mar-bot0 mar-top30"><span className="fa fa-1x fa-tags color-green"></span> Vos avis et conseils (Encore Merci !!!)</h6>

            <div className="testimonials">
              {this.datas.contributions.map((value, index) => {
                return <div class="item" key={index} >
                    <div class="quote">
                      <StarRatings
                        rating={parseInt(value.mark)}
                        starRatedColor="red"
                        numberOfStars={5}
                        name='rating'
                        starDimension='20px'
                        starSpacing='0,5px'
                      />
                      <p>{value.remark !== '' ? value.remark : ''}</p>
                
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </section>       
      </div> 
    )
  }
}

export default Crow1;