import React, {Component, Fragment} from 'react';
import {Helmet} from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Cookies extends Component {
    render() {
        return (

            <Fragment>
                <Helmet>
                    <title>Utilisation des cookies | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="cookies">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/legales" className="list-group-item list-group-item-action">Mentions
                                            légales</a>
                                        <a href="/terms" className="list-group-item list-group-item-action">Conditions
                                            générales</a>
                                        <a href="/privacy" className="list-group-item list-group-item-action">Politique de
                                            Confidentialité</a>
                                        <a href="/cookies" className="list-group-item list-group-item-action active">Utilisation
                                            des cookies</a>
                                        <a href="/prohibited" class="list-group-item list-group-item-action">Produits interdits</a>
                                    </div>

                                </div>
                                <div className="col-sm-9">
                                    <div className="section">
                                        <div className="section-inner">

                                            <h1 className="f-heading">Utilisation des cookies</h1>
                                            <p>Cette section du site est en cours de rédaction. N'hésitez pas à <a
                                                href="https://www.facebook.com/MonKolis/" className="link" target="_blank">contacter
                                                notre équipe</a> en cas de besoin.
                                            </p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Cookies;