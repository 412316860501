import React, { Component } from 'react';
// import './../../../assets/css/main.css';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';
import Beginners from './beginners';
import Payements from './payments';
import Resiliations from './resiliations';
import Senders from './senders';
import Travelers from './travelers';
import Others from './others';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import { Helmet } from 'react-helmet';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error: [],
            renderBeginners: true,
            renderPayements: false,
            renderResiliations: false,
            renderSenders: false,
            renderTravelers: false,
            renderOthers: false,

        }


        this.handleBeginnersClick = this.handleBeginnersClick.bind(this);
        this.handlePayementsClick = this.handlePayementsClick.bind(this);
        this.handleResiliationsClick = this.handleResiliationsClick.bind(this);
        this.handleSendersClick = this.handleSendersClick.bind(this);
        this.handleTravelersClick = this.handleTravelersClick.bind(this);
        this.handleOthersClick = this.handleOthersClick.bind(this);
    }

    handleBeginnersClick(event) {
        this.setState({
            renderBeginners: true,
            renderPayements: false,
            renderResiliations: false,
            renderSenders: false,
            renderTravelers: false,
            renderOthers: false,

        })
    }

    handlePayementsClick(event) {
        this.setState({
            renderBeginners: false,
            renderPayements: true,
            renderResiliations: false,
            renderSenders: false,
            renderTravelers: false,
            renderOthers: false,

        })
    }

    handleResiliationsClick(event) {
        this.setState({
            renderBeginners: false,
            renderPayements: false,
            renderResiliations: true,
            renderSenders: false,
            renderTravelers: false,
            renderOthers: false,

        })
    }

    handleSendersClick(event) {
        this.setState({
            renderBeginners: false,
            renderPayements: false,
            renderResiliations: false,
            renderSenders: true,
            renderTravelers: false,
            renderOthers: false,
        })
    }

    handleTravelersClick(event) {
        this.setState({
            renderBeginners: false,
            renderPayements: false,
            renderResiliations: false,
            renderSenders: false,
            renderTravelers: true,
            renderOthers: false,
        })
    }

    handleOthersClick(event) {
        this.setState({
            renderBeginners: false,
            renderPayements: false,
            renderResiliations: false,
            renderSenders: false,
            renderTravelers: false,
            renderOthers: true,
        })
    }




    render() {
        return (
        <div id="pagetile">
            <Helmet>
                <title>Foires aux questions pour en savoir plus sur Monkolis | Monkolis </title>
                <meta name="robots" content="index,follow"/>
            </Helmet>

        <div className="faq">
            <div className="main-box_content">
            <div className="container-fluid container-fluid-pad container-fluid-br">
                <div id="secondNav">
                    <div className="row pad-top20 pad-bot20">
                        <div className="col-sm-3 mar-bot20">
                            <div className="list-group mar-bot20">
                                    <h2 class="list-group-item bg-color-global txt-bold">FAQ</h2>

                                {
                                    this.state.renderBeginners ?
                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleBeginnersClick}>Premiers pas sur MonKolis</a> :
                                        <a className="list-group-item list-group-item-action" onClick={this.handleBeginnersClick}>Premiers pas sur MonKolis</a>
                                }


                                {
                                    this.state.renderPayements ?
                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handlePayementsClick}>Paiements</a> :
                                        <a className="list-group-item list-group-item-action" onClick={this.handlePayementsClick}>Paiements</a>
                                }


                                {
                                    this.state.renderResiliations ?
                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleResiliationsClick}>Annulation &amp; Absence</a> :
                                        <a className="list-group-item list-group-item-action" onClick={this.handleResiliationsClick}>Annulation &amp; Absence</a>
                                }



                                {
                                    this.state.renderSenders ?
                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleSendersClick}>Vous êtes un Expéditeur</a> :
                                        <a className="list-group-item list-group-item-action" onClick={this.handleSendersClick}>Vous êtes un Expéditeur</a>
                                }


                                {
                                    this.state.renderTravelers ?
                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleTravelersClick}>Vous êtes un Transporteur</a> :
                                        <a className="list-group-item list-group-item-action" onClick={this.handleTravelersClick}>Vous êtes un Transporteur</a>
                                }


                                {
                                    this.state.renderOthers ?
                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleOthersClick}>D'autres questions ?</a> :
                                        <a className="list-group-item list-group-item-action" onClick={this.handleOthersClick}>D'autres questions ?</a>
                                }

                            </div>

                            </div>
                            <div className="col-sm-9">
                                {this.state.renderBeginners ? <Beginners /> : null}
                                {this.state.renderPayements ? <Payements /> : null}
                                {this.state.renderResiliations ? <Resiliations /> : null}
                                {this.state.renderSenders ? <Senders /> : null}
                                {this.state.renderTravelers ? <Travelers /> : null}
                                {this.state.renderOthers ? <Others /> : null}
                            </div>
                    </div>
                </div>

            </div>
        </div>
        </div>
        </div>
                
            
        );
    }
}
export default withRouter(Faq);