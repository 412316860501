import React, { Component} from 'react';

class AvisClient1 extends Component{
    render() {
        return(
            <div className="card-body">
              <div className="testimonials">
                <div class="item">
                  <div class="quote">
                      <p>
                      <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                      <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                      <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                      <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                      <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                      James is an excellent software engineer and he is passionate about what he does. You can totally count on him to deliver your projects!</p>
                  </div>
                  <p class="source"><span class="name txt-bold">Anonyme</span> &nbsp; <span class="date">XX €</span></p>
                </div>

                <div className="item">
                <div className="quote">
                    <p>
                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                    <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                    James is an excellent software engineer and he is passionate about what he does. You can totally count on him to deliver your projects!</p>
                </div>
                <p className="source"><span className="name txt-bold">Tim Adams</span> &nbsp; <span className="date">5 €</span></p>
                </div>
                <div className="item">
                <blockquote className="quote">
                    <p>
                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                    <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                    James is an excellent software engineer and he is passionate about what he does. You can totally count on him to deliver your projects!</p>
                </blockquote>
                <p className="source"><span className="name txt-bold">Tim Adams</span> &nbsp; <span className="date">15 €</span></p>
                </div>
                </div>
        </div>
        )
    }
}
export default AvisClient1;