import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/annex.css';
import './../../../assets/css/modal.css';
import './../../../assets/css/blog.css';
import RUE from './../../../assets/img/blog/rue.jpg';
import TCHEP from './../../../assets/img/blog/tchep.jpg';
import ART from './../../../assets/img/blog/grigri.jpg';
import VILLAGE from './../../../assets/img/blog/village.png';

class Senegal extends Component {
  render() {
    return (

      <Fragment>
        <Helmet>
          <title>5 bonnes raisons de visiter le Sénégal | MonKolis</title>
          <meta name="robots" content="index,follow" />
        </Helmet>

        <div className="blog">
          <div className="main-box_content">
            <div className="container-fluid container-fluid-br headerwrap box-radius">
              <div className="blog-title">
                <h2 className="title">Blog MonKolis</h2>
                <hr></hr>
                <h5 className="txt-light">Histoires, conseils, secrets, astuces... On vous raconte tout !</h5>
              </div>
            </div>
            <div className="container-fluid container-fluid-pad container-fluid-br">
              <div className="row pad-top20 pad-bot20">
                <div className="col-sm-3">
                  <div className="list-group">
                    <li className="list-group-item bg-color-global txt-bold">Articles</li>
                    <a href="/blog/genesis" className="list-group-item list-group-item-action">La Génèse de MonKolis</a>
                    <a href="/blog/press-communication" className="list-group-item list-group-item-action">Communiqué de presse de lancement</a>
                    <a href="/blog/travel-to-Senegal" className="list-group-item list-group-item-action active"> Visiter le Sénégal</a>
                    <a href="/blog/personnal-shopper" className="list-group-item list-group-item-action"> Personnal Shopping</a>
                    <a href="/blog/covid" className="list-group-item list-group-item-action"> Les 3 destinations idéales à visiter pendant le COVID</a>
                    <a href="/blog/visit-Belgium" className="list-group-item list-group-item-action"> Visiter la Belgique</a>
                    <a href="/blog/monkolis-make-money" className="list-group-item list-group-item-action"> Gagner de l'argent avec Monkolis</a>
                    <a href="/blog/monkolis-mouve-out" className="list-group-item list-group-item-action"> Préparer son déménagement</a>
                    <a href="/blog/expedition" className="list-group-item list-group-item-action " > Expéditions à l'international</a>
                  </div>
                </div>

                <div className="col-sm-9">
                  <div className="section">
                    <div className="section-inner">
                    <img src={RUE} alt="Insérer une photo" className="cover rounded" />
                    <br/> <br/>
                    <h3 class="heading mar-bot0">5 RAISONS DE VISITER LE SENEGAL</h3>
                    <p> Cet article fait partie d'une longue série dans laquelle MonKolis vous fait découvrir les richesses des pays africains. Commençons notre aventure par 
le Sénégal, un magnifique pays d'Afrique de l'Ouest qui compte environ 17 millions d'habitants. Le français est la langue officielle, le wolof est la langue nationale, et une multitude d'autres langues locales y sont parlées. Le Sénégal possède de magnifiques plages, des jungles tropicales et de vastes déserts.
Si vous hésitiez encore, voici 5 raisons qui vous donneront surement envie de visiter ce pays.</p> 

<h3>1/ LA TERANGA</h3>
<p>L’incroyable hospitalité des Sénégalais est une chose tellement ancrée dans le tissu culturel du pays qu'on la désigne par un mot en wolof, la téranga. La téranga est plus qu'une simple hospitalité. C'est l'idée qu'il faut être accueillant pour tout le monde, même s'ils sont d'un système de croyance différent, d'une autre partie du monde, etc. C'est l'idée qu'il faut prendre soin de tout le monde.</p> 

<h3>2/ LA NOURRITURE SÉNÉGALAISE</h3>
<p>La cuisine sénégalaise est issue d’un métissage culturel et a été influencée par des traditions culinaires étrangères. Ceci en fait sa richesse. Ne soyez donc pas surpris d’y retrouver des plats familiers tels que le mafé et la sauce gombo. </p> 

<h4>LE THIEBOU DIEN et non TCHEP</h4>
<img src={TCHEP} alt="Insérer une photo" className="cover rounded" />
<br/> <br/>
<p>Le Thiebou dien, le plat phare du pays, est un délicieux mélange de riz, de poisson et de légumes, assaisonné d'un savoureux mélange d'épices. </p>

<h4>LE YASSA</h4>
<p>Le Yassa est un poulet avec un merveilleux tas d'oignons caramélisés sur le dessus, qui sont souvent cuits avec de la moutarde et du vinaigre pour un peu de saveur pour équilibrer la douceur. Le Yassa se fait aussi avec du poisson et cela ne change rien à sa saveur.</p> 

<h4>LE MAFÉ</h4>
<p>Le mafé est du bœuf ou du poisson cuit dans une sauce dense et riche à base de pâte d'arachide, et est bien sûr servi sur du riz.</p> 

<h4>LA SOUPOUKANDIA</h4>
<p>La soupoukandia ou la sauce gombo est un plat à découvrir si vous ne le connaissez pas déjà, au Sénégal il est généralement fait avec du poisson et se consomme avec du riz.</p>

<h4>LES JUS</h4>
<p>La plupart des Sénégalais ne boivent pas d'alcool pour des raisons religieuses, ils ont donc une grande variété de jus aussi désaltérant les uns que les autres. Au bissap qui est fait à partir des pétales de fleurs d'hibiscus les Sénégalais ajoutent souvent de la menthe ou encore du lait. Ils font aussi du délicieux jus de gingembre, ainsi que du jus de baobab qu’ils appellent  bouye. </p>

<h3>3/ L'HARMONIE RELIGIEUSE</h3>

<img src={ART} alt="Insérer une photo" className="cover rounded" />
<br/> <br/>
 
<p>Le Sénégal est essentiellement musulman (environ 95%). Environ 4 % du pays est chrétien (principalement catholique), et d'autres pratiquent des religions traditionnelles (qui sont souvent aussi incorporées dans les religions monothéistes). On pourrait s'attendre à ce qu'il y ait des divisions, ou du moins des tensions, mais il n'y en a pas.
Conformément au concept de la téranga, les Sénégalais coexistent pacifiquement malgré leurs différences de religion. Les musulmans préparent des plats spéciaux pour leurs amis et leur famille chrétiens à Noël et à Pâques, et les chrétiens leur rendent la pareille pendant les fêtes musulmanes. Il arrive qu'il y ait des musulmans et des chrétiens dans la même famille.</p>

<h3>4/ L’ART</h3>
<img src={VILLAGE} alt="Insérer une photo" className="cover rounded" />
<br/> <br/>
<p>Le Sénégal compte d'innombrables musiciens, danseurs et artistes visuels étonnants.
Dakar a une scène artistique florissante, avec des tonnes de petites galeries et de grands musées qui peuvent être explorés. Il y a aussi beaucoup de marchés locaux dans tout le pays où les artisans présentent leurs produits, notamment des sacs, des vêtements, des décorations, des chapeaux, des bijoux, et bien d'autres choses encore.
Chaque année, un festival de jazz de renommée mondiale a lieu dans la petite ville de Saint Louis. Les artistes et les amateurs de musique viennent du monde entier pour visiter la ville et apprécier la musique.</p>

<h3>5/ L’HISTOIRE</h3>
<p>C'est une terrible vérité que des millions d'Africains ont été enlevés du Sénégal ou sont passés par ce pays pour être vendus comme esclaves dans différentes parties du monde. Cette tragédie est commémorée au Sénégal sur l'île de Gorée, au large des côtes de Dakar. Vous pouvez y visiter la Maison des Esclaves, où ceux qui avaient été capturés et réduits en esclavage étaient gardés avant d'être entassés dans des bateaux et de traverser l'Atlantique, pour ne jamais revenir chez eux.
L'île de Gorée est un endroit difficile à visiter. Il est douloureux de penser au mal qui s'y est produit. La beauté de l'île ne convient pas à son sombre passé. Il est difficile de ne pas se sentir profondément attristé pendant la visite, c’est dur d’imaginer que ses ancêtres ont été retenus captifs dans ces minuscules pièces avant d'être arrachés à leurs maisons et à leurs familles et contraints à une vie d'esclavage.
Malgré la difficulté, c'est un endroit important à visiter. </p>

<p>Visiter le Sénégal sera une belle aventure mais à défaut vous pouvez aussi vous faire expédier des articles typiques de ce beau pays. Pourquoi pas une sculpture ou une étoffe de l’île de Gorée ? Vous aurez l’embarras du choix… Pour le transport du Sénégal à votre lieu de résidence c’est tout aussi simple : réservez des kilos sur <a href="https://www.monkolis.com">www.monkolis.com</a> et votre colis vous sera transmis sans aucun tracas. </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}
export default Senegal;