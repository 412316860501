import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Level extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Partager votre expérience | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="level">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/work" className="list-group-item list-group-item-action">Comment ça
                                            marche?</a>
                                        <a href="/trust" className="list-group-item list-group-item-action">Gages de
                                            Confiance</a>
                                        <a href="/level" className="list-group-item list-group-item-action active">Niveau
                                            d'expérience</a>
                                        <a href="/opinions" className="list-group-item list-group-item-action">Les
                                            avis</a>
                                    </div>

                                </div>
                                <div className="col-md-9">
                                    <div className="how">

                                        <div className="section">
                                            <div className="section-inner">

                                                <h1 className="f-heading">Qu’est-ce que le niveau d’Expérience ?</h1>
                                                <p>Lorsque vous effectuez une expédition sur MonKolis, vous interagissez
                                                avec un membre faisant partie d’une communauté de confiance.
                                                Chaque membre dispose de son propre niveau d’Expérience, niveau qui
                                                évolue en fonction de son ancienneté et de son activité sur le site.
                                                Les niveaux d’Expérience vous aident à choisir la personne idéals
                                                pour votre expédition avec MonKolis.
                                                    <br />
                                                    Il existe 5 niveaux :
                                                </p>
                                                <h2 className="f-heading-two color-text3">Niveau d'expérience sur
                                                    MonKolis</h2>
                                                <div className="table-responsive">
                                                    <table className="table table-striped">
                                                        <tr>
                                                            <th></th>
                                                            <th>Débutant</th>
                                                            <th>Initié</th>
                                                            <th>Confirmé</th>
                                                            <th>Expert</th>
                                                            <th>Ambassadeur</th>
                                                        </tr>

                                                        <tr>
                                                            <td>E-mail vérifié</td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <td>Mobile vérifié</td>
                                                            <td>&nbsp;</td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <td>Photo présente</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-check-square color-green"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Avis positifs reçus</td>
                                                            <td>&nbsp;</td>
                                                            <td><span
                                                                className="fa fa-1x fa-star color-green"></span><br />1
                                                                avis
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-star color-green"></span><br />3
                                                                avis
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-star color-green"></span><br />6
                                                                avis
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-star color-green"></span><br />12
                                                                avis
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Part des avis positifs reçus</td>
                                                            <td>&nbsp;</td>
                                                            <td><span
                                                                className="fa fa-1x fa-star color-green"></span><br /><span
                                                                    className="symbole">&rsaquo;</span> 60%
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-star color-green"></span><br /><span
                                                                    className="symbole">&rsaquo;</span> 70%
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-star color-green"></span><br /><span
                                                                    className="symbole">&rsaquo;</span> 80%
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-star color-green"></span><br /><span
                                                                    className="symbole">&rsaquo;</span> 90%
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Ancienneté</td>
                                                            <td>&nbsp;</td>
                                                            <td><span
                                                                className="fa fa-1x fa-calendar color-green"></span><br />1
                                                                mois
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-calendar color-green"></span><br />3
                                                                mois
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-calendar color-green"></span><br />6
                                                                mois
                                                            </td>
                                                            <td><span
                                                                className="fa fa-1x fa-calendar color-green"></span><br />12
                                                                mois
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                                <h2 className="f-heading-two color-text3 mar-top20">Votre niveau
                                                    d’Expérience sur MonKolis</h2>
                                                <p>
                                                    Avoir un niveau d’Expérience élevé sur le site indique que vous êtes
                                                    un membre de confiance de la communauté MonKolis. Votre niveau est
                                                    calculé en fonction de 4 facteurs :<br />
                                                </p>
                                                <p>
                                                    <span className="fa fa-1x fa-check-square color-green"></span> La
                                                    vérification de votre e-mail et de votre numéro de téléphone<br />
                                                    <span className="fa fa-1x fa-star color-green"></span> Le niveau de
                                                    remplissage de votre profil<br />
                                                    <span className="fa fa-1x fa-user-circle color-green"></span> Le
                                                    nombre d'avis positifs et négatifs que les autres membres vous ont
                                                    laissés<br />
                                                    <span className="fa fa-1x fa-calendar color-green"></span> Votre
                                                    ancienneté sur le site depuis votre inscription<br /><br />

                                                    Votre niveau d’Expérience est calculé de façon périodique.<br />
                                                    En fonction de votre niveau d'Expérience, vous avez accès à
                                                    différentes offres. En savoir plus sur <a
                                                        href="/dashboard/profile/plans" className="link color-blue">les bons
                                                    plans</a>.
                                                </p>

                                                <h2 className="f-heading-two color-text3">La confiance, c’est la clé
                                                    !</h2>
                                                <p> En augmentant notre niveau d’Expérience, -en laissant des avis et en
                                                complétant notre profil sur MonKolis, nous créons ensemble encore
                                                plus de confiance au sein de la communauté.
                                                La confiance est un élément très puissant qui nous permet de
                                                partager toujours plus. Plus nous partageons, plus nous profitons !
                                                </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}

export default Level;