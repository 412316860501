import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/annex.css';
import './../../../assets/css/modal.css';
import './../../../assets/css/blog.css';
import LOGO from './../../../assets/img/blog/logo-monkolis.jpg';

class MonkolisMoney extends Component {
  render() {
    return (

      <Fragment>
        <Helmet>
          <title>COMMENT MÉLANIE A GAGNÉ 200€ EN TRANSPORTANT DES COLIS | MonKolis</title>
          <meta name="robots" content="index,follow" />
        </Helmet>

        <div className="blog">
          <div className="main-box_content">
            <div className="container-fluid container-fluid-br headerwrap box-radius">
              <div className="blog-title">
                <h2 className="title">Blog MonKolis</h2>
                <hr></hr>
                <h5 className="txt-light">Histoires, conseils, secrets, astuces... On vous raconte tout !</h5>
              </div>
            </div>
            <div className="container-fluid container-fluid-pad container-fluid-br">
              <div className="row pad-top20 pad-bot20">
                <div className="col-sm-3">
                  <div className="list-group">
                    <li className="list-group-item bg-color-global txt-bold">Articles</li>
                    <a href="/blog/genesis" className="list-group-item list-group-item-action">La Génèse de MonKolis</a>
                    <a href="/blog/press-communication" className="list-group-item list-group-item-action">Communiqué de presse de lancement</a>
                    <a href="/blog/travel-to-Senegal" className="list-group-item list-group-item-action "> Visiter le Sénégal</a>
                    <a href="/blog/personnal-shopper" className="list-group-item list-group-item-action"> Personnal Shopping</a>
                    <a href="/blog/covid" className="list-group-item list-group-item-action"> Les 3 destinations idéales à visiter pendant le COVID</a>
                    <a href="/blog/visit-Belgium" className="list-group-item list-group-item-action"> Visiter la Belgique</a>
                    <a href="/blog/monkolis-make-money" className="list-group-item list-group-item-action active"> Gagner de l'argent avec Monkolis</a>
                    <a href="/blog/monkolis-mouve-out" className="list-group-item list-group-item-action "> Préparer son déménagement</a>
                    <a href="/blog/expedition" className="list-group-item list-group-item-action" > Expéditions à l'international</a>
                  </div>
                </div>

                <div className="col-sm-9">
                  <div className="section">
                    <div className="section-inner">
                    <img src={LOGO} alt="Insérer une photo" className="cover rounded" />
                    <br/> <br/>
                    <h1>COMMENT MÉLANIE A GAGNÉ 200€ EN TRANSPORTANT DES COLIS</h1>
<p>J’ai découvert MonKolis par pur hasard en voulant envoyer une perruque à mon amie Ariane sur Abidjan. En ce temps, j’avais déjà été maintes fois déçue par des sociétés d’expédition dont le professionnalisme laissait à désirer. J’avoue que j’ai eu peur quand j’ai découvert que l’entreprise ne se charge pas elle-même du transport des colis, mais l’innovation dans leur mode de fonctionnement m'a rassurée et je me suis dis, pourquoi pas.</p>
<p>Comme le veut la procédure, je me suis inscrite sur le site. Notons que quand on est inscrit sur le site <a href="https://www.monkolis.com">www.monkolis.com</a> nous avons à la fois le profil d’expéditeur et celui de transporteur, on a donc ces deux casquettes à chaque opération.<br /> Me voici donc à la recherche de quelqu’un pour acheminer ma perruque. Je clique sur « trouver un transporteur » en écrivant bien le trajet « Paris » et « Abidjan » dans la barre de recherche. Une liste de transporteurs s’affiche, ce sont des particuliers et professionnels qui proposent des dates de départs et des prix de kilos très intéressants. J’opte facilement pour un particulier que je contacte aussitôt après avoir réservé un kilos sur son trajet. <br /> Mr Touré, mon transporteur pour l'occasion, me reçoit au téléphone avec grande courtoisie. RDV est pris le même soir pour le dépôt du colis. Chose faite, je reçois deux jours seulement après les remerciements de Ariane, très heureuse d’avoir reçu son cadeau. Et cette opération ne m’aura coûté que 8€.<br /> Je n’en revenais même pas. Finis les longues semaines d'attente, là je n’avais qu’à choisir une date et mon colis partait sans problème. Surtout que le transporteur que j’ai choisi avait fait vérifier son billet d’avion par les administrateurs du site. Il n’y avait donc aucun doute sur sa crédibilité.</p>
<p>En plus les bonnes surprises avec MonKolis ne vont pas s’arrêter là. Quelques mois plus tard, lorsque je décide de me rendre au Mali pour mes vacances je pense à proposer mes kilos étant donné que j’avais de la place dans mes valises. Cette fois en tant que transporteur, j’enregistre mon trajet sur le site, je décide de vendre mes kilos au prix raisonnable de 10€/kilos. Grande fut ma surprise quand à la veille de mon voyage, j’avais vendu les 20 kilos dont je disposais. Après avoir bien contrôlé les colis que j’ai reçus, je ne réalisais toujours pas que je venais de me faire 200€ comme grâce à ce service. Et quand je pense que ces kilos ne m’auraient servi à rien… en tout cas je venais d’assurer mon séjour et c’était pas pour me déplaire. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}
export default MonkolisMoney;