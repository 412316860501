import React, { Component } from 'react';
import voyageuse from './../../assets/img/other/voyageuse.png';


import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';
import { withRouter } from 'react-router-dom';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class ServiceBlockVoyage extends Component {
    constructor(props) {
        super(props);
        this.handPublishTripClick = this.handPublishTripClick.bind(this);
    }

    handPublishTripClick(){
        
        //if (sessionStorage.getItem('idToken') === null || sessionStorage.getItem('idToken') === undefined || sessionStorage.getItem('idToken') === 'null' || sessionStorage.getItem('idToken') === 'undefined'){
        if (getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined'){    
            this.props.history.push({
                pathname: '../connexion'
            });

        }
        else{
            //if (sessionStorage.getItem('phone_number') === null || sessionStorage.getItem('phone_number') === undefined || sessionStorage.getItem('phone_number') === 'null' || sessionStorage.getItem('phone_number') === 'undefined') {
            if (getCookieConsentValue('phone_number') === null || getCookieConsentValue('phone_number') === undefined || getCookieConsentValue('phone_number') === 'null' || getCookieConsentValue('phone_number') === 'undefined') {
                alert("Vous n'avez pas renseigner de numéro de Télephone, merci de le faire dans la page Profil")
                this.props.history.push({
                    pathname: '../dashboard/myprofile',
                })
            }
            else {
                this.props.history.push({
                    pathname: '/dashboard/mytrips',
                    state: { pages: 'new_travel' }
                })
    
            }

        }
    }

    render() {
        return (  
            <section className="align-left voyageuse bg-color-white">
                <div className="container-fluid container-fluid-pad ">
                    <div className="row">

                        <div className="col-sm-7" >
                                <img src={voyageuse} alt="Voyageuse" className=""/>
                        </div>

                        <div className="col-sm-5">
                        <h2 className="mar-bot20 txt-bold">Vous prenez un vol bientôt ?</h2>
                            <h4 className="txt-light">Gagnez de l'argent en transportant des kolis.</h4>
                                <div>
                                   {/*  <a onClick={this.handPublishTripClick} className="btn btn-lg btn-primary mar-top30 txt-white" type="button"> Proposer votre trajet</a> */}
                                   <button onClick={this.handPublishTripClick} className="btn btn-lg btn-primary mar-top30 txt-white" type="button"> Proposer votre trajet</button> 
                                  

                                </div>
                        </div>


                    </div>
                </div>
            </section>
          );
    }
  }
  
  export default withRouter(ServiceBlockVoyage);
