import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Rules extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Charte de bonne conduite | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="rules">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/rules" className="list-group-item list-group-item-action active">Charte
                                            de bonne conduite</a>
                                        <a href="/tips" className="list-group-item list-group-item-action">Conseils
                                            prévention</a>
                                        <a href="/price" className="list-group-item list-group-item-action">Prix du
                                            cobagage</a>

                                    </div>

                                </div>
                                <div className="col-sm-9">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Charte de bonne conduite</h1>
                                            <p>
                                                Nous mettons tout en œuvre pour que les transactions soient réussies et
                                                pour que le comportement des uns ne nuise pas aux autres. C’est la
                                                raison pour laquelle nous demandons aux membres de la communauté de
                                                prendre connaissance de notre charte et de la respecter.
                                            </p>

                                            <h2 className="f-heading-three">● Respect des autres </h2>
                                            <p>Je m’engage à avoir un comportement respectueux envers les autres
                                            membres, l’équipe d’animation et les tiers. Je m’engage à éviter tout
                                            comportement agressif, grossier, violent, moqueur envers les autres, et
                                            plus généralement tout comportement de nature à troubler le bon
                                                déroulement d'une transaction.</p>


                                            <h2 className="f-heading-three">● Règles de sécurité </h2>

                                            <p>Je m’engage à respecter la réglementation du/des pays destinataire(s)
                                            notamment lorsqu’elle publie une liste de produits prohibés et interdits
                                            d'importation sur
                                            son territoire (ex : interdiction de de produits éclaircissants ou de
                                                viande de brousse en France).</p>

                                            <h2 className="f-heading-three">● Règles des colis transportés </h2>

                                            <p>Je m’engage à respecter les colis qui me sont confiés en tant que
                                            transporteur.
                                            Toutes dégradations ou vols engageront ma responsabilité et pourront
                                                entraîner une demande de réparation financière.</p>

                                            <h2 className="f-heading-three">● Valeur des colis </h2>

                                            <p>Je m'engage à ne pas expédier des kolis dont la valeur excède cinq fois
                                            le montant payé au tranporteur et accepte qu'en cas de survenance
                                            d'incident ma réclamation ne puisse pas porter sur une valeur excédant
                                                cinq fois le montant payé au transporteur.</p>
                                            <p>De façon générale, nous déconseillons l'expédition kolis contenant des
                                            objects de luxe ou de valeur au risque de les perdre, de les endommager
                                                ou de se faire dérober.</p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Rules;