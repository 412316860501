import React, { Component, Fragment } from 'react';
// import './../../../assets/css/main.css';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/dashboard.css';
import './../../../assets/css/modal.css';
import { withRouter, Redirect } from 'react-router-dom';
import MyMoneyAvailable from './moneyavailable';
import MyAccount from './mybankaccount';
import MyCrowfunding from './mycrowfunding';
import MyMoneyBankCard from './mymoneybankcard';
import MyMoneyHistoric from './mymoneyhistoric';
import MyPaymentMade from './mypaymentmade';
import Nav from 'react-bootstrap/Nav';
import { Helmet } from 'react-helmet';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: [],
            items: [],
            renderMoneyAvailable: false,
            renderHistoric : false,
            renderBankData : true,
            renderMoneyPaymentMade: false,
            renderMoneyBankCard: false,
            renderCrowfunding: false,
        }
        this.handleMoneyAvailbleClick = this.handleMoneyAvailbleClick.bind(this);
        this.handleHistoricClick = this.handleHistoricClick.bind(this);
        this.handleBankDataClick = this.handleBankDataClick.bind(this);
        this.handleMoneyPayMadeClick = this.handleMoneyPayMadeClick.bind(this);
        this.handleMoneyBankCardClick = this.handleMoneyBankCardClick.bind(this);
        this.handleCrowfundingClick = this.handleCrowfundingClick.bind(this);
    }

    componentWillMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
    }

    handleMoneyAvailbleClick(event){
		this.setState({
			renderMoneyAvailable: true,
            renderHistoric : false,
            renderBankData : false,
            renderMoneyPaymentMade: false,
            renderMoneyBankCard: false,
            renderCrowfunding: false,
		})
    }

    handleHistoricClick(event){
		this.setState({
			renderMoneyAvailable: false,
            renderHistoric : true,
            renderBankData : false,
            renderMoneyPaymentMade: false,
            renderMoneyBankCard: false,
            renderCrowfunding: false,
		})
    }
    
    handleBankDataClick(event){
		this.setState({
			renderMoneyAvailable: false,
            renderHistoric : false,
            renderBankData : true,
            renderMoneyPaymentMade: false,
            renderMoneyBankCard: false,
            renderCrowfunding: false,
		})
    }
    
    handleMoneyPayMadeClick(event){
		this.setState({
			renderMoneyAvailable: false,
            renderHistoric : false,
            renderBankData : false,
            renderMoneyPaymentMade: true,
            renderMoneyBankCard: false,
            renderCrowfunding: false,
		})
    }
    
    handleMoneyBankCardClick(event){
		this.setState({
			renderMoneyAvailable: false,
            renderHistoric : false,
            renderBankData : false,
            renderMoneyPaymentMade: false,
            renderMoneyBankCard: true,
            renderCrowfunding: false,
		})
    }
    
    handleCrowfundingClick(event){
		this.setState({
			renderMoneyAvailable: false,
            renderHistoric : false,
            renderBankData : false,
            renderMoneyPaymentMade: false,
            renderMoneyBankCard: false,
            renderCrowfunding: true,
		})
	}


    render() {
        
        return (
            <Fragment>
                <Helmet>
                    <title></title>
                    <meta name="robots" content="noindex,follow" />
                </Helmet>

                <div className="mymoney">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            {getCookieConsentValue('idToken') === null ? <Redirect to="/connexion" /> :
                                <div className="pad-top20 mar-bot20 d-sm-block" id="secondNav">
                                    <Nav variant="tabs" defaultActiveKey="/dashboard/money">
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mydashboard" href="/dashboard/mydashboard">Tableau de bord</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mytrips" href="/dashboard/mytrips">Mes trajets</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myexpeditions" href="/dashboard/myexpeditions">Mes expéditions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mymessage" href="/dashboard/mymessage">Messages</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myalerts" href="/dashboard/myalerts">Alertes</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myprofile" href="/dashboard/myprofile">Profil</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href="/dashboard/money" href="/dashboard/money">Paiements</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <br />
                                    <div className="row moneys">
                                        <div className="col-sm-3 mar-bot20">
                                            <div className="list-group mar-bot20">
                                                {
                                                    this.state.renderMoneyAvailable ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleMoneyAvailbleClick}>Argent disponible</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleMoneyAvailbleClick}>Argent disponible</a>
                                                }

                                                {
                                                    this.state.renderHistoric ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleHistoricClick}>Historique des encaissements</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleHistoricClick}>Historique des encaissements</a>
                                                }

                                                {
                                                    this.state.renderBankData ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleBankDataClick}>Coordonnées Bancaires</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleBankDataClick}>Coordonnées Bancaires</a>
                                                }

                                                <hr />
                                                {
                                                    this.state.renderMoneyPaymentMade ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleMoneyPayMadeClick}>Paiements effectués</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleMoneyPayMadeClick}>Paiements effectués</a>
                                                }

                                                {
                                                    this.state.renderMoneyBankCard ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleMoneyBankCardClick}>Carte bancaire</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleMoneyBankCardClick}>Carte bancaire</a>
                                                }

                                            </div>


                                            {/* <div className="list-group">
                            {
                                this.state.renderCrowfunding ?
                                <a className="list-group-item list-group-item-action active btn btn-yellow" onClick={this.handleCrowfundingClick}>My Crowdfunding</a> :
                                <a className="list-group-item list-group-item-action btn btn-yellow" onClick={this.handleCrowfundingClick}>My Crowdfunding</a>
                            }

                            </div> */}
                                        </div>
                                        <div className="col-sm-9">
                                            {this.state.renderMoneyAvailable ? <MyMoneyAvailable /> : null}
                                            {this.state.renderHistoric ? <MyMoneyHistoric /> : null}
                                            {this.state.renderBankData ? <MyAccount /> : null}
                                            {this.state.renderMoneyPaymentMade ? <MyPaymentMade /> : null}
                                            {this.state.renderMoneyBankCard ? <MyMoneyBankCard /> : null}
                                            {this.state.renderCrowfunding ? <MyCrowfunding /> : null}
                                        </div>


                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(MyMoney);