import React, { Component } from 'react';

import './../../assets/css/multiple.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

import double from './../../assets/img/other/double.png';
import AvisClient1 from './avisclient1.jsx';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
//import './../../assets/css/credit-card-style.scss'

import { PAYE_CROWFUNDING_QUERY } from './../../query/querycrowfunding';
import { CONTRIBUTION_URL, SECURE_COOKIES } from './../../Url';
import axios from 'axios';
import Moment from 'moment'
import moment from 'moment'
import StarRatings from 'react-star-ratings';
import { withRouter } from 'react-router-dom';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class Crow2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      displayPayment: false,
      cvc: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
      amount: '',
      showAmount: false,
      showName: false,
      marks: '5/5 Très bien',
      remark: '',
    }

    this.handleShowPayementClick = this.handleShowPayementClick.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleShowAmountChecked = this.handleShowAmountChecked.bind(this);
    this.handleShownameChecked = this.handleShownameChecked.bind(this);
    this.handleSubmitPayment = this.handleSubmitPayment.bind(this);
    this.handleChangeMakrs = this.handleChangeMakrs.bind(this);
    this.handleChangeRemarks = this.handleChangeRemarks.bind(this);

    this.datas = this.props.data.getDetailsAboutLastActiveCrowFundingCampaignInfos
  }

  componentWillMount() {
    
    this.idToken = getCookieConsentValue('idToken');
    this.accessToken= getCookieConsentValue('accessToken');
    // this.idToken = sessionStorage.getItem('idToken');
    // this.accessToken= sessionStorage.getItem('accessToken');
  }

  handleChangeRemarks(event) {
    this.setState({
      remark: event.target.value
    })
  }

  handleChangeMakrs(event) {
    this.setState({ marks: event.target.value })
  }

  handleSubmitPayment(event) {
    event.preventDefault();

    var VAR = JSON.stringify({
      "contribution": {
        "id_campaign": this.datas.crow_funding_campaign.id,
        "transaction_id": "2019-06-12T22:19:39+0000",
        "transaction_date": new Date,
        "transaction_amount": this.state.amount,
        "transaction_status": "TRANSACTION_SUCCESS",
        "remark": this.state.remark,
        "mark": this.state.marks[0],
        "is_remain_anonymous_name": this.state.showName === true ? "Y" : "N",
        "is_remain_anonymous_amount": this.state.showAmount === true ? "Y" : "N"
      }
    })
    //console.log(VAR)
    this.payment(PAYE_CROWFUNDING_QUERY, VAR)
  }

  payment = async (query, variables) => {
    try {
      const response = await axios.post(CONTRIBUTION_URL,
        { query, variables },
        {
          'headers':
          {
            'Authorization': this.idToken,
            'AccessToken': this.accessToken
          }
        }
      );

      // Log the response so we can look at it in the console
      //console.log(response.data)
      // Set the data to the state
      if (response.data.data.contributeToCrowFundingCampaign.status === 200) {
        alert('Payement OK');
        
        Cookies.set('contrib_cwf_balance', response.data.data.contributeToCrowFundingCampaign.contrib_cwf_balance, { path: '/', secure: SECURE_COOKIES, expires: 1 })
        //sessionStorage.setItem('contrib_cwf_balance', response.data.data.contributeToCrowFundingCampaign.contrib_cwf_balance)
        window.location.reload(false);
      }

    } catch (error) {
      // If there's an error, set the error to the state
      this.setState(() => ({ error }))
      //console.log(error)
    }
  }


  handleShowAmountChecked(event) {
    this.setState({
      showAmount: !this.state.showAmount
    });
  }

  handleShownameChecked(event) {
    this.setState({
      showName: !this.state.showName
    });
  }

  handleAmountChange(event) {
    event.preventDefault()
    this.setState({
      amount: event.target.value
    });
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  handleShowPayementClick(event) {
    event.preventDefault();
    
    if (/*sessionStorage.getItem('idToken')*/ getCookieConsentValue('idToken') === null) {
      this.props.history.push({
        pathname: '/connexion'
      });
    } else {
      this.setState({
        displayPayment: !this.state.displayPayment
      })
    }

  }

  renderPayment() {
    return (
      <div id="PaymentForm">
        <div className="card-header">
          <h4 className="card-title"><i className="fa fa-lock"></i> Paiement Sécurisé <br /><span className="fa fa-1x fa-cc-visa color-text2"></span><span className="fa fa-1x fa-cc-mastercard color-text2"></span></h4>
        </div>
        <br />
        <Cards
          cvc={this.state.cvc}
          expiry={this.state.expiry}
          focus={this.state.focus}
          name={this.state.name}
          number={this.state.number}
        />
        <br />
        <form onSubmit={this.handleSubmitPayment}>
          <div className="form-group">
            <label for="Amount" className="sr-only">Montant</label>
            <input type="number" className="form-control" id="Amount" placeholder="15 €" value={this.state.amount} onChange={this.handleAmountChange} required />
          </div>

          <div className="form-group">
            <input className="col-sm-10" type="tel" name="number" placeholder="Card Number" onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
          </div>

          <div className="form-group">
            <input className="col-sm-10" type="text" name="name" placeholder="Name" onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
          </div>
          <div className="form-group">
            <input className="col-sm-5" type="number" name="expiry" placeholder="Valid Thru" onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
          </div>
          <div className="form-group">
            <input className="col-sm-5" type="number" name="cvc" placeholder="CVC" onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
          </div>
          <div>
            <input id="checkbox6" type="checkbox" className="magic-checkbox" onChange={this.handleShownameChecked} />
            <label for="checkbox6" className="color-text2">Don anonyme</label>
          </div>
          <div className="mar-bot20">
            <input id="checkbox5" type="checkbox" className="magic-checkbox" onChange={this.handleShowAmountChecked} />
            <label for="checkbox5" className="color-text2">Masquer le montant de mon don</label>
          </div>
          <div className="form-group">
            <label for="exampleFormControlTextarea1">Laisser un message</label>

            <textarea className="form-control" placeholder="Mon avis sur le projet... (facultatif)" maxlength="255" id="exampleFormControlTextarea1" rows="4" value={this.state.remark} onChange={this.handleChangeRemarks}></textarea>
          </div>

          <div className="form-group">
            <label for="exampleFormControlTextarea1">Ma note</label>
            <select className="form-control" value={this.state.marks} onChange={this.handleChangeMakrs}>
              <option>5/5 Géniale idée</option>
              <option>4/5 Très belle idée</option>
              <option>3/5 Belle idée</option>
              <option>2/5 Décevant</option>
              <option>1/5 A éviter</option>
            </select>
          </div>

          <input type="submit" className="btn btn-primary" value="Payez" />
        </form>
      </div>
    )
  }

  render() {
    
    return (
      <div className="col-md-4">
        <section className="paybox section">

          <div className="payhead">


            <h2 className="align-right mar-top0 mar-bot5 color-green"><span className=""> {this.datas.stats.total_amount_contributions}</span>€ <span className="color-text2"> / {this.datas.crow_funding_campaign.max_amount} €</span></h2>
            <h6 className="float-right mar-top0 txt-light"><span className="fa fa-1x fa-clock-o"></span> {this.datas.crow_funding_campaign.number_of_days_left} <span className=""> jours </span></h6>
            <h6 className="align-left mar-top0 txt-light"><span className="fa fa-1x fa-user-circle-o"></span> {this.datas.stats.number_of_contributors} <span> donateurs </span></h6>
            <div className="progress mar-bot0 mar-top0 ">
              <div className="progress-bar progress-bar-striped  progress-bar-animated" role="progressbar" aria-valuenow="9" aria-valuemin="0" aria-valuemax="100" style={{width:this.datas.stats.contribution_percentage}}>
                {this.datas.stats.contribution_percentage}%
                            </div>
            </div>
          </div>
          <div className="payfoot">
            <button data-toggle="collapse" type="btn" className="btn btn-yellow btn-lg btn-block" onClick={this.handleShowPayementClick}>Soutenir MonKolis*</button>
            <p className="color-text3">* Le double de votre soutien sera mis à votre disposition dans votre compte! </p>


            {this.state.displayPayment ? this.renderPayment() : null}

          </div>
        </section>


        <div className="card mar-top20">
          <div className="card-header">
            <h4 className="card-title">Le Double Cash Back, c'est quoi?</h4>
          </div>
          <div className="card-body">

            <img src={double} alt="MDN Logo" className="" />

            <p className="mar-bot0">
              Nous lancons notre entreprise et avons besoin de fonds pour nous développer.
                            <br />
                            Nous vous proposons de verser dès maintenant l'argent que vous utiliserez pour vos expéditions de kolis plus tard.
                            <br /><br />
              <span className="txt-bold">Peu importe le montant que vous donnez, nous le doublons</span> et vous l'avez à disposition dans votre compte pour vos futures expéditions.
                            <br /><br />
                            En plus, parce que ça compte, nous vous disons Mille fois Merci!<br /><br />
              {
                //sessionStorage.getItem('idToken') !== '' ?
                getCookieConsentValue('idToken') !== '' ?
                  <div><a href="/dashboard/money" className="link color-blue"> Consulter mes dons →</a><br /></div> :
                  <div><a href="/connexion" className="link color-blue"> Consulter mes dons →</a><br /></div>
              }


            </p>
          </div>
        </div>



        <div className="mar-bot10 mar-top10">


          <a type="btn" href="https://www.facebook.com/groups/1654698577936540/" className="btn btn-facebook  btn-block"><i className="fa fa-thumbs-o-up"></i> J'aime <span>3 476</span></a>
          {/* </li>				 */}

          <a type="btn" href="https://www.facebook.com/groups/1654698577936540/" className="btn btn-facebook btn-block"><i className="fa fa-share"></i> Je partage</a>
          {/* </li> */}


        </div>

        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Les derniers avis sur le projet</h4>
          </div>
          <div className="card-body">
            <div className="testimonials">
              {this.datas.contributions.map((value, index) => {
                return <div className="item" key={index} >
                  <div className="quote">
                    <StarRatings
                      rating={parseInt(value.mark)}
                      starRatedColor="red"
                      numberOfStars={5}
                      name='rating'
                      starDimension='20px'
                      starSpacing='0,5px'
                    />
                    <p>{value.remark !== '' ? value.remark : ''}</p>

                  </div>
                </div>
              })}
            </div>


          </div>
        </div>

        {/* <?php include 'include/crow2';?>	 */}
      </div>
    )
  }
}

export default withRouter(Crow2);