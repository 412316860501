
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DOUBLE from '../../../assets/img/other/double.png'

class MyBonsplans extends Component {
	constructor(props) {
		super(props);
		this.state = {
        };
    }

	render() {
		return (
		<div class="myprofile">

		<aside class="aside section plans">
			<div class="section-inner">
				<h3 class="heading">Bons plans</h3>
				<div class="content">
					<div class="">
						<p>Retrouvez tous vos bons plans dans cette section.</p>
						{/*<div class="col-md-5">
							<img src={DOUBLE} alt="MDN Logo" class="cover"/>
						</div>

						 <div class="col-md-7">

							 <h4 class="color-text2">Donnez <span class="color-green">10 €</span><br/>
							   Disposez du double <span class="color-green">20 €</span></h4> 
							 <p class="mar-bot0"> 
								Nous lancons notre entreprise et avons besoin de fonds pour nous développer.
								<br/>
								Nous vous proposons de verser dès maintenant l'argent que vous utiliserez pour vos expéditions de kolis plus tard.
								<br/><br/>
								<span class="txt-bold">Peu importe le montant que vous donnez, nous le doublons</span> et vous l'avez à disposition dans votre compte pour vos futures expéditions.
								<br/><br/>
								En plus, parce que ça compte, nous vous disons Mille fois Merci!<br/><br/>

								<a href="/dashboard/money" class="link color-blue"> Consulter mes dons →</a><br/>

							</p>
					
							<div class="mar-top30">
								<a href="/crowfunding" class="btn btn-lg btn-yellow btn-block">Je découvre</a>	
							</div> 																		
						 
						 </div>*/}
					</div>


					
				</div>
			</div>            
		</aside>

</div>);
}
}
export default withRouter(MyBonsplans);