import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Details extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Cordonnées | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="commercial-infos">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/about" className="list-group-item list-group-item-action">A propos</a>
                                        <a href="/founders"
                                            className="list-group-item list-group-item-action">Cofondateurs</a>
                                        <a href="/details"
                                            className="list-group-item list-group-item-action active">Coordonnées</a>
                                        <hr />
                                        <a href="/careers" className="list-group-item list-group-item-action">Carrière</a>
                                        <a href="/press" className="list-group-item list-group-item-action">Presse</a>
                                        <a href="/contact" className="list-group-item list-group-item-action">Contact</a>

                                    </div>
                                </div>
                                <div className="col-sm-9">

                                    <aside className="aside section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Coordonnées</h1>
                                            <p> Fondée en janvier 2019 et basée à Paris, en France, MonKolis est une
                                            plate-forme communautaire de confiance permet d'envoyer et recevoir des
                                                kolis du pays. </p>

                                            <p>MonKolis met en relation des voyageurs et des expéditeurs qui veulent
                                            envoyer ou recevoir un kolis du pays et grâce à notre service, le kolis
                                            de l'expéditeur arrive rapidement et en toute sureté tandis que le
                                                voyageur rentabilise son voyage et fait de belles rencontres.</p>

                                            <h5>Fournisseur du site web et partie contractante pour les services de
                                                paiement</h5>

                                            <p>
                                                MonKolis SAS <br />
                                                28 Bis Avenue Daumesnil<br />
                                                75012, Paris<br />
                                                France<br />
                                            </p>

                                            <p>
                                                Représentant Légal : Cyrille TACHUM FOTSO <br />
                                                SAS au capital de 1 000 € <br />
                                                Numéro de TVA : IE9827384L<br />
                                                Numéro d'immatriculation : (Registre du commerce de Paris) IE
                                                511825<br />
                                            </p>
                                            <p>
                                                <span className="txt-bold">Nous contacter :<br /></span>
                                                <a href="/">hello@monkolis.com</a><br />
                                                <a href="/">https://www.monkolis.fr/help/contact_us</a>
                                            </p>

                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>

        );
    }
}

export default Details;