import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

class Tips extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Conseils prévention | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="tips">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/rules" className="list-group-item list-group-item-action">Charte de
                                            bonne conduite</a>
                                        <a href="/tips" className="list-group-item list-group-item-action active">Conseils
                                            prévention</a>
                                        <a href="/price" className="list-group-item list-group-item-action">Prix du
                                            cobagage</a>
                                    </div>

                                </div>
                                <div className="col-sm-9">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Conseils prévention</h1>
                                            <p>
                                                Nous mettons tout en œuvre pour que les transactions soient réussies et
                                                pour que le comportement des uns ne nuise pas aux autres. Toutefois,
                                                nous vous recommandons de suivre les conseils ci-dessous lors de vos
                                                transactions.
                                            </p>

                                            <h2 className="f-heading-three">1. Choix ou acceptation d'un membre pour le
                                                transport ou l'expédition</h2>
                                            <p>
                                                <ul className="mar-left10">
                                                    <li>Privilégier les membres ayant des profils vérifiés pour vos
                                                    transactions.
                                                    </li>
                                                    <li>Prenez connaissance des avis des autres membres de la communauté
                                                    en consultant le profil de l'interessé.
                                                    </li>
                                                    <li>Si vous avez un doute, essayez de trouver un autre
                                                    interlocuteur.
                                                    </li>
                                                </ul>
                                            </p>

                                            <h2 className="f-heading-three">2. Contenu du colis</h2>
                                            <p>
                                                Controller et noter le contenu du colis en présence des deux parties
                                                lors du dépôt et de la livraison du colis.
                                            </p>

                                            <h2 className="f-heading-three">3. Paiement du service de transport</h2>
                                            <p>
                                                Payer le service directement sur la plateforme et jamais main à main.
                                                Vous bénéficiez ainsi l'assurance mise en place par MonKolis.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Tips;