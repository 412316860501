
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { TRAVEL_PROPOSAL_PAST_QUERY } from '../../../query/travelquery';
import { TRAVEL_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Moment from "moment";
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyMoneyBankCard extends Component {
	constructor(props) {
		super(props);
		this.state = {

        };
    }

	render() {
		return (
			<div className="mymoney">
				<div className="section">
					<div className="section-inner">
						<h3 className="heading">Carte bancaire</h3>
						<p>	Pour la phase de lancement de la plateforme MonKolis, le règlement des transactions s'effectue en direct entre l'expéditeur et le transporteur.</p>

						{/*<p>
							Vous pourrez remplacer cette carte lors de votre prochaine réservation.
						</p>

						<form className="">

							<div className="row">
								<div className="form-group col-md-6">
									<label htmlFor="titulaire" className="">Titulaire de la carte</label>
									<input type="text" className="form-control" id="titulaire"
										   placeholder="Vanessa EGUE" readOnly />
								</div>
							</div>
							<div className="row">
								<div className="form-group col-md-6">
									<label htmlFor="bank" className="">Numéro de carte</label>
									<input type="number" className="form-control" id="bank"
										   placeholder="XXXXXXXXXXXX4521" readOnly />
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col-md-3">
									<label htmlFor="monthend">Mois de fin</label>
									<input type="text" className="form-control" id="monthend"
										   placeholder="09 - Sept" readOnly />
								</div>
								<div className="form-group col-md-4">
									<label htmlFor="yearend">Année de fin</label>
									<input type="number" className="form-control" id="yearend"
										   placeholder="2020" readOnly />
								</div>
							</div>

							<a href="#" type="btn" className="btn btn-danger">Supprimer</a>

						</form>*/}

					</div>
				</div>
			</div>
		);
}
}
export default withRouter(MyMoneyBankCard);