import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import etoiles from './../../assets/img/other/etoiles.png';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Opinions extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Comment avez-vous vécu votre expérience sur Monkolis ? | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div>
                    <div className="opinions">
                        <div className="main-box_content">
                            <div className="container-fluid container-fluid-pad container-fluid-br">
                                <div className="row pad-top20 pad-bot20">
                                    <div className="col-sm-3 mar-bot20">

                                        <div className="list-group">
                                            <a href="/work" className="list-group-item list-group-item-action">Comment
                                                ça marche?</a>
                                            <a href="/trust" className="list-group-item list-group-item-action">Gages de
                                                Confiance</a>
                                            <a href="/level" className="list-group-item list-group-item-action">Niveau
                                                d'expérience</a>
                                            <a href="/" className="list-group-item list-group-item-action active">Les
                                                avis</a>
                                        </div>
                                    </div>

                                    <div className="col-md-9">
                                        <div className="how">
                                            <div className="section">
                                                <div className="section-inner avis">
                                                    <h1 className="f-heading">Les avis</h1>
                                                    <h2 className="f-heading-two color-text3 mar-top20">Pourquoi
                                                        demandons-nous à nos membres de laisser des avis ?</h2>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <img src={etoiles} alt="MDN Logo" className="cover" />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <p className="color-text2">
                                                                La confiance est une valeur pilier de notre communauté.
                                                                Les avis que vous laissez sur d'autres membres servent
                                                                de recommandations. Ils sont utiles à toutes et à tous
                                                                car ils permettent de décider si l'on souhaite confier
                                                                son kolis à un membre ou non.
                                                                <br />Pensez donc à laisser un avis la prochaine fois que
                                                                vous finalisez une expédition sur MonKolis ! </p>
                                                            <a href="dashboard/profile/opinions-received"
                                                                className="color-blue link">Voir les avis reçus →</a>
                                                        </div>

                                                    </div>

                                                    <h2 className="f-heading-two color-text3 mar-top20">Comment faire
                                                        pour laisser un avis ?</h2>
                                                    <div className="color-text2">
                                                        <p>Vous pourrez laisser un avis sur votre expéditeur ou voyageur
                                                        dès que ce dernier aura validé la remise du kolis grâce au
                                                        code de validation.
                                                            <br />Vous recevrez un rappel par e-mail et une notification
                                                            sur votre tableau de bord à ce sujet.</p>

                                                        <p>Quand vous laissez un avis, n'hésitez pas à être honnête.
                                                        L'autre membre ne pourra pas voir ce que vous avez écrit
                                                            tant qu'il ou elle n'aura pas laissé un avis sur vous.<br />

                                                            L'autre membre disposera de 14 jours pour laisser un avis à
                                                            son tour. Nous afficherons vos deux avis sur vos profils
                                                            respectifs dès qu'ils auront été publiés ou une fois les 14
                                                            jours passés.</p>

                                                        <p>Une fois votre avis publié, vous pourrez découvrir celui
                                                        qu'il ou elle aura laissé sur vous. Nous afficherons
                                                        également vos avis sur vos profils respectifs afin de les
                                                        partager avec les autres membres de la communauté. Voici les
                                                            étapes à suivre pour laisser un avis :<br /></p>
                                                    </div>
                                                    <div className="row mar-top20">
                                                        <div className="col-md-8">
                                                            <div className="color-text2">
                                                                <p className="f-heading-three">1. Choisissez l'adjectif
                                                                qui résume le mieux votre expérience avec ce
                                                                    membre.</p>

                                                                <p className="f-heading-three">2. Ajoutez quelques mots.
                                                                Dites si la personne était à l'heure, si le kolis a
                                                                    été remis en bon état etc.</p>

                                                                <p>En manque d'inspiration ? Demandez-vous simplement si
                                                                vous recommanderiez cette personne à votre entourage
                                                                    :</p>

                                                                Très bien - Une transaction agréable dans l'ensemble,
                                                                rien à redire.<br />
                                                                Bien - C'est une personne de confiance. Il ou elle a
                                                                fait en sorte que mon kolis arrive rapidement et en bon
                                                                état.<br />
                                                                Moyen - Il ou elle était courtois. Dans l'ensemble, vous
                                                                avez apprécié cette expédition.<br />
                                                                Déçu - Expérience décevante. Peu recommandable.<br />
                                                                Très déçu - À éviter. Plus jamais.<br />

                                                            </div>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="avis-note">
                                                                <input id="checkbox123" type="checkbox"
                                                                    className="magic-radio" checked />
                                                                <label for="checkbox123">- Très Bien</label>
                                                                <input id="checkbox123" type="checkbox"
                                                                    className="magic-radio" />
                                                                <label for="checkbox123">- Bien</label>
                                                                <input id="checkbox123" type="checkbox"
                                                                    className="magic-radio" />
                                                                <label for="checkbox123">- Moyen</label>
                                                                <input id="checkbox123" type="checkbox"
                                                                    className="magic-radio" />
                                                                <label for="checkbox123">- Déçu</label>
                                                                <input id="checkbox123" type="checkbox"
                                                                    className="magic-radio" />
                                                                <label for="checkbox123">- Très Déçu</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Opinions;