import axios from 'axios';
import React, {Component, Fragment} from 'react';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
//import './../../assets/css/index.css';
import './../../assets/css/login.css';
import './../../assets/css/modal.css';
import {withRouter} from 'react-router-dom';
//import PhoneInput from 'react-phone-number-input'
//import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import PhoneInput, {formatPhoneNumber, formatPhoneNumberIntl} from 'react-phone-number-input'

import {CONTACT_MONKOLIS_QUERY} from '../../query/querycrowfunding';
import {CONTACT_MONKOLIS_URL} from '../../Url';
import {Helmet} from 'react-helmet';


class Contact extends Component {


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            firstname: '',
            lastname: '',
            telephone: '',
            mdp: '',
            msgAlertBody: '',
            msgAlertHeader: '',
            alertVariant: 'danger',
            showAlert: false,
            msg_reason: '',
            msg_object: '',
            msg_body: '',
            items: [],
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFirstnameChange = this.handleFirstnameChange.bind(this);
        this.handleLastnameChange = this.handleLastnameChange.bind(this);
        this.handleTelephoneChange = this.handleTelephoneChange.bind(this);
        this.handleMsg_reasonChange = this.handleMsg_reasonChange.bind(this);
        this.handleMsg_objectChange = this.handleMsg_objectChange.bind(this);
        this.handleMsg_bodyChange = this.handleMsg_bodyChange.bind(this);


        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }


    handleFirstnameChange(event) {
        this.setState({firstname: event.target.value});
    }

    handleLastnameChange(event) {
        this.setState({lastname: event.target.value});
    }

    handleTelephoneChange(event) {
        this.setState({telephone: event.target.value});
    }

    handleMsg_reasonChange(event) {
        this.setState({msg_reason: event.target.value});
    }

    handleMsg_objectChange(event) {
        this.setState({msg_object: event.target.value});
    }

    handleMsg_bodyChange(event) {
        this.setState({msg_body: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            this.setState(() => ({
                validated: true
            }));
            var VAR = JSON.stringify({
                "msg": {
                    "firstname": this.state.firstname,
                    "lastname": this.state.lastname,
                    "email": this.state.email,
                    "telephone": this.state.telephone,
                    "msg_reason": this.state.msg_reason,
                    "msg_object": this.state.msg_object,
                    "msg_body": this.state.msg_body
                }
            })

            this.contactMonko(CONTACT_MONKOLIS_QUERY, VAR)
        }
    }

    contactMonko = async (query, variables) => {
        try {
            const response = await axios.post(CONTACT_MONKOLIS_URL, {
                query,
                variables
            });
            // Log the response so we can look at it in the console


            // Set the data to the state
            this.setState(() => ({
                isLoaded: true,
                items: response.data.data
            }));

            if (this.state.items.sendMessageByContact.status === 200) {
                this.setState(() => ({
                    showAlert: true,
                    msgAlertBody: 'Votre message a bien été envoyé, un administrateur vous contactera très prochainement',
                    msgAlertHeader: 'success',
                    alertVariant: 'success'

                }));
            } else {
                this.setState(() => ({
                    showAlert: true,
                    msgAlertBody: 'Message non envoyé, Merci de verifier les informations saisies svp ou reessayer',
                    msgAlertHeader: 'Echec',
                    alertVariant: 'danger'

                }));
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({error}))
        }
    }

    handleCloseAlert(event) {
        this.setState({showAlert: false});
    }

    render() {
        return (

            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Conctactez Monkolis | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="contact">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/about" className="list-group-item list-group-item-action">A propos</a>
                                        <a href="/founders"
                                            className="list-group-item list-group-item-action">Cofondateurs</a>
                                        <a href="/details"
                                            className="list-group-item list-group-item-action">Coordonnées</a>
                                        <hr />
                                        <a href="/careers"
                                            className="list-group-item list-group-item-action">Carrière</a>
                                        <a href="/press" className="list-group-item list-group-item-action">Presse</a>
                                        <a href="/contact"
                                            className="list-group-item list-group-item-action active">Contact</a>

                                    </div>
                                </div>

                                <div className="col-lg-9 col-md-10 col-sm-10 col-12">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Contact</h1>
                                            {this.state.showAlert ?
                                                <Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert}
                                                    dismissible>
                                                    <Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
                                                    <p>{this.state.msgAlertBody} </p>
                                                </Alert> : ''}
                                            <Form className="well" validated={this.state.validated}
                                                onSubmit={this.handleSubmit}>

                                                <Form.Group controlId="validationCustom01">
                                                    <Form.Label>Motif</Form.Label>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">

                                                            <select value={this.state.msg_reason}
                                                                onChange={this.handleMsg_reasonChange}
                                                                className="form-control">
                                                                {/* <option selected="true" style="display:none;"> Choisir</option> */}
                                                                <option selected> Choisir</option>
                                                                <option value="Investisseur">Investisseur</option>
                                                                <option value="Presse">Presse</option>
                                                                <option value="Partenariat">Partenariat</option>
                                                                <option value="Problème de compte">Problème de compte
                                                                </option>
                                                                <option value="Autres">Autres</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </Form.Group>


                                                <Form.Group controlId="validationCustom01">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <Form.Label>Nom</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Nom"
                                                                defaultValue={this.state.lastname}
                                                                onChange={this.handleLastnameChange}
                                                            />
                                                            <span
                                                                className="glyphicon glyphicon-lock form-control-feedback"></span>
                                                        </div>
                                                    </div>
                                                </Form.Group>


                                                <Form.Group controlId="validationCustom01">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <Form.Label>Prénoms</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Prénoms"
                                                                defaultValue={this.state.firstname}
                                                                onChange={this.handleFirstnameChange}
                                                            />

                                                            <span
                                                                className="glyphicon glyphicon-lock form-control-feedback"></span>
                                                        </div>
                                                    </div>
                                                </Form.Group>


                                                <Form.Group controlId="validationCustom01">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="email"
                                                                placeholder="Saisissez un email valide"
                                                                defaultValue={this.state.email}
                                                                onChange={this.handleEmailChange}
                                                            />
                                                            <span
                                                                className="glyphicon glyphicon-envelope form-control-feedback"></span>
                                                        </div>
                                                    </div>
                                                </Form.Group>

                                                <Form.Group controlId="validationCustom01">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <Form.Label>Téléphone</Form.Label>

                                                            {/*  <PhoneInput
                            placeholder="Saisissez un numéro de téléphone valide"
                            country={'fr'}
                            value={this.state.telephone}
                            onChange={telephone => this.setState({ telephone })}
                          /> */}

                                                            <PhoneInput
                                                                placeholder="Saisissez un numéro de téléphone valide"
                                                                country={'fr'}
                                                                international
                                                                autoFormat={true}
                                                                value={formatPhoneNumberIntl(this.state.telephone)}
                                                                onChange={telephone => this.setState({ telephone })}
                                                            />
                                                            <span
                                                                className="glyphicon glyphicon-lock form-control-feedback"></span>
                                                        </div>
                                                    </div>
                                                </Form.Group>

                                                <Form.Group controlId="validationCustom01">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <Form.Label>Titre du message</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                defaultValue={this.state.msg_object}
                                                                onChange={this.handleMsg_objectChange}
                                                            />
                                                            <span
                                                                className="glyphicon glyphicon-lock form-control-feedback"></span>
                                                        </div>
                                                    </div>
                                                </Form.Group>

                                                <div className="form-group mar-top30 mar-bot20">
                                                    <label for="exampleFormControlTextarea1">Laisser un message</label>
                                                    <textarea className="form-control" id="exampleFormControlTextarea1"
                                                        rows="4"
                                                        value={this.state.msg_body === 'null' ? '' : this.state.msg_body}
                                                        onChange={this.handleMsg_bodyChange}></textarea>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <input type="submit" href="/"
                                                            className="btn btn-primary btn-block"
                                                            value="Envoyer votre message" />
                                                    </div>
                                                </div>

                                            </Form>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>

        );
    }
}

export default Contact;