import React, { Component, Fragment} from 'react';
import imgsimple from './../../assets/img/other/imgsimple.png';
import imgsimple2 from './../../assets/img/other/imgsimple2.png';


import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';

import { SEARCHTRAVEL_QUERY } from './../../query/querytrajet';
import { SEARCHTRAVEL_URL } from './../../Url';
import axios from 'axios';
import Iframe from 'react-iframe';
import { withRouter } from 'react-router-dom';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
class ServiceBlockSimple extends Component {
    constructor(props) {
        super(props);
        this.handPublishTripClick = this.handPublishTripClick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    handleSearch(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "search_travel_proposal": {}
        })
        //this.searchTravel(SEARCHTRAVEL_QUERY, VAR)
        
        getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? this.searchTravel(SEARCHTRAVEL_QUERY, VAR) : this.searchTravel(SEARCHTRAVEL_QUERY, VAR)
    }

    searchTravel = async (query, variables) => {
        try {
            const response = await axios.post(SEARCHTRAVEL_URL, {
                query,
                variables
            });
            this.setState(() => ({
                isLoaded: true,
                items: response.data.data
            }));

            // Log the response so we can look at it in the console
            //console.log(response.data)
            //console.log(this.props)
            this.props.history.push({
                pathname: '../trips',
                state: { items: this.state.items }
            });

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            //console.log(error)
        }
    }

    handPublishTripClick() {
        
        if (getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined') {
            this.props.history.push({
                pathname: '../connexion'
            });

        }
        else {
            if (getCookieConsentValue('phone_number') === null || getCookieConsentValue('phone_number') === undefined || getCookieConsentValue('phone_number') === 'null' || getCookieConsentValue('phone_number') === 'undefined') {
                alert("Vous n'avez pas renseigner de numéro de Télephone, merci de le faire dans la page Profil")
                this.props.history.push({
                    pathname: '../dashboard/myprofile',
                })
            }
            else {
                this.props.history.push({
                    pathname: '/dashboard/mytrips',
                    state: { pages: 'new_travel' }
                })

            }

        }
    }

    render() {
        return (
            <Fragment>
                <section className="section-two__home section-pad align-left bg-color-white">
                    <div className="container-fluid container-fluid-pad ">
                        <h2 className="txt-bold mar-bot30">Simple comme bonjour !</h2>
                        <div className="row">

                            <div className="col-sm-6">
                                <h5 className="color-text2"><span className="badge badge-pill bg-color-green color-white">1</span> Le transporteur propose un trajet</h5>
                                <p className="pad-left20">En tant que transporteur, vous renseignez votre trajet et vos critères d'acceptation des kolis (ville de départ, ville d'arrivée, date, prix, volume...).
                            </p>
                                <h5 className="color-text2"><span className="badge badge-pill bg-color-green color-white">2</span>  L'expéditeur effectue sa réservation</h5>
                                <p className="pad-left20">En tant qu'expéditeur, vous réservez et payez les frais d'expédition. Vous pourrez ensuite contacter le transporteur via la messagerie privée pour définir les détails de la transaction.
                            </p>
                                <h5 className="color-text2"><span className="badge badge-pill bg-color-green color-white">3</span>  Le kolis est livré à destination</h5>
                                <p className="pad-left20"> A son arrivée, le transporteur remet le kolis au destinataire et récupère le code de livraison qui permettra à MonKolis de lui verser son argent.
                            </p>

                                <a className="pad-left20 color-blue link" href="/work"><span className="fa fa-long-arrow-right"></span> En savoir plus</a>

                            </div>
                            <div className="col-sm-2 d-none d-sm-block" >
                                <img src={imgsimple2} alt="Logo Tasodo" />
                            </div>

                            <div className="col-sm-4" >
                                <img src={imgsimple} alt="Logo Tasodo" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="align-left" id="guide">
                    <div className="container-fluid container-fluid-pad ">
                        <h2 className="text-bold">Guide et tutos MonKolis </h2>
                        <a href="/tutos" className="link"><span className="fa fa-long-arrow-right"></span> Voir toutes les vidéos</a>

                        <div className="card-deck mar-top30">
                            <div className="card">
                                <div className="card-header embed-responsive embed-responsive-16by9">
                                    <Iframe url="https://www.youtube.com/embed/TpR3DNDjyUU"
                                        className="embed-responsive-item"
                                        allow="fullscreen"
                                    />
                                </div>
                                <div className="card-body">
                                    <h6 className="card-title">Je transporte, comment faire pour créer mon trajet ?</h6>
                                    <p className="card-text"><small className="text-muted"><span class="fa fa-clock-o"></span> Durée : 4 minutes 06</small></p>
                                </div>
                                {/* <div class="card-footer">
                                <button onClick={this.handPublishTripClick} class="btn btn-block btn-primary">Proposer un trajet</button>
                                </div> */}
                            </div>
                            <div className="card">
                                <div className="card-header embed-responsive embed-responsive-16by9">
                                    <Iframe url="https://www.youtube.com/embed/YDaaluW76gQ"
                                        className="embed-responsive-item"
                                        allow="fullscreen"
                                    />
                                </div>
                                <div className="card-body">
                                    <h6 className="card-title">Je souhaite expédier un colis. Comment trouver des transporteurs ?</h6>
                                    <p className="card-text"><small class="text-muted"><span className="fa fa-clock-o"></span> Durée : 2 minutes 48</small></p>
                                </div>
                                {/* <div class="card-footer">
                                <a onClick={this.handleSearch} class="btn btn-block btn-primary">Trouver un transporteur</a>
                                </div> */}
                            </div>
                            <div className="card">
                                <div className="card-header embed-responsive embed-responsive-16by9">
                                    <Iframe url="https://www.youtube.com/embed/hFyR_MFkdp8"
                                        className="embed-responsive-item"
                                        allow="fullscreen"
                                        
                                    />
                                </div>
                                <div className="card-body">
                                    <h6 className="card-title">Les codes de dépôt et code de livraison, qu'est-ce que c'est ?</h6>
                                    <p className="card-text"><small className="text-muted"><span className="fa fa-clock-o"></span> Durée : 2 minutes 28</small></p>
                                </div>
                                {/* <div class="card-footer">
                                <a href="#" class="btn btn-block btn-primary">Créer mon compte</a>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </section>

            </Fragment>

            // <section className="align-left voyageuse">
            //     <div className="container-fluid container-fluid-pad ">
            //         <div className="row">

            //             <div className="col-sm-7" >
            //                     <img src="img/other/voyageuse.png" alt="Voyageuse" className=""/>
            //             </div>

            //             <div className="col-sm-5">
            //             <h2 className="mar-bot20">Vous prenez un vol bientôt?</h2>
            //                 <h4 className="txt-light">Gagnez de l'argent en transportant des colis.</h4>

            //                     <div className="">
            //                         <a href="/dashboard/my-trips/new" className="btn btn-lg btn-primary mar-top30" type="button"> Publiez votre trajet</a>
            //                     </div>
            //             </div>


            //         </div>
            //     </div>
            // </section>
        );
    }
}

export default withRouter(ServiceBlockSimple);
