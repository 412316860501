import React, { Component } from 'react';
import axios from 'axios';
import { GET_USER_NOTIFICATIONS_PREFERENCES_QUERY, MODIFY_USER_NOTIFCATIONS_PREFERENCES_QUERY } from './../../../query/queryprofil'
import { NOTIFICATION_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import Moment from 'moment';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyNotification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			preferences_notifications: [],
			check_message_email: true,
			check_message_whatsapp: true,
			check_message_sms: true,
			check_RPA_email: true,
			check_RPA_whatsapp: true,
			check_RPA_sms: true,
			check_RPA_appel: true,
			check_PC_email: true,
			check_PC_whatsapp: true,
			check_PC_sms: true,
			check_PC_appel: true,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handle_message_email = this.handle_message_email.bind(this);
		this.handle_message_whatsapp = this.handle_message_whatsapp.bind(this);
		this.handle_message_sms = this.handle_message_sms.bind(this);
		this.handle_RPA_email = this.handle_RPA_email.bind(this);
		this.handle_RPA_whatsapp = this.handle_RPA_whatsapp.bind(this);
		this.handle_RPA_sms = this.handle_RPA_sms.bind(this);
		this.handle_RPA_appel = this.handle_RPA_appel.bind(this);
		this.handle_PC_email = this.handle_PC_email.bind(this);
		this.handle_PC_whatsapp = this.handle_PC_whatsapp.bind(this);
		this.handle_PC_sms = this.handle_PC_sms.bind(this);
		this.handle_PC_appel = this.handle_PC_appel.bind(this);
	}

	componentWillMount() {
		
        this.idToken = getCookieConsentValue('idToken');
		//this.idToken = sessionStorage.getItem('idToken')
		this.getNotificationPreferences(NOTIFICATION_URL, this.idToken)
	}

	getNotificationPreferences = async (url, idToken) => {
		try {
			const response = await axios.post(url,
				{ "query": GET_USER_NOTIFICATIONS_PREFERENCES_QUERY },
				{
					'headers':
					{
						'Authorization': idToken
					}
				}
			);

			if (response.data.data.getUserExtraParameters.status === 200){
				this.setState({
					preferences_notifications: response.data.data.getUserExtraParameters.user_extra_parameters.preferences_notifications
				});
			}
			if (this.state.preferences_notifications.length !== 0){
				this.setState({
					check_message_email: this.state.preferences_notifications.messages[0].pref_value ==="Y" ? true : false,
					check_message_whatsapp: this.state.preferences_notifications.messages[1].pref_value ==="Y" ? true : false,
					check_message_sms: this.state.preferences_notifications.messages[2].pref_value ==="Y" ? true : false,
					check_RPA_email: this.state.preferences_notifications.politics_and_community[0].pref_value ==="Y" ? true : false,
					check_RPA_whatsapp: this.state.preferences_notifications.politics_and_community[1].pref_value ==="Y" ? true : false,
					check_RPA_sms: this.state.preferences_notifications.politics_and_community[2].pref_value ==="Y" ? true : false,
					check_RPA_appel: this.state.preferences_notifications.politics_and_community[3].pref_value ==="Y" ? true : false,
					check_PC_email: this.state.preferences_notifications.reminder_promos_tips[0].pref_value ==="Y" ? true : false,
					check_PC_whatsapp: this.state.preferences_notifications.reminder_promos_tips[1].pref_value ==="Y" ? true : false,
					check_PC_sms: this.state.preferences_notifications.reminder_promos_tips[2].pref_value ==="Y" ? true : false,
					check_PC_appel: this.state.preferences_notifications.reminder_promos_tips[3].pref_value ==="Y" ? true : false,
				})
			}
		} catch (error) {
			// If there's an error, set the error to the state
			//this.setState(() => ({ error }))
			console.log(error)
		}
	}

	handleSubmit(event) {
		event.preventDefault();
		var VAR = JSON.stringify({
			"user_extra_parameters": {
				"preferences_notifications": {
					"messages": [
						{
							"pref_name": "Email",
							"pref_type": "S",
							"pref_value": this.state.check_message_email === false ?  "N": "Y"
						},
						{
							"pref_name": "Whatsapp",
							"pref_type": "S",
							"pref_value": this.state.check_message_whatsapp === false ?  "N": "Y"
						},
						{
							"pref_name": "SMS",
							"pref_type": "S",
							"pref_value": this.state.check_message_sms === false ?  "N": "Y"
						}
					],
					"reminder_promos_tips": [
						{
							"pref_name": "Email",
							"pref_type": "S",
							"pref_value": this.state.check_RPA_email === false ?  "N": "Y"
						},
						{
							"pref_name": "Whatsapp",
							"pref_type": "S",
							"pref_value": this.state.check_RPA_whatsapp === false ?  "N": "Y"
						},
						{
							"pref_name": "SMS",
							"pref_type": "S",
							"pref_value": this.state.check_RPA_sms === false ?  "N": "Y"
						},
						{
							"pref_name": "Phone call",
							"pref_type": "S",
							"pref_value": this.state.check_RPA_appel === false ?  "N": "Y"
						}
					],
					"politics_and_community": [
						{
							"pref_name": "Email",
							"pref_type": "S",
							"pref_value": this.state.check_PC_email === false ?  "N": "Y"
						},
						{
							"pref_name": "Whatsapp",
							"pref_type": "S",
							"pref_value": this.state.check_PC_whatsapp === false ?  "N": "Y"
						},
						{
							"pref_name": "SMS",
							"pref_type": "S",
							"pref_value": this.state.check_PC_sms === false ?  "N": "Y"
						},
						{
							"pref_name": "Phone call",
							"pref_type": "S",
							"pref_value": this.state.check_PC_appel === false ?  "N": "Y"
						}
					]
				}
			}
		})
		this.modifypreferences(MODIFY_USER_NOTIFCATIONS_PREFERENCES_QUERY, VAR)
	}

	modifypreferences = async (query, variables) => {
		try {
			const response = await axios.post(NOTIFICATION_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken
					}
				}
			);
			// Set the data to the state
			if (response.data.data.updateUserExtraParameters.status === 200) {
				alert('Modification effectuée')
				//window.location.reload(false);
			}
		} catch (error) {
			// If there's an error, set the error to the state
			console.log(error)
		}
	}


	handle_message_email() {
		this.setState({
			check_message_email: !this.state.check_message_email
		})
	}

	handle_message_whatsapp() {
		this.setState({
			check_message_whatsapp: !this.state.check_message_whatsapp
		})
	}

	handle_message_sms() {
		this.setState({
			check_message_sms: !this.state.check_message_sms
		})
	}

	handle_RPA_email() {
		this.setState({
			check_RPA_email: !this.state.check_RPA_email
		})
	}

	handle_RPA_whatsapp() {
		this.setState({
			check_RPA_whatsapp: !this.state.check_RPA_whatsapp
		})
	}

	handle_RPA_sms() {
		this.setState({
			check_RPA_sms: !this.state.check_RPA_sms
		})
	}

	handle_RPA_appel() {
		this.setState({
			check_RPA_appel: !this.state.check_RPA_appel
		})
	}

	handle_PC_email() {
		this.setState({
			check_PC_email: !this.state.check_PC_email
		})
	}

	handle_PC_whatsapp() {
		this.setState({
			check_PC_whatsapp: !this.state.check_PC_whatsapp
		})
	}

	handle_PC_sms() {
		this.setState({
			check_PC_sms: !this.state.check_PC_sms
		})
	}
	handle_PC_appel(){
		this.setState({
			check_PC_appel: !this.state.check_PC_appel
		})
	}

	render() {
		
		return (
			<div className="section_profile_notification">
				<div className="section notified">
					<div className="section-inner">
						<h3 className="heading">Notifications</h3>
						<div className="content">
							<div className="row">
								<div className="col-sm-6">
									<form onSubmit={this.handleSubmit}>
										<h5 className="">Message</h5>
										<p className="mar-bot20">Recevez des messages de la part des transporteurs et des expéditeurs.	</p>

										<div className="mar-bot15 color-text3">
											<input id="checkbox51" type="checkbox" className="magic-checkbox" checked={this.state.check_message_email} onChange={this.handle_message_email} />
											<label for="checkbox51">E-mail</label>
										</div>
										<div className="mar-bot15 color-text3">
											<input id="checkbox52" type="checkbox" className="magic-checkbox" checked={this.state.check_message_whatsapp} onChange={this.handle_message_whatsapp} />
											<label for="checkbox52">Whatsapp</label>
										</div>
										<div className="mar-bot40 color-text3">
											<input id="checkbox53" type="checkbox" className="magic-checkbox" checked={this.state.check_message_sms} onChange={this.handle_message_sms} />
											<label for="checkbox53">SMS</label>
										</div>


										<h5 className="mar-top20">Rappels, promotions et astuces </h5>
										<p className="mar-bot20">Recevez des rappels, des conseils pratiques pour vos trajets et d'autres messages concernant vos activités sur MonKolis.</p>

										<div className="mar-bot15 color-text3">
											<input id="checkbox54" type="checkbox" className="magic-checkbox" checked={this.state.check_RPA_email} onChange={this.handle_RPA_email} />
											<label for="checkbox54">E-mail</label>
										</div>
										<div className="mar-bot15 color-text3">
											<input id="checkbox55" type="checkbox" className="magic-checkbox" checked={this.state.check_RPA_whatsapp} onChange={this.handle_RPA_whatsapp} />
											<label for="checkbox55">Whatsapp</label>
										</div>
										<div className="mar-bot15 color-text3">
											<input id="checkbox56" type="checkbox" className="magic-checkbox" checked={this.state.check_RPA_sms} onChange={this.handle_RPA_sms} />
											<label for="checkbox56">SMS</label>
										</div>
										<div className="mar-bot40 color-text3">
											<input id="checkbox57" type="checkbox" className="magic-checkbox" checked={this.state.check_RPA_appel} onChange={this.handle_RPA_appel} />
											<label for="checkbox57">Appels téléphoniques</label>
										</div>



										<h5 className="mar-top20">Politique et communauté  </h5>
										<p className="mar-bot20">Recevez des nouvelles sur les réglementations liées à notre activité. Tenez-vous au courant des initiatives visant à créer des lois équitables et responsables au sein de votre communauté. </p>

										<div className="mar-bot15 color-text3">
											<input id="checkbox58" type="checkbox" className="magic-checkbox" checked={this.state.check_PC_email} onChange={this.handle_PC_email} />
											<label for="checkbox58">E-mail</label>
										</div>
										<div className="mar-bot15 color-text3">
											<input id="checkbox59" type="checkbox" className="magic-checkbox" checked={this.state.check_PC_whatsapp} onChange={this.handle_PC_whatsapp} />
											<label for="checkbox59">Whatsapp</label>
										</div>
										<div className="mar-bot15 color-text3">
											<input id="checkbox60" type="checkbox" className="magic-checkbox" checked={this.state.check_PC_sms} onChange={this.handle_PC_sms} />
											<label for="checkbox60">SMS</label>
										</div>
										<div className="mar-bot30 color-text3">
											<input id="checkbox61" type="checkbox" className="magic-checkbox" checked={this.state.check_PC_appel} onChange={this.handle_PC_appel} />
											<label for="checkbox61">Appels téléphoniques</label>
										</div>

										<input type="submit" className="btn btn-block btn-theme2" value="Enregister" />

									</form>
								</div>
								<div className="col-sm-6 coord">
									<h5 className="mar-top10">Coordonnées </h5>
									<p className="mar-bot20">Ces informations peuvent être modifiées sur votre page de profil.<a href="/dashboard/myprofile"> Modifier le profil</a></p>
									<form className="">
										<div className="form-group">
											<label for="emailUser" className="">E-mail</label>
											<input type="email" className="form-control" id="emailUser" placeholder={getCookieConsentValue('email')/*sessionStorage.getItem('email')*/} readOnly />
										</div>

										<div className="form-group">
											<label for="PhoneUser" className="">SMS</label>
											<input type="text" className="form-control" id="PhoneUser" placeholder={getCookieConsentValue('phone_number')/*sessionStorage.getItem('phone_number')*/} readOnly />
										</div>
									</form>

								</div>

							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
}
export default withRouter(MyNotification);