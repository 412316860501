import React, { Component } from 'react';


import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';
import PHOTO from '../../assets/img/user/user0.png'
class ServiceBlockVoila extends Component {
    render() {
        return (
            <div>
                <section className="testimony bg-color-white">
                    <div className="container-fluid container-fluid-pad ">
                        <h2 className="align-left mar-bot30">Merci MonKolis !</h2>
                        <div className="card-deck">
                            <div className="card  border-primary">
                                <div className="card-body">
                                    <h6 className="card-title color-green">Une très belle idée !</h6>
                                    <p className="">C'est pratique, sécurisé et simple</p>
                                    <p className="card-text pad-bot30"><small className="text-muted color-green">Amenan TANOH, Commercante</small></p>
                                </div>
                                <div className="avatar" style={{ backgroundImage: `url(${PHOTO})` }}>
                                </div>
                            </div>
                            <div className="card  border-primary">
                                <div className="card-body">
                                    <h6 className="card-title color-green">La solution qui nous manquait !</h6>
                                    <p className="">MonKolis m'a permis d'accroitre ma clientèle et ma visibilité</p>
                                    <p className="card-text pad-bot30"><small className="text-muted color-green">Vivien THIERRY, Logisticien</small></p>
                                </div>
                                <div className="avatar" style={{ backgroundImage: `url(${PHOTO})` }}>
                                </div>
                            </div>
                            <div className="card border-primary">
                                <div className="card-body">
                                    <h6 className="card-title color-green">Une révolution !</h6>
                                    <p className="">Grace à Monkolis, j'arrive à envoyer des colis à mes proches partout dans le monde, toute l'année</p>
                                    <p className="card-text pad-bot30"><small className="text-muted color-green">Jacques N'GOG, Business Developer</small></p>
                                </div>
                                <div className="avatar" style={{ backgroundImage: `url(${PHOTO})` }}>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="section-stats align-center">
                    <div className="container-fluid container-fluid-pad " >
                        <h2 className="align-left mar-bot30 txt-bold">Voilà où nous en sommes !</h2>
                        <div className="row">
                            <div className="col-sm-3 col-6">
                                <p><i className="fa fa-archive fa-5x color-green"></i></p>
                                <h3 className="data">21 337</h3>
                                <hr></hr>
                                <h6>Kolis transportés</h6>
                            </div>

                            <div className="col-sm-3 col-6">
                                <p><i className="fa fa-plane fa-5x color-green"></i></p>
                                <h3 className="data">9 764</h3>
                                <hr />
                                <h6>Trajets effectués</h6>
                            </div>

                            <div className="col-sm-3 col-6">
                                <p><i className="fa fa-comments fa-5x color-green"></i></p>
                                <h3 className="data">19 000</h3>
                                <hr />
                                <h6>Belles rencontres</h6>
                            </div>

                            <div className="col-sm-3 col-6">
                                <p><i className="fa fa-trophy fa-5x color-green"></i></p>
                                <h3>996</h3>
                                <hr />
                                <h6>Beaux témoignages</h6>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ServiceBlockVoila;
