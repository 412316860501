import React, { Component, Fragment } from 'react';
// import './../../../assets/css/main.css';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';
import './../../assets/css/trip.css';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import { RESERVE_TRAVEL_QUERY } from './../../query/querytrajet';
import { TRAVEL_ACTIONS_URL } from './../../Url';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import PhoneInput from 'react-phone-number-input';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import Modal from 'react-bootstrap/Modal';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import Moment from 'moment'

import DEFAULT_PHOTO from '../../assets/img/user/user0.png';

class Payment extends Component {
    constructor(props) {
        super(props);
        if (localStorage.getItem('user_dahsboard_details') !== null) {
            var user_dashboard_details = localStorage.getItem('user_dahsboard_details')
            let decodde = new Buffer(user_dashboard_details, 'base64');
            this.user_info = JSON.parse(decodde.toString('utf-8'));
        }

        this.countryList = countryList().getData();
        this.state = {
            countryList: this.countryList,
            error: [],
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            nameCard: '',
            number: '',
            receiver_phone_number: '',
            surname: '',
            instructions: '',
            showReportModal: false,
            disable_reservation: false,

            email_verified: this.user_info.verifications.email_verified,
            phone_number_verified: this.user_info.verifications.phone_number_verified,
            id_card_verified: this.user_info.verifications.id_card_verified
        }

        this.discount_total = 0;
        this.reserverNumber = "";
        if (localStorage.getItem('tripdetail') !== null) {
            var tripdetail = localStorage.getItem('tripdetail')
            let decodde = new Buffer(tripdetail, 'base64');
            this.travel_infos = JSON.parse(decodde.toString('utf-8'));
            this.amoung_reserved = localStorage.getItem('among_reserved')
            this.reserverNumber = this.travel_infos.user_proposal_attributes === null || this.travel_infos.user_proposal_attributes === undefined || this.travel_infos.user_proposal_attributes === 'null' || this.travel_infos.user_proposal_attributes === 'undefined' ? '' : this.travel_infos.user_proposal_attributes.phone_number
        }
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePhone_numberChange = this.handlePhone_numberChange.bind(this);
        this.handleSurnameChange = this.handleSurnameChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleNameCardChange = this.handleNameCardChange.bind(this);
        this.handleInstructionsChange = this.handleInstructionsChange.bind(this);
        this.handleSubmitPayment = this.handleSubmitPayment.bind(this);
        this.handleCloseReportModal = this.handleCloseReportModal.bind(this);
    }

    componentWillMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        this.accessToken = getCookieConsentValue('accessToken');
        this.contrib_cwf_balance = getCookieConsentValue('contrib_cwf_balance')
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    handleCloseReportModal() {
        this.setState(
            { showReportModal: false }
        )
        this.props.history.push({ pathname: '/dashboard/myexpeditions' })
    }

    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    handlePhone_numberChange(event) {
        this.setState({ receiver_phone_number: event });
    }

    handleSurnameChange(event) {
        this.setState({ surname: event.target.value });
    }

    handleNameChange(event) {
        this.setState({ name: event.target.value });
    }

    handleNameCardChange(event) {
        this.setState({ nameCard: event.target.value });
    }

    handleInstructionsChange(event) {
        this.setState({ instructions: event.target.value });
    }

    handleSubmitPayment(event) {
        event.preventDefault();
        this.setState({
            disable_reservation: !this.state.disable_reservation 
        });
        var VAR = JSON.stringify({
            "requester_proposal": {
                "id_travel": this.travel_infos.id_travel,
                "transaction_id": "RP-200198-0000111-45",
                "transaction_amount": parseFloat(this.amoung_reserved * this.travel_infos.service_charge_amount).toFixed(2).toString(),//parseFloat(this.amoung_reserved* this.travel_infos.proposal_amount_by_kg/1.25).toFixed(2).toString() ,//parseFloat(parseFloat(this.amoung_reserved * this.travel_infos.proposal_amount_by_kg) - parseFloat(this.discount_total)).toFixed(2).toString(),
                "transaction_date": new Date,
                "transaction_status": "TRANSACTION_SUCCESS",
                "contrib_cwf_discount": parseFloat(this.discount_total).toFixed(2).toString(),
                "weight_reserved": this.amoung_reserved,
                "estimated_amount_value": parseFloat(this.amoung_reserved * this.travel_infos.service_charge_amount).toFixed(2).toString(),//parseFloat(this.amoung_reserved* this.travel_infos.proposal_amount_by_kg/1.25).toFixed(2).toString(),//parseFloat(parseFloat(this.amoung_reserved * this.travel_infos.proposal_amount_by_kg) - parseFloat(this.discount_total)).toFixed(2).toString(),
                "town": this.travel_infos.arrival_city,
                "service_charge_amount": parseFloat(this.amoung_reserved * this.travel_infos.proposal_amount_by_kg).toFixed(2).toString(),//parseFloat(this.amoung_reserved * (this.travel_infos.proposal_amount_by_kg/1.25) * 0.25).toFixed(2), //parseFloat(parseFloat(this.travel_infos.proposal_amount_by_kg).toFixed(2) - parseFloat(this.travel_infos.service_charge_amount).toFixed(2)).toFixed(2),
                "additional_infos": "xx",//this.state.instructions,
                "receiver_infos": {
                    "firstname": "xx",//this.state.nameCard,
                    "lastname": "xx", //this.state.surname,
                    "contacts": {
                        "contact_type": "MOBILE",
                        "contact_value": "xx" //this.state.receiver_phone_number
                    }
                }
            }
        });
        this.reserveTravel(RESERVE_TRAVEL_QUERY, VAR)

        // this.props.history.push({
        //     pathname: '/dashboard/myexpeditions',
        // });
    }

    reserveTravel = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken,
                        'AccessToken': this.accessToken
                    }
                }
            );
            // Action after the result
            //console.log(response)

            if (response.data.data.requestProposalTravel.status === 200) {
                //getCookieConsentValue('contrib_cwf_discount', parseFloat(this.contrib_cwf_balance) - parseFloat(response.data.data.requestProposalTravel.requester_proposal.contrib_cwf_discount))
                //sessionStorage.setItem('contrib_cwf_discount', parseFloat(this.contrib_cwf_balance) - parseFloat(response.data.data.requestProposalTravel.requester_proposal.contrib_cwf_discount))
                //console.log(response)

                this.setState({
                    showReportModal: !this.state.showReportModal,
                });
            } else {
                this.setState({
                    disable_reservation: !this.state.disable_reservation 
                });
                alert('Reservation échouée')
            }


        } catch (error) {
            // If there's an error, set the error to the state
            this.setState({
				disable_reservation: !this.state.disable_reservation 
			});
            this.setState(() => ({ error }))
            alert('Une erreur inconnue est survenue')
            console.log(error)
        }
    }

    getDiscount() {
        if (this.contrib_cwf_balance === 0 || this.contrib_cwf_balance === "null") {
            this.discount_total = 0
            return this.discount_total
        } else {
            if (parseFloat(this.contrib_cwf_balance) >= parseFloat(parseFloat(this.travel_infos.proposal_amount_by_kg).toFixed(2) - parseFloat(this.travel_infos.service_charge_amount).toFixed(2)).toFixed(2)) {
                this.discount_total = parseFloat(parseFloat(this.travel_infos.proposal_amount_by_kg).toFixed(2) - parseFloat(this.travel_infos.service_charge_amount).toFixed(2)).toFixed(2);
                return this.discount_total
            } else {
                this.discount_total = this.contrib_cwf_balance;
                return this.discount_total
            }
        }
    }

    render() {
        
        if (/*sessionStorage.getItem('idToken') === null && */getCookieConsentValue('idToken') === null) {
            return (
                <div>
                    <Redirect to="/connexion" />
                </div>
            )
        } else {
            return (
                <Fragment>
                <div className="money">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row mar-top20 mar-bot20">
                                <div className="col-sm-8">
                                    <div className="section">
                                        <div className="payment-inner">
                                            <div className="row">

                                                <div className="col-sm-4">
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <span className="fa fa-2x fa-check-square color-green"></span>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="mar-bot0"><span className="txt-bold">Mon espace est garanti</span> <br /> <span className="">en réglant</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="media ">
                                                        <div className="media-left">
                                                            <span className="fa fa-2x fa-window-close color-green"></span>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="mar-bot0"><span className="txt-bold">Je peux annuler</span> <br /> <span className="">en cas d'empechement</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <span className="fa fa-2x fa-share-square color-green"></span>
                                                        </div>
                                                        <div className="media-body mar-left5">
                                                            <p className="mar-bot0"><span className="txt-bold">Vous êtes remboursé</span> <br /> <span className="">si le trasnporteur annule</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>



                                    <div className="section mar-top10">
                                        <div className="payment-inner">
                                            <h5 className="txt-bold">Récapitulatif du transport</h5>

                                            <form>
                                                {/* <div className="form-group row">
                                    <label for="inputPassword" className="col-sm-5 col-form-label">Je souhaite une réponse avant</label>
                                    <div className="col-sm-7">
                                        <select className="form-control" id="">
                                            <option>Demain à 15h00</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div> */}

                                            </form>

                                            <div className="command bg-color-white">
                                                <div className="row mar-bot0">
                                                    <div className="col-sm-7">

                                                        <p><span className="txt-bold">{this.amoung_reserved} {this.travel_infos.measure_unit} </span>x {parseFloat(this.travel_infos.service_charge_amount).toFixed(2)} €</p>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <p className="txt-bold align-right">{parseFloat(this.amoung_reserved * this.travel_infos.service_charge_amount).toFixed(2)} €</p>
                                                    </div>
                                                </div>
                                                <div className="row mar-bot0">
                                                    <div className="col-sm-7">
                                                        <p>Frais de réservation  <span className="fa fa-1x fa-question-circle-o" data-placement="right" data-toggle="tooltip" title="Les frais de réservation sont les commissions versées à MonKolis pour continuer de vous assurer ce service"></span></p>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <p className="txt-bold align-right">{parseFloat(this.amoung_reserved * (this.travel_infos.service_charge_amount) * 0.25).toFixed(2)} €</p>
                                                    </div>
                                                </div>
                                                <div className="row mar-bot0">
                                                    <div className="col-sm-7">
                                                        <p className="mar-bot0">Total</p>
                                                    </div>

                                                    <div className="col-sm-5">
                                                        <p className="txt-bold align-right mar-bot0">{parseFloat((this.amoung_reserved * this.travel_infos.service_charge_amount) + (this.amoung_reserved * (this.travel_infos.service_charge_amount) * 0.25)).toFixed(2)} €</p>
                                                    </div>
                                                </div> <br />
                                                {/* <div className="row mar-bot0">
                                            <div className="col-sm-7">
                                                <p className="">Promo Crowfunding Double-Cash-Back  <span className="fa fa-1x fa-question-circle-o" data-placement="right" data-toggle="tooltip" title="Vous bénéficiez de réduction sur vos frais grâce à votre participation à notre Crowfunding. Vous pouvez consultez votre solde dans votre compte"></span></p>
                                            </div>
                                            <div className="col-sm-5">
                                                <p className="txt-bold align-right">{"-" + this.getDiscount()} €</p>
                                            </div>
                                        </div> */}

                                                <div className="row mar-bot0">
                                                    <div className="col-sm-7">
                                                        <p>Bon de réduction </p>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <p className="txt-bold align-right">{"-" + parseFloat(this.amoung_reserved * (this.travel_infos.service_charge_amount) * 0.25).toFixed(2)} €</p>
                                                    </div>
                                                </div>

                                                <hr />

                                                <div className="row">
                                                    <div className="col-sm-7">
                                                        <p className="mar-bot0">Montant Total</p>
                                                    </div>
                                                    {/* <div className="col-sm-5">
                                                <p className="txt-bold align-right mar-bot0">{parseFloat(parseFloat(this.amoung_reserved * this.travel_infos.proposal_amount_by_kg) - parseFloat(this.discount_total)-parseFloat(this.getDiscount())).toFixed(2)} €</p>
                                            </div> */}
                                                    <div className="col-sm-5">
                                                        <p className="txt-bold align-right">{parseFloat(this.amoung_reserved * this.travel_infos.service_charge_amount).toFixed(2)} €</p>
                                                    </div>
                                                </div>
                                                <p className="txt-bold align-right" style={{ fontStyle: 'italic' }}>Promotions ! les frais de réservation ne sont pas facturés durant la phase de lancement. </p>
                                                <p className="txt-bold align-right" style={{ fontStyle: 'italic' }}>Pour le moment , Le paiement se fera de main à main auprès du transporteur. </p>
                                            </div>

                                            <br />
                                            <br />
                                            <form onSubmit={this.handleSubmitPayment}>
                                                {/* <h5 className="">Informations du receveur</h5>

                                    <div className="row mar-bot15">
                                        <div className="col-lg-4"><label>Nom</label></div>
                                        <div className="col-lg-8"><input type="text" className="form-control col-sm-9" placeholder="Votre Nom" value={this.state.surname} onChange={this.handleSurnameChange} required="true" /></div>
                                    </div>
                                    <div className="row mar-bot15">

                                        <div className="col-lg-4"><label >Prenoms</label></div>
                                        <div className="col-lg-8"><input type="text" className="form-control col-sm-9" placeholder="Vos Prénoms" value={this.state.nameCard} onChange={this.handleNameCardChange} required="true" /></div>

                                    </div>

                                    <div className="row mar-bot15">
                                        <div className="col-lg-4"> <label for="travelDead">Numero de téléphone </label></div>
                                        <div className="col-lg-6"><PhoneInput
                                            placeholder="Enter phone number"
                                            inputClassName="form-control col-sm-9"
                                            value={this.state.phone_number === null ? '' : this.state.phone_number}
                                            onChange={this.handlePhone_numberChange}
                                        /></div>

                                    </div>
                                    <div className="row mar-bot15">

                                        <div className="col-lg-4"><label >Laisser vos instructions </label></div>
                                        <div className="col-lg-8"><textarea className="form-control" id="exampleFormControlTextarea1" rows="3" cols="20" value={this.state.instructions} onChange={this.handleInstructionsChange} required="true"></textarea></div>

                                    </div> */}

                                                {/* <div className="row mar-top30">
                                            <div className="col-sm-7">
                                                <h5 className="">Votre moyen de paiement</h5>
                                            </div>
                                            <div className="col-sm-5">
                                                <h6 className="align-right">Paiement sécurisé <span ><i className="fa fa-lock"></i> </span></h6>
                                            </div>
                                        </div>


                                        <div className="command bg-color-white">

                                            <h5 className="color-text3">Carte bancaire <span className="fa fa-1x fa-cc-visa color-green"></span><span className="fa fa-1x fa-cc-mastercard color-green"></span></h5>
                                            <hr />

                                            <form className="">
                                                <div className="form-group row">
                                                    <div id="PaymentForm" className="form-group col-md-6">
                                                        <Cards
                                                            cvc={this.state.cvc}
                                                            expiry={this.state.expiry}
                                                            focused={this.state.focus}
                                                            name={this.state.name}
                                                            number={this.state.number}
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <form>
                                                            <div className="form-group">
                                                                <input className="col-sm-10" type="tel" name="number" placeholder="Card Number" onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                                                            </div>

                                                            <div className="form-group">
                                                                <input className="col-sm-10" type="text" name="name" placeholder="Name" onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                                                            </div>
                                                            <div className="form-group">
                                                                <input className="col-sm-5" type="number" name="expiry" placeholder="Valid Thru" onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                                                            </div>
                                                            <div className="form-group">
                                                                <input className="col-sm-5" type="number" name="cvc" placeholder="CVC" onChange={this.handleInputChange} onFocus={this.handleInputFocus} />
                                                            </div>
                                                        </form>
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-4">
                                                    </div>
                                                    <div className="col-sm-8">

                                                        <input type="submit" className="btn btn-primary btn-block btn-lg" value={"Payer " + parseFloat(parseFloat(this.amoung_reserved * this.travel_infos.proposal_amount_by_kg) - parseFloat(this.discount_total)).toFixed(2) + "€"} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div> */}

                                                <div className="col-sm-8">
                                                    <form>
                                                        <input type="submit" className="btn btn-primary btn-block btn-lg" disabled={this.state.disable_reservation} value={"Confirmer la réservation " + parseFloat(this.amoung_reserved * this.travel_infos.service_charge_amount).toFixed(2) /* this.travel_infos.proposal_amount_by_kg/1.25 */ /*this.amoung_reserved*parseFloat(this.travel_infos.proposal_amount_by_kg - parseFloat(parseFloat(this.travel_infos.proposal_amount_by_kg).toFixed(2) - parseFloat(this.travel_infos.service_charge_amount).toFixed(2)).toFixed(2)).toFixed(2)*/ + "€"} /> &nbsp;&nbsp;
                                                        {this.state.disable_reservation === true ? <label className="text-danger"><i className="fa fa-1x fa-spinner fa-spin"></i> Votre réservation est en cours</label> : ''}
                                                    </form>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4 mar-bot20">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Récapitulatif</h3>
                                        </div>
                                        <div className="card-body">
                                            <p className="mar-bot5"><i className="fa fa-calendar"></i><span>  {Moment(this.travel_infos.departure_date).format('DD/MM/YYYY')}</span></p>
                                            <p className="mar-bot5"><i className="fa fa-dot-circle-o color-green"></i><span>  {this.travel_infos.departure_city}</span></p>
                                            <p className="mar-bot5"><i className="fa fa-dot-circle-o color-blue"></i><span>  {this.travel_infos.arrival_city}</span></p>
                                            <hr />
                                            <div className="media media2 mar-bot5">
                                                <div className="media-left media-top">
                                                    <div className="photo">
                                                        {this.travel_infos.user_proposal_attributes.picture === null || this.travel_infos.user_proposal_attributes.picture === 'null' ?
                                                            <img src={DEFAULT_PHOTO} alt="MDN Logo" className="media-object cover rounded" /> :
                                                            <img src={this.travel_infos.user_proposal_attributes.picture} alt="MDN Logo" className="media-object cover rounded " />
                                                        }
                                                        {/* <img src={this.travel_infos.user_proposal_attributes.picture} alt="MDN Logo" className="media-object cover rounded" /> */}
                                                    </div>
                                                </div>
                                                <div className="media-body mar-left10">
                                                {
													this.travel_infos.user_proposal_attributes.account_type === null || this.travel_infos.user_proposal_attributes.account_type === undefined || this.travel_infos.user_proposal_attributes.account_type === 'null' || this.travel_infos.user_proposal_attributes.account_type === 'undefined' || this.travel_infos.user_proposal_attributes.account_type === "Particulier" || this.travel_infos.user_proposal_attributes.account_type === "" ? 
													<h5 className="media-heading">{this.travel_infos.user_proposal_attributes.given_name === null || this.travel_infos.user_proposal_attributes.given_name === undefined || this.travel_infos.user_proposal_attributes.given_name === 'undefined' || this.travel_infos.user_proposal_attributes.given_name === 'null' || this.travel_infos.user_proposal_attributes.given_name === '' ? this.travel_infos.user_proposal_attributes.name : this.travel_infos.user_proposal_attributes.given_name}</h5>
													:
													<h5 className="media-heading txt-bold">{this.travel_infos.user_proposal_attributes.account_type_details.company_name === null || this.travel_infos.user_proposal_attributes.account_type_details.company_name === undefined || this.travel_infos.user_proposal_attributes.account_type_details.company_name === 'undefined' || this.travel_infos.user_proposal_attributes.account_type_details.company_name === 'null' || this.travel_infos.user_proposal_attributes.account_type_details.company_name === '' ? this.state.name  : this.travel_infos.user_proposal_attributes.account_type_details.company_name}  </h5>
												}
                                                    
                                                </div>
                                            </div>
                                            {
                                                this.travel_infos.user_proposal_attributes.account_type === null || this.travel_infos.user_proposal_attributes.account_type === undefined || this.travel_infos.user_proposal_attributes.account_type === 'null' || this.travel_infos.user_proposal_attributes.account_type === 'undefined' || this.travel_infos.user_proposal_attributes.account_type === "Particulier" || this.travel_infos.user_proposal_attributes.account_type=== "" ? '' :
                                                    <div>
                                                        <span class="badge badge-danger">Professionnel</span>
                                                    </div>
                                            }


                                            <hr />

                                            <ul className="fa-ul txt-bold color-green">
                                                {this.state.id_card_verified === 'true' ?
                                                    <li className="mar-bot10"><i className="fa-li fa fa-check-square-o"></i>Pièce d'identité</li> :
                                                    <li className="color-red mar-bot10"><i class="fa-li fa fa-close"></i>Pièce d'identité</li>

                                                }

                                                {this.state.phone_number_verified !== 'true' ?
                                                    <li className="color-red mar-bot10"><i class="fa-li fa fa-close"></i>Téléphone</li> :
                                                    <li className="mar-bot10"><i className="fa-li fa fa-check-square-o"></i>Téléphone</li>
                                                }

                                                {this.state.email_verified !== 'true' ?
                                                    <li className="color-red mar-bot10"><i class="fa-li fa fa-close"></i>Email</li> :
                                                    <li className="mar-bot10"><i className="fa-li fa fa-check-square-o"></i>Email</li>
                                                }
                                            </ul>


                                        </div>
                                    </div>
                                </div>
                                <Modal show={this.state.showReportModal} onHide={this.handleCloseReportModal} centered backdrop="static">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Réservation effectuée</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <p>
                                                Bravo ! Vous pouvez écrire au transporteur pour convenir du lieu de rdv ou annuler votre réservation dans la section mes expéditions en cours.
                                                    {this.reserverNumber !== '' ? <p> <i className="fa fa-phone"></i> son contact : {this.reserverNumber}</p> : ''}
                                            </p>
                                        </div>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className="btn btn-primary" onClick={(e) => { this.props.history.replace('/dashboard/myexpeditions', '') } }>
                                            Ok
                                            </button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                </Fragment>
            )
        }
    }

}

export default withRouter(Payment);
