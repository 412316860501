import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/annex.css';
import './../../../assets/css/modal.css';
import './../../../assets/css/blog.css';
import ENTETE from './../../../assets/img/blog/kolilovers.png';
import FUSEE from './../../../assets/img/blog/fusee.png';

class Expeditions extends Component {
  render() {
    return (

      <Fragment>
        <Helmet>
          <title>Expéditions à l'international | MonKolis</title>
          <meta name="robots" content="index,follow" />
        </Helmet>

        <div className="blog">
          <div className="main-box_content">
            <div className="container-fluid container-fluid-br headerwrap box-radius">
              <div className="blog-title">
                <h2 className="title">Blog MonKolis</h2>
                <hr></hr>
                <h5 className="txt-light">Histoires, conseils, secrets, astuces... On vous raconte tout !</h5>
              </div>
            </div>
            <div className="container-fluid container-fluid-pad container-fluid-br">
              <div className="row pad-top20 pad-bot20">
                <div className="col-sm-3">
                  <div className="list-group">
                    <li className="list-group-item bg-color-global txt-bold">Articles</li>
                    <a href="/blog/genesis" className="list-group-item list-group-item-action">La Génèse de MonKolis</a>
                    <a href="/blog/press-communication" className="list-group-item list-group-item-action">Communiqué de presse de lancement</a>
                    <a href="/blog/travel-to-Senegal" className="list-group-item list-group-item-action "> Visiter le Sénégal</a>
                    <a href="/blog/personnal-shopper" className="list-group-item list-group-item-action"> Personnal Shopping</a>
                    <a href="/blog/covid" className="list-group-item list-group-item-action"> Les 3 destinations idéales à visiter pendant le COVID</a>
                    <a href="/blog/visit-Belgium" className="list-group-item list-group-item-action"> Visiter la Belgique</a>
                    <a href="/blog/monkolis-make-money" className="list-group-item list-group-item-action"> Gagner de l'argent avec Monkolis</a>
                    <a href="/blog/monkolis-mouve-out" className="list-group-item list-group-item-action"> Préparer son déménagement</a>
                    <a href="/blog/expedition" className="list-group-item list-group-item-action active" > Expéditions à l'international</a>
                  </div>
                </div>

                <div className="col-sm-9">
                  <div className="section">
                    <div className="section-inner">
                    <img src={ENTETE} alt="Insérer une photo" className="cover rounded" />
                    <br/> <br/>
                    <h3 class="heading mar-bot0">Expédition à l’international : ce qu'il faut considérer en premier</h3>
                    <p> En tant que commerçant, expédier vos articles à l’international peut être la clé qui vous permettra de devancer la concurrence. Cela vous permettra d'étendre vos activités et d'atteindre un plus grand nombre de clients, dans le monde entier.
Avant de vous lancer dans l'expédition internationale, vous devez réfléchir à plusieurs points :
</p> 

<img src={FUSEE} alt="Insérer une photo" className="cover rounded" />
<br/> <br/>
<p><b>Le type de marchandises</b> : selon le type de marchandises que vous vendez, vous devrez vous conformer à différentes réglementations. Certains produits sont soumis à la TVA et aux taxes d'importation et peuvent même être interdits dans certains pays. D'autres produits peuvent nécessiter des conditions de transport particulières et ne pas convenir à certains services d'expédition. </p>
<p><b>Pays de livraison</b> : vous pouvez décider d'offrir un service d'expédition mondial ou de réduire le champ de vos livraisons à un ensemble spécifique de pays. En fonction de votre champ d’expédition, vous devez prendre en compte les restrictions des pays d'enlèvement et de livraison avant d'accepter la vente.</p>
<p><b>TVA, droits de douane et taxes </b>: lors d'une expédition internationale, il peut y avoir des frais de TVA, des droits de douane, des tarifs et des taxes d'exportation ou d'importation à payer. Vous devrez peut-être obtenir un numéro de TVA spécial ou un permis d'exportation.
Volume d'expédition : En calculant le volume d'expédition mensuel moyen, vous pouvez planifier à l'avance et réduire les coûts d'expédition internationale. Une bonne pratique avant de mettre en place un système d'expédition mondial est de calculer le nombre d'envois nationaux que vous effectuez. </p>
<p><b>Emballage </b>: les vendeurs doivent fournir un emballage adéquat et préparer leurs produits en toute sécurité avant l'expédition. Dans notre prochain article il sera justement question de l’emballage avant expédition.</p>

<p><b>Expédition internationale : avantages et inconvénients </b></p>

<p><b><i>AVANTAGES</i></b></p>

<p><b>Marché pertinent </b>: en proposant l'expédition internationale, vous toucherez des clients partout dans le monde. En vendant en ligne, vos utilisateurs cibles peuvent accéder à votre page depuis n'importe quel endroit.</p>
<p><b>Élargissement de la clientèle </b>: en dépassant le cadre de votre marché local, vous pourrez cibler un grand nombre de clients potentiels à la recherche d'un produit comme le vôtre.</p>
<p><b>Améliorer l'image de votre marque </b>: une expansion mondiale vous aidera à devenir une marque plus forte.</p>

<p><b><i>INCONVÉNIENTS</i></b></p>

<p>Douanes, droits d'importation et TVA : les vendeurs en ligne doivent tenir compte des procédures douanières, des droits d'importation et d'exportation et de la TVA internationale lorsqu'ils mettent en place une expédition internationale. Ces éléments peuvent non seulement rendre vos livraisons plus coûteuses, mais aussi ralentir le processus d'expédition.
Restrictions internationales : certains produits et articles ne peuvent pas être vendus sur certains marchés, et les vendeurs ont parfois besoin de permis spéciaux pour pouvoir vendre dans un endroit spécifique.
Coûts plus élevés : l'expédition à l'étranger est plus coûteuse. Toutefois, avec Monkolis vous pouvez obtenir les meilleurs tarifs d’expédition en vous inscrivant sur notre site <a href="https://www.monkolis.com">www.monkolis.com</a>. Sur notre site, vous aurez accès à des transporteurs particuliers ou des professionnels ayant des trajets réguliers. Vous pourrez également choisir les meilleurs délais de livraison afin que vos clients ne restent pas longtemps dans l’attente. 
 </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}
export default Expeditions;