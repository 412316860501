import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';
import { withRouter, Redirect } from 'react-router-dom';

class PersonnalShooping extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Personal Shooper | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="legales">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/personnalshooping" className="list-group-item list-group-item-action active">Comment ça marche</a>
                                        <a href="/personnalshooper" className="list-group-item list-group-item-action">Nos personal shoppers </a>
                                    </div>

                                </div>
                                
                                <div className="col-sm-9">
                                <div className="aside section">

                                            <div class="section-inner">
                                                <h3 class="heading">Tout savoir sur le personal shopping</h3>
                                                <p> Le personal shopper, aussi appelé assistant d’achat ou commis aux commandes personnelles, est un métier de conseil au service de particuliers.
                           Son rôle est d'orienter le client vers le produit qui convient à son style, ses goûts et son budget. Certains sont employés par les boutiques et grands magasins, d'autres travaillent en tant qu'indépendants ou n'offrent qu'un service en ligne. Leurs conseils portent en général sur les vêtements, même si le nombre de boutiques non vestimentaires qui offrent ce service, tels que les magasins d'ameublement, est en hausse, et maints commis aideront leurs clients dans la recherche de tout article dont ils ont envie. </p>

                                                <p> Retrouvez tous nos partenaires personal shoppers en cliquant sur le lien  : <a href="/personnalshooper" class="link">liste des personal shoppers.</a></p>

                                            </div>


                                        </div>
                                    </div>


                                </div>
                            </div>
                            </div>
                        </div>
                    
               
            </Fragment>
        );
    }
}
export default withRouter(PersonnalShooping);