
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import IMG_OOPS from '../../assets/img/other/oops.png'

class Error404 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <div class="container-fluid container-fluid-pad container-fluid-br">
                    <div class="">
                        <nav class="navbar navbar-light navbar-expand-sm">
                        </nav>

                        <div class="row pad-top40 pad-bot40">
                            <div class="col-md-5">
                                <img src={IMG_OOPS} alt="MDN Logo" class="cover" />

                            </div>

                            <div class="col-md-7 pad-top20 ">

                                <h1 class="heading color-green oops">Oops !</h1>
                                <h1 class="mar-bot15">Erreur 404 (Page Not found).</h1>
                                <h2 class="txt-light mar-bot30">Désolé, cette page est inaccessible !</h2>

                                <h5 class="txt-light mar-bot15">1.  Vous pouvez consulter notre <a href="/" class="link color-blue">page d'accueil</a> et profitez de bons plans :)</h5>
                                <h5 class="txt-light mar-bot15">2.  Envoyez-nous vos <a href="/contact" class="link color-blue">avis</a> avec ce que vous vous attendiez à voir sur cette page :(</h5>
                            </div>

                        </div>
                    </div> 

                </div>
            </div>
        );
    }
}
export default withRouter(Error404);