import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/annex.css';
import './../../../assets/css/modal.css';
import './../../../assets/css/blog.css';
import MASK from './../../../assets/img/blog/masque.jpg';
import GIRAFFE from './../../../assets/img/blog/giraffe.jpg';
import PLAGE from './../../../assets/img/blog/plage.jpg';
import SAOTOME from './../../../assets/img/blog/sao-tome.jpg';
import SEYCHELLES from './../../../assets/img/blog/seychelles.jpg';

class Covid extends Component {
  render() {
    return (

      <Fragment>
        <Helmet>
          <title>Blog | Monkolis</title>
          <meta name="robots" content="index,follow" />
        </Helmet>

        <div className="blog">
          <div className="main-box_content">
            <div className="container-fluid container-fluid-br headerwrap box-radius">
              <div className="blog-title">
              <h2 className="title">Blog MonKolis</h2>
                <hr></hr>
                <h5 className="txt-light">Histoires, conseils, secrets, astuces... On vous raconte tout !</h5>
              </div>
            </div>
            <div className="container-fluid container-fluid-pad container-fluid-br">
              <div className="row pad-top20 pad-bot20">
                <div className="col-sm-3">
                  <div className="list-group">
                    <li className="list-group-item bg-color-global txt-bold">Articles</li>
                    <a href="/blog/genesis" className="list-group-item list-group-item-action">La Génèse de MonKolis</a>
                    <a href="/blog/press-communication" className="list-group-item list-group-item-action">Communiqué de presse de lancement</a>
                    <a href="/blog/travel-to-Senegal" className="list-group-item list-group-item-action "> Visiter le Sénégal</a>
                    <a href="/blog/personnal-shopper" className="list-group-item list-group-item-action"> Personnal Shopping</a>
                    <a href="/blog/covid" className="list-group-item list-group-item-action active"> Les 3 destinations idéales à visiter pendant le COVID</a>
                    <a href="/blog/visit-Belgium" className="list-group-item list-group-item-action"> Visiter la Belgique</a>
                    <a href="/blog/monkolis-make-money" className="list-group-item list-group-item-action"> Gagner de l'argent avec Monkolis</a>
                    <a href="/blog/monkolis-mouve-out" className="list-group-item list-group-item-action "> Préparer son déménagement</a>
                    <a href="/blog/expedition" className="list-group-item list-group-item-action" > Expéditions à l'international</a>
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="section">
                    <div className="section-inner">
                    <h3 class="heading mar-bot0">Les 3 destinations idéales à visiter pendant le COVID</h3>
                    <img src={MASK} alt="Insérer une photo" className="cover rounded" />
                    <br/> <br/>
                    <p>Depuis le début de la crise sanitaire liée au coronavirus la situation n’a cessé d’évoluer. Si les organismes sanitaires internationaux font de leurs mieux pour que tout le monde soit au même niveau d’information, il revient à chacun de se soumettre aux mesures quoi que restrictives destinées à limiter l’impact de la maladie. <br />
                      L’Afrique qui est toujours le continent le moins touché ne compte par contre pas moins de 3 905 936 cas à ce jour. 
                      Que ce soit pour des raisons professionnelles ou pour de simples vacances, si vous souhaitez voyager sur le continent Africain, assurez-vous de bien prendre connaissance de la situation qui prévaut dans le pays où vous vous rendez. <br />
                      Pour vous aider Monkolis a fait le point des pays où il est moins risqué de se rendre en cette période sensible. Les détails dans la suite de cet article.
                    </p>
                    <h3>LA TANZANIE</h3> 
                    <img src={GIRAFFE} alt="Insérer une photo" className="cover rounded" />
<p>Il y a quelques mois certains pays du continent ne figuraient pas sur la liste des pays touchés par le coronavirus, hélas ce n’est plus le cas. Le dernier pays à entrer dans la danse fut le Lesotho et il présente à ce jour 10 497 cas. Bien qu’il ait enregistré ses premiers cas, il y a peu, le Lesotho n’est pas le pays le moins touché par la maladie à Coronavirus. Cette palme revient à la Tanzanie qui enregistre 509 cas dont 181 guéris. La Tanzanie devient alors le pays où vous pouvez vous rendre sans trop craindre d’être contaminé, mais cela si vous respectez bien entendu les mesures barrières. </p>
<p>Le pays a en plus un grand potentiel touristique à offrir. Vous serez surpris de la présence des animaux et en particulier de la girafe, emblème de ce pays. Ces animaux semblent être réellement chez eux, ce sont les rois. Il faut savoir être attentif et patient, pour profiter de spectacles inhabituels. Les hyènes, les éléphants, les guépards et les lions partagent ces territoires.<br />
Au plan culturel, la Tanzanie compte plus de 120 tribus vivant en parfaite harmonie sur l’ensemble du territoire. La tribu Bantou représente 90 % de la population. Les Massaïs sont les plus connus. En côtoyant ces différents groupes ethniques, on découvre toute la diversité des traditions ancestrales. Une raison de plus pour vous rendre en Tanzanie ! Ce contact permet aussi de mieux appréhender leur manière de vivre et les légendes qui sont très vivaces. Ce pays est incontestablement une terre d’accueil et il séduit par la simplicité et les échanges qui se font facilement avec sa population. On ne peut rester insensible au charme qui transpire de ces terres.</p>

<h3>MAURICE</h3>
<img src={PLAGE} alt="Insérer une photo" className="cover rounded" />
<p>Le deuxième pays le moins touché par le Coronavirus en Afrique est l’île Maurice, il compte 619 cas dont 588 guérisons. L’île Maurice qui pensait être venue à bout de la maladie en Mai 2020 l’a vu repartir de plus belle avec une nouvelle vague de contamination qui à toutefois été maitrisée et le pays figure parmi les territoires les plus surs du continent. Et bien entendu, cela n’est pas la seule raison de se rendre à l’ile Maurice. Le pays est surtout connu pour ses plages de sable blanc, ses décors de montagne volcanique entourée par une mer turquoise avec plusieurs nuances de bleu qui s’étend vers l’infinie dans l’horizon. Une île magnifique qu’on peut explorer seul ou accompagner. </p>

<p>L’ile est pourvue d’une architecture riche et variée qui raconte son histoire et l’évolution de cette population hétéroclite vers une culture multiraciale venue des trois grands continents. Vous y trouverez des temples indiens introduits par les migrants indous, des maisons coloniales, des grandes plantations sucrières et des plantations de thé vestige du colonialisme. Des jardins botaniques, une faune et une flore préservée abritant de nombreuses espèces d’animaux exotiques rares qui vivent dans une végétation abondante.
Vous aurez accès à une myriade d’activités, vous aurez l’opportunité de côtoyer des félins grâce à la marche avec les lions, sauter en parachute ou réaliser un rêve d’enfant qui est de nager avec les dauphins sur la côte ouest de l’ile Maurice. Les Mauriciens sont réputés pour leur enthousiasme, leur hospitalité et leur joie de vivre qui est souvent contagieuse.</p>

<h3>SÃO TOME-ET-PRINCIPE</h3>
<img src={SAOTOME} alt="Insérer une photo" className="cover rounded" />
<p>Cap sur São Tomé-et-Principe qui sont le troisième pays le moins touché par la crise avec 1 840 cas. Visiter Sao Tome et Principe, c'est être plongé directement dans le cercle magique de l'archipel chocolat. Composée de l'île de Sao Tome et de celle de Principe, cette ancienne colonie portugaise, située en face du Gabon dans le golfe de Guinée, est célèbre mondialement pour sa production de cacao et pour l'excellence de ses chocolats. Les superbes plages dorées, l'accueil chaleureux de la population et la faune exceptionnelle de tortues et d'oiseaux de l'île sont également quelques-uns des atouts majeurs de ce joyau de l'Atlantique sud.</p>

<h3>SEYCHELLES</h3>
<img src={SEYCHELLES} alt="Insérer une photo" className="cover rounded" />
<p>Terminons en beauté avec les Seychelles, eh oui comme vous pouvez le constater les territoires les moins touchés du continent sont en majorité des iles. Les îles Seychelles, 4ᵉ pays le moins touché par le coronavirus (2890 cas dont 2532 guéris) ont tout du lieu idéal pour passer des vacances. Cette République, où le Français est l’une des trois langues principales, comprend 115 îles, dispersées sur une vaste zone de l’océan Indien. Et si les voyageurs sont souvent obligés de se cantonner à visiter les principales comme Mahé, Praslin ou La Digue, vous pourrez pourquoi pas y découvrir d’autres merveilles. En parlant de merveilles il y à surtout de magnifiques produits d’artisanat que vous pourrez ramener des Seychelles, trouvés dans les magasins du village d’Anse-aux-Pins ou du marché de Victoria. Coraux et coquillages se retrouvent transformés en bijoux ; la fibre de coco et le raphia tressé servent à fabriquer des chapeaux, des paniers, des nattes et des sacs. Et si malheuresement vous ne pouvez pas vous y rendre vous-même vous pourrez toujours vous faire parvenir ses articles par un voyageur trouvé sur <a href="https://www.monkolis.com" class="link">www.monkolis.com</a>. </p> 

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}
export default Covid;