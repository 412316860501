import './../../../assets/css/dashboard.css';

import './../../../assets/css/googleapi.css';
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { NEW_TRAVEL_QUERY } from './../../../query/travelquery'
import { GET_VERIFICATIONS_INFOS_QUERY } from '../../../query/queryprofil';
import { TRAVEL_URL, TRAVEL_ACTIONS_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import Moment from 'moment';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import PlacesAutocomplete from 'react-places-autocomplete';
import {
	geocodeByAddress,
	geocodeByPlaceId,
	getLatLng,
} from 'react-places-autocomplete';
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import { FacebookShareButton, FacebookIcon } from "react-share";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';

class NewTrip extends Component {
	constructor(props) {
		super(props);
		this.duplicate_data = ''
		this.type = ''
		if (this.props.location.state === null || this.props.location.state === undefined || this.props.location.state === 'null' || this.props.location.state === 'undefined') {
			const { location, history } = this.props;
			history.replace()
		} else {
			this.duplicate_data = this.props.location.state.duplicate_data
			this.type = this.props.location.state.type
		}
		if (this.type === 'duplicate') {
			this.state = {
				error: [],
				showTravelBack: false,
				isLoaded: false,
				travels: [],
				file: new FileReader(),
				doc: '',
				showVolume: false,
				showVolumeBack: false,
				show: false,
				id_travel: '',
				id_card_verified: '',
				phone_number_verified: '',
				email_verified: '',

				//departure
				transport_way: this.duplicate_data.transportation_type,
				departure_date: "",
				arrival_date: "",
				departure_city: this.duplicate_data.departure_city,
				arrival_city: this.duplicate_data.arrival_city,
				has_check_ticket: "",
				unity: this.duplicate_data.measure_unit,
				volume: this.duplicate_data.volume,
				max_weight_available: this.duplicate_data.max_weight_available,
				min_weight_booking: this.duplicate_data.min_weight_booking,
				proposal_amount_by_kg: this.duplicate_data.proposal_amount_by_kg,
				booking_deadline: "",
				hour_deadline: "",
				service_charge_amount: this.duplicate_data.service_charge_amount,
				is_automatic_approval: true,
				transport_company: this.duplicate_data.transport_company,
				others_infos: this.duplicate_data.others_infos,
				take_off_place: this.duplicate_data.take_off_place,
				recovery_place: this.duplicate_data.recovery_place,
				status: "",
				devise: "EURO",
				prix_choisi: this.duplicate_data.service_charge_amount,
				focused: false,
				disable_reservation: false,

				//back
				transport_way_back: "Avion",
				departure_date_back: "",
				arrival_date_back: "",
				has_check_ticket_back: "",
				unity_back: "Kg",
				volume_back: "",
				max_weight_available_back: "",
				min_weight_booking_back: "1",
				proposal_amount_by_kg_back: "",
				booking_deadline_back: "",
				hour_deadline_back: "",
				service_charge_amount_back: "",
				is_automatic_approval_back: true,
				transport_company_back: "",
				others_infos_back: "",
				take_off_place_back: "",
				recovery_place_back: "",
				status_back: "",
				devise_back: "EURO",
				prix_choisi_back: "",
				transportation_condition: "Voyage Personnel",
				transportation_condition_back: "Voyage Personnel",

				hideTravelBackInfo: false,
				lat_arrival: "",
				long_arrival: "",
				lat_departure: "",
				long_departure: "",

			};
		} else {
			this.state = {
				error: [],
				showTravelBack: false,
				isLoaded: false,
				travels: [],
				file: new FileReader(),
				doc: '',
				showVolume: false,
				showVolumeBack: false,
				show: false,
				id_travel: '',
				id_card_verified: '',
				phone_number_verified: '',
				email_verified: '',

				//departure
				transport_way: "Avion",
				departure_date: "",
				arrival_date: "",
				departure_city: "",
				arrival_city: "",
				has_check_ticket: "",
				unity: "Kg",
				volume: "",
				max_weight_available: "",
				min_weight_booking: "1",
				proposal_amount_by_kg: "",
				booking_deadline: "",
				hour_deadline: "",
				service_charge_amount: "",
				is_automatic_approval: true,
				transport_company: "",
				others_infos: "",
				take_off_place: "",
				recovery_place: "",
				status: "",

				//back
				transport_way_back: "Avion",
				departure_date_back: "",
				arrival_date_back: "",
				has_check_ticket_back: "",
				unity_back: "Kg",
				volume_back: "",
				max_weight_available_back: "",
				min_weight_booking_back: "1",
				proposal_amount_by_kg_back: "",
				booking_deadline_back: "",
				hour_deadline_back: "",
				service_charge_amount_back: "",
				is_automatic_approval_back: true,
				transport_company_back: "",
				others_infos_back: "",
				take_off_place_back: "",
				recovery_place_back: "",
				status_back: "",
				devise: "EURO",
				prix_choisi: "",
				devise_back: "EURO",
				prix_choisi_back: "",

				transportation_condition: "Voyage personnel",
				transportation_condition_back: "Voyage personnel",


				focused: false,
				disable_reservation: false,

				hideTravelBackInfo: false,
				lat_arrival: "",
				long_arrival: "",
				lat_departure: "",
				long_departure: "",

			};
		}


		//rendering travel back
		this.handletravelBack = this.handletravelBack.bind(this)
		this.renderTravelBack = this.renderTravelBack.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleClose = this.handleClose.bind(this);

		//one-way trip
		this.handle_departure_date_change = this.handle_departure_date_change.bind(this)
		this.handle_departure_city_change = this.handle_departure_city_change.bind(this)
		this.handle_arrival_city_change = this.handle_arrival_city_change.bind(this)
		this.handle_has_check_ticket_change = this.handle_has_check_ticket_change.bind(this)
		this.handle_max_weight_available_change = this.handle_max_weight_available_change.bind(this)
		this.handle_min_weight_booking_change = this.handle_min_weight_booking_change.bind(this)
		this.handle_booking_deadline_change = this.handle_booking_deadline_change.bind(this)
		this.handle_service_charge_amount_change = this.handle_service_charge_amount_change.bind(this)
		this.handle_is_automatic_approval_change = this.handle_is_automatic_approval_change.bind(this)
		this.handle_transport_company_change = this.handle_transport_company_change.bind(this)
		this.handle_others_infos_change = this.handle_others_infos_change.bind(this)
		this.handle_take_off_place_change = this.handle_take_off_place_change.bind(this)
		this.handle_recovery_place_change = this.handle_recovery_place_change.bind(this)
		this.handle_status_change = this.handle_status_change.bind(this)
		this.handle_transport_way = this.handle_transport_way.bind(this)
		this.handle_arrival_date = this.handle_arrival_date.bind(this)
		this.handle_unity = this.handle_unity.bind(this)
		this.handle_volume = this.handle_volume.bind(this)
		this.handle_hour_deadline_change = this.handle_hour_deadline_change.bind(this)

		//travel back
		this.handle_departure_date_back_change = this.handle_departure_date_back_change.bind(this)
		this.handle_has_check_ticket_back_change = this.handle_has_check_ticket_back_change.bind(this)
		this.handle_max_weight_available_back_change = this.handle_max_weight_available_back_change.bind(this)
		this.handle_min_weight_booking_back_change = this.handle_min_weight_booking_back_change.bind(this)
		this.handle_booking_deadline_back_change = this.handle_booking_deadline_back_change.bind(this)
		this.handle_service_charge_amount_back_change = this.handle_service_charge_amount_back_change.bind(this)
		this.handle_is_automatic_approval_back_change = this.handle_is_automatic_approval_back_change.bind(this)
		this.handle_transport_company_back_change = this.handle_transport_company_back_change.bind(this)
		this.handle_others_infos_back_change = this.handle_others_infos_back_change.bind(this)
		this.handle_take_off_place_back_change = this.handle_take_off_place_back_change.bind(this)
		this.handle_recovery_place_back_change = this.handle_recovery_place_back_change.bind(this)
		this.handle_status_back_change = this.handle_status_back_change.bind(this)
		this.handle_transport_way_back_change = this.handle_transport_way_back_change.bind(this)
		this.handle_arrival_date_back_change = this.handle_arrival_date_back_change.bind(this)
		this.handle_unity_back_change = this.handle_unity_back_change.bind(this)
		this.handle_volume_back_change = this.handle_volume_back_change.bind(this)
		this.handle_hour_deadline_back_change = this.handle_hour_deadline_back_change.bind(this)


		this.handle_devise = this.handle_devise.bind(this)
		this.handle_prix_choisi = this.handle_prix_choisi.bind(this)
		this.handle_devise_back = this.handle_devise_back.bind(this)
		this.handle_prix_choisi_back = this.handle_prix_choisi_back.bind(this)

		this.onDateChange = this.onDateChange.bind(this);
		this.onFocusChange = this.onFocusChange.bind(this);

		this.handle_transportation_condition_change = this.handle_transportation_condition_change.bind(this);
		this.handle_transportation_condition_back_change = this.handle_transportation_condition_back_change.bind(this);

	}

	handleClose() {
		this.setState({ show: false });
		this.props.history.push({
			state: { pages: 'in_progress' }
		});
		window.location.reload(false);
	}

	// handleLaterShare = (event) =>{ 
	// 	event.preventDefault();
	// 	this.setState({ show: false });
	// 	this.props.history.push({
	// 		state: { pages: 'in_progress' }
	// 	});
	// 	window.location.reload(false);
	// }


	componentWillMount() {
		window.scrollTo(0, 0);

		this.idToken = getCookieConsentValue('idToken');
		//this.getDashboardInfos(TRAVEL_ACTIONS_URL, this.idToken);
	}

	getDashboardInfos = async (url, idToken) => {
		try {
			const response = await axios.post(url,
				{ "query": GET_VERIFICATIONS_INFOS_QUERY },
				{
					'headers':
					{
						'Authorization': idToken
					}
				}
			);

			//console.log(response.data)
			// Log the response so we can look at it in the console
			// Set the data to the state

			if (response.data.data.getUserDashboard.status === 200) {
				this.setState(() => ({
					id_card_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.id_card_verified,
					phone_number_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.phone_number_verified,
					email_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.email_verified,
				}));
				if (this.state.phone_number_verified === 'false' || this.state.phone_number_verified === false || this.state.phone_number_verified === '' || this.state.phone_number_verified === null || this.state.phone_number_verified === 'null' || this.state.phone_number_verified === 'undefined' || this.state.phone_number_verified === undefined) {
					alert('Merci d\'avoir enregistré votre numéro de téléphone. \nVeuillez le vérifier pour proposer un trajet svp.')
					this.props.history.push({
						pathname: '/dashboard/myprofile',
						state: { pages: 'verfications' }
					});
				}
			}
		} catch (error) {
			// If there's an error, set the error to the state
			this.setState({ error: error })
			console.log(this.state.error)
		}
	}

	////////handle file selector//////////

	handleFileSelect = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			this.setState({
				file: file,
				doc: reader.result
			});
		}
		reader.readAsDataURL(file)
	}
	/////////////////////////////////////


	handletravelBack() {
		this.setState({ showTravelBack: !this.state.showTravelBack });
	}

	//one-way trip handle function
	handle_departure_date_change(event) {
		this.setState({ departure_date: event });
		/* this.setState({ departure_date: event.target.value }); */
	}

	handle_transportation_condition_change(event) {
		this.setState({
			transportation_condition: event.target.value
		});

		if (event.target.value === "Fret") {
			this.setState({
				hideTravelBackInfo: true
			});
		} else {
			this.setState({
				hideTravelBackInfo: false
			});
		}
	}


	handle_transportation_condition_back_change(event) {
		this.setState({ transportation_condition_back: event.target.value });
	}



	handle_hour_deadline_change(event) { this.setState({ hour_deadline: event.target.value }); }

	// Debut Ajout Ville by GooGle Place

	handle_departure_city_change = departure_city => {
		this.setState({ departure_city });
	};


	handle_arrival_city_change = arrival_city => {
		this.setState({ arrival_city });

	};


	handleSelectDepartureCity = (departure_city, placeId, suggestion) => {
		this.setState({ departure_city: suggestion.description });
		//this.getLatLngDeparture(suggestion.description);
	}

	getLatLngDeparture = async (city) => {
		await geocodeByAddress(city)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				this.setState({
					lat_departure: latLng.lat,
					long_departure: latLng.lng
				})
			})
			.catch(error => console.error('Error', error));

	}

	handleSelectArrivalCity = (handlearrivalCity, placeId, suggestion) => {
		this.setState({ arrival_city: suggestion.description });
		//this.getLatLngArrival(suggestion.description);
	}

	getLatLngArrival = async (city) =>{
		
		await geocodeByAddress(city)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				this.setState({
					lat_arrival: latLng.lat,
					long_arrival: latLng.lng
				})
			})

	}

	// Fin Ajout Ville by GooGle Place

	/* handle_departure_city_change(event) { this.setState({ departure_city: event.target.value }); }
	handle_arrival_city_change(event) { this.setState({ arrival_city: event.target.value }); } */


	handle_has_check_ticket_change(event) { this.setState({ has_check_ticket: event.target.value }); }
	handle_max_weight_available_change(event) { this.setState({ max_weight_available: event.target.value }); }
	handle_min_weight_booking_change(event) { this.setState({ min_weight_booking: event.target.value }); }
	handle_booking_deadline_change(event) {
		this.setState({ booking_deadline: event });
		/* this.setState({ booking_deadline: event.target.value }); */
	}
	handle_service_charge_amount_change(event) {

		/* if (parseFloat(parseFloat(event.target.value) * 0.25) > 1) {
			this.setState({
				service_charge_amount: event.target.value,
				proposal_amount_by_kg: parseFloat(parseFloat(event.target.value) + (parseFloat(event.target.value) * 0.25)).toFixed(2)
			});
		} else {
			this.setState({
				service_charge_amount: event.target.value,
				proposal_amount_by_kg: parseFloat(parseFloat(event.target.value) + (parseFloat(1))).toFixed(2)
			})
		} */
	}
	handle_is_automatic_approval_change() { this.setState({ is_automatic_approval: !this.state.is_automatic_approval }); }
	handle_transport_company_change(event) { this.setState({ transport_company: event.target.value }); }
	handle_others_infos_change(event) { this.setState({ others_infos: event.target.value }); }
	handle_take_off_place_change(event) { this.setState({ take_off_place: event.target.value }); }
	handle_recovery_place_change(event) { this.setState({ recovery_place: event.target.value }); }
	handle_status_change(event) { this.setState({ status: event.target.value }); }
	handle_transport_way(event) { this.setState({ transport_way: event.target.value }); }
	handle_arrival_date(event) {
		this.setState({ arrival_date: event });
		/* this.setState({ arrival_date: event.target.value });  */
	}
	handle_unity(event) {
		this.setState({
			unity: event.target.value
		});
		if (event.target.value === 'Barrique' || event.target.value === 'Carton') {
			this.setState({
				showVolume: true
			});
		} else {
			this.setState({
				showVolume: false
			});
		}
	}
	handle_volume(event) { this.setState({ volume: event.target.value }); }

	//travel back handle function
	handle_departure_date_back_change(event) {
		this.setState({ departure_date_back: event });
		/* this.setState({ departure_date_back: event.target.value }); */
	}
	handle_hour_deadline_back_change(event) { this.setState({ hour_deadline_back: event.target.value }); }
	handle_has_check_ticket_back_change(event) { this.setState({ has_check_ticket_back: event.target.value }); }
	handle_max_weight_available_back_change(event) { this.setState({ max_weight_available_back: event.target.value }); }
	handle_min_weight_booking_back_change(event) { this.setState({ min_weight_booking_back: event.target.value }); }
	handle_booking_deadline_back_change(event) {
		this.setState({ booking_deadline_back: event });
		/* this.setState({ booking_deadline_back: event.target.value }); */
	}
	handle_service_charge_amount_back_change(event) {
		/* if (parseFloat(parseFloat(event.target.value.replace(',', '.')) * 0.25) > 1) {
			this.setState({
				service_charge_amount_back: event.target.value,
				proposal_amount_by_kg_back: parseFloat(parseFloat(event.target.value.replace(',', '.')) + (parseFloat(event.target.value.replace(',', '.')) * 0.25)).toFixed(2)
			});
		} else {
			this.setState({
				service_charge_amount_back: event.target.value,
				proposal_amount_by_kg_back: parseFloat(parseFloat(event.target.value.replace(',', '.')) + (parseFloat(1))).toFixed(2)
			})
		} */
	}
	handle_is_automatic_approval_back_change() { this.setState({ is_automatic_approval_back: !this.state.is_automatic_approval_back }); }
	handle_transport_company_back_change(event) { this.setState({ transport_company_back: event.target.value }); }
	handle_others_infos_back_change(event) { this.setState({ others_infos_back: event.target.value }); }
	handle_take_off_place_back_change(event) { this.setState({ take_off_place_back: event.target.value }); }
	handle_recovery_place_back_change(event) { this.setState({ recovery_place_back: event.target.value }); }
	handle_status_back_change(event) { this.setState({ status_back: event.target.value }); }
	handle_transport_way_back_change(event) { this.setState({ transport_way_back: event.target.value }); }
	handle_arrival_date_back_change(event) {
		this.setState({ arrival_date_back: event });
		/* this.setState({ arrival_date_back: event.target.value });  */
	}

	handle_devise(event) {
		this.setState({
			devise: event.target.value,
			service_charge_amount: '',
			prix_choisi: '',
		});
	}


	handle_devise_back(event) {
		this.setState({
			devise_back: event.target.value,
			service_charge_amount_back: '',
			prix_choisi_back: '',
		});
	}

	handle_prix_choisi(event) {
		//{this.state.devise==="FCFA" ? this.state.service_charge_amount = parseFloat(this.state.prix_choisi/655.95).toFixed(2).toString() : this.state.service_charge_amount =this.state.prix_choisi}
		this.setState({ prix_choisi: event.target.value });
		if (this.state.devise === "FCFA") {
			this.setState({ service_charge_amount: parseFloat(event.target.value / 655.95).toFixed(2).toString() });
			if (parseFloat(parseFloat(event.target.value) / 655.95).toFixed(2) * 0.25 > 1) {
				this.setState({
					proposal_amount_by_kg: parseFloat(parseFloat(parseFloat(event.target.value) / 655.95) + parseFloat(parseFloat(parseFloat(event.target.value) / 655.95).toFixed(2) * 0.25)).toFixed(2)
				});

			} else {
				this.setState({
					proposal_amount_by_kg: parseFloat(parseFloat(event.target.value / 655.95).toFixed(2) + (parseFloat(1))).toFixed(2)
				})
			}

		}
		else {
			this.setState({ service_charge_amount: event.target.value });
			if (parseFloat(parseFloat(event.target.value) * 0.25) > 1) {
				this.setState({
					proposal_amount_by_kg: parseFloat(parseFloat(event.target.value) + (parseFloat(event.target.value) * 0.25)).toFixed(2)
				});
			} else {
				this.setState({
					proposal_amount_by_kg: parseFloat(parseFloat(event.target.value) + (parseFloat(1))).toFixed(2)
				})
			}
		}

	}

	handle_prix_choisi_back(event) {
		//{this.state.devise==="FCFA" ? this.state.service_charge_amount = parseFloat(this.state.prix_choisi/655.95).toFixed(2).toString() : this.state.service_charge_amount =this.state.prix_choisi}
		this.setState({ prix_choisi_back: event.target.value });
		if (this.state.devise_back === "FCFA") {
			this.setState({ service_charge_amount_back: parseFloat(event.target.value / 655.95).toFixed(2).toString() });
			if (parseFloat(parseFloat(event.target.value / 655.95).toFixed(2) * 0.25) > 1) {
				this.setState({
					proposal_amount_by_kg_back: parseFloat(parseFloat(parseFloat(event.target.value) / 655.95) + parseFloat(parseFloat(parseFloat(event.target.value) / 655.95).toFixed(2) * 0.25)).toFixed(2)
				});
			} else {
				this.setState({
					proposal_amount_by_kg_back: parseFloat(parseFloat(event.target.value / 655.95).toFixed(2) + (parseFloat(1))).toFixed(2)
				})
			}

		}
		else {
			this.setState({ service_charge_amount_back: event.target.value });
			if (parseFloat(parseFloat(event.target.value) * 0.25) > 1) {
				this.setState({
					proposal_amount_by_kg_back: parseFloat(parseFloat(event.target.value) + (parseFloat(event.target.value) * 0.25)).toFixed(2)
				});
			} else {
				this.setState({
					proposal_amount_by_kg_back: parseFloat(parseFloat(event.target.value) + (parseFloat(1))).toFixed(2)
				})
			}
		}
	}
	handle_unity_back_change(event) {
		this.setState({ unity_back: event.target.value });
		if (event.target.value === 'Barrique' || event.target.value === 'Carton') {
			this.setState({
				showVolumeBack: true
			});
		} else {
			this.setState({
				showVolumeBack: false
			});
		}
	}
	handle_volume_back_change(event) { this.setState({ volume_back: event.target.value }); }

	onDateChange(event) {
		this.setState({ dateTest: event });


	}

	onFocusChange({ focused }) {
		this.setState({ focused });
	}


	handleSubmit = async (event) => {
		event.preventDefault();
		await this.getLatLngArrival(this.state.arrival_city);
		await this.getLatLngDeparture(this.state.departure_city);
		if (this.state.doc !== '') {
			var datas = this.state.doc.split(';')
			var encodingfile = datas[1].split(',')
			var extension = datas[0].split('/')
		}
		if (encodingfile != undefined && encodingfile[1].length > 3145728) {
			alert('Veuillez sélectionner un document de moins de 3 Mb svp')
		} else {
			this.setState({
				disable_reservation: !this.state.disable_reservation
			});
			var VAR = JSON.stringify({
				"travel_proposal": {
					"departure_date": moment(this.state.departure_date).format("YYYY-MM-DD") + "T23:59",
					"departure_city": this.state.departure_city,
					"arrival_city": this.state.arrival_city,
					"has_check_ticket": this.state.doc !== '' ? "E" : "N",
					"max_weight_available": this.state.max_weight_available,
					"min_weight_booking": this.state.min_weight_booking,
					"proposal_amount_by_kg": this.state.proposal_amount_by_kg.toString(),
					"booking_deadline": this.state.booking_deadline === "" ? this.state.departure_date : this.state.booking_deadline  /* + 'T' + this.state.hour_deadline */,
					"service_charge_amount": this.state.service_charge_amount,
					"is_automatic_approval": this.state.is_automatic_approval ? "Y" : "N",
					"transport_company": this.state.transport_company,
					"others_infos": this.state.others_infos,
					"take_off_place": this.state.take_off_place,
					"recovery_place": this.state.recovery_place,
					"status": "1",
					"measure_unit": this.state.unity,
					"volume": this.state.volume === '' ? "0" : this.state.volume,
					"arrival_date": this.state.arrival_date,
					"transportation_type": this.state.transport_way,
					"transportation_condition": this.state.transportation_condition,
					"lat_arrival_city": this.state.lat_arrival.toString(),
					"lng_arrival_city": this.state.long_arrival.toString(),
					"lat_departure_city": this.state.lat_departure.toString(),
					"lng_departure_city": this.state.long_departure.toString(),
					"travel_type_info": {
						"type_info": this.state.showTravelBack ? "round-trip" : "one-way",
						"specific_info": "depart"
					},
					"ticket_infos": {
						"encoding": encodingfile != undefined ? encodingfile[1] : null,
						"extension": extension != undefined ? extension[1] : null
					}
				}
			})
			this.addNewTravel(NEW_TRAVEL_QUERY, VAR)
		}
	}

	sleep = (milliseconds) => {
		return new Promise(resolve => setTimeout(resolve, milliseconds))
	}

	addNewTravel = async (query, variables) => {
		try {
			const response = await axios.post(TRAVEL_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken
					}
				}
			);
			// Set the data to the state

			if (response.data.data.createTravelProposal.status === 200) {
				this.setState({
					isLoaded: true,
					travels: response.data.data,
					id_travel: response.data.data.createTravelProposal.travel_proposal.id_travel,
				});



				if (this.state.showTravelBack) {
					var VARBACK = JSON.stringify({
						"travel_proposal": {
							"departure_date": moment(this.state.departure_date_back).format("YYYY-MM-DD") + "T23:59",
							"departure_city": this.state.arrival_city,
							"arrival_city": this.state.departure_city,
							"has_check_ticket": this.state.doc !== '' ? "Y" : "N",
							"max_weight_available": this.state.max_weight_available_back,
							"min_weight_booking": this.state.min_weight_booking_back,
							"proposal_amount_by_kg": this.state.proposal_amount_by_kg_back.toString(),
							"booking_deadline": this.state.booking_deadline_back === "" ? this.state.departure_date_back : this.state.booking_deadline_back /* + 'T' + this.state.hour_deadline_back */,
							"service_charge_amount": this.state.service_charge_amount_back,
							"is_automatic_approval": this.state.is_automatic_approval_back ? "Y" : "N",
							"transport_company": this.state.transport_company_back,
							"others_infos": this.state.others_infos_back,
							"take_off_place": this.state.take_off_place_back,
							"recovery_place": this.state.recovery_place_back,
							"status": "1",
							"measure_unit": this.state.unity_back,
							"volume": this.state.volume_back === '' ? "0" : this.state.volume_back,
							"arrival_date": this.state.arrival_date_back,
							"transportation_type": this.state.transport_way_back,
							"transportation_condition": this.state.transportation_condition_back,
							"lat_arrival_city": this.state.lat_departure.toString(),
							"lng_arrival_city": this.state.long_departure.toString(),
							"lat_departure_city": this.state.lat_arrival.toString(),
							"lng_departure_city": this.state.long_arrival.toString(),
							"travel_type_info": {
								"type_info": "round-trip",
								"specific_info": "return",
								"travel_id_depart": response.data.data.createTravelProposal.travel_proposal.id_travel
							}
						}
					})
					this.addNewTravelBack(NEW_TRAVEL_QUERY, VARBACK)
				}
				this.setState({
					show: true
				});
				await this.sleep(60000);
				this.props.history.push({
					state: { pages: 'in_progress' }
				});
				window.location.reload(false);
			} else {
				alert("Veuillez vérifier les informations saisies");
				this.setState({
					disable_reservation: !this.state.disable_reservation
				});
			}
		} catch (error) {
			// If there's an error, set the error to the state
			alert("Une erreur inconue empêche la création de votre trajet");
			this.setState({
				disable_reservation: !this.state.disable_reservation
			});
			console.log(error)
		}
	}

	addNewTravelBack = async (query, variables) => {
		try {
			const response = await axios.post(TRAVEL_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken
					}
				}
			);
			// Set the data to the state
			if (response.data.data.createTravelProposal.status === 200) {
				this.setState({
					isLoaded: true,
					travels: response.data.data
				});
				// alert('Votre voyage à été enregistré')
				// window.location.reload(false);
			}
			else {
				alert('Impossible d\'enregistrer votre trajet ! Veuillez vérifiez vos informations saisies')
			}
		} catch (error) {
			// If there's an error, set the error to the state
			console.log(error)
		}
	}

	renderTravelBack() {
		return (
			<Fragment>
				<hr className="mar-top30 mar-bot30 hrback" />
				<h3 className="heading mar-top30">Informations du trajet retour</h3>
				<h4 className="color-text2 mar-bot0">Détails du trajet retour</h4>
				<hr className="mar-top5" />

				<div className="form-row">
					<div className="form-group col-md-4" data-toggle="tooltip" title="Quel type de conditionnement?">
						<label for="uniTravel">Type de transport </label>
						<select className="form-control" id="uniTravel" value={this.state.transportation_condition_back} onChange={this.handle_transportation_condition_back_change} required="true">
							<option selected>Voyage personnel</option>
							<option>Fret</option>
						</select>
					</div>

					<div className="form-group col-md-4 col-6">
						<label for="moyenTravel">Moyen de transport : </label>
						<select className="form-control" id="moyenTravel" value={this.state.transport_way_back} onChange={this.handle_transport_way_back_change} required="true" >
							<option selected>Avion</option>
							<option>Bâteau</option>
							<option>Voiture</option>
							<option>Train</option>
						</select>
					</div>
					<div className="form-group col-md-4 col-6">
						<label for="travelFirm">Compagnie de transport</label>
						<input type="text" className="form-control" id="travelFirm" placeholder="Compagnie de transport" value={this.state.transport_company_back} onChange={this.handle_transport_company_back_change} required="true" />
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-md-6 col-6">
						<label for="travelDepart">Ville de départ</label>
						<input type="text" className="form-control" id="travelDepart" placeholder="Ville de départ" value={this.state.arrival_city} readOnly required="true" />
					</div>
					<div className="form-group col-md-6">
						<label for="travelArrivee">Ville d'arrivée</label>
						<input type="text" className="form-control" id="travelArrivee" placeholder="Ville d'arrivée" value={this.state.departure_city} readOnly required="true" />
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-md-6 col-6">
						<label for="travelDate">Date de départ</label>
						<div>
							<DatePicker
								selected={this.state.departure_date_back}
								onChange={this.handle_departure_date_back_change}
								dateFormat="dd/MM/yyyy"
								minDate={this.state.arrival_date}
							/>
						</div>
						{/* <input type="date" min={this.state.arrival_date} className="form-control" id="travelDate" placeholder="Date" value={this.state.departure_date_back} onChange={this.handle_departure_date_back_change} required="true" /> */}
					</div>
					<div className="form-group col-md-6 col-6">
						<label for="arriveDate">Date d'arrivée</label>
						<div>
							<DatePicker
								selected={this.state.arrival_date_back}
								onChange={this.handle_arrival_date_back_change}
								dateFormat="dd/MM/yyyy"
								minDate={this.state.departure_date_back}
							/>
						</div>
						{/* <input type="date" min={this.state.departure_date_back} className="form-control" id="arriveDate" placeholder="Date d'arrivée" value={this.state.arrival_date_back} onChange={this.handle_arrival_date_back_change} required="true" /> */}
					</div>
				</div>

				<h4 className="color-text2 mar-bot0 mar-top20">Détails de transport retour</h4>
				<hr className="mar-top5" />

				<div className="form-row">
					<div className="form-group col-md-4" data-toggle="tooltip" title="Quel type de conditionnement?">
						<label for="uniTravel">Unité : </label>
						<select className="form-control" id="uniTravel" value={this.state.unity_back} onChange={this.handle_unity_back_change} required="true">
							<option selected>Kg</option>
							<option>Barrique</option>
							<option>Carton</option>
						</select>
					</div>
					{this.state.showVolumeBack === true ?
						<div className="form-group col-md-4" data-toggle="tooltip" title="Quel est le volume maximum de l'unité">
							<label for="volTravel">Volume (litres)</label>
							<input type="number" min="0,1" className="form-control" id="volTravel" placeholder={"Nombre de " + this.state.unity_back} value={this.state.volume_back} onChange={this.handle_volume_back_change} />
						</div> :
						null
					}
					<div className="form-group col-md-4" data-toggle="tooltip" title="Nombre d'unité disponible">
						<label for="qteTravel">Quantité disponible</label>
						<input type="number" min="1" className="form-control" id="qteTravel" placeholder={"Nombre de " + this.state.unity_back} value={this.state.max_weight_available_back} onChange={this.handle_max_weight_available_back_change} required="true" />
					</div>

				</div>

				<div className="form-row">
					<div className="form-group col-md-4" data-toggle="tooltip" title={"Entrer le nombre minimum de " + this.state.unity_back + " qu'un expéditeur doit réserver"}>
						<label for="travelWeightMin">Minimum à réserver</label>
						<input type="number" step="0.1" min="1" max={this.state.max_weight_available_back} className="form-control" id="travelWeightMin" placeholder={"Nombre de " + this.state.unity_back} value={this.state.min_weight_booking_back} onChange={this.handle_min_weight_booking_back_change} required="true" />
					</div>
					<div className="form-group col-md-4" data-toggle="tooltip" title="Entrer la date limite de réservation. Après cette date, votre voyage n'apparaitra plus dans les recherches">
						<label for="travelDead">Date limite de reservations </label>
						<div>
							<DatePicker
								selected={this.state.booking_deadline_back}
								onChange={this.handle_booking_deadline_back_change}
								dateFormat="dd/MM/yyyy H:mm"
								minDate={new Date()}
								maxDate={this.state.departure_date_back}
								showTimeSelect
								timeFormat="HH:mm"
							/>
						</div>
						{/* <input type="date" className="form-control" min={Moment(new Date()).format('YYYY-MM-DD')} max={this.state.departure_date_back} placeholder="Date" value={this.state.booking_deadline_back} onChange={this.handle_booking_deadline_back_change} required="true" /> */}
					</div>
					{/* <div className="form-group col-md-4" data-toggle="tooltip" title="Entrer la date limite de réservation. Après cette date, votre voyage n'apparaitra plus dans les recherches">
						<label for="travelDead">Heure limite de reservations </label>
						<input type="time" className="form-control" min="00:00" max="24:00" placeholder="Date" value={this.state.hour_deadline_back} onChange={this.handle_hour_deadline_back_change} required="true" />
					</div> */}
				</div>

				<div className="form-row">
					<div className="form-group col-md-6">
						<label for="travelTake">Lieu de dépôt</label>
						<input type="text" className="form-control" id="travelTake" placeholder="Directement à l'aéroport" value={this.state.take_off_place_back} onChange={this.handle_take_off_place_back_change} required="true" />
					</div>
					<div className="form-group col-md-6">
						<label for="travelGive">Lieu de livraison</label>
						<input type="text" className="form-control" id="travelGive" placeholder="Paris, Près de la gare de Lyon" value={this.state.recovery_place_back} onChange={this.handle_recovery_place_back_change} required="true" />
					</div>
				</div>

				<h4 className="color-text2 mar-bot0 mar-top20">Tarification service retour ({"€/" + this.state.unity_back}) </h4>
				<hr className="mar-top5" />

				<div className="form-row">
					<div>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label for="moyenTravel">Devise : </label>
								<select className="form-control" id="moyenTravel" value={this.state.devise_back} onChange={this.handle_devise_back} required="true" >
									<option selected>EURO</option>
									<option>FCFA</option>
								</select>
							</div>

							<div className="form-group col-md-6">
								<label for="travelGain">Prix ({this.state.devise_back === "EURO" ? "€" : this.state.devise_back} /{this.state.unity_back})</label>
								<input type="number" step="0.1" min="1" className="form-control" id="travelGain" placeholder={this.state.devise_back + "/" + this.state.unity_back} value={this.state.prix_choisi_back} onChange={this.handle_prix_choisi_back} required="true" />
							</div>
						</div>
						<div className="form-row">
							<div className="form-group col-md-4">
								<label for="travelGain">Prix en Euro(€)</label>
								<input type="number" step="0.1" min="1" className="form-control" id="travelGain" placeholder={"€/" + this.state.unity_back} value={this.state.service_charge_amount_back} onChange={this.handle_service_charge_amount_back_change} required="true" readOnly />
							</div>

							<div className="form-group col-md-4">
								<label for="travelGain">Frais de réservation</label>
								<input type="number" min="1" className="form-control" id="travelGain" placeholder="1 €/Unité" readOnly value={0/*this.state.service_charge_amount_back * 0.25*/} />
							</div>
							<div className="form-group col-md-4">
								<label for="travelPrice">{"Prix final (€/" + this.state.unity_back + ")"}</label>
								<input type="number" min="1" className="form-control" id="travelPrice" placeholder={"€/" + this.state.unity_back} readOnly value={this.state.service_charge_amount_back/*this.state.proposal_amount_by_kg_back*/}
								/>
							</div>
						</div>

					</div>

				</div>

				<div className="form-row">
					<div className="col-md-6 pad-top25">
						<input id="checkbox7" type="checkbox" className="magic-checkbox" onChange={this.handle_is_automatic_approval_back_change} defaultChecked />
						<label for="checkbox7">J'accepte automatiquement les réservations</label>
					</div>
				</div>


				<h4 className="color-text2 mar-bot0 mar-top20">Autres informations pour le retour</h4>
				<hr className="mar-top5" />

				<div className="form-group">
					<label for="exampleFormControlTextarea1">Laisser un message</label>
					<small id="travelTicket" className="form-text text-muted">
						Ne pas indiquer de <span className="fa fa-1x fa-phone-square"></span> Numéro de téléphone
     	ou	<span className="fa fa-1x fa-facebook-square"></span> de détails de votre Facebook
     </small>
					<textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.others_infos_back} onChange={this.handle_others_infos_back_change} ></textarea>
				</div>
			</Fragment>
		);
	}

	render() {
		let description = this.state.departure_city + "---" + this.state.arrival_city;
		let hashtag = "#monkolis";



		return (
			<Fragment>
				<Helmet>
					<title>Proposer un trajet | MonKolis</title>
					<meta name="robots" content="noindex,follow" />
				</Helmet>

				<div className="mytrips">
					<div className=" section newtravel">
						<div className="section-inner">
							<h3 className="heading">Proposer un nouveau trajet</h3>
							<form onSubmit={this.handleSubmit}>
								<h4 className="color-text2 mar-bot0">Détails du trajet</h4>
								<hr className="mar-top5" />
								<div className="form-row">
									<div className="form-group col-md-4" data-toggle="tooltip" title="Quel type de conditionnement?">
										<label for="uniTravel">Type de transport </label>
										<select className="form-control" id="uniTravel" value={this.state.transportation_condition} onChange={this.handle_transportation_condition_change} required="true">
											<option selected>Voyage personnel</option>
											<option>Fret</option>
										</select>
									</div>
									<div className="form-group col-md-4">
										<label for="moyenTravel">Moyen de transport : </label>
										<select className="form-control" id="moyenTravel" value={this.state.transport_way} onChange={this.handle_transport_way} required="true" >
											<option selected>Avion</option>
											<option>Bâteau</option>
											<option>Voiture</option>
											<option>Train</option>
										</select>
									</div>
									<div className="form-group col-md-4">
										<label for="travelFirm">Compagnie de transport</label>
										<input type="text" className="form-control" id="travelFirm" placeholder="Compagnie de transport" value={this.state.transport_company} onChange={this.handle_transport_company_change} required="true" />
									</div>
								</div>
								<div className="form-row">
									<div className="form-group col-md-6">
										<label for="travelDepart">Ville de départ</label>
										<PlacesAutocomplete
											value={this.state.departure_city}
											onChange={this.handle_departure_city_change}
											onSelect={this.handleSelectDepartureCity}
										>
											{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
												<div>
													<input
														{...getInputProps({
															placeholder: ' Ville de départ ...',
															className: 'location-search-input',
														})}
													/>
													<div className="autocomplete-dropdown-container">
														{loading && <div>Loading...</div>}
														{suggestions.map(suggestion => {
															const className = suggestion.active
																? 'suggestion-item--active'
																: 'suggestion-item';
															// inline style for demonstration purpose
															const style = suggestion.active
																? { backgroundColor: '#fafafa', cursor: 'pointer' }
																: { backgroundColor: '#ffffff', cursor: 'pointer' };
															return (
																<div
																	{...getSuggestionItemProps(suggestion, {

																		style,
																	})}
																>
																	<span>{suggestion.description}</span>
																</div>
															);
														})}
													</div>
												</div>
											)}
										</PlacesAutocomplete>
										{/* <input type="text" className="form-control" id="travelDepart" placeholder="Ville départ" value={this.state.departure_city} onChange={this.handle_departure_city_change} required="true" /> */}
									</div>
									<div className="form-group col-md-6">
										<label for="travelArrivee">Ville d'arrivée</label>
										<PlacesAutocomplete
											value={this.state.arrival_city}
											onChange={this.handle_arrival_city_change}
											onSelect={this.handleSelectArrivalCity}
										>
											{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
												<div>
													<input
														{...getInputProps({
															placeholder: '  Ville d\'arrivée ...',
															className: 'location-search-input',
														})}
													/>
													<div className="autocomplete-dropdown-container">
														{loading && <div>Loading...</div>}
														{suggestions.map(suggestion => {
															const className = suggestion.active
																? 'suggestion-item--active'
																: 'suggestion-item';
															// inline style for demonstration purpose
															const style = suggestion.active
																? { backgroundColor: '#fafafa', cursor: 'pointer' }
																: { backgroundColor: '#ffffff', cursor: 'pointer' };
															return (
																<div
																	{...getSuggestionItemProps(suggestion, {
																		style,
																	})}
																>
																	<span>{suggestion.description}</span>
																</div>
															);
														})}
													</div>
												</div>
											)}
										</PlacesAutocomplete>
										{/* <input type="text" className="form-control" id="travelArrivee" placeholder="Ville arrivée" value={this.state.arrival_city} onChange={this.handle_arrival_city_change} required="true" /> */}
									</div>
								</div>

								<div className="form-row">
									<div className="form-group col-md-6">
										<label for="travelDate">Date de départ</label>
										<div>
											<DatePicker
												selected={this.state.departure_date}
												onChange={this.handle_departure_date_change}
												dateFormat="dd/MM/yyyy"
												minDate={new Date()}
											/>
										</div>
										{/* <input type="date"  min={Moment(new Date()).format('YYYY-MM-DD')} className="form-control" id="travelDate" placeholder="Date" value={this.state.departure_date} onChange={this.handle_departure_date_change} required="true" /> */}
									</div>
									<div className="form-group col-md-6">
										<label for="arriveDate">Date d'arrivée</label>
										<div>
											<DatePicker
												selected={this.state.arrival_date}
												onChange={this.handle_arrival_date}
												dateFormat="dd/MM/yyyy"
												minDate={this.state.departure_date}
											/>
										</div>
										{/* <input type="date" min={this.state.departure_date} className="form-control" id="arriveDate" placeholder="Date d'arrivée" value={this.state.arrival_date} onChange={this.handle_arrival_date} required="true" /> */}
									</div>
								</div>

								{this.state.transportation_condition === 'Fret' ? '' :
									<div className="form-row">
										<div className="form-group col-md-6">
											<label for="travelTicket">Vérification du billet (facultatif)</label>
											<input className="btn fileInput" type="file" onChange={(e) => this.handleFileSelect(e)} accept=".jpg,.jpeg,.png,.pdf" />
											<small id="travelTicket" className="form-text text-muted">Pdf, jpeg, png de 3 Mo maximum</small>
										</div>
									</div>}

								<h4 className="color-text2 mar-bot0 mar-top20">Détails de transport</h4>
								<hr className="mar-top5" />

								<div className="form-row">
									<div className="form-group col-md-4" data-toggle="tooltip" title="Quel type de conditionnement?">
										<label for="uniTravel">Unité : </label>
										<select className="form-control" id="uniTravel" value={this.state.unity} onChange={this.handle_unity} required="true">
											<option selected>Kg</option>
											<option>Barrique</option>
											<option>Carton</option>
										</select>
									</div>
									{this.state.showVolume === true ?
										<div className="form-group col-md-4" data-toggle="tooltip" title="Quel est le volume maximum de l'unité">
											<label for="volTravel">Volume (litres)</label>
											<input type="number" min="0,1" className="form-control" id="volTravel" placeholder={"Volume de " + this.state.unity} value={this.state.volume} onChange={this.handle_volume} />
										</div> :
										null
									}
									<div className="form-group col-md-4" data-toggle="tooltip" title="Nombre d'unité disponible">
										<label for="qteTravel">Quantité disponible</label>
										<input type="number" min="1" className="form-control" id="qteTravel" placeholder={"Nombre de " + this.state.unity} value={this.state.max_weight_available} onChange={this.handle_max_weight_available_change} required="true" />
									</div>

								</div>

								<div className="form-row">
									<div className="form-group col-md-4" data-toggle="tooltip" title={"Entrer le nombre minimum de " + this.state.unity + " qu'un expéditeur doit réserver"}>
										<label for="travelWeightMin">Minimum à réserver</label>
										<input type="number" step="0.1" min="1" max={this.state.max_weight_available} className="form-control" id="travelWeightMin" placeholder={"Nombre de " + this.state.unity} value={this.state.min_weight_booking} onChange={this.handle_min_weight_booking_change} required="true" />
									</div>
									<div className="form-group col-md-4" data-toggle="tooltip" title="Entrer la date limite de réservation. Après cette date, votre voyage n'apparaitra plus dans les recherches">
										<label for="travelDead">Date limite de reservations </label>
										<div>
											<DatePicker
												selected={this.state.booking_deadline}
												onChange={this.handle_booking_deadline_change}
												dateFormat="dd/MM/yyyy H:mm"
												minDate={new Date()}
												maxDate={this.state.departure_date}
												showTimeSelect
												timeFormat="HH:mm"
											/>
										</div>
										{/* <input type="date" className="form-control" min={Moment(new Date()).format('YYYY-MM-DD')} max={this.state.departure_date} placeholder="Date" value={this.state.booking_deadline} onChange={this.handle_booking_deadline_change} required="true" /> */}
									</div>
									{/* <div className="form-group col-md-4" data-toggle="tooltip" title="Entrer la date limite de réservation. Après cette date, votre voyage n'apparaitra plus dans les recherches">
										<label for="travelDead">Heure limite de reservations </label>
										<input type="time" className="form-control" min="00:00" max="24:00" placeholder="Date" value={this.state.hour_deadline} onChange={this.handle_hour_deadline_change} required="true" />
									</div> */}
								</div>

								<div className="form-row">
									<div className="form-group col-md-6">
										<label for="travelTake">Lieu de dépôt</label>
										<input type="text" className="form-control" id="travelTake" placeholder="Paris, Près de la gare de Lyon" value={this.state.take_off_place} onChange={this.handle_take_off_place_change} required="true" />
									</div>
									<div className="form-group col-md-6">
										<label for="travelGive">Lieu de livraison</label>
										<input type="text" className="form-control" id="travelGive" placeholder="Directement à l'aéroport" value={this.state.recovery_place} onChange={this.handle_recovery_place_change} required="true" />
									</div>
								</div>

								<h4 className="color-text2 mar-bot0 mar-top20">Tarification service ({"€/" + this.state.unity})</h4>
								<hr className="mar-top5" />

								<div >
									<div className="form-row">
										<div className="form-group col-md-6">
											<label for="moyenTravel">Devise : </label>
											<select className="form-control" id="moyenTravel" value={this.state.devise} onChange={this.handle_devise} required="true" >
												<option selected>EURO</option>
												<option>FCFA</option>
											</select>
										</div>

										<div className="form-group col-md-6">
											<label for="travelGain">Prix ({this.state.devise === "EURO" ? "€" : this.state.devise} /{this.state.unity})</label>
											<input type="number" step="0.1" min="1" className="form-control" id="travelGain" placeholder={this.state.devise + "/" + this.state.unity} value={this.state.prix_choisi} onChange={this.handle_prix_choisi} required="true" />
										</div>
									</div>

									<div className="form-row">

										<div className="form-group col-md-4">

											<label for="travelGain">Prix en Euro(€) </label>
											<input type="number" step="0.1" min="1" className="form-control" id="travelGain" placeholder={"€/" + this.state.unity} value={this.state.service_charge_amount} onChange={this.handle_service_charge_amount_change} required="true" readOnly />
										</div>

										<div className="form-group col-md-4">
											<label for="travelGain">Frais de réservation</label>
											<input type="number" min="1" className="form-control" id="travelGain" placeholder="1 €/Unité" readOnly value={0/*this.state.service_charge_amount * 0.25*/} />
										</div>
										<div className="form-group col-md-4">
											<label for="travelPrice">{"Prix final (€/" + this.state.unity + ")"}</label>
											<input type="number" min="1" className="form-control" id="travelPrice" placeholder={"€/" + this.state.unity} readOnly value={this.state.service_charge_amount/*this.state.proposal_amount_by_kg*/}
											/>
										</div>
										{/* <div>
											<DatePicker
												selected={this.state.dateTest}
												onChange={this.onDateChange}
												dateFormat="dd/MM/yyyy" 
												minDate={new Date()}
											/>
										</div> */}

									</div>

								</div>


								<div className="form-row">
									<div className="col-md-6 pad-top25">
										<input id="checkbox5" type="checkbox" className="magic-checkbox" onChange={this.handle_is_automatic_approval_change} defaultChecked />
										<label for="checkbox5">J'accepte automatiquement les réservations</label>
									</div>

								</div>





								<h4 className="color-text2 mar-bot0 mar-top20">Autres informations</h4>
								<hr className="mar-top5" />

								<div className="form-group">
									<label for="exampleFormControlTextarea1">Laisser un message</label>
									<small id="travelTicket" className="form-text text-muted">
										Ne pas indiquer de <span className="fa fa-1x fa-phone-square"> Numéro de téléphone </span>
                    ou	    <span className="fa fa-1x fa-facebook-square"> de détails de votre Facebook </span>
									</small>
									<textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.others_infos} onChange={this.handle_others_infos_change}></textarea>
								</div>
								{this.state.hideTravelBackInfo === false ? <div className="mar-bot20 mar-top20" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
									<input id="checkbox6" type="checkbox" className="magic-checkbox" onChange={this.handletravelBack} />
									<label for="checkbox6">Je veux également publier mon trajet retour</label>
								</div> : ""}
								{this.state.showTravelBack ? this.renderTravelBack() : null}
								<button type="submit" className="btn btn-primary" /* value="Publier mon trajet" */ disabled={this.state.disable_reservation}> Publier mon trajet </button>&nbsp;&nbsp;
								{this.state.disable_reservation === true ? <label className="text-danger"><i className="fa fa-1x fa-spinner fa-spin"></i> Votre trajet est en cours de création</label> : ''}
							</form>

						</div>
						<Modal show={this.state.show} onHide={this.handleClose} centered>
							<Modal.Header closeButton>
								<Modal.Title>Trajet Enregistré</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<p>{"Votre voyage du  " + Moment(this.state.departure_date).format('YYYY-MM-DD') + " à destination de " + this.state.departure_city + "//" + this.state.arrival_city + " a bien été enregistré"} </p>
								<p>Vous pouvez le partager sur Facebook en cliquant sur le bouton de partage</p>
							</Modal.Body>

							<Modal.Footer>
								<button className="btn btn-outline-primary" onClick={this.handleClose}>
									Plus tard
                    			</button>
								<FacebookShareButton
									url={'http://www.monkolis.com/traveldetails/' + this.state.id_travel}
									quote={this.state.departure_city + " ------> " + this.state.arrival_city + "\n" + "" + this.state.max_weight_available + " " + this.state.unity + " restant(s), depart prévu le " + Moment(this.state.departure_date).format('DD/MM/YYYY') + "   au prix de " + this.state.service_charge_amount + "€/" + this.state.unity}
									hashtag={hashtag}
									description={description}

								>
									<a type="btn" className="btn btn-facebook btn-block" ><i className="fa fa-facebook-square"></i> Partager sur facebook</a>
								</FacebookShareButton>
							</Modal.Footer>
						</Modal>
					</div>


				</div>
			</Fragment>



		);
	}
}
export default withRouter(NewTrip);
