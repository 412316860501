export const BASE_URL ='https://api.monkolis.com';

export const CONNEXION_URL = 'https://api.monkolis.com/user-mgt';
export const REGISTER_URL =  'https://api.monkolis.com/user-mgt';
export const CONFIRMUSERCODE_URL = 'https://api.monkolis.com/user-mgt';
export const PASSWORDLOST_URL = 'https://api.monkolis.com/user-mgt';
export const NEW_PASSORD_URL = 'https://api.monkolis.com/user-mgt';
export const SEARCHTRAVEL_URL ='https://api.monkolis.com/search-travel-proposal'
export const TRAVEL_URL = 'https://api.monkolis.com/travel-proposal';

export const USER_INFO_URL = 'https://api.monkolis.com/user-auth-mgt';
export const MODIFY_PICTURE_URL ='https://api.monkolis.com/user-auth-upload-mgt';

export const TRAVEL_ACTIONS_URL = 'https://api.monkolis.com/requester-proposal';


export const AVIS_RECU_URL = "https://api.monkolis.com/requester-proposal"

export const MESSAGE_VOYAGE_URL = "https://api.monkolis.com/requester-proposal"
export const MESSAGE_EXPEDITIONS_URL = "https://api.monkolis.com/requester-proposal"


export const GET_CODEVALIDATION_URL = "https://api.monkolis.com/user-auth-mgt"
export const VERIFY_NUMBER_URL = "https://api.monkolis.com/user-auth-mgt"
export const UPLOAD_IDENTITY_URL ="https://api.monkolis.com/user-auth-upload-mgt"
export const CONTRIBUTION_URL = 'https://api.monkolis.com/contribution';
export const OPEN_DATA_URL = 'https://api.monkolis.com/open-data';

export const GET_MONEY_TRANSFERT_HISTORY_URL = "https://api.monkolis.com/requester-proposal"
export const GET_PAYEMENTS_MADE_URL = "https://api.monkolis.com/requester-proposal"

export const GET_AVAILABLE_MONEY_URL ="https://api.monkolis.com/requester-proposal"

export const NOTIFICATION_URL = 'https://api.monkolis.com/user-auth-mgt';

export const REPORT_TRAVEL_QUERY_URL ='https://api.monkolis.com/travel-proposal'

export const GET_TRAVEL_INFO_BY_RS_URL = 'https://api.monkolis.com/search-travel-proposal'

export const CONTACT_MONKOLIS_URL = 'https://api.monkolis.com/open-data'

export const SECURE_COOKIES = true