import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/annex.css';
import './../../../assets/css/modal.css';
import './../../../assets/css/blog.css';

class PressCom extends Component {
  render() {
    return (

      <Fragment>
        <Helmet>
          <title>Blog | Monkolis</title>
          <meta name="robots" content="index,follow" />
        </Helmet>

        <div className="blog">
          <div className="main-box_content">
            <div className="container-fluid container-fluid-br headerwrap box-radius">
              <div className="blog-title">
                <h2 className="title">Blog MonKolis</h2>
                <hr></hr>
                <h5 className="txt-light">Histoires, conseils, secrets, astuces... On vous raconte tout !</h5>
              </div>
            </div>
            <div className="container-fluid container-fluid-pad container-fluid-br">
              <div className="row pad-top20 pad-bot20">
                <div className="col-sm-3">
                  <div className="list-group">
                    <li className="list-group-item bg-color-global txt-bold">Articles</li>
                    <a href="/blog/genesis" className="list-group-item list-group-item-action">La Génèse de MonKolis</a>
                    <a href="/blog/press-communication" className="list-group-item list-group-item-action active">Communiqué de presse de lancement</a>
                    <a href="/blog/travel-to-Senegal" className="list-group-item list-group-item-action "> Visiter le Sénégal</a>
                    <a href="/blog/personnal-shopper" className="list-group-item list-group-item-action"> Personnal Shopping</a>
                    <a href="/blog/covid" className="list-group-item list-group-item-action"> Les 3 destinations idéales à visiter pendant le COVID</a>
                    <a href="/blog/visit-Belgium" className="list-group-item list-group-item-action"> Visiter la Belgique</a>
                    <a href="/blog/monkolis-make-money" className="list-group-item list-group-item-action"> Gagner de l'argent avec Monkolis</a>
                    <a href="/blog/monkolis-mouve-out" className="list-group-item list-group-item-action"> Préparer son déménagement</a>
                    <a href="/blog/expedition" className="list-group-item list-group-item-action" > Expéditions à l'international</a>
                  </div>
                </div>

                <div className="col-sm-9">
                  <div className="section">
                    <div className="section-inner">
                    <h3 class="heading mar-bot0">Lancement de la plateforme www.monkolis.com  </h3>
                    <i class="mar-top0">Paris, le 17 octobre 2020</i>
								    <p  class="mar-top25">

Lancée en octobre 2017 avec un groupe Facebook, MonKolis est une plateforme digitale qui a pour but de mettre en relation aussi bien des particuliers que des professionnels. Le but ? Expédier et transporter des colis à travers le monde à des prix concurrentiels et en toute simplicité.

					    	         </p>

                         <p>D’abord présent sur Facebook avec un groupe qui compte près de 16 000 membres à ce jour, MonKolis franchi un cap de plus dans son développement avec le lancement de son site internet presque 3 ans jour pour jour après sa création. Disponible depuis le 19 octobre 2020, la promesse de cette plateforme web est d’assurer la sécurité, la traçabilité des colis et des transporteurs, et d’augmenter la visibilité de ceux-ci en les rendant accessibles à partir de tous les moteurs de recherche.</p>
<p>Facile d’accès et totalement gratuit, la plateforme web et le groupe Facebook MonKolis ont  pour but principal d’être à l’écoute des utilisateurs et de leur apporter la réponse qui correspond le plus à leur besoin. En quelques clics, il est possible de créer un trajet de transport ou de trouver un transporteur capable de répondre à ses besoins. Grâce à MonKolis, de nombreux voyageurs et transporteurs professionnels gagnent de l’argent en toute quiétude, transportent en toute confiance et expédient en toute assurance.</p>
<p>En 3 ans d’existence, MonKolis c’est plus de 10 000 colis transportés, près de 3 000 trajets effectués et des milliers de rencontres. Pour faire partie de la communauté MonKolis, il suffit de visiter notre plateforme web  sur le lien www.monkolis.com/ </p>

<p> <b>À propos des fondateurs :</b> Piloté par Demel Yedoh, co-fondateur en charge de la stratégie, MonKolis est né de la volonté des fondateurs, tous issus de l’INP-HB et diplômés d’écoles de commerce ou d’ingénieurs françaises, de résoudre une problématique à laquelle ils étaient confrontés en tant que membres de la diaspora : envoyer rapidement et à moindre coût un colis au pays. Jeune et dynamique, cette équipe est fière de proposer cette solution, résultat d’une formidable collaboration et forte de leur expertise développée dans leurs différents domaines d’activité.</p>

<p> <b>Contact : <a href="#" class="link">hello@monkolis.com </a> </b> </p> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}
export default PressCom;