import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Press extends Component {
    render() {
        return (

            <Fragment>
                <Helmet>
                    <title>Presse - Toute l'actualité des colis dans le monde | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="press">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/about" className="list-group-item list-group-item-action">A propos</a>
                                        <a href="/founders"
                                            className="list-group-item list-group-item-action">Cofondateurs</a>
                                        <a href="/details"
                                            className="list-group-item list-group-item-action">Coordonnées</a>
                                        <hr />
                                        <a href="/careers"
                                            className="list-group-item list-group-item-action">Carrière</a>
                                        <a href="/press"
                                            className="list-group-item list-group-item-action active">Presse</a>
                                        <a href="/contact"
                                            className="list-group-item list-group-item-action">Contact</a>
                                    </div>

                                </div>
                                <div className="col-sm-9">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Presse</h1>

                                            <h2 className="f-heading-two color-text2">MonKolis réinvente le transport de
                                                kolis en le confiant aux particuliers.</h2>

                                            <p>L'idée de MonKolis est née d'un double constat : expédier un kolis au
                                            pays peut se révéler extrêmement coûteux pour un particulier, surtout
                                            s'il est lourd ou volumineux, alors que de nombreuses personnes prennent
                                                l'avion avec des valises vides. </p>
                                            <p> Très souvent, les frais d'envoi sont même plus élevés que la valeur du
                                            bien transporté !
                                            MonKolis se veut être la plateforme collaborative dédiée au transport de
                                            kolis entre particuliers sur un mode économique et éco-responsable.
                                            </p>
                                            <p>MonKolis met en relation des transporteurs et des expéditeurs qui veulent
                                            envoyer ou recevoir un kolis du pays. Grâce à notre service, le kolis de
                                            l'expéditeur arrive rapidement et en toute sureté tandis que le
                                            transporteur rentabilise son voyage et fait de belles rencontres. Fondée
                                            en janvier 2019, sa plateforme offre une expérience utilisateur unique
                                                qui lui a permis d'attirer des milliers d'utilisateurs à ce jour.</p>
                                            <p>MonKolis a réuni les ingrédients pour rendre le transport de kolis entre
                                            partculiers facile et sécurisé et ambitionne désormais de devenir la
                                            meilleure alternative aux solutions de transport traditionnelles
                                                présentes sur le marché.</p>

                                            <h4 className="color-text2">Contact presse</h4>
                                            <p>
                                                <span>Contactez-nous par email : <a href="mailto:hello@monkolis.fr"
                                                    className="color-blue link">hello@monkolis.fr</a></span><br />
                                                <span>Communiqué de presse : <a href="#" className="color-blue link">téléchargez-le au format pdf</a></span><br />
                                                <span>Illustrer un article : <a href="#" className="color-blue link">téléchargez les visuels de MonKolis </a></span>
                                            </p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default Press;