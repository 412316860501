import React, { Component } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';
import { withRouter, Redirect } from 'react-router-dom';

class Beginners extends Component {
  render() {
    return(
      <div className="section">
        <div className="section-inner">

        <h1 class="f-heading" id="top">Premiers pas sur MonKolis</h1>

          <a href="#1" class="link color-blue">→ Découvrir MonKolis</a><br />
          <a href="#2" class="link color-blue">→ Inscription et identification</a><br />
          <a href="#3" class="link color-blue">→ Téléphone portable et Vérifications</a><br />
          <a href="#4" class="link color-blue">→ Faire vérifier ma pièce d'identité</a><br />
          <a href="#5" class="link color-blue">→ Découvrir mon Tableau de bord et mon Profil</a><br />
          <a href="#6" class="link color-blue">→ Les avis</a><br />
          <a href="#7" class="link color-blue">→ L'assurance du transport</a><br />
          <a href="#8" class="link color-blue">→ Expédier en toute sécurité</a><br /><br />

          <h2 className="f-heading-two" id="1">MonKolis, c'est quoi ?</h2>

            <p>MonKolis offre un service dans l’esprit de l’économie collaborative dont le but est de faire des économies au quotidien tout en contribuant au développement durable.</p> 

            <p> Le transport de kolis à l'international n'aura jamais été aussi simple : nous mettons en relation des transporteurs (particuliers ou professionnels) et des expéditeurs qui veulent envoyer ou recevoir un colis du pays.</p>

            <p>Grâce à notre service, le colis de l'expéditeur arrive rapidement et en toute sureté tandis que le transporteur rentabilise son voyage et fait de belles rencontres. Tout le monde y gagne : les uns payent moins cher leur envoi et les autres remboursent une partie de leurs frais de transport ou augmentent leurs revenus, tout en rendant service.</p>

          <h5>Comment ça marche ?</h5>  
            <p>C’est très simple !</p>
            <p>Les particuliers proposent leurs valises vides lors de leurs voyages et les expéditeurs intéressés par l'envoi d'un colis sur la même destination peuvent réserver leur espace et n’auront plus qu’à remettre leurs colis aux voyageurs.</p>

            <p>Les transporteurs professionnels ont également la possibilité de mettre leurs trajets sur la plateforme afin que les expéditeurs yfassent des réservations.
              Vous souhaitez en savoir plus ? Découvrez vite <a href="/work" class="link color-blue">Comment ça marche !</a></p>

            <p>Simple, économique et pratique, MonKolis permet aujourd’hui à de nombreuses personnes d’effectuer d’importantes économies sur leurs expéditions de colis au pays, et aux voyageurs et transporteurs professionnels de gagner de l'argent grâce à leurs trajets !</p>

            <p>	L’inscription est rapide, gratuite et immédiate et MonKolis vous permet d'expédier vos colis en toute confiance, grâce à un ensemble de services innovants et pratiques. <br/> Vous n’avez plus qu’à essayer ! <br/> <br/> <a href="/register" class="link color-blue">Je m'inscris sur MonKolis</a></p>

            <a href="#top" class="link color-text2">Remonter</a><br /><br />

          <h4 id="2">Inscription et identification</h4> 
          <h5>Comment créer un compte </h5>
            <p>Créer un compte ne prend que quelques secondes. Pour cela, vous pouvez utiliser Facebook ou votre adresse e-mail, c'est au choix.</p>

            <p>En utilisant Facebook, il suffit d'un clic. Mais si vous préférez utiliser votre e-mail, vous n'aurez qu'à renseigner votre nom et votre année de naissance, puis nous dire si vous êtes une femme ou un homme.</p>

            <p>Rendez-vous sur la page d'accueil, choisissez Inscription et suivez les instructions !
            <br />
            Une fois <a href="/register" class="link"> l’inscription </a> effectuée, nous vous demanderons de compléter votre profil en vérifiant notamment votre numéro de téléphone ou en ajoutant une photo de profil.</p> 

          <h5>L’inscription est-elle gratuite et obligatoire ?</h5>
            <p className="txt-bold">L’inscription sur MonKolis est totalement gratuite !</p>
            <p>Elle est obligatoire si vous souhaitez profiter de la mise en relation entre transporteurs et expéditeurs et faire d’importantes économies sur vos futurs expédiitions.
            <br /> Être membre de MonKolis vous permet de proposer vos volises vides ou vos moyens de transport et d'expédier vos colis au pays à moindre coût !
            <br/><br/><a href="/register" class="link">Je m'inscris sur MonKolis !</a>
            </p>

          <h5>Peut-on réserver ou publier un trajet pour quelqu'un d'autre ?</h5>
            <p className="txt-bold">Chaque personne utilisant MonKolis doit avoir son propre profil !</p>
            <p>Chaque profil créé sur le site doit être fiable, et nous vérifions certaines informations pour des questions de sécurité et de confiance entre les membres. C'est le cas du numéro de téléphone, par exemple.</p>

            <p>Si vous réservez votre expédition en vous fiant à ses avis reçus et que c'est une autre personne que celle que vous attendez qui se présente, la confiance n'est plus de mise et vous serez en droit d'annuler votre expédition.</p>

            <p> Et la situation est valable aussi du côté des expéditeurs : si vous acceptez une réservation après avoir consulté un profil et que c'est une autre personne qui se présente, vous n'êtes plus en confiance et êtes en droit de refuser de transporter le colis de la personne.</p>

            <p>Vous pouvez donc créer votre profil ou le conseiller à vos proches : <a href="/register" className="link">https://www.monkolis.fr/register </a> Cela prend 30 secondes et il suffit d'une adresse email et d'un numéro de téléphone.</p>

          <h6>Astuce pour les expéditeurs :</h6>
            <p> Si vous ou votre proche êtes dans l'incapacité de vous inscrire, vous pouvez indiquer par message après avoir réservé que ce n'est pas vous qui allez expédier, afin d'en informer le transporteur.</p>

          <h5>Je n'arrive pas à m'inscrire</h5>
            <p>Pour vous inscrire, vous devez renseigner tous les champs demandés : nom, prénom, date de naissance, mot de passe, et fournir une adresse email valide.
            <br />L’inscription par Facebook n’est pas obligatoire mais vous permet de vous inscrire plus simplement et rapidement.</p>

            <p>Si votre numéro de téléphone est indiqué comme déjà utilisé, c’est qu’il est attaché à un compte déjà enregistré sur le site. En effet, il n’est pas possible d’utiliser un même numéro  de téléphone  pour plusieurs comptes.</p>

            <p>Si vous avez oublié votre mot de passe, il vous faut suivre la procédure de récupération en cliquant sur "<a href="/password-lost" className="link">Mot de passe oublié</a>", dans la fenêtre d'identification.</p>

            <p>Si un membre est inscrit avec votre numéro de téléphone, ou si un compte existe déjà avec vos identifiants Facebook, ou pour tout autre cas, nous vous invitons à <a href="https://www.facebook.com/MonKolis/" className="link" target="_blank">contacter notre équipe</a> qui vous apportera une réponse.</p>

          <h5>Comment se connecter ?</h5>
            <div>
              <p>Vous n'arrivez pas à vous <a href="/connexion" className="link">connecter</a> à votre compte ? Essayez ce qui suit :</p>
              <ul className="mar-left10">
                <li>Si vous essayez de vous connecter avec Facebook, assurez-vous que vous utilisez le même compte Facebook que celui utilisé le jour de votre inscription.</li>
                <li>Si vous essayez de vous connecter avec votre adresse e-mail, assurez-vous que c'est bien celle que vous aviez utilisée au moment de vous inscrire, et renseignez à nouveau votre mot de passe.</li>
                <li>Vous ne vous souvenez plus de votre mot de passe ? Pas d'inquiétude, choisissez <a href="/password-lost" className="link">Mot de passe oublié </a> et entrez votre adresse e-mail. Nous vous enverrons un code qui vous permettra de créer un nouveau mot de passe.</li>
              </ul>
            </div>
          
            <h5> Que faire si j'oublie mon mot de passe ?</h5>
              <p>Pas d'inquiétude ! Si vous oubliez votre mot de passe, vous pouvez en créer un nouveau. </p>

              <p>Il vous suffit d’indiquer l’adresse e-mail que vous utilisez pour MonKolis, et nous vous enverrons un code qui vous permettra de changer votre mot de passe.</p>

              <p>Vous ne recevez pas notre e-mail ? Vérifiez vos spams, il s’y trouve peut-être !</p>

              <p>Vous avez reçu l'e-mail, mais le code ne fonctionne pas ? Si vous avez fait plusieurs demandes de mises à jour de votre mot de passe, vous avez dû recevoir plusieurs e-mails de notre part. Veillez à utiliser le dernier envoyé. En cas de doute, supprimez tous les e-mails reçus pour mettre à jour votre mot de passe, et demandez-en un nouveau sur le site.</p>

              <p>Attention, le code donné dans l'e-mail n'est valide que 24 heures ! Si ce temps est écoulé, vous devrez renouveller votre demande de nouveau mot de passe.</p>

            <h5>Comment modifier mon mot de passe ?</h5>

              <p>Vous pouvez modifier directement votre mot de passe depuis votre compte <a href="/dashboard/profile/password" className="link">ici</a> (vous devez être identifié au préalable).<p></p>
          
              <p>Vous pouvez aussi accéder à votre Profil (en cliquant sur votre prénom en haut à droite de l’écran), puis cliquer sur “Mot de passe” dans la rubrique “Profil”.</p>

              <p>Pour modifier votre mot de passe, vous devez renseigner votre mot de passe actuel puis le nouveau mot de passe choisi à confirmer avant de valider les modifications.<br/>
              Il est recommandé de choisir un mot de passe dont vous pourrez facilement vous souvenir.</p>

            <h5>Pourquoi ne puis-je pas me connecter après avoir changé mon mot de passe ?</h5>
              <p>Tout d’abord, assurez-vous d'avoir renseigné les bons identifiants. Confondre ses mots de passe d’un site à un autre, cela arrive à tout le monde ! </p>

              <p>Si vous êtes sûr(e) d’utiliser le bon mot de passe, videz le cache et les cookies de votre navigateur, puis réessayez.</p>

              <p>Vous n'arrivez toujours pas à vous connecter ? <a href="https://www.facebook.com/MonKolis/" className="link" target="_blank">Contactez-nous</a>, expliquez-nous ce qui se passe et nous nous ferons un plaisir de vous aider.</p>

              <a href="#top" className="link color-text2">Remonter</a> <br /><br/>

						<h4 id="3">Téléphone portable et Vérifications</h4>
						
            <h5 className="txt-bold">Où et quand mon numéro est-il affiché ?</h5>
              <p>Votre numéro de téléphone est transmis aux transporteurs lorsque vous effectuez une réservation ou aux expéditeurs qui réservent sur votre trajet.
              <br />
              Le numéro est enregistré sur votre compte, et il n'est pas affiché dans tous les autres emplacements du site pour garantir la fiabilité du service dans son ensemble.</p>

            <h5 className="txt-bold">Comment vérifier mon numéro ?</h5>
							<p>Vous êtes invité(e) à renseigner et <a href="/dashboard/profile/verifications" className="link">vérifier votre numéro</a> de téléphone portable, démarche simple et indispensable qui vous permettra de profiter pleinement des services de MonKolis.</p>
              <div>
                <p>Vérifier votre numéro est gratuit et très rapide, il vous suffit de vous laisser guider !</p>
                <ul className="mar-left10">
                  <li>Vous saisissez votre numéro de portable sur la page de vérification</li>
                  <li>Vous recevez instantanément un SMS contenant un code à 4 chiffres</li>
                  <li>Vous saisissez ce code sur la page de vérification, validez… et c’est tout !</li>
                </ul>
              </div>

            <h5>Et si je n’ai qu’un téléphone fixe ?</h5>
              <p>Seuls les numéros de portables sont acceptés sur MonKolis, afin de garantir que les membres soient joignables en toutes circonstances.

              <br />Il est en effet indispensable de disposer d’un téléphone portable lorsque vous prévoyez d’expdier un colis, puisque vous ne serez pas chez vous au moment du rendez-vous.

              <br />Si vous n’avez qu’un numéro de fixe et que l'expéditeur cherche à vous joindre parce qu’il ne vous trouve pas, cela entraînera à coup sûr une réclamation et de l’insatisfaction.</p>

            <h5>Pourquoi la vérification est-elle obligatoire ?</h5>
              <p>Disposer d'un numéro de portable vérifié est nécessaire pour contacter un transporteur, réserver un kg ou une barrique, et pour accepter une demande de réservation en ligne.</p>

              <p>Si vous êtes transporteur, vous ne pourrez pas pas publier de trajet si votre numéro n'est pas vérifié.</p>

              <p/>Il s’agit simplement de garantir aux autres membres que votre numéro est bien valide ! Et de nous assurer que vous serez bien joignable en cas de besoin. C’est une garantie de fiabilité et un gage de confiance pour la communauté en général.</p>

              <p> Pas d’inquiétude, votre numéro de téléphone n’est pas visible sur votre profil public, il est transmis en cas de réservation uniquement.</p>

              <p>Et bien sûr, aucun usage ne sera fait de votre numéro : il ne sera jamais utilisé à des fins commerciales.</p>

            <h5>Je n'arrive pas à faire vérifier mon numéro de téléphone.</h5>
              <div>
                  <p>Vous n'avez pas reçu les 4 chiffres vous permettant de vérifier votre numéro de téléphone ? Il est possible que :</p>
                <ul className="mar-left10">
                  <li>le numéro de téléphone ou l'indicatif pays renseigné ne soit pas le bon (vérifiez le numéro enregistré sur votre Profil)</li>
                  <li>le numéro soit déjà utilisé par un autre compte</li>
                  <li>vous ne puissiez pas recevoir de SMS pour l'instant</li>
                  <li>vous essayiez de faire vérifier un numéro de téléphone fixe et non un numéro de téléphone mobile.</li>
                </ul>
              </div>

              <p> Par ailleurs, sachez qu'il peut parfois s'écouler jusqu'à 20 minutes entre la demande de vérification et la réception du SMS contenant les 4 chiffres. Si vous attendez depuis plus de 15 minutes, contactez-nous. Nous nous chargerons de vérifier votre numéro de téléphone.
              </p>

            <h5>Peut-on vérifier un numéro de portable non français ?</h5>
              <p>Il est tout à fait possible de choisir un indicatif étranger, parmi la liste de choix qui vous est proposée ! <br/>Pour cela, choisissez le pays de l’indicatif de votre téléphone portable. </p>

						<a href="#top" className="link color-text2">Remonter</a> <br /><br />

						<h4 id="4">Faire vérifier ma pièce d'identité</h4>
            <h5>Comment faire vérifier ma pièce d’identité ?</h5>

              <p> Pour vérifier votre pièce d’identité, rendez-vous sur la page <a href="/dashboard/profile/verifications" className="link">Vos vérifications </a> de votre Profil. </p>

              <p> Vous pouvez transmettre une copie de votre Passeport ou votre Carte nationale d’identité (CNI) enregistré sur votre ordinateur ou votre mobile.
              <br />  Il est nécessaire que votre prénom et votre nom indiqués dans votre profil soient identique à ce qui apparait sur votre pièce d’identité.</p>

              <p> Une fois la copie de votre pièce d'identité reçu, nous nous chargeaon de vérifier votre pièce d’identité. Une fois acceptée, un macaron sera ajouté à votre photo de profil et nous indiquerons “Pièce d’identité vérifiée” sur votre Profil public.</p>

            <h5> Quel type de pièce d’identité puis-je faire vérifier ?</h5>

							<p>Vous pouvez faire vérifier votre Carte Nationale d’identité ou votre passeport.</p>

              <p>Pour le moment, les permis de conduire ou les cartes de séjour ne sont pas acceptées. Nous faisons notre possible pour que cela soit bientôt possible.
              <br />
              À noter : seule une photo ou une copie couleur de votre pièce d’identité est acceptée.
              <br />
              Si vous ne parvenez pas à faire vérifier votre pièce d’identité, <a href="https://www.facebook.com/MonKolis/" className="link" target="_blank">contactez-nous</a>.</p>

            <h5>Pourquoi faire vérifier ma pièce d’identité ?</h5>

							<p>La communauté MonKolis repose sur la confiance entre ses membres. Nous leur demandons de nous laisser vérifier plusieurs éléments de leur profil pour favoriser la fiabilité et la confiance au sein de la communauté.</p>

              <p>Nous avons ajouté la vérification de la pièce d’identité pour garantir davantage de sécurité sur les profils MonKolis. En faisant vérifier votre pièce d’identité, vous améliorerez vos chances de trouver des transporteurs et expéditeurs. Tous les autres membres verront que votre pièce d’identité est vérifiée et ils seront naturellement plus enclins à travailler avec vous !</p>

              <div>
                <p>Vous pouvez faire vérifier :</p>
                <ul className="mar-left10">
                  <li>votre Passeport</li>
                  <li>votre Carte nationale d’identité (CNI)</li>
                </ul>
              </div>

              <p>Nous prendrons en charge la vérification et nous ne partagerons avec aucun autre tiers l’image de votre pièce d’identité. Les autres membres n’auront pas accès à ces données : ils ne verront qu’un macaron sur votre photo de profil et la mention Identité vérifiée sur votre profil.
              </p>

							<h5>Est-il risqué d’envoyer ma pièce d’identité ?</h5>
							<p>La vérification de votre pièce d’identité contribue à la confiance inspirée par votre profil et donnera envie aux autres membres de transporter ou expédier avec vous. Vous pouvez envoyer votre carte d’identité (CNI) ou votre passeport.</p>

              <p>Nous conserverons les données de votre pièce d’identité en sécurité. Seul nous y avons accès, nous ne les partagerons avec aucun autre tiers. Nous ne procéderons à aucun contrôle de vos antécédents, de votre vie privée ou de votre vie professionnelle.</p>

              <p>De plus, votre pièce d’identité ne sera pas visible pour les visiteurs de votre profil MonKolis. Les autres membres ne verront que la mention Pièce d’identité vérifiée sur votre profil.</p>

            <h5>Qui aura accès à ma pièce d’identité et comment sera-t-elle utilisée ?</h5>
							<p>Lorsque vous téléchargez votre pièce d'identité pour vérification (votre carte d’identité ou votre passeport), nous vérifierons que l’identité que vous avez renseignée sur votre profil MonKolis correspond bien à celle qui apparaît sur votre pièce d’identité.</p>
              <p>Si c’est le cas, cette dernière sera alors vérifiée.
              <br />
              Les données que comporte votre pièce d’identité seront conservées en sécurité. Seul nous y aurons accès, nous ne les partagerons avec aucun tiers.
              <br />
              De plus, votre pièce d’identité ne sera pas visible pour les autres membres ; ils ne verront que la mention “Pièce d’identité vérifiée” sur votre profil.</p>

						  <a href="#top" className="link color-text2">Remonter</a> <br /><br />

              <h4 id="5">Découvrir mon Tableau de bord et mon Profil</h4>
						
            <h5>Bien renseigner mon profil</h5>
	
            <h6>Mon Tableau de bord, qu’est-ce que c’est ?</h6>
              <p>Depuis votre <a href="/dashboard" className="link">Tableau de bord</a> vous accédez à toutes vos notifications et messages non lus, mais pouvez également modifier votre profil, vos préférences et faire vérifier votre numéro de téléphone, adresse email et pièce d'identité.
              C'est aussi là que vous pouvez accéder aux offres spéciales que nous vous proposons.
              </p>
              <p>Pour vous rendre sur votre Tableau de bord, cliquez sur votre nom et votre photo en haut à droite de votre page, et sélectionnez Tableau de bord sur la liste déroulante.</p>
                    
              <div>
                <p>Depuis cette même liste, vous pouvez également vous rendre sur les pages suivantes :</p>
                <ul>
                  <li><a href="/dashboard/my-trips" className="link">Mes trajets</a></li>
                  <li><a href="/dashboard/my-bookings" className="link">Mes expéditions</a></li>
                  <li><a href="/dashboard/messages/messages-trip" className="link">Messages</a></li>
                  <li><a href="/dashboard/alerts" className="link">Alertes</a></li>
                  <li><a href="/dashboard/profile" className="link">Profil</a></li>
                  <li><a href="/dashboard/money" className="link"> Paiements</a></li>
                </ul>
              </div>

            <h6>Comment choisir ou modifier mes Préférences et mon Profil ?</h6>
              <p>Pour accéder à vos Préférences depuis votre Tableau de bord, vous pouvez cliquer sur “Modifier vos préférences”, situé sous votre portrait, ou en cliquant sur l’onglet <a href="/dashboard/profile" className="link">Profil</a>.</p>
              <p>Cette fonction vous est aussi accessible depuis le menu disponible en cliquant sur votre prénom en haut à droite de votre écran.</p> 

              <a href="/dashboard/profile/preferences" className="link">Modifier vos préférences</a>

              <ul>
                <li>Vous accepter de transporter des produits de beauté ?</li>
                <li>Vous acceptez de transporter des produits alimentaires ?</li>
                <li>Dites-le, en faisant les choix qui vous conviennent !</li>
              </ul>

              <p>C'est depuis votre <a href="/dashboard/profile" className="link">Profil</a> que vous pouvez accéder à tout moment à la mise à jour des informations qui vous concernent :</p>
              <h6> Votre Profil :</h6>
                <ul>
                  <li>Vos informations personnelles (celles que vous aviez choisies lors de votre inscription)</li>
                  <li>Votre photo</li>
                  <li>Vos préférences</li>
                  <li>Vos vérifications (téléphone, email et pièce d'identité)</li>
                  <li>Votre adresse postale</li>
                </ul>

              <h6>Votre compte :</h6>
              <h6>Vos paiements :</h6>
                <ul>
                  <li>La gestion de vos alertes</li>
                  <li>Votre mot de passe</li>
                  <li>La fermeture de votre compte</li>
                </ul>     

             <h6>Comment gérer les alertes et les newsletters que MonKolis m’envoie ?</h6>   
                <p>Vous avez accès depuis votre Profil, dans la rubrique <a href="/dashboard/profile/notifications" className="link">Notifications</a> à une gestion personnalisée des alertes et emails que vous souhaitez recevoir de la part de BlaBlaCar.</p>

                <p>Vous pouvez choisir individuellement les notifications que vous souhaitez activer ou désactiver et modifier vos <a href="/dashboard/alerts" className="link">alertes</a> de trajets à votre convenance.
                </p>

              <h5>Le niveau d'expérience sur MonKolis</h5>
              <h6>À quoi sert le niveau d’Expérience sur BlaBlaCar ?</h6>

                <p>Le transport de colis entre particuliers repose essentiellement sur la confiance que s’accordent les utilisateurs.</p>

                <p>Le niveau d’Expérience définit votre ancienneté et votre niveau d’activité sur le site. C’est une donnée qui traduit le nombre d’avis reçus, le taux de remplissage de votre profil, les vérifications effectuées (email et téléphone) et votre ancienneté.</p>   

                <p>Votre niveau d’Expérience est visible sur votre profil, il informe les membres de votre expérience sur MonKolis. C’est un moyen simple et efficace de donner confiance aux transporteurs sur le choix de leurs expéditeurs. Cela rassure les expéditeurs de savoir que le tranporteur est connu sur le site, a déjà de nombreux avis positifs et une certaine ancienneté</p>
                <h5>Vous êtes nouveau sur le site ? </h5>
            <p>Il est important d’obtenir rapidement vos premiers avis et de compléter votre profil pour augmenter la confiance que les expédieurs pourront vous porter.</p>

          <h6>Comment est calculé le niveau d’Expérience, comment augmenter mon niveau ? </h6>
            <p>Cette évaluation est un moyen simple et visuel de donner confiance aux expéditeurs sur le choix de leurs transporteurs et vice-versa.</p>

          <h5>Comment améliorer mon niveau d'Expérience?</h5>
          <h6>Laissez des avis : vous en aurez en retour !</h6>
            <ul>
              <li>Remplissez votre <a href="/dashboard/profile" className="link">profil </a></li>
              <li>Votre niveau est recalculé de façon journalière, à la hausse comme à la baisse. De cette manière, les efforts consentis se mesurent sur la durée et non à un seul instant.</li>
            </ul>

          <h6>Je suis Ambassadeur sur MonKolis, quels sont mes avantages ?</h6> 
            <p>En tant qu’Ambassadeur sur MonKolis, vous apparaissez aux autres utilisateurs comme un membre actif et aguerri sur le site. Pour les expéditeurss, c'est l’assurance d’un transport parfaitement organisé et sans mauvaise surprise, ce qui les incitera à prendre plus facilement contact avec vous.</p>

            <p>Par ailleurs, en tant qu’Ambassadeur et membre très actif du site, nous pouvons parfois prendre contact avec vous pour prendre votre avis sur certaines fonctionnalités du site, organiser des tables rondes ou des événements.</p>

            <p>Nous considérons en tout cas nos ambassadeurs comme des membres privilégiés portant les valeurs du covoiturage et nous aidant à faire grandir le service dans la meilleure direction, ce pour quoi nous vous remercions vivement.
            </p>

            <h5>Ma photo</h5>
            <h6> Pourquoi ajouter ma photo, comment faire ? </h6>
              <p>MonKolis est un site de mise en relation de personnes souhaitant effectuer expédier des colis avec des voyageurs ou transporteurs professionnels. Il est important et tout simplement rassurant de voir les personnes à qui l'on va remettre son colis, notamment pour se reconnaître sur le lieu du rendez-vous !</p>

              <p>Les expéditeurs vont plus spontanément vers les transporteurs proposant leur portrait, car c’est moins anonyme et plus rassurant. En moyenne, les utilisateurs avec photo sont plus contactés ! <br/>
              C’est pourquoi nous incitons réellement les utilisateurs à ajouter une photo sur leur profil, ce qui a pour effet d’augmenter le taux de remplissage de leur profil et leur donne une meilleure visibilité.<br/>
              Deux (02) minutes suffisent à télécharger votre photo et cela facilitera grandement vos transferts futurs.</p>

            <h6>Comment faire ?</h6>
              <p>Vous pouvez ajouter votre photo depuis la page suivante : <a href="/dashboard/profile/photo" className="link">Ajouter une photo </a></p>

							<p> Vous avez la possibilité de nous envoyer votre photo au format jpeg, gif ou png (3 Mo maximum)  en cliquant sur « Parcourir » et en sélectionnant le fichier sur votre ordinateur
              Toutes les photos sont modérées par notre équipe qui opère notamment un recadrage serré sur le visage. Votre photo sera visible par les membres une fois cette validation effectuée.</p>

            <h6>Quelles sont les règles concernant les photos ?</h6>
              <p>Les photos doivent en premier lieu respecter une certaine taille et être à un format adapté.Nous acceptons les formats jpeg, gif et png et fichiers d’une taille maximum de 3 Mo (megaoctets).</p>

              <p>Si la taille est supérieure, il vous faudra au préalable réduire la taille de votre fichier grâce à un logiciel d’édition dédié ou en passant par un site comme celui-ci : <a href="http://reduction-image.com/resizer-online/" className="link" target="_blank">http://reduction-image.com/resizer-online/</a> </p>

            <h6>Les photos doivent répondre à un certain nombre de critères, vous devez notamment :</h6>
              <ul>
                <li>être seul(e)</li>
                <li>être identifiable</li>
                <li>ne pas avoir de lunettes de soleil</li>
                <li>être en gros plan</li>
                <li>être souriant, c'est toujours plus sympa &#9786; </li>
                <li>adopter une attitude correcte, être décent(e)</li>
              </ul>

            <h6>Nous refusons :</h6>
              <ul>
                <li>les dessins et avatars</li>
                <li>les photos prises de trop loin</li>
                <li>les photos floues ou trop sombres</li>
                <li>les grimaces</li>
              </ul>
              <strong>N'oubliez pas, l'objectif est que votre futur expéditeur/transporteur puisse vous reconnaître !</strong>
              <p>Par ailleurs, si nous sommes aussi exigeants sur les photos, c’est qu’elles peuvent apparaître à de nombreux endroits sur site, parfois avec de très petites dimensions (notamment sur les applications mobiles). Vous devez donc être clairement identifiable !</p>
 
          
            <h5>Je rencontre des problèmes avec ma photo, que faire ?</h5>
              <p>Si votre photo n’est pas visible, elle peut-être suppriée à tout moment par notre équipe !</p>
              <p>Plusieurs milliers de photos sont modérées au quotidien. Nous procédons également à un recadrage de ces dernières pour les recentrer sur votre visage, afin que l’on puisse vous reconnaître facilement même sur les plus petits formats. <br/>Ce contrôle peut intervenir plusieurs jours après que vous ayez téléchargé votre photo.</p>
              <p>Vous serez alerté(e) par email et sur le site si jamais votre photo n'est pas conforme à nos critères. Le motif vous est indiqué dans l’email vous notifiant la suppression.</p>

              <div>Pour rappel, vous devez notamment  :
                <ul>
                  <li>être seul(e)</li>
                  <li>être identifiable</li>
                  <li>ne pas avoir de lunettes de soleil</li>
                  <li>être en gros plan</li>
                  <li>être souriant c'est toujours plus sympa &#9786; </li>
                  <li>adopter une attitude correcte, être décent(e)</li>
                </ul>
              </div>
              <h6>Nous refusons en outre :</h6>
              <ul>
                <li>les dessins et avatars</li>
                <li>les photos prises de trop loin</li>
                <li>les photos floues ou trop sombres</li>
                <li>les grimaces</li>
              </ul>

              <p>Si votre photo est rejetée, nous vous conseillons de nous soumettre une nouvelle photo respectant les points précédents.</p>

            <h5>Messages</h5>
            <h6> Comment envoyer des messages ?</h6>
              <p> En tant que expéditeur, vous avez la possibilité de contacter le transporteur aussi bien avant qu'après la réservation. Une fois sur l'annonce du trajet, cliquez sur Ecrire au transporteur.</p>          
              <p>En tant que transporteur, vous pouvez utiliser la messagerie MonKolis en répondant à vos messages si vous en avez, ou en écrivant directement à vos expéditeurs après qu'ils aient réservé ou fait une demande de réservation.</p>

            <h5>Quelles sont les règles d'envoi des messages ? </h5>
              <div>
                <span>Notre équipe bloquera des messages contenant :</span>
                <ul>
                  <li>un numéro de téléphone, une adresse email</li>
                  <li>une tentative de contournement du fonctionnement du site</li>
                </ul>
                <p>Si nous bloquons votre message, nous vous écrirons pour vous en expliquer la raison et vous conseiller sur la marche à suivre pour envoyer un autre message.</p>
              </div>

            <h6>Pourquoi ne puis-je pas envoyer de message ?</h6>
              <div>
                Voici une liste des cas dans lesquels vous ne pouvez pas envoyer de message :
                <ul>
                  <li>Votre message est en cours de modération (notre équipe s'en occupe au plus vite !)</li>
                  <li>Nous ne l’avons pas envoyé parce qu’il ne respecte pas nos règles de rédaction.</li>
                  <li>Votre réseau ou votre connexion sont insuffisants.</li>
                </ul>
              </div>

            <h6>Si vous êtes expédieur :</h6> 
              <ul>
                <li>Le transporteur a refusé votre demande.</li>
                <li>Il n'y a plus d'espace disponible.</li>
                <li>Le transport a lieu dans moins de 30 minutes.</li>
              </ul>

              <p>Si vous avez des difficultés à envoyer des messages pour une toute autre raison, <a href="https://www.facebook.com/MonKolis/" className="link" target="_blank">contactez-nous</a>.</p>

            <h6>Et si je ne reçois pas de réponse ?</h6>
              <p> Si le transporteur ou l'expéditeur que vous avez contacté ne vous répond pas, il vaut mieux chercher un autre membre pour votre expédition. Il se peut que notre équipe ait bloqué votre message initial.</p>

              <p>Lorsque c'est le cas, nous vous envoyons un mail pour vous en expliquer la raison et vous conseiller sur la marche à suivre pour envoyer un autre message. <br/> 
              Si vous n'avez rien reçu de notre part, consultez votre dossier de courriers indésirables (spam) ou vérifiez dans votre compte MonKolis que vous utilisez la bonne adresse mail.</p>

              <h6>Comment gérer mes notifications de messagerie ?</h6>

                <p> Gérez toutes vos notifications depuis votre profil dans la section « <a href="/dashboard/profile/notifications" className="link">Notifications</a> ».</p>

                <p>Sur cette page, vous pouvez activer et désactiver vos notifications, et choisir lesquelles recevoir et par quel moyen. <br/> Vous pouvez également accéder à la gestion de ces préférences depuis le lien inclus en bas de nos mails de notification. </p>

                <a href="#top" className="link color-text2">Remonter</a> <br /><br />

              <h4 id="6">Les avis</h4>
              <h5>Laisser des avis</h5>
              <h6>Pourquoi est-ce important de laisser des avis ?</h6>
                <p>Les avis sont le meilleur moyen de créer et renforcer les liens de confiance entre les membres de la communauté.

                <br />Grâce à l'avis que vous laissez sur un transporteur ou un expéditeur, les autres membres peuvent imaginer à quoi ressemblera leur transaction avec lui ou elle.

                <br />Comment obtenir facilement un avis ? Facile, laissez-en un à votre transporteur. Plus vous laissez d'avis, plus vous en recevrez, et plus les autres membres auront envie d'expédier avec vous.

                <br />Les avis sont également un bon moyen de passer au niveau d'expérience supérieur, pensez-y ! 
                </p>

              <h5>Comment puis-je laisser un avis ?</h5>

              <p>Vous pouvez laisser un avis en suivant l'indication ans votre compte après la livraison du colis.

                <br />Vous pouvez également suivre le lien disponible dans l’e-mail que nous vous avions envoyé après la livraison du colis.</p>

                <p>C'est la première fois que vous laissez un avis ? Voici comment faire : 
                  <ul>
                    <li>Choisissez l'adjectif qui décrit le mieux votre trajet en sa compagnie.</li>
                    <li>Ajoutez une note plus personnelle concernant sa ponctualité par exemple, ou ses goûts musicaux !</li>
                  </ul>
                </p>

                <p>Vous souhaitez laisser un avis négatif ? Essayez de l'exprimer de manière objective. Et si vous préférez nous en parler en privé, n'hésitez pas à nous <a href="https://www.facebook.com/MonKolis/" className="link" target="_blank">contacter</a>.</p>

              <h5>À partir de quand puis-je laisser un avis ?</h5> 
                <p>Vous pouvez laisser un avis depuis votre compte une fois le colis livré et la livraison validée par le transporteur avec son code de livraison.</p>

                <p>Si l'autre membre vous laisse un avis en premier, vous aurez 14 jours pour lui en laisser un en retour. Une fois les 14 jours passés, l'avis que vous avez reçu sera visible sur votre profil et, si vous ne l'avez pas déjà fait, vous ne pourrez plus en laisser un en retour. <br/> Important : il n'est pas possible de laisser un avis si la réservation a été annulée. </p>

              <h5> Je laisse un avis : que dois-je écrire ? </h5>

                <p>  Pas besoin d'écrire des pages et des pages sur l'autre membre &#9786; prenez juste quelques minutes pour décrire comment l'expédition s'es déroulé (bienveillance du transporteur, conversations passionnantes...). </p>

                <p>  Demandez-vous si vous accepteriez de transporter à nouveau le colis de cette personne, ou si vous le recommanderiez comme transporteur à un membre de votre entourage. Si oui, laissez un avis positif ! </p>

                <p>  Mais si la réponse est non, informez-en les autres membres de la communauté. Toutefois, veillez à ce que votre avis reste aussi objectif que possible. Si votre colis a été livrée avec du retard, vous pouvez le mentionner mais n'oubliez pas qu'on ne peut pas toujours tout contrôler (annulation ou retard de vols, oublis...). </p>

              <h6> Comment modifier ou supprimer un avis que j’ai laissé ? </h6>
                <p>La modification ou la suppression d'un avis laissé à un membre n'est pas possible. Après une livraison, c'est votre première impression qui compte. </p>

                <p>Les avis sont là pour permettre à tous les membres d'avoir une idée de l'expédition qu'ils s'apprêtent à effectuer. Le but recherché n'est pas que chaque membre obtienne 5/5 à chaque fois :

                <br />Mais si vous pensez avoir publié un avis erroné pour une autre raison, vous pouvez nous <a href="https://www.facebook.com/MonKolis/" className="link" target="_blank">contacter</a>. Pensez à nous apporter des précisions sur votre demande. </p>

              <h6> Pourquoi n'ai-je que 14 jours pour laisser un avis ? </h6>
                <p> Il est important de bien se rappeler d'une expérience pour en parler. Voilà pourquoi vous avez 14 jours pour laisser un avis après que l'autre membre en a laissé un pour vous. <br/> Une fois les 14 jours écoulés, vous ne pourrez plus laisser d'avis.</p>

                <p>Si vous avez laissé un avis en premier, la même règle s'applique. L'autre membre dispose de 14 jours pour vous en laisser un en retour. Une fois que les deux membres ont laissé un avis - ou si les 14 jours sont écoulés, nous les afficherons sur vos profils respectifs.</p>
                
              <h6>L'avis que j'ai laissé n'a pas été publié et je ne peux pas en laisser un autre. Pourquoi ?</h6> 

                <p>Après la validation de la livraison, vous pourrez laisser un avis à l'autre membre mais attention : s'il ne respecte pas nos recommandations et que nous décidons de ne pas le publier, vous ne pourrez pas en laisser un autre.</p>

                <div>
                  Souvenez-vous-en la prochaine fois ! Avant de publier votre avis, vous avez la possibilité de le relire une dernière fois. Vérifiez bien qu'il suit nos recommandations :
                  <ul>
                    <li>Écrivez un avis qui correspond à l'évaluation que vous avez donnée (nous ne publions pas un avis "à éviter" s'il ne contient que des commentaires positifs par exemple).</li>
                    <li>Les avis laissés à un membre sont affichés sur son profil, il est important d'écrire de façon claire !</li>
                    <li>Ne communiquez ni numéro de téléphone, ni adresse e-mail, ni URL (site internet ou page Facebook), même s'il s'agit seulement d'une information évoquée pendant le trajet.</li>
                    <li>N'écrivez rien d'inapproprié, de discriminatoire ou d'agressif.</li>
                  </ul>
                </div>

              <h5>Obtenir des avis </h5>
              <h6>Que faire si je ne suis pas d'accord avec l'avis que j'ai reçu ? </h6>
                <p>Personne ne souhaite obtenir d'avis négatifs, nous le savons bien, mais nous ne supprimons que les avis à caractère injurieux ou discriminatoire.
                
                <br />N'oubliez pas que si c'est quelque chose s'est mal passé indépendamment de votre volonté, un avis négatif au milieu aura un effet limité au milieu des autres avis. </p>

              <h6>Puis-je voir l'avis qu'on m'a laissé avant d'en écrire un en retour ? </h6>
                <p> Malheureusement non, car il est important que nos membres soient honnêtes au moment de laisser un avis. </p>
                <p>Les membres ne peuvent pas voir les avis qu'on leur a laissés avant d'en laisser un eux-mêmes, vous pouvez compter sur leur honnêteté et croire à la description de l'expédition. Les avis ne sont utiles que s'ils sont sincères.</p>

                <a href="#top" className="link color-text2">Remonter</a> <br /><br />

              <h4 id="7">L'assurance du transport</h4>
                <p>Les colis expédiés sur MonKolis bénéficient d'une assurance spécifique, couvrant automatiquement le bien assuré pendant la période d'expédition.</p>
                <p>Le coût est inclus dans le montant global de l'expédition. <br/>
                La franchise éventuelle à régler en cas de dommages est entièrement à la charge de l'exéditeur. <br/>
                Une vérification du contenu du colis avec photos, doit être fait avant et le dépôt et après la livraison du colis. <br/>
                Le montant maximum de garantie par matériel, et ce quel que soit la valeur à neuf du dit-matériel, est fixé forfaitairement à 1000 €.</p>

                <a href="#top" className="link color-text2">Remonter</a> <br /><br />

              <h4 id="8">Expédier en toute sécurité</h4>
                <p>Vous retrouverez tous nos conseils pour réussir son expdtition dans la section <a href="/tips" className="link">conseils de prévention</a>.</p>
                <a href="#top" className="link color-text2">Remonter</a> <br /><br />
        </div>
      </div>
    );
  }
}
export default withRouter(Beginners);