import React, { Component, Fragment} from 'react';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

import axios from 'axios';
import { CHANGE_USER_PASSWORD_QUERY } from './../../../query/queryprofil'
import { USER_INFO_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import Moment from 'moment';
import './../../../assets/css/login.css';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyMotdepasse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			old_password: '',
			proposed_password: '',
			confirm_newpassword: '',
			error: null,
			isLoaded: false,

			msgAlertBody: '',
			msgAlertHeader: '',
			alertVariant: 'danger',
			showAlert: false,

			isPasswordShown: false,

		};
		this.handleProposed_passwordChange = this.handleProposed_passwordChange.bind(this);
		this.handleOld_passwordChange = this.handleOld_passwordChange.bind(this);
		this.handleConfirm_newpasswordChange = this.handleConfirm_newpasswordChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	togglePasswordVisibily = () => {
		const { isPasswordShown } = this.state;
		this.setState({ isPasswordShown: !isPasswordShown })

	}

	handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		else {
			if (this.state.proposed_password !== this.state.confirm_newpassword) {
				this.setState(() => ({
					showAlert: true,
					msgAlertBody: 'Les mots de passe ne sont pas identiques, merci de verifier les informations saisies',
					msgAlertHeader: 'Echec',
					alertVariant: 'danger'

				}));
				
				//alert("Passwords don't match");
			}
			else {
				var VAR = JSON.stringify(
					{
						"old_password": this.state.old_password,
						"proposed_password": this.state.proposed_password

					})
				this.udapteMDPRequest(CHANGE_USER_PASSWORD_QUERY, VAR)
			}
		}
	}

 
	handleCloseAlert(event) {
		this.setState({ showAlert: false });
	}

	handleProposed_passwordChange(event) {
		this.setState({ proposed_password: event.target.value });
	}


	handleOld_passwordChange(event) {
		this.setState({ old_password: event.target.value });
	}

	handleConfirm_newpasswordChange(event) {
		this.setState({ confirm_newpassword: event.target.value });
	}

	componentWillMount() {
		
		this.idToken = getCookieConsentValue('idToken');
		this.accessToken = getCookieConsentValue('accessToken');
		// this.idToken = sessionStorage.getItem('idToken')
		// this.accessToken = sessionStorage.getItem('accessToken')
	}


	udapteMDPRequest = async (query, variables) => {
		try {
			const response = await axios.post(USER_INFO_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken,
						'AccessToken': this.accessToken
					}
				}
			);
			// Set the data to the state

			if (response.data.data.changePasswordUser.status === 200) {

				this.setState(() => ({
					showAlert: true,
					msgAlertBody: 'Votre mot de passe a été modifié avec succès',
					msgAlertHeader: 'Modification effectuée',
					alertVariant: 'success'
				}));
				this.setState(() => ({
					requestIsOk: true
				}));
				//window.location.reload(false);
			}
			else {
				//alert("Erreur Modification de vos informations")
				this.setState(() => ({
					showAlert: true,
					msgAlertBody: 'La modification  du mot de passe a échoué, merci de verifier les informations saisies',
					msgAlertHeader: 'Echèc de modification',
					alertVariant: 'danger'

				}));
				this.setState(() => ({
					requestIsOk: false
				}));
			}
		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	render() {
		const { isPasswordShown } = this.state;
		return (
			<Fragment>
				<div className="myprofile_password">
					<div className="section">
						<div className="section-inner">
							<h3 className="heading">Changer de mot de passe</h3>
							{this.state.showAlert ?
								<Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert} dismissible>
									<Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
									<p>{this.state.msgAlertBody} </p>
								</Alert> : ''}
							<div className="content">
								<div className="text-muted"><em className="align-center">Le mot de passe doit être de 8 caractères minimum</em></div>
								<br />
								<Form className="well" validated={this.state.validated} onSubmit={this.handleSubmit}>
									<Form.Group controlId="validationCustom01">
										<Form.Label>Mot de passe actuel</Form.Label>
										<Form.Control
											required
											type={(isPasswordShown) ? "text" : "password"}
											placeholder=""
											defaultValue={this.state.old_passwordp}
											onChange={this.handleOld_passwordChange}
										/>
										<span className="glyphicon glyphicon-lock form-control-feedback"></span>
										{/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
									</Form.Group>

									<Form.Group controlId="validationCustom01">
										<Form.Label>Nouveau mot de passe</Form.Label>
										<Form.Control
											required
											type={(isPasswordShown) ? "text" : "password"}
											placeholder=""
											defaultValue={this.state.proposed_password}
											onChange={this.handleProposed_passwordChange}
										/>
										<span className="viewpass"><i className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"}`}
											onClick={this.togglePasswordVisibily}></i></span>

										<span className="glyphicon glyphicon-lock form-control-feedback"></span>
										{/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
									</Form.Group>


									<Form.Group controlId="validationCustom01">
										<Form.Label>Confirmation du mot de passe</Form.Label>
										<Form.Control
											required
											type={(isPasswordShown) ? "text" : "password"}
											placeholder=""
											defaultValue={this.state.confirm_newpassword}
											onChange={this.handleConfirm_newpasswordChange}
										/>
										<span className="viewpass"><i className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"}`}
											onClick={this.togglePasswordVisibily}></i></span>

										<span className="glyphicon glyphicon-lock form-control-feedback"></span>
										{/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
									</Form.Group>
									<input type="submit" className="btn btn-block btn-theme2" value="Valider" />
								</Form>

								{/* <form onSubmit={this.handleSubmit} className="">
								<div className="row">
									<div className="form-group col-md-6">
										<label for="PastPassword" className="">Mot de passe actuel</label>
										<input type={(isPasswordShown) ? "text" : "password"} className="form-control" value={this.state.old_password} onChange={this.handleOld_passwordChange} />
									</div>
									<i className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"} password-icon`}
										onClick={this.togglePasswordVisibily}></i>
								</div>
								<div className="row">
									<div className="form-group col-md-6">
										<label for="NewPassword1" className="">Nouveau mot de passe</label>
										<input type={(isPasswordShown) ? "text" : "password"} className="form-control" value={this.state.proposed_password} onChange={this.handleProposed_passwordChange} />
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-6">
										<label for="NewPassword2" className="">Confirmation du mot de passe</label>
										<input type={(isPasswordShown) ? "text" : "password"} className="form-control" value={this.state.confirm_newpassword} onChange={this.handleConfirm_newpasswordChange} />
									</div>
								</div>

								<div className="row">
									<div className="col-md-6">
										<input type="submit" classNameName="btn btn-block btn-theme2" value="Valider" />
									</div>
								</div>

							</form> */}
								<div className="modal fade" id="modal-mdp-change" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" >

								</div>

							</div>
						</div>
					</div>

				</div>
			</Fragment>
		);
	}
}
export default withRouter(MyMotdepasse);