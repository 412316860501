import React, { Component } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';
import logo from './../../assets/img/logo/logo2.png';
import {
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
} from "react-social-buttons";

import ReactFBLike from 'react-fb-like';
class Footer extends Component {

    render() {
        return (
            <footer className="section-footer">
                <div className="container-fluid container-fluid-pad">
                    <div className="footer-content">
                        <div className="col-lg-3 col-sm-6 col-12">
                            <h6 className="footer-title">MonKolis</h6>
                            <ul className="list-items">
                                <li><a className="link" href="/about">A propos</a></li>
                                <li><a className="link" href="/founders">Cofondateurs</a></li>
                                <li><a className="link" href="/careers" >Carrière</a></li>
                                <li><a className="link" href="/press" >Presse</a></li>
                                <li><a className="link" href="/blog/genesis" >Blog</a></li>
                                <li><a className="link" href="/personnalshooping" >Personal Shoppers</a></li>
                                <li><a className="link" href="/contact" >Contact</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <h6 className="footer-title">En pratique	</h6>
                            <ul className="list-items">
                                <li><a className="link" href="/work" >Comment ça marche?</a></li>
                                <li><a className="link" href="/trust" >Gages de Confiance</a></li>
                                <li><a className="link" href="/level" >Niveau d'expérience</a></li>
                                <li><a className="link" href="/opinions" >Les avis</a></li>
                                <li><a className="link" href="/rules" >Charte de bonne conduite</a></li>
                                <li><a className="link" href="/tips" >Conseils prévention</a></li>
                                <li><a className="link" href="/price" >Prix du cobagage</a></li>
                                <li><a className="link" href="/tutos" >Guide des tutos</a></li>
                                <li><a className="link" href="/faq/" >Foire aux questions</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <h6 className="footer-title">Informations légales</h6>
                            <ul className="list-items">
                                <li><a className="link" href="/legales" >Mentions légales</a></li>
                                <li><a className="link" href="/terms" >Conditions générales</a></li>
                                <li><a className="link" href="/privacy" >Charte de Confidentialité</a></li>
                                <li><a className="link" href="/cookies" >Utilisation des cookies</a></li>
                                <li><a className="link" href="/prohibited" >Produits interdits</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="social-addons">
                                <h6 className="footer-title">Suivez-nous</h6>
                                <div className="list-social">
                                    <a type="btn" href="https://www.facebook.com/MonKolis" target="_blank"><i className="fa fa-facebook-square"></i></a>
                                    <a type="btn" href="https://www.instagram.com/monkolis_officiel/" target="_blank"><i className="fa fa-instagram"></i></a>
                                    <a type="btn" href="https://www.youtube.com/channel/UCe_IOKnXEncUXW60NlT75zQ" target="_blank"><i className="fa fa-youtube"></i></a>
                                    <a type="btn" href="https://www.twitter.com/KolisMon/" target="_blank"><i className="fa fa-twitter"></i></a>
                                </div>
                            </div>
                            <div className="touchus">
                                <h6 className="footer-title">Contacter nous par message sur WhatsApp </h6>
                                <ul className="touchus-items">
                                    <li><i className="fa fa-1x fa-whatsapp"></i> +225 97 09 65 78</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="align-center mar-top20 mar-bot20 reso col-lg-12">
                            <p>MonKolis offre un service dans l’esprit de l’économie collaborative dont le but est de faire des économies au quotidien tout en contribuant au développement durable.
                                <br />Le transport de kolis à l'international n'aura jamais été aussi simple : nous mettons en relation des transporteurs et des expéditeurs qui veulent envoyer ou recevoir un kolis du pays. Grâce à notre service, le kolis de l'expéditeur arrive rapidement et en toute sureté tandis que le transporteur rentabilise son voyage et fait de belles rencontres. Tout le monde y gagne : les uns payent moins cher leur envoi et les autres remboursent une partie de leurs frais de transport ou augmentent leurs revenus, tout en rendant service.
                            </p>
                        </div>
                    </div>
                    <div className="logo">
                        <p className=""><img src={logo} alt="Logo MonKolis" /></p>
                    </div>
                    <div className="pad-bot20 mar-bot0 pad-top5">
                        <p className="copyright align-center mar-bot0">© 2020 - MonKolis | 0.1.52</p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;