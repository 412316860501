import React from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
// components
import Header from './../components/header/header';
import Footer from './../components/footer/footer';
// pages
import About from "../pages/about/about";

//blog
import Blog from "../pages/blog/blog";
import PressCom from "../pages/blog/blog-presscom/presscom";
import Senegal from "../pages/blog/blog-senegal/senegal";
import PersonnalShopping from "../pages/blog/blog-personnalshopper/personnalshopper";
import Covid from "../pages/blog/blog-covid/covid";
import Belgium from "../pages/blog/belgium/belgium";
import MonkolisMoney from "../pages/blog/blog-monkolismoney/monkolismoney";
import Moveout from "../pages/blog/blog-moveout/moveout";
import Expeditions from "../pages/blog/expeditions/expeditions";

import Careers from "../pages/careers/careers";
import Connexion from './../pages/connexion/connexion';
import Contact from "../pages/contact/contact";
import ContactSuccess from "../pages/contact/contactsuccess";
import Cookies from "../pages/cookies/cookies";
import Crowfunding from './../pages/crowfunding/crowfunding';
import Details from "../pages/details/details";
import FaqBeginners from "../pages/faq/beginners";
import FaqPayments from "../pages/faq/payments";
import FaqResiliations from "../pages/faq/resiliations";
import FaqSenders from "../pages/faq/senders";
import FaqTravelers from "../pages/faq/travelers";
import FaqOthers from "../pages/faq/others";

import Founders from "../pages/founders/founders";
import Home from './../pages/home/home';
import Level from "../pages/level/level";
import Opinions from "../pages/opinions/opinions";
import PasswordLost from './../pages/passwordlost/passwordlost';
import Press from "../pages/press/press";
import Price from "../pages/price/price";
import Privacy from "../pages/privacy/privacy";
import Register from './../pages/register/register';
import Rules from "../pages/rules/rules";
import Terms from "../pages/terms/terms";
import Legales from "../pages/legales/legales";
import PersonnalShooper from "../pages/personnalshooper/personnalshooper";
import PersonnalShooping from "../pages/personnalshooping/personnalshooping";
import Tips from "../pages/tips/tips";
import Trips from "../pages/trips/trips";
import Trust from "../pages/trust/trust";
import Work from "../pages/work/work";
import Prohibited from "../pages/prohibited_products/prohibite";




import PasswordChange from "../pages/passwordchange/passwordchange";
import Dashboard from '../pages/dashboard/dashboard'
import Confirmusercode from "../pages/confirmusercode/confirmusercode";
import Traveldetails from "../pages/traveldetails/traveldetails"
import Traveldetailsrs from "../pages/traveldetailsrs/traveldetailsrs"
import Mytrips from "../pages/dashboard/mytrips/mytrips";
import MyTripsInProgress from "../pages/dashboard/mytrips/mytripsinprogress";
import NewTrip from "../pages/dashboard/mytrips/newtrip";
import UpdateTrip from "../pages/dashboard/mytrips/updatetrip";
import TripHistoric from "../pages/dashboard/mytrips/historic";
import MyProfile from "../pages/dashboard/myprofile/myprofile";
import MyPersoInformation from "../pages/dashboard/myprofile/mypersoinformation";
import MyPhoto from "../pages/dashboard/myprofile/myphoto";
import MyPreferences from "../pages/dashboard/myprofile/mypreferences";
import MyVerifications from "../pages/dashboard/myprofile/myverifications";
import MyAvisrecu from "../pages/dashboard/myprofile/myavisrecu";
import MyAvislaisses from "../pages/dashboard/myprofile/myavislaisses";
import MyNotification from "../pages/dashboard/myprofile/mynotification";
import MyBonsplans from "../pages/dashboard/myprofile/mybonsplans";
import MyMotdepasse from "../pages/dashboard/myprofile/mymotdepasse";
import MyFermeturedecompte from "../pages/dashboard/myprofile/myfermeturedecompte";

import Faq from "../pages/faq/faq";
import Beginners from "../pages/faq/beginners";
import Payements from "../pages/faq/payments";
import Resiliations from "../pages/faq/resiliations";
import Senders from "../pages/faq/senders";
import Travelers from "../pages/faq/travelers";
import Others from "../pages/faq/others";


import User from "../pages/user/user";
import MyAlert from "../pages/dashboard/myalerts/myalerts";
import Payment from "../pages/payement/payement";
import MyMoney from "../pages/dashboard/mymoney/mymoney";
import MyExpeditions from "../pages/dashboard/myexpeditions/myexpeditions";
import MyMessages from "../pages/dashboard/mymessage/mymessage"
import MessagesVoyages from "../pages/dashboard/mymessage/messagevoyages"
import MessagesExpeditions from "../pages/dashboard/mymessage/messagesexpeditions"
import MyDashBoard from "../pages/dashboard/mydashboard/mydashboard"
import Error404 from "../components/errors/error404"
import Error500 from "../components/errors/error500"

import Tuto from "../pages/tuto/tuto";
import VideoGuide from "../pages/tuto/videoguide";
import VideoPub from "../pages/tuto/videopub";
import VideoTuto from "../pages/tuto/videotuto";


function RouterOutlet() {
  return (
    <BrowserRouter>
      <div>
        <Route path="/error-404" component={goError404} />
        <Route path="/error-500" component={goError500} />
        <Route path="/about" component={goAbout} />

        <Route path="/blog/genesis" component={goBlog} />
        <Route path="/blog/press-communication" component={goBlogPressCom} />
        <Route path="/blog/travel-to-Senegal" component={goBlogVisitSenegal} />
        <Route path="/blog/personnal-shopper" component={goBlogPersonnalShopping} />
        <Route path="/blog/covid" component={goBlogCovid} />
        <Route path="/blog/visit-Belgium" component={goBlogVisitBelgique} />
        <Route path="/blog/monkolis-make-money" component={goBlogMakeMoney} />
        <Route path="/blog/monkolis-mouve-out" component={goBlogMoveOut} />
        <Route path="/blog/expedition" component={goBlogExpedition} />
        

        <Route path="/careers" component={goCareers} />
        <Route path="/connexion" component={goConnexion} />
        <Route path="/contact" component={goContact} />
        <Route path="/contact-success" component={goContactSuccess} />
        <Route path="/cookies" component={goCookies} />
        <Route path="/crowfunding" component={goCrowfunding} />
        <Route path="/details" component={goDetails} />
        <Route path="/faq/beginners" component={goFaqBeginners} />
        <Route path="/faq/payments" component={goFaqPayments} />
        <Route path="/faq/resiliations" component={goFaqResiliations} />
        <Route path="/faq/senders" component={goFaqSenders} />
        <Route path="/faq/travelers" component={goFaqTravelers} />
        <Route path="/faq/others" component={goFaqOthers} />

        <Route path="/founders" component={goFounders} />
        <Route exact path="/" component={goHome} />
        <Route exact path="/logout" component={goHome} />
        <Route path="/legales" component={goLegales} />
        <Route path="/level" component={goLevel} />
        <Route path="/opinions" component={goOpinions} />
        <Route path="/password-lost" component={goPasswordLost} />
        <Route path="/press" component={goPress} />
        <Route path="/price" component={goPrice} />
        <Route path="/privacy" component={goPrivacy} />
        <Route path="/register" component={goRegister} />
        <Route path="/rules" component={goRules} />
        <Route path="/terms" component={goTerms} />
        <Route path="/tips" component={goTips} />
        <Route path="/trust" component={goTrust} />
        <Route path="/work" component={goWork} />
        <Route path="/passwordchange" component={goPasswordChange} />
        <Route path="/confirmusercode" component={goConfirmusercode} />
        <Route path="/trips" component={goTrips} />
        <Route path="/traveldetails/:id_travel" component={goTravelDeatils} />
        {/* <Route path="/traveldetailsrs/:id_travel" component={goTravelDeatilsrs} /> */}
        <Route path="/user" component={goUser} />
        <Route path="/payment" component={goPayment} />
        
        <Route path="/dashboard" component={goDashboard} />
        <Route path="/dashboard/myalerts" component={goMyAlert} />
        <Route path="/dashboard/mytrips" component={goMytrips} />
        <Route path="/dashboard/mytrips/inprogress" component={goMytripsInProgress} />
        <Route path="/dashboard/mytrips/newtravel" component={goNewTrip} />
        <Route path="/dashboard/mytrips/updatetravel" component={goUpdateTrip} />
        <Route path="/dashboard/mytrips/historic" component={goTripsHistoric} />
        <Route path="/dashboard/myprofile" component={goMyProfile} />
        <Route path="/dashboard/myprofile/myavislaisses" component={goMyAvislaisses} />
        <Route path="/dashboard/myprofile/myavisrecu" component={goMyAvisrecu} />
        <Route path="/dashboard/myprofile/mybonsplans" component={goMyBonsplans} />
        <Route path="/dashboard/myprofile/myfermeturedecompte" component={goMyFermeturedecompte} />
        <Route path="/dashboard/myprofile/mymotdepasse" component={goMyMotdepasse} />
        <Route path="/dashboard/myprofile/mynotification" component={goMyNotification} />
        <Route path="/dashboard/myprofile/mypersoinformation" component={goMyPersoInformation} />
        <Route path="/dashboard/myprofile/myphoto" component={goMyPhoto} />
        <Route path="/dashboard/myprofile/mypreferences" component={goMyPreferences} />
        <Route path="/dashboard/myprofile/myverifications" component={goMyVerifications} />

        <Route path="/faq" component={goFaq} />
        <Route path="/faq/beginners" component={goBeginners} />
        <Route path="/faq/payments" component={goPayements} />
        <Route path="/faq/resiliations" component={goResiliations} />
        <Route path="/faq/senders" component={goSenders} />
        <Route path="/faq/travelers" component={goTravelers} />
        <Route path="/faq/others" component={goOthers} />

        <Route path="/dashboard/money" component={goMyMoney} />
        <Route path="/dashboard/myexpeditions" component={goMyExpeditions} />
        <Route path="/dashboard/mymessage" component={goMyMessages} />
        <Route path="/dashboard/mymessage/messagevoyages" component={goMessagesVoyages} />
        <Route path="/dashboard/mymessage/messagesexpeditions" component={goMessagesExpeditions} />
        <Route path="/dashboard/mydashboard" component={goMyDashboard} />

       
        <Route path="/personnalshooper" component={goPersonnalShooper} /> 
        <Route path="/personnalshooping" component={goPersonnalShooping} />

        <Route path="/tutos" component={goMyTuto} />
        <Route path="/tutos/guide" component={goMyVideoGuide} />
        <Route path="/tutos/pubs" component={goMyVideoPub} />
        <Route path="/tutos/tuto" component={goMyVideoTuto} />
        <Route path="/prohibited" component={goProhibited} />

        {/* <Route path="*" component={goError404}/> 
        <Redirect from='*' to='/error-404' /> */}
      </div>
    </BrowserRouter>
  );
}

function goProhibited() {
  return (
    <div>
      <Header />
      <Prohibited />
      <Footer />
    </div>
  );
}


function goMyTuto() {
  return (
    <div>
      <Header />
      <Tuto />
      <Footer />
    </div>
  );
}



function goMyVideoGuide() {
  return (
    <div>
      <Header />
      <VideoGuide />
      <Footer />
    </div>
  );
}


function goMyVideoPub() {
  return (
    <div>
      <Header />
      <VideoPub />
      <Footer />
    </div>
  );
}


function goMyVideoTuto() {
  return (
    <div>
      <Header />
      <VideoTuto />
      <Footer />
    </div>
  );
}



function goPersonnalShooper() {
  return (
    <div>
      <Header />
      <PersonnalShooper />
      <Footer />
    </div>
  );
}


function goPersonnalShooping() {
  return (
    <div>
      <Header />
      <PersonnalShooping />
      <Footer />
    </div>
  );
}

function goError404() {
  return (
    <div>
      <Header />
      <Error404 />
      <Footer />
    </div>
  );
}


function goError500() {
  return (
    <div>
      <Header />
      <Error500 />
      <Footer />
    </div>
  );
}



function goAbout() {
  return (
    <div>
      <Header />
      <About />
      <Footer />
    </div>
  );
}

function goBlog() {
  return (
    <div>
      <Header />
      <Blog />
      <Footer />
    </div>
  );
}

function goBlogPressCom() {
  return (
    <div>
      <Header />
      <PressCom />
      <Footer />
    </div>
  );
}

function goBlogVisitSenegal() {
  return (
    <div>
      <Header />
      <Senegal />
      <Footer />
    </div>
  );
}

function goBlogPersonnalShopping() {
  return (
    <div>
      <Header />
      <PersonnalShopping />
      <Footer />
    </div>
  );
}

function goBlogCovid() {
  return (
    <div>
      <Header />
      <Covid />
      <Footer />
    </div>
  );
}

function goBlogVisitBelgique() {
  return (
    <div>
      <Header />
      <Belgium />
      <Footer />
    </div>
  );
}

function goBlogMakeMoney() {
  return (
    <div>
      <Header />
      <MonkolisMoney />
      <Footer />
    </div>
  );
}

function goBlogMoveOut() {
  return (
    <div>
      <Header />
      <Moveout />
      <Footer />
    </div>
  );
}

function goBlogExpedition() {
  return (
    <div>
      <Header />
      <Expeditions />
      <Footer />
    </div>
  );
}


function goCareers() {
  return (
    <div>
      <Header />
      <Careers />
      <Footer />
    </div>
  );
}
function goConnexion() {
  return (
    <div>
      <Header />
      <Connexion />
      <Footer />
    </div>
  );
}

function goContact() {
  return (
    <div>
      <Header />
      <Contact />
      <Footer />
    </div>
  );
}

function goContactSuccess() {
  return (
    <div>
      <Header />
      <ContactSuccess />
      <Footer />
    </div>
  );
}

function goCookies() {
  return (
    <div>
      <Header />
      <Cookies />
      <Footer />
    </div>
  );
}

function goCrowfunding() {
  return (
    <div>
      <Header />
      <Crowfunding />
      <Footer />
    </div>
  );
}

function goDetails() {
  return (
    <div>
      <Header />
      <Details />
      <Footer />
    </div>
  );
}

function goFaqBeginners() {
  return (
    <div>
      <Header />
      <FaqBeginners />
      <Footer />
    </div>
  );
}

function goFaqPayments() {
  return (
    <div>
      <Header />
      <FaqPayments />
      <Footer />
    </div>
  );
}

function goFaqResiliations() {
  return (
    <div>
      <Header />
      <FaqResiliations />
      <Footer />
    </div>
  );
}

function goFaqSenders() {
  return (
    <div>
      <Header />
      <FaqSenders />
      <Footer />
    </div>
  );
}

function goFaqOthers() {
  return (
    <div>
      <Header />
      <FaqOthers />
      <Footer />
    </div>
  );


}function goFaqTravelers() {
  return (
    <div>
      <Header />
      <FaqTravelers />
      <Footer />
    </div>
  );
}

function goFounders() {
  return (
    <div>
      <Header />
      <Founders />
      <Footer />
    </div>
  );
}

function goHome() {
  return (
    <div>
      <Header />
      <Home />
      <Footer />
    </div>
  );
}


function goLegales() {
  return (
    <div>
      <Header />
      <Legales />
      <Footer />
    </div>
  );
}


function goLevel() {
  return (
    <div>
      <Header />
      <Level />
      <Footer />
    </div>
  );
}

function goOpinions() {
  return (
    <div>
      <Header />
      <Opinions />
      <Footer />
    </div>
  );
}

function goPasswordLost() {
  return (
    <div>
      <Header />
      <PasswordLost />
      <Footer />
    </div>
  );
}

function goPress() {
  return (
    <div>
      <Header />
      <Press />
      <Footer />
    </div>
  );
}


function goPrivacy() {
  return (
    <div>
      <Header />
      <Privacy />
      <Footer />
    </div>
  );
}

function goPrice() {
  return (
    <div>
      <Header />
      <Price />
      <Footer />
    </div>
  );
}

function goRegister() {
  return (
    <div>
      <Header />
      <Register />
      <Footer />
    </div>
  );
}

function goRules() {
  return (
    <div>
      <Header />
      <Rules />
      <Footer />
    </div>
  );
}

function goTerms() {
  return (
    <div>
      <Header />
      <Terms />
      <Footer />
    </div>
  );
}

function goTips() {
  return (
    <div>
      <Header />
      <Tips />
      <Footer />
    </div>
  );
}

function goTrust() {
  return (
    <div>
      <Header />
      <Trust />
      <Footer />
    </div>
  );
}

function goWork() {
  return (
    <div>
      <Header />
      <Work />
      <Footer />
    </div>
  );
}

function goPasswordChange() {
  return (
    <div>
      <Header />
      <PasswordChange />
      <Footer />
    </div>
  );
}

function goConfirmusercode() {
  return (
    <div>
      <Header />
      <Confirmusercode />
      <Footer />
    </div>
  );
}


function goTrips() {
  return (
    <div>
      <Header />
      <Trips />
      <Footer />
    </div>
  );
}

function goTravelDeatils() {
  return (
    <div>
      <Header />
      <Traveldetails />
      <Footer />
    </div>
  );
}

function goTravelDeatilsrs() {
  return (
    <div>
      <Header />
      <Traveldetailsrs />
      <Footer />
    </div>
  );
}

function goDashboard() {
  return (
    <div>
      <Header />
      <Dashboard />
    </div>
  )
}

function goMytrips() {
  return (
    <div>
      <Mytrips />
      <Footer />
    </div>
  )
}



function goMytripsInProgress() {
  return (
    <div>
      <MyTripsInProgress />
      <Footer />
    </div>
  )
}

function goNewTrip() {
  return (
    <div>
      <NewTrip />
      <Footer />
    </div>
  )
}

function goTripsHistoric() {
  return (
    <div>
      <TripHistoric />
      <Footer />
    </div>
  )
}

function goUpdateTrip() {
  return (
    <div>
      <UpdateTrip />
    </div>
  )
}


function goMyProfile() {
  return (
    <div>
      <MyProfile />
      <Footer />
    </div>
  )
}

function goMyAvislaisses() {
  return (
    <div>
      <MyAvislaisses />
    </div>
  )
}

function goMyPersoInformation() {
  return (
    <div>
      <MyPersoInformation />
    </div>
  )
}



function goMyPhoto() {
  return (
    <div>
      <MyPhoto />
    </div>
  )
}


function goMyPreferences() {
  return (
    <div>
      <MyPreferences />
    </div>
  )
}


function goMyVerifications() {
  return (
    <div>
      <MyVerifications />
    </div>
  )
}


function goMyAvisrecu() {
  return (
    <div>
      <MyAvisrecu />
    </div>
  )
}

function goMyNotification() {
  return (
    <div>
      <MyNotification />
    </div>
  )
}

function goMyBonsplans() {
  return (
    <div>
      <MyBonsplans />
    </div>
  )
}

function goMyMotdepasse() {
  return (
    <div>
      <MyMotdepasse />
    </div>
  )
}

function goMyFermeturedecompte() {
  return (
    <div>
      <MyFermeturedecompte />
    </div>
  )
}





function goFaq() {
  return (
    <div>
      <Header/>
      <Faq />
      <Footer/>
    </div>
  )
}
function goBeginners() {
  return (
    <div>
      <Beginners />
    </div>
  )
}

function goPayements() {
  return (
    <div>
      <Payements />
    </div>
  )
}


function goResiliations() {
  return (
    <div>
      <Resiliations />
    </div>
  )
}

function goSenders() {
  return (
    <div>
      <Senders />
    </div>
  )
}

function goTravelers() {
  return (
    <div>
      <Travelers />
    </div>
  )
}

function goOthers() {
  return (
    <div>
      <Others />
    </div>
  )
}



function goUser() {
  return (
    <div>
      <Header />
      <User />
      <Footer />
    </div>
  )
}

function goMyAlert() {
  return (
    <div>
      <MyAlert />
      <Footer />
    </div>
  )
}

function goPayment() {
  return (
    <div>
      <Header />
      <Payment />
      <Footer />
    </div>
  )
}

function goMyMoney() {
  return (
    <div>
      <MyMoney />
      <Footer />
    </div>
  )
}

function goMyExpeditions() {
  return (
    <div>
      <MyExpeditions />
      <Footer />
    </div>
  )
}

function goMyMessages() {
  return (
    <div>
      <MyMessages />
      <Footer />
    </div>
  )
}

function goMessagesVoyages() {
  return (
    <div>
      <MessagesVoyages />
      <Footer />
    </div>
  )
}


function goMessagesExpeditions() {
  return (
    <div>
      <MessagesExpeditions />
      <Footer />
    </div>
  )
}

function goMyDashboard() {
  return (
    <div>
      <MyDashBoard />
      <Footer />
    </div>
  )
}



// function goTopics({ match }) {
//   return (
//     <div>
//       <h2>Topics</h2>
//       <ul>
//         <li>
//           <Link to={`${match.url}/rendering`}>Rendering with React</Link>
//         </li>
//         <li>
//           <Link to={`${match.url}/components`}>Components</Link>
//         </li>
//         <li>
//           <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
//         </li>
//       </ul>

//       <Route path={`${match.path}/:topicId`} component={Topic} />
//       <Route
//         exact
//         path={match.path}
//         render={() => <h3>Please select a topic.</h3>}
//       />
//     </div>
//   );
// }

// function Topic({ match }) {
//   return (
//     <div>
//       <h3>{match.params.topicId}</h3>
//     </div>
//   );
// }

export default RouterOutlet;