import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/login.css';
import './../../assets/css/modal.css';
import { withRouter } from 'react-router-dom';
import { CONFIRMUSERCODE_QUERY, RESEND_CONFIRM_CODE } from '../../query/queryauthent';
import { CONFIRMUSERCODE_URL } from '../../Url';
import Modal from 'react-bootstrap/Modal';

class Confirmcodeuser extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			confirmation_code: '',
			error: null,
			isLoaded: false,
			showResendModal: false,
			emailResend: '',
			showModalSuccess: false,
		};

		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleConfirmation_codeChange = this.handleConfirmation_codeChange.bind(this);
		this.handleResendConfirmationCodeClick = this.handleResendConfirmationCodeClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEmailResendChange = this.handleEmailResendChange.bind(this);
		this.handlereSendEmailSummit = this.handlereSendEmailSummit.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handlereSendEmailSummit

	handleEmailResendChange(event) {
		this.setState({
			emailResend: event.target.value
		})
	}

	handleSubmit(event) {
		event.preventDefault();
		var VAR = JSON.stringify({
			"username": this.state.email,
			"confirmation_code": this.state.confirmation_code
		})
		this.confirmCode(CONFIRMUSERCODE_QUERY, VAR)
	}

	handleEmailChange(event) {
		this.setState({ email: event.target.value });
	}


	handleConfirmation_codeChange(event) {
		this.setState({ confirmation_code: event.target.value });
	}


	confirmCode = async (query, variables) => {
		//alert(JSON.stringify(query)+ '   variables: '+variables)
		try {
			const response = await axios.post(CONFIRMUSERCODE_URL, {
				query,
				variables
			});
		if(response.data.data.confirmAnAuthentificatedUser.status===200){
			this.setState({
				showModalSuccess: true,
			});
			
		}
		else{
			if(response.data.data.confirmAnAuthentificatedUser.message ==="NotAuthorizedException|NotAuthorizedException|User cannot be confirmed. Current status is CONFIRMED"){
				
				alert("Ce email a déja été utilisé est déja confirmé")
			  }else
			  {
				  alert("Echec de confirmation, Merci de vérifier votre code et/ou votre email")
			  }
			
		}
			// Log the response so we can look at it in the console

			//this.props.history.push('../connexion');
		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
			console.log(error)
		}
	}

	handleResendConfirmationCodeClick() {
		this.setState({
			showResendModal: !this.state.showResendModal
		})
	}

	handleClose() {
		this.setState({ showModalSuccess: false });
		this.props.history.push({
			pathname: '../connexion',
		});
	}

	handlereSendEmailSummitClick(event) {
		event.preventDefault();
		var VAR = JSON.stringify({
			"username": this.state.emailResend
		})
		this.handlereSendEmailSummit(RESEND_CONFIRM_CODE, VAR)
	}

	handlereSendEmailSummit = async (query, variables) => {
		try {
			const response = await axios.post(CONFIRMUSERCODE_URL, {
				query,
				variables
			});
			// Log the response so we can look at it in the console

			alert('Veuillez vérifier vos mails, un nouveau code de validation vous a été envoyé')
		} catch (error) {
			// If there's an error, set the error to the state

			alert('Une erreur est survenue, veuillez vérifier votre adresse email')
		}
	}


	render() {
		return (

			<Fragment>
				<Helmet>
					<title>Confirmation de code | Monkolis</title>
					<meta name="robots" content="noindex,follow" />
				</Helmet>

				<div className="confirmusercode">
					<div className="main-box_content">
						<div className="container-fluid container-fluid-pad container-fluid-br">
							<div className="row pad-top20 pad-bot20">

								<div className="col-lg-6 col-md-10 col-sm-10 col-10 d-center">
									<div className="section">
										<div className="section-inner">
											<h3 className="heading">Confirmer votre inscription</h3>

											<p>Saisissez le code de validation qui vous a été envoyé par mail svp.
								</p>



											<form className="well" onSubmit={this.handleSubmit}>

												<div className="form-group has-feedback">
													<input type="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleEmailChange} />
													<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
												</div>

												<div className="form-group has-feedback">
													<input type="text" className="form-control" placeholder="Code" value={this.state.confirmation_code} onChange={this.handleConfirmation_codeChange} />
													<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
												</div>

												<input type="submit" className="btn btn-primary btn-block" value="Confirmation" /> <br />
												<a className="mar-bot20 mar-top5 link color-blue" onClick={this.handleResendConfirmationCodeClick} >Renvoyez le code</a>

											</form>


										</div>
									</div>
								</div>

							</div>
						</div>
						<Modal show={this.state.showResendModal} onHide={this.handleResendConfirmationCodeClick} centered>
							<Modal.Header closeButton>
								<Modal.Title>Renvoi du code de confirmation</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="form-group">
									<label for="exampleFormControlTextarea1">Votre adresse email</label>
									<input type="text" className="form-control" id="travelFirm" placeholder="email" value={this.state.emailResend} onChange={this.handleEmailResendChange} required="true" />
								</div>



							</Modal.Body>
							<Modal.Footer>
								<button className="btn btn-outline-primary" onClick={this.handleResendConfirmationCodeClick}>
									Annuler
                                </button>
								<button className="btn btn-primary" onClick={(e) => { this.handlereSendEmailSummitClick(e) }}>
									Valider
                                 </button>
							</Modal.Footer>


						</Modal>

						<Modal show={this.state.showModalSuccess} onHide={this.handleClose} centered>
							<Modal.Header closeButton>
								<Modal.Title> Inscription validée</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<p>Bravo, votre inscription a bien été validée. Vous pouvez à présent vous connecter et proposer votre trajet ou réserver chez un transporteur.</p>
							</Modal.Body>

							<Modal.Footer>
								<button className="btn btn-outline-primary" onClick={this.handleClose}>
									Me connecter.
								</button>
							</Modal.Footer>
						</Modal>
					</div>
				</div>
			</Fragment>

		);
	}
}


export default withRouter(Confirmcodeuser);
