import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/annex.css';
import './../../../assets/css/modal.css';
import './../../../assets/css/blog.css';
import PALAIS from './../../../assets/img/blog/palais-belgique.jpg';
import LAC from './../../../assets/img/blog/lac-belgique.jpg';
import BIERRE from './../../../assets/img/blog/bierre-geante.jpg';
import DEFILE from './../../../assets/img/blog/defile.jpg';
import CHAMBRE from './../../../assets/img/blog/chambre-belgique.jpg';
import CEREMONIE from './../../../assets/img/blog/ceremonie-belgique.jpg';

class Belgium extends Component {
  render() {
    return (

      <Fragment>
        <Helmet>
          <title>Blog | Monkolis</title>
          <meta name="robots" content="index,follow" />
        </Helmet>

        <div className="blog">
          <div className="main-box_content">
            <div className="container-fluid container-fluid-br headerwrap box-radius">
              <div className="blog-title">
              <h2 className="title">Blog MonKolis</h2>
                <hr></hr>
                <h5 className="txt-light">Histoires, conseils, secrets, astuces... On vous raconte tout !</h5>
              </div>
            </div>
            <div className="container-fluid container-fluid-pad container-fluid-br">
              <div className="row pad-top20 pad-bot20">
                <div className="col-sm-3">
                  <div className="list-group">
                    <li className="list-group-item bg-color-global txt-bold">Articles</li>
                    <a href="/blog/genesis" className="list-group-item list-group-item-action">La Génèse de MonKolis</a>
                    <a href="/blog/press-communication" className="list-group-item list-group-item-action">Communiqué de presse de lancement</a>
                    <a href="/blog/travel-to-Senegal" className="list-group-item list-group-item-action "> Visiter le Sénégal</a>
                    <a href="/blog/personnal-shopper" className="list-group-item list-group-item-action"> Personnal Shopping</a>
                    <a href="/blog/covid" className="list-group-item list-group-item-action"> Les 3 destinations idéales à visiter pendant le COVID</a>
                    <a href="/blog/visit-Belgium" className="list-group-item list-group-item-action active"> Visiter la Belgique</a>
                    <a href="/blog/monkolis-make-money" className="list-group-item list-group-item-action"> Gagner de l'argent avec Monkolis</a>
                    <a href="/blog/monkolis-mouve-out" className="list-group-item list-group-item-action "> Préparer son déménagement</a>
                    <a href="/blog/expedition" className="list-group-item list-group-item-action" > Expéditions à l'international</a>
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="section">
                    <div className="section-inner">
                    <h3 class="heading mar-bot0">POURQUOI LA BELGIQUE EST L’UNE DES MEILLEURES DESTINATIONS AU MONDE</h3>
                    <img src={PALAIS} alt="Insérer une photo" className="cover rounded" />
                    <br/> <br/>
                    <p>Visiter la Belgique est une expérience qui vous ouvre les yeux sur les merveilles dont regorge ce pays. Selon l'endroit que vous choisissez de visiter, vous découvrirez une architecture fantastique, une mode haut de gamme et une cuisine délicieuse, entre autres choses et parfois tout à la fois. Voici les principales raisons qui font de la Belgique l’un des meilleurs endroits à visiter.</p>

<h3>Villes pittoresques</h3>
<img src={LAC} alt="Insérer une photo" className="cover rounded" />
 
<p>Une des raisons les plus évidentes de visiter la Belgique est l'architecture, surtout dans les petites villes pittoresques comme Ostende ou Courtrai. Ces villes pittoresques ne sont pas les plus grandes du pays, mais sont les plus impressionnantes sur le plan architectural, comme Anvers et Bruxelles. Si vous aimez les structures et les œuvres d'art plus modernes, jetez un coup d'œil à l'art et à l'architecture modernes étonnants de Gand. Votre voyage en Belgique ne serait surtout pas complet sans une visite de la magnifique ville de Bruges. Les rivières, les bâtiments et les champs de jonquilles font de Bruges l'une des destinations les plus romantiques d'Europe.</p>

<h3>Gastronomie et boissons</h3>
<img src={BIERRE} alt="Insérer une photo" className="cover rounded" />
 
<p>La bière belge n'a pas besoin d'être présentée. La Belgique est réputée pour la grande qualité et la grande variété des bières qu’elle produit. De la pâle lager aux ales ambrées, en passant par les ales rouges flamandes, les ales brunes acides, les ales fortes et les stouts. Un autre aliment célèbre produit en Belgique, et l'un des plus délicieux et des plus populaires dans le monde entier, est bien sûr le chocolat belge. Les Belges ont inventé la praline en 1912 et sont devenus peu après connus pour fabriquer les meilleurs chocolats du monde. Une grande partie de leurs produits sont faits à la main, ce qui signifie que chaque bouchée fait l'objet d'un soin et d'une attention particulière.</p>

<h3>Une mode avant-gardiste</h3>
<img src={DEFILE} alt="Insérer une photo" className="cover rounded" />
 
<p>Vous ne réaliserez peut-être pas à quel point la Belgique est à la mode avant d'avoir visité la "5e capitale mondiale de la mode", Anvers. La ville possède l'une des plus anciennes académies du monde et l'une des écoles de mode les plus renommées. Il y a même un musée, le MoMu, qui célèbre les contributions de la ville à la haute couture. Il suffit de se promener dans la rue principale pour rencontrer des adolescents à la dernière mode, des femmes âgées en fourrure et des vêtements de jour glamour, et certains des plus beaux costumes que l'argent peut acheter aux hommes d'affaires de la ville. Mais il n'y a pas que les vêtements, Anvers est aussi l'endroit où il faut être pour acheter des diamants et des bijoux exquis. C'est l'endroit idéal pour vous acheter quelque chose de très spécial ou cette bague très importante pour marquer vos fiançailles.</p>

<h3>Un hébergement de qualité</h3>
<img src={CHAMBRE} alt="Insérer une photo" className="cover rounded" />
 
<p>Voyager en Belgique, c'est bien, mais en fin de compte, c'est votre logement qui fait ou défait votre séjour. Le logement est généralement de qualité, surtout dans les agglomérations comme Anvers. Même si certains hôtels habitués à recevoir des voyageurs de la haute vous sembleront trop couteux, vous trouverez quand même de belles offices à des prix raisonnables mettant un point d’honneur sur le confort de leurs hôtes.</p>

<h3>Des personnages hauts en couleur</h3>
<img src={CEREMONIE} alt="Insérer une photo" className="cover rounded" />

<p>La Belgique est l'une des nations les plus diverses du monde. Sa situation géographique, coincée entre la France et l'Allemagne, en fait un lieu privilégié pour l'immigration en provenance de toute l'Europe. Le pays possède des régions distinctes : la Flandre néerlandophone au nord, la Wallonie francophone au sud et une communauté germanophone à l'est. Les habitants ont généralement un sens de l'humour amusant et autodérisoire ; leur symbole national est un garçon qui pisse avec défi dans une flaque d'eau après tout ! Ils sont également très disposés à aider si quelqu'un a besoin d'indications ou de recommandations.</p>

<p>Si vous souhaitez visiter un pays diversifié, éducatif et culturellement important, la Belgique est votre meilleur choix. Lorsque vous serez décidé à partir n’hésitez pas à proposer vos kilos en plus sur <a href="https://www.monkolis.com" class="link">www.monkolis.com</a> afin de réduire un tant soit peu le cout de votre voyage</p>.


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}
export default Belgium;