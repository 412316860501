import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import userChris from './../../assets/img/user/userchris.jpeg';
import usertachi from './../../assets/img/user/usertachi.jpeg';
import userDemel from './../../assets/img/user/userDemel.jpeg';
import userLufred from './../../assets/img/user/userLufred.jpeg';
import userHamilton from './../../assets/img/user/userhamilton.jpeg';


import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Founders extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Découvrez notre histoire | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="funders">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/about" className="list-group-item list-group-item-action">A propos</a>
                                        <a href="/founders"
                                            className="list-group-item list-group-item-action active">Cofondateurs</a>
                                        <a href="/details"
                                            className="list-group-item list-group-item-action">Coordonnées</a>
                                        <hr />
                                        <br />
                                        <a href="/careers" className="list-group-item list-group-item-action">Carrière</a>
                                        <a href="/press" className="list-group-item list-group-item-action">Presse</a>
                                        <a href="/contact" className="list-group-item list-group-item-action">Contact</a>
                                    </div>
                                </div>

                                <div className="col-sm-9">
                                    <div className="aside section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Cofondateurs</h1>
                                            <p> Cela fait plus d'une dizaine d'années qu'on se connait et depuis les
                                            bancs de l'école d'ingénieur à Yamoussoukro jusqu'à nos bureaux
                                            respectifs à Paris, nous n'avons cessé de partager nos projets, nos
                                            ambitions... Aujourd'hui, MonKolis est le fruit de cette formidable
                                            collaboration.
                                            </p>


                                            <div className="funders-box">
                                                <div className="funder-item">
                                                    <div className="funder-item_content">
                                                        <div className="row">
                                                            <div className="funders-img col-sm-4 col-4">
                                                                <img src={userChris} alt="MDN Logo"
                                                                    className="item auto" />
                                                            </div>
                                                            <div className="col-sm-8 col-8">
                                                                <h4 className="media-heading">Chrysostom Mobio</h4>
                                                                <p className="color-text2 txt-bold">Directeur des
                                                                    produits et cofondateur</p>

                                                                <div className="funders-item_text">
                                                                    <p>Chryso est cofondateur et responsable technique
                                                                        web de MonKolis. <br /> Il
                                                                        est le moteur dans la politique d'innovation et
                                                                        garantit la disponibilité,
                                                                        ainsi que les évolutions des plateformes
                                                                        techniques des sites web et mobile.
                                                                    </p>
                                                                    <p>Ingénieur de formation diplômé de l'INPHB
                                                                    Yamoussoukro et de Centrale
                                                                    Paris, Chryso a occupé plusieurs postes de
                                                                    responsabilité dans le domaine
                                                                    du digital et des systèmes d'informations avant
                                                                        de créer MonKolis.</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="funders-box">
                                                <div className="funder-item">
                                                    <div className="funder-item_content">
                                                        <div className="row">
                                                            <div className="funders-img col-sm-4 col-4">
                                                                <img src={userDemel} alt="MDN Logo"
                                                                    className="media-object cover rounded" />
                                                            </div>
                                                            <div className="col-sm-8 col-8">
                                                                <h4 className="media-heading">Demel Yedoh</h4>
                                                                <p className="color-text2 txt-bold">Directeur de la
                                                                    stratégie et cofondateur</p>
                                                                <p>Demel est cofondateur et responsable de la stratégie
                                                                    chez MonKolis. <br /> Il joue un rôle central dans la
                                                                    mise en œuvre d'initiatives stratégiques clés pour
                                                                    le développement de nos services.</p>
                                                                <p>Ingénieur de formation diplômé de l'INPHB
                                                                Yamoussoukro et également titulaire d'un Master
                                                                Grande Ecole de HEC Paris, De Mel a occupé plusieurs
                                                                postes dans le secteur financier avant de créer
                                                                    MonKolis.</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="funders-box">
                                                <div className="funder-item">
                                                    <div className="funder-item_content">
                                                        <div className="row">
                                                            <div className="funders-img col-sm-4 col-4">
                                                                <img src={usertachi} alt="MDN Logo"
                                                                    className="media-object cover rounded" />
                                                            </div>
                                                            <div className="col-sm-8 col-8">
                                                                <h4 className="media-heading">Cyrille Fotso</h4>
                                                                <p className="color-text2 txt-bold">Responsable
                                                                    Community et cofondateur</p>
                                                                <p>Le travail de Cyrille porte sur plusieurs domaines :
                                                                élaborer la culture de l'entreprise, dessiner
                                                                l'image de la marque et travailler à une croissance
                                                                    toujours plus innovante.</p>
                                                                <p>Ingénieur de formation diplômé de l'INPHB
                                                                Yamoussoukro et également titulaire d'un Master
                                                                Grande Ecole de ESCP Europe, Cyrille a occupé
                                                                plusieurs postes dans le secteur financier avant de
                                                                créer MonKolis.
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="funders-box">
                                                <div className="funder-item">
                                                    <div className="funder-item_content">
                                                        <div className="row">
                                                            <div className="funders-img col-sm-4 col-4">
                                                                <img src={userLufred} alt="MDN Logo"
                                                                    className="media-object cover rounded" />
                                                            </div>
                                                            <div className="col-sm-8 col-8">
                                                                <h4 className="media-heading">Lufred Kouakou</h4>
                                                                <p className="color-text2 txt-bold">Lead DevOps et
                                                                    cofondateur</p>
                                                                <p> Ingénieur informatique de l'inphb auquel s'ajoute un
                                                                Master en Cybersecurity And Web Techbology de IMT
                                                                Atlantique (ex Télécom Bretagne) France, Lufred
                                                                possède une double compétence dans le monde de l'IT.
                                                                Aujourd'hui, Consultant Cloud Security and DevOps,
                                                                Il a été notamment impliqué dans des missions
                                                                variées auprès des entreprises du CAC 40, avant la
                                                                    création de MonKolis.</p>
                                                                <p>Il demeure convaincu que la transformation digitale
                                                                est le socle de tout développement au 21 eme siècle.
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <

                                                div className="funders-box">
                                                <div className="funder-item">
                                                    <div className="funder-item_content">
                                                        <div className="row">
                                                            <div className="funders-img col-sm-4 col-4">
                                                                <img src={userHamilton} alt="MDN Logo"
                                                                    className="media-object cover rounded" />
                                                            </div>
                                                            <div className="col-sm-8 col-8">
                                                                <h4 className="media-heading">Hamilton Yao</h4>
                                                                <p className="color-text2 txt-bold">Lead FullStack et
                                                                    cofondateur</p>
                                                                <p> Titulaire d'un DTS informatique de l'INPHB
                                                                Yamoussoukro et d'un Master en Développement et
                                                                Administration de Services obtenu à l'Université de
                                                                    Reims Champagne Ardenne</p>
                                                                <p>Hamilton a occupé plusieurs postes de consultant dans
                                                                le domaine du digital et des systèmes d'informations
                                                                notamment le cloud computing.
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default Founders;