import React, { Component } from 'react';
import InputRange from 'react-input-range';
import ReactPaginate from 'react-paginate';
import Moment from 'moment';
import axios from 'axios';
import { TRAVEL_ACTIONS_URL } from '../../Url';
import { GET_VERIFICATIONS_INFOS_QUERY } from '../../query/queryprofil';
import Searchform from './../../components/searchform/searchform';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/search.css';
import './../../assets/css/modal.css';
import { withRouter } from 'react-router-dom';
import PHOTO from '../../assets/img/user/user0.png';
import moment from 'moment';
import 'moment/min/locales'
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

export class TripsList extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick = value => () => {
		var tmp = JSON.stringify(value)
		let buff = new Buffer(tmp);
		let base64data = buff.toString('base64');
		localStorage.setItem('tripdetail', base64data)
		this.props.history.push({
			pathname: '../traveldetails/'+value.id_travel
		});

	};




    componentDidMount(){
		window.scrollTo(0, 0);
		/* 
		this.idToken = getCookieConsentValue('idToken');
		this.getDashboardInfos(TRAVEL_ACTIONS_URL, this.idToken); */
    }

	getDashboardInfos = async (url, idToken) => {
        try {
            const response = await axios.post(url,
                { "query": GET_VERIFICATIONS_INFOS_QUERY },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );

            //console.log(response.data)
            // Log the response so we can look at it in the console
            // Set the data to the state

            if (response.data.data.getUserDashboard.status === 200) {
                this.setState(() => ({
                    id_card_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.id_card_verified,
                    phone_number_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.phone_number_verified,
                    email_verified: response.data.data.getUserDashboard.user_dashboard === null ? '' : response.data.data.getUserDashboard.user_dashboard.verifications.email_verified,
				}));
				if (this.state.phone_number_verified === 'false' || this.state.phone_number_verified === false || this.state.phone_number_verified === '' || this.state.phone_number_verified === null || this.state.phone_number_verified === 'null' || this.state.phone_number_verified === 'undefined' || this.state.phone_number_verified === undefined) {
					alert('Merci d\'avoir enregistré votre numéro de téléphone. \nVeuillez le vérifier pour voir les trajets disponibles svp.')
					this.props.history.push({
						pathname: '/dashboard/myprofile',
						state: { pages: 'verfications' }
					});
				}
			}
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState({ error: error })
            console.log(this.state.error)
        }
    }

	render() {
		moment.locale('fr');
		var elements = this.props.data
		
		if(getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined ||  getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined'){
			elements= elements.slice(0,3);
		}
		return (
			<div class="travels">
				{elements.map((value, index) => {
					return <div key={index} >
						<div class="travel mar-bot10">
							<div class="travel-inner">
								<div class="row" onClick={this.handleClick(value)} style={{ cursor: "pointer" }}>
									<div class="col-3 col-md-4 detail1">
										<div class="media mar-bot15">
											<div class="media-left media-top">
												<div class="photo">
													{value.user_proposal_attributes.picture === null || value.user_proposal_attributes.picture === 'null'?
														<img src={PHOTO} className="user-image rounded cover" alt="User" /> :
														<img src={value.user_proposal_attributes.picture} className="user-image rounded cover" alt="User" />
													}
												
													{/* <i class="fa fa-check-square color-orange"></i> */}
												</div>
												{
													
													value.user_proposal_attributes.account_type === null || value.user_proposal_attributes.account_type === undefined || value.user_proposal_attributes.account_type === 'null' || value.user_proposal_attributes.account_type === 'undefined' || value.user_proposal_attributes.account_type === "Particulier" || value.user_proposal_attributes.account_type === "" ? '' :
													<div>
														<span class="badge badge-danger">Pro.</span>
													</div> 
												}
												{
													value.user_proposal_attributes.account_type === null || value.user_proposal_attributes.account_type === undefined || value.user_proposal_attributes.account_type === 'null' || value.user_proposal_attributes.account_type === 'undefined' || value.user_proposal_attributes.account_type === "Particulier" || value.user_proposal_attributes.account_type === "" ? 
													<h5 className="media-heading txt-bold">{value.user_proposal_attributes.given_name === null || value.user_proposal_attributes.given_name === undefined || value.user_proposal_attributes.given_name === 'undefined' || value.user_proposal_attributes.given_name === 'null' || value.user_proposal_attributes.given_name === '' ? value.user_proposal_attributes.name  : value.user_proposal_attributes.given_name}  {/* {value.user_proposal_attributes.name} */}  </h5>
													:
													<h5 className="media-heading txt-bold">{value.user_proposal_attributes.account_type_details.company_name === null || value.user_proposal_attributes.account_type_details.company_name === undefined || value.user_proposal_attributes.account_type_details.company_name === 'undefined' || value.user_proposal_attributes.account_type_details.company_name === 'null' || value.user_proposal_attributes.account_type_details.company_name === '' ? value.user_proposal_attributes.name  : value.user_proposal_attributes.account_type_details.company_name}  </h5>
												}
												{value.user_proposal_attributes.city_of_residence === null || value.user_proposal_attributes.city_of_residence === undefined || value.user_proposal_attributes.city_of_residence === "null" || value.user_proposal_attributes.city_of_residence === "undefined" || value.user_proposal_attributes.city_of_residence === "" ? "": <p className="media-heading color-text2">{value.user_proposal_attributes.city_of_residence}</p>}
											</div>
											<div class="media-body">
											{
													value.user_proposal_attributes.account_type === null || value.user_proposal_attributes.account_type === undefined || value.user_proposal_attributes.account_type === 'null' || value.user_proposal_attributes.account_type === 'undefined' || value.user_proposal_attributes.account_type === "Particulier" || value.user_proposal_attributes.account_type === "" ? 
													<h5 class="media-heading"> {/* {value.user_proposal_attributes.given_name}  */} {/* {value.user_proposal_attributes.name}  */}  {value.user_proposal_attributes.given_name === null || value.user_proposal_attributes.given_name === undefined || value.user_proposal_attributes.given_name === 'undefined' || value.user_proposal_attributes.given_name === 'null' || value.user_proposal_attributes.given_name === ''  ? value.user_proposal_attributes.name  : value.user_proposal_attributes.given_name}  </h5>
													:
													<h5 className="media-heading txt-bold">{value.user_proposal_attributes.account_type_details.company_name === null || value.user_proposal_attributes.account_type_details.company_name === undefined || value.user_proposal_attributes.account_type_details.company_name === 'undefined' || value.user_proposal_attributes.account_type_details.company_name === 'null' || value.user_proposal_attributes.account_type_details.company_name === '' ? value.user_proposal_attributes.name  : value.user_proposal_attributes.account_type_details.company_name}  </h5>
												}
												{value.user_proposal_attributes.city_of_residence === null || value.user_proposal_attributes.city_of_residence === undefined || value.user_proposal_attributes.city_of_residence === "null" || value.user_proposal_attributes.city_of_residence === "undefined" || value.user_proposal_attributes.city_of_residence === "" ? "": <p class="media-heading color-text2">{value.user_proposal_attributes.city_of_residence}</p>}
												{/* <p class="color-blue mar-bot0">Pièce vérifiée</p> */}
											</div>
										</div>
									</div>
									<div class="col-sm-5 col-6 detail2">
										<h5 class="media-heading txt-bold">{Moment(value.departure_date).format('DD MMM, YYYY')}</h5>
										<p class="txt-bold">
											{value.transportation_type === null ?
												<span className="fa fa-1x fa-plane icon"></span> :
												null
											}
											{value.transportation_type === "Avion" ?
												<span className="fa fa-1x fa-plane icon"></span> :
												null
											}
											{value.transportation_type === "Bâteau" ?
												<span className="fa fa-1x fa-ship icon"></span> :
												null
											}
											{value.transportation_type === "Voiture" ?
												<span className="fa fa-1x fa-car icon"></span> :
												null
											}
											{value.transportation_type === "Train" ?
												<span className="fa fa-1x fa-train icon"></span> :
												null
											}<span className="travel_plan">{value.departure_city}</span> &nbsp;&nbsp;<i className="fa fa-long-arrow-right color-text2"></i>&nbsp;&nbsp;<span className="travel_plan">{value.arrival_city}&nbsp;&nbsp;</span>
											{ value.transportation_condition !== null ||  value.transportation_condition !== undefined || value.transportation_condition !== "null" || value.transportation_condition !== "undefined"  || value.transportation_condition !== "" ? value.transportation_condition === "Fret" ? <p className="txt-bold d-none d-sm-inline"><span className="color-green"><i className="fa fa-ticket color-yellow"></i>&nbsp; Par Fret</span></p>: value.has_check_ticket === "Y" ? <p className="txt-bold d-none d-sm-inline"><span className="color-green"><i className="fa fa-ticket color-yellow"></i>&nbsp; Billet vérifié</span></p>: "" : ""}
										</p>
										<p className="mar-bot5" data-toggle="tooltip" title="Date limite de réservation"><i className="fa fa-clock-o color-orange"></i><span className="color-text2 txt-bold"> {moment(value.booking_deadline).format('DD/MM/YYYY LT')}</span></p>
										<p className="mar-bot0 deliver" data-toggle="tooltip" Title="Lieu de rencontre pour déposer le kolis"><i className="fa fa-map-marker color-green"></i><span className="color-text2"> {value.take_off_place}</span></p>
										<p className="mar-bot0 deliver" data-toggle="tooltip" Title="Lieu de rencontre pour livrer le kolis"><i className="fa fa-map-marker color-blue"></i><span className="color-text2"> {value.recovery_place}</span></p>
										
									</div>
									<div className="col-md-3 col-3 detail3 align-right">
										<span className="value_amount"><span> {parseFloat(value.service_charge_amount).toFixed(2)} €</span></span>
										<p ><span>Par {value.measure_unit !== null ? value.measure_unit : 'par Kg'} {value.volume !== null & value.volume !== '0' ? '(' + value.volume + ' litres )' : ''}</span></p>
										<p className="mar-bot0"><span className="weight">{value.weigth_available_info.weigth_available === null ? "0" : value.weigth_available_info.weigth_available}</span> <span className="txt-bold">{value.measure_unit !== null ? value.measure_unit + '(s)' : 'Kg(s)'} restant(s)</span></p>
										{value.is_automatic_approval === 'Y' ? <p class="mar-bot0"><span class="">{parseInt(value.min_weight_booking)!== 1 ? <i className="fa fa-2x fa-info-circle color-yellow" data-toggle="tooltip" Title={"Le voyageur exige de réserver au moins "+ parseInt(value.min_weight_booking) + " " + value.measure_unit}></i> : '' }<i className="fa fa-2x fa-flash color-yellow" data-toggle="tooltip" Title="La réservation est acceptéé automatiquement"></i></span></p> : null}
									</div>
								</div>
								{/* </Card>*/}
							</div>
						</div>
					</div>

				})
				}
				{ getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined ||  getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? <div style={{ display: 'flex', justifyContent: 'center' }}> <a href='/connexion' className="btn btn-outline-primary mar-top20"> ...Plus de résultats  </a> </div> : <br />}
			</div >
		);
	}
}




class Trips extends Component {
	constructor(props) {
		super(props);
		const nbeElementOfPage = 10
		const offsetInitial = 0
		this.departure_city = this.props.location.state !== undefined ? this.props.location.state.departure_city : ''
		this.arrival_city = this.props.location.state !== undefined ? this.props.location.state.arrival_city : ''
		this.departure_date = this.props.location.state !== undefined ? this.props.location.state.departure_date : ''
		this.nbrTotal = this.props.location.state.items.searchTravel.travel_proposals.length

		this.state = {
			pageCount: Math.ceil(this.props.location.state.items.searchTravel.travel_proposals.length / nbeElementOfPage),
			departure_date_period_start: '',
			departure_date_period_end: '',
			departure_city: '',
			arrival_city: '',
			proposal_amount_by_kg: '',
			has_check_ticket: 'N',
			is_automatic_approval: 'N',
			offset: offsetInitial,
			perPage: nbeElementOfPage,
			currentPage: 0,
			data: this.props.location.state.items.searchTravel.travel_proposals.length > nbeElementOfPage ? this.props.location.state.items.searchTravel.travel_proposals.slice(offsetInitial, nbeElementOfPage) : this.props.location.state.items.searchTravel.travel_proposals,
			elements: this.props.location.state.items.searchTravel.travel_proposals,
			error: null,
			isLoaded: false,
			checkedAutoApproove: false,
			checkedBillet: false,
			value: { min: 2, max: 10 },
			dataFilter: this.props.location.state.items.searchTravel.travel_proposals,
			transportation_type: '',
			transportation_condition : '',
			measure_unit: '',
			proposal_amount_by_kg: ''
			


		};


        /*
        TODO: Initialize the state property here; initialize the string values to
        empty strings, and the date values to null.
        */

		this.handledepartureStart = this.handledepartureStart.bind(this);
		this.handledepartureEnd = this.handledepartureEnd.bind(this);
		this.handledepartureCity = this.handlearrivalCity.bind(this);
		this.handlearrivalCity = this.handlearrivalCity.bind(this);

		this.handlehasCheckTicket = this.handlehasCheckTicket.bind(this);
		this.handleisautomaticApproval = this.handleisautomaticApproval.bind(this);

		/* this.handlePieceVerifie = this.handlePieceVerifie.bind(this); */
		this.handleBilletVerifie = this.handleBilletVerifie.bind(this);
		this.handleAccepationAuto = this.handleAccepationAuto.bind(this);
		this.handleChangeKilograme = this.handleChangeKilograme.bind(this);
		this.handleManagedDataFilter = this.handleManagedDataFilter.bind(this);

		this.handleTransportationFilter = this.handleTransportationFilter.bind(this);
		this.handleMeasure_unitFilter = this.handleMeasure_unitFilter.bind(this);
		this.handlePrixMaxFilter = this.handlePrixMaxFilter.bind(this);
		this.handleTransportationConditionFilter = this.handleTransportationConditionFilter.bind(this)
	}

	componentDidMount() {
		localStorage.setItem('transportation_type', 'Tous')
		localStorage.setItem('measure_unit', 'Tous')
		localStorage.setItem('proposal_amount_by_kg', 'Tous')
		localStorage.setItem('has_check_ticket', 'N')
		localStorage.setItem('is_automatic_approval', 'N')
		localStorage.setItem('transportation_condition', 'Tous')
	}

	handlePageChange(pageNumber) {
		this.setState({ activePage: pageNumber });
	}

	handledepartureStart(event) {
		this.setState({ departure_date_period_start: event.target.value });
	}

	handledepartureEnd(event) {
		this.setState({ departure_date_period_end: event.target.value });
	}

	handledepartureCity(event) {
		this.setState({ departure_city: event.target.value });
	}

	handlearrivalCity(event) {
		this.setState({ arrival_city: event.target.value });
	}




	handlehasCheckTicket(event) {
		this.setState({ has_check_ticket: event.target.value });
	}

	handleisautomaticApproval(event) {
		this.setState({ is_automatic_approval: event.target.value });
	}



	handleTransportationFilter(event) {
		this.setState({ transportation_type: event.target.value });
		localStorage.setItem('transportation_type', event.target.value)
		this.handleManagedDataFilter();
	}

	handleTransportationConditionFilter(event) {
		this.setState({ transportation_condition: event.target.value });
		localStorage.setItem('transportation_condition', event.target.value)
		this.handleManagedDataFilter();
	}

	handleMeasure_unitFilter(event) {
		this.setState({ measure_unit: event.target.value });
		localStorage.setItem('measure_unit', event.target.value)
		this.handleManagedDataFilter();
	}

	handlePrixMaxFilter(event) {
		this.setState({ proposal_amount_by_kg: event.target.value });
		localStorage.setItem('proposal_amount_by_kg', event.target.value)
		this.handleManagedDataFilter();

	}



	handleBilletVerifie(event) {
		if (event.target.checked) {
			localStorage.setItem('has_check_ticket', 'Y')
		} else {
			localStorage.setItem('has_check_ticket', 'N')
		}
		this.handleManagedDataFilter();
	}

	handleAccepationAuto(event) {
		if (event.target.checked) {
			localStorage.setItem('is_automatic_approval', 'Y')
		} else {
			localStorage.setItem('is_automatic_approval', 'N')
		}
		this.handleManagedDataFilter();
	}


	handleManagedDataFilter() {
		var nbre_element_par_page = 10;
		// initialise
		var df = this.props.location.state.items.searchTravel.travel_proposals;
		// Tu fais ensuite calculs
		if (localStorage.getItem('has_check_ticket') === 'Y' && localStorage.getItem('is_automatic_approval') === 'Y') {
			df = this.props.location.state.items.searchTravel.travel_proposals.filter(item => item.has_check_ticket === 'Y' && item.is_automatic_approval === 'Y');
			df = this.filterDataSelected(df)


		} else if (localStorage.getItem('has_check_ticket') === 'Y' && localStorage.getItem('is_automatic_approval') === 'N') {

			df = this.props.location.state.items.searchTravel.travel_proposals.filter(item => item.has_check_ticket === 'Y');
			df = this.filterDataSelected(df)

		} else if (localStorage.getItem('has_check_ticket') === 'N' && localStorage.getItem('is_automatic_approval') === 'Y') {

			df = this.props.location.state.items.searchTravel.travel_proposals.filter(item => item.is_automatic_approval === 'Y');
			df = this.filterDataSelected(df)

		} else if (localStorage.getItem('has_check_ticket') === 'N' && localStorage.getItem('is_automatic_approval') === 'N') {

			df = this.props.location.state.items.searchTravel.travel_proposals;
			df = this.filterDataSelected(df)

		}
		else {
			df = this.filterDataSelected(df)
		}
		// ensuite tu les mets dans des stats
		this.setState(() => ({
			dataFiler: df,
			data: df.length > nbre_element_par_page ? df.slice(0, nbre_element_par_page) : df,
			pageCount: Math.ceil(df.length / nbre_element_par_page)
		}));

	}

	filterDataSelected(df) {
		var dfs = df
		// Selected:  transportation_type , measure_unit ,transportation_condition ,proposal_amount_by_kg
		// Not Selected:
		if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous' && 
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') !== 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type') &&
				item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit') &&
				item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg')) &&
				item.transportation_condition !== null && item.transportation_condition === localStorage.getItem('transportation_condition')
			);
			this.nbrTotal = dfs.length

		}
         // Selected:  transportation_type   OK
		// Not Selected: measure_unit ,transportation_condition ,proposal_amount_by_kg
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') === 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') === 'Tous'&&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') === 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type')
			);
			this.nbrTotal = dfs.length	

		}
		// Selected:  transportation_type ,transportation_condition, proposal_amount_by_kg OK
		// Not Selected: measure_unit
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') === 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous'&&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') !== 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type') &&
				item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg')) &&
				item.transportation_condition !== null && item.transportation_condition === localStorage.getItem('transportation_condition')
			);
			this.nbrTotal = dfs.length
			

		}
		// Selected:  transportation_type ,measure_unit,transportation_condition OK
		// Not Selected: proposal_amount_by_kg
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') === 'Tous'&&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') !== 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type')&&
			item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit') &&
			item.transportation_condition !== null && item.transportation_condition === localStorage.getItem('transportation_condition')
			);
			this.nbrTotal = dfs.length	

		}
		// Selected:  transportation_type ,measure_unit,proposal_amount_by_kg OK
		// Not Selected: transportation_condition
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous'&&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') === 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type')&&
				item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit') &&
				item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg')) 
			);
			this.nbrTotal = dfs.length	

		}
		
		// Selected:  transportation_type ,measure_unit, OK
		// Not Selected: transportation_condition,proposal_amount_by_kg
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') === 'Tous'&&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') === 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type')&&
			item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit')
			);
			this.nbrTotal = dfs.length	

		}
        // Selected:  transportation_type ,proposal_amount_by_kg OK
		// Not Selected: transportation_condition,measure_unit
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') === 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous'&&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') === 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type')&&
			item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg'))
			);
			this.nbrTotal = dfs.length	

		}
        // Selected:  transportation_type ,transportation_condition OK
		// Not Selected: ,measure_unit , proposal_amount_by_kg
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') === 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') === 'Tous'&&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') !== 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type')&&
			item.transportation_condition !== null && item.transportation_condition === localStorage.getItem('transportation_condition')
			);
			this.nbrTotal = dfs.length	

		}


		// Selected:   measure_unit
		// Not Selected: transportation_type ,transportation_condition ,proposal_amount_by_kg OK
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') === 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') === 'Tous' &&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') === 'Tous') {
			dfs = dfs.filter(item => item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit')
			);
			this.nbrTotal = dfs.length

		}
		

		// Selected:   measure_unit ,transportation_condition,proposal_amount_by_kg OK
		// Not Selected: transportation_type
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') === 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous' &&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') !== 'Tous') {
			dfs = dfs.filter(item => item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit')&&
			item.transportation_condition !== null && item.transportation_condition === localStorage.getItem('transportation_condition') &&
			item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg'))
			);
			this.nbrTotal = dfs.length

		}

		

		// Selected:   measure_unit ,transportation_condition OK
		// Not Selected: transportation_type,proposal_amount_by_kg
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') === 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') === 'Tous' &&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') !== 'Tous') {
			dfs = dfs.filter(item => item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit') &&
			item.transportation_condition !== null && item.transportation_condition === localStorage.getItem('transportation_condition')
			);
			this.nbrTotal = dfs.length

		}
		// Selected:   measure_unit,proposal_amount_by_kg OK
		// Not Selected: transportation_type,transportation_condition
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') === 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous' &&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') === 'Tous') {
			dfs = dfs.filter(item => item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit') &&
			item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg'))
			);
			this.nbrTotal = dfs.length

		}

		// Selected:   transportation_condition,proposal_amount_by_kg OK
		// Not Selected: transportation_type,measure_unit
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') === 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') === 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous' &&
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') !== 'Tous') {
			dfs = dfs.filter(item => item.transportation_condition !== null && item.transportation_condition === localStorage.getItem('transportation_condition') &&
			item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg')
			));
			this.nbrTotal = dfs.length

		}



		// Selected:   proposal_amount_by_kg OK
		// Not Selected: transportation_type ,transportation_condition ,measure_unit
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') === 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') === 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous' && 
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') === 'Tous') {
			dfs = dfs.filter(item => item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg'))
			);
			this.nbrTotal = dfs.length

		}


		// Selected:    transportation_condition 
		// Not Selected: transportation_type ,measure_unit,proposal_amount_by_kg OK
		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') === 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') === 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') === 'Tous' && 
			localStorage.getItem('transportation_condition') !== null && localStorage.getItem('transportation_condition') !== 'Tous') {
			dfs = dfs.filter(item => item.transportation_condition !== null && item.transportation_condition === localStorage.getItem('transportation_condition')
			);
			this.nbrTotal = dfs.length

		}


		/* else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') === 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type') &&
				item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit')
			);
			this.nbrTotal = dfs.length

		}

		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') !== 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') === 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous') {
			dfs = dfs.filter(item => item.transportation_type !== null && item.transportation_type === localStorage.getItem('transportation_type') &&
				item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg'))
			);
			this.nbrTotal = dfs.length

		}

		else if (localStorage.getItem('transportation_type') !== null && localStorage.getItem('transportation_type') === 'Tous' &&
			localStorage.getItem('measure_unit') !== null && localStorage.getItem('measure_unit') !== 'Tous' &&
			localStorage.getItem('proposal_amount_by_kg') !== null && localStorage.getItem('proposal_amount_by_kg') !== 'Tous') {
			dfs = dfs.filter(item => item.proposal_amount_by_kg !== null && parseFloat(item.proposal_amount_by_kg) <= parseFloat(localStorage.getItem('proposal_amount_by_kg')) &&
				item.measure_unit !== null && item.measure_unit === localStorage.getItem('measure_unit')
			);
			this.nbrTotal = dfs.length 

		}*/
		else{
			this.nbrTotal = this.props.location.state.items.searchTravel.travel_proposals.length
		}

		return dfs

	}


	/*
		handleManagedDataFilter() {
			var nbre_element_par_page = 5;
			// initialise
			var df = this.props.location.state.items.searchTravel.travel_proposals;
			// Tu fais ensuite calculs
			if (this.state.has_check_ticket === 'Y' && this.state.is_automatic_approval === 'Y') {
				df = this.props.location.state.items.searchTravel.travel_proposals.filter(item => item.has_check_ticket === 'Y' && item.is_automatic_approval === 'Y');

			} else if (this.state.has_check_ticket === 'Y' && this.state.is_automatic_approval === 'N') {

				df = this.props.location.state.items.searchTravel.travel_proposals.filter(item => item.has_check_ticket === 'Y');

			} else if (this.state.has_check_ticket === 'N' && this.state.is_automatic_approval === 'Y') {

				df = this.props.location.state.items.searchTravel.travel_proposals.filter(item => item.is_automatic_approval === 'Y');

			} else if (this.state.has_check_ticket === 'N' && this.state.is_automatic_approval === 'N') {

				df = this.props.location.state.items.searchTravel.travel_proposals;

			}
			console.log(df)
			console.log(this.state.is_automatic_approval)
			console.log(this.state.has_check_ticket)
			// ensuite tu les mets dans des stats
			this.setState(() => ({
				dataFiler: df,
				data: df.length > nbre_element_par_page ? df.slice(0, nbre_element_par_page) : df,
				pageCount: Math.ceil(df.length / nbre_element_par_page)
			}));

		} */

	/* handleBilletVerifie(event) {

		if (event.target.checked) {
			console.log(event.target.checked)
			this.setState({ checkedBillet: !this.state.checkedBillet });
			console.log(this.state.data)
			this.state.has_check_ticket = 'Y'
			var df = this.state.dataFilter
			//var df = this.props.location.state.items.searchTravel.travel_proposals
			df = df.filter(item => item.has_check_ticket === this.state.has_check_ticket)
			var pc = Math.ceil(df.length / 5)
			this.setState(() => ({
				dataFilter: df,
				pageCount : pc,
				data :df.length > 5 ? df.slice(0, 5) : df
			}));
			console.log(this.state.data)
		}
		else {
			console.log(event.target.checked)
			//const df = this.props.location.state.items.searchTravel.travel_proposals
			const df = this.state.dataFilter
			df = df.filter(item => item.has_check_ticket === this.state.has_check_ticket)
			console.log(this.state.dataFilter)
			const pc = Math.ceil(df.length / 5)
			console.log(pc)
			//this.state.data = df.length > 5 ? this.state.dataFilter.slice(0, 5) : df
			this.setState(() => ({
				dataFilter: df,
				pageCount : pc,
				data : df.length > 5 ? df.slice(0, 5) : df
			}));
			console.log(this.state.data)
		}


	}

	handleAccepationAuto(event) {
		if (event.target.checked) {
			console.log(event.target.checked)
			this.setState({ checkedAutoApproove: !this.state.checkedAutoApproove });
			console.log(this.state.data)
			this.state.is_automatic_approval = 'Y'
			var df = this.props.location.state.items.searchTravel.travel_proposals
			df = df.filter(item => item.is_automatic_approval === this.state.is_automatic_approval)
			var pc = Math.ceil(df.length / 5)
			this.setState(() => ({
				dataFilter: df,
				pageCount : pc,
				data :df.length > 5 ? df.slice(0, 5) : df
			}));
			console.log(this.state.data)
		}
		else {

			console.log(event.target.checked)
			const df = this.props.location.state.items.searchTravel.travel_proposals
			console.log(this.state.dataFilter)
			const pc = Math.ceil(df.length / 5)
			console.log(pc)
			//this.state.data = df.length > 5 ? this.state.dataFilter.slice(0, 5) : df
			this.setState(() => ({
				dataFilter: df,
				pageCount : pc,
				data : df.length > 5 ? df.slice(0, 5) : df
			}));
			console.log(this.state.data)
		}
	}
 */
	handleChangeKilograme(event) {
		this.setState({ value: event.target.value });
	}


	/* handleClick = value => () => {
		const someValue = this.props.location.state.items.searchTravel.travel_proposals
		const resultat = someValue.find(fruit => fruit.id_travel === value)
		this.props.history.push({
			pathname: '../traveldetails',
			data: resultat
		});

	}; */

	loadTravelData() {
		//const elements = this.props.location.state.items.searchTravel.travel_proposals;
		const elements = this.state.dataFilter;

		this.setState({
			data: elements.slice(this.state.offset, this.state.offset + this.state.perPage),
			pageCount: Math.ceil(elements.length / this.state.perPage)
		});

	}

	handlePageClick = data => {
		let selected = data.selected;
		let offset = selected * this.state.perPage;

		this.setState({ currentPage: selected, offset: offset }, () => {
			this.loadTravelData();
		});
	};

	render() {
		
		return (
			<div class="trips">
				<div className="main-box_content">
				<div class="container-fluid container-fluid-pad container-fluid-br">

					<div class="pad-top10">
						<div class="formulaire">
							<Searchform />
						</div>
					</div>
					<div class="row">
						<div class="col-sm-3">
							{/*  debut Card pour pièce vérifié, billet vérifié  */}
							<div class="about section">

								<div class="section-inner">
								<div class="form-group">
										<label for="moyenTri">Type de transport : </label>
										<select class="form-control" id="moyenTri" value={this.state.transportation_condition} onChange={this.handleTransportationConditionFilter} >
											<option value='Tous'>Tous</option>
											<option value='Voyage personnel'>Voyage personnel</option>
											<option value='Fret'>Fret</option>
										</select>
									</div>

									<div class="form-group">
										<label for="moyenTri">Moyen de transport : </label>
										<select class="form-control" id="moyenTri" value={this.state.transportation_type} onChange={this.handleTransportationFilter} >
											<option value='Tous'>Tous</option>
											<option value='Avion'>Avion</option>
											<option value='Bâteau'>Bâteau</option>
											<option value='Voiture'>Voiture</option>
											<option value='Train'>Train</option>
										</select>
									</div>

									<div class="form-group">
										<label for="uniTri">Unité : </label>
										<select class="form-control" id="uniTri" value={this.state.measure_unit} onChange={this.handleMeasure_unitFilter}>
											<option value='Tous'>Tous</option>
											<option value='Kg'>Kg</option>
											<option value='Barrique'>Barrique</option>
											<option value='Carton'>Carton</option>
										</select>
									</div>

									<div class="form-group">
										<label for="prixTri">Prix max (€/unité) : </label>
										<select class="form-control" id="prxTri" value={this.state.proposal_amount_by_kg} onChange={this.handlePrixMaxFilter}>
											<option value='Tous'>Tous</option>
											<option value='5'>5</option>
											<option value='6'>6</option>
											<option value='7'>7</option>
											<option value='8'>8</option>
											<option value='9'>9</option>
											<option value='10'>10</option>
											<option value='15'>15</option>
											<option value='20'>20</option>
											<option value='30'>30</option>

										</select>
									</div>
								</div>

								<form>
									<div class="section-inner">
										<div class="mar-bot10">
											<input id="checkbox6" type="checkbox" class="magic-checkbox" onChange={this.handleBilletVerifie} defaultChecked={this.state.checkedBillet} />
											<label for="checkbox6"> Billet vérifié	</label>
										</div>
										<hr />
										<div class="mar-bot0">
											<input id="checkbox7" type="checkbox" class="magic-checkbox" onChange={this.handleAccepationAuto} defaultChecked={this.state.checkedAutoApproove} />
											<label for="checkbox7"> Acceptation	automatique</label>
										</div>
									</div>
								</form>
							</div>
						</div>
						{/*  Fin Card pour pièce vérifié, billet vérifié  */}

						<div class="col-sm-9">
							<div class="alert alert-light order">
								<div class="row" >
									<div class="available">
										<h4 class="mar-bot0"><span> {this.nbrTotal}</span><span> disponibles</span></h4>
									</div>
									{/* <div class="col-sm-4">
										<div class=" float-left">
											<h6 class="mar-top5 txt-light"> Trier par</h6>
										</div>
										<div class="btn-group float-right">

											<div class="btn-group">
												<button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
													<span class="fa fa-eur color-green"></span> <span class="fa fa-caret-down color-green"></span>
												</button>
												<div class="dropdown-menu">
													<a class="dropdown-item" href="#"><span class="fa fa-arrow-down color-green"> Prix décroissant</span></a>
													<a class="dropdown-item" href="#"><span class="fa fa-arrow-up color-green"> Prix croissant</span></a>
												</div>
											</div>


											<div class="btn-group">
												<button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
													<span class="fa fa-calendar color-blue"></span><span class="fa fa-caret-down color-blue"></span>
												</button>
												<div class="dropdown-menu">
													<a class="dropdown-item" href="#"><span class="fa fa-arrow-down color-blue"> Date décroissante</span></a>
													<a class="dropdown-item" href="#"><span class="fa fa-arrow-up color-blue"> Date croissante</span></a>
												</div>
											</div>

										</div>
									</div> */}
								</div>
							</div>


							{/*  debut Card for every travel   */}




							{/* Fin Card for every travel  */}


							{
								getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined ||  getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? 
								<div class="mar-bot20 mar-top20">
								<TripsList history={this.props.history} data={this.state.data} />
								<ReactPaginate
									previousLabel={'«'}
									nextLabel={'»'}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={1}
									marginPagesDisplayed={2}
									pageRangeDisplayed={10}
									onPageChange={this.handlePageClick}
									containerClassName={'pagination float-right'}
									pageClassName={'page-item'}
									pageLinkClassName={'page-link'}
									nextClassName={'page-item'}
									previousClassName={'page-item'}
									previousLinkClassName={'page-link'}
									nextLinkClassName={'page-link'}
									activeClassName={'active'}
									disabledClassName={'desabled'}
								/>
								</div>
								: 
								<div class="mar-bot20 mar-top20">
								<TripsList history={this.props.history} data={this.state.data} />
								<ReactPaginate
									previousLabel={'«'}
									nextLabel={'»'}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={this.state.pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={1}
									onPageChange={this.handlePageClick}
									containerClassName={'pagination float-right'}
									pageClassName={'page-item'}
									pageLinkClassName={'page-link'}
									nextClassName={'page-item'}
									previousClassName={'page-item'}
									previousLinkClassName={'page-link'}
									nextLinkClassName={'page-link'}
									activeClassName={'active'}
									disabledClassName={'desabled'}
								/>
							</div>}
						</div>
					</div>
					</div>
				</div>

			</div>
		)
	}
}
export default withRouter(Trips, TripsList);