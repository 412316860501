import React, { Component } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

import { withRouter, Redirect } from 'react-router-dom';

class Resiliations extends Component {
  render() {
    return (
      <div className="faq-resiliation">
      	<div class="section">
              <div class="section-inner">
                  <h3 class="heading" id="top">Annulation &amp; Absence</h3>


                <a href="#1" class="link color-blue">→ En tant que transporteur</a><br/>
                <a href="#2" class="link color-blue">→ En tant que expéditeur</a><br/>
                <a href="#3" class="link color-blue">→ Quel est le délai de traitement d’une réclamation ?</a><br/><br/>

              <h4 class="" id="1">En tant que transporteur <br/></h4>
              <p>

            <span class="txt-bold">	Je n’arrive pas à joindre un expéditeur</span>	<br/>
            Vous n’arrivez pas à joindre l’une des personnes qui doit vous remettre un kolis ? Vérifiez tout d’abord que vous avez le bon numéro de téléphone.
            Nous vous l’avons envoyé par e-mail et/ou SMS quand vous avez accepté sa réservation.
            <br/>
            <br/>
            C’est le bon numéro mais vous n’avez pas de réponse ? Essayez la messagerie MonKolis. Rendez-vous sur <a href="../dashboard/my-trips.php" class="link">vos trajets</a> et choisissez l'expéditeur que vous voulez contacter. Depuis cette page, vous pourrez lui envoyer un message.
            <br/>
            <br/>

            Toujours pas de nouvelles ? S’il reste encore quelques jours avant le départ, attendez un peu qu’il ou elle vous recontacte. Certaines personnes ont des rythmes différents, d’autres sont en vacances et ne vérifient leur téléphone que tard le soir.
            <br/>
            <br/>
            Si l’heure du départ approche et que vous pensez que vous n’aurez pas de nouvelles, vous pouvez annuler sa réservation en indiquant qu’il ou elle n’était pas joignable. Les unités réservées seront alors à nouveau disponibles dans les résultats de recherche.
            <br/>
            <br/>
            <span class="txt-bold">	Je souhaite annuler un trajet.</span>	<br/>

            Annuler un trajet ne prend qu’une minute. Bien entendu, c’est encore plus rapide si vous n’avez pas déjà accepté des réservations, mais pas d’inquiétude, cela reste néanmoins possible. Rendez-vous sur la page <a href="../dashboard/my-trips.php" class="link">vos trajets</a> et sélectionnez celui que vous souhaitez annuler.
            <br/>
            <br/>
            Choisissez l’une des raisons proposées et prenez quelques instants pour nous expliquer les raisons de votre annulation (seule notre équipe aura accès à ces détails). Devoir annuler un trajet est tout à fait compréhensible, mais afin de respecter les liens de confiance qui tissent notre communauté, informez au plus vite vos passagers de votre décision pour qu’ils aient le temps de réserver un autre trajet. Ceux-ci recevront également un e-mail et un SMS de notre part afin de confirmer l’annulation.
            <br/>
            <br/>
            Vous ne serez pas pénalisé à cause d’une annulation. Toutefois, il peut nous arriver d’empêcher la publication de nouveaux trajets à des transporteurs ayant pour habitude d’annuler leur trajet à la dernière minute, ceci afin de préserver les valeurs de confiance et de fiabilité qui caractérisent la communauté MonKolis.


            <br/>
            <br/>
            <span class="txt-bold">	Que se passe-t-il si un expéditeur annule à la dernière minute ou ne se présente pas ?</span>	<br/>

            Une réservation annulée au dernier moment, c’est embêtant. C’est pourquoi nous proposons une compensation financière pour les annulations de dernière minute.
            Et si votre expéiteur vous pose un lapin, vous pouvez ouvrir une réclamation depuis votre trajet. Pour cela, sélectionnez la personne qui ne vous a pas remis son kolis et indiquez que vous n’avez pas tranporté son kolis.
            <br/>
          <br/>

            Selon les cas, voici les modalités de compensation :
              <br/>
            <br/>
            - Que se passe-t-il si un expéditeur annule plus de 72 heures avant l’heure limite de réservation ?


                              <br/>

            Les unitées réservées apparaîssent de nouveau dans les résultats de recherche mais nous ne vous versons pas le montant de sa réservation car il vous reste suffisamment de temps pour avoir de nouvelles réservations.


                              <br/>
                              <br/>

            - Que se passe-t-il si un expéditeur annule entre 72 heures et 24 heures avant l’heure limite de réservation ?


                              <br/>
            Les unitées réservées apparaîssent de nouveau dans les résultats de recherche et nous vous versons 50% du montant de sa réservation même si les unités sont réservées par un autre membre.


                              <br/>
                              <br/>
            - Que se passe-t-il si un expéditeur annule moins de 24 heures avant l’heure limite de réservation ?


                              <br/>
            Les unitées réservées apparaîssent de nouveau dans les résultats de recherche et nous vous versons la totalité du montant de sa réservation à titre de dédommagement.


                              <br/>
                              <br/>
            - Que se passe-t-il si l'expéditeur ne se présente pas au rendez-vous de dépôt du kolis?


                              <br/>
          Une fois la réclamation ouverte, notre équipe s’occupe de tout. L'expéditeur dispose de quelques jours pour confirmer ou contredire votre réclamation.
            Si tout le monde est d’accord, vous recevrez le paiement complet, à titre de dédommagement.
            <br/>
            <br/>
          </p>
          <a href="#top"class="link color-text2">Remonter</a> <br/><br/>

            <h4 class="" id="2">En tant que expéditeur <br/></h4>
            <p>
              <span class="txt-bold">Je n’arrive pas à joindre le transporteur</span>	<br/>
              Vous n’arrivez pas à joindre le transporteur ? Vérifiez tout d’abord que vous avez le bon numéro de téléphone.
              Nous vous l’avons envoyé par e-mail et/ou SMS quand vous avez accepté sa réservation.
              <br/>
              <br/>
              C’est le bon numéro mais vous n’avez pas de réponse ? Essayez la messagerie MonKolis. Rendez-vous sur <a href="../dashboard/my-bookings.php" class="link">vos expéditions</a> et choisissez le transporteur que vous voulez contacter. Depuis cette page, vous pourrez lui envoyer un message.
              <br/>
              <br/>

              Toujours pas de nouvelles ? S’il reste encore quelques jours avant le départ, attendez un peu qu’il ou elle vous recontacte. Certaines personnes ont des rythmes différents, d’autres sont en vacances et ne vérifient leur téléphone que tard le soir.
              <br/>
              <br/>
              Si l’heure du départ approche et que vous pensez que vous n’aurez pas de nouvelles, vous pouvez annuler sa réservation en indiquant qu’il ou elle n’était pas joignable.
              <br/>
              <br/>
              <span class="txt-bold">Je souhaite annuler ma réservation.</span>	<br/>

              Pour annuler une réservation, rendez-vous sur la page de <a href="../dashboard/my-bookings.php" class="link">vos expéditions</a>.
              <br/>
              <br/>
              Devoir annuler une réservation est tout à fait compréhensible. Pensez seulement à le faire dans les plus brefs délais afin de ne pas pénaliser le transporteur. Le montant de votre remboursement dépendra du moment de l’annulation.
              <br/>
              <br/>
              Les modalités de remboursement faisant suite à une annulation sont les suivantes :
              <br/>
              <br/>
              J’annule ma réservation plus de 72 heures avant l'heure limite de reservation ;<br/>
              Nous vous remboursons le montant de votre réservation exceptés les frais de réservation.

              <br/>
              <br/>

              J’annule ma réservation moins de 72 heures et plus de 24 heures avant l'heure limite de reservation ;<br/>
              Nous vous remboursons 50 % du montant de votre réservation exceptés les frais de réservation.
              <br/>
              <br/>

              J’annule ma réservation moins de 24 heures avant l'heure limite de reservation ;<br/>
              Aucun remboursement n'est effectué pour ne pas pénaliser le transporteur.
              <br/>
              <br/>


              Je ne me présente pas le jour du départ
              Nous verserons au transporteur l’intégralité du montant de votre réservation.
              <br/>
              <br/>


              Nous avons mis en place cette politique d’annulation afin d’être le plus juste possible avec les transporteurs et les expéditeurs.
              Lorsque vous annulez votre réservation tradivement, il est très difficile pour le transporteur de trouver un autre expéditeur pour participer aux frais du transport.
              C’est pour cette raison que nous ne vous remboursons pas toujours intégralement.

              <br/>
              <br/>

              Quoiqu’il en soit, il est essentiel que vous préveniez le transporteur dès que vous aurez pris votre décision, ceci afin de préserver les valeurs de confiance et de fiabilité qui caractérisent la communauté MonKolis.

              <br/>
              <br/>
              <span class="txt-bold">Et si le transporteur annule ou ne vient pas au rendez-vous ?</span>	<br/>

              Les annulations sont rares, mais si cela arrive, nous vous remboursons intégralement.
              <br/>
              <br/>
              Si le transporteur n'annule pas son trajet en ligne, dès que vous avez l'information nous vous invitons à nous le signaler en <a href="https://www.facebook.com/MonKolis/" class="link" target = "_blank">contactant notre équipe</a>.
              <br/>
              <br/>

              <span class="txt-bold">Le trajet n’a pas eu lieu, que faire ?</span>	<br/>

              Si le trajet n’a pas eu lieu, faites-le-nous savoir dès que possible en <a href="https://www.facebook.com/MonKolis/" class="link" target = "_blank">contactant notre équipe</a>.
              <br/>
              <br/>
              Précisez nous le trajet concerné et indiquez que celui-ci n’a pas eu lieu. Indiquez aussi la raison et ajoutez les détails qui vous paraissent pertinents (nous ne partagerons pas ces informations).
              Nous demanderons ensuite au transporteur s’il est d’accord avec votre réclamation ou non. S’il se trouve que c’est bien le transporteur qui est en tort, nous vous rembourserons le montant de votre réservation dans son intégralité.
              <br/>
              <br/>
              Vous avez jusqu’à 48 heures après la date estimée d'arrivée pour nous dire si le transport n’a pas eu lieu.


              <br/>
              <br/>

            </p>
            <a href="#top"class="link color-text2">Remonter</a> <br/><br/>

            <h4 class="" id="3">Quel est le délai de traitement d’une réclamation ?<br/></h4>
              <p>

                Votre trajet n’a pas eu lieu ou ne s’est pas déroulé comme prévu ?
                Si vous (ou votre transporteur) ouvrez une réclamation, nous vous envoyons un e-mail qui vous indique que vous ou votre transporteur disposez de 7 jours pour répondre à cette réclamation.
                <br/>
                <br/>
                Le délai de versement de votre remboursement ou de votre paiement varie selon la réponse apportée à la réclamation :
                <br/>
                <br/>
                En général, une réclamation est traitée automatiquement sous 7 jours maximum, parfois moins si les deux parties sont réactifs et qu’ils ont la même version des faits :)
                <br/>
                <br/>
                Il se peut que vous ne soyez pas d’accord avec votre transporteur, pour différentes raisons ou à cause d’un simple malentendu ; le montant de la réservation est alors bloqué temporairement. C’est notre équipe qui enquête et qui vous contacte si besoin pour en savoir plus.
                <br/>
                <br/>
                En fonction de vos réponses, notre équipe détermine à qui doit revenir le montant de la réservation et quelques jours supplémentaires peuvent être nécessaires pour résoudre et fermer la réclamation de la manière la plus juste.
                <br/>
                <br/>
                Vous avez une réclamation en cours et vous n’avez pas encore reçu de nos nouvelles ?<br/>
                Vous voulez contester le traitement d’une réclamation ?<br/>
                <a href="https://www.facebook.com/MonKolis/" class="link" target = "_blank">Contactez-nous</a> et notre équipe vous répondra rapidement.


            </p>

            <a href="#top"class="link color-text2">Remonter</a> <br/><br/>



          </div>
      </div>  
    </div>

    );
  }
}

export default withRouter(Resiliations);