import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { TRAVEL_PROPOSAL_PAST_QUERY } from '../../../query/travelquery';
import { TRAVEL_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Moment from "moment";
import MyExpeditionsHistoric from './myexpeditionsHistoric';
import MyExpeditionsInProgress from './myexpeditionsinprogress';
import Nav from 'react-bootstrap/Nav';
import './../../../assets/css/multiple2.css';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';


class MyExpeditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renderInProgress: true,
            renderHistoric: false,
        };
        this.handleInprogressClick = this.handleInprogressClick.bind(this);
        this.handleHistoricClick = this.handleHistoricClick.bind(this);
    }

    handleInprogressClick(event) {
        this.setState({
            renderInProgress: true,
            renderHistoric: false
        })
    }

    handleHistoricClick(event) {
        this.setState({
            renderInProgress: false,
            renderHistoric: true,
        })
    }

    render() {
        
        this.idToken = getCookieConsentValue('idToken');
        return (
            <Fragment>
                <Helmet>
                    <title>Mes expéditions | Monkolis</title>
                    <meta name="robots" content="noindex,follow" />
                </Helmet>

                <div className="myexpeditions">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            { /*sessionStorage.getItem('idToken')*/ getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? <Redirect to="/connexion" /> :
                                <div className="pad-top20 mar-bot20 d-sm-block" id="secondNav">
                                    <Nav variant="tabs" defaultActiveKey="/dashboard/myexpeditions">
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mydashboard" href="/dashboard/mydashboard">Tableau de bord</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mytrips" href="/dashboard/mytrips">Mes trajets</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myexpeditions" href="/dashboard/myexpeditions">Mes expéditions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mymessage" href="/dashboard/mymessage">Messages</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myalerts" href="/dashboard/myalerts">Alertes</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myprofile" href="/dashboard/myprofile">Profil</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href="/dashboard/money" href="/dashboard/money">Paiements</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <br />
                                    <div className="row expeds">
                                        <div className="col-sm-3 mar-bot20">
                                            <div className="list-group">
                                                {
                                                    this.state.renderInProgress ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleInprogressClick}>Expéditions en cours</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleInprogressClick}>Expéditions en cours</a>
                                                }

                                                {
                                                    this.state.renderHistoric ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleHistoricClick}>Expéditions historiques</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleHistoricClick}>Expéditions historiques</a>
                                                }

                                            </div>
                                        </div>
                                        <div className="col-sm-9">
                                            {this.state.renderInProgress ? <MyExpeditionsInProgress /> : null}
                                            {this.state.renderHistoric ? <MyExpeditionsHistoric /> : null}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default withRouter(MyExpeditions);