import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';

import VideoGuide from './videoguide';
import VideoPub from './videopub';
import VideoTuto from './videotuto';
import { withRouter, Redirect } from 'react-router-dom';


class Tuto extends Component {
	constructor(props) {
		super(props);
		this.state= {
            renderVideoTuto: true,
			renderVideoGuide: false,
			renderVideoPub: false,
        }
		
		this.handleVideoTutoClick = this.handleVideoTutoClick.bind(this);
		this.handleVideoGuideClick = this.handleVideoGuideClick.bind(this);
		this.handleVideoPubClick = this.handleVideoPubClick.bind(this);
	}

	componentWillMount() {
		
	}

	handleVideoTutoClick(event) {
		this.setState({
			renderVideoTuto: true,
			renderVideoGuide: false,
			renderVideoPub: false,
		})
	}

	handleVideoPubClick(event) {
		this.setState({
			renderVideoTuto: false,
			renderVideoGuide: false,
			renderVideoPub: true,
		})
	}

	handleVideoGuideClick(event) {
		this.setState({
			renderVideoTuto: false,
			renderVideoGuide: true,
			renderVideoPub: false,
		})
	}	
	
	render() {
		return (
			<Fragment>
				<Helmet>
					<title>Découvrez nos services en vidéos | MonKolis </title>
					<meta name="robots" content="index,follow" />
				</Helmet>

				<div className="Tuto">
					<div className="main-box_content">
						<div className="container-fluid container-fluid-pad container-fluid-br">
							<div className="row pad-top20 pad-bot20">
								<div className="col-sm-3 mar-bot20">
									<div className="list-group">
										{
											this.state.renderVideoTuto ?
												<a className="list-group-item list-group-item-action txt-white active" onClick={this.handleVideoTutoClick}>Vidéos Tutos</a> :
												<a className="list-group-item list-group-item-action" onClick={this.handleVideoTutoClick}>Vidéos Tutos</a>
										}
										{
											this.state.renderVideoGuide ?
												<a className="list-group-item list-group-item-action txt-white active" onClick={this.handleVideoGuideClick}>Vidéos Guides</a> :
												<a className="list-group-item list-group-item-action" onClick={this.handleVideoGuideClick}> Vidéos Guides</a>
										}
										{
											this.state.renderVideoPub ?
												<a className="list-group-item list-group-item-action txt-white active" onClick={this.handleVideoPubClick}>Vidéos Pubs</a> :
												<a className="list-group-item list-group-item-action" onClick={this.handleVideoPubClick}>Vidéos Pubs</a>
										}

									</div>
								</div>
								<div className="col-sm-9">
									{this.state.renderVideoTuto ? <VideoTuto /> : null}
									{this.state.renderVideoGuide ? <VideoGuide /> : null}
									{this.state.renderVideoPub ? <VideoPub /> : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
export default withRouter(Tuto);