import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Prohibited extends Component {
    render() {
        return (

            <Fragment>
                <Helmet>
                <meta charSet="utf-8" />
                    <title>Produits et objets interdits dans l'avion | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="cookies">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/legales" className="list-group-item list-group-item-action">Mentions
                                            légales</a>
                                        <a href="/terms" className="list-group-item list-group-item-action">Conditions
                                            générales</a>
                                        <a href="/privacy" className="list-group-item list-group-item-action">Politique de
                                            Confidentialité</a>
                                        <a href="/cookies" className="list-group-item list-group-item-action">Utilisation
                                            des cookies</a>
                                        <a href="/prohibited" class="list-group-item list-group-item-action  active">Produits interdits</a>
                                    </div>

                                </div>
                                <div className="col-sm-9">
                                    <div className="section">
                                        <div class="section-inner">
                                        <h3 class="heading">Produits et objets interdits dans l'avion</h3>
                                            <p>
                                                Certains objets ou matières sont interdits à bord des avions, d’autres soumis à réglementation.
                                                Lesquels pouvez-vous emporter à bord ? En cabine ? En soute ? Avez-vous besoin d’un accord préalable ?
                        La liste ci-dessous publiée par la compagnie AirFrance à titre d'exemple <a href="https://www.airfrance.fr/FR/fr/common/guidevoyageur/pratique/produits-interdits-et-reglementes-airfrance.htm#" class="link" target="_blank">(voir sur le site de AirFrance)</a>, bien que non exhaustive, est là pour vous aider.<br />
                        N'hésitez donc pas à vous renseigner au préalable sur la liste des produits interdits auprès de votre companie de transport ainsi que des douanes de vos pays de départ et d'arrivée.

                      <br /><br />PS : Pour les accords préalables, les autorisations doivent être demandées auprès d'Air France ou de votre agence de voyages.
                    <br /><br />Vous avez la possibilité d'utiliser un service en ligne pour connaître précisément la liste des produits autorisés :
                     <a href="https://airbag.dsac.aviation-civile.gouv.fr/AirBag" class="link" target="_blank">Accéder à la recherche</a>

                                            </p>


                                            <span class="txt-bold">	1. Aérosols, articles de toilette et médicaments</span>	<br />
                                            <span class="txt-bold">1.1 Aérosols non inflammables </span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br /> •	En soute : oui<br /> •	Accord préalable : non
                  </p>
                                            <p>
                                                Gaz non inflammables, non toxiques, sans autre forme de risque à usage sportif ou domestique. Les valves des aérosols doivent être protégées par un capuchon.
                  </p>

                                            <span class="txt-bold " >1.2 Articles de toilette et médicaments non radioactifs (y compris en aérosols)</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                  •	En soute : oui<br />
                  •	Accord préalable : non
                  </p>
                                            <p>
                                                Laque pour cheveux, déodorant, parfum, eau de Cologne, dissolvant, vernis à ongles… et médicaments contenant de l'alcool.
                  </p>
                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : la quantité nette totale des articles de toilette, des médicaments non radioactifs et des aérosols cités ci-dessus est limitée à 2 kg ou 2 l par personne. La quantité nette de chaque article ne doit pas excéder 0,5 kg ou 500 ml.

                                                Important : ces quantités ne tiennent pas compte des restrictions liées au transport des liquides en cabine. Vous pouvez acheter ces articles dans les boutiques hors taxes à l’aéroport, après le contrôle de sûreté.
                  Voir la rubrique <span class="txt-bold">12 - Liquides – Boissons alcoolisées, combustibles, liquides inflammables – Poudres</span> pour plus d’informations.
                </p>
                                            <span class="txt-bold">1.3 Aérosols inflammables ou toxiques</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                  •	En soute : non<br />
                                            </p>
                                            <p>
                                                Produits ménagers, d’entretien, de bricolage… tels les encres, peintures, laques et vernis de couverture, de quelques sortes que ce soit (même à base d’eau), acétone, alcool, diluant pour peinture, éthanol, essence, fluide pour briquet.
                  </p>

                                            <span class="txt-bold">	2. Allumettes, briquets et cigarettes électroniques et leurs batteries de rechange</span>	<br />
                                            <span class="txt-bold">2.1 Allumettes de sûreté et briquets classiques </span>
                                            <p class="txt-italic">
                                                •	En cabine : uniquement sur soi<br /> •	En soute : non<br /> •	Accord préalable : non <br />1 petite boîte d’allumettes ou 1 briquet maximum par personne.
                  </p>
                                            <p>
                                                Allumettes de sûreté, briquets classiques (à gaz liquéfié, y compris briquets allume-barbecue ou à essence équipés d’un capot de protection).
                  </p>
                                            <span class="txt-bold">2.2 Autres allumettes et briquets </span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br /> •	En soute : non
                </p>
                                            <p>
                                                Allumettes sans frottoir, briquets Blue flame ou allume-cigare, briquets contenant du liquide inflammable non absorbé (sauf du gaz liquéfié), essence à briquet, cartouches de recharge, briquets fonctionnant avec une batterie au lithium (métal ou ion) non munis d’une protection contre une activation accidentelle.
                  </p>
                                            <span class="txt-bold">2.3 Cigarettes électroniques </span>
                                            <p class="txt-italic">
                                                •	En cabine : uniquement sur soi et complètement éteintes<br /> •	En soute : non<br /> •	Accord préalable : non
                </p>
                                            <p>
                                                Cigarettes électroniques de tous types (e-cigarettes, e-cigares, e-pipes, e-chicha, vapoteurs personnels, systèmes électroniques d'administration de nicotine…).
                      </p>
                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : il est conseillé de protéger les cigarettes électroniques (de tous types) dans des étuis adaptés. L’utilisation et la recharge des cigarettes électroniques sont interdites pendant le vol.
                  </p>

                                            <p class="bg-secondary color-white pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Batteries au Lithium</span><br />
                    Les batteries au lithium peuvent prendre feu si elles sont court-circuitées, endommagées, mal conçues ou mal assemblées. Les batteries au lithium, les appareils électriques et électroniques, endommagés, défectueux ou rappelés par le fabricant sont interdits dans les avions.
                  <br />  Manipulez avec soin vos appareils et batteries de rechange à bord.
                    </p>

                                            <span class="txt-bold">2.4 Batteries de rechange des cigarettes électroniques </span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : non<br /> •	Accord préalable : non
                  </p>
                                            <p>
                                                Chaque batterie de rechange doit être protégée contre les courts-circuits. Elle doit être placée dans l’emballage d’origine, un sac plastique, ou une enveloppe protectrice individuelle. Sinon, les bornes doivent être isolées avec du ruban adhésif non-conducteur.
                        </p>

                                            <span class="txt-bold">	3. Appareils électriques et électroniques, piles et batteries de rechange</span>	<br />
                                            <p class="">
                                                Les appareils placés dans les bagages en soute doivent obligatoirement être éteints. Leur mise en marche involontaire doit être rendue impossible par quelque moyen que ce soit.
                  </p>

                                            <span class="txt-bold">3.1 Appareils et batteries au Lithium</span>
                                            <p>
                                                Les batteries au lithium peuvent prendre feu si elles sont court-circuitées, endommagées, mal conçues ou mal assemblées. Elles sont interdites dans les avions, de même que les appareils endommagés, défectueux ou rappelés par le fabricant.
                                                Pour éviter tous dommages et leurs conséquences, emballez ou protégez soigneusement vos appareils et batteries lorsqu’ils voyagent dans vos bagages en soute et manipulez-les avec soin lorsqu’ils voyagent en cabine.
                    </p>

                                            <span class="txt-bold">3.2 Appareils électroniques portables inférieure à 100 wh</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : oui<br /> •	Accord préalable : non jusqu’à 15 appareils par personne.
                  </p>
                                            <p>
                                                Appareils contenant des piles ou batteries au lithium-métal (n’excédant pas 2 g de lithium) ou au lithium-ion (n’excédant pas 100 Wh) transportés pour un usage personnel : montres, caméscopes, appareils photo, téléphones portables, ordinateurs portables, tablettes, drones…
                  </p>

                                            <span class="txt-bold">3.3 Bagages équipés d'une batterie inférieure à 100 wh</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : oui à condition de retirer la batterie du bagage et de la conserver en cabine.<br /> •	Accord préalable : non.
                  </p>
                                            <p>
                                                Bagages équipés d’une batterie servant à recharger vos appareils portables personnels ou à faire fonctionner un appareil électronique intégré (fonction pèse-bagage, balise GPS…). Seuls les bagages équipés d’une batterie amovible sont autorisés à bord.
                    Pour les bagages motorisés de type valise-scooter veuillez vous référer à la section <span class="txt-bold">9 - Engins de déplacement électriques autres que ceux destinés aux personnes à mobilité réduite.</span>
                                            </p>

                                            <span class="txt-bold">3.4 Batteries de rechange et batteries externes inférieure à 100 wh </span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : non. <br /> •	Accord préalable : non jusqu’à 20 batteries par personne.
                  </p>
                                            <p>
                                                Batteries au lithium-métal (n’excédant pas 2 g de lithium) ou au lithium-ion (n’excédant pas 100 Wh), pour appareils électroniques portables (montres, caméscopes, appareils photo, téléphones portables, ordinateurs portables, tablettes, chargeurs externes de type Power Bank, drones…).
                  </p>

                                            <span class="txt-bold">3.5 Appareils électroniques portables entre 100 wh et 160 wh </span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : oui <br /> •	Accord préalable : oui
                  </p>
                                            <p>
                                                Appareils électroniques portables contenant des batteries au lithium-ion (supérieures à 100 Wh, mais n’excédant pas 160 Wh tels que les caméras vidéo, ordinateurs portables, drones…).
                  </p>

                                            <span class="txt-bold">3.6 Bagages équipés d'une batterie entre 100 wh et 160 wh </span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : oui, à condition de retirer la batterie du bagage et de la conserver en cabine. <br /> •	Accord préalable : oui
                  </p>
                                            <p>
                                                Bagages équipés d’une batterie servant à recharger vos appareils portables personnels ou à faire fonctionner un appareil électronique intégré (fonction pèse-bagage, balise GPS…). Seuls les bagages équipés d’une batterie amovible sont autorisés à bord.
                    Pour les bagages motorisés de type valise-scooter, veuillez vous référer à la section <span class="txt-bold">9 - Engins de déplacement électriques autres que ceux destinés aux personnes à mobilité réduite.</span>
                                            </p>

                                            <span class="txt-bold">3.7 Batteries de rechange et batteries externes entre 100 wh et 160 wh </span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : non <br /> •	Accord préalable : oui <br />
                  2 batteries de rechange maximum par personne.
                  </p>
                                            <p>
                                                Batteries de rechange au lithium-ion pour appareils électroniques portables (supérieures à 100 Wh, mais n’excédant pas 160 Wh tels que les caméras vidéo, ordinateurs portables, chargeurs externes de type Power Bank, drones…).
                  </p>

                                            <span class="txt-bold">3.8 Appareils électroniques portables supérieures à 160 wh, leurs batteries de rechange et batteries externes </span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br /> •	En soute : non
                  </p>
                                            <p>
                                                Batteries au lithium-métal contenant plus de 2 g de lithium, et batteries au lithium-ion dont l’énergie dépasse 160 Wh, contenues dans l’appareil ou de rechange telles qu’outils électriques, chargeurs externes de type Power Bank, drones, batteries de voiture… (voir aussi la rubrique <span class="txt-bold">9 - Engins de déplacement électriques autres que ceux destinés aux personnes à mobilité réduite.)</span>
                                            </p>

                                            <span class="txt-bold">3.9 Piles à combustible</span>
                                            <p>
                                                L'installation d’une cartouche de rechange est autorisée, mais le remplissage des piles à combustible est interdit.
                    <br />La quantité maximale de combustible dans une pile à combustible ou une cartouche pour piles à combustible ne doit pas dépasser :
                    <br />- 200 ml pour les liquides,
                    <br />- 200 g pour les solides,
                    <br />- pour les gaz liquéfiés, 120 ml pour les piles à combustible ou les cartouches pour piles à combustible non métallique, ou 200 ml pour les piles à combustible ou les cartouches de piles à combustible métallique,
                    <br />- pour l'hydrogène en hydrure métallique, les cartouches de piles à combustible doivent avoir une capacité d’eau inférieure ou égale à 120 ml.
                  </p>


                                            <span class="txt-bold">3.10 Appareils à piles à combustible</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : non  <br /> •	Accord préalable : non
                  </p>

                                            <p>
                                                Appareils électroniques portables fonctionnant avec des piles à combustible tels les appareils photo, téléphones portables, ordinateurs portables et caméscopes, avec marquage durable du fabricant « approved for carriage in aircraft cabin only » (approuvé pour le transport en cabine uniquement).
                  </p>

                                            <span class="txt-bold">3.11 Cartouches de rechange</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : oui  <br /> •	Accord préalable : non
                  <br /> 2 cartouches de rechange maximum par personne.
                  </p>

                                            <p>
                                                Cartouches de rechange pour piles à combustible pour appareils électroniques portables.
                  </p>

                                            <span class="txt-bold">3.12 Appareils à batteries inversables</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : oui  <br /> •	Accord préalable : non
                  <br /> 2 cartouches de rechange maximum par personne.
                  </p>

                                            <p>
                                                Appareils électroniques portables fonctionnant avec des batteries inversables dites « batteries sèches » ou « batteries à gel liquide » répondant à la disposition particulière A67 (chargeur ou booster de batterie, balance…). Les batteries doivent avoir un voltage maximum de 12 V et une puissance maximum de 100 Wh.
                  </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : les valeurs énergétiques des batteries doivent être visibles sur l’appareil ou sur les batteries.
                    </p>

                                            <span class="txt-bold">3.13 Autres batteries de rechange</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : non  <br /> •	Accord préalable : non
                    </p>

                                            <p>
                                                Batteries alcalines ou nickel-métal hydride.
                    </p>

                                            <span class="txt-bold">3.14 Appareils électriques produisant une chaleur extrême</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br /> •	En soute : oui  <br /> •	Accord préalable : oui
                    </p>

                                            <p>
                                                Lampes torches de haute intensité (torches de plongée), fers à souder…<br />
                      Afin d’éviter tout risque d’incendie, la batterie et l'élément qui produit de la chaleur doivent être séparés. La batterie doit être protégée contre les courts-circuits. Elle doit être placée dans l’emballage d’origine, un sac plastique ou une enveloppe protectrice individuelle. Sinon, les bornes doivent être isolées avec du ruban adhésif.
                    </p>

                                            <span class="txt-bold">4. Appareils médicaux électriques et électroniques, piles et batteries de rechange</span>
                                            <p>
                                                Les appareils placés dans les bagages en soute doivent obligatoirement être éteints. Leur mise en marche involontaire doit être rendue impossible par quelque moyen que ce soit.
                                                Chaque batterie de rechange doit être protégée contre les courts-circuits. Elle doit être placée dans l’emballage d’origine, un sac plastique ou une enveloppe protectrice individuelle. Sinon, les bornes doivent être isolées avec du ruban adhésif non-conducteur.
                    </p>
                                            <span class="txt-bold">4.1 Appareils et batteries au lithium </span>

                                            <p>
                                                Les batteries au lithium peuvent prendre feu si elles sont court-circuitées, endommagées, mal conçues ou mal assemblées. Les batteries au lithium, les appareils endommagés, défectueux ou rappelés par le fabricant sont interdits dans les avions.
                                                Pour éviter tous dommages et leurs conséquences, emballez ou protégez soigneusement vos appareils et batteries lorsqu’ils voyagent dans vos bagages en soute et manipulez-les avec soin lorsqu’ils voyagent en cabine.
                    </p>

                                            <span class="txt-bold">4.2 Appareils électroniques portables inférieure à 100 wh </span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                    •	En soute : oui<br />
                    •	Accord préalable : non
                    </p>
                                            <p>
                                                Appareils médicaux électroniques portables contenant des piles ou batteries au lithium-métal (n’excédant pas 2 g de lithium) ou au lithium-ion (n’excédant pas 100 Wh) : concentrateurs d’oxygène personnels (COP), transportés pour un usage médical.
</p>

                                            <span class="txt-bold">4.3 Batteries de rechange inférieure à 100 WH</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                    •	En soute : non<br />
                    •	Accord préalable : non
                    </p>
                                            <p>
                                                Batteries de rechange au lithium-métal (n’excédant pas 2 g de lithium) ou au lithium-ion (n’excédant pas 100 Wh) pour appareils médicaux électroniques portables : concentrateurs d’oxygène personnels (COP).
                    </p>


                                            <span class="txt-bold">4.4 Appareils électroniques portables entre 100 wh et 160 wh</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                    •	En soute : oui<br />
                    •	Accord préalable : oui
                    </p>
                                            <p>
                                                Appareils médicaux électroniques portables contenant des piles ou batteries au lithium-métal (n'excédant pas 8 g de lithium) ou au lithium-ion (supérieures à 100 Wh, mais n’excédant pas 160 Wh) transportés pour un usage médical : défibrillateurs externes automatiques (DEA), nébuliseurs, appareils de ventilation en pression positive continue (AVSPPC), tels les appareils anti-apnée ou anti-ronflements.
                  </p>


                                            <span class="txt-bold">4.5 Batteries de rechange entre 100 wh et 160 wh</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                    •	En soute : non<br />
                    •	Accord préalable : oui<br />
                    2 batteries de rechange maximum par personne.
                    </p>

                                            <p>
                                                Batteries de rechange au lithium-métal (n’excédant pas 8 g de lithium) ou au lithium-ion (supérieures à 100 Wh, mais n’excédant pas 160 Wh) pour appareils médicaux électroniques portables (DEA, AVSPPC, nébuliseurs…).
                    </p>

                                            <span class="txt-bold">4.6 Appareils électroniques portables supérieures à 160 wh et leurs batteries de rechange</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                  </p>
                                            <p>
                                                Batteries au lithium-métal contenant plus de 8 g de lithium, et batteries au lithium-ion dont l’énergie dépasse 160 Wh, contenues dans l’appareil ou de rechange (voir aussi la rubrique <span class="txt-bold">10 - Fauteuils roulants et autres engins de déplacement électriques pour personnes à mobilité réduite )</span>.
                    </p>

                                            <span class="txt-bold">4.7 Appareils à batteries inversables</span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                    •	En soute : oui<br />
                    •	Accord préalable : non <br />
                    2 batteries de rechange maximum par personne.
                    </p>

                                            <p>
                                                Appareils électroniques portables fonctionnant avec des batteries inversables dites « batteries sèches » ou « batteries à gel liquide » répondant à la disposition particulière A67. Les batteries doivent avoir un voltage maximum de 12 V et une puissance maximum de 100 Wh.
                    </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : les valeurs énergétiques des batteries doivent être visibles sur l’appareil ou sur les batteries.
                    </p>


                                            <span class="txt-bold">5. Appareils et articles médicaux autres que ceux fonctionnant avec des piles ou batteries</span>
                                            <p class="">
                                                Toutes les mesures nécessaires à la protection des dispositifs contenant du gaz doivent être prises.
  <br />La mise en marche involontaire des appareils équipés de bouteilles de gaz doit être rendue impossible par quelque moyen que ce soit.

                    </p>

                                            <span class="txt-bold">5.1 Oxygène ou air sous forme de gaz</span>


                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                    •	En soute : non<br />
                    •	Accord préalable : oui <br />
                    Poids brut maximum autorisé par bouteille : 5 kg.
                    </p>

                                            <p>
                                                Bouteilles d’oxygène ou d’air sous forme gazeuse, ou les appareils contenant ces bouteilles, pour usage médical.                    </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : l'utilisation à bord des bouteilles personnelles d’oxygène ou d’air est interdite.
                    </p>


                                            <span class="txt-bold">5.2 Oxygène liquide</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                    </p>

                                            <p>
                                                Appareils contenant des bouteilles d'oxygène sous forme liquide, à usage médical.
                    </p>

                                            <span class="txt-bold">5.3 Appareils ou produits médicaux radioactifs

</span>
                                            <p class="txt-italic">
                                                •	En cabine : uniquement sur soi<br />
                    •	En soute : non<br />
                    •	Accord préalable : non
                    </p>

                                            <p>
                                                Régulateurs cardiaques ou dispositifs équivalents radio isotopiques, (y compris ceux à piles au lithium), implantés dans l’organisme ou fixés à l’extérieur.
                    </p>


                                            <span class="txt-bold">5.4 cartouches de gaz pour prothèse </span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                    •	En soute : oui<br />
                    •	Accord préalable : non
                    </p>

                                            <p>
                                                Petites cartouches de gaz non inflammables, non toxiques pour prothèses mécaniques portées par le passager ainsi que des cartouches de rechange, d’une taille équivalente, en quantité nécessaire à la durée du voyage.
                    </p>

                                            <span class="txt-bold">5.5 Thermomètre à mercure </span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : oui<br />
                    •	Accord préalable : non<br />
                    1 thermomètre maximum par personne.
                    </p>

                                            <p>
                                                Thermomètre médical ou clinique, contenant du mercure, pour usage personnel, lorsqu'il est placé dans son étui de protection.
                    </p>




                                            <span class="txt-bold">6. Armes et munitions</span>
                                            <span class="txt-bold">6.1 Armes électriques</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                    </p>

                                            <p>
                                                Armes électriques contenant des éléments dangereux tels que des explosifs, gaz comprimés, batteries au lithium : matraques ou pistolets à décharge électrique (type Taser)…
                    </p>
                                            <span class="txt-bold">6.2 Armes à feu et munitions</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : oui<br />
                    •	Accord préalable : oui<br />
                    Plusieurs passagers ne peuvent pas ranger leurs munitions en un seul colis.
                    </p>

                                            <p>
                                                Ces armes destinées au sport et à la chasse sont autorisées à condition que vous puissiez présenter tous les permis et licences requis, et seulement après en avoir réservé le transport.
                                                Les munitions (cartouches pour armes de petit calibre) doivent être solidement emballées (division 1.4S, UN0012 ou UN0014 uniquement) en quantité n’excédant pas 5 kg brut par personne et pour l’usage de cette personne, à l’exclusion des cartouches à projectiles explosifs ou incendiaires.
                                      </p>

                                            <span class="txt-bold">6.3 Autres armes de tir</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : oui<br />
                    •	Accord préalable : non
                    </p>

                                            <p>
                                                Armes de tir n'entrant pas dans la catégorie des armes à feu.
                  </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Liste : </span><br />
                        - Appareils émettant des décharges électriques, par exemple sondes pour le bétail, systèmes à énergie conduite par balistique
                        <br />- Arbalètes
                        <br />- Catapultes
                        <br />- Composants d'armes à feu (sauf les équipements de vision télescopique et viseurs)
                        <br />- Fusils à plombs
                        <br />- Fusils à rivets et à clous industriels
                        <br />- Harpons et fusils de pêche
                        <br />- Pistolets à air comprimé et fusils à air comprimé
                        <br />- Pistolets à fusées de signalisation
                        <br />- Pistolets d'abattage d'animaux
                        <br />- Pistolets de jeu
                        <br />- Pistolets de starter (compétitions)
                        <br />- Répliques et imitations d'armes à feu
                        <br />- Bijoux ou amulettes fabriqués à partir de balles réelles ou de cartouches de balles vides (uniquement applicable aux vols au départ et à destination des Philippines)

                        <br /><br />Liste non exhaustive

                    </p>


                                            <span class="txt-bold">6.4 Armes et objets tranchants</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : oui<br />
                    •	Accord préalable : non
                    </p>

                                            <p>
                                                Armes et objets pouvant provoquer des blessures.
                  </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Liste : </span><br />
                    - Aiguilles et crochets (tricot, broderie), en métal ou tout autre matériau suffisamment solide pour servir d’arme
                        <br />- Bâtons de ski, de marche ou de randonnée
                      <br />- Canifs ou couteaux à cran d’arrêt, quelle que soit la longueur de la lame
                      <br />- Ciseaux
                      <br />- Couperets à viande
                      <br />- Couteaux, y compris les couteaux de cérémonie, en métal ou en tout autre matériau suffisamment résistant pour servir d’arme
                      <br />- Crampons
                      <br />- Flèches et fléchettes
                      <br />- Haches et hachettes
                      <br />- Machettes
                      <br />- Outils qui pourraient servir d’arme contondante ou tranchante, tels les perceuses et forets, cutters, couteaux de travail, toutes les scies, tournevis, pieds-de-biche, marteaux, plieuses, clés, équipements de soudure
                      <br />- Piolets et pics à glace
                      <br />- Patins à glace
                      <br />- Rasoirs ouverts (sauf les rasoirs de sécurité ou les rasoirs jetables avec des lames insérées dans des cartouches)
                      <br />- Sabres, épées et dagues
                      <br />- Scalpels
                      <br />- Shurikens

                      <br /><br />Liste non exhaustive
                    </p>

                                            <span class="txt-bold">6.5 Instruments contondants</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : oui<br />
                    •	Accord préalable : non
                    </p>

                                            <p>
                                                Armes et objets pouvant provoquer des blessures.
                  </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Liste : </span><br />
                                Battes de baseball et balles de cuir
                          <br />- Battes de cricket
                          <br />- Cannes à pêche
                          <br />- Clubs de golf
                          <br />- Clubs ou bâtons rigides et flexibles, tels les matraques, accessoires de black-jack et bâtons
                          <br />- Crosses de hockey
                          <br />- Crosses de Lacrosse
                          <br />- Équipements d'arts martiaux, tels les protections des doigts, crosses, fléaux, nunchakus, kubatons et kubasaunts
                          <br />- Pagaies de kayak et de canoë
                          <br />- Queues de billard, de billard américain et de billard anglais
                          <br />- Skateboards

                      <br /><br />Liste non exhaustive
                    </p>







                                            <span class="txt-bold">7. Dispositifs à usage scientifique ou autre</span>
                                            <span class="txt-bold">7.1 Qualité de l'air</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : oui<br />
                    •	Accord préalable : non
                    </p>

                                            <p>
                                                Dispositifs de pénétration utilisés pour étalonner des appareils de contrôle de la qualité de l’air.
                    </p>

                                            <span class="txt-bold">7.2 Mallette de sûreté</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                    </p>

                                            <p>
                                                Mallette de sûreté, boîte, bourse ou sac pour le transport d'argent liquide et équipement de type sécurité… contenant des articles dangereux tels des piles au lithium ou des matières pyrotechniques.
                    </p>

                                            <span class="txt-bold">7.3 Baromètre ou thermomètre au mercure </span>
                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                    •	En soute : non <br />
                    •	Accord préalable : oui
                    </p>

                                            <p>
                                                Baromètre ou thermomètre au mercure transporté par un représentant du bureau météorologique gouvernemental ou d'un organisme officiel similaire.
                    </p>


                                            <span class="txt-bold">8. Explosifs, feux d'artifice et pétards</span><br />
                                            <span class="txt-bold">8.1 Articles pyrotechniques</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                    </p>

                                            <p>
                                                Ces articles sont interdits.
                  </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Liste : </span><br />
                        - Détonateurs et amorces
                        <br />- Engins explosifs ou incendiaires
                        <br />- Feux d'artifice (y compris bougies ou cierges magiques) ou fusées éclairantes sous toute forme
                        <br />- Grenades, bombes ou cartouches fumigènes
                        <br />- Munitions autres que celles autorisées dans la rubrique « Armes à feu et munitions »
                        <br />- Mines et autres dispositifs militaires d'explosion
                        <br />- Pétards y compris Christmas Crackers (pétards de Noël) et amorces
                        <br />- Répliques ou imitations de matières explosives ou d'explosifs
                        <br />- Dynamite, poudre et explosifs plastiques


                      <br /><br />Liste non exhaustive
                    </p>

                                            <span class="txt-bold">9. Engins de déplacement électriques autres que ceux destinés aux personnes à mobilité réduite</span><br />
                                            <span class="txt-bold">9.1 Vélos électriques</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                    </p>

                                            <p>
                                                Vélos ou bicyclettes électriques, à usage de loisir, fonctionnant avec des batteries au lithium (quelle que soit la puissance des batteries en Wh) avec batteries installées.
                    </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Vélo électrique sans batterie: </span><br />
                      Vous pouvez voyager avec un vélo électrique, à condition que ses batteries soient retirées.
                      Il est alors considéré comme un vélo normal.
                    </p>

                                            <span class="txt-bold">9.2 Engins de déplacement personnels, à usage de loisir</span>
                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                    </p>

                                            <p>
                                                Gyropodes, hoverboards, airboards, oxboards, e-skates, waveboards, valises-scooters, trottinettes… fonctionnant avec des batteries au lithium (quelle que soit leur puissance en Wh et même si la batterie est déconnectée ou retirée).
                    </p>


                                            <span class="txt-bold">10. Fauteuils roulants et autres engins de déplacement électriques pour personnes à mobilité réduite</span>
                                            <p class="">
                                                Selon la nature de votre handicap ou déficience, une assistance spécifique est prévue dans tous les aéroports. Si cela vous est nécessaire, cette assistance vous permet notamment :
                    <br />- de vous déplacer,
                    <br />- de procéder à votre enregistrement ainsi qu'à celui de vos bagages et équipements de mobilité,
                    <br />- de vous rendre jusqu'à l'avion,
                    <br />- d'embarquer et de vous installer à bord,
                    <br />- de quitter l'avion,
                    <br />- de récupérer vos bagages et équipements de mobilité,
                    <br />- de quitter l'aéroport.

                      <br /> <br />Dans tous les aéroports de l'Union européenne, cette assistance est placée sous la responsabilité des gestionnaires d'aéroports, conformément au Règlement européen 1107/2006.

                    </p>


                                            <span class="txt-bold">11. Gaz et azote liquide dans des appareils, bouteilles ou cartouches</span>
                                            <p>
                                                Toutes les mesures nécessaires à la protection des dispositifs contenant du gaz permettant d’éviter des dommages ou une fuite du contenu devront être prises.

                      <br />La mise en marche involontaire des appareils équipes de bouteilles ou cartouches de gaz placés dans les bagages de cabine ou les bagages enregistrés doit être rendue impossible par quelque moyen que ce soit.
                    </p>
                                            <span class="txt-bold">11.1 Air comprimé</span>

                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                    </p>
                                            <p class="">
                                                Bouteilles d’air comprimé, utilisées pour la plongée sous-marine par exemple, non vidées (pressurisées à plus de 200 kPa ou 2,0 bars).
                    </p>
                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold"> Bouteilles vides :</span>
                                                <br />  Les bouteilles vidées (pression inférieure à 200 kPa ou 2,0 bars) sont acceptées et considérées comme un équipement de plongée.
                    </p>

                                            <span class="txt-bold">11.2 Gaz inflammables</span>

                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                    </p>
                                            <p class="">
                                                Cartouches ou bouteilles de gaz inflammable, neuves ou entamées, percutées, non percutées ou avec système de fermeture, utilisées pour le fonctionnement des réchauds à gaz, chalumeaux (de cuisine ou de bricolage), outils, camping-gaz.
                    </p>

                                            <span class="txt-bold">11.3 Dispositifs de défense à gaz</span>

                                            <p class="txt-italic">
                                                •	En cabine : non<br />
                    •	En soute : non
                    </p>
                                            <p class="">
                                                Dispositifs de défense contenant un gaz poivré, une substance irritante ou incapacitante (bombe lacrymogène…).
                    </p>

                                            <span class="txt-bold">11.4 Dispositifs de sauvetage en avalanche</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui (voir ci-dessous le cas particulier des États-Unis)<br />
                    •	En soute : oui<br />
                    •	Accord préalable : oui<br />
                    1 dispositif maximum autorisé par personne.
                    </p>
                                            <p class="">
                                                Dispositifs de sauvetage en avalanche, contenant une cartouche de gaz comprimé non inflammable et non toxique.
                    </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Restrictions</span><br />
                    •	Le dispositif doit être emballé de manière à ce qu’il ne puisse être activé accidentellement.
                    <br />•	Les sacs gonflables du kit doivent être munis de soupapes de sécurité.
                    <br />•	Le dispositif peut également être équipé d'un mécanisme de déclenchement pyrotechnique ne contenant pas plus de 200 mg net d’explosifs de la division 1.4S.

                    </p>

                                            <p class="bg-secondary color-white pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Cas particuliers des Etats-Unis</span><br />
                      Le transport d’un kit de sauvetage en avalanche contenant un explosif (1.4S) dans le mécanisme de déclenchement n’est pas autorisé de, vers, via et à l’intérieur des États-Unis.
                </p>

                                            <span class="txt-bold">11.5 Dispositifs de sécurité auto gonflables</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                •	En soute : oui<br />
                •	Accord préalable : oui<br />
                1 dispositif avec 2 cartouches maximum intégrées et 2 cartouches de rechange maximum par personne.
                </p>
                                            <p class="">
                                                Dispositifs de sécurité auto gonflables individuels fonctionnant avec des cartouches (de petite taille) de gaz non inflammable et non toxique comme le dioxyde de carbone (CO2), ou un autre gaz : gilets ou radeaux de sauvetage, vestes de moto, d’équitation. Le dispositif doit être emballé de manière à ce qu’il ne puisse être activé accidentellement.
                </p>



                                            <span class="txt-bold">11.6 Petites cartouches de gaz</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                •	En soute : oui<br />
                •	Accord préalable : oui<br />
4 petites cartouches maximum par personne.
                </p>
                                            <p class="">
                                                Divers équipements fonctionnant avec des cartouches (de petite taille) de gaz non inflammable et non toxique comme le dioxyde de carbone (CO2), ou un autre gaz : siphons culinaires et à soda, pistolets à air comprimé…
                </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Restrictions</span><br />

                      •	La capacité en eau de chaque cartouche ne doit pas dépasser 50 ml.
<br />•	Pour le dioxyde de carbone, une bouteille de gaz avec une capacité de liquide de 50 ml équivaut à une cartouche de 28 g.
<br />•	De plus, les cartouches de protoxyde d’azote (N2O) sont interdites, quelles que soient la quantité et la contenance.

                </p>


                                            <span class="txt-bold">11.7 Azote liquide</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                •	En soute : oui<br />
                •	Accord préalable : oui
                </p>
                                            <p class="">
                                                Emballages isothermes ou récipients cryogéniques contenant de l'azote liquide réfrigéré (type Dry Shipper) entièrement absorbé dans un matériau poreux, renfermant uniquement des produits non dangereux devant être maintenus à basse température.
                </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : le poids brut maximum de l'emballage ne doit pas dépasser 10 kg.
                </p>

                                            <span class="txt-bold">11.8 Gaz d'hydrocarbures</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                •	En soute : oui<br />
                •	Accord préalable : non <br />
                1 appareil maximum par personne.
                </p>
                                            <p class="">
                                                Fers à friser contenant des gaz d'hydrocarbures, à condition que le couvercle de sécurité soit solidement fixé sur l'élément chauffant.
                </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : l'utilisation à bord et le transport de recharges de gaz pour le fer sont interdits.
                </p>


                                            <span class="txt-bold">11.9 Gaz d'hydrocarbures</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui<br />
                •	En soute : oui<br />
                •	Accord préalable : oui
                </p>
                                            <p class="">
                                                La glace ou neige carbonique est autorisée dans des quantités ne dépassant pas 2,5 kg par personne lorsqu'elle est utilisée pour réfrigérer des denrées périssables non soumises à la Réglementation Marchandises Dangereuses dans les bagages enregistrés ou en cabine. L'emballage doit permettre au dioxyde de carbone de s'échapper, porter une étiquette « Dry Ice » (dioxyde de carbone solide) et indiquer le poids net de glace carbonique.
                </p>


                                            <span class="txt-bold">12. Liquides, crèmes – boissons alcoolisées, combustibles, liquides inflammables - poudres</span>
                                            <span class="txt-bold">12.1 Boissons alcoolisées</span>

                                            <p class="txt-italic">
                                                •	En cabine : non <br />
                •	En soute : oui<br />
                •	Accord préalable : non <br />
                5 l maximum par personne
                </p>
                                            <p class="">
                                                Les boissons alcoolisées ne dépassant pas 70 % d’alcool en volume, contenues dans des emballages de vente au détail, correctement fermés et ne dépassant pas 5 litres, sont autorisées.
                </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : les boissons alcoolisées de fabrication artisanale ne sont pas autorisées.
                </p>

                                            <span class="txt-bold">12.2 Produits ménagers, d'entretien et de bricolage</span>

                                            <p class="txt-italic">
                                                •	En cabine : non <br />
                •	En soute : non
                </p>
                                            <p class="">
                                                Encres, peintures, laques et vernis de couverture, de quelles que sortes que ce soit (même à base d’eau) et quel que soit le conditionnement (aérosol, bidon, bombe, cartouche, pot, tube…), acétone, alcool, diluants pour peinture, éthanol essence, fluides pour briquet, gasoil, pesticides, produits pour piscine, solvants, térébenthine…
                </p>

                                            <span class="txt-bold">12.3 Contenant vide de combustible liquide inflammable</span>

                                            <p class="txt-italic">
                                                •	En cabine : non <br />
                •	En soute : oui <br />
                Accord préalable : oui
                </p>
                                            <p class="">
                                                Réchaud de camping et jerrican de carburant (ou tout autre contenant) ayant contenu un combustible liquide inflammable.
                </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Voir les consignes pour vider le contenant</span><br />
                  Le réservoir de carburant, ou le contenant, doit avoir été vidé pendant au moins 1 h et laissé ouvert pendant au moins 6 h pour permettre à tout le carburant résiduel de s’évaporer.
                  Pour le transport, le réservoir de carburant, ou le contenant, doit être fermé avec le bouchon solidement fixé. Le réchaud, ou le contenant, doit être enveloppé dans un matériau absorbant comme une serviette de papier et placé dans un sac en plastique ou équivalent. Le sac doit être fermé avec un élastique ou du ruban adhésif.
                                </p>


                                            <span class="txt-bold">12.4 Echantillons de liquide inflammable</span>

                                            <p class="txt-italic">
                                                •	En cabine : non <br />
                •	En soute : non
                </p>
                                            <p class="">
                                                Échantillons non infectieux emballés avec de petites quantités de liquide inflammable.
                </p>


                                            <span class="txt-bold">12.5 Liquides, pâtes, gels, crèmes</span>

                                            <p class="txt-italic">
                                                •	En cabine : non <br />
                  •	En soute : oui <br />
                  Accord préalable : oui
                  </p>
                                            <p class="">
                                                Les liquides, crèmes et pâtes en petites quantités sont autorisés en cabine, dans des contenants de 100 ml maximum chacun. Ils doivent être placés dans un sac plastique transparent fermé d’un volume maximum de 1 l. Un seul sac est autorisé par personne.

                    <br /><br /><span class="txt-bold">Exceptions</span>
                                                <br />Les produits suivants, s'ils sont nécessaires pendant votre vol, sont autorisés sans restriction :
                    <br />- les aliments pour bébé,
                    <br />- les médicaments, accompagnés d'une ordonnance ou d'une déclaration de votre médecin,
                    <br />- les denrées alimentaires de régime particulier.
                  </p>

                                            <p class="bg-secondary color-white pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Achats hors taxes à l'aéroport</span><br /><br />
                                                <span class="txt-bold">Vol sans correspondance</span><br />
                    Les liquides hors taxes achetés à l’aéroport ou à bord de l'avion doivent être placés dans un sac plastique transparent scellé. Ils doivent obligatoirement être accompagnés de la preuve d'achat, visible à l'intérieur du sac et mentionnant l'aéroport ou la compagnie aérienne auprès duquel l'achat a été effectué.

                    <br /><br /><span class="txt-bold">Vol avec correspondance</span><br />
                    Si vous êtes en correspondance dans un pays membre de l'Union européenne*, vous pouvez conserver les liquides hors taxes achetés à l'aéroport de départ ou à bord de l'avion, à condition qu'ils soient placés dans un sac plastique transparent scellé. Ils doivent obligatoirement être accompagnés de la preuve d’achat, visible à l'intérieur du sac et mentionnant l'aéroport ou la compagnie aérienne auprès duquel l'achat a été effectué.
                    <br />Pour une correspondance dans un autre pays, veuillez vous renseigner sur la réglementation en vigueur dans le pays où vous effectuez cette correspondance. En fonction de la législation locale, Air France peut accepter en cabine les liquides hors taxes achetés pendant la correspondance dans les mêmes conditions d’emballage que les cas précédents.

                    <br />Si vous effectuez une correspondance aux États-Unis ou au Canada, vous devez récupérer vos bagages en soute au 1er point de correspondance. Nous vous conseillons de placer tout liquide acheté hors taxes dans vos bagages en soute.
                    <br /><br />* Y compris Norvège, Islande et Suisse.
                  </p>



                                            <span class="txt-bold">12.6 Poudres</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui (voir ci-dessous le casparticulier des États-Unis) <br />
                  •	En soute : oui <br />
                  Accord préalable : non
                  </p>
                                            <p class="">
                                                Les poudres comprennent notamment : farine, sucre, café moulu, épices, lait en poudre, cosmétiques, sable, poudres à usage médical, nourriture pour bébé, etc.
                  </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="txt-bold">Cas particulier des États-Unis</span><br /><br />
                    Pour les vols à destination des États-Unis, le transport de produits sous forme de poudre, en quantité supérieure à 350 ml, est interdit en cabine. Les poudres en quantité supérieure doivent être placées dans les bagages en soute.

                    <br /><span class="txt-bold">Exceptions</span> :sont autorisées les poudres à usage médical sur prescription, la nourriture pour bébé, les cendres funéraires, ainsi que les poudres achetées hors taxes, placées dans un sac scellé (STEB).

                  </p>

                                            <span class="txt-bold">13. Moteurs, gros outillages, véhicules et pièces détachées</span><br />
                                            <span class="txt-bold">13.1 Moteurs non éléctriques</span>

                                            <p class="txt-italic">
                                                •	En cabine : non <br />
                  •	En soute : non
                  </p>
                                            <p class="">
                                                Moteurs thermiques, même neufs, à combustion interne ou à pile à combustible.
     </p>


                                            <span class="txt-bold">13.2 Pieces détachées pour véhicules</span>

                                            <p class="txt-italic">
                                                •	En cabine : non <br />
                  •	En soute : non
                  </p>
                                            <p class="">
                                                Pièces détachées, même neuves, pour véhicules à 2, 3 ou 4 roues, susceptibles de contenir des produits chimiques dangereux : amortisseurs de tous types, batteries de tous types pour véhicules, airbags…
          </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : pour les articles cités ci-dessus, Air France applique une politique plus restrictive que ce que prévoit le règlement international.
                  </p>

                                            <span class="txt-bold">13.3 Moteurs électriques</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui <br />
                  •	En soute : oui <br />
                  •	Accord préalable : non
                  </p>
                                            <p class="">
                                                Moteurs électriques fonctionnant sur secteur.
          </p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                <span class="">Moteurs électriques fonctionnant avec des batteries</span>
Pour ces moteurs, veuillez vous reporter à la rubrique <span class="txt-bold">3 - Appareils électriques et électroniques, piles et batteries de rechange.</span>
                                            </p>



                                            <span class="txt-bold">14. Animaux et végétaux protégés</span>

                                            <p class="">
                                                Le commerce et le transport illégal d’animaux ou de végétaux menacés d’extinction, ou des produits dérivant de ces espèces protégées, constitue une menace croissante pour la biodiversité. Air France coopère avec les autorités et des ONG internationales, en mettant en place des mesures d’alerte, et en informant ses passagers des risques et des conséquences du transport d’espèces protégées.
                  <br />Ne vous mettez pas en situation illégale, et engagez-vous à nos côtés : vérifiez la légalité des produits que vous transportez, en soute ou en cabine.
                    <br />Le transport des espèces en Annexe 1 du site CITES est interdit.

                    <br /><br /> <a href="https://checklist.cites.org/#/fr" class="link" target="_blank">Vérifier la légalité des produits que vous transportez sur le site CITES</a>

                                                <br /><br /><a href="https://www.iata.org/en/programs/environment/wildlife-trafficking/" class="link" target="_blank">Visiter le site IATA sur l'engagement contre le trafic des espèces protégées (en anglais)</a>
                                            </p>


                                            <span class="txt-bold">15. Parachutes</span>
                                            <span class="txt-bold">15.1 Parachutes agréés</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui <br />
          •	En soute : oui <br />
          •	Accord préalable : non
          </p>
                                            <p class="">
                                                Seuls les modèles de parachutes dont le système de déclenchement est agréé par la Direction Générale de l’Aviation Civile (DGAC) française sont autorisés sur les vols Air France. Les autres modèles ne sont pas autorisés dans nos avions.
  </p>

                                            <span class="txt-bold">16. Organes vivants</span>
                                            <span class="txt-bold">16.1 Organes humains vivants</span>

                                            <p class="txt-italic">
                                                •	En cabine : oui <br />
  •	En soute : oui <br />
  •	Accord préalable : oui
  </p>
                                            <p class="">
                                                Organes humains vivants destinés aux transplantations, cultures de cellules humaines, embryons. Ils doivent être transportés dans des sacs isothermes avec des pains de glace.
</p>

                                            <p class="bg-light pad-left10 pad-right10 pad-top10 pad-bot10">
                                                À noter : les documents ci-dessous doivent être présentés à l’aéroport.
    <br />- L’ordre de mission du passager transportant les organes vivants.
    <br />- Un message* du service hospitalier ayant demandé le transport, sauf pour les embryons.
    <br />- Un certificat d’insémination, pour les embryons uniquement.
    <br /> <br />* Ce message, envoyé par fax, télex ou e-mail, doit également être transmis aux autorités aéroportuaires concernées (police, douane, police militaire, etc.).
  </p>

                                            <span class="txt-bold">16.2 Sang humain</span>

                                            <p class="txt-italic">
                                                •	En cabine : non <br />
  •	En soute : non
  </p>
                                            <p class="">
                                                Échantillons de sang humain transportés pour analyses.
</p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
            </Fragment >
        );
    }
}

export default Prohibited;