
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { GET_MONEY_TRANSFERT_HISTORY_QUERY } from '../../../query/queryprofil';
import { GET_MONEY_TRANSFERT_HISTORY_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Moment from "moment";
import DataTable from 'react-data-table-component';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyMoneyHistoric extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			datas: [],

		};
	}

	componentDidMount() {
		
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
        this.getMonneyHistory(GET_MONEY_TRANSFERT_HISTORY_QUERY)
	}
	

	getMonneyHistory = async (query) => {
        try {
            const response = await axios.post(GET_MONEY_TRANSFERT_HISTORY_URL,
                { query },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
			);
			this.setState(() => ({
                isLoaded: true,
                items: response.data.data.getUserMoneyTransferHistory.user_money_transfer_history
            }));
			// Set the data to the state
			const tmp = [];
			response.data.data.getUserMoneyTransferHistory.user_money_transfer_history.forEach(element => {
                tmp.push({
                    departure_date: Moment(element.travel_infos.departure_date).format('DD-MM-YYYY'),
                    details : element.travel_infos.departure_city+"--->"+element.travel_infos.arrival_city,
					total_service_charge_amount : element.total_service_charge_amount === "0" ?  0 : element.total_service_charge_amount,
                });
			});
			this.setState({
				datas: tmp,
			})
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }


	render() {
        if (this.state.items.length === 0) {
            return (
                <div className="col-sm-12">
                    <aside className="aside section testimonials">
                        <div className="section-inner">
                            <h3 className="heading">Historique des encaissements</h3>
                            <div className="content">
								<p>	Pour la phase de lancement de la plateforme MonKolis, le règlement des transactions s'effectue en direct entre l'expéditeur et le transporteur.</p>
								{/*} <p className="mar-bot30">Sous combien de temps recevrai-je mon argent ? </p>
                                <div className="testimonials"></div>
                                <div>VOTRE HISTORIQUE EST VIDE</div>*/}
                            </div>
                        </div>
                    </aside>
                </div >
            )
		}
		else{
			const columns = [
				{
					name: 'Date',
					selector: 'departure_date',
					sortable: true,
				},
				{
					name: 'Details',
					selector: 'details',
					sortable: true,
				},
				{
					name: 'Montant (€)',
					selector: 'total_service_charge_amount',
					sortable: true,
				},
			];
		return (
			<div class="col-sm-12">

				<aside class="aside section">
					<div class="section-inner">
						<h3 class="heading">Historique des encaissements</h3>
						<p>	Pour la phase de lancement de la plateforme MonKolis, le règlement des transactions s'effectue en direct entre l'expéditeur et le transporteur.</p>
						{/*<p>

							<span class="txt-bold">Sous combien de temps recevrai-je mon argent ?</span>
							<br />
								Si vous avez demandé votre virement depuis plus de 24 heures, nous vous l'avons viré.
								Il apparaîtra sur votre compte sous 5 jours ouvrés. Pour voir ce que nous vous avons déjà envoyé, consultez l'historique des virements.
							  <br /><br />
						</p>*/}
						
						<div className="col-sm-30">
							<DataTable
								title="Liste des trajets"
								columns={columns}
								data={this.state.datas}
								pagination
								selectableRowsComponent
							/>
						</div>
					
						
					</div>
					
							  																	
			</aside>


	</div>);
		}
	}
}
export default withRouter(MyMoneyHistoric);