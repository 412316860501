
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { GET_MY_EXPEDITIONS_HISTORIC, EVALUATE_TRAVELER_QUERY } from '../../../query/queryexpeditions';
import { TRAVEL_ACTIONS_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyExpeditionsHistoric extends Component {
    constructor(props) {
        super(props);
        this.nbeElementOfPage = 3
        this.offsetInitial = 0
        this.state = {
            pageCount: 0,
            offset: this.offsetInitial,
            perPage: this.nbeElementOfPage,
            currentPage: 0,
            data: [],
            tmp: '',
            commentary: '',
            showEvalModal: false,
            nameTmp: '',
            marks: '5/5 Très bien',
            requester_proposals: [],
        };
        this.handleCommentaryChange = this.handleCommentaryChange.bind(this);
        this.handleCloseEvalModal = this.handleCloseEvalModal.bind(this);
        this.handleShowEvalModal = this.handleShowEvalModal.bind(this);
        this.handleEvalRequest = this.handleEvalRequest.bind(this);
        this.handleChangeMakrs = this.handleChangeMakrs.bind(this);
    }

    handleChangeMakrs(event) {
        this.setState({ marks: event.target.value })
    }

    handleCommentaryChange(event) {
        this.setState({ commentary: event.target.value })
    }

    handleCloseEvalModal() {
        this.setState(
            { showEvalModal: false }
        )
    }

    handleShowEvalModal(event, valueIdProposal, valueName) {
        event.preventDefault();
        this.setState({
            showEvalModal: !this.state.showEvalModal,
            tmp: valueIdProposal,
            nameTmp: valueName
        }
        )
    }

    componentWillMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
        this.getExpeditionsHistoric(TRAVEL_ACTIONS_URL, this.idToken);
    }

    getExpeditionsHistoric = async (url, idToken) => {
        try {
            const response = await axios.post(url,
                { "query": GET_MY_EXPEDITIONS_HISTORIC },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );



            this.setState(() => ({
                requester_proposals: response.data.data.getUserRequestersProposals.requester_proposals,
                pageCount: Math.ceil(response.data.data.getUserRequestersProposals.requester_proposals.length / this.nbeElementOfPage),
                data: response.data.data.getUserRequestersProposals.requester_proposals.length > this.nbeElementOfPage ? response.data.data.getUserRequestersProposals.requester_proposals.slice(this.offsetInitial, this.nbeElementOfPage) : response.data.data.getUserRequestersProposals.requester_proposals,
            }));

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }

    handleEvalRequest(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_proposal": this.state.tmp,
            "marks": this.state.marks[0],
            "comment": this.state.commentary
        });

        this.evalRequest(EVALUATE_TRAVELER_QUERY, VAR)
    }

    evalRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            // Log the response so we can look at it in the console

            // Set the data to the state

            if (response.data.data.requesterProposalEvaluationTravel.status === 200) {
                alert('Votre évaluation a bien été prise en compte')
                window.location.reload(false);
            } else {
                alert('Echec lors de l\'evaluation')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in evaluation')
        }
    }

    loadHistoricData() {
        const elements = this.state.requester_proposals;


        this.setState({
            data: elements.slice(this.state.offset, this.state.offset + this.state.perPage),
            pageCount: Math.ceil(elements.length / this.state.perPage)
        });

    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = selected * this.state.perPage;

        this.setState({ currentPage: selected, offset: offset }, () => {
            this.loadHistoricData();
        });
    };

    getKolisStatus(status) {
        switch (status) {
            case 'INITIATED':
                return "Reservation en attente de validation"
            case 'VALIDATED':
                return "Réservation validée"
            case 'PACKAGE_RECEIVED':
                return "Colis remis au transporteur"
            case 'DELIVERED':
                return "Livraison confirmée"
            case 'REJECTED':
                return "Réservation refusée"
            case 'CANCELED_BY_REQUESTER':
                return "Expédition annulée"
            case 'CANCELED_BY_TRAVELER':
                return "Réservation annulée"
            default:
                return "";
        }
    }

    getKolisStatusMessage(status) {
        switch (status) {
            case 'INITIATED':
                return "Votre demande de réservation est en attente de validation du transporteur."
            case 'VALIDATED':
                return "Votre demande de réservation a été validée. Lors du dépôt du colis, le transporteur vous remettra le code de dépôt. Ce code doit obligatoirement être renseigné dans votre compte afin d'obtenir le code de livraison."
            case 'PACKAGE_RECEIVED':
                return "Votre colis a été déposé chez le transporteur. Lors de votre livraison, n'oubliez pas de lui remettre le code de livraison obtenu en cliquant sur le bouton ci-dessous."
            case 'DELIVERED':
                return "Expédition du colis confirmée par vous et par le transporteur."
            case 'REJECTED':
                return "Demande de réservation refusée par le transporteur."
            case 'CANCELED_BY_REQUESTER':
                return "Vous avez annulé votre expédition moins de 72h avant la date de fin des réservations."
            case 'CANCELED_BY_TRAVELER':
                return "Votre réservation a été annulée par le transporteur."
            default:
                return "";
        }
    }

    render() {
        return (
            <Fragment>
                <div class="section mar-bot20">
                    <div class="section-inner">
                        <h3 class="heading">Historique des expéditions</h3>
                        <p>
                            Retrouvez ici l'historique de vos expéditions.
                    </p>
                    </div>
                </div>
                {this.state.data.length === 0 ?
                    <div className="section">
                        <div className="section-inner">
                            Vous n'avez pas d'expéditions en cours.
                        </div>
                    </div>
                    :

                    this.state.data.map((value, index) => {
                        return <div key={index} >
                            <div class="card exped mar-bot20">
                                <div class="card-header">
                                    <div class="card-header_content">
                                        <div class="card-header_title">
                                            <h3 class="card-title">
                                                <span class="">{value.travel_infos.departure_city}</span>&nbsp;
                                                <i class="fa fa-long-arrow-right color-text2"></i>&nbsp;
                                                <span class="">{value.travel_infos.arrival_city}</span>
                                            </h3>
                                            <h4 className="card-subtitle"> <span className="txt-bold head">Id du trajet: </span>{value.id_travel}</h4>
                                            <div className="card-header_status">
                                                <span className="txt-bold">Statut:</span> <span className="txt">{this.getKolisStatus(value.request_status)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div className="col-md-5 criteria">
                                            <p><span className="fa fa-1x fa-calendar"></span>  {Moment(value.travel_infos.departure_date).format('D-MM-YYYY')}</p>
                                            <p><span className="fa fa-1x fa-tags  color-orange"></span>  {value.weight_reserved} {value.measure_unit === undefined ? 'Unité(s)' : value.measure_unit} réservés - {value.estimated_amount_value / 1.25} €</p>
                                            <p><span className="fa fa-1x fa-user-circle color-blue"></span>  
                                            {
                                                                value.user_traveler_attributes.account_type === null || value.user_traveler_attributes.account_type === undefined || value.user_traveler_attributes.account_type === 'null' || value.user_traveler_attributes.account_type === 'undefined' || value.user_traveler_attributes.account_type === "Particulier" || value.user_traveler_attributes.account_type === "" ? 
                                                                <span className="media-heading">{value.user_traveler_attributes.given_name}</span>
                                                                :
                                                                value.user_traveler_attributes.account_type_details !== ""|| value.user_traveler_attributes.account_type_details !=="null" || value.user_traveler_attributes.account_type_details !=="undefined" || value.user_traveler_attributes.account_type_details !== null || value.user_traveler_attributes.account_type_details !== undefined ? 
                                                                <span className="media-heading">{value.user_traveler_attributes.account_type_details.company_name}</span> 
                                                                : 
                                                                <span className="media-heading">{value.user_traveler_attributes.given_name}</span>
                                                                
                                                            } 
                                            
                                            </p>
                                        </div>
                                        <div className="col-md-7 comments">
                                            <div>{this.getKolisStatusMessage(value.request_status)}<br /><br />

                                                {value.request_status === 'DELIVERED' ?
                                                    <button type="button" href="" className="btn btn-theme2 btn-sm" data-toggle="modal" data-target="#modal-evaluate-trip" onClick={(e) => { this.handleShowEvalModal(e, value.id_proposal, value.user_requester_attributes.given_name) }}><i class="fa fa-star" ></i> Laisser un avis</button>
                                                    : null}

                                            </div>

                                        </div>
                                        <Modal show={this.state.show} onHide={this.handleClose} centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title> Inscription validée</Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <p>Bravo, votre inscription a bien été validée. Vous pouvez à présent vous connecter et proposer votre trajet ou réserver chez un transporteur. Buton : Me connecter.</p>
                                            </Modal.Body>

                                            <Modal.Footer>
                                                <button className="btn btn-outline-primary" onClick={this.handleClose}>
                                                Me connecter.
                                            </button>
                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* fin du map */ }
                    })}
                <ReactPaginate
                    previousLabel={'«'}
                    nextLabel={'»'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination float-right'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    disabledClassName={'desabled'}
                />

                <Modal show={this.state.showEvalModal} onHide={this.handleCloseEvalModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Evaluer {this.state.nameTmp}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="modal-body">
                            <span class="txt-bold">Choisissez l'adjectif qui résume le mieux votre expérience avec ce membre.</span>
                            <br />
                            <br />

                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">Ma note</label>
                                <select class="form-control" value={this.state.marks} onChange={this.handleChangeMakrs}>
                                    <option>5/5 Très bien</option>
                                    <option>4/5 Bien</option>
                                    <option>3/5 Moyen</option>
                                    <option>2/5 Déçu</option>
                                    <option>1/5 Très déçu</option>
                                </select>
                                <div className="form-group">
                                    <label for="exampleFormControlTextarea1">Laisser un commentaire</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentaryChange}></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseEvalModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={this.handleEvalRequest}>
                            Valider
                        </button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}
export default withRouter(MyExpeditionsHistoric);
