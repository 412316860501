import axios from 'axios';
import React, { Component, Fragment } from 'react';
import Moment from 'moment';
import { withRouter, Redirect } from 'react-router-dom';
import Searchform from '../../components/searchform/searchform';
import { GET_OTHER_USER_DASHBOARD_QUERY, GET_TRVAEL_INFO_BY_RS_QUERY } from './../../query/queryprofil';
import { REPORT_USER_ABUSE_QUERY } from './../../query/travelquery';
import { WRITE_TO_TRAVELER_QUERY } from '../../query/queryexpeditions';
import { TRAVEL_ACTIONS_URL, NOTIFICATION_URL, GET_TRAVEL_INFO_BY_RS_URL } from '../../Url';
import { OPEN_DATA_URL } from './../../Url'
import PHOTO from '../../assets/img/user/user0.png';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/search.css';
import './../../assets/css/modal.css';
import StarRatings from 'react-star-ratings';
import Modal from 'react-bootstrap/Modal'
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { ThemeConsumer } from 'styled-components';
import { Helmet } from "react-helmet";
import {
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
} from "react-social-buttons";
import moment from 'moment';
import 'moment/min/locales'

class Traveldetailsrs extends Component {
    constructor(props) {
        super(props);

        if (this.props.match.params) {
            try {
                var travel_id = this.props.match.params.id_travel;
            } catch (err) {
                this.setState({ loading: false, error: true });
            }
        }

        var travel_id = this.props.match.params.id_travel
        if (localStorage.getItem('tripdetail') !== null) {
            var tripdetail = localStorage.getItem('tripdetail')
            let decodde = new Buffer(tripdetail, 'base64');
            this.details = JSON.parse(decodde.toString('utf-8'));
            this.user_proposal_user_name = this.details.user_proposal_attributes.username
            // this.user_proposal_user_name = this.details.user_proposal_attributes.email
        }

        this.state = {
            cgu_accepted: true,
            /*   email : this.details.email,
              arrival_city: this.details.arrival_city,
              departure_city: this.details.departure_city,
              departure_date: Moment(this.details.departure_date).format('LLLL'),
              booking_deadline: Moment(this.details.booking_deadline).format('LLLL'),
              transport_company: this.details.transport_company,
              recovery_place: this.details.recovery_place,
              take_off_place: this.details.take_off_place,
              user_proposal_given_name: this.details.user_proposal_attributes.given_name,
              user_proposal_family_name: this.details.user_proposal_attributes.family_name,
              registration_date: Moment(this.details.registration_date).format('LLLL'),
              picture: this.details.user_proposal_attributes.picture,
              max_weight_available: this.details.max_weight_available,
              weigth_available_info_weigth_available: this.details.weigth_available_info.weigth_available,
              proposal_amount_by_kg: this.details.proposal_amount_by_kg,
              min_weight_booking: this.details.min_weight_booking,
              measure_unit: this.details.measure_unit === undefined ? 'Unité' : this.details.measure_unit,
              travel_description: this.details.others_infos,
              among_reserved: 0,
              cgu_accepted: true,
              error: null,
              isLoaded: false,
              user_proposal_sub: this.details.id_user_proposal,
              showSendToModal: false,
  
              user_proposal_username: this.details.user_proposal_attributes.email, */
            //items_others_dashboard: {},


            locality: '',
            description: '',
            id_card_verified: '',
            phone_number_verified: '',
            email_verified: '',
            user_create_date: '',
            iat: '',
            total_of_travel: '',
            total_of_requester: '',
            mark5: '',
            mark4: '',
            mark3: '',
            mark2: '',
            mark1: '',
            evaluations: [],
            level: '',
            progress_percent: '',
            //user_attributes: this.details.user_proposal_attributes,
            commentary: '',
            object: '',
            showReportModal: false,
            tmpIdSub: '',

            //get traveldetails from 
            id_travel: travel_id,
            //id_travel: 'tp-41a0681a-cb94-4751-baeb-26fbbd087007',
            arrival_city: '',
            departure_date: '',
            booking_deadline: '',
            departure_city: '',
            proposal_amount_by_kg: '',
            recovery_place: '',
            registration_date: '',
            take_off_place: '',
            family_name: '',
            given_name: '',
            picture: '',
            transport_company: '',
            has_check_ticket: 'N',
            others_infos: '',
            measure_unit: '',
            weigth_available_info_weigth_available: '',
            min_weight_booking: '',
            user_proposal_sub: '',
            service_charge_amount: '',
            itemstravels: [],





        };


        this.handleCloseReportModal = this.handleCloseReportModal.bind(this)
        this.handletTermsClick = this.handletTermsClick.bind(this);
        this.handleAmongReservedChange = this.handleAmongReservedChange.bind(this);
        this.handleAcceptCGU = this.handleAcceptCGU.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.handleCloseWritoModal = this.handleCloseWritoModal.bind(this);
        this.handleCommentary_change = this.handleCommentary_change.bind(this);
        this.handleObjectChange = this.handleObjectChange.bind(this);
        this.handleWriteToClick = this.handleWriteToClick.bind(this);
        // this.handleReportAbuseUserClick = this.handleReportAbuseUserClick.bind(this);
        this.handleReportAbuseSubmit = this.handleReportAbuseSubmit.bind(this);
    }

    handleReportAbuseUserClick(event, idSub) {
        event.preventDefault();
        this.setState({
            showReportModal: !this.state.showReportModal,
            tmpIdSub: idSub
        }
        )
    }

    handleCloseReportModal() {
        this.setState(
            { showReportModal: false }
        )
    }

    handleCloseWritoModal() {
        this.setState(
            { showSendToModal: false }
        )
    }

    handleObjectChange(event) {
        this.setState({
            object: event.target.value
        })
    }

    handleCommentary_change(event) {
        this.setState({ commentary: event.target.value })
    }

    // componentWillMount() {
    //     var VAR2 = JSON.stringify({
    //         "userSub": sessionStorage.getItem('userSub'),
    //         "username": this.state.user_proposal_username,
    //     });
    //     this.getOtherUserDashbordInfo(GET_OTHER_USER_DASHBOARD_QUERY, VAR2)
    // }

    componentDidMount() {
        
        this.idToken = getCookieConsentValue('idToken')
        this.current_user_sub = sessionStorage.getItem('userSub')
        var VAR = JSON.stringify({ "id_travel": this.state.id_travel });
        this.getTravelInfofromFB(GET_TRVAEL_INFO_BY_RS_QUERY, VAR)

    }


    getOtherUserDashbordInfo = async (query, variables) => {
        try {
            const response = await axios.post(OPEN_DATA_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            // Log the response so we can look at it in the consol

            if (response.data.data.getOtherUserDashboard.status === 200) {
                var tmp = JSON.stringify(response.data.data.getOtherUserDashboard.user_dashboard)
                let buff = new Buffer(tmp);
                let base64data = buff.toString('base64');
                localStorage.setItem('user_dahsboard_details', base64data)


                tmp = JSON.stringify(this.state.user_attributes)
                buff = new Buffer(tmp);
                base64data = buff.toString('base64');
                localStorage.setItem('user_attributes', base64data)

                this.setState(() => ({
                    locality: response.data.data.getOtherUserDashboard.user_dashboard.address !== null ? response.data.data.getOtherUserDashboard.user_dashboard.address.locality : '',
                    description: response.data.data.getOtherUserDashboard.user_dashboard.description !== null ? response.data.data.getOtherUserDashboard.user_dashboard.description : '',
                    id_card_verified: response.data.data.getOtherUserDashboard.user_dashboard.verifications.id_card_verified,
                    phone_number_verified: response.data.data.getOtherUserDashboard.user_dashboard.verifications.phone_number_verified,
                    email_verified: response.data.data.getOtherUserDashboard.user_dashboard.verifications.email_verified,
                    user_create_date: response.data.data.getOtherUserDashboard.user_dashboard.activities.user_create_date,
                    iat: response.data.data.getOtherUserDashboard.user_dashboard.activities.iat,
                    total_of_travel: response.data.data.getOtherUserDashboard.user_dashboard.activities.total_of_travel,
                    total_of_requester: response.data.data.getOtherUserDashboard.user_dashboard.activities.total_of_requester,
                    evaluations: response.data.data.getOtherUserDashboard.user_dashboard.evaluations_receive,
                    mark5: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '5') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '5').nbre : '0',
                    mark4: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '4') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '4').nbre : '0',
                    mark3: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '3') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '3').nbre : '0',
                    mark2: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '2') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '2').nbre : '0',
                    mark1: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '1') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '1').nbre : '0',
                    progress_percent: response.data.data.getOtherUserDashboard.user_dashboard.activities.positifs_evaluation_receive_part,
                    level: response.data.data.getOtherUserDashboard.user_dashboard.activities.level_of_experience,
                    items_others_dashboard: response.data.data.getOtherUserDashboard.user_dashboard
                }));
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }

    handleShowWriteToModal(event, valueIdProposal, valueName) {
        event.preventDefault();
        this.setState({
            showSendToModal: !this.state.showSendToModal,
            tmp: valueIdProposal,
            nameTmp: valueName
        }
        )
    }

    handlePayment(event) {
        event.preventDefault();
        localStorage.setItem('among_reserved', this.state.among_reserved);
        
        if (getCookieConsentValue('idToken') !== undefined) {
            this.props.history.push({
                pathname: '../payment'
            });
        }
        else {
            this.props.history.push({
                pathname: '../connexion'
            });
        }

    }

    handleAcceptCGU() {
        this.setState({ cgu_accepted: !this.state.cgu_accepted })
    }

    handleAmongReservedChange(event) {
        this.setState({ among_reserved: event.target.value })
    }

    handletTermsClick(event) {
        event.preventDefault();
        window.open("/terms", '_blank')
    }

    handleWriteToClick(event) {
        event.preventDefault();
        if (this.state.commentary !== "") {
            var VAR = JSON.stringify({
                "notification": {
                    "id_proposal": this.state.tmp,
                    "msg_object": this.state.object,
                    "msg_content": this.state.commentary,
                    "type_notification": "MSG-WEB"
                }

            });
            this.writeToTravelerRequest(WRITE_TO_TRAVELER_QUERY, VAR)
        }

    }

    writeToTravelerRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.notifiedProposalTravelByRequester.status === 200) {
                alert('Votre message à bien été envoyé au voyageur')
                window.location.reload(false);
            } else {
                alert('Echec lors de l\'envoi de votre message')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleReportAbuseSubmit(event) {
        event.preventDefault();
        if (this.state.commentary !== "") {
            var VAR = JSON.stringify({
                "make_report_on_user_notification": {
                    "report_object": this.state.object,
                    "report_content": this.state.commentary,
                    "type_notification": "MSG-WEB",
                    "receiver_username": this.user_proposal_user_name,
                    "receiver_id_user": this.state.tmpIdSub
                }

            });
            this.reportAbuseUser(REPORT_USER_ABUSE_QUERY, VAR)
        }

    }

    reportAbuseUser = async (query, variables) => {
        try {
            const response = await axios.post(NOTIFICATION_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.makeReportOnUser.status === 200) {
                alert('Votre message à bien été envoyé au administrateur')
                window.location.reload(false);
            } else {
                alert('Echec lors de l\'envoi de votre message')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }



    handleUserProfileClick = value => () => {
        this.props.history.push({
            pathname: '../user',
            data: this.state.items_others_dashboard,
            data_user_attributes: this.state.user_attributes
        });

    };


    getTravelInfofromFB = async (query, variables) => {
        try {
            const response = await axios.post(GET_TRAVEL_INFO_BY_RS_URL, {
                query,
                variables
            });

            console.log(response.data)

            // Set the data to the state
            this.setState(() => ({
                isLoaded: true,
                itemstravels: response.data.data.getTravelProposalInfoAlone.travel_proposal
            }));

            if (response.data.data.getTravelProposalInfoAlone.status === 200) {
                var tmp = JSON.stringify(this.state.itemstravels)
                let buff = new Buffer(tmp);
                let base64data = buff.toString('base64');
                localStorage.setItem('tripdetail', base64data)

                this.setState(() => ({
                    arrival_city: this.state.itemstravels.arrival_city,
                    departure_date: this.state.itemstravels.departure_date,
                    booking_deadline: this.state.itemstravels.booking_deadline,
                    departure_city: this.state.itemstravels.departure_city,
                    proposal_amount_by_kg: this.state.itemstravels.proposal_amount_by_kg,
                    recovery_place: this.state.itemstravels.recovery_place,
                    registration_date: this.state.itemstravels.registration_date,
                    has_check_ticket: this.state.has_check_ticket,
                    take_off_place: this.state.itemstravels.take_off_place,
                    family_name: this.state.itemstravels.user_proposal_attributes.family_name,
                    given_name: this.state.itemstravels.user_proposal_attributes.given_name,
                    user_proposal_given_name: this.state.itemstravels.user_proposal_attributes.given_name,
                    user_proposal_family_name: this.state.itemstravels.user_proposal_attributes.family_name,
                    picture: this.state.itemstravels.user_proposal_attributes.picture,
                    transport_company: this.state.itemstravels.transport_company,
                    others_infos: this.state.itemstravels.others_infos,
                    measure_unit: this.state.itemstravels.measure_unit,
                    service_charge_amount: this.state.itemstravels.service_charge_amount,
                    weigth_available_info_weigth_available: this.state.itemstravels.weigth_available_info.weigth_available,
                    min_weight_booking: this.state.itemstravels.min_weight_booking,
                    max_weight_available: this.state.itemstravels.max_weight_available,
                    user_proposal_sub: this.state.itemstravels.id_user_proposal,
                    user_proposal_username: this.state.itemstravels.user_proposal_attributes.username
                }));


            }


        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }

    render() {
        moment.locale('fr');
        return (
            <Fragment>
                <Helmet>
                    <title>MonKolis : Transport de colis à l’international entre particuliers </title>
                    <meta name="robots" content="noindex,follow" />
                </Helmet>
                <div className="traveldetailslrs">
                    <div className="container-fluid container-fluid-pad container-fluid-br">
                        <div className="pad-top20">
                            <div className="formulaire">
                                <Searchform />
                            </div>
                        </div>

                        <div className="row titres">
                            <div className="col-md-7">
                                <h3 className="mar-top0">
                                    <span>{this.state.departure_city} </span>
                                    <i className="fa fa-exchange color-text2"></i>
                                    <span> {this.state.arrival_city}</span>

                                    <span className="color-green"> {this.state.has_check_ticket == "Y" ?
                                        <i className="fa fa-ticket color-yellow"> Billet
                                                        vérifié </i> : this.state.has_check_ticket === "E" ? "Billet en cours de vérification" : ''}</span>
                                </h3>
                            </div>

                            <div className="col-md-5">
                                <p className="align-right">{"Trajet publié le " + this.state.registration_date}</p>

                            </div>

                        </div>

                        <div className="row">
                            {/* debut information du voyage  */}
                            <div className="col-md-8">
                                <div className="describe section">
                                    <div className="section-inner">
                                        <div className="row mar-bot15">
                                            <div className="col-lg-4">
                                                <p className="mar-bot0 color-text3 txt-bold">Date de départ</p>
                                            </div>
                                            <div className="col-lg-8">
                                                <p className="mar-bot0 txt-bold"><i className="fa fa-calendar "></i><span>&nbsp; {Moment(this.state.departure_date).format('DD/MM/YYYY')}</span></p>
                                            </div>
                                        </div>
                                        <div className="row mar-bot15">
                                            <div className="col-lg-4">
                                                <p className="mar-bot0 color-text3">Fin des réservations</p>
                                            </div>
                                            <div className="col-lg-8">
                                                <p className="mar-bot0"><i className="fa fa-clock-o color-orange"></i><span>&nbsp; {Moment(this.state.booking_deadline).format('DD/MM/YYYY LT')}</span></p>
                                            </div>
                                        </div>
                                        <div className="row mar-bot15">
                                            <div className="col-lg-4">
                                                <p className="mar-bot0 color-text3">Moyen de transport</p>
                                            </div>
                                            <div className="col-lg-8">
                                                <p className="mar-bot0">{this.state.transportation_type === null ?
                                                    <span className="fa fa-1x fa-plane icon"></span> :
                                                    null
                                                }
                                                    {this.state.transportation_type === "Avion" ?
                                                        <span className="fa fa-1x fa-plane icon"></span> :
                                                        null
                                                    }
                                                    {this.state.transportation_type === "Bâteau" ?
                                                        <span className="fa fa-1x fa-ship icon"></span> :
                                                        null
                                                    }
                                                    {this.state.transportation_type === "Voiture" ?
                                                        <span className="fa fa-1x fa-car icon"></span> :
                                                        null
                                                    }
                                                    {this.state.transportation_type === "Train" ?
                                                        <span className="fa fa-1x fa-train icon"></span> :
                                                        null
                                                    }<span>  {this.state.transport_company}</span></p>
                                            </div>
                                        </div>

                                        <div className="row mar-bot15">
                                            <div className="col-lg-4">
                                                <p className="mar-bot0 color-text3 txt-bold">Date d'arrivée</p>
                                            </div>
                                            <div className="col-lg-8">
                                                <p className="mar-bot0"><i className="fa fa-calendar"></i><span className="txt-bold">&nbsp; {this.state.arrival_date}</span></p>
                                            </div>
                                        </div>

                                        <div className="row mar-bot15">
                                            <div className="col-lg-4">
                                                <p className="mar-bot0 color-text3">Lieu de dépôt</p>
                                            </div>
                                            <div className="col-lg-8">
                                                <p className="mar-bot0"><i className="fa fa-map-marker color-green"></i><span>&nbsp; {this.state.take_off_place}</span></p>
                                            </div>
                                        </div>
                                        <div className="row mar-bot15">
                                            <div className="col-lg-4">
                                                <p className="mar-bot0 color-text3">Lieu de livraison</p>
                                            </div>
                                            <div className="col-lg-8">
                                                <p className="mar-bot0"><i className="fa fa-map-marker  color-blue"></i><span>&nbsp; {this.state.recovery_place}</span></p>
                                            </div>
                                        </div>
                                        <div className="row mar-bot20">
                                            <div className="col-lg-4">
                                                <p className="mar-bot0 color-text3">Validation des réservations</p>
                                            </div>
                                            <div className="col-lg-8">
                                                <p className="mar-bot0"><span>{this.state.is_automatic_approval == "Y" ? <i className="fa fa-flash color-yellow"> La confirmation de votre réservation est automatique</i> : 'La confirmation de votre expédition sera soumise à validation du voyageur'} </span></p>
                                            </div>
                                        </div>
                                        {/* <div className="row mar-bot20">
                                                <div className="col-lg-4">
                                                    <p className="mar-bot0 color-text3 txt-bold">Voyage retour</p>
                                                </div>
                                                <div className="col-lg-8">
                                                    <p className="mar-bot0 "><i />Je fais également le voyage retour sur ce trajet le <span className="txt-bold"></span></p>

                                                    <a href="#" className="link color-blue"><i className="fa fa-long-arrow-right"></i>Voir mon retour</a>


                                                </div>
                                            </div>  */}


                                        <div className="row mar-bo30 mar-top10">
                                            <div className="col-lg-4">
                                                <p className="mar-bot0 color-text3">Préférences</p>
                                            </div>
                                            <div className="col-lg-4">
                                                <p className="mar-bot15 txt-underline">Je prends</p>
                                                <p className="mar-bot10"><i className="fa fa-1x fa-envelope color-blue"></i><span>&nbsp; Enveloppes</span></p>
                                                <p className="mar-bot10"><i className="fa fa-1x fa-suitcase color-blue"></i><span>&nbsp; Sacs</span></p>
                                                <p className="mar-bot0"><i className="fa fa-1x fa-cube color-blue"></i><span>&nbsp; Paquets</span></p>
                                            </div>

                                            <div className="col-lg-4">
                                                <p className="mar-bot15 txt-underline">Je ne prends pas</p>
                                                <p className="mar-bot10"><i className="fa fa-1x fa-tint color-red"></i><span>&nbsp; Liquides</span></p>
                                                <p className="mar-bot10"><i className="fa fa-1x fa-cutlery color-red"></i><span>&nbsp; Nourriture</span></p>
                                            </div>
                                        </div>

                                        <div className="media mar-top20">
                                            <div className="media-left ">
                                                <div className="photo">
                                                    {this.state.picture === null || this.state.picture === 'null' ?
                                                        <img src={PHOTO} alt="Photo" className="media-object cover rounded" /> :
                                                        <img src={this.state.picture} alt="Photo" className="media-object cover rounded" />}
                                                </div>
                                            </div>
                                            <div className="media-body pad-left10">

                                                {/* <a onClick={this.handleUserProfileClick()} class="link color-blue"> <h6>{this.state.user_proposal_given_name} {this.state.user_proposal_family_name}</h6></a> */}
                                                <p className="mar-bot10">{this.state.travel_description === '' ? '' : this.state.travel_description}</p>
                                                {/*  <a type="btn" className="btn btn-theme2" href="#" data-toggle="modal" data-target="#modal-write" /*onClick={(e) => { this.handleShowWriteToModal(e, this.state.user_proposal_sub, this.state.user_proposal_given_name) }} >Ecrire au voyageur</a>  */}

                                                <div className="modal fade" id="modal-write" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" >

                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal fade" id="modal-signal-trip" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" >
                                        </div>


                                    </div>
                                </div>
                            </div>
                            {/* fin information du voyage  */}





                            {/* DEbut information du Voyageur  */}
                            <div className="col-md-4">
                                <div className="paybox section">
                                    <div className="payhead">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <p className="align-left"><span className="color-text2">Par {this.state.measure_unit}</span></p>
                                            </div>
                                            <div className="col-lg-6">
                                                <h2 className="align-right"><span> {this.state.service_charge_amount}</span>€</h2>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="paybody">
                                        <div className="progress mar-bot0 mar-top0 ">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-color-green" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: this.state.weigth_available_info_weigth_available === 'null' ? "100%" : this.state.weigth_available_info_weigth_available * 100 / this.state.max_weight_available + "%" }}>
                                                {this.state.weigth_available_info_weigth_available === 'null' ? "100%" : parseInt(this.state.weigth_available_info_weigth_available * 100 / this.state.max_weight_available)}%
								</div>
                                        </div>
                                        <h5 className="mar-bot0 mar-top10 align-right"><span className="txt-bold">{this.state.weigth_available_info_weigth_available === 'null' ? 0 : this.state.weigth_available_info_weigth_available} </span><span className="color-text2 txt-bold"> {this.state.measure_unit}(s) restant(s)</span></h5>
                                    </div>
                                    <div className="payfoot">

                                        <p className="mar-bot0">Le minimum à reserver est de <span className="txt-bold">{this.state.min_weight_booking} </span><span className="txt-bold"> {this.state.measure_unit}(s)</span> </p>

                                        <form className="mar-top0 mar-bot10" onSubmit={this.handlePayment}>

                                            <div className="form-group has-feedback">
                                                <input type="number" className="form-control" placeholder={"Combien de " + this.state.measure_unit + " ?"} min={this.state.min_weight_booking} max={this.state.weigth_available_info_weigth_available} value={this.state.among_reserved} onChange={this.handleAmongReservedChange} required />
                                            </div>
                                            {
                                                this.state.user_proposal_sub === this.current_user_sub ?
                                                    <div className="mar-top10">
                                                        <input type="submit" className="btn btn-primary btn-lg btn-block" value="Réserver" disabled={true} />
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className="mar-bot10 mar-top20">
                                                            <input id="checkbox5" type="checkbox" className="magic-checkbox" onChange={this.handleAcceptCGU} />
                                                            <label for="checkbox5">J'accepte les <a href="" className="link color-blue" onClick={this.handletTermsClick}>CGU</a>	</label>
                                                        </div>
                                                        <div className="mar-top10">
                                                            <input type="submit" className="btn btn-primary btn-lg btn-block" value="Réserver" disabled={this.state.cgu_accepted} />
                                                        </div>
                                                    </div>
                                            }


                                        </form>
                                    </div>
                                </div>
                                {/* <div className="card mar-bot30 mar-top20">
                                <div className="card-header">
                                    <h3 className="card-title">Transporteur</h3>
                                </div>
                                <div className="card-body">
                                    <div className="media mar-bot5">
                                        <div className="media-left media-top">
                                            <div className="photo">
                                                {this.state.picture === null || this.state.picture === 'null' ?
                                                    <img src={PHOTO} alt="Photo" className="media-object cover rounded" /> :
                                                    <img src={this.state.picture} alt="Photo" className="media-object cover rounded" />}
                                            </div>
                                        </div>
                                        <div className="media-body pad-left10">
                                            <a onClick={this.handleUserProfileClick()} className="link color-blue"> <h5 className="media-heading">{this.state.user_proposal_given_name} {this.state.user_proposal_family_name}</h5></a>
                                            <p className="color-text2">{this.state.locality}</p>
                                        </div>
                                    </div>

                                    <p className="mar-bot0">
                                        <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                        <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                        <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                        <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                        <i className="fa fa-star-half-o color-yellow" aria-hidden="true" ></i>
                                        <i>- {this.state.evaluations.length} avis</i>
                                    </p>
                                    <hr />

                                    <ul className="fa-ul txt-bold color-green">
                                        {console.log('id:' + this.state.id_card_verified)}
                                        {this.state.id_card_verified !== 'true' || this.state.id_card_verified === null || this.state.id_card_verified === '' ?
                                            <li className="color-red"><i className="fa-li fa fa-close"></i><p>Pièce d'identité</p></li> :
                                            <li><i className="fa-li fa fa-check-square-o"></i><p>Pièce d'identité</p></li>
                                        }
                                        {console.log('phone: ' + this.state.phone_number_verified)}
                                        {this.state.phone_number_verified === 'false' ?
                                            <li className="color-red"><i className="fa-li fa fa-close"></i><p>Téléphone</p></li> :
                                            <li><i className="fa-li fa fa-check-square-o"></i><p>Téléphone</p></li>
                                        }

                                        {this.state.email_verified === 'false' ?
                                            <li className="color-red"><i className="fa-li fa fa-close"></i><p>Email</p></li> :
                                            <li><i className="fa-li fa fa-check-square-o"></i><p>Email</p></li>
                                        }

                                        {/* {this.state.ad ==='false' ? 
                                            <li className="color-red"><i className="fa-li fa fa-close"></i><p>Adresse</p></li>:
                                            <li><i className="fa-li fa fa-check-square-o"></i><p>Adresse</p></li>
                                        } */}
                                {/* <li className="color-facebook"><i className="fa-li fa fa-facebook-square"></i><p className="color-text2">1640 Amis</p></li> */}


                                {/*</ul>
                                    <hr />

                                    <p className="mar-bot5">Membre depuis : {Moment(this.state.user_create_date).format('YYYY. MMMM')} </p>
                                    <p className="mar-bot5">Nombre d'expéditions: {this.state.total_of_travel}</p>
                                    <p className="mar-bot5">Nombre de trajets: {this.state.total_of_requester}</p>

                                    <hr />

                                    <h3 className="mar-top10 mar-bot10 ">Avis reçus</h3>

                                    <div className="testimonials">



                                        {this.state.evaluations.map((value, index) => {
                                            return <div className="item" key={index} >

                                                {value.evaluation !== null ? <div>
                                                    <div className="quote">
                                                        <StarRatings
                                                            rating={value.evaluation.marks}
                                                            starRatedColor="red"
                                                            numberOfStars={5}
                                                            name='rating'
                                                            starDimension='20px'
                                                            starSpacing='0,5px'
                                                        />

                                                        <p>

                                                            {value.evaluation.comment}</p>
                                                    </div>
                                                    <p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}, {Moment(value.evaluation.evaluation_date).format('MMM-YYYY')}</span></p>
                                                </div>
                                                    : <div>
                                                        Vous n'avez pas laissé de commentaire
												        <p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}</span></p>
                                                    </div>
                                                }

                                            </div>
                                        })}
                                    </div>

                                    {/* <a href="/user" /* onClick={this.handleUserProfileClick()  className="link color-blue"><i className="fa fa-long-arrow-right"></i> Voir le profil du voyageur</a> */}



                                {/* <div className="mar-top40 mar-bot0">
                                                {
                                                   
                                                    this.user_proposal_user_name !== sessionStorage.getItem('email') ?
                                                        <a href="" className="link color-red" data-toggle="modal" data-target="#modal-signal-user" onClick={(e) => { this.handleReportAbuseUserClick(e, this.state.user_proposal_sub) }}>Signaler ce membre</a> :
                                                        null
                                                }

                                            </div>*/}

                                {/*<div className="modal fade" id="modal-signal-user" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" >

                                    </div>

                                    <Modal show={this.state.showSendToModal} onHide={this.handleCloseWritoModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Message</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="form-group">
                                                <label for="exampleFormControlTextarea1">Objet </label>
                                                <input className="form-control" id="exampleFormControlTextarea1" value={this.state.object} onChange={this.handleObjectChange} />
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleFormControlTextarea1">Laisser votre message </label>
                                                <input className="form-control" id="exampleFormControlTextarea1" value={this.state.commentary} onChange={this.handleCommentary_change} />
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-outline-primary" onClick={this.handleCloseWritoModal}>
                                                Annuler
                                            </button>
                                            <button className="btn btn-primary" onClick={(e) => { this.handleWriteToClick(e) }}>
                                                Valider
                                            </button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={this.state.showReportModal} onHide={this.handleCloseReportModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Message</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="form-group">
                                                <label for="exampleFormControlTextarea1">Objet </label>
                                                <input className="form-control" id="exampleFormControlTextarea1" value={this.state.object} onChange={this.handleObjectChange} />
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleFormControlTextarea1">Laisser votre message </label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentary_change} />
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-outline-primary" onClick={this.handleCloseReportModal}>
                                                Annuler
                                            </button>
                                            <button className="btn btn-primary" onClick={(e) => { this.handleReportAbuseSubmit(e) }}>
                                                Valider
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div> */}
                            </div>
                            {/* Fin information du Voyageur */}

                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
    //}
}
export default withRouter(Traveldetailsrs);