import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/login.css';
import './../../assets/css/modal.css';
import { withRouter } from 'react-router-dom'
import { NEW_PASSWORD_QUERY } from '../../query/queryauthent';
import { NEW_PASSORD_URL } from '../../Url';

class PasswordChange extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            confirmation_code: '',
            confirm_password: '',
            new_password: '',
            validated: false,
            msgAlertBody: '',
            msgAlertHeader: '',
            alertVariant: 'danger',
            showAlert: false,
            error: null,
            isLoaded: false,

            isPasswordShown : false,

            items: []
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleConfirmationCodeChange = this.handleConfirmationCodeChange.bind(this);
        this.handleMdpChange = this.handleMdpChange.bind(this);
        this.handleConfirmMdpChange = this.handleConfirmMdpChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    togglePasswordVisibily = () => {
		const {isPasswordShown} = this.state;
		this.setState({ isPasswordShown : !isPasswordShown})

	}

    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
        }
        else{
            if (this.state.new_password !== this.state.confirm_password) {
                this.setState(() => ({
                    validated: true
                }));
                this.setState(() => ({
					showAlert : true,
					msgAlertBody : 'Les mots de passe ne correspondent pas',
					msgAlertHeader : 'Error',
					
				}));
            }
            else {
                var VAR = JSON.stringify({
                    "username": this.state.email,
                    "verification_code": this.state.confirmation_code,
                    "new_password": this.state.new_password
                })
                this.passwordchange(NEW_PASSWORD_QUERY, VAR)
            }

        }
        
    }

    handleCloseAlert(event) {
        this.setState({ showAlert: false });
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    handleConfirmationCodeChange(event) {
        this.setState({ confirmation_code: event.target.value });
    }

    handleMdpChange(event) {
        this.setState({ new_password: event.target.value });
    }

    handleConfirmMdpChange(event) {
        this.setState({ confirm_password: event.target.value });
    }

    passwordchange = async (query, variables) => {
        try {
            const response = await axios.post(NEW_PASSORD_URL, {
                query,
                variables
            });
            
            this.setState(() => ({
				isLoaded: true,
				items: response.data.data
            }));
           

            if (this.state.items.confirmPasswordUser.status === 400) {
				if (this.state.items.confirmPasswordUser.message === "Bad Request") {
					this.setState(() => ({
						showAlert : true,
						msgAlertBody : 'Verifiez les informations entrées svp',
						msgAlertHeader : 'Error',
						
					}));
					//alert("Veillez svp entrer un mot de passe respectant la politique de mot de passe")
				}
                else{
                    this.setState(() => ({
						showAlert : true,
						msgAlertBody : 'Verifiez les informations entrées svp"',
						msgAlertHeader : 'Error',
						
					}));
                }

			}
			else {
				this.props.history.push('../connexion');
            }
            
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }

    render() {
        const {isPasswordShown} = this.state;
        return (
            <Fragment>
                <Helmet>
                    <title>Modifier mot de passe | Monkolis</title>
                    <meta name="robots" content="noindex,follow" />
                </Helmet>

                <div className="Password-change">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">

                                <div className="col-lg-6 col-md-8 col-sm-8 col-12 d-center">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h3 className="heading">Modifier mon mot de passe</h3>
                                            {this.state.showAlert ?
                                                <Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert} dismissible>
                                                    <Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
                                                    <p>{this.state.msgAlertBody} </p>
                                                </Alert> : ''}

                                            <p>Veuillez Saisir votre nouveau mot de passe.</p>


                                            <Form className="well" validated={this.state.validated} onSubmit={this.handleSubmit}>

                                                <Form.Group controlId="validationCustom01">
                                                    <Form.Control
                                                        required
                                                        type="email"
                                                        placeholder="Email"
                                                        defaultValue={this.state.email}
                                                        onChange={this.handleEmailChange}
                                                    />
                                                    <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                                                </Form.Group>

                                                <Form.Group controlId="validationCustom01">
                                                    <Form.Control
                                                        required
                                                        type=""
                                                        placeholder="Code de confirmation"
                                                        defaultValue={this.state.confirmation_code}
                                                        onChange={this.handleConfirmationCodeChange}
                                                    />
                                                    <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                                                </Form.Group>

                                                <Form.Group controlId="validationCustom01">
                                                    <Form.Label className="text-muted"><em className="align-center">Le mot de passe doit être de 8 carractères minimum</em></Form.Label>
                                                    <Form.Control
                                                        required
                                                        type={(isPasswordShown) ? "text" : "password"}
                                                        placeholder="Mot de passe"
                                                        defaultValue={this.state.new_password}
                                                        onChange={this.handleMdpChange}
                                                    />
                                                    <span className="viewpass"><i className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"}`}
                                                        onClick={this.togglePasswordVisibily}></i></span>
                                                    <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                                                </Form.Group>

                                                <Form.Group controlId="validationCustom01">
                                                    <Form.Control
                                                        required
                                                        type={(isPasswordShown) ? "text" : "password"}
                                                        placeholder="Mot de passe"
                                                        defaultValue={this.state.confirm_password}
                                                        onChange={this.handleConfirmMdpChange}
                                                    />
                                                     <span className="viewpass"><i className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"}`}
                                                        onClick={this.togglePasswordVisibily}></i></span>
                                                    <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                                                </Form.Group>

                                                <input type="submit" className="btn btn-primary btn-block" value="Valider" />

                                            </Form>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}

export default withRouter(PasswordChange);