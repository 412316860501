
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { GET_USER_PARTICIPATION_QUERY } from '../../../query/querycrowfunding';
import { CONTRIBUTION_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import double from './../../../assets/img/other/double.png';
import Moment from "moment";
import DataTable from 'react-data-table-component';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyCrowfunding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			datas: [],
		};
		//this.id_campaign = 'cf-9ec4018c-0117-47db-8314-4a5f1c6a9117'
	}

	componentWillMount() {
		
		this.idToken = getCookieConsentValue('idToken');
		this.contrib_cwf_balance = getCookieConsentValue('contrib_cwf_balance');
		// this.idToken = sessionStorage.getItem('idToken');
		// this.contrib_cwf_balance = sessionStorage.getItem('contrib_cwf_balance');
		this.getCampainInfos(CONTRIBUTION_URL)
	}

	getCampainInfos = async (url) => {
		try {
			const response = await axios.post(url,
				{ "query": GET_USER_PARTICIPATION_QUERY},
				{
					'headers':
					{
						'Authorization': this.idToken,
					}
				}
			);
			// Set the data to the state
			const tmp = [];
			response.data.data.getUserGainsAndRequestersDiscounts.gains_discounts.forEach(element => {
                tmp.push({
                    registration_date: Moment(element.registration_date).format('DD-MM-YYYY'),
                    amount: element.type ==='CROW_FUNDING' ? element.amount : -element.amount,
                    details: element.details,
                    type: element.type ==='CROW_FUNDING' ? 'Crow funding' : 'Achat expédition',
                });
            });
			this.setState({
				datas: tmp,
			})

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	render() {
		const columns = [
            {
                name: 'Date',
                selector: 'registration_date',
                sortable: true,
            },
            {
                name: 'Détails',
                selector: 'details',
                sortable: true,
            },
            {
                name: 'Type',
                selector: 'type',
                sortable: true,
			},   
			{
                name: 'Montant',
                selector: 'amount',
                sortable: true,
            },
        ];
		return (
			<div class="col-sm-12">
				<aside class="aside section crowfund">
					<div class="section-inner">
						<h3 class="heading">Crowfunding - Double Cash Back</h3>
						<div class="row">
							<div class="col-sm-5">
								<img src={double} alt="MDN Logo" class="cover" />
							</div>
							<div class="col-sm-7">
								<h4 class="color-text2">Donnez <span class="color-green">10 €</span><br />
	       																			Obtenez le double <span class="color-green">20 €</span></h4>

								<p>
									Vous retrouvez ici tous vos paiements effectués sur
									MonKolis (réservations acceptées automatiquement ou manuellement) ainsi que vos remboursements en cours ou effectués.
													            				<br /><br />

																				Peu importe le montant que vous donnez, nous reversons le double dans votre compte afin que vous puissiez l'utiliser pour vos
																								futures expéditions.<br /><br />
																						En plus, parce que ça compte, nous vous disons Mille fois Merci!<br /></p>

								<a href="/crowfunding" class="btn btn-block btn-yellow"> Consulter l'opération ?</a><br /><br />

							</div>
						</div>

						<h3 class="heading">Mes opérations Double Cash Back - <span class="color-text2">Solde </span><span class="color-green">{this.contrib_cwf_balance === 'null' ? 0 : this.contrib_cwf_balance} €</span></h3>

						<div className="col-sm-30">
							<DataTable
								title="Liste des réservations"
								columns={columns}
								data={this.state.datas}
								pagination
								selectableRowsComponent
							/>
						</div>	
						{/* <table class="table">
							<tr>
								<th class="">Date</th>
								<th class="">Détails</th>
								<th class="">Type</th>
								<th class="">Montant</th>
							</tr>
							<tr>
								{
								this.state.datas.map((value, index) => {
									return <div class="item" key={index} >
										<td class="">19/12/2013 </td>
										<td class="">
											{value.details}
																</td>
										<td class="">{value.type === 'CROW_FUNDING' ? 'Crow funding' : 'Achat expédition'} </td>
										<td class="">{value.amount} €</td>
									</div>
								})}
							</tr>
							<tr>
								<td class=""> </td>
								<td class=""> </td>
								<td class="txt-bold">Solde</td>
								<td class="txt-bold">{this.contrib_cwf_balance} €</td>
							</tr>

						</table> */}
					</div>
				</aside>

			</div>
		);
	}
}
export default withRouter(MyCrowfunding);