import React, { Component } from 'react';
import logo from './../../assets/img/other/axa.png';
import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';


class ServiceBlockAimer extends Component {
    render() {
        return (  
            <section className="align-center useme">
                <div className="container-fluid container-fluid-pad ">
                <h2 className="align-left mar-bot30">Pourquoi utiliser MonKolis ?</h2>
                    <div className="row">
                        <div className="col-sm-4" >
                            <div className="service">
                                <i className="fa fa-credit-card-alt fa-5x mar-bot20 color-green" aria-hidden="true"  ></i>
                                <h4  className="color-text2">Gagnez de l'argent en toute quiétude</h4>
                                <p>En trouvant facilement et rapidement des expéditeurs de kolis.</p>
                                <br/>
                            </div>
                        </div>

                        <div className="col-sm-4" >
                            <div className="service">
                                <i className="fa fa-handshake-o fa-5x mar-bot20 color-green" aria-hidden="true" ></i>
                                <h4  className="color-text2">Transportez en toute confiance</h4>
                                <p>Grâce au système de vérification des profils des membres de la communauté.</p>
                             
                            </div>
                        </div>

                        <div className="col-sm-4" >
                            <div className="service">
                                <i className="fa fa-smile-o fa-5x mar-bot20 color-green"></i>
                                <h4  className="color-text2">Expédiez en toute assurance</h4>
                                <p>Livraison à destination garantie en cas d'incident.</p>
                                {/* <img src={logo} alt="AXA" className="cover"/> */}
                                <br/>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
          );
    }
  }

  
  export default ServiceBlockAimer;
