export const GET_MY_EXPEDITIONS_IN_PROGRESS = `query {
    getUserRequestersProposals(booking_time: "in-progress") {
      status
      message
      requester_proposals {
        id_proposal
        id_user_requester
        id_travel
        transaction_id
        transaction_amount
        transaction_date
        transaction_status
        registration_date
        weight_reserved
        estimated_amount_value
        town
        additional_infos
        request_status
        last_date_response
        response_comment
        receiver_infos {
          firstname
          lastname
          email
          contacts {
            contact_type
            contact_value
          }
        }
        traveler_evaluation {
          evaluation_date
          marks
          comment
        }
        requester_evaluation {
          evaluation_date
          marks
          comment
        }
        notifications  {
          msg_id
          msg_date
          msg_object
          msg_content
          type_notification
          status
          expeditor_id_user
          receiver_id_user
        }
        requester_cancel_info {
          requester_cancel_date
          comment
          reimbursment_date
          reimbursment_amount
        }
        user_requester_attributes {
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
            street_address
            locality
            region
            postal_code
            country
          }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
       user_traveler_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        account_type
        account_type_details {
            registration_number
            company_name
            company_country_name
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }

       travel_infos {
        departure_date
        departure_city
        arrival_city
        measure_unit
      }
      package_received_delivered_infos {
        received_code
        received_code_setup_date
        received_code_validation_date
        delivered_code
        delivered_code_setup_date
        delivered_code_validation_date
      }
      }
      errors{
        param
        msg
      }
    }
  }
`

export const GET_MY_EXPEDITIONS_HISTORIC = `query {
    getUserRequestersProposals(booking_time: "past") {
      status
      message
      requester_proposals {
        id_proposal
        id_user_requester
        id_travel
        transaction_id
        transaction_amount
        transaction_date
        transaction_status
        registration_date
        weight_reserved
        estimated_amount_value
        town
        additional_infos
        request_status
        last_date_response
        response_comment
        receiver_infos {
          firstname
          lastname
          email
          contacts {
            contact_type
            contact_value
          }
        }
        traveler_evaluation {
          evaluation_date
          marks
          comment
        }
        requester_evaluation {
          evaluation_date
          marks
          comment
        }
        notifications  {
          msg_id
          msg_date
          msg_object
          msg_content
          type_notification
          status
          expeditor_id_user
          receiver_id_user
        }
        requester_cancel_info {
          requester_cancel_date
          comment
          reimbursment_date
          reimbursment_amount
        }
        user_requester_attributes {
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
            street_address
            locality
            region
            postal_code
            country
          }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
       user_traveler_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        account_type
        account_type_details {
            registration_number
            company_name
            company_country_name
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }

       travel_infos {
        departure_date
        departure_city
        arrival_city
        measure_unit
      }
      }
      errors{
        param
        msg
      }
    }
  }
`
export const CANCEL_RESERVATION_QUERY = `mutation ($id_proposal: String!, $requester_comment: String!){
  requesterCancelProposalTravel(id_proposal: $id_proposal, requester_comment: $requester_comment) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
    }
    errors{
      param
      msg
    }
  }
}
`

export const WRITE_TO_TRAVELER_QUERY = `mutation($notification: NotificationInput!) {
  notifiedProposalTravelByRequester(notification: $notification) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
    }
    errors{
      param
      msg
    }
  }
}
`
export const EVALUATE_TRAVELER_QUERY =`mutation($id_proposal: String!, $marks: String!, $comment: String!) {
  requesterProposalEvaluationTravel(id_proposal: $id_proposal, marks: $marks, comment: $comment) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        account_type
        account_type_details {
              registration_number
              company_name
              company_country_name
          }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
    }
    errors{
      param
      msg
    }
  }
}
`
export const REQUESTER_DECRYPT_CODE_QUERY = `query($id_proposal: String!, $cipher_code: String!) {
  decryptDeliveredCode(id_proposal: $id_proposal, cipher_code: $cipher_code) {
    status
    message
	decrypt_data{
	  KeyId
	  Plaintext
	  EncryptionAlgorithm
	}
    errors{
      param
      msg
    }
  }
}
`

export const VALIDATE_CODE_QUERY = `mutation($id_proposal : String!, $plaintext_code : String!) {
  validatedReceivedCode(id_proposal: $id_proposal, plaintext_code: $plaintext_code) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
     package_received_delivered_infos {
     	received_code
     	received_code_setup_date
     	received_code_validation_date
     	delivered_code
     	delivered_code_setup_date
     	delivered_code_validation_date
     }
    }
    errors{
      param
      msg
    }
  }
}
`