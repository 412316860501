
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import './../../../assets/css/modal.css'
import axios from 'axios';
import { GET_MY_EXPEDITIONS_IN_PROGRESS, CANCEL_RESERVATION_QUERY, WRITE_TO_TRAVELER_QUERY, REQUESTER_DECRYPT_CODE_QUERY, VALIDATE_CODE_QUERY } from '../../../query/queryexpeditions';
import { TRAVEL_ACTIONS_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import PHOTO from '../../../assets/img/user/user0.png';
import Moment from 'moment';
import Modal from 'react-bootstrap/Modal'
import { createBrowserHistory } from 'history';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyExpeditionsInProgress extends Component {
    constructor(props) {
        super(props);
        this.codeDecrypt = '';
        this.nbeElementOfPage = 3
        this.offsetInitial = 0
        this.state = {
            pageCount: 0,
            offset: this.offsetInitial,
            perPage: this.nbeElementOfPage,
            currentPage: 0,
            data: [],
            dataAll: [],
            open: false,
            showDeleteModal: false,
            tmp: '',
            commentary: '',
            showSendToModal: false,
            showDeleteModal: false,
            showReceivedCodeModal: false,
            showValidationCodeModal: false,
            object: '',
            nameTmp: '',
            code: '',
        };

        this.handleCancelReservationClick = this.handleCancelReservationClick.bind(this);
        this.handleWriteToClick = this.handleWriteToClick.bind(this);

        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.handleShowDeleteModal = this.handleShowDeleteModal.bind(this);
        this.handleCommentary_change = this.handleCommentary_change.bind(this);
        this.handleObjectChange = this.handleObjectChange.bind(this);
        this.handleShowReceivedCodeModalClick = this.handleShowReceivedCodeModalClick.bind(this);
        this.handleCloseReceiveCodeModalClick = this.handleCloseReceiveCodeModalClick.bind(this);
        this.handleCloseValidationCodeModal = this.handleCloseValidationCodeModal.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleShowValidationModal = this.handleShowValidationModal.bind(this);
        this.handleValidationCodeClick = this.handleValidationCodeClick.bind(this);
        this.handleCloseWritoModal = this.handleCloseWritoModal.bind(this);
    }

    handleObjectChange(event) {
        this.setState({
            object: event.target.value
        })
    }

    handleCommentary_change(event) {
        this.setState({ commentary: event.target.value })
    }

    handleCodeChange(event) {
        this.setState({ code: event.target.value })
    }

    handleCloseDeleteModal() {
        this.setState(
            { showDeleteModal: false }
        )
    }

    handleCloseWritoModal() {
        this.setState(
            { showSendToModal: false }
        )
    }

    handleCloseReceiveCodeModalClick() {
        this.setState(
            { showReceivedCodeModal: false }
        )
    }

    handleCloseValidationCodeModal(event, id_proposal) {
        this.setState(
            {
                showValidationCodeModal: false,
                tmp: id_proposal
            }
        )
    }

    handleShowWriteToModal(event, valueIdProposal, valueName) {
        event.preventDefault();
        this.setState({
            showSendToModal: !this.state.showSendToModal,
            tmp: valueIdProposal,
            nameTmp: valueName
        }
        )
    }

    handleShowDeleteModal(event, value) {
        event.preventDefault();
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            tmp: value
        }
        )
    }

    handleShowReceivedCodeModalClick(event, id_proposal, code) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_proposal": id_proposal,
            "cipher_code": code,
        });
        this.decryptCode(REQUESTER_DECRYPT_CODE_QUERY, VAR)

    }

    decryptCode = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );



            if (response.data.data.decryptDeliveredCode.status === 200) {
                this.codeDecrypt = response.data.data.decryptDeliveredCode.decrypt_data.Plaintext
                this.setState({
                    showReceivedCodeModal: !this.state.showReceivedCodeModal
                })
            } else {
                alert('Echec lors du décodage')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in cipher value sending')
        }
    }

    handleShowValidationModal(event, id_proposal) {
        event.preventDefault();
        this.setState({
            showValidationCodeModal: !this.state.showValidationCodeModal,
            tmp: id_proposal
        })
    }

    handleValidationCodeClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_proposal": this.state.tmp,
            "plaintext_code": this.state.code
        });

        this.validateCode(VALIDATE_CODE_QUERY, VAR)
    }

    validateCode = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            // Log the response so we can look at it in the console

            // Set the data to the state

            if (response.data.data.validatedReceivedCode.status === 200) {
                alert('Le dépot du colis est confirmé auprès du transporteur !')
                this.setState({
                    showValidationCodeModal: !this.state.showValidationCodeModal
                })
                window.location.reload(false);
            } else {
                alert('Echec lors du décodage')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    componentWillMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        this.getExpeditionsInProgress(TRAVEL_ACTIONS_URL, this.idToken);
    }

    getExpeditionsInProgress = async (url, idToken) => {
        try {
            const response = await axios.post(url,
                { "query": GET_MY_EXPEDITIONS_IN_PROGRESS },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );



            this.setState(() => ({
                pageCount: Math.ceil(response.data.data.getUserRequestersProposals.requester_proposals.length / this.nbeElementOfPage),
                data: response.data.data.getUserRequestersProposals.requester_proposals.length > this.nbeElementOfPage ? response.data.data.getUserRequestersProposals.requester_proposals.slice(this.offsetInitial, this.nbeElementOfPage) : response.data.data.getUserRequestersProposals.requester_proposals,
                dataAll: response.data.data.getUserRequestersProposals.requester_proposals
            }));

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }

    handleWriteToClick(event) {
        event.preventDefault();
        if (this.state.commentary !== "") {
            var VAR = JSON.stringify({
                "notification": {
                    "id_proposal": this.state.tmp,
                    "msg_object": this.state.object,
                    "msg_content": this.state.commentary,
                    "type_notification": "MSG-WEB"
                }

            });

            this.writeToTravelerRequest(WRITE_TO_TRAVELER_QUERY, VAR)
        }

    }

    writeToTravelerRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );



            if (response.data.data.notifiedProposalTravelByRequester.status === 200) {
                alert('Votre message à bien été envoyé au voyageur')
                window.location.reload(false);
            } else {
                alert('Echec lors de l\'envoi de votre message')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleCancelReservationClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_proposal": this.state.tmp,
            "requester_comment": this.state.commentary
        });

        this.cancelReservationRequest(CANCEL_RESERVATION_QUERY, VAR)
    }

    cancelReservationRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );



            if (response.data.data.requesterCancelProposalTravel.status === 200) {
                alert('Reservation annulée')
                this.setState({ commentary: '' });
                window.location.reload(false);
            } else {
                alert('Echec de l\'annualtion de votre réservation')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in the deletation')
        }
    }

    loadHistoricData() {
        const elements = this.state.dataAll;

        this.setState({
            data: elements.slice(this.state.offset, this.state.offset + this.state.perPage),
            pageCount: Math.ceil(elements.length / this.state.perPage)
        });

    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = selected * this.state.perPage;

        this.setState({ currentPage: selected, offset: offset }, () => {
            this.loadHistoricData();
        });
    };

    getKolisStatus(status) {
        switch (status) {
            case 'INITIATED':
                return "Reservation en attente de validation"
                break;
            case 'VALIDATED':
                return "Réservation validée"
                break;
            case 'PACKAGE_RECEIVED':
                return "Colis remis au transporteur"
                break;
            case 'DELIVERED':
                return "Livraison confirmée"
                break;
            case 'REJECTED':
                return "Réservation refusée"
                break;
            case 'CANCELED_BY_REQUESTER':
                return "Expédition annulée"
                break;
            case 'CANCELED_BY_TRAVELER':
                return "Trajet annulé"
                break;
            default:
                return "";
        }
    }

    getKolisStatusMessage(status) {
        switch (status) {
            case 'INITIATED':
                return "Votre demande de réservation est en attente de validation du transporteur."
            case 'VALIDATED':
                return "Votre demande de réservation a été validée. Lors du dépôt du colis, le transporteur vous remettra le code de dépôt. Ce code doit obligatoirement être renseigné dans votre compte afin d'obtenir le code de livraison."
            case 'PACKAGE_RECEIVED':
                return "Votre colis a été déposé chez le transporteur. Lors de votre livraison, n'oubliez pas de lui remettre le code de livraison obtenu en cliquant sur le bouton ci-dessous."
            //return "Demande de réservation en attente de validation du voyageur. Vous n'avez pas été prélevé."
            case 'DELIVERED':
                return "Expédition du colis confirmée par vous et par le transporteur."
            case 'REJECTED':
                return "Votre demande de réservation a été refusée par le transporteur. N'hésitez pas à rechercher un nouveau transporteur."
            case 'CANCELED_BY_REQUESTER':
                return "Vous avez annulé votre expédition moins de 72h avant la date de fin des réservations."
            case 'CANCELED_BY_TRAVELER':
                return "Votre réservation a été annulée par le transporteur."
            default:
                return "";
        }
    }

    componentDidMount() {

        const { history } = this.props;

        if (history && history.listen) {

            history.listen(location => {
                console.log('Listening location change', location);
            })

            this.unblockCallback = history.block((location, action) => {
                if (this.state.showAlert) {
                    return "Are you sure you want to leave the site?"
                }

            })
        }
    }




    render() {


        return (
            <Fragment>
                <div className="section mar-bot20">
                    <div className="section-inner">
                        <h3 className="heading">Mes expéditions</h3>
                        {/* <p>
                            Vous pouvez annuler vos réservations selon les  conditions ci-dessous :
                            <br /><br />
                            <span className="txt-bold">Conditions d'annulation :</span><br />
                            Le transporteur annule : remboursement intégral. <br/>
                            Vous annulez plus de 72 heures avant la date limite de réservation : remboursement du prix hors frais de réservation.<br />
                            Vous annulez au cours des 72 heures précédant la date limite de réservation : remboursement de 50 % du prix hors frais de réservation.<br />
                            Vous annulez moins de 24 heures avant la date limite de réservation : pas de remboursement.

                        </p>*/}

                        {/*<span className="txt-bold">Codes et suivi de vos colis :</span><br />*/}
                        <p>
                            <span className="txt-bold">Code de dépôt :</span> Lorsque vous remettez votre colis au transporteur, ce dernier vous donne le code de dépôt. Vous devez obligatoirement renseigner ce code dans votre espace pour prouver que vous lui avez bien remis le colis. Une fois ce code renseigné dans votre espace, vous obtenez alors le code de livraison.
                        </p>
                        <p>
                            <span className="txt-bold">Code de livraison :</span>  Une fois que vous avez validé le dépôt, vous recevez le code de livraison. Vous devrez remettre ce code au transporteur lors de la livraison de votre colis. Ce code lui servira à prouver qu'il vous a bien livré le colis.
                        </p>

                    </div>
                </div>
                {/* début du map  */}
                {this.state.data.length === 0 ?
                    <div className="section">
                        <div className="section-inner">
                            Vous n'avez pas d'expéditions en cours.
                        </div>
                    </div>
                    :

                    this.state.data.map((value, index) => {
                        return <div key={index} >
                            <div className="card exped mar-bot20">
                                <div className="card-header">
                                    <div className="card-header_content">
                                        <div className="card-header_title">
                                            <h3 className="card-title">
                                                <span>{value.travel_infos.departure_city}</span>&nbsp;

                                                <i className="fa fa-long-arrow-right color-text2"></i>&nbsp;

                                                <span>{value.travel_infos.arrival_city}</span>

                                            </h3>
                                            <h4 className="card-subtitle"> <span className="txt-bold head">Id du trajet: </span>{value.id_travel}</h4>
                                            <div className="card-header_status">
                                                <span className="txt-bold">Statut :</span> <span className="txt">{this.getKolisStatus(value.request_status)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 criteria">
                                            <p><span className="fa fa-1x fa-calendar"></span >  {Moment(value.travel_infos.departure_date).format('D-MM-YYYY')}</p>
                                            <p><span className="fa fa-1x fa-tags  color-orange"></span >  {value.weight_reserved} {value.travel_infos.measure_unit === undefined ? 'Unité' : value.travel_infos.measure_unit} réservés - {value.estimated_amount_value} €</p>
                                            <p>
                                                <i className="fa fa-1x fa-user-circle color-blue"></i> &nbsp;
                                                {
                                                    value.user_traveler_attributes.account_type === null || value.user_traveler_attributes.account_type === undefined || value.user_traveler_attributes.account_type === 'null' || value.user_traveler_attributes.account_type === 'undefined' || value.user_traveler_attributes.account_type === "Particulier" || value.user_traveler_attributes.account_type === "" ?
                                                        <span className="media-heading">{value.user_traveler_attributes.given_name}</span>
                                                        :
                                                        value.user_traveler_attributes.account_type_details !== "" || value.user_traveler_attributes.account_type_details !== "null" || value.user_traveler_attributes.account_type_details !== "undefined" || value.user_traveler_attributes.account_type_details !== null || value.user_traveler_attributes.account_type_details !== undefined ?
                                                            <span className="media-heading">{value.user_traveler_attributes.account_type_details.company_name}</span>
                                                            :
                                                            <span className="media-heading">{value.user_traveler_attributes.given_name}</span>

                                                }
                                            </p>
                                            <p> <i className="fa fa-mobile"></i> {value.user_traveler_attributes.phone_number === null || value.user_traveler_attributes.phone_number === 'null' || value.user_traveler_attributes.phone_number === undefined || value.user_traveler_attributes.phone_number === 'undefined' ? '' : value.user_traveler_attributes.phone_number}</p>
                                        </div>
                                        <div className="col-md-6">
                                            {this.getKolisStatusMessage(value.request_status)}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="trips-features">
                                        <div className="features_button2 mar-top0">
                                            <button type="button" href="" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleShowWriteToModal(e, value.id_proposal, value.user_traveler_attributes.given_name) }} > <span className="fa fa-1x fa-pencil-square-o"></span>
                                            Ecrire à
                                            {
                                                    value.user_traveler_attributes.account_type === null || value.user_traveler_attributes.account_type === undefined || value.user_traveler_attributes.account_type === 'null' || value.user_traveler_attributes.account_type === 'undefined' || value.user_traveler_attributes.account_type === "Particulier" || value.user_traveler_attributes.account_type === "" ?
                                                        <span className="media-heading"> {value.user_traveler_attributes.given_name}</span>
                                                        :
                                                        value.user_traveler_attributes.account_type_details !== "" || value.user_traveler_attributes.account_type_details !== "null" || value.user_traveler_attributes.account_type_details !== "undefined" || value.user_traveler_attributes.account_type_details !== null || value.user_traveler_attributes.account_type_details !== undefined ?
                                                            <span className="media-heading"> {value.user_traveler_attributes.account_type_details.company_name}</span>
                                                            :
                                                            <span className="media-heading"> {value.user_traveler_attributes.given_name}</span>

                                                }
                                            </button>
                                            <button type="button" href="" className="btn btn-danger btn-sm" data-toggle="modal" data-target="#modal-del-my-exp" onClick={(e) => { this.handleShowDeleteModal(e, value.id_proposal) }}> <span className="fa fa-1x fa-trash"></span> Annuler</button>
                                            {value.request_status === "PACKAGE_RECEIVED" ? <button type="button" href="" className="btn btn-success btn-sm" data-toggle="modal" data-target="#modal-voir-code-livraison" onClick={(e) => { this.handleShowReceivedCodeModalClick(e, value.id_proposal, value.package_received_delivered_infos !== null ? value.package_received_delivered_infos.delivered_code : 'null') }}><i className="fa fa-check-square-o"></i> Code de livraison </button> : null}
                                            {value.request_status !== "VALIDATED" ? '' : <button type="button" href="" className="btn btn-success btn-sm" data-toggle="modal" data-target="#modal-confirm-code-depot" onClick={(e) => { this.handleShowValidationModal(e, value.id_proposal) }}><i className="fa fa-check-square-o"></i> Confirmer le dépôt du colis? </button>}

                                            {/*<a href="" className="link color-blue" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleShowWriteToModal(e, value.id_proposal, value.user_requester_attributes.given_name) }} > <span className="fa fa-1x fa-pencil"></span> Ecrire à {value.user_requester_attributes.given_name}</a>
                                                <br />
                                                <a href="" className="link color-red" data-toggle="modal" data-target="#modal-del-my-exp" onClick={(e) => { this.handleShowDeleteModal(e, value.id_proposal) }}> <span className="fa fa-1x fa-close color-red"></span> Annuler la réservation</a>
                                                <br />
                                                <a href="" className="link color-green" data-toggle="modal" data-target="#modal-del-my-exp" onClick={(e) => { this.handleShowValidationModal(e, value.id_proposal) }}> <span className=" color-green link fa fa-circle-o" aria-hidden="true"></span> Valider le code</a>
                                                <br />
                                                <a href="" className="link color-blue" data-toggle="modal" data-target="#modal-del-my-exp" onClick={(e) => { this.handleShowReceivedCodeModalClick(e, value.id_proposal, value.package_received_delivered_infos !== null ? value.package_received_delivered_infos.delivered_code : 'null') }}> <span className=" color-blue link fa fa-eye" aria-hidden="true"></span> Consulter le code</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* fin du map */ }


                    })}
                <ReactPaginate
                    previousLabel={'«'}
                    nextLabel={'»'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination float-right'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    disabledClassName={'desabled'}
                />
                <Modal show={this.state.showDeleteModal} onHide={this.handleCloseDeleteModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Annuler mon expédition</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Laisser un commentaire</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentary_change}></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseDeleteModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={(e) => { this.handleCancelReservationClick(e) }}>
                            Valider
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showSendToModal} onHide={this.handleCloseWritoModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Envoyer un Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <p>Vous pouvez retrouver le suivi de vos échanges dans la section messages de votre compte.</p>
                            <label for="exampleFormControlTextarea1">Objet </label>
                            <input className="form-control" id="exampleFormControlTextarea1" value={this.state.object} onChange={this.handleObjectChange} />
                        </div>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Votre message </label>

                            <textarea cols="30" rows="4" className="form-control" id="exampleFormControlTextarea1" value={this.state.commentary} onChange={this.handleCommentary_change} placeholder="Demander des détails supplémentaires au transporteur / expéditeur"></textarea>
                            <small id="travelTicket" className="form-text text-muted">
                                Par mesure de sécurité, prière de ne pas indiquer de <span className="fa fa-1x fa-phone-square"></span> Numéro de téléphone ou <span className="fa fa-1x fa-facebook-square"></span> de détails de votre facebook </small>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseWritoModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={(e) => { this.handleWriteToClick(e) }}>
                            Valider
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showValidationCodeModal} onHide={this.handleCloseValidationCodeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title variant="txt-bold">Confirmer le dépôt de colis.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <span className="txt-bold">Entrez le code pour valider le dépôt du colis.</span> <br />
                            <label for="FormCode">Code de dépôt reçu</label>
                            <input type="text" className="form-control" id="travelFirm" placeholder="Code de dépôt" value={this.state.code} onChange={this.handleCodeChange} required="true" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseValidationCodeModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={(e) => { this.handleValidationCodeClick(e) }}>
                            Valider
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showReceivedCodeModal} onHide={this.handleCloseReceiveCodeModalClick} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Votre code de réservation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Le code pour la validation: <h2>{this.codeDecrypt}</h2></label>
                            <label for="exampleFormControlTextarea2">Vous devez remettre ce code au voyageur lorsque votre colis sera livré. Cela permettra à MonKolis de s'assurer que la livraison a bien été effectuée</label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="btn btn-outline-primary close" onClick={this.handleCloseReceiveCodeModalClick} centered>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}
export default withRouter(MyExpeditionsInProgress);
