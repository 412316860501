import React, { Component, Fragment } from 'react';
import SearchBar from './../../components/searchbar/searchbar';
import ServiceBlockAimer from './../../components/serviceblock/serviceblockaimer';
import ServiceBlockSimple from './../../components/serviceblock/serviceblocksimple';
import ServiceBlockVoyage from './../../components/serviceblock/serviceblockvoyage';
import ServiceMillier from './../../components/serviceblock/serviceblockmillier';
import ServiceVoila from './../../components/serviceblock/serviceblockvoila';
import './../../assets/css/main.css';
import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';


class Home extends Component {

  render() {
    return (
      <Fragment>
          <div className="headerwrap"></div>
          <SearchBar/>
          <ServiceBlockAimer/>
          <ServiceBlockSimple/>
          <ServiceBlockVoyage/>
          <ServiceMillier/>
          <ServiceVoila/>
          {/* <ServiceTestimonials/> */}
      </Fragment>
    );
  }
}
export default Home;