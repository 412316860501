import React, { Component } from 'react';
import './../../assets/css/main.css';
import './../../assets/css/multiple.css';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';
import MyTrips from './../dashboard/mytrips/mytrips';
import { withRouter } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
class Dashboard extends Component {
	constructor(props) {

		super(props);
		this.state = {
			
		}
	}

	componentWillMount() {
		
	}

	render() {
		return (
			<React.Fragment>
				
			</React.Fragment>
		);
	}
}
export default withRouter(Dashboard);