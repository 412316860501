import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { GET_TRAVEL_RESERVATION_LIST, REJECT_TRAVEL_PROPOSAL_QUERY, ACCEPT_TRAVEL_PROPOSAL_QUERY, SEND_MESSAGE_TO_TRAVEL_REQUESTER_QUERY, CANCEL_RESERVATION_QUERY, TRAVELER_DECRYPT_CODE, VALIDATE_DELIVER_CODE_QUERY, TRAVELER_EVALUATION_QUERY } from '../../../query/travelquery';
import { TRAVEL_ACTIONS_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Moment from "moment";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import moment from 'moment';
import 'moment/min/locales'
import './../../../assets/css/listreservation.css';

class ListReservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //travel_data 
            has_check_ticket: "",
            max_weight_available: "",
            min_weight_booking: "",
            proposal_amount_by_kg: "",
            is_automatic_approval: false,
            others_infos: "",
            take_off_place: "",
            recovery_place: "",
            status: "",
            transport_company: "",
            departure_city: "",
            arrival_city: "",
            dataValue: [],
            tmp: '',
            tmpIdproposal: '',
            commentary: '',
            showSendToModal: false,
            showDeleteModal: false,
            showAcceptationModal: false,
            showCancellationModal: false,
            showReceivedCodeModal: false,
            showRappelReceivedCodeModal: false,
            showValidationCodeModal: false,
            showRappelAcceptationModal: false,
            object: '',
            nameTmp: '',
            code: '',
            showEvalModal: false,
            marks: '5/5 Très bien',
        };
        this.listreservation = this.props.props;
        this.data = [];
        this.codeDecrypt = '';

        this.handleObjectChange = this.handleObjectChange.bind(this);
        this.handleCommentary_change = this.handleCommentary_change.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.handleCloseWritoModal = this.handleCloseWritoModal.bind(this);
        this.handleShowWriteToModal = this.handleShowWriteToModal.bind(this);
        this.handleShowDeleteModal = this.handleShowDeleteModal.bind(this);
        this.handleWriteToClick = this.handleWriteToClick.bind(this);
        this.handleRejetReservationClick = this.handleRejetReservationClick.bind(this);
        this.handleAcceptReservationClick = this.handleAcceptReservationClick.bind(this);
        this.handleCloseAcceptationModal = this.handleCloseAcceptationModal.bind(this);
        this.handleCloseRappelAcceptationModal = this.handleCloseRappelAcceptationModal.bind(this);
        this.handleShowAcceptationModal = this.handleShowAcceptationModal.bind(this);
        this.handleShowRappelAcceptationModal = this.handleShowRappelAcceptationModal.bind(this);
        this.handleCloseCancellationModal = this.handleCloseCancellationModal.bind(this);
        this.handleCancellationModalClick = this.handleCancellationModalClick.bind(this);
        this.handleCancelReservationClick = this.handleCancelReservationClick.bind(this);
        this.handleShowReceivedCodeModalClick = this.handleShowReceivedCodeModalClick.bind(this);
        this.handleRappelShowReceivedCodeModalClick = this.handleRappelShowReceivedCodeModalClick.bind(this);
        this.handleCloseReceiveCodeModalClick = this.handleCloseReceiveCodeModalClick.bind(this);
        this.handleCloseRappelReceiveCodeModalClick = this.handleCloseRappelReceiveCodeModalClick.bind(this);
        this.handleShowValidationModal = this.handleShowValidationModal.bind(this);
        this.handleValidationCodeClick = this.handleValidationCodeClick.bind(this);
        this.handleCloseValidationCodeModal = this.handleCloseValidationCodeModal.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleCommentaryChange = this.handleCommentaryChange.bind(this);
        this.handleCloseEvalModal = this.handleCloseEvalModal.bind(this);
        this.handleShowEvalModal = this.handleShowEvalModal.bind(this);
        this.handleEvalRequest = this.handleEvalRequest.bind(this);
        this.handleChangeMakrs = this.handleChangeMakrs.bind(this);
        this.handleReturnTripsClick = this.handleReturnTripsClick.bind(this);
    }

    handleReturnTripsClick() {
        window.location.reload(false);
    }

    handleChangeMakrs(event) {
        this.setState({ marks: event.target.value })
    }

    handleCommentaryChange(event) {
        this.setState({ commentary: event.target.value })
    }

    handleCloseEvalModal() {
        this.setState(
            { showEvalModal: false }
        )
    }

    handleShowEvalModal(event, valueIdProposal, valueName) {
        event.preventDefault();
        this.setState({
            showEvalModal: !this.state.showEvalModal,
            tmp: valueIdProposal,
            nameTmp: valueName
        }
        )
    }

    handleEvalRequest(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_proposal": this.state.tmp,
            "marks": this.state.marks[0],
            "comment": this.state.commentary
        });
        this.evalRequest(TRAVELER_EVALUATION_QUERY, VAR)
    }

    evalRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            if (response.data.data.requesterProposalEvaluationByTraveler.status === 200) {
                alert('Votre évaluation a bien été prise en compte')
                window.location.reload(false);
            } else {
                alert('Echec lors de l\'evaluation')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in evaluation')
            console.log(error)
        }
    }

    handleCodeChange(event) {
        this.setState({ code: event.target.value })
    }

    handleCloseValidationCodeModal(event, id_proposal, code) {
        this.setState(
            {
                showValidationCodeModal: false,
                tmp: id_proposal,
                code: code
            }
        )
    }

    handleShowValidationModal(event, id_proposal) {
        event.preventDefault();
        this.setState({
            showValidationCodeModal: !this.state.showValidationCodeModal,
            tmp: id_proposal
        })
    }

    handleValidationCodeClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_proposal": this.state.tmp,
            "plaintext_code": this.state.code
        });
        this.validateCode(VALIDATE_DELIVER_CODE_QUERY, VAR)
    }

    validateCode = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.validatedDeliveredCode.status === 200) {
                alert('Le colis a été remis au destinataire final !')
                window.location.reload(false);
            } else {
                alert('Echec lors du décodage')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleObjectChange(event) {
        this.setState({
            object: event.target.value
        })
    }

    handleCommentary_change(event) {
        this.setState({
            commentary: event.target.value,
        })
    }

    handleCloseDeleteModal() {
        this.setState(
            { showDeleteModal: false }
        )
    }

    handleCloseCancellationModal() {
        this.setState(
            { showCancellationModal: false }
        )
    }

    handleCloseAcceptationModal() {
        this.setState(
            { showAcceptationModal: false }
        )
    }

    handleCloseRappelAcceptationModal() {
        this.setState(
            { showRappelAcceptationModal: false }
        )
    }

    handleCloseWritoModal() {
        this.setState(
            { showSendToModal: false }
        )
    }

    handleCloseReceiveCodeModalClick() {
        this.setState(
            { showReceivedCodeModal: false }
        )
    }

    handleCloseRappelReceiveCodeModalClick() {
        this.setState(
            { showRappelReceivedCodeModal: false }
        )
    }

    handleShowWriteToModal(event, valueIdProposal, valueName) {
        event.preventDefault();
        this.setState({
            showSendToModal: !this.state.showSendToModal,
            tmp: valueIdProposal,
            nameTmp: valueName
        }
        )
    }

    handleShowReceivedCodeModalClick(event, id_proposal, code, type) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_proposal": id_proposal,
            "cipher_code": code,
        });
        this.decryptCode(TRAVELER_DECRYPT_CODE, VAR, type)
    }

    handleRappelShowReceivedCodeModalClick(event, id_proposal, code, type) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_proposal": id_proposal,
            "cipher_code": code,
        });
        this.decryptCode(TRAVELER_DECRYPT_CODE, VAR, type)
    }

    decryptCode = async (query, variables, type) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.decryptReceivedCode.status === 200) {
                this.codeDecrypt = response.data.data.decryptReceivedCode.decrypt_data.Plaintext
                if (type==="rappel"){
                    this.setState({
                        showRappelReceivedCodeModal: !this.state.showRappelReceivedCodeModal
                    })
                }else{
                    this.setState({
                        showReceivedCodeModal: !this.state.showReceivedCodeModal
                    })
                }
                
            } else {
                alert('Echec lors de lors du décodage')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleShowDeleteModal(event, id_travel, id_proposal) {
        event.preventDefault();
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            tmp: id_travel,
            tmpIdproposal: id_proposal
        }
        )
    }

    handleCancellationModalClick(event, id_proposal) {
        event.preventDefault();
        this.setState({
            showCancellationModal: !this.state.showCancellationModal,
            tmpIdproposal: id_proposal
        }
        )
    }

    handleShowAcceptationModal(event, id_travel, id_proposal) {
        event.preventDefault();
        this.setState({
            showAcceptationModal: !this.state.showAcceptationModal,
            tmp: id_travel,
            tmpIdproposal: id_proposal
        }
        )
    }
    handleShowRappelAcceptationModal(event, id_travel, id_proposal) {
        event.preventDefault();
        this.setState({
            showRappelAcceptationModal: !this.state.showRappelAcceptationModal,
            tmp: id_travel,
            tmpIdproposal: id_proposal
        }
        )
    }

    handleWriteToClick(event) {
        event.preventDefault();
        if (this.state.commentary !== "") {
            var VAR = JSON.stringify({
                "notification": {
                    "id_proposal": this.state.tmp,
                    "msg_object": this.state.object,
                    "msg_content": this.state.commentary,
                    "type_notification": "MSG-WEB"
                }

            });
            this.writeToTravelerRequest(SEND_MESSAGE_TO_TRAVEL_REQUESTER_QUERY, VAR)
        }

    }

    writeToTravelerRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.notifiedProposalTravelByTraveler.status === 200) {
                alert('Votre message à bien été envoyé au transporteur')
                window.location.reload(false);
            } else {
                alert('Echec lors de l\'envoi de votre message')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('Une erreur s\'est produite durant l\'envoi de votre message ')
        }
    }

    handleRejetReservationClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_travel": this.listreservation.id_travel,
            "id_proposal": this.state.tmpIdproposal,
            "response_comment": this.state.commentary
        });
        this.rejectReservationRequest(REJECT_TRAVEL_PROPOSAL_QUERY, VAR)
    }

    rejectReservationRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            // Set the data to the state

            if (response.data.data.rejectProposalTravel.status === 200) {
                alert('Reservation annulée')
                this.setState({ commentary: '' });
                window.location.reload(false);
            } else {
                alert('Echec du refus de la réservation')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('Une erreur s\est produite lors du refus de la réservation')
        }
    }

    handleCancelReservationClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_proposal": this.state.tmpIdproposal,
            "requester_comment": this.state.commentary
        });
        this.cancelReservationRequest(CANCEL_RESERVATION_QUERY, VAR)
    }

    cancelReservationRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            // Set the data to the state

            if (response.data.data.requesterCancelProposalTravelByTraveler.status === 200) {
                alert('Reservation annulée')
                this.setState({ commentary: '' });
                window.location.reload(false);
            } else {
                alert('Echec de l\'annualtion de la réservation')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('erreur dans l\'annulation')
        }
    }

    handleAcceptReservationClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_travel": this.state.tmp,
            "id_proposal": this.state.tmpIdproposal
        });
        this.acceptReservationRequest(ACCEPT_TRAVEL_PROPOSAL_QUERY, VAR)
    }

    acceptReservationRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.acceptProposalTravel.status === 200) {
                alert('Reservation effectuée')
                this.setState({ commentary: '' });
                window.location.reload(false);
            } else {
                alert('Echec de lors de la validation de la réservartion')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('erreur dans la validation')
        }
    }


    getTravelerList = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            this.data = response.data.data.getBookingsForUserTravelProposal.requester_proposals
            const tmp = []
            this.data.forEach(element => {
                tmp.push({
                    date: Moment(element.registration_date).format('D-MM-YYYY'),
                    expediteur: element.user_requester_attributes.given_name === null || element.user_requester_attributes.given_name === undefined || element.user_requester_attributes.given_name === 'undefined' || element.user_requester_attributes.given_name === 'null' || element.user_requester_attributes.given_name === ''  ? element.user_requester_attributes.name : element.user_requester_attributes.given_name,
                    quantite: element.weight_reserved,
                    telephone: element.user_requester_attributes.phone_number,
                    statut: this.getKolisStatus(element.request_status),
                    frais: parseFloat(element.estimated_amount_value).toFixed(2),
                    action: 'test',
                    code: 1,
                    id_travel: element.id_travel,
                    id_proposal: element.id_proposal,
                    status: element.request_status,
                    received_code: element.package_received_delivered_infos !== null || element.package_received_delivered_infos !== undefined || element.package_received_delivered_infos !== "null" || element.package_received_delivered_infos !== "undefined" ? element.package_received_delivered_infos.received_code : 'null',
                    delivered_code: element.package_received_delivered_infos !== null || element.package_received_delivered_infos !== undefined || element.package_received_delivered_infos !== "null" || element.package_received_delivered_infos !== "undefined" ? element.package_received_delivered_infos.delivered_code : 'null',
                    given_name: element.user_requester_attributes.given_name,
                    traveler_evaluation : element.traveler_evaluation !== null || element.traveler_evaluation !== undefined || element.traveler_evaluation !== "null" || element.traveler_evaluation !== "undefined" ? element.traveler_evaluation : null,
                });
            });
            this.setState({ dataValue: tmp });
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }


    componentWillMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
        this.setState({
            has_check_ticket: this.listreservation.has_check_ticket,
            max_weight_available: this.listreservation.max_weight_available,
            min_weight_booking: this.listreservation.min_weight_booking,
            proposal_amount_by_kg: this.listreservation.proposal_amount_by_kg,
            is_automatic_approval: this.listreservation.is_automatic_approval,
            others_infos: this.listreservation.others_infos,
            take_off_place: this.listreservation.take_off_place,
            recovery_place: this.listreservation.recovery_place,
            status: this.listreservation.status,
            transport_company: this.listreservation.transport_company,
            departure_city: this.listreservation.departure_city,
            arrival_city: this.listreservation.arrival_city,
            departure_date: this.listreservation.departure_date,
            arrival_date: this.listreservation.arrival_date,
            booking_deadline: this.listreservation.booking_deadline,
            service_charge_amount: this.listreservation.service_charge_amount,
            transportation_type: this.listreservation.transportation_type,
        });
        var VAR = JSON.stringify({
            "id_travel": this.listreservation.id_travel
        })
        this.getTravelerList(GET_TRAVEL_RESERVATION_LIST, VAR);
    }

    getKolisStatus(status) {
        switch (status) {
            case 'INITIATED':
                return "À valider"
            case 'VALIDATED':
                return "Validé"
            case 'PACKAGE_RECEIVED':
                return "Colis déposé au transporteur"
            case 'DELIVERED':
                return "Livraison confirmée"
            case 'REJECTED':
                return "Rejet transporteur"
            case 'CANCELED_BY_REQUESTER':
                return "Annulation expéditeur"
            case 'CANCELED_BY_TRAVELER':
                return "Annulation transporteur"
            default:
                return "";
        }
    }

    render() {
        moment.locale('fr');
        const columns = [
            {
                name: 'Date de réservation',
                selector: 'date',
                sortable: true,
            },
            {
                name: 'Expéditeur',
                selector: 'expediteur',
                sortable: true,
            },
            {
                name: 'Téléphone',
                selector: 'telephone',
                sortable: true,
                responsive: true,
                cell: row => <div>{
                    
                    <label> {row.telephone} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                }</div>
            },
            {
                name: 'Action',
                selector: 'action',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        row.status === 'INITIATED' ?
                            <div className="btn-group btn-group-sm d-flex">
                                <a href="" className="btn btn-primary btn-sm w-100" data-toggle="modal" title="Valider la réservation" data-original-title="Valider la réservation" data-target="#modal-write" onClick={(e) => { this.handleShowAcceptationModal(e, row.id_travel, row.id_proposal) }}><span className="fa fa-1x fa-check-square"></span></a>
                               <a href="" className="btn btn-danger btn-sm w-100" data-toggle="modal" title="Refuser la réservation" data-original-title="Refuser la réservation" data-target="#modal-write"  onClick={(e) => { this.handleShowDeleteModal(e, row.id_travel, row.id_proposal) }}><span className="fa fa-1x fa-trash"></span></a>
                            </div>
                            : null
                    }
                    {
                        row.status === 'VALIDATED' ?
                            <div className="btn-group btn-group-sm d-flex">
                                <a href="" className="btn btn-primary btn-sm w-100" data-toggle="modal" title="Ecrire à l'expéditeur" data-original-title="Ecrire à l'expéditeur" data-target="#modal-write" onClick={(e) => { this.handleShowWriteToModal(e, row.id_proposal, row.name) }}><span className="fa fa-1x fa-envelope"></span></a>
                                <a href="" className="btn btn-danger btn-sm w-100" data-toggle="modal" title="Annuler la réservation" data-original-title="Annuler la réservation"  data-target="#modal-del-exp" onClick={(e) => { this.handleCancellationModalClick(e, row.id_proposal) }}><span className="fa fa-1x fa-remove"></span></a>
                            </div>
                            : null
                    }
                    {
                        row.status === 'PACKAGE_RECEIVED' ?
                            <a href="" className="btn btn-primary btn-sm w-100" data-toggle="modal" title="Ecrire à l'expéditeur" data-original-title="Ecrire à l'expéditeur" data-target="#modal-write" onClick={(e) => { this.handleShowWriteToModal(e, row.id_proposal, row.name) }}> <span className="fa fa-1x fa-envelope"></span> Ecrire</a>
                            : null
                    }
                    { row.traveler_evaluation === null && row.status === 'DELIVERED' ?
                        <a href="" className="btn btn-theme2 btn-sm" data-toggle="modal" data-target="#modal-evaluate-trip" onClick={(e) => { this.handleShowEvalModal(e, row.id_proposal, row.given_name) }}> <span class="fa fa-1x fa-star"></span> Evaluer {/*} {row.given_name} */}</a>
                        : null
                    }
                    {row.status === 'REJECTED' ? null : null}
                    {row.status === 'CANCELED_BY_REQUESTER' ? null : null}
                    {row.status === 'CANCELED_BY_TRAVELER' ? null : null}
                    { row.traveler_evaluation !== null && row.status === 'DELIVERED' ?  <span class="fa fa-1x fa-check-square-o color-green"></span> : null}
                    {/*<a href="" class="link color-blue" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleShowAcceptationModal(e, row.id_travel, row.id_proposal) }}>Valider</a> /
                    <a href="" class="link color-blue" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleShowDeleteModal(e, row.id_travel, row.id_proposal) }}>Refuser</a>
                    <a href="" class="link color-blue" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleShowWriteToModal(e, row.id_proposal, row.name) }}>Ecrire</a>  /
                     <a href="" class="link color-red" data-toggle="modal" data-target="#modal-del-exp" onClick={(e) => { this.handleCancellationModalClick(e, row.id_proposal) }}>Annuler</a>
                     <a href="" class="link color-red" data-toggle="modal" data-target="#modal-del-exp">Evaluer</a>  */}
                </div>,
            },
            {
                name: 'Code de dépôt',
                selector: 'codeD',
                sortable: true,
                button: true,
                cell: row => <div>
                     {
                        row.status === 'INITIATED' ?
                            <a className="btn btn-outline-info btn-sm" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleShowRappelAcceptationModal(e, row.id_travel, row.id_proposal) }}><span className="fa fa-1x fa-spinner fa-spin"></span> En attente</a>
                            : null
                    }
                    {
                        row.status === 'VALIDATED' ?
                            <a href="#" className="btn btn-info btn-sm" data-toggle="modal" title="Consulter" data-original-tile="Consulter" data-target="#modal-write" onClick={(e) => { this.handleShowReceivedCodeModalClick(e, row.id_proposal, row.received_code, "normal") }}><span className="fa fa-1x fa-eye"></span> Voir code</a>
                            : null
                    }
                    {
                        row.status === 'PACKAGE_RECEIVED' ?
                            <div className="badge badge-pill badge-info"> Colis reçu</div>
                            : null
                    }
                    {
                        row.status === 'DELIVERED' ?
                            <div className="badge badge-pill badge-info"> Colis reçu</div>
                            : null
                    }
                    {
                        row.status === 'REJECTED' ?
                            <a className="link color-blue" data-toggle="modal" data-target="#modal-write" ></a>
                            : null
                    }
                    {
                        row.status === 'CANCELED_BY_REQUESTER' ?
                            <a className="link color-blue" data-toggle="modal" data-target="#modal-write" ></a>
                            : null
                    }
                    {
                        row.status === 'CANCELED_BY_TRAVELER' ?
                            <a className="link color-blue" data-toggle="modal" data-target="#modal-write" ></a>
                            : null
                    }
                </div>,
            },
            {
                name: 'Code de livraison',
                selector: 'codeL',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        row.status === 'INITIATED' ?
                            <a className="btn btn-outline-success btn-sm" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleShowRappelAcceptationModal(e, row.id_travel, row.id_proposal) }}> <span className="fa fa-1x fa-spinner fa-spin"></span> En attente</a>
                            : null
                    }
                    {
                        row.status === 'VALIDATED' ?
                            <a  className="btn btn-outline-success btn-sm" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleRappelShowReceivedCodeModalClick(e, row.id_proposal, row.received_code, "rappel") }}><span className="fa fa-1x fa-spinner fa-spin"></span> En attente</a>
                            : null
                    }
                    {
                        row.status === 'PACKAGE_RECEIVED' ?
                            <a href="#" className="btn btn-success btn-sm" data-toggle="modal" title="Valider la réservation" data-original-title="Valider la réservation" data-target="#modal-write" onClick={(e) => { this.handleShowValidationModal(e, row.id_proposal, row.delivered_code) }}><span className="fa fa-1x fa-check-square"></span> Valider</a>
                            : null
                    }
                    {
                        row.status === 'DELIVERED' ?
                            <div className="badge badge-pill badge-success"> Colis livré</div>
                            : null
                    }
                    {
                        row.status === 'REJECTED' ?
                            <a className="link color-blue" data-toggle="modal" data-target="#modal-write" ></a>
                            : null
                    }
                    {
                        row.status === 'CANCELED_BY_REQUESTER' ?
                            <a className="link color-blue" data-toggle="modal" data-target="#modal-write" ></a>
                            : null
                    }
                    {
                        row.status === 'CANCELED_BY_TRAVELER' ?
                            <a className="link color-blue" data-toggle="modal" data-target="#modal-write" ></a>
                            : null
                    }
                </div>,
            },
            {
                name: 'Frais de transport (€)',
                selector: 'frais',
                sortable: true,
            },
            {
                name: 'Quantité',
                selector: 'quantite',
                sortable: true,
            },
            {
                name: 'Statut',
                selector: 'statut',
                sortable: true,
                cell: row => <div>{
                    
                    <label> { row.traveler_evaluation !== null && row.status === 'DELIVERED' ? 'Complet' : this.getKolisStatus(row.status) } </label>
                }</div>
            },
        ];

        return (
            <Fragment>
                <div className="mytrips">
                    <div className="card mytravel mar-bot20">
                        <div className="card-header">
                            <div className="card-header_content">
                                <div className="card-header_title">
                                    <h3 className="card-title">
                                        <span>{this.state.departure_city}</span>&nbsp;
                                        <i className="fa fa-long-arrow-right color-text2"></i>&nbsp;
                                        <span>{this.state.arrival_city}</span>
                                    </h3>
                                    <h4 className="card-subtitle"> <span className="txt-bold">Id du trajet: </span>{this.listreservation.id_travel}</h4>
                                </div>
                                <div className="card-header_cta">
                                    <a href="" className="link color-blue" onClick={this.handleReturnTripsClick}><span
                                    className="fa fa-long-arrow-left"></span> Retourner vers vos trajets</a>
                                </div>

                                <div className="modal fade" id="modal-delete-trip" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 criteria">
                                    <p><span className="fa fa-1x fa-calendar" title="Date de voyage"></span>  {Moment(this.state.departure_date).format('DD MMM, YYYY')}</p>
                                    <p><span className="fa fa-1x fa-clock-o color-orange" title="Date limite de réservation"></span>  {Moment(this.state.booking_deadline).format('LLLL')}</p>
                                    {/* <p><span className="fa fa-1x fa-plane"></span>  {this.state.transport_company}</p>
                                    <p><span className="fa fa-1x fa-ticket color-yellow"></span>  {(this.state.has_check_ticket == 'Y') ? "Billet vérifié" : "Billet non vérifié"} </p> */}
                                    {this.state.transportation_type === null ?
                                        <p><span className="fa fa-1x fa-plane"></span>  {this.state.transport_company}</p> :
                                        null
                                    }
                                    {this.state.transportation_type === "Avion" ?
                                        <p><span className="fa fa-1x fa-plane"></span>  {this.state.transport_company}</p> :
                                        null
                                    }
                                    {this.state.transportation_type === "Bâteau" ?
                                        <p><span className="fa fa-1x fa-ship"></span>  {this.state.transport_company}</p> :
                                        null
                                    }
                                    {this.state.transportation_type === "Voiture" ?
                                        <p><span className="fa fa-1x fa-car"></span>  {this.state.transport_company}</p> :
                                        null
                                    }
                                    {this.state.transportation_type === "Train" ?
                                        <p><span className="fa fa-1x fa-train"></span>  {this.state.transport_company}</p> :
                                        null
                                    }
                                    <p>  {this.state.has_check_ticket === "Y" ?
                                        <i className="fa fa-ticket color-yellow"> Billet
                                                        vérifié </i> : this.state.has_check_ticket === "E" ? <span className="fa fa-1x fa-ticket color-green"> "Billet en cours de vérification" </span> : ''}
                                    </p>

                                </div>
                                <div className="col-md-6 comments">
                                    <p><span className="fa fa-1x fa-tags color-orange"></span>  {this.state.max_weight_available}kgs - {this.state.service_charge_amount}€ / kg - Min {this.state.min_weight_booking}kg</p>
                                    <p><span className="fa fa-1x fa-flash color-yellow"></span> {(this.state.is_automatic_approval == 'Y') ? 'Acceptation automatique' : 'Acceptation manuelle'}</p>
                                    <p><span className="fa fa-map-marker color-green"></span> {this.state.recovery_place}</p>
                                    <p><span className="fa fa-map-marker color-blue"></span> {this.state.take_off_place}</p>
                                </div>

                            </div>
                        </div>
                        <div className="card-footer">
                            {/* <div className="progress mar-bot0 mar-top0 ">
                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-color-green" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: value.weigth_available_info.weigth_available === 'null' || value.weigth_available_info.weigth_available ===undefined || 100-(value.weigth_available_info.weigth_available * 100 / value.max_weight_available) < 20 ? "10%" : 100-(value.weigth_available_info.weigth_available * 100 / value.max_weight_available) + "%" }}>
                                    {value.weigth_available_info.weigth_available === 'null' ? "0 "+value.measure_unit: (parseInt(value.weigth_available_info.weigth_available))} {value.measure_unit}(s)
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
                <div className="table table-responsive listreservation-table">
                    <DataTable
                        title="Liste des réservations"
                        columns={columns}
                        data={this.state.dataValue}
                        pagination
                        selectableRowsComponent
                    />
                </div>
                <Modal show={this.state.showDeleteModal} onHide={this.handleCloseDeleteModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Refus de la réservation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Pourquoi voulez-vous refuser cette réservation ?</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentary_change}></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseDeleteModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={(e) => { this.handleRejetReservationClick(e) }}>
                            Refuser
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showSendToModal} onHide={this.handleCloseWritoModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Ecrire un message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <span>Vous pouvez retrouver le suivi de vos échanges dans la section messages de votre compte.</span>

                            <br />
                            <br />
                            <label for="exampleFormControlTextarea1">Objet du message</label>
                            <input type="text" className="form-control" id="travelFirm" placeholder="" value={this.state.object} onChange={this.handleObjectChange} required="true" /> <br />
                            <label for="exampleFormControlTextarea1">Laisser un message</label>
                            <small id="travelTicket" className="form-text text-muted">
                                Ne pas indiquer de <span className="fa fa-1x fa-phone-square"> Numéro de téléphone </span>
                    ou	    <span className="fa fa-1x fa-facebook-square"> de détails de votre Facebook </span>
                            </small>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentary_change}></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseWritoModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={(e) => { this.handleWriteToClick(e) }}>
                            Envoyer
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showAcceptationModal} onHide={this.handleCloseAcceptationModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Validation de la réservation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <span>Voulez-vous accepter cette réservation ?</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseAcceptationModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={(e) => { this.handleAcceptReservationClick(e) }}>
                            Accepter
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showRappelAcceptationModal} onHide={this.handleCloseRappelAcceptationModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Attention, réservation non validée</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <span>Le code de dépôt à remettre à l'expéditeur et la validation du code de livraison ne seront actifs qu'après l'acceptation de la réservation.</span> <br/> <br/>
                            <span>Voulez-vous accepter cette réservation ?</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseRappelAcceptationModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={(e) => { this.handleAcceptReservationClick(e) }}>
                            Accepter
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showCancellationModal} onHide={this.handleCloseCancellationModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Annuler cette réservation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Pourquoi voulez-vous annuler cette réservation ?</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentary_change}></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseCancellationModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={(e) => { this.handleCancelReservationClick(e) }}>
                            Valider
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showReceivedCodeModal} onHide={this.handleCloseReceiveCodeModalClick} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Votre code de dépôt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Votre code de dépôt: <h2>{this.codeDecrypt}</h2></label>
                            <label for="exampleFormControlTextarea2">Vous devez remettre ce code à l'expéditeur. Il permettra à MonKolis de s'assurer que vous avez récupéré le kolis.</label>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="btn btn-outline-primary close" onClick={this.handleCloseReceiveCodeModalClick} centered>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showRappelReceivedCodeModal} onHide={this.handleCloseRappelReceiveCodeModalClick} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Réception du colis en attente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Votre expéditeur n'a pas confirmé  le dépôt du colis ou alors vous n'avez pas encore reçu le colis.</label>
                            <label for="exampleFormControlTextarea2">Voici le code de dépôt à remettre à l'expéditeur pour confirmer le dépôt du colis : <h2>{this.codeDecrypt}</h2></label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="btn btn-outline-primary close" onClick={this.handleCloseRappelReceiveCodeModalClick} centered>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showValidationCodeModal} onHide={this.handleCloseValidationCodeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Valider code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <span>Veuillez saisir le code recu de l'expediteur</span>
                            <input type="text" className="form-control" id="travelFirm" placeholder="Code" value={this.state.code} onChange={this.handleCodeChange} required="true" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseValidationCodeModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={(e) => { this.handleValidationCodeClick(e) }}>
                            Valider
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showEvalModal} onHide={this.handleCloseEvalModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Evaluer {this.state.nameTmp}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body">
                            <span className="txt-bold">Choisissez l'adjectif qui résume le mieux votre expérience avec ce membre.</span>
                            <br />
                            <br />

                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Ma note</label>
                                <select className="form-control" value={this.state.marks} onChange={this.handleChangeMakrs}>
                                    <option>5/5 Très bien</option>
                                    <option>4/5 Bien</option>
                                    <option>3/5 Moyen</option>
                                    <option>2/5 Déçu</option>
                                    <option>1/5 Très déçu</option>
                                </select>
                                <div className="form-group">
                                    <label for="exampleFormControlTextarea1">Laisser un commentaire</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentaryChange}></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-primary" onClick={this.handleCloseEvalModal}>
                            Annuler
                        </button>
                        <button className="btn btn-primary" onClick={this.handleEvalRequest}>
                            Valider
                        </button>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}
export default withRouter(ListReservation);