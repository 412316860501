import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';
import PS1 from '../../assets/img/other/PS1.jpeg'
import PS2 from '../../assets/img/other/PS2.jpeg'
import PS4 from '../../assets/img/other/PS4.jpeg'
import PS3 from '../../assets/img/other/PS3.jpg'

class PersonnalShooper extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Nos personal shoppers</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="privacy">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div>
                                <div className="row pad-top20 pad-bot20">
                                    <div className="col-sm-3 mar-bot20">

                                        <div className="list-group">
                                            <a href="/personnalshooping" className="list-group-item list-group-item-action ">Comment ça marche</a>
                                            <a href="/personnalshooper" className="list-group-item list-group-item-action active">Nos personal shoppers </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="aside section">
                                            <div className="section-inner">
                                                <h1 className="f-heading">Nos personal shoppers</h1>
                                                <p class="mar-top15">
                                                    L'ensemble des personal shoppers référencés sont des partenaires de MonKolis. <br />
                        Vous pouvez les filtrer selon leur localisation et leur niveau d'expérience défini par MonKolis sur la base des avis de nos utilisateurs.
                        </p>


                                                <form class="mar-bot20">
                                                    <div class="form-row">
                                                        <div class="form-group col-sm-3">
                                                            <label for="country" class="sr-only">Pays</label>
                                                            <input type="text" class="form-control" id="country" placeholder="Pays de résidence" />
                                                        </div>
                                                        <div class="form-group col-sm-3">
                                                            <label for="city" class="sr-only">Ville</label>
                                                            <input type="text" class="form-control" id="city" placeholder="Ville de résidence" />
                                                        </div>
                                                        <div class="form-group col-sm-3">
                                                            <label for="experience" class="sr-only">Expérience</label>
                                                            <input type="text" class="form-control" id="experience" placeholder="Experience" />
                                                        </div>
                                                        <div class="form-group col-sm-3">
                                                            <a href="#" class="btn btn-primary btn-block"> Filtrer</a>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div class="card-deck">
                                                    <div class="card">
                                                        <img src={PS1} alt="Card image cap" height="200"/>
                                                        <div class="card-body">
                                                            <h4 class="card-title"><span class="color-text3">L'agence de </span><br />O.B.S ( Ô BREAK SHOPPING)</h4>
                                                            <p class="card-text">Bienvenue chez Ô.B.S ( Ô BREAK SHOPPING), votre allié d'instant Shopping.
                                                            Désormais, plus besoin de vous déplacer, ni voyager pour ses courses.
                                    Nous réalisons vos souhaits en tenant compte de vos besoins et de votre satisfaction à la clé.<br/>
                                    A vos marques....SHOPPEZ</p>
                                                            {/* <p class="mar-top0">
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star-half color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <span class="color-text2 txt-bold"> - 12 avis</span>
                                                            </p> */}
                                                        </div>
                                                        {/* <div> */}

                                                        {/*  </div> */}
                                                        {/* <div class="card-footer"> */}
                                                            <span class=""><i class="fa fa-reply color-blue"></i><a href="#" class="link"> OBSK78@yahoo.com </a></span>
                                                            <span class=" color-green float-right"><i class="fa fa-whatsapp"></i>+33618887756</span>

                                                            <span class="color-green txt-bold"> <i class="fa fa-map-marker"></i>  Région Parisienne</span>
                                                            <a href="https://www.facebook.com/lin.225/" class="link color-blue float-right">Site Web Agence</a>
                                                        {/* </div> */}
                                                    </div>
                                                    <div class="card">
                                                        <img src={PS4} alt="Card image cap" height="200"/>
                                                        <div class="card-body">
                                                            <h4 class="card-title"><span class="color-text3">L'agence de </span><br />LB SHOP</h4>
                                                            <p class="card-text">Vous cherchez un article, avez besoin d’un service, manquez de temps ? Nous nous occupons de tout, de l’achat à l’expédition à votre domicile.</p>
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            {/* <p class="mar-top0">
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <span class="color-text2 txt-bold"> - 16 avis</span>
                                                            </p> */}

                                                        </div>
                                                        {/* div class="card-footer"> */}
                                                        <span class=""><i class="fa fa-reply color-blue"></i><a href="#" class="link"> GNANOGO Linda Carole</a></span>
                                                        <span class=" color-green float-right"><i class="fa fa-whatsapp"></i> +33698403822</span>
                                                        <span class="color-green txt-bold"> <i class="fa fa-map-marker"></i> Ile de France</span>
                                                        <a href="#" class="link color-blue float-right">Site Web Agence</a>
                                                        {/* </div> */}
                                                    </div>

                                                </div>
                                                <div class="card-deck">

                                                    <div class="card">
                                                        <img src={PS3} alt="Card image cap"  height="200"/>
                                                        <div class="card-body">
                                                            <h4 class="card-title"><span class="color-text3">L'agence de </span><br />Shoppingatoutprix</h4>
                                                            <p class="card-text">Shoppingatoutprix est une boutique en ligne de vente d’articles (sacs à main, chaussures, parfums, vêtements et autres) en provenance de la France.
                                                            Nous proposons à notre clientèle divers articles disponibles dans les boutiques Françaises et nous leur donnons la possibilité de faire un shopping de qualité tout en étant à Abidjan.
                                                            Nous nous chargeons aussi de faire vos courses personnalisées en France avec le suivi de notre personal shopper qui vous donnera des conseils et vous guidera dans vos choix.
                                                            Nos valeurs : l’Originalité,
                                                            la qualité et la fiabilité Shoppingatoutprix est basé en côte d’ivoire précisément à Abidjan et la livraison de vos colis se font partout en côte d’ivoire et
                                                            aussi dans les pays de la sous régions comme le mali , le burkina faso ,le benin et autres. Shoppingatoutprix, la mode parisienne qui vient vers vous.</p>
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            
                                                            {/* <p class="mar-top0">
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                                <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                                <span class="color-text2 txt-bold"> - 16 avis</span>
                                                            </p> */}

                                                        </div>
                                                        {/* <div class="card-footer"> */}
                                                        <span class=""><i class="fa fa-reply color-blue"></i><a href="#" class="link">gmc.multiservices2018@yahoo.com</a></span>
                                                        <span class=" color-green float-right"><i class="fa fa-whatsapp"></i>France : 0033753541869 </span>
                                                        <span class=" color-green float-right"><i class="fa fa-whatsapp"></i>Côte d’ivoire : 00225 78133571 </span>
                                                        <span class="color-green txt-bold"> <i class="fa fa-map-marker"></i> Ile de France</span>
                                                        <a href="https://www.facebook.com/Shoppingatoutprix-281623022480765" class="link color-blue float-right">Site Web Agence</a>

                                                        {/* </div> */}

                                                    </div>
                                                    <div class="card">
                                                    <img src={PS2} alt="Card image cap"  height="200" />
                                                        <div class="card-body">
                                                            <h4 class="card-title"><span class="color-text3">L'agence de </span><br />NEO SHOPPER</h4>
                                                            <p class="card-text">NeoShopper, le partenaire de confiance. Nous venons à vous sans vous déplacer. Notre priorité, vous servir et vous faire plaisir.
                                                             Avec Neo Shopper c'est la tranquilité, la fiabilité, la zen attitude, vous Shoppez sans inquiétude</p>
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            
    

                                                        </div>
                                                        {/* <div class="card-footer"> */}
                                                        <span class=""><i class="fa fa-reply color-blue"></i><a href="#" class="link">neo.shopper@yahoo.com</a></span>
                                                        <span class=" color-green float-right"><i class="fa fa-whatsapp"></i>France : +33627702599 </span>
                                                        <span class="color-green txt-bold"> <i class="fa fa-map-marker"></i> Ile de France</span>
                                                        <a href="#" class="link color-blue float-right">Site Web Agence</a>

                                                        {/* </div> */}

                                                    </div>


                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default PersonnalShooper;