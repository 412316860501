import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
// import './../../../assets/css/main.css';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/dashboard.css';
import './../../../assets/css/modal.css';
import MessagesVoyages from './messagevoyages';
import MessagesExpeditions from './messagesexpeditions';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error: [],
            renderMessageVoyage: true,
            renderMessageExpeditions: false,
        }


        this.handleMessageVoyageClick = this.handleMessageVoyageClick.bind(this);
        this.handleMessageExpeditionClick = this.handleMessageExpeditionClick.bind(this);
    }

    componentWillMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
    }



    handleMessageVoyageClick(event) {
        this.setState({
            renderMessageVoyage: true,
            renderMessageExpeditions: false,

        })
    }

    handleMessageExpeditionClick(event) {
        this.setState({
            renderMessageExpeditions: true,
            renderMessageVoyage: false,
        })
    }



    render() {
        
        return (
            <Fragment>
                <Helmet>
                    <title>Mes messages de trajets | Monkolis</title>
                    <meta name="robots" content="noindex,follow" />
                </Helmet>

                <div className="mymessage">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            {getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? <Redirect to="/connexion" /> :
                                <div className="pad-top20 mar-bot20 d-sm-block" id="secondNav">
                                    <Nav variant="tabs" defaultActiveKey="/dashboard/mymessage">
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mydashboard" href="/dashboard/mydashboard">Tableau de bord</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mytrips" href="/dashboard/mytrips">Mes trajets</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myexpeditions" href="/dashboard/myexpeditions">Mes expéditions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/mymessage" href="/dashboard/mymessage">Messages</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myalerts" href="/dashboard/myalerts">Alertes</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="/dashboard/myprofile" href="/dashboard/myprofile">Profil</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href="/dashboard/money" href="/dashboard/money">Paiements</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <br />
                                    <div className="row mytravels">
                                        <div className="col-sm-3 mar-bot20">
                                            <div className="list-group mar-bot20">
                                                {
                                                    this.state.renderMessageVoyage ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleMessageVoyageClick}>Expéditeurs</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleMessageVoyageClick}>Expéditeurs</a>
                                                }
                                                {
                                                    this.state.renderMessageExpeditions ?
                                                        <a className="list-group-item list-group-item-action txt-white active" onClick={this.handleMessageExpeditionClick}>Transporteurs</a> :
                                                        <a className="list-group-item list-group-item-action" onClick={this.handleMessageExpeditionClick}>Transporteurs</a>
                                                }
                                            </div>

                                        </div>
                                        <div className="col-sm-9">
                                            {this.state.renderMessageVoyage ? <MessagesVoyages /> : null}
                                            {this.state.renderMessageExpeditions ? <MessagesExpeditions /> : null}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}
export default withRouter(MyMessages);