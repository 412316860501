import React, {Component, Fragment} from 'react';
import {Helmet} from 'react-helmet';
//import './../../assets/css/main.css';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class About extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>A propos de Monkolis | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="about">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br box-radius">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/about" className="list-group-item list-group-item-action active">A
                                            propos</a>
                                        <a href="founders"
                                            className="list-group-item list-group-item-action">Cofondateurs</a>
                                        <a href="/details"
                                            className="list-group-item list-group-item-action">Coordonnées</a>
                                        <hr/><hr/>
                                        <a href="/careers"
                                            className="list-group-item list-group-item-action">Carrière</a>
                                        <a href="/press" className="list-group-item list-group-item-action">Presse</a>
                                        <a href="/contact"
                                            className="list-group-item list-group-item-action">Contact</a>

                                    </div>

                                </div>
                                <div className="col-sm-9">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">À propos de MonKolis</h1>
                                            <p> Fondée en janvier 2019 et basée à Paris, MonKolis offre un service dans
                                            l’esprit de l’économie collaborative dont le but est de faire des
                                            économies au quotidien tout en contribuant au développement durable.
                                            </p>
                                            <p> Le transport de colis à l'international n'aura jamais été aussi simple :
                                            nous mettons en relation des transporteurs et des expéditeurs qui
                                            veulent envoyer ou recevoir un kolis du pays. Grâce à notre service, le
                                            colis de l'expéditeur arrive rapidement et en toute sureté tandis que le
                                            transporteur rentabilise son voyage et fait de belles rencontres. Tout
                                            le monde y gagne : les uns payent moins cher leur envoi et les autres
                                            remboursent une partie de leurs frais de transport ou augmentent leurs
                                            revenus, tout en rendant service.
                                            </p>

                                            <div className="row mar-top20">
                                                <div className="col-md-6">
                                                    <div className="block">
                                                        <p className="float-left mar-right15"><i
                                                            className="fa fa-archive fa-5x color-green"></i></p>
                                                        <h6 className="color-text2 mar-bot5">Kgs transportés</h6>
                                                        <h1 className="mar-bot0 mar-top0">21.337</h1>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="block">
                                                        <p className="float-left mar-right15"><i
                                                            className="fa fa-plane fa-5x color-green"></i></p>
                                                        <h6 className="color-text2 mar-bot5">Trajets effectués</h6>
                                                        <h1 className="mar-bot0 mar-top0">9.764</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mar-top20">
                                                <div className="col-md-6">
                                                    <div className="block">
                                                        <p className="float-left mar-right15"><i
                                                            className="fa fa-comments fa-5x color-green"></i></p>
                                                        <h6 className="color-text2 mar-bot5">Belles rencontres</h6>
                                                        <h1 className="mar-bot0 mar-top0">19.000</h1>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="block">
                                                        <p className="float-left mar-right15"><i
                                                            className="fa fa-trophy fa-5x color-green"></i></p>
                                                        <h6 className="color-text2 mar-bot5">Beaux témoignages</h6>
                                                        <h1 className="mar-bot0 mar-top0">996</h1>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>


        );
    }
}

export default About;