
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { MESSAGES_VOYAGES_QUERY } from '../../../query/queryprofil';
import { MESSAGE_VOYAGE_URL } from '../../../Url';
import { SEND_MESSAGE_TO_TRAVEL_REQUESTER_QUERY } from '../../../query/travelquery';
import { TRAVEL_ACTIONS_URL } from '../../../Url';
import DEFAULT_PHOTO from '../../../assets/img/user/user0.png';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Moment from "moment";
import moment from 'moment';
import 'moment/min/locales';
import Modal from 'react-bootstrap/Modal';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MessagesVoyages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: [],
            showSendToModal: false,
            msg_content: '',
            proposal: '',
            msg_object: ''
        };

        this.handleSendMessageModal = this.handleSendMessageModal.bind(this);
        this.handleCloseMessageModal = this.handleCloseMessageModal.bind(this);
        this.handleCommentaryChange = this.handleCommentaryChange.bind(this);
        this.handleShowWriteToModal = this.handleShowWriteToModal.bind(this);
        this.handleObjectChange = this.handleObjectChange.bind(this);


    }

    componentDidMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        this.userSub = getCookieConsentValue('userSub')
        this.name = getCookieConsentValue('name')
        this.family_name = getCookieConsentValue('family_name')
        this.given_name = getCookieConsentValue('given_name')
        this.photo = getCookieConsentValue('photo');

        // this.idToken = sessionStorage.getItem('idToken')
        // this.userSub = sessionStorage.getItem('userSub')
        // this.name = sessionStorage.getItem('name')
        // this.family_name = sessionStorage.getItem('family_name')
        // this.given_name = sessionStorage.getItem('given_name')
        // this.photo = sessionStorage.getItem('photo');
        this.getMessagesVoyages(MESSAGES_VOYAGES_QUERY)
    }

    handleCloseMessageModal() {
        this.setState(
            { showSendToModal: false }
        )
    }

    handleSendMessageModal(event, proposal) {
        event.preventDefault();
        if (this.state.msg_content !== "") {
            var VAR = JSON.stringify({
                "notification": {
                    "id_proposal": this.state.proposal,
                    "msg_object": "xxxxx",  //this.state.msg_object,
                    "msg_content": this.state.msg_content,
                    "type_notification": "MSG-WEB"
                }

            });
            this.writeToTravelerRequest(SEND_MESSAGE_TO_TRAVEL_REQUESTER_QUERY, VAR)
        }
    }

    writeToTravelerRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            if (response.data.data.notifiedProposalTravelByTraveler.status === 200) {
                alert('Votre message a bien été envoyé au transporteur')
                window.location.reload(false);
            } else {
                alert('Echec, merci de renseigner un objet pour votre message')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleCommentaryChange(event) {
        this.setState({ msg_content: event.target.value })
    }

    handleObjectChange(event) {
        this.setState({
            msg_object: event.target.value
        })
    }

    handleShowWriteToModal(event, id_proposal) {
        event.preventDefault();
        this.setState({
            showSendToModal: !this.state.showSendToModal,
            proposal: id_proposal
        }
        )
    }

    getMessagesVoyages = async (query) => {
        try {
            const response = await axios.post(MESSAGE_VOYAGE_URL,
                { query },
                {
                    'headers':
                    {
                        'Authorization': this.idToken,
                        'Content-Type': 'application/json'
                    }
                }
            );

            this.setState(() => ({
                isLoaded: true,
                items: response.data.data.getRequesterProposalsForUserTraveler.requester_proposals
            }));

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }

    render() {
        moment.locale('fr');
        if (this.state.items.length === 0) {
            return (
                <Fragment>
                    <div className="mymessage">
                        <div className="section testimonials">
                            <div className="aside section photo">
                                <div className="section-inner">
                                    <h3 className="heading">Vos échanges avec les expéditeurs ! </h3>
                                    <p>
                                        Vous pouvez demander des précisions à tout moment aux expéditeurs via la messagerie interne.
                                    </p>
                                </div>
                                <br />
                                <div>VOUS N'AVEZ PAS DE MESSAGES</div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
        else {
            return (
                <div className="row messages">
                    <div className="col-sm-12">

                        <Modal show={this.state.showSendToModal} onHide={this.handleCloseMessageModal} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Vos échanges avec les expéditeurs!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <span>Vous pouvez retrouver le suivi de vos échanges dans la section messages de votre compte.</span>
                                    <br />
                                    <br />
                                   {/*  <label for="exampleFormControlTextarea1">Objet du message</label>
                                    <input type="text" className="form-control" id="travelFirm" placeholder="(facultatif)" value={this.state.msg_object} onChange={this.handleObjectChange} required="true" /> */}
                                    <label for="exampleFormControlTextarea1">Laisser un message</label>
                                    <small id="travelTicket" className="form-text text-muted">
                                        Ne pas indiquer de <span className="fa fa-1x fa-phone-square"> Numéro de téléphone </span>
                    ou	        <span className="fa fa-1x fa-facebook-square"> de détails de votre Facebook </span>
                                    </small>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.msg_content} onChange={this.handleCommentaryChange}></textarea>
                                </div>



                            </Modal.Body>
                            <Modal.Footer>
                                <button claasName="btn btn-outline-primary" onClick={this.handleCloseMessageModal}>
                                    Annuler
                                </button>
                                <button className="btn btn-primary" onClick={(e) => { this.handleSendMessageModal(e) }}>
                                    Valider
                                 </button>
                            </Modal.Footer>


                        </Modal>

                        <div className="aside section photo">
                            <div className="section-inner">
                                <h3 className="heading">Vos échanges avec les expéditeurs ! </h3>
                                <p>
                                    Vous pouvez demander des précisions à tout moment aux expéditeurs via la messagerie interne.
                                </p>
                            </div>
                        </div>


                        {this.state.items.map((value, index) => {
                            return <div key={index} >
                                {value.notifications !== null ? <div>


                                    Votre voyage :<span>{value.travel_infos.departure_city}</span><i className="fa fa-long-arrow-right color-text2"></i><span >{value.travel_infos.arrival_city}</span> du <span>{Moment(value.departure_date).format('LLLL')}</span>
                                    <hr className="mar-top5" />
                                    <p className="mar-bot0"> Expéditeur :
                                    <span class="color-text2">
                                            {
                                                value.user_traveler_attributes.account_type === null || value.user_traveler_attributes.account_type === undefined || value.user_traveler_attributes.account_type === 'null' || value.user_traveler_attributes.account_type === 'undefined' || value.user_traveler_attributes.account_type === "Particulier" || value.user_traveler_attributes.account_type === "" ?
                                                    <span className="media-heading"> {value.user_traveler_attributes.given_name}</span>
                                                    :
                                                    value.user_traveler_attributes.account_type_details !== "" || value.user_traveler_attributes.account_type_details !== "null" || value.user_traveler_attributes.account_type_details !== "undefined" || value.user_traveler_attributes.account_type_details !== null || value.user_traveler_attributes.account_type_details !== undefined ?
                                                        <span className="media-heading"> {value.user_traveler_attributes.account_type_details.company_name}</span>
                                                        :
                                                        <span className="media-heading"> {value.user_traveler_attributes.given_name}</span>

                                            }

                        - {value.weight_reserved} kg</span></p>
                                    <div className="message">

                                        {value.notifications !== null ? value.notifications.map((val, index) => {
                                            return <div key={index} >

                                                <div className="media">
                                                    <div className="media-left media-top">
                                                        <div className="photo">
                                                            {
                                                                val.expeditor_id_user === this.userSub ? this.photo === 'null' || this.photo === null || this.photo === undefined || this.photo === 'undefined' ?
                                                                    <img src={DEFAULT_PHOTO} alt="MDN Logo" className="cover rounded" /> :
                                                                    <img src={this.photo} alt="MDN Logo" className="cover rounded" />
                                                                    : value.user_requester_attributes.picture === 'null' || value.user_requester_attributes.picture === null ? <img src={DEFAULT_PHOTO} alt="MDN Logo" className="cover rounded" /> : <img src={value.user_requester_attributes.picture} alt="MDN Logo" className="cover rounded" />}
                                                        </div>
                                                    </div>
                                                    <div className="media-body pad-left2">
                                                        {val.expeditor_id_user === this.userSub ? <h6 className="head"> {   value.user_traveler_attributes.account_type === "Entreprise" ? value.user_traveler_attributes.account_type_details.company_name : this.given_name} {/* {this.family_name} */}</h6> : value.user_requester_attributes.given_name === null || value.user_requester_attributes.given_name === undefined || value.user_requester_attributes.given_name === 'null' || value.user_requester_attributes.given_name === 'undefined' || value.user_requester_attributes.given_name === "" ? value.user_requester_attributes.family_name : value.user_requester_attributes.account_type === "Entreprise" ? value.user_requester_attributes.account_type_details.company_name : <h6 className="head"> {value.user_requester_attributes.given_name} </h6> } 

                                                        <p>{val.msg_content}
                                                        </p>
                                                        <span className="date color-text2">Envoyé le {Moment(val.msg_date).format('LLLL')}</span>

                                                    </div>
                                                </div>

                                                <hr />
                                            </div>


                                        }) : null}

                                        <div className="align-right"><a className="link color-blue" data-toggle="modal" data-target="#modal-write" href="" onClick={(e) => { this.handleShowWriteToModal(e, value.id_proposal) }}><i class="fas fa-reply"></i><span>Répondre</span></a>
                                        </div>
                                    </div>

                                </div> : ''}
                            </div>

                        })}

                    </div>
                </div>


            );
        }
    }
}
export default withRouter(MessagesVoyages);