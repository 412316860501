import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import axios from 'axios';
import { UPDATE_USER_INFORMATION_QUERY } from './../../../query/queryprofil'
import { USER_INFO_URL, SECURE_COOKIES } from '../../../Url';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import Moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'
//import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
/* import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css' */
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import PhoneInput from 'react-phone-input-2'


import Select from 'react-select'
import countryList from 'react-select-country-list'

class MyPersoInformation extends Component {
	constructor(props) {
		super(props);
		this.countryList = countryList().getData()
		this.state = {
			countryList: this.countryList,
			isLoaded: false,
			user_info: [],
			stateUpdate: [],
			accessToken: '',
			username: "",
			userConfirmed: "",
			userSub: "",
			name: "",
			given_name: "",
			family_name: "",
			email: "",
			gender: "",
			street_address: "",
			locality: "",
			region: "",
			postal_code: "",
			phone_number: "",
			city_of_residence: "",
			description: "",
			birthdate: "",
			additional_infos: "",
			country: "",
			validated: false,
			msgAlertBody: '',
			msgAlertHeader: '',
			alertVariant: 'danger',
			showAlert: false,
			items: [],
			account_type: "Particulier",
			registration_number: "",
			company_name: "",
			company_country_name: "",

			countryCode: "ci",
			country_calling_code: "+225",
			id_card_check_status : "false"

		};

		this.handleUsernameChange = this.handleUsernameChange.bind(this);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleGiven_nameChange = this.handleGiven_nameChange.bind(this);
		this.handleFamily_nameChange = this.handleFamily_nameChange.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleGenderChange = this.handleGenderChange.bind(this);
		this.handleStreet_addressChange = this.handleStreet_addressChange.bind(this);
		this.handleLocalityChange = this.handleLocalityChange.bind(this);
		this.handleRegionChange = this.handleRegionChange.bind(this);
		this.handlePostal_codeChange = this.handlePostal_codeChange.bind(this);
		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handlePhone_numberChange = this.handlePhone_numberChange.bind(this);
		this.handleCity_of_residenceChange = this.handleCity_of_residenceChange.bind(this);
		this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
		this.handle_sexe_change = this.handle_sexe_change.bind(this);
		this.handleBirthdateChange = this.handleBirthdateChange.bind(this);
		this.handleAdditionalInfosChange = this.handleAdditionalInfosChange.bind(this);
		this.handleCountryImatChange = this.handleCountryImatChange.bind(this);
		this.handleTravelerTypeChange = this.handleTravelerTypeChange.bind(this);
		this.handleIdEntrepriseChange = this.handleIdEntrepriseChange.bind(this);
		this.handleNameEntrepriseChange = this.handleNameEntrepriseChange.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCloseAlert = this.handleCloseAlert.bind(this);
	}

	handleIdEntrepriseChange(event) {
		this.setState({ registration_number: event.target.value })
	}

	handleNameEntrepriseChange(event) {
		this.setState({ company_name: event.target.value })
	}

	handleTravelerTypeChange(event) {
		this.setState({ account_type: event.target.value })
	}

	handleAdditionalInfosChange(event) {
		this.setState({ additional_infos: event.target.value })
	}


	handle_sexe_change(event) {
		this.setState({ gender: event.target.value })
	}

	handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (this.state.account_type !== "Particulier") {
			if (this.state.registration_number === "" ||
				this.state.company_name === "" ||
				this.state.company_country_name === ""
			) {
				window.scrollTo(0, 0);
				this.setState(() => ({
					showAlert: true,
					msgAlertBody: 'Merci de renseigner les champs obligatoires(*)',
					msgAlertHeader: 'Champs vides',
					alertVariant: 'danger'
				}));
				window.scrollTo(0, 0);
			}
			else if (form.checkValidity() === false) {
				event.stopPropagation();
			}
			else {
				if (this.state.phone_number === "" || this.state.country === "" || this.state.country === null || this.state.country === undefined || this.state.country === "null" || this.state.country === "undefined") {
					this.setState(() => ({
						showAlert: true,
						msgAlertBody: 'Merci de renseigner votre numero de téléphone et votre pays svp',
						msgAlertHeader: 'Champ telephone obligatoire',
						alertVariant: 'danger'
					}));
				}
				else {

					this.setState(() => ({
						validated: true
					}));
					var Plus = "+"
					var VAR = JSON.stringify({
						"user_attributes": [
							{
								"Name": "family_name",
								"Value": this.state.family_name
							},
							{
								"Name": "name",
								"Value": this.state.name
							},
							{
								"Name": "given_name",
								"Value": this.state.given_name
							},
							{
								"Name": "street_address",
								"Value": this.state.street_address
							},

							{
								"Name": "gender",
								"Value": this.state.gender
							},
							{
								"Name": "country",
								"Value": this.state.country.label
							},
							{
								"Name": "locality",
								"Value": this.state.locality
							},
							{
								"Name": "phone_number",
								"Value": Plus.concat(this.state.phone_number.split('+').join('')) //Plus.concat(this.state.phone_number.replaceAll("+",""))
							},
							{
								"Name": "city_of_residence",
								"Value": this.state.city_of_residence
							},
							{
								"Name": "birthdate",
								"Value": Moment(this.state.birthdate).format('YYYY-MM-DD') === "Invalid date" ? '01/01/2020' : Moment(this.state.birthdate).format('YYYY-MM-DD')
							},
							{
								"Name": "additional_infos",
								"Value": this.state.additional_infos
							},
							{
								"Name": "description",
								"Value": this.state.description
							},
							{
								"Name": "postal_code",
								"Value": this.state.postal_code
							},
							{
								"Name": "account_type",
								"Value": this.state.account_type
							},
							{
								"Name": "registration_number",
								"Value": this.state.registration_number
							},
							{
								"Name": "company_name",
								"Value": this.state.company_name
							},
							{
								"Name": "company_country_name",
								"Value": this.state.company_country_name === null || this.state.company_country_name === undefined || this.state.company_country_name === "null" || this.state.company_country_name === "undefined" || this.state.company_country_name === "" ? "" : this.state.company_country_name.label
							}
						]
					})
					this.udapteUserInfoRequest(UPDATE_USER_INFORMATION_QUERY, VAR)
				}



			}
		}
		else if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		else {
			if (this.state.phone_number === "" || this.state.country === "" || this.state.country === null || this.state.country === undefined || this.state.country === "null" || this.state.country === "undefined") {
				window.scrollTo(0, 0);
				this.setState(() => ({
					showAlert: true,
					msgAlertBody: 'Merci de renseigner votre numero de téléphone et votre pays svp',
					msgAlertHeader: 'Champ telephone obligatoire',
					alertVariant: 'danger'
				}));
			}
			else {
				this.setState(() => ({
					validated: true
				}));
				var Plus = "+"
				var VAR = JSON.stringify({
					"user_attributes": [
						{
							"Name": "family_name",
							"Value": this.state.family_name
						},
						{
							"Name": "name",
							"Value": this.state.name
						},
						{
							"Name": "given_name",
							"Value": this.state.given_name
						},
						{
							"Name": "street_address",
							"Value": this.state.street_address
						},

						{
							"Name": "gender",
							"Value": this.state.gender
						},
						{
							"Name": "country",
							"Value": this.state.country.label
						},
						{
							"Name": "locality",
							"Value": this.state.locality
						},
						{
							"Name": "phone_number",
							"Value": Plus.concat(this.state.phone_number.split('+').join(''))// Plus.concat(this.state.phone_number).replaceAll("+",""))
						},
						{
							"Name": "city_of_residence",
							"Value": this.state.city_of_residence
						},
						{
							"Name": "birthdate",
							"Value": Moment(this.state.birthdate).format('YYYY-MM-DD') === "Invalid date" ? '01/01/2020' : Moment(this.state.birthdate).format('YYYY-MM-DD')
						},
						{
							"Name": "additional_infos",
							"Value": this.state.additional_infos
						},
						{
							"Name": "description",
							"Value": this.state.description
						},
						{
							"Name": "postal_code",
							"Value": this.state.postal_code
						},
						{
							"Name": "account_type",
							"Value": this.state.account_type
						},
						{
							"Name": "registration_number",
							"Value": ""//this.state.registration_number
						},
						{
							"Name": "company_name",
							"Value": ""//this.state.company_name
						},
						{
							"Name": "company_country_name",
							"Value": ""//this.state.company_country_name === null || this.state.company_country_name === undefined || this.state.company_country_name === "null" || this.state.company_country_name === "undefined" || this.state.company_country_name === "" ? "" : this.state.company_country_name.label
						}
					]
				})
				this.udapteUserInfoRequest(UPDATE_USER_INFORMATION_QUERY, VAR)

			}


		}
	}

	handleCloseAlert(event) {
		this.setState({ showAlert: false });
		//window.location.reload(false);
	}

	handleUsernameChange(event) {
		this.setState({ username: event.target.value });
	}

	handleBirthdateChange(event) {
		this.setState({ birthdate: event.target.value });
	}

	handleNameChange(event) {
		this.setState({ name: event.target.value });
	}

	handleGiven_nameChange(event) {
		this.setState({ given_name: event.target.value });
	}

	handleFamily_nameChange(event) {
		this.setState({ family_name: event.target.value });
	}

	handleEmailChange(event) {
		this.setState({ email: event.target.value });
	}

	handleGenderChange(event) {
		this.setState({ gender: event.target.value });
	}

	handleStreet_addressChange(event) {
		this.setState({ street_address: event.target.value });
	}

	handleLocalityChange(event) {
		this.setState({ locality: event.target.value });
	}

	handleRegionChange(event) {
		this.setState({ region: event.target.value });
	}

	handlePostal_codeChange(event) {
		this.setState({ postal_code: event.target.value });
	}


	handleCountryChange(event) {
		this.setState({
			country: event,
			//country: event.label
		});
	}

	handleCountryImatChange(event) {
		this.setState({
			company_country_name: event,
			//country: event.label
		});
	}

	handlePhone_numberChange(event) {
		this.setState({ phone_number: event });
	}

	handleCity_of_residenceChange(event) {
		this.setState({ city_of_residence: event.target.value });
	}

	handleDescriptionChange(event) {
		this.setState({ description: event.target.value });
	}


	componentWillMount() {
		window.scrollTo(0, 0);
		try {
			this.getGeoInfo();
		} catch {

		}


		this.idToken = getCookieConsentValue('idToken');
		this.accessToken = getCookieConsentValue('accessToken')
		this.setState(
			{
				username: getCookieConsentValue('userName'),
				userConfirmed: getCookieConsentValue('userConfirmed'),
				name: getCookieConsentValue('name'),
				given_name: getCookieConsentValue('given_name'),
				family_name: getCookieConsentValue('family_name'),
				email: getCookieConsentValue('email'),
				gender: getCookieConsentValue('gender'),
				street_address: getCookieConsentValue('street_address') === null || getCookieConsentValue('street_address') === undefined || getCookieConsentValue('street_address') === "null" || getCookieConsentValue('street_address') === "undefined" || getCookieConsentValue('street_address') === "" ? "" : getCookieConsentValue('street_address'),
				locality: getCookieConsentValue('locality') === null || getCookieConsentValue('locality') === undefined || getCookieConsentValue('locality') === "null" || getCookieConsentValue('locality') === "undefined" || getCookieConsentValue('locality') === "" ? "" : getCookieConsentValue('locality'),
				region: getCookieConsentValue('region') === null || getCookieConsentValue('region') === undefined || getCookieConsentValue('region') === "null" || getCookieConsentValue('region') === "undefined" || getCookieConsentValue('region') === "" ? "" : getCookieConsentValue('region'),
				postal_code: getCookieConsentValue('postal_code') === null || getCookieConsentValue('postal_code') === undefined || getCookieConsentValue('postal_code') === "null" || getCookieConsentValue('postal_code') === "undefined" || getCookieConsentValue('postal_code') === "" ? "" : getCookieConsentValue('postal_code'),
				country: getCookieConsentValue('country'),
				phone_number: getCookieConsentValue('phone_number') === null || getCookieConsentValue('phone_number') === undefined || getCookieConsentValue('phone_number') === "null" || getCookieConsentValue('phone_number') === "undefined" || getCookieConsentValue('phone_number') === "" ? "" : "+" + getCookieConsentValue('phone_number'),
				description: getCookieConsentValue('description') === null || getCookieConsentValue('description') === undefined || getCookieConsentValue('description') === "null" || getCookieConsentValue('description') === "undefined" || getCookieConsentValue('description') === "" ? "" : getCookieConsentValue('description'),
				city_of_residence: getCookieConsentValue('city_of_residence') === null || getCookieConsentValue('city_of_residence') === undefined || getCookieConsentValue('city_of_residence') === "null" || getCookieConsentValue('city_of_residence') === "undefined" || getCookieConsentValue('city_of_residence') === "" ? "" : getCookieConsentValue('city_of_residence'),
				birthdate: getCookieConsentValue('birthdate') === null || getCookieConsentValue('birthdate') === undefined || getCookieConsentValue('birthdate') === "null" || getCookieConsentValue('birthdate') === "undefined" || getCookieConsentValue('birthdate') === "" ? "" : getCookieConsentValue('birthdate'),
				additional_infos: getCookieConsentValue('additional_infos') === null || getCookieConsentValue('additional_infos') === undefined || getCookieConsentValue('additional_infos') === "null" || getCookieConsentValue('additional_infos') === "undefined" || getCookieConsentValue('additional_infos') === "" ? "" : getCookieConsentValue('additional_infos'),
				country: getCookieConsentValue('country') === 'null' || getCookieConsentValue('country') === undefined || getCookieConsentValue('country') === 'undefined' ? null : { 'value': countryList().getValue(getCookieConsentValue('country')), 'label': getCookieConsentValue('country') },
				account_type: getCookieConsentValue('account_type') === 'null' || getCookieConsentValue('account_type') === undefined || getCookieConsentValue('account_type') === 'undefined' ? "Particulier" : getCookieConsentValue('account_type'),
				id_card_check_status: getCookieConsentValue('id_card_check_status'),
				
			}
		)

		if (getCookieConsentValue('account_type') !== "Particulier") {
			this.setState({
				registration_number: getCookieConsentValue('registration_number') === 'null' || getCookieConsentValue('registration_number') === undefined || getCookieConsentValue('registration_number') === 'undefined' ? null : getCookieConsentValue('registration_number'),
				company_name: getCookieConsentValue('company_name') === 'null' || getCookieConsentValue('company_name') === undefined || getCookieConsentValue('company_name') === 'company_name' ? null : getCookieConsentValue('company_name'),
				company_country_name: getCookieConsentValue('company_country_name') === 'null' || getCookieConsentValue('company_country_name') === undefined || getCookieConsentValue('company_country_name') === 'undefined' ? null : { 'value': countryList().getValue(getCookieConsentValue('company_country_name')), 'label': getCookieConsentValue('company_country_name') },
			})

		}

	}


	udapteUserInfoRequest = async (query, variables) => {
		try {
			const response = await axios.post(USER_INFO_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken,
						'AccessToken': this.accessToken
					}
				}
			);
			console.log("datzzzz",response)
			this.setState(() => ({
				isLoaded: true,
				items: response.data.data
			}));

			if (this.state.items.updateInfosUser.status === 200) {

				var Plus = "+"
				Cookies.set('userName', this.state.username, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('userConfirmed', this.state.userConfirmed, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('name', this.state.name, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('family_name', this.state.family_name, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('email', this.state.email, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('gender', this.state.gender, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('given_name', this.state.given_name, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('phone_number', Plus.concat(this.state.phone_number.split('+').join('')), { path: '/', secure: SECURE_COOKIES, expires: 1 })  //Plus.concat(this.state.phone_number.replaceAll("+","")), { path: '/' })
				Cookies.set('country', this.state.country.label, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('locality', this.state.locality, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('street_address', this.state.street_address, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('description', this.state.description, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('birthdate', this.state.birthdate, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('city_of_residence', this.state.city_of_residence, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				//Cookies.set('id_card_check_status', this.state.city_of_residence, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('postal_code', this.state.postal_code, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				Cookies.set('additional_infos', this.state.additional_infos, { path: '/', secure: SECURE_COOKIES, expires: 1 })


				Cookies.set('account_type', this.state.account_type === null || this.state.account_type === undefined || this.state.account_type === 'null' || this.state.account_type === 'undefined' ? '' : this.state.account_type, { path: '/', secure: SECURE_COOKIES, expires: 1 })
				if (this.state.account_type !== "Particulier" && this.state.account_type !== null && this.state.account_type !== undefined && this.state.account_type !== 'null' && this.state.account_type !== 'undefined' && this.state.account_type !== '') {
					Cookies.set('registration_number', this.state.registration_number ? this.state.registration_number : '', { path: '/', secure: SECURE_COOKIES, expires: 1 })
					Cookies.set('company_name', this.state.company_name ? this.state.company_name : '', { path: '/', secure: SECURE_COOKIES, expires: 1 })
					Cookies.set('company_country_name', this.state.company_country_name.label ? this.state.company_country_name.label : '', { path: '/', secure: SECURE_COOKIES, expires: 1 })
				}
				window.scrollTo(0, 0);
				this.setState(() => ({
					showAlert: true,
					msgAlertBody: 'La modification de vos informations a bien été prise en compte.',
					msgAlertHeader: 'Succès',
					alertVariant: 'success'
				}));
				//alert("Vos informations ont bien été enregistrées avec succès")
				//window.location.reload(false);
			}
			else {
				this.setState(() => ({
					showAlert: true,
					msgAlertBody: "Veuillez renseigner correctement vos informations",
					msgAlertHeader: 'Alerte',

				}));
				window.scrollTo(0, 0);
			}
		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	getGeoInfo = () => {
		axios.get('https://ipapi.co/json/').then((response) => {
			let data = response.data;
			this.setState({
				countryCode: data.country_code,
				country_calling_code: data.country_calling_code
			});
		}).catch((error) => {
			console.log(error);
		});
	};

	render() {
		return (

			<div className="myprofile">
				<aside className="aside section info">

					<div className="section-inner">
						<h3 className="heading">Informations personnelles</h3>
						<Form validated={this.state.validated} onSubmit={this.handleSubmit}>
							<div className="form-row">
								<div className="form-group col-md-12">
									<label for="gender">Genre</label>
									<div className="form-row">
										<div className="form-group col-md-2">
											<input id="radio11" name="radiosex" type="radio" className="magic-radio" value="M" checked={this.state.gender === 'M'} onChange={this.handle_sexe_change} />
											<label htmlFor="radio11">Homme</label>
										</div>
										<div className="form-group col-md-2">
											<input id="radio12" name="radiosex" type="radio" className="magic-radio" value="F" checked={this.state.gender === 'F'} onChange={this.handle_sexe_change} />
											<label htmlFor="radio12">Femme</label>
										</div>
										<div className="form-group col-md-4">
											<input id="radio13" name="radiosex" type="radio" className="magic-radio" value="N" checked={this.state.gender === 'N'} onChange={this.handle_sexe_change} />
											<label htmlFor="radio13">Je préfère ne pas le dire</label>
										</div>
									</div>
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col-md-6">
									<Form.Group controlId="validationCustom01">
										<Form.Label>Prénom(s) *</Form.Label>
										<Form.Control
											required
											type="text"
											placeholder="Prénom"
											defaultValue={this.state.given_name === null ? '' : this.state.given_name}
											onChange={this.handleGiven_nameChange}
											readOnly={this.state.id_card_check_status === "false" || this.state.id_card_check_status === "in-progress" || this.state.id_card_check_status === "" || this.state.id_card_check_status === undefined || this.state.id_card_check_status === null || this.state.id_card_check_status === "undefined" || this.state.id_card_check_status === "null" ? false : true}
										/>
									</Form.Group>
								</div>
								<div className="form-group col-md-6">
									<Form.Group controlId="validationCustom01">
										<Form.Label>Nom *</Form.Label>
										<Form.Control
											required
											type="text"
											placeholder=""
											defaultValue={this.state.name === null ? '' : this.state.name}
											onChange={this.handleNameChange}
											readOnly={this.state.id_card_check_status === "false" || this.state.id_card_check_status === "in-progress" || this.state.id_card_check_status === "" || this.state.id_card_check_status === undefined || this.state.id_card_check_status === null || this.state.id_card_check_status === "undefined" || this.state.id_card_check_status === "null" ? false : true}
										/>
									</Form.Group>
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col-md-6">
									<Form.Group controlId="validationCustom01">
										<Form.Label>Email *</Form.Label>
										<Form.Control
											readOnly
											required
											type="email"
											placeholder="hello@monkolis.com"
											defaultValue={this.state.email === null ? '' : this.state.email}
											onChange={this.handleEmailChange}
										/>
									</Form.Group>
								</div>
								<div className="form-group">
									<label for="telephone">Téléphone Whatsapp (ex: +225 07 00 00 00) *</label>
									{/* <Form.Control
										required
										id="UserPhone-suSH"
										type="tel"
										placeholder="Entrez le numéro de téléphone"
										value={this.state.phone_number === null ? '' : this.state.phone_number}
										onChange={this.handlePhone_numberChange}
									/> */}

									{/* <PhoneInput
										 inputProps={{
											required: true,
											autoFocus: true
										}} 
										placeholder="Saisissez un numéro de téléphone valide"
										international
										autoFormat={true}
										value={this.state.phone_number === null || this.state.phone_number === undefined || this.state.phone_number === 'null' || this.state.phone_number === 'undefined' ? '' : formatPhoneNumberIntl(this.state.phone_number)}
										onChange={phone_number => this.setState({ phone_number })}
										defaultCountry={"CI"}
									/> */}

									<PhoneInput
										inputProps={{
											required: true,
											autoFocus: true
										}}
										enableSearch={true}
										defaultCountry={this.state.countryCode.toLowerCase()}
										value={this.state.phone_number === "" ? this.state.country_calling_code : this.state.phone_number}
										onChange={phone_number => this.setState({ phone_number })}
									/>
								</div>
							</div>

							<div className="form-row">
								<div className="form-group col-md-6">
									<Form.Group controlId="validationCustom01">
										<Form.Label>Date de naissance</Form.Label>
										<Form.Control
											type="date"
											placeholder=""
											defaultValue={this.state.birthdate}
											onChange={this.handleBirthdateChange}
										/>
									</Form.Group>
								</div>
								<div className="form-group col-md-6">
									<Form.Group controlId="validationCustom01">
										<Form.Label>Ville de résidence *</Form.Label>
										<Form.Control
											type="text"
											placeholder="Ma ville"
											defaultValue={this.state.city_of_residence === 'null' ? '' : this.state.city_of_residence}
											onChange={this.handleCity_of_residenceChange}
											required
										/>
									</Form.Group>
								</div>
							</div>

							<div className="form-group">
								<Form.Group controlId="validationCustom01">
									<Form.Label>Adresse</Form.Label>
									<Form.Control
										type="inputAddress"
										placeholder="1234 Main St"
										defaultValue={this.state.street_address === 'null' ? '' : this.state.street_address}
										onChange={this.handleStreet_addressChange}
									/>
								</Form.Group>
								<small id="" className="form-text text-muted">Votre adresse est confidentielle, elle ne sera jamais divulguée.</small>
							</div>

							<div className="form-group">
								<Form.Group controlId="validationCustom01">
									<Form.Label>Complément d'adresse</Form.Label>
									<Form.Control
										type="text"
										placeholder="Appartement, studio, ou étage"
										defaultValue={this.state.additional_infos === 'null' ? '' : this.state.additional_infos}
										onChange={this.handleAdditionalInfosChange}
									/>
								</Form.Group>
							</div>

							<div className="form-row">
								<div className="form-group col-md-6">
									<Form.Group controlId="validationCustom01">
										<Form.Label>Ville</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											defaultValue={this.state.locality === 'null' ? '' : this.state.locality}
											onChange={this.handleLocalityChange}
										/>
									</Form.Group>
								</div>
								<div className="form-group col-md-2">
									<Form.Group controlId="validationCustom01">
										<Form.Label>Code postal</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											defaultValue={this.state.postal_code === 'null' ? '' : this.state.postal_code}
											onChange={this.handlePostal_codeChange}
										/>
									</Form.Group>
								</div>
								<div className="form-group col-md-4">
									<Form.Group controlId="validationCustom01">
										<label for="inputState">Pays *</label>
										<Select
											required
											options={this.state.countryList}
											value={this.state.country}
											onChange={this.handleCountryChange}
										/>
									</Form.Group>
								</div>
							</div>

							<div className="form-group mar-top30 mar-bot20">
								<Form.Group controlId="validationCustom01">
									<label for="exampleFormControlTextarea1">Votre description</label>
									<small id="travelTicket" className="form-text text-muted">
										Par mesure de sécurité, prière de ne pas indiquer de <span className="fa fa-1x fa-phone-square"></span> Numéro de téléphone ou<span className="fa fa-1x fa-facebook-square"></span> de détails de votre Facebook
								        </small>
									<textarea className="form-control" id="exampleFormControlTextarea1" rows="2" value={this.state.description === 'null' ? '' : this.state.description} onChange={this.handleDescriptionChange} ></textarea>
								</Form.Group>
							</div>

							<div className="form-row">
								<div className="form-group col-md-6">
									<Form.Group controlId="validationCustom01">
										<label for="inputState" className="txt-bold">Statut de Transporteur*</label>
										<select id="inputState" className="form-control" value={this.state.account_type} onChange={this.handleTravelerTypeChange}>
											<option selected>Particulier</option>
											<option>Entreprise</option>
											<option>Auto-entrepreneur / Indépendant</option>
											<option>Artisan / Commerçant</option>
										</select>
									</Form.Group>
								</div>

								{
									this.state.account_type === "Particulier" || this.state.account_type === "null" || this.state.account_type === "undefined" || this.state.account_type === null || this.state.account_type === undefined ? '' :
										<div className="form-group col-md-6">
											<Form.Group controlId="validationCustom01">
												<label for="inputIDEnter" class="txt-bold">Identifiant* (SIRET, Immatriculation, etc)</label>
												<Form.Control
													type="text"
													placeholder="Identifiant entreprise"
													defaultValue={this.state.registration_number === 'null' ? '' : this.state.registration_number}
													onChange={this.handleIdEntrepriseChange}
												/>
											</Form.Group>
										</div>
								}
							</div>
							{
								this.state.account_type === "Particulier" || this.state.account_type === "null" || this.state.account_type === "undefined" || this.state.account_type === null || this.state.account_type === undefined ? '' :
									<div className="form-row mar-bot20">
										<div className="form-group col-md-6">
											<Form.Group controlId="validationCustom01">
												<label for="inputNameStructure" class="txt-bold">Nom de votre structure*</label>
												<Form.Control
													type="text"
													placeholder="Nom de votre structure"
													defaultValue={this.state.company_name === 'null' ? '' : this.state.company_name}
													onChange={this.handleNameEntrepriseChange}
												/>
											</Form.Group>
										</div>
										<div className="form-group col-md-6">
											<Form.Group controlId="validationCustom01">
												<label for="EntCountryName" class="txt-bold">Pays de domiciliation*</label>
												<Select
													id="EntCountryName"
													required
													options={this.state.countryList}
													value={this.state.company_country_name}
													onChange={this.handleCountryImatChange}
												/>
											</Form.Group>
										</div>
									</div>
							}

							<input type="submit" className="btn btn-theme2" value="Enregistrer mes données" />
						</Form>
						<Modal show={this.state.showAlert} onHide={this.handleCloseAlert} centered>
							<Modal.Header closeButton>
								<Modal.Title>{this.state.msgAlertHeader}</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<p>{this.state.msgAlertBody}</p>
							</Modal.Body>

							<Modal.Footer>
								<button className="btn btn-outline-primary" onClick={this.handleCloseAlert}>
									OK
                    			</button>
							</Modal.Footer>
						</Modal>
					</div>


				</aside>

			</div>




		);
	}
}
export default withRouter(MyPersoInformation);