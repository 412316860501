
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { GET_AVAILABLE_MONEY_QUERY } from '../../../query/queryprofil';
import { GET_AVAILABLE_MONEY_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Moment from "moment";
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyMoneyAvailable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],

		};
	}
	componentDidMount() {
		
        this.idToken = getCookieConsentValue('idToken');
		//this.idToken = sessionStorage.getItem('idToken')
		this.getPayementsMade(GET_AVAILABLE_MONEY_QUERY)
	}


	getPayementsMade = async (query) => {
		try {
			const response = await axios.post(GET_AVAILABLE_MONEY_URL,
				{ query },
				{
					'headers':
					{
						'Authorization': this.idToken
					}
				}
			);
			this.setState(() => ({
				isLoaded: true,
				items: response.data.data.getUserAvailablesMoney.user_availables_money
			}));

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	render() {
		if (this.state.items.length === 0) {
			return (
				<div className="col-sm-12">
					<aside class="aside section">
						<div class="section-inner">
							<h3 class="heading">Argent disponible</h3>
							<p>	Pour la phase de lancement de la plateforme MonKolis, le règlement des transactions s'effectue en direct entre l'expéditeur et le transporteur.</p>
							{/*<p>

								<span class="txt-bold">Quand est ce que mon argent est disponible ?</span>
								<br />
							  Votre argent pour un voyage est disponible une fois que vous validez les codes de tous les expéditeurs que vous avez aidés.
							<br /><br />

								<span class="txt-bold">Où est mon argent ?</span>
								<br />
							C'est dans la section Préférences de virement que vous pouvez choisir et modifier la méthode de virement, sur laquelle nous envoyons votre argent par défaut.
							<br /><br />
								<span class="txt-bold">Sous combien de temps recevrai-je mon argent ?</span>
								<br />
							Si vous avez demandé votre virement depuis plus de 24 heures, nous vous l'avons viré. Il apparaîtra sur votre compte sous 5 jours ouvrés.
						    Pour voir ce que nous vous avons déjà envoyé, consultez l'historique des virements.

							</p>*/}
						</div>
					</aside>

					<div>VOTRE HISTORIQUE EST VIDE</div>
				</div >
			)
		} else {
			return (
				<div class="col-sm-12">

					<aside class="aside section">
						<div class="section-inner">
							<h3 class="heading">Argent disponible</h3>
							<p>	Pour la phase de lancement de la plateforme MonKolis, le règlement des transactions s'effectue en direct entre l'expéditeur et le transporteur.</p>
							{/*<p>

								<span class="txt-bold">Quand est ce que mon argent est disponible ?</span>
								<br />
							  Votre argent pour un voyage est disponible une fois que vous validez les codes de tous les expéditeurs que vous avez aidés.
							<br /><br />

								<span class="txt-bold">Où est mon argent ?</span>
								<br />
							C'est dans la section Préférences de virement que vous pouvez choisir et modifier la méthode de virement, sur laquelle nous envoyons votre argent par défaut.
							<br /><br />
								<span class="txt-bold">Sous combien de temps recevrai-je mon argent ?</span>
								<br />
							Si vous avez demandé votre virement depuis plus de 24 heures, nous vous l'avons viré. Il apparaîtra sur votre compte sous 5 jours ouvrés.
						    Pour voir ce que nous vous avons déjà envoyé, consultez l'historique des virements.

							</p>*/}
						</div>
					</aside>

					{/*{this.state.items.map((value, index) => {
                            return <div key={index} >
					<div class="card money mar-bot20">
						<div class="card-header">
							<div class="row">
								<div class="col-md-5">
									<h3 class="panel-title">
									<span class="">{value.travel_infos.arrival_city}</span >

										<i class="fa fa-long-arrow-right color-text2"></i>

										<span class="">{value.travel_infos.departure_city}</span>
									</h3>
								</div>
								<div class="col-md-7 status"> 
									<h4 class="card-title">
										Confirmé
		                     </h4>
								</div>

							</div>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-5 criteria">
									<p><span class="fa fa-1x fa-calendar"></span > {Moment(value.travel_infos.departure_date).format('DD-MM-YYYY')}</p>
									<p><span class="fa fa-1x fa-tags color-orange"></span >  Frais de service - {value.total_service_charge_amount}€ </p>
									<a href="/dashboard/mytrips/historic" class="link color-blue" > <span class="fa fa-1x fa-long-arrow-right"></span> Voir les détails</a>
								</div>
								<div class="col-md-7 comments">
									<p>Le virement de votre argent sera effectué une fois que vous aurez défini vos préférences de virement.<br /><br />

										<a href="/dashboard/money/" class="link color-blue"> <span class="fa fa-1x fa-long-arrow-right"></span> Entrer mes coordonnées bancaires</a>

									</p>


								</div>

							</div>
						</div>
					</div>
					</div>
				})}*/}

				</div>






			);
		}
	}
}
export default withRouter(MyMoneyAvailable);