import React, { Component } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class ContactSuccess extends Component{
    render() {
        return (
            <div className="section_contact">
                <div className="section_contact_success">
                    <h2>Succès!</h2>
                    <p className>Votre message a bien été envoyé, un administrateur vous contactera très prochainement </p>
                    <div className="btn btn-primary back_link ">
                        <a href="/" className="txt-white">Retour à l'accueil</a>
                    </div>
                </div>
            </div>

        );
    }
}

export default ContactSuccess;
