import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert'
import axios from 'axios';
import { GET_PREFERENCE_QUERY, UPDATE_PREFERENCE_QUERY } from './../../../query/queryprofil'
import { USER_INFO_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import Moment from 'moment';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyPreferences extends Component {
	constructor(props) {
		super(props);
		this.state = {
			preferences: [],
			enveloppes: true,
			sacsPleins: true,
			paquets: true,
			liquides: true,
			nourriture: true,
			msgAlertBody : '',
			msgAlertHeader : '',
			alertVariant :'danger',
			showAlert : false,
		};
		this.handleSubmit = this.handleSubmit.bind(this)

		this.handle_enveloppes_change = this.handle_enveloppes_change.bind(this)
		this.handle_sacsPleins_change = this.handle_sacsPleins_change.bind(this)
		this.handle_paquets_change = this.handle_paquets_change.bind(this)
		this.handle_liquides_change = this.handle_liquides_change.bind(this)
		this.handle_nourriture_change = this.handle_nourriture_change.bind(this)
		this.handle_enveloppes_change_false = this.handle_enveloppes_change_false.bind(this)
		this.handle_sacsPleins_change_false = this.handle_sacsPleins_change_false.bind(this)
		this.handle_paquets_change_false = this.handle_paquets_change_false.bind(this)
		this.handle_liquides_change_false = this.handle_liquides_change_false.bind(this)
		this.handle_nourriture_change_false = this.handle_nourriture_change_fakse.bind(this)
		this.handleCloseAlert = this.handleCloseAlert.bind(this);
	}
	
	componentWillMount(){
		
        this.idToken = getCookieConsentValue('idToken');
		// this.idToken = sessionStorage.getItem('idToken')
		this.getPreferences(GET_PREFERENCE_QUERY, this.idToken)
		
	}


	handle_enveloppes_change() { this.setState({ enveloppes: true })}
	handle_sacsPleins_change() { this.setState({ sacsPleins: true }); }
	handle_paquets_change() { this.setState({ paquets: true }); }
	handle_liquides_change() { this.setState({ liquides: true }); }
	handle_nourriture_change() { this.setState({ nourriture: true }); }
	handle_enveloppes_change_false() { this.setState({ enveloppes: false })}
	handle_sacsPleins_change_false() { this.setState({ sacsPleins: false }); }
	handle_paquets_change_false() { this.setState({ paquets: false }); }
	handle_liquides_change_false() { this.setState({ liquides: false }); }
	handle_nourriture_change_fakse() { this.setState({ nourriture: false }); }
	handleCloseAlert(event) {
		this.setState({ showAlert: false});
	}

	getPreferences= async(query,idToken) => {
		try {
			const response = await axios.post(USER_INFO_URL,
				{ query },
				{
					'headers':
					{
						'Authorization': idToken
					}
				}
			);

			// Log the response so we can look at it in the console
			// Set the data to the state
			if (response.data.data.getUserExtraParameters.status === 200){
				this.setState({
					preferences: response.data.data.getUserExtraParameters.user_extra_parameters.preferences
				});
			}
			if (this.state.preferences.length !==0){
				this.setState({ 
					enveloppes: this.state.preferences[0].pref_value ==="Y" ? true : false,
					sacsPleins: this.state.preferences[1].pref_value ==="Y" ? true : false,
					paquets: this.state.preferences[2].pref_value ==="Y" ? true : false,
					liquides: this.state.preferences[4].pref_value ==="Y" ? true : false,
					nourriture: this.state.preferences[3].pref_value ==="Y" ? true : false,
				});
			}
		} catch (error) {
			console.log(error)
		}
	}

	putpreferences = async (query, variables) =>{
		try {
            const response = await axios.post(USER_INFO_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
			);
			this.setState(() => ({
                showAlert : true,
                msgAlertBody : 'La mise à jour de vos préférences a été effectuée',
                msgAlertHeader : 'Done',
                alertVariant : 'success' 
            }));
			//alert("La mise à jour de vos préférences a été effectuée")
        } catch (error) {
            // If there's an error, set the error to the state
			this.setState(() => ({ error }))
			console.log(error)
			this.setState(() => ({
                showAlert : true,
                msgAlertBody : 'La mise à jour de vos préférences a échoué',
                msgAlertHeader : 'Error',
                alertVariant : 'danger'
                
            }));
			//alert('La mise à jour de vos préférences a échoué')
        }
	}

	handleSubmit(event){
		event.preventDefault();
		var VAR = JSON.stringify({
			"user_extra_parameters":{
				"preferences": [
					{
						"pref_name": "Enveloppes",
						"pref_type": "S",
						"pref_value": this.state.enveloppes ? "Y" : "N" 
					},
					{
						"pref_name": "Sacs pleins",
						"pref_type": "S",
						"pref_value": this.state.sacsPleins ? "Y" : "N"
					},
					{
						"pref_name": "Paquets",
						"pref_type": "S",
						"pref_value": this.state.paquets ? "Y" : "N"
					},
					{
						"pref_name": "liquides",
						"pref_type": "S",
						"pref_value": this.state.liquides ? "Y" : "N"
					},
					{
						"pref_name": "Nourriture",
						"pref_type": "S",
						"pref_value": this.state.nourriture ? "Y" : "N"
					}
					
				]
			}
		})
		this.putpreferences( UPDATE_PREFERENCE_QUERY, VAR)
	}

	render() {
		return (
		
		<div id="pagetitle">
			<Helmet>
				<title>Préférences - informations personnelles | Monkolis</title>
				<meta name="robots" content="noindex,follow"/>
			</Helmet>

		<div class="myprofile">
		<div class="section prefered">
			<form onSubmit={this.handleSubmit}>
					<div class="section-inner">
						
						<h3 class="heading">Préférences</h3>
						{this.state.showAlert ?
							<Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert} dismissible>
								<Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
								<p>{this.state.msgAlertBody} </p>
							</Alert> : ''}
						<div class="content">
							<p>
								Vous avez la possibilité d'afficher les différentes catégories de kolis que vous souhaitez transporter sur vos trajets.
								</p>
						<div class="row settings">
							<div class="col-sm-5 col-6 itake">
								<p class="txt-underline align-right">Je prends</p>
							</div>

							<div class="col-sm-4 col-6 notake">
								<p class="txt-underline">Je ne prends pas</p>

							</div>
						 </div>
						<div class="row">
							<div class="col-md-4 col-4">
								<p class="align-right">Enveloppes</p>
							</div>
							<div class="col-md-3 col-3">
								<input id="radio11" name="radioenv" type="radio" class="magic-radio" value={true} checked={this.state.enveloppes === true} onChange={this.handle_enveloppes_change} />
								<label for="radio11"></label>
							</div>
							<div class="col-md-3 col-3">
								<input id="radio12" name="radioenv" type="radio" class="magic-radio" value={false} checked={this.state.enveloppes === false} onChange={this.handle_enveloppes_change_false}/>
								<label for="radio12"></label>
							</div>
						 </div>

						 <div class="row">
							<div class="col-md-4 col-4">
								<p class="align-right">Sacs pleins</p>
							</div>
							<div class="col-md-3 col-3">
								<input id="radio21" name="radiobag" type="radio" class="magic-radio" value={true} checked={this.state.sacsPleins === true} onChange={this.handle_sacsPleins_change} />
								<label for="radio21"></label>
							</div>
							<div class="col-md-3 col-3">
								<input id="radio22" name="radiobag" type="radio" class="magic-radio" value={false} checked={this.state.sacsPleins === false} onChange={this.handle_sacsPleins_change_false} />
								<label for="radio22"></label>
							</div>
						 </div>

						 <div class="row">
							<div class="col-md-4 col-4">
								<p class="align-right">Paquets</p>
							</div>
							<div class="col-md-3 col-3">
								<input id="radio31" name="radiopaq" type="radio" class="magic-radio" value={true} checked={this.state.paquets === true} onChange={this.handle_paquets_change}/>
								<label for="radio31"></label>
							</div>
							<div class="col-md-3 col-3">
								<input id="radio32" name="radiopaq" type="radio" class="magic-radio" value={false} checked={this.state.paquets === false} onChange={this.handle_paquets_change_false} />
								<label for="radio32"></label>
							</div>
						 </div>

						 <div class="row">
							<div class="col-md-4 col-4">
								<p class="align-right">Liquides</p>
							</div>
							<div class="col-md-3 col-3">
								<input id="radio41" name="radiobij" type="radio" class="magic-radio" value={true} checked={this.state.liquides === true} onChange={this.handle_liquides_change} />
								<label for="radio41"></label>
							</div>
							<div class="col-md-3 col-3">
								<input id="radio42" name="radiobij" type="radio" class="magic-radio" value={false} checked={this.state.liquides === false} onChange={this.handle_liquides_change_false} />
								<label for="radio42"></label>
							</div>
						 </div>

						 <div class="row">
							<div class="col-md-4 col-4">
								<p class="align-right">Nourriture</p>
							</div>

							<div class="col-md-3 col-3">
								<input id="radio51" name="radioeat" type="radio" class="magic-radio" value={true} checked={this.state.nourriture === true} onChange={this.handle_nourriture_change} />
								<label for="radio51"></label>
							</div>
							<div class="col-md-3 col-3">
								<input id="radio52" name="radioeat" type="radio" class="magic-radio" value={false} checked={this.state.nourriture === false} onChange={this.handle_nourriture_change_false} />
								<label for="radio52"></label>
							</div>
						 </div>
						</div>
					</div>   
					<br/>    
					<input type="submit" className="btn btn-primary" value="Modifier mes préférences" />
				</form>
			</div>
			</div>
		
	</div>);
}
}
export default withRouter(MyPreferences);