import React, { Component } from 'react';
import logo from './../../assets/img/logo/MonKolisa.png';
import NavItem from './../navitem/navitem';

import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';

import PHOTO from '../../assets/img/user/user0.png'
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import { SEARCHTRAVEL_QUERY } from './../../query/querytrajet';
import { SEARCHTRAVEL_URL, USER_INFO_URL,SECURE_COOKIES } from './../../Url';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import Amplify, { Auth, Hub } from 'aws-amplify';
import { CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js';
//import awsconfig from '../../aws-exports';
//Amplify.configure(awsconfig);
Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        identityPoolRegion: 'eu-west-1',
        identityPoodId: 'eu-west-1:3cb5ff3c-a4ed-4856-aba6-9328d0c6afe7',
        userPoolId: 'eu-west-1_86RptyH5c',
        userPoolWebClientId: '49qp7c8hrmqv1ptpfrnmg71g8k',
        oauth: {
            domain: 'monkolis-prd.auth.eu-west-1.amazoncognito.com',
            redirectSignIn: 'https://www.monkolis.com/',
            redirectSignOut: 'https://www.monkolis.com/logout',
            scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            responseType: 'token'
        }
    }
});

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        }
        this.handleDeconnexionClick = this.handleDeconnexionClick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handlePublishNewTravelClick = this.handlePublishNewTravelClick.bind(this);
    }

    async componentDidMount() {
        if (getCookieConsentValue('counter') === null || getCookieConsentValue('counter') === undefined || getCookieConsentValue('counter') === 'null' || getCookieConsentValue('counter') === 'undefined' || getCookieConsentValue('counter') === ''){
            Cookies.set('counter', 0, { path: '/', secure: SECURE_COOKIES, expires: 1 });
        }
            await Auth.currentSession().then(user => {
            Cookies.set('idToken', user.idToken.jwtToken, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('accessToken', user.accessToken.jwtToken, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('refreshToken', user.refreshToken.jwtToken, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('userName', user.idToken.payload.name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('user_name', user.idToken.payload['cognito:username'], { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('userSub', user.idToken.payload.sub, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('userConfirmed', user.idToken.payload.email_verified, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('name', user.idToken.payload.name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('family_name', user.idToken.payload.given_name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('email', user.idToken.payload.email, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('given_name', user.idToken.payload.given_name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('phone_number', user.idToken.payload.phone_number, { path: '/', secure: SECURE_COOKIES, expires: 1 });
            Cookies.set('birthdate', user.idToken.payload.birthdate, { path: '/', secure: SECURE_COOKIES, expires: 1 });
        })
        .catch((e) => console.log(e));
        await Auth.currentAuthenticatedUser( {bypassCache:true })
            .then(user => {
                const { attributes = {} } = user;
                Cookies.set('userName', attributes.name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('user_name', attributes['cognito:username'], { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('userSub', attributes.sub, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('userConfirmed', attributes.email_verified, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('name', attributes.name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('family_name', attributes.given_name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('email', attributes.email, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('given_name', attributes.given_name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('birthdate', attributes.birthdate, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('phone_number', attributes.phone_number, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('photo', attributes.picture, { path: '/' });
                Cookies.set('id_card_check_status', attributes["custom:id_card_check_status"] !== null || attributes["custom:id_card_check_status"] !== undefined || attributes["custom:id_card_check_status"] !== "null" || attributes["custom:id_card_check_status"] !== "undefined" || attributes["custom:id_card_check_status"] !== "" ? attributes["custom:id_card_check_status"] : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('additional_infos', attributes["custom:additional_infos"] !== null || attributes["custom:additional_infos"] !== undefined || attributes["custom:additional_infos"] !== "null" || attributes["custom:additional_infos"] !== "undefined" || attributes["custom:additional_infos"] !== "" ? attributes["custom:additional_infos"] : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('contrib_cwf_balance', attributes["custom:contrib_cwf_balance"] !== null || attributes["custom:contrib_cwf_balance"] !== undefined || attributes["custom:contrib_cwf_balance"] !== "null" || attributes["custom:contrib_cwf_balance"] !== "undefined" || attributes["custom:contrib_cwf_balance"] !== "" ? attributes["custom:contrib_cwf_balance"] : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('description', attributes["custom:description"] !== null || attributes["custom:description"] !== undefined || attributes["custom:description"] !== "null" || attributes["custom:description"] !== "undefined" || attributes["custom:description"] !== "" ? attributes["custom:description"] : "", { path: '/' , secure: SECURE_COOKIES, expires: 1});
                Cookies.set('account_type', attributes["custom:account_type"] !== null || attributes["custom:account_type"] !== undefined || attributes["custom:account_type"] !== "null" || attributes["custom:account_type"] !== "undefined" || attributes["custom:account_type"] !== "" ? attributes["custom:account_type"] : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('city_of_residence', attributes["custom:city_of_residence"] !== null || attributes["custom:city_of_residence"] !== undefined || attributes["custom:city_of_residence"] !== "null" || attributes["custom:city_of_residence"] !== "undefined" || attributes["custom:city_of_residence"] !== "" ? attributes["custom:city_of_residence"] : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                if (attributes.address !== null || attributes.address !== undefined || attributes.address !== "null" || attributes.address !== "undefined" || attributes.address !== "") {
                    const addr = JSON.parse(attributes.address)
                    Cookies.set('street_address', addr.street_address !== null || addr.street_address !== undefined || addr.street_address !== "null" || addr.street_address !== "undefined" || addr.street_address !== "" ? addr.street_address : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    Cookies.set('locality', addr.locality !== null || addr.locality !== undefined || addr.locality !== "null" || addr.locality !== "undefined" || addr.locality !== "" ? addr.locality : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    Cookies.set('region', addr.region !== null || addr.region !== undefined || addr.region !== "null" || addr.region !== "undefined" || addr.region !== "" ? addr.region : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    Cookies.set('postal_code', addr.postal_code !== null || addr.postal_code !== undefined || addr.postal_code !== "null" || addr.postal_code !== "undefined" || addr.postal_code !== "" ? addr.postal_code : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    Cookies.set('country', addr.country !== null || addr.country !== undefined || addr.country !== "null" || addr.country !== "undefined" || addr.country !== "" ? addr.country : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                } else {
                    Cookies.set('street_address', "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    Cookies.set('locality', "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    Cookies.set('region', "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    Cookies.set('postal_code', "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    Cookies.set('country', "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                }
                if (getCookieConsentValue('account_type') !== "Particulier") {
                    if (attributes["custom:account_type_details"] !== null || attributes["custom:account_type_details"] !== null || attributes["custom:account_type_details"] !== undefined || attributes["custom:account_type_details"] !== "null" || attributes["custom:account_type_details"] !== "undefined" || attributes["custom:account_type_details"] !== "") {
                        const type_details = JSON.parse(attributes["custom:account_type_details"])
                        Cookies.set('registration_number', type_details.registration_number !== null || type_details.registration_number !== undefined || type_details.registration_number !== "null" || type_details.registration_number !== "undefined" || type_details.registration_number !== "" ? type_details.registration_number : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                        Cookies.set('company_name', type_details.company_name !== null || type_details.company_name !== undefined || type_details.company_name !== "null" || type_details.company_name !== "undefined" || type_details.company_name !== "" ? type_details.company_name : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                        Cookies.set('company_country_name', type_details.company_country_name !== null || type_details.company_country_name !== undefined || type_details.company_country_name !== "null" || type_details.company_country_name !== "undefined" || type_details.company_country_name !== "" ? type_details.company_country_name : "", { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    }
                }
                 this.setState({ user })
                 if (getCookieConsentValue('counter') === 0 || getCookieConsentValue('counter') === '0') {
                    Cookies.set('counter', 1, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                    window.location.reload(true);
                }
            }
            )
            //.catch(() => console.log("Not signed in"));
            .catch(() => this.setState({ user: null }));
    }


    /* componentDidMount () {
        Hub.listen ("auth", async ({ payload: { event, data } })  =>  {
            console.log(event)
            switch (event) {
                case "signIn":
                    this.setState({ user: data });
                    //console.log(this.state.user.attributes)
                    //Auth.currentSession().then(user => console.log(user.idToken.jwtToken))
                    await Auth.currentSession().then(user => {
                        console.log(user)
                        
                        Cookies.set('idToken', user.idToken.jwtToken, { path: '/' });
                        Cookies.set('accessToken', user.accessToken.jwtToken, { path: '/' });
                        Cookies.set('refreshToken', user.refreshToken.jwtToken, { path: '/' });
                        Cookies.set('userName', user.idToken.payload.name, { path: '/' });
                        Cookies.set('user_name', user.idToken.payload['cognito:username'], { path: '/' });
                        Cookies.set('userSub', user.idToken.payload.sub, { path: '/' });
                        Cookies.set('userConfirmed', user.idToken.payload.email_verified, { path: '/' });
                        Cookies.set('name', user.idToken.payload.name, { path: '/' });
                        Cookies.set('family_name', user.idToken.payload.given_name, { path: '/' });
                        Cookies.set('email', user.idToken.payload.email, { path: '/' });
                        Cookies.set('given_name', user.idToken.payload.given_name, { path: '/' });
                        Cookies.set('phone_number', user.idToken.payload.phone_number, { path: '/' });
                        Cookies.set('birthdate', user.idToken.payload.birthdate, { path: '/' });
                    })
                    .catch((e) => console.log(e));
                   await Auth.currentAuthenticatedUser()
                        .then(user => {
                            const { attributes = {} } = user;
                            
                            Cookies.set('photo', attributes.picture, { path: '/' });
                            Cookies.set('additional_infos', attributes["custom:additional_infos"] !== null || attributes["custom:additional_infos"] !== undefined || attributes["custom:additional_infos"] !== "null" || attributes["custom:additional_infos"] !== "undefined" || attributes["custom:additional_infos"] !== "" ? attributes["custom:additional_infos"] : "", { path: '/' });
                            Cookies.set('contrib_cwf_balance', attributes["custom:contrib_cwf_balance"] !== null || attributes["custom:contrib_cwf_balance"] !== undefined || attributes["custom:contrib_cwf_balance"] !== "null" || attributes["custom:contrib_cwf_balance"] !== "undefined" || attributes["custom:contrib_cwf_balance"] !== "" ? attributes["custom:contrib_cwf_balance"] : "", { path: '/' });
                            Cookies.set('description', attributes["custom:description"] !== null || attributes["custom:description"] !== undefined || attributes["custom:description"] !== "null" || attributes["custom:description"] !== "undefined" || attributes["custom:description"] !== "" ? attributes["custom:description"] : "", { path: '/' });
                            Cookies.set('account_type', attributes["custom:account_type"] !== null || attributes["custom:account_type"] !== undefined || attributes["custom:account_type"] !== "null" || attributes["custom:account_type"] !== "undefined" || attributes["custom:account_type"] !== "" ? attributes["custom:account_type"] : "", { path: '/' });
                            Cookies.set('city_of_residence', attributes["custom:city_of_residence"] !== null || attributes["custom:city_of_residence"] !== undefined || attributes["custom:city_of_residence"] !== "null" || attributes["custom:city_of_residence"] !== "undefined" || attributes["custom:city_of_residence"] !== "" ? attributes["custom:city_of_residence"] : "", { path: '/' });
                            if (attributes.address !== null || attributes.address !== undefined || attributes.address !== "null" || attributes.address !== "undefined" || attributes.address !== "") {
                                const addr = JSON.parse(attributes.address)
                                Cookies.set('street_address', addr.street_address !== null || addr.street_address !== undefined || addr.street_address !== "null" || addr.street_address !== "undefined" || addr.street_address !== "" ? addr.street_address : "", { path: '/' });
                                Cookies.set('locality', addr.locality !== null || addr.locality !== undefined || addr.locality !== "null" || addr.locality !== "undefined" || addr.locality !== "" ? addr.locality : "", { path: '/' });
                                Cookies.set('region', addr.region !== null || addr.region !== undefined || addr.region !== "null" || addr.region !== "undefined" || addr.region !== "" ? addr.region : "", { path: '/' });
                                Cookies.set('postal_code', addr.postal_code !== null || addr.postal_code !== undefined || addr.postal_code !== "null" || addr.postal_code !== "undefined" || addr.postal_code !== "" ? addr.postal_code : "", { path: '/' });
                                Cookies.set('country', addr.country !== null || addr.country !== undefined || addr.country !== "null" || addr.country !== "undefined" || addr.country !== "" ? addr.country : "", { path: '/' });
                            } else {
                                Cookies.set('street_address', "", { path: '/' });
                                Cookies.set('locality', "", { path: '/' });
                                Cookies.set('region', "", { path: '/' });
                                Cookies.set('postal_code', "", { path: '/' });
                                Cookies.set('country', "", { path: '/' });
                            }
                            if (getCookieConsentValue('account_type') !== "Particulier") {
                                if (attributes["custom:account_type_details"] !== null || attributes["custom:account_type_details"] !== null || attributes["custom:account_type_details"] !== undefined || attributes["custom:account_type_details"] !== "null" || attributes["custom:account_type_details"] !== "undefined" || attributes["custom:account_type_details"] !== "") {
                                    const type_details = JSON.parse(attributes["custom:account_type_details"])
                                    Cookies.set('registration_number', type_details.registration_number !== null || type_details.registration_number !== undefined || type_details.registration_number !== "null" || type_details.registration_number !== "undefined" || type_details.registration_number !== "" ? type_details.registration_number : "", { path: '/' });
                                    Cookies.set('company_name', type_details.company_name !== null || type_details.company_name !== undefined || type_details.company_name !== "null" || type_details.company_name !== "undefined" || type_details.company_name !== "" ? type_details.company_name : "", { path: '/' });
                                    Cookies.set('company_country_name', type_details.company_country_name !== null || type_details.company_country_name !== undefined || type_details.company_country_name !== "null" || type_details.company_country_name !== "undefined" || type_details.company_country_name !== "" ? type_details.company_country_name : "", { path: '/' });
                                }
                            }
                            this.setState({ user })
                        }
                        )
                        //.catch(() => console.log("Not signed in"));
                        .catch(() => this.setState({ user: null }));
                    window.location.reload(true);

                    break;
                case "signOut":
                    //console.log(this.state.user)
                    this.setState({ user: null });
                    break;
                case "customOAuthState":
                    this.setState({ customState: data });

                default:
                    console.log('here')
            }
        });

    } */


    componentWillMount() {
        // this.user_name = sessionStorage.getItem('given_name');
        // this.photo = sessionStorage.getItem('photo');

        this.user_name = getCookieConsentValue('given_name');
        this.photo = getCookieConsentValue('photo');
    }

    handleSearch(event) {

        //if (sessionStorage.getItem('phone_number') === null || sessionStorage.getItem('phone_number') === undefined || sessionStorage.getItem('phone_number') === 'null' || sessionStorage.getItem('phone_number') === 'undefined') {
        if (getCookieConsentValue('phone_number') === null || getCookieConsentValue('phone_number') === undefined || getCookieConsentValue('phone_number') === 'null' || getCookieConsentValue('phone_number') === 'undefined') {
            alert("Vous n'avez pas renseigné de numéro de Télephone, merci de le faire dans la page Profil")
            this.props.history.push({
                pathname: '../dashboard/myprofile',
            })
        }
        else {
            event.preventDefault();
            var VAR = JSON.stringify({
                "search_travel_proposal": {}
            })
            this.searchTravel(SEARCHTRAVEL_QUERY, VAR)

        }

        /*  event.preventDefault();
         var VAR = JSON.stringify({
             "search_travel_proposal": {}
         })
         this.searchTravel(SEARCHTRAVEL_QUERY, VAR) */
    }

    handlePublishNewTravelClick(event) {

        //if (sessionStorage.getItem('phone_number') === null || sessionStorage.getItem('phone_number') === undefined || sessionStorage.getItem('phone_number') === 'null' || sessionStorage.getItem('phone_number') === 'undefined') {
        if (getCookieConsentValue('phone_number') === null || getCookieConsentValue('phone_number') === undefined || getCookieConsentValue('phone_number') === 'null' || getCookieConsentValue('phone_number') === 'undefined') {
            alert("Vous n'avez pas renseigner de numéro de Télephone, merci de le faire dans la page Profil")
            this.props.history.push({
                pathname: '../dashboard/myprofile',
            })
        }
        else {
            this.props.history.push({
                pathname: '/dashboard/mytrips',
                state: { pages: 'new_travel' }
            })

        }

        /* this.props.history.push({
            pathname: '/dashboard/mytrips',
            state: { pages: 'new_travel' }
        }); */
    }

    searchTravel = async (query, variables) => {
        try {
            const response = await axios.post(SEARCHTRAVEL_URL, {
                query,
                variables
            });
            this.setState(() => ({
                isLoaded: true,
                items: response.data.data
            }));

            // Log the response so we can look at it in the console

            this.props.history.push({
                pathname: '../trips',
                state: { items: this.state.items }
            });

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            //console.log(error)
        }
    }

    handleDeconnexionClick(event) {
        //
        event.preventDefault();
        localStorage.clear();
        sessionStorage.clear();
        ////////remove all cookies//////////
        Cookies.remove('idToken', { path: '/' });
        Cookies.remove('accessToken', { path: '/' });
        Cookies.remove('refreshToken', { path: '/' });
        Cookies.remove('userName', { path: '/' });
        Cookies.remove('user_name', { path: '/' });
        Cookies.remove('userSub', { path: '/' });
        Cookies.remove('userConfirmed', { path: '/' });
        Cookies.remove('name', { path: '/' });
        Cookies.remove('family_name', { path: '/' });
        Cookies.remove('email', { path: '/' });
        Cookies.remove('gender', { path: '/' });
        Cookies.remove('given_name', { path: '/' });
        Cookies.remove('street_address', { path: '/' });
        Cookies.remove('locality', { path: '/' });
        Cookies.remove('region', { path: '/' });
        Cookies.remove('postal_code', { path: '/' });
        Cookies.remove('country', { path: '/' });
        Cookies.remove('phone_number', { path: '/' });
        Cookies.remove('city_of_residence', { path: '/' });
        Cookies.remove('description', { path: '/' });
        Cookies.remove('birthdate', { path: '/' });
        Cookies.remove('additional_infos', { path: '/' });
        Cookies.remove('contrib_cwf_balance', { path: '/' });
        Cookies.remove('photo', { path: '/' });
        Cookies.remove('account_type', { path: '/' });
        Cookies.remove('counter', { path: '/' }); 
        Cookies.remove('id_card_check_status', { path: '/' }); 
        ////////////////////////////////////

        ///////////// remove dashboard cookies/////
        Cookies.remove('idToken', { path: '/dashboard' });
        Cookies.remove('accessToken', { path: '/dashboard' });
        Cookies.remove('accessToken', { path: '/dashboard' });
        Cookies.remove('userName', { path: '/dashboard' });
        Cookies.remove('user_name', { path: '/dashboard' });
        Cookies.remove('userSub', { path: '/dashboard' });
        Cookies.remove('userConfirmed', { path: '/dashboard' });
        Cookies.remove('name', { path: '/dashboard' });
        Cookies.remove('family_name', { path: '/dashboard' });
        Cookies.remove('email', { path: '/dashboard' });
        Cookies.remove('gender', { path: '/dashboard' });
        Cookies.remove('given_name', { path: '/dashboard' });
        Cookies.remove('street_address', { path: '/dashboard' });
        Cookies.remove('locality', { path: '/dashboard' });
        Cookies.remove('region', { path: '/dashboard' });
        Cookies.remove('postal_code', { path: '/dashboard' });
        Cookies.remove('country', { path: '/dashboard' });
        Cookies.remove('phone_number', { path: '/dashboard' });
        Cookies.remove('city_of_residence', { path: '/dashboard' });
        Cookies.remove('description', { path: '/dashboard' });
        Cookies.remove('birthdate', { path: '/dashboard' });
        Cookies.remove('additional_infos', { path: '/dashboard' });
        Cookies.remove('contrib_cwf_balance', { path: '/dashboard' });
        Cookies.remove('photo', { path: '/dashboard' });
        Cookies.remove('account_type', { path: '/dashboard' });
        Cookies.remove('counter', { path: '/dashboard' }); 
        Cookies.remove('id_card_check_status', { path: '/dashboard' }); 
        //////////////////////////////////////////
        this.props.history.push({
            pathname: '/logout'
        });

    }

    handleNewTrip(event) {
        this.props.history.push({
            pathname: '/dashboard/mytrips',
            renderNewTripEvent: true
        });
    }

    render() {

        //this.idToken = getCookieConsentValue('idToken')
        if (getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined') /*if (sessionStorage.getItem('idToken') === null)*/ {
            return (
                <div class="mynavbar mynavbar2">
                    <div className="container-fluid">
                        <nav className="navbar navbar-light navbar-expand-xl">
                            <div className="header-content row">
                                <a className="navbar-brand" href="/">
                                    <img alt="MonKolis" className="cover" src={logo} />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                    <ul className="navbar-nav ml-auto">
                                        {/* {<NavItem type="" className="btn btn-yellow btn-block" href="/crowfunding" text="Crowdfunding" />} */}
                                        {<NavItem type="" className="btn btn-yellow btn-block" href="/tutos" text="Comment ca marche ?" />}
                                        <NavItem type="" className="btn btn-primary btn-block" href="/connexion" text="Proposer un trajet" />
                                        <NavItem type="" className="btn btn-outline-primary btn-block" href="/register" text="Inscription" />
                                        <NavItem type="" className="btn btn-outline-primary btn-block" href="/connexion" text="Connexion" />
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            );
        } else {

            var account_type = getCookieConsentValue('account_type');
            var company_name = getCookieConsentValue('company_name');
            return (
                <div className="mynavbar mynavbar2">
                    <div className="container-fluid">
                        <nav className="navbar navbar-light navbar-expand-xl">
                            <div className="header-content row">
                                <a className="navbar-brand" href="/">
                                    <img alt="MonKolis" className="cover" src={logo} />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                    <ul className="navbar-nav ml-auto">
                                        {/* <li className="nav-item">
                                            <a type="button" className="btn btn-yellow" href="/crowfunding">Crowdfunding</a>
                                        </li> */}
                                        <li className="nav-item">
                                            <a type="button" className="btn btn-yellow" href="/tutos">Comment ca marche ?</a>
                                        </li>
                                        <li className="nav-item">
                                            <a type="" className="btn btn-primary btn-block txt-white" onClick={this.handlePublishNewTravelClick}>Proposer un trajet</a>

                                        </li>
                                        <li className="nav-item">
                                            <a className="btn btn-primary btn-block txt-white" onClick={this.handleSearch}>Trouver un transporteur</a>
                                        </li>
                                        <li className="nav-item dropdown user-menu">
                                            <Dropdown as={ButtonGroup}>
                                                <Button variant="light">
                                                    <a>
                                                        {this.photo === 'null' || this.photo === undefined || this.photo === null || this.photo === "undefined" ?
                                                            <img src={PHOTO} alt="MDN Logo" className="cover rounded" /> :
                                                            <img src={this.photo} alt="MDN Logo" className="cover rounded" />
                                                        }
                                                        {account_type === "Particulier" || account_type === "" || account_type === "null" || account_type === "undefined" || account_type === null || account_type === undefined ? this.user_name : company_name !== "" || company_name !== "null" || company_name !== "undefined" || company_name !== null || company_name !== undefined ? company_name : this.user_name}
                                                    </a>
                                                </Button>

                                                <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="/dashboard/mydashboard">Tableau de bord</Dropdown.Item>
                                                    <Dropdown.Item href="/dashboard/mytrips">Mes trajets</Dropdown.Item>
                                                    <Dropdown.Item href="/dashboard/myexpeditions">Mes expéditions</Dropdown.Item>
                                                    <Dropdown.Item href="/dashboard/mymessage">Messages</Dropdown.Item>
                                                    <Dropdown.Item href="/dashboard/myalerts">Alertes</Dropdown.Item>
                                                    <Dropdown.Item href="/dashboard/myprofile">Profil</Dropdown.Item>
                                                    <Dropdown.Item href="/dashboard/money">Paiements</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.handleDeconnexionClick}>Déconnexion</Dropdown.Item>
                                                </Dropdown.Menu>

                                                <div className="menu-mobile-dashboard">
                                                    <div className="menu-mobile-dashboard-content">
                                                        <a href="/dashboard/mydashboard" className="items">Tableau de bord</a>
                                                        <a href="/dashboard/mytrips" className="items">Mes trajets</a>
                                                        <a href="/dashboard/myexpeditions" className="items">Mes expéditions</a>
                                                        <a href="/dashboard/mymessage" className="items">Messages</a>
                                                        <a href="/dashboard/myalerts" className="items">Alertes</a>
                                                        <a href="/dashboard/myprofile" className="items">Profil</a>
                                                        <a href="/dashboard/money" className="items">Paiements</a>
                                                        <a href="" onClick={this.handleDeconnexionClick} className="items">Déconnexion</a>
                                                    </div>
                                                </div>
                                            </Dropdown>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                    </div>
                </div>
            );
        }
    }
}

export default withRouter(Header);