import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { MODIFY_PICTURE_QUERY } from './../../../query/queryprofil'
import { MODIFY_PICTURE_URL, SECURE_COOKIES } from '../../../Url';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import DEFAULT_PHOTO from '../../../assets/img/user/user0.png';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyPhoto extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isLoaded: false,
			modifypicture: [],
			picture: getCookieConsentValue('photo'),
			//picture: sessionStorage.getItem('photo'),
			file: new FileReader(),
		};

		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentWillMount() {
		
		this.idToken = getCookieConsentValue('idToken');
		this.accessToken = getCookieConsentValue('accessToken');
		// this.idToken = sessionStorage.getItem('idToken');
		// this.accessToken = sessionStorage.getItem('accessToken');
	}

	handleFileSelect = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		console.log(file)
		reader.onloadend = () => {
			this.setState({
				file: file,
				picture: reader.result
			});
			
		}
		if(file === null || file === undefined || file === "null" || file === "undefined" || file === ""){
			alert("Vous n'avez pas selectionnez de photo")
		}else{
			reader.readAsDataURL(file)
		}
		
	}

	handleSubmit(event) {
		event.preventDefault();
		if (this.state.file.size === null || this.state.file.size === undefined || this.state.file.size === "null" || this.state.file.size === "undefined" || this.state.file.size === "") {
			alert("Veuillez importer une photo svp")
		} 
		else if (this.state.file.size !== 0){
			var datas = this.state.picture.split(';')
			var encodingfile = datas[1].split(',')
			var extension = datas[0].split('/')
			if (encodingfile[1].length > 3145728) {
				alert('Veuillez sélectionner une photo de moins de 3 Mb svp')
			} else {
				var VAR = JSON.stringify({
					"file": {
						"encoding": encodingfile[1],
						"extension": extension[1],
					}
				});
				if (extension[1] === "jpg" || extension[1] === "jpeg" || extension[1] === "png") {
					window.confirm('Voulez-vous vraiment modifier votre photo/logo ?') ? this.modifyPictureRequest(MODIFY_PICTURE_QUERY, VAR) : window.close()
				} else {
					alert('Veuillez selectionner une photo au format PNG ou JPG')
				}
			}
		}else{
			alert("Echec de l'import de votre photo/logo")
		}

	}

	modifyPictureRequest = async (query, variables) => {
		try {
			const response = await axios.post(MODIFY_PICTURE_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken,
						'AccessToken': this.accessToken
					}
				}
			);

			this.setState(() => ({
				isLoaded: true,
				modifypicture: response.data.data
			}));

			if (response.data.data.updatePictureUser.status === 200) {
				
				Cookies.set('photo', response.data.data.updatePictureUser.file_path_details.Location, { path: '/', secure: SECURE_COOKIES, expires: 1 })

				alert('Votre photo/logo a bien été modifiée.')
				this.setState(() => ({
					isLoaded: true,
					picture: getCookieConsentValue('photo')
				}));
				window.location.reload(false);
			} else {
				alert('Echec de la modification de votre photo/logo.')
			}

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
			alert("Une erreur inconnue s'est produite, veuillez réessayer")
		}
	}

	render() {
		return (
			<div id="pagetitle">
				<Helmet>
					<title>Photo - informations personnelles | Monkolis</title>
					<meta name="robots" content="noindex,follow" />
				</Helmet>

				<div className="myprofile">
					<div className="section photo">
						<div className="section-inner">
							<h3 className="heading">Photo / Logo</h3>
							<div className="content">
								<div className="row">
									<div className="col-sm-4">
										<a>
											{this.state.picture === 'null' || this.state.picture === undefined || this.state.picture === null || this.state.picture === "undefined" ?
												<img src={DEFAULT_PHOTO} alt="Insérer une photo" className="cover rounded" /> :
												<img src={this.state.picture} alt="Insérer une photo" className="cover rounded" />
											}
										</a>
									</div>
									<div className="col-sm-6">
										<p>
											Des photos nettes et de face pour les particuliers et des logos de bonne résolution pour les professionnels permettent aux expéditeurs et aux transporteurs de se reconnaître. Cela rassurera les autres membres.
										</p>
										<p>Si vous êtes un particulier, assurez-vous d'utiliser une photo qui montre clairement votre visage et qui ne contient pas d'informations personnelles ou sensibles, car les expéditeurs ou transporteurs la verront.</p>
										<form onSubmit={this.handleSubmit}>
											<div className="form-group col-md-6 row">
												<input className="fileInput" type="file" onChange={(e) => this.handleFileSelect(e)} accept=".gif,.jpg,.jpeg,.png,.svg,.bmp,.tiff" />
												<small id="travelTicket" className="form-text text-muted">jpeg, png de 3 Mo maximum</small>
											</div>
											<input type="submit" className="btn btn-lg btn-theme2 btn-block" value="Définir comme photo de profil" />
										</form>

									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(MyPhoto);