import React, { Component } from 'react';
import SearchForm from './../searchform/searchform';

class SearchBar extends Component {
    render() {
        return (  
            <section className="mvp pad-bot50 pad-top20 " >
                <div className="container-fluid container-fluid-pad ">
                    <div className="zone-recherche">
                        <h1 className="txt-bold">Envoi de colis à l'international entre particuliers et pro.</h1>
                        <h4 className="txt-light">Moins cher, plus rapide, plus pratique</h4>

                        <div className="serchform">
                        <SearchForm/>
                        </div>
                    </div>
                </div>
            </section>
          );
    }
  }
  
  export default SearchBar;