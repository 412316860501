import React, { Component } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

import { withRouter, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";

class Travelers extends Component {
  render() {
    return (
      <div className="pagetitle">
          <Helmet>
              <title>Transporteurs : Foires aux questions pour en savoir plus sur Monkolis | Monkolis</title>

          </Helmet>
      <div className="faq-travelers">
        <div class="section">
          <div class="section-inner">
              <h3 class="heading" id="top">Vous êtes un transporteur</h3>
              <p>
              Cette section du faq est en cours de rédaction. N'hésitez pas à <a href="https://www.facebook.com/MonKolis/" class="link" target = "_blank">contacter notre équipe</a> en cas de besoin.
              </p>

          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default withRouter(Travelers);