import axios from 'axios';
import React, { Component, Fragment } from 'react';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { withRouter } from 'react-router-dom'
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/login.css';
import './../../assets/css/modal.css';
import { REGISTER_QUERY } from '../../query/queryauthent';
import { REGISTER_URL } from '../../Url';
import Amplify, { Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet';
import Modal from 'react-bootstrap/Modal';
//import awsconfig from '../../aws-exports';
Amplify.configure({
	Auth: {  
		region: 'eu-west-1',  
		identityPoolRegion: 'eu-west-1',  
		identityPoodId: 'eu-west-1:3cb5ff3c-a4ed-4856-aba6-9328d0c6afe7',  
		userPoolId: 'eu-west-1_86RptyH5c',  
		userPoolWebClientId: '49qp7c8hrmqv1ptpfrnmg71g8k',  
		oauth: { 	
			domain: 'monkolis-prd.auth.eu-west-1.amazoncognito.com', 	
			redirectSignIn: 'https://www.monkolis.com/',  	
			redirectSignOut: 'https://www.monkolis.com/logout',  
			responseType: 'token'  
		}  
	}
});


class Register extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			mdp: '',
			name: '',
			given_name: '',
			confirmPassword: '',
			error: null,
			validated: false,
			msgAlertBody : '',
			msgAlertHeader : '',
			alertVariant :'danger',
			showAlert : false,
			isLoaded: false,
			sexechoosen: 'M',

			isPasswordShown : false,
			items: [],

			show: false,
		};

		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleMDPChange = this.handleMDPChange.bind(this);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleGiven_nameChange = this.handleGiven_nameChange.bind(this);
		this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handle_sexe_change = this.handle_sexe_change.bind(this);
		this.handleGUCClick = this.handleGUCClick.bind(this);
		this.handleGCPClick = this.handleGCPClick.bind(this);
		this.handleCloseAlert = this.handleCloseAlert.bind(this); 
		this.handleCocheCGU = this.handleCocheCGU.bind(this);
	}


	togglePasswordVisibily = () => {
		const {isPasswordShown} = this.state;
		this.setState({ isPasswordShown : !isPasswordShown})

	}

	handleClose() {
		this.setState({ show: false });
		this.props.history.push({
			pathname: '/connexion',
		});
	}

	/* componentDidMount() {
		Hub.listen("auth",({ payload: { event, data } }) =>  {
			switch (event) {
				case "signIn":
					this.setState({ user: data});
					console.log(this.state.user)
					console.log(this.state.user.attributes)
					//Auth.currentSession().then(user => console.log(user.idToken.jwtToken))
					Auth.currentSession().then(user => {
						sessionStorage.setItem('idToken', user.idToken.jwtToken);
						sessionStorage.setItem('accessToken', user.accessToken.jwtToken);
						sessionStorage.setItem('refreshToken', user.refreshToken.jwtToken);
						sessionStorage.setItem('email', user.idToken.payload.email);
						sessionStorage.setItem('userConfirmed', user.idToken.payload.email_verified)
						sessionStorage.setItem('userSub', user.idToken.payload.sub)
						sessionStorage.setItem('provider', user.idToken.payload.identities[0].providerName)
					})
					sessionStorage.setItem('userName', this.state.user.username);
					sessionStorage.setItem('street_address', "")
					sessionStorage.setItem('locality', "")
					sessionStorage.setItem('region', "")
					sessionStorage.setItem('postal_code', "")
					sessionStorage.setItem('country', "")
					
					// 
					// sessionStorage.setItem('name', this.state.user.CognitoUser.user.name)
					// sessionStorage.setItem('family_name', this.state.user.CognitoUser.user.family_name)
					// sessionStorage.setItem('given_name', this.state.user.CognitoUser.user.given_name)
					// sessionStorage.setItem('phone_number', this.state.user.CognitoUser.user.phone_number)
					// sessionStorage.setItem('photo', this.state.user.CognitoUser.user.picture)
					break;
				case "signOut":
					this.setState({ user: null });
					break;
				case "customOAuthState":
					this.setState({ customState: data });
			}
		});

		Auth.currentAuthenticatedUser()
			.then(user => this.setState({ user }))
			.catch(() => console.log("Not signed in"));

	}
 */
	handleCloseAlert(event) {
		this.setState({ showAlert: false});
	}



	handleGUCClick(event) {
		event.preventDefault();
		window.open("/terms", '_blank')
	}

	handleCocheCGU(event) {

	}

	handleGCPClick(event) {
		event.preventDefault();
		window.open("/privacy", '_blank')
	}

	handle_sexe_change(event) {
		this.setState({ sexechoosen: event.target.value })
	}

	handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		else {
			this.setState(() => ({
				validated: true
			}));
			if (this.state.mdp !== this.state.confirmPassword) {
				this.setState(() => ({
					showAlert : true,
					msgAlertBody : 'Passwords dont match',
					msgAlertHeader : 'Error',
				}));
			}
			else {
				var VAR = JSON.stringify({
					"userinfos": {
						"email": this.state.email,
						"password": this.state.mdp,
						"name": this.state.name,
						"given_name": this.state.given_name,
						"gender": this.state.sexechoosen
					}
				})
				this.registerUser(REGISTER_QUERY, VAR)
			}

		}

	}

	handleEmailChange(event) {
		this.setState({ email: event.target.value });
	}

	handleMDPChange(event) {
		this.setState({ mdp: event.target.value });
	}

	handleNameChange(event) {
		this.setState({ name: event.target.value });
	}

	handleGiven_nameChange(event) {
		this.setState({ given_name: event.target.value });
	}

	handleConfirmPasswordChange(event) {
		this.setState({ confirmPassword: event.target.value });
	}


	registerUser = async (query, variables) => {
		try {
			const response = await axios.post(REGISTER_URL, {
				query,
				variables
			});
			this.setState(() => ({
				isLoaded: true,
				items: response.data.data
			}));

			if (this.state.items.registrateUser.status === 400) {
				if (this.state.items.registrateUser.message === "Bad Request") {
					this.setState(() => ({
						showAlert : true,
						msgAlertBody : 'Veillez svp entrer un mot de passe respectant la politique de mot de passe (8 caractères minimum)',
						msgAlertHeader : 'Erreur sur le format du mot de passe',
						
					}));
					
				}
				if (this.state.items.registrateUser.message === "UsernameExistsException|UsernameExistsException|User already exists") {
		
					this.setState(() => ({
						showAlert : true,
						msgAlertBody : 'Il semble que vous possédiez déjà un compte. Veuillez vous connecter',
						msgAlertHeader : 'Error',
						
					}));
				}

			}
			else {
				this.props.history.push('../confirmusercode');
			}


		} catch (error) {
			this.setState(() => ({
				showAlert : true,
				msgAlertBody : 'Veillez svp entrer un mot de passe respectant la politique de mot de passe',
				msgAlertHeader : 'Password Type  error',
				
			}));
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}


	render() {
		const {isPasswordShown} = this.state;
		return (
			<Fragment>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Inscription gratuite | Monkolis</title>
					<meta name="robots" content="index,follow" />
				</Helmet>

				<div className="register">
					<div className="main-box_content">
						<div className="container-fluid container-fluid-pad container-fluid-br">
							<div className="row pad-top20 pad-bot20">

								<div className="col-lg-6 col-md-8 col-sm-8 col-12 d-center">
									<div className="section">
										<div className="section-inner">
											<h3 className="heading">Inscription</h3>
											{this.state.showAlert ?
												<Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert} dismissible>
													<Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
													<p>{this.state.msgAlertBody} </p>
												</Alert> : ''}
											<button className="btn btn-block btn-social btn-facebook" onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}><i className="fa fa-facebook"></i>  Inscription avec Facebook</button>
											<button className="btn btn-block btn-social btn-google" onClick={() => Auth.federatedSignIn({ provider: 'Google' })}><i className="fa fa-google"></i>  Inscription avec Google</button>
											<p className="mar-top20 txt-bold">OU ALORS</p>


											<Form className="well" validated={this.state.validated} onSubmit={this.handleSubmit}>

												<Form.Group controlId="validationCustom01">
													<Form.Control
														required
														type="email"
														placeholder="Email"
														defaultValue={this.state.email}
														onChange={this.handleEmailChange}
													/>
													<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
												</Form.Group>

												<Form.Group controlId="validationCustom01">
													<Form.Control
														required
														type="text"
														placeholder="Prénom"
														defaultValue={this.state.given_name}
														onChange={this.handleGiven_nameChange}
													/>
													<span className="glyphicon glyphicon-user form-control-feedback"></span>
												</Form.Group>

												<Form.Group controlId="validationCustom01">
													<Form.Control
														required
														type="text"
														placeholder="Nom"
														defaultValue={this.state.name}
														onChange={this.handleNameChange}
													/>
													<span className="glyphicon glyphicon-user form-control-feedback"></span>
												</Form.Group>

												<Form.Group controlId="validationCustom01">
													<Form.Label className="text-muted"><em className="align-center">Le mot de passe doit être de 8 caractères minimum</em></Form.Label>
													<Form.Control
														required
														type={(isPasswordShown) ? "text" : "password"}
														placeholder="Mot de passe"
														defaultValue={this.state.mdp}
														onChange={this.handleMDPChange}
													/>
													<span className="viewpass"><i className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"}`}
														onClick={this.togglePasswordVisibily}></i></span>
													<span className="glyphicon glyphicon-lock form-control-feedback"></span>
												</Form.Group>

												<Form.Group controlId="validationCustom01">
													<Form.Control
														required
														type={(isPasswordShown) ? "text" : "password"}
														placeholder="Confirmation Mot de passe"
														defaultValue={this.state.confirmPassword}
														onChange={this.handleConfirmPasswordChange}
													/>

													<span className="glyphicon glyphicon-lock form-control-feedback"></span>
												</Form.Group>

												<div className="form-group">
													<div className="form-row">
														<div className="form-group col-md-3">
															<input id="radio11" name="radiosex" type="radio" className="magic-radio" value="M" checked={this.state.sexechoosen === 'M'} onChange={this.handle_sexe_change} />
															<label htmlFor="radio11">Homme</label>
														</div>
														<div className="form-group col-md-3">
															<input id="radio12" name="radiosex" type="radio" className="magic-radio" value="F" checked={this.state.sexechoosen === 'F'} onChange={this.handle_sexe_change} />
															<label htmlFor="radio12">Femme</label>
														</div>
														<div className="form-group col-md-6">
															<input id="radio13" name="radiosex" type="radio" className="magic-radio" value="N" checked={this.state.sexechoosen === 'N'} onChange={this.handle_sexe_change} />
															<label htmlFor="radio13">Je préfère ne pas le dire</label>
														</div>
													</div>
												</div>

												<div className="sm-3">
													<Form.Check>
														<Form.Check.Input
															type="checkbox"
															id="session-active2"
															onChange={this.handleCocheCGU}
															required
														/>
														<Form.Check.Label>
															J'accepte les <a type="button" onClick={this.handleGUCClick} className="link color-blue">conditions générales d'utilisation</a> ainsi que la <a type="button" onClick={this.handleGCPClick} className="link color-blue">politique de confidentialité</a>
														</Form.Check.Label>
														{/* <label htmlFor="session-active2">J'accepte les <a type="button" onClick={this.handleGUCClick} className="link color-blue">conditions générales d'utilisation</a> ainsi que la <a type="button" onClick={this.handleGCPClick} className="link color-blue">politique de confidentialité</a></label>									 */}
													</Form.Check>
												</div><br />
												<input type="submit" className="btn btn-primary btn-block" value="S'enregistrer" />

											</Form>

											<p className="mar-top20">J'ai déjà un compte, <a href="/connexion" className="link color-blue">je me connecte </a></p>



										</div>
									</div>

								</div>
								<Modal show={this.state.show} onHide={this.handleClose} centered>
									<Modal.Header closeButton>
										<Modal.Title> Inscription validée</Modal.Title>
									</Modal.Header>

									<Modal.Body>
										<p>Bravo, votre inscription a bien été validée. Vous pouvez à présent vous connecter et proposer votre trajet ou réserver chez un transporteur. Buton : Me connecter.</p>
									</Modal.Body>

									<Modal.Footer>
										<button className="btn btn-outline-primary" onClick={this.handleClose}>
											Me connecter.
                                            </button>
									</Modal.Footer>
								</Modal>

							</div>
						</div>

					</div>
				</div>
			</Fragment>

		);
	}
}
export default withRouter(Register);