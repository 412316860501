import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
// import './../../../assets/css/main.css';
import './../../../assets/css/multiple.css';
import './../../../assets/css/multiple2.css';
import './../../../assets/css/dashboard.css';
import './../../../assets/css/modal.css';
import MyTripsInProgress from './mytripsinprogress';
import TripHistoric from './historic';
import NewTrip from './newtrip';
import UpdateTrip from './updatetrip';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import { TRAVEL_PROPOSAL_QUERY } from '../../../query/travelquery';
import { TRAVEL_URL } from '../../../Url';
import Nav from 'react-bootstrap/Nav';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyTrips extends Component {
	constructor(props) {
		super(props);
		this.pages = this.props
		if(this.pages.location.state !== undefined && this.pages.location.state.pages ==='new_travel'){
			this.state = {
				isLoaded: false,
				travels: [],
				error: [],
				renderInProgress: false,
				renderNewTrip: true,
				renderHistoric: false,
			}
		}else if (this.pages.location.state !== undefined && this.pages.location.state.pages ==='in_progress'){
			this.state = {
				isLoaded: false,
				travels: [],
				error: [],
				renderInProgress: true,
				renderNewTrip: false,
				renderHistoric: false,
			}
		} else {
			this.state = {
				isLoaded: false,
				travels: [],
				error: [],
				renderInProgress: true,
				renderNewTrip: false,
				renderHistoric: false,
			}
		}
		this.handleInprogressClick = this.handleInprogressClick.bind(this);
		this.handleHistoricClick = this.handleHistoricClick.bind(this);
		this.handleNewTripClick = this.handleNewTripClick.bind(this);
		this.handleUpdateTripClick = this.handleUpdateTripClick.bind(this);
	}

	componentWillMount() {
		
        this.idToken = getCookieConsentValue('idToken');
		//this.idToken = sessionStorage.getItem('idToken')
		this.getProposalTravel(TRAVEL_URL, this.idToken)
	}

	getProposalTravel = async (url, idToken) => {
		try {
			const response = await axios.post(url,
				{ "query": TRAVEL_PROPOSAL_QUERY },
				{
					'headers':
					{
						'Authorization': idToken
					}
				}
			);
			// Set the data to the state


			this.setState({
				isLoaded: true,
				travels: response.data.data
			});

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState({ error: error })
			console.log(this.state.error)
		}
	}

	handleInprogressClick(event) {
		this.setState({
			renderInProgress: true,
			renderHistoric: false,
			renderNewTrip: false,
		})
	}

	handleNewTripClick(event) {
		const {location,history} = this.props;
		history.replace()
		this.setState({
			renderInProgress: false,
			renderHistoric: false,
			renderNewTrip: true,
		})
	}

	handleHistoricClick(event) {
		this.setState({
			renderInProgress: false,
			renderHistoric: true,
			renderNewTrip: false,
		})
	}	
	
	handleUpdateTripClick(event) {
		this.setState({
			renderInProgress: false,
			renderHistoric: false,
			renderNewTrip: false,
		})
	}

	render() {
		
        this.idToken = getCookieConsentValue('idToken');
		return (
			<Fragment>
				<Helmet>
					<title>Mes trajets | Monkolis</title>
					<meta name="robots" content="noindex,follow" />
				</Helmet>

				<div className="mytrips">
					<div className="main-box_content">
						<div className="container-fluid container-fluid-pad container-fluid-br">
							{ /*sessionStorage.getItem('idToken') */ getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? <Redirect to="/connexion" /> :
								<div className="pad-top20 mar-bot20 d-sm-block" id="secondNav">
									<Nav variant="tabs" defaultActiveKey="/dashboard/mytrips">
										<Nav.Item>
											<Nav.Link eventKey="/dashboard/mydashboard" href="/dashboard/mydashboard">Tableau de bord</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="/dashboard/mytrips" href="/dashboard/mytrips">Mes trajets</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="/dashboard/myexpeditions" href="/dashboard/myexpeditions">Mes expéditions</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="/dashboard/mymessage" href="/dashboard/mymessage">Messages</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="/dashboard/myalerts" href="/dashboard/myalerts">Alertes</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="/dashboard/myprofile" href="/dashboard/myprofile">Profil</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link href="/dashboard/money" href="/dashboard/money">Paiements</Nav.Link>
										</Nav.Item>
									</Nav>
									<br />
									<div className="row mytravels">
										<div className="col-sm-3 mar-bot20">
											<div className="list-group">
												{
													this.state.renderInProgress ?
														<a className="list-group-item list-group-item-action txt-white active" onClick={this.handleInprogressClick}>Trajets en cours</a> :
														<a className="list-group-item list-group-item-action" onClick={this.handleInprogressClick}>Trajets en cours</a>
												}
												{
													this.state.renderHistoric ?
														<a className="list-group-item list-group-item-action txt-white active" onClick={this.handleHistoricClick}>Trajets Historiques</a> :
														<a className="list-group-item list-group-item-action" onClick={this.handleHistoricClick}> Trajets Historiques</a>
												}

												<hr />
												{
													this.state.renderNewTrip ?
														<a className="list-group-item list-group-item-action txt-white active" onClick={this.handleNewTripClick}>Nouveau Trajet</a> :
														<a className="list-group-item list-group-item-action" onClick={this.handleNewTripClick}>Nouveau Trajet</a>
												}

											</div>
										</div>
										<div className="col-sm-9">
											{this.state.renderInProgress ? <MyTripsInProgress /> : null}
											{this.state.renderHistoric ? <TripHistoric /> : null}
											{this.state.renderNewTrip ? <NewTrip /> : null}
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
export default withRouter(MyTrips);