import './../../assets/css/googleapi.css';

import axios from 'axios';
import React, { Component } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';
import { withRouter } from 'react-router-dom';
import './../../assets/css/login.css';
import { SEARCHTRAVEL_QUERY,/* NOT_CONNECTED_SEARCHTRAVEL_QUERY*/ } from '../../query/querytrajet';
import { SEARCHTRAVEL_URL } from '../../Url';

import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class SearchForm extends Component {
  constructor(props) {
    super(props);

    this.departure_city = this.props.location.state !== undefined ? this.props.location.state.departure_city : ''
    this.arrival_city = this.props.location.state !== undefined ? this.props.location.state.arrival_city : ''
    this.departure_date = this.props.location.state !== undefined ? this.props.location.state.departure_date : ''

    this.state = {
      departure_date_period_start: this.departure_date,
      departure_date_period_end: '',
      departure_city: this.departure_city,
      arrival_city: this.arrival_city,
      proposal_amount_by_kg: '',
      has_check_ticket: '',
      is_automatic_approval: '',
      error: null,
      isLoaded: false,
      items: [],
      lat_arrival: "",
      long_arrival: "",
      lat_departure: "",
      long_departure: "",
      address: ''
    };

    this.handledepartureStart = this.handledepartureStart.bind(this);
    this.handledepartureEnd = this.handledepartureEnd.bind(this);
    this.handledepartureCity = this.handledepartureCity.bind(this);
    this.handlearrivalCity = this.handlearrivalCity.bind(this);
    this.handleproposalAmount = this.handleproposalAmount.bind(this);
    this.handlehasCheckTicket = this.handlehasCheckTicket.bind(this);
    this.handleisautomaticApproval = this.handleisautomaticApproval.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.state.arrival_city !== '') {
      await this.getLatLngArrival(this.state.arrival_city);
    }
    if (this.state.departure_city !== '') {
      await this.getLatLngDeparture(this.state.departure_city);
    }
    if (getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined') {
      event.preventDefault();
      var VAR = JSON.stringify({
        "search_travel_proposal": {
          "departure_date_period_start": this.state.departure_date_period_start,
          "departure_date_period_end": this.state.departure_date_period_end,
          "departure_city": this.state.departure_city,
          "arrival_city": this.state.arrival_city,
          "proposal_amount_by_kg": this.state.proposal_amount_by_kg,
          "has_check_ticket": this.state.has_check_ticket,
          "is_automatic_approval": this.state.is_automatic_approval,
          "lat_arrival_city": this.state.lat_arrival.toString(),
          "lng_arrival_city": this.state.long_arrival.toString(),
          "lat_departure_city": this.state.lat_departure.toString(),
          "lng_departure_city": this.state.long_departure.toString()
        }
      })
      getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? await this.searchTravel(SEARCHTRAVEL_QUERY, VAR) : await this.searchTravel(SEARCHTRAVEL_QUERY, VAR)
    }
    else {
      event.preventDefault();
      if (getCookieConsentValue('phone_number') === null || getCookieConsentValue('phone_number') === undefined || getCookieConsentValue('phone_number') === 'null' || getCookieConsentValue('phone_number') === 'undefined' || getCookieConsentValue('phone_number') === '') {
        alert("Vous n'avez pas renseigné de numéro de téléphone, merci de le faire dans la page Profil.")
        this.props.history.push({
          pathname: '../dashboard/myprofile',
        })

      }
      else {
        var VAR = JSON.stringify({
          "search_travel_proposal": {
            "departure_date_period_start": this.state.departure_date_period_start,
            "departure_date_period_end": this.state.departure_date_period_end,
            "departure_city": this.state.departure_city,
            "arrival_city": this.state.arrival_city,
            "proposal_amount_by_kg": this.state.proposal_amount_by_kg,
            "has_check_ticket": this.state.has_check_ticket,
            "is_automatic_approval": this.state.is_automatic_approval,
            "lat_arrival_city": this.state.lat_arrival.toString(),
            "lng_arrival_city": this.state.long_arrival.toString(),
            "lat_departure_city": this.state.lat_departure.toString(),
            "lng_departure_city": this.state.long_departure.toString()
          }
        })
        getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? this.searchTravel(SEARCHTRAVEL_QUERY, VAR) : this.searchTravel(SEARCHTRAVEL_QUERY, VAR)

      }

    }




  }

  handledepartureStart(event) {
    this.setState({ departure_date_period_start: event.target.value });
  }


  handledepartureEnd(event) {
    this.setState({ departure_date_period_end: event.target.value });
  }

  handledepartureCity(event) {
    this.setState({ departure_city: event.target.value });
  }

  handlearrivalCity(event) {
    this.setState({ arrival_city: event.target.value });
  }

  handleproposalAmount(event) {
    this.setState({ proposal_amount_by_kg: event.target.value });
  }

  handlehasCheckTicket(event) {
    this.setState({ has_check_ticket: event.target.value });
  }

  handleisautomaticApproval(event) {
    this.setState({ is_automatic_approval: event.target.value });
  }


  //add autocomplete
  handleChange = address => {
    this.setState({ address });
    //console.log(address)
  };

  handleChangeDepartCity = departure_city => {
    this.setState({ departure_city });
  };

  handleChangeArrivalCity = arrival_city => {
    this.setState({ arrival_city });
  };


  handleSelectDepartureCity = (departure_city, placeId, suggestion) => {
    //console.log(JSON.stringify(suggestion))
    this.setState({ departure_city: suggestion.description });

    // Do something with address and placeId and suggestion
  }

  getLatLngDeparture = async (city) => {
    await geocodeByAddress(city)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({
          lat_departure: latLng.lat,
          long_departure: latLng.lng
        })
      })
      .catch(error => console.error('Error', error));

  }

  handleSelectArrivalCity = (handlearrivalCity, placeId, suggestion) => {
    //console.log(JSON.stringify(suggestion))
    this.setState({ arrival_city: suggestion.description });

    // Do something with address and placeId and suggestion
  }

  getLatLngArrival = async (city) => {
    await geocodeByAddress(city)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({
          lat_arrival: latLng.lat,
          long_arrival: latLng.lng
        })
      })
      .catch(error => console.error('Error', error));
  }

  //End Add


  searchTravel = async (query, variables) => {
    try {
      const response = await axios.post(SEARCHTRAVEL_URL, {
        query,
        variables
      });
      this.setState(() => ({
        isLoaded: true,
        items: response.data.data
      }));
      this.props.history.push({
        pathname: '../trips',
        state: {
          items: this.state.items,
          departure_city: this.state.departure_city,
          arrival_city: this.state.arrival_city,
          departure_date: this.state.departure_date_period_start
        }
      });
      window.location.reload(false);

    } catch (error) {
      // If there's an error, set the error to the state
      console.log(error)
    }
  }

  render() {
    return (
      <form className="search-form" onSubmit={this.handleSubmit}>
        <div className="form-row">
          <div className="form-group col-sm-3">
            <label htmlFor="depart" className="sr-only">Ville Départ</label>
            <PlacesAutocomplete
              value={this.state.departure_city}
              onChange={this.handleChangeDepartCity}
              onSelect={this.handleSelectDepartureCity}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: '  Ville Départ ...',
                      className: 'location-search-input',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                        >
                          <i class="material-icons">location_on</i><span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {/* <input type="text" className="form-control" placeholder="Ville de Départ" value={this.state.departure_city} onChange={this.handledepartureCity} /> */}
          </div>
          <div className="form-group col-sm-3">
            <label htmlFor="arrivee" className="sr-only">Ville Arrivée</label>
            {/*               <input type="text" className="form-control" placeholder="Ville d'arrivée" value={this.state.arrival_city} onChange={this.handlearrivalCity} /> */}
            <PlacesAutocomplete
              value={this.state.arrival_city}
              onChange={this.handleChangeArrivalCity}
              onSelect={this.handleSelectArrivalCity}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: '  Ville Arrivée ...',
                      className: 'location-search-input',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {

                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="form-group col-sm-3">
            <label htmlFor="date" className="sr-only">Date</label>
            <input type="date" className="location-search-input" placeholder="Date" value={this.state.departure_date_period_start} onChange={this.handledepartureStart} />
          </div>
          <div className="form-group col-sm-3">
            <input type="submit" className="btn btn-primary btn-block" value="Trouver un transporteur" />
          </div>
        </div>
      </form>

    );
  }
}
export default withRouter(SearchForm);