import ReactPaginate from 'react-paginate';
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { TRAVEL_PROPOSAL_PAST_QUERY } from '../../../query/travelquery';
import { TRAVEL_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";
import Moment from "moment";
import ListReservation from './listreservation'
import moment from 'moment';
import 'moment/min/locales'
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';


class TripHistoric extends Component {
    constructor(props) {
        super(props);
        this.nbeElementOfPage =3
        this.offsetInitial = 0
        this.state = {
			isLoaded: false,
			travels: [],
            departure_date: "",
			departure_city: "",
			arrival_city: "",
			has_check_ticket: "",
			max_weight_available: "",
			min_weight_booking: "",
			proposal_amount_by_kg: "",
			booking_deadline: "",
			service_charge_amount: "",
			is_automatic_approval: false,
			transport_company: "",
			others_infos: "",
			take_off_place: "",
			recovery_place: "",
            status: "",
            pageCount: 0,
            offset: this.offsetInitial,
			perPage: this.nbeElementOfPage,
			currentPage: 0,
            data: [],
            renderListReservation: false,
            stateUpdate: [],
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
    }

    handleDuplicateClick(event, value){
        this.props.history.push({
            pathname: '/dashboard/mytrips',
            state: { 
                pages: 'new_travel',
                type: 'duplicate',
                duplicate_data: value
            }
        });
        window.location.reload(false);
    }

    handleReservationsClick(event, value){
        event.preventDefault();
        this.setState({
            renderListReservation: true, 
            stateUpdate: value
        })
       }

    componentWillMount(){
        
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
        this.getProposalTravel(TRAVEL_URL, this.idToken)
	}

    getProposalTravel = async(url,idToken) =>{
        try {
           const response = await axios.post(url, 
               {"query": TRAVEL_PROPOSAL_PAST_QUERY}, 
               {'headers': 
                   {
                       'Authorization': idToken
                   }
               }
           );
            this.setState(() => ({
					isLoaded: true,
                    travels: response.data.data.getUserTravelProposal.travel_proposals,
                    pageCount: Math.ceil(response.data.data.getUserTravelProposal.travel_proposals.length /this.nbeElementOfPage),
                    data: response.data.data.getUserTravelProposal.travel_proposals.length > this.nbeElementOfPage ? response.data.data.getUserTravelProposal.travel_proposals.slice(this.offsetInitial, this.nbeElementOfPage): response.data.data.getUserTravelProposal.travel_proposals,
           }));
         } catch (error) {
           // If there's an error, set the error to the state
           this.setState(() => ({ error }))
         }
   }

   loadHistoricData() {
    const elements = this.state.travels;
    

    this.setState({
        data: elements.slice(this.state.offset, this.state.offset + this.state.perPage),
        pageCount: Math.ceil(elements.length / this.state.perPage)
    });

}

   handlePageClick = data => {
        let selected = data.selected;
        let offset = selected * this.state.perPage;

        this.setState({currentPage: selected, offset: offset }, () => {
            this.loadHistoricData();
        });
    };

    render() {
        moment.locale('fr');
        if (this.state.travels.length === 0 ){
                return(<p>AUCUN HISTORIQUE DE TRAJET </p>)
            }else if (this.state.renderListReservation){
                return(
                    <ListReservation props={this.state.stateUpdate}/>
                ) 
            }else {
        return (
            <Fragment>
                <div className="travels">
                    <div className="section mar-bot20">
                        <div className="section-inner">
                            <h3 className="heading">Mes trajets historiques </h3>
                            {/* <p>
                        <span className="txt-bold">Important</span> : Vous trouverez vos paiements dans la section <a
                            href="" className="link color-blue">historiques des virements</a>.
                        </p> */}
                        </div>
                    </div>
                    {this.state.data.map((value, index) => {
                        return <div key={index} >
                            <div className="card mytravel mar-bot20">
                                <div className="card-header">
                                    <div className="card-header_content">
                                        <div className="card-header_title">
                                            <h3 className="card-title">
                                                <span>{value.departure_city}</span>
                                                <i className="fa fa-long-arrow-right color-text2"></i>
                                                <span>{value.arrival_city}</span>
                                            </h3>
                                            <h4 className="card-subtitle"> <span className="txt-bold head">Id du trajet: </span>{value.id_travel}</h4>
                                            <div className="card-header_status">
                                                <span className="txt-bold">Statut:</span><span className="txt">Réalisé</span>
                                            </div>
                                        </div>
                                        <div className="card-header_cta">
                                            <button type="button" href="" className="btn btn-primary btn-sm" onClick={(e) => { this.handleReservationsClick(e, value) }}> <span className="fa fa-1x fa-pencil"></span> Réservations </button>
                                            <button type="button" href="" className="btn btn-info btn-sm" onClick={(e) => { this.handleDuplicateClick(e, value) }}> <i className="fa fa-1x fa-copy"></i> Dupliquer </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mar-bot20">
                                        <div className="col-md-6 criteria">
                                            <p><span className="fa fa-1x fa-calendar" title="Date de voyage"></span>  {Moment(value.departure_date).format('DD MMM, YYYY')}</p>
                                            <p><span className="fa fa-1x fa-clock-o color-orange" title="Date limite de réservation"></span>  {Moment(value.booking_deadline).format('DD/MM/YYYY LT')}</p>
                                            {value.transportation_type === null ?
                                                <p><span className="fa fa-1x fa-plane"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                null
                                            }
                                            {value.transportation_type === "Avion" ?
                                                <p><span className="fa fa-1x fa-plane"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                null
                                            }
                                            {value.transportation_type === "Bâteau" ?
                                                <p><span className="fa fa-1x fa-ship"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                null
                                            }
                                            {value.transportation_type === "Voiture" ?
                                                <p><span className="fa fa-1x fa-car"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                null
                                            }
                                            {value.transportation_type === "Train" ?
                                                <p><span className="fa fa-1x fa-train"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                null
                                            }
                                            <p><span className="fa fa-1x fa-ticket color-yellow"></span>  {(value.has_check_ticket == 'Y') ? "Billet vérifié" : "Billet non vérifié"} </p>
                                        </div>
                                        <div className="col-md-6 comments">
                                            <p><span className="fa fa-1x fa-tags color-orange"></span>  {value.max_weight_available}kgs - {value.service_charge_amount}€ / kg - Min {value.min_weight_booking}kg</p>
                                            <p><span className="fa fa-1x fa-flash color-yellow"></span> {(value.is_automatic_approval == 'Y') ? 'Acceptation automatique' : 'Acceptation manuelle'}</p>
                                            <p><span className="fa fa-map-marker color-green"></span> {value.recovery_place}</p>
                                            <p><span className="fa fa-map-marker color-blue"></span> {value.take_off_place}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="trips-features">
                                        <div className="progress">
                                            <div className="progress-bar bg-color-green" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: parseFloat(value.weigth_available_info.weigth_available).toFixed(2) * 100 / parseFloat(value.max_weight_available).toFixed(2) + "%" }}>
                                                <span >{parseFloat(value.max_weight_available).toFixed(2) - parseFloat(value.weigth_available_info.weigth_available).toFixed(2)} kgs</span>
                                                <span className="sr-only">{(parseFloat(value.max_weight_available).toFixed(2) - parseFloat(value.weigth_available_info.weigth_available).toFixed(2)) * 100 / parseFloat(value.max_weight_available).toFixed(2)}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                    <ReactPaginate
                        previousLabel={'«'}
                        nextLabel={'»'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={10}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination float-right'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                        disabledClassName={'desabled'}
                    />
                </div>
         </Fragment>

        );
            }
    }
}
export default withRouter(TripHistoric);





