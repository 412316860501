import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MESSAGES_EXPEDITION_QUERY } from '../../../query/queryprofil';
import { MESSAGE_EXPEDITIONS_URL } from '../../../Url';
import { SEND_MESSAGE_TO_TRAVEL_TRAVELER_QUERY } from '../../../query/travelquery';
import { TRAVEL_ACTIONS_URL } from '../../../Url';
import DEFAULT_PHOTO from '../../../assets/img/user/user0.png';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Moment from "moment";
import moment from 'moment';
import 'moment/min/locales';
import Modal from 'react-bootstrap/Modal';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import Alert from 'react-bootstrap/Alert'

class MessagesExpeditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: [],
            showSendToModal: false,
            msg_content: '',
            proposal: '',
            msg_object: '',

            msgAlertBody: '',
            msgAlertHeader: '',
            alertVariant: 'danger',
            showAlert: false,

        };

        this.handleSendMessageModal = this.handleSendMessageModal.bind(this);
        this.handleCloseMessageModal = this.handleCloseMessageModal.bind(this);
        this.handleCommentaryChange = this.handleCommentaryChange.bind(this);
        this.handleShowWriteToModal = this.handleShowWriteToModal.bind(this);
        this.handleObjectChange = this.handleObjectChange.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }
    componentDidMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        this.userSub = getCookieConsentValue('userSub')
        this.name = getCookieConsentValue('name')
        this.family_name = getCookieConsentValue('family_name')
        this.given_name = getCookieConsentValue('given_name')
        this.photo = getCookieConsentValue('photo');

        this.getExpeditionsVoyages(MESSAGES_EXPEDITION_QUERY)
    }

    handleCloseAlert(event) {
		this.setState({ showAlert: false });
	}

    getExpeditionsVoyages = async (query) => {
        try {
            const response = await axios.post(MESSAGE_EXPEDITIONS_URL,
                { query },
                {
                    'headers':
                    {
                        'Authorization': this.idToken,
                        'Content-Type': 'application/json'
                    }
                }
            );
            //console.log(response.data)
            this.setState(() => ({
                isLoaded: true,
                items: response.data.data.getRequesterProposalsForUserRequester.requester_proposals
            }));


        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }


    handleCloseMessageModal() {
        this.setState(
            { showSendToModal: false }
        )
    }

    handleSendMessageModal(event, id_proposal) {
        event.preventDefault();
        if (this.state.msg_content !== "") {
            var VAR = JSON.stringify({
                "notification": {
                    "id_proposal": this.state.proposal,
                    "msg_object": "xxxx", //this.state.msg_object
                    "msg_content": this.state.msg_content,
                    "type_notification": "MSG-WEB"
                }

            });
            this.writeToTravelerRequest(SEND_MESSAGE_TO_TRAVEL_TRAVELER_QUERY, VAR)
        }
        else{
            alert("merci de renseigner un champ")
        }
    }

    writeToTravelerRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

           
            console.log(response.data)
            if (response.data.data.notifiedProposalTravelByRequester.status === 200) {
                alert('Votre message a bien été envoyé au transporteur')
                window.location.reload(false);
            } else {
                this.setState(() => ({
                    showAlert: true,
                    msgAlertBody: 'Echec, merci de renseigner un objet pour votre message',
                    msgAlertHeader: 'Champs vides',
                    alertVariant: 'danger'
    
                }));
                //alert('Echec, merci de renseigner un objet pour votre message')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleCommentaryChange(event) {
        this.setState({ msg_content: event.target.value })
    }

    handleObjectChange(event) {
        this.setState({
            msg_object: event.target.value
        })
    }

    handleShowWriteToModal(event, id_proposal) {
        event.preventDefault();
        this.setState({
            showSendToModal: !this.state.showSendToModal,
           proposal : id_proposal
        }
        )
    }

    render() {
        moment.locale('fr');
        //console.log(this.state.items.length)
        if (this.state.items.length ===0) {
			return (
				<div className="mymessage">
                    <div className="section testimonials">
                    <aside class="aside section photo">
                        <div class="section-inner">
                            <h3 class="heading">Vos échanges avec les transporteurs ! </h3>
                            <p>
                                Vous pouvez demander des précisions à tout moment aux transporteurs via la messagerie interne.
                            </p>
                        </div>
                        <br/>
                        <div>VOUS N'AVEZ PAS DE MESSAGES</div>
                    </aside>
                    </div>
                </div >
			)
		}
		else {
            return (
            <div class="row messages ">
                <div class="col-sm-12">

                <Modal show={this.state.showSendToModal} onHide={this.handleCloseMessageModal} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Vos échanges avec les transporteurs!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <span class="">Vous pouvez retrouver le suivi de vos échanges dans la section messages de votre compte.</span>
                                    {this.state.showAlert ?
                                        <Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert} dismissible>
                                            <Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
                                            <p>{this.state.msgAlertBody} </p>
                                        </Alert> : ''}
                                    <br />
                                    <br />
                            {/*         <label for="exampleFormControlTextarea1">Objet du message</label>
                                    <input type="text" className="form-control" id="travelFirm" placeholder="merci de renseigner l'objet du message" value={this.state.msg_object} onChange={this.handleObjectChange} required="true" /> */}
                                    <label for="exampleFormControlTextarea1">Laisser un message</label>
                                    <small id="travelTicket" className="form-text text-muted">
                                        Ne pas indiquer de <span className="fa fa-1x fa-phone-square"> Numéro de téléphone </span>
                    ou	    <span className="fa fa-1x fa-facebook-square"> de détails de votre Facebook </span>
                                    </small>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.msg_content} onChange={this.handleCommentaryChange}></textarea>
                                </div>



                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-outline-primary" onClick={this.handleCloseMessageModal}>
                                    Annuler
                                </button>
                                <button className="btn btn-primary" onClick={(e) => { this.handleSendMessageModal(e) }}>
                                    Valider
                                 </button>
                            </Modal.Footer>


                        </Modal>



                    <aside class="aside section photo">
                        <div class="section-inner">
                            <h3 class="heading">Vos échanges avec les transporteurs !</h3>
                            <p>
                                Vous pouvez demander des précisions à tout moment aux expéditeurs via la messagerie interne.
                            </p>
                        </div>
                    </aside>

                    {this.state.items.map((value, index) => {
                        return <div key={index} >
                            
                            { value.notifications !== null ? 
                            <div>  Votre voyage :<span class="">{value.travel_infos.departure_city}</span ><i class="fa fa-long-arrow-right color-text2"></i><span class="">{value.travel_infos.arrival_city}</span> du <span class="">{Moment(value.departure_date).format('LLLL')}</span>
                            <hr class="mar-top5" />
                            <p class="mar-bot0"> Voyageur :
                            
                            <span class="color-text2">
                                {/* {value.user_traveler_attributes.given_name} {value.user_traveler_attributes.family_name}  */}
                                {
                                                    value.user_traveler_attributes.account_type === null || value.user_traveler_attributes.account_type === undefined || value.user_traveler_attributes.account_type === 'null' || value.user_traveler_attributes.account_type === 'undefined' || value.user_traveler_attributes.account_type === "Particulier" || value.user_traveler_attributes.account_type === "" ?
                                                        <span className="media-heading"> {value.user_traveler_attributes.given_name}</span>
                                                        :
                                                        value.user_traveler_attributes.account_type_details !== "" || value.user_traveler_attributes.account_type_details !== "null" || value.user_traveler_attributes.account_type_details !== "undefined" || value.user_traveler_attributes.account_type_details !== null || value.user_traveler_attributes.account_type_details !== undefined ?
                                                            <span className="media-heading"> {value.user_traveler_attributes.account_type_details.company_name}</span>
                                                            :
                                                            <span className="media-heading"> {value.user_traveler_attributes.given_name}</span>

                                                }
                                - {value.weight_reserved} kg</span></p>
                            <div class="message">
                            
                        {value.notifications !== null ? value.notifications.map((val, index) => {   
                                        return <div key={index} >
                                            <div class="media">
								            <div class="media-left media-top">
                                                <div class="photo">
                                                {
                                                val.expeditor_id_user === this.userSub ?  this.photo==='null' || this.photo===null ? 
                                                              <img src={DEFAULT_PHOTO} alt="MDN" className="cover rounded" /> :
                                                             <img src={this.photo} alt="MDN" className="cover rounded" />
                                                                 : value.user_traveler_attributes.picture==='null' ||value.user_traveler_attributes.picture === null  ? <img src={DEFAULT_PHOTO} alt="MDN" className="cover rounded" /> :<img src={value.user_traveler_attributes.picture} alt="MDN Logo" className="cover rounded" /> }
                                                </div>
                                                </div>
                                                <div class="media-body pad-left10">
                                                    {val.expeditor_id_user === this.userSub ? <h6 class="head">{  value.user_requester_attributes.account_type === "Entreprise" ? value.user_requester_attributes.account_type_details.company_name : this.given_name} {/* this.family_name */}</h6> : value.user_traveler_attributes.given_name === null || value.user_traveler_attributes.given_name === undefined || value.user_traveler_attributes.given_name === 'null' || value.user_traveler_attributes.given_name === 'undefined' || value.user_traveler_attributes.given_name === "" ? value.user_traveler_attributes.family_name :  value.user_traveler_attributes.account_type === "Entreprise" ? value.user_traveler_attributes.account_type_details.company_name : <h6 className="head"> {value.user_traveler_attributes.given_name} </h6>  }

                                                    <p class="">{val.msg_content}
                                                    </p>
                                                    <span class="date color-text2">Envoyé le {Moment(val.msg_date).format('LLLL')}</span>

                                                </div>
                                            </div>

                                            <hr />
                                        </div>

                                    }) : null}
                                    
                                            <div class="align-right">
                                                <a class="link color-blue" data-toggle="modal" data-target="#modal-write" href="" onClick={(e) => { this.handleShowWriteToModal(e, value.id_proposal) }}><span class="fa fa-1x fa-reply"></span><span>Répondre</span></a>
                                            </div>
                            
                            </div> </div> : ''}
                            
                        </div>

                    }  )}

                </div>
            </div>



        );
     }
    }
}
export default withRouter(MessagesExpeditions);