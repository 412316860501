import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';

import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { AVIS_RECU_QUERY } from '../../../query/queryprofil';
import { AVIS_RECU_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Moment from "moment";
import ReactPaginate from 'react-paginate';
import PHOTO from '../../../assets/img/user/user0.png';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import _ from 'lodash';
import { Rating } from '@material-ui/lab';

class MyAvisrecu extends Component {
	constructor(props) {
		super(props);
		this.nbeElementOfPage = 3
		this.offsetInitial = 0
		this.state = {
			isLoaded: false,
			items: [],

			pageCount: 0,
			offset: this.offsetInitial,
			perPage: this.nbeElementOfPage,
			currentPage: 0,
			data: [],
			tmp: '',
			commentary: '',
			showEvalModal: false,
			nameTmp: '',
			marks: '5/5 Très bien',
			requester_proposals: [],
			datavalue: []
		};
	}

	componentWillMount() {
		
        this.idToken = getCookieConsentValue('idToken');
		//this.idToken = sessionStorage.getItem('idToken')
		this.getUserAvisRecu(AVIS_RECU_QUERY)
	}


	getUserAvisRecu = async (query) => {
		try {
			const response = await axios.post(AVIS_RECU_URL,
				{ query },
				{
					'headers':
					{
						'Authorization': this.idToken,
						'Content-Type': 'application/json'
					}
				}
			);

			// Set the data to the state
			this.setState(() => ({
				isLoaded: true,
				items: response.data.data.getEvaluationsSendReceivedAndWaitingByUser.evaluations_send_receive_waiting.evaluations_receive

			}));

			if (response.data.data.getEvaluationsSendReceivedAndWaitingByUser.status === 200) {
				this.setState(() => ({
					dataValue: response.data.data.getEvaluationsSendReceivedAndWaitingByUser.evaluations_send_receive_waiting.evaluations_receive,
					pageCount: Math.ceil(response.data.data.getEvaluationsSendReceivedAndWaitingByUser.length / this.nbeElementOfPage),
					data: response.data.data.getEvaluationsSendReceivedAndWaitingByUser.evaluations_send_receive_waiting.length > this.nbeElementOfPage ? response.data.data.getEvaluationsSendReceivedAndWaitingByUser.evaluations_send_receive_waiting.slice(this.offsetInitial, this.nbeElementOfPage) : response.data.data.getEvaluationsSendReceivedAndWaitingByUser.evaluations_send_receive_waiting,
				}));
			}

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	loadHistoricData() {
		const elements = this.state.dataValue;
		this.setState({
			data: elements.slice(this.state.offset, this.state.offset + this.state.perPage),
			pageCount: Math.ceil(elements.length / this.state.perPage)
		});

	}

	handlePageClick = data => {
		let selected = data.selected;
		let offset = selected * this.state.perPage;

		this.setState({ currentPage: selected, offset: offset }, () => {
			this.loadHistoricData();
		});
	};

	render() {

		return (
			<div class="myprofile">
				<aside class="aside section avis testimonials">
					<div class="section-inner">
						<h3 class="heading">Avis reçus</h3>
						<div class="content">
							<p class="mar-bot0">Vous êtes évalué à la fin de chaque transaction par les membres avec qui vous avez échangé des services. Les commentaires reçus sont visibles ici et sur votre profil public. </p>
							<br />
							{this.state.data.length === 0 ?
								<div>
									Vous n'avez réçu d'avis
                    			</div>
								: this.state.data.evaluations_receive.map((value, index) => {
									return <div class="item" key={index} >

										{value.evaluation !== null ? <div>
											<div class="quote">
												{/* <StarRatings
													rating={value.evaluation.marks}
													starRatedColor="red"
													numberOfStars={5}
													name='rating'
													starDimension='20px'
													starSpacing='0,5px'
												/> */}

												<Rating
													name="hover-feedback"
													value={value.evaluation.marks}
													precision={0.5}
													readOnly
												/>

												<p>

													{value.evaluation.comment}</p>
													
											</div>
											{value.user_attributes.account_type === null || value.user_attributes.account_type === undefined || value.user_attributes.account_type === 'null' || value.user_attributes.account_type === 'undefined' || value.user_attributes.account_type === "Particulier" || value.user_attributes.account_type === "" ? <p class="source"><span class="name txt-bold">{value.user_attributes.given_name}</span></p>  :
											value.user_attributes.account_type_details.company_name === null || value.user_attributes.account_type_details.company_name === undefined || value.user_attributes.account_type_details.company_name === 'undefined' || value.user_attributes.account_type_details.company_name === 'null' || value.user_attributes.account_type_details.company_name === '' ? value.user_attributes.given_name  : value.user_attributes.account_type_details.company_name} <p><span class="date"> {value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}, {Moment(value.evaluation.evaluation_date).format('MMM-YYYY')}</span></p>
										</div>
											: <div>
												Vous n'avez pas laissé de commentaire
											<p class="source"><span class="name txt-bold">{value.user_attributes.given_name}</span> <span class="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}</span></p>
											</div>
										}

									</div>
								})}

							<ReactPaginate
								previousLabel={'«'}
								nextLabel={'»'}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={this.state.pageCount}
								marginPagesDisplayed={2}
								pageRangeDisplayed={10}
								onPageChange={this.handlePageClick}
								containerClassName={'pagination float-right'}
								pageClassName={'page-item'}
								pageLinkClassName={'page-link'}
								nextClassName={'page-item'}
								previousClassName={'page-item'}
								previousLinkClassName={'page-link'}
								nextLinkClassName={'page-link'}
								activeClassName={'active'}
								disabledClassName={'desabled'}
							/>

						</div>
					</div>
				</aside>
			</div>

		);

	}
}
export default withRouter(MyAvisrecu);