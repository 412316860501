export const TRAVEL_PROPOSAL_QUERY = `query {
  getUserTravelProposal {
    status
    message
    travel_proposals {
      id_travel
      id_user_proposal
      registration_date
      departure_date
      departure_city
      arrival_city
      has_check_ticket
      max_weight_available
      min_weight_booking
      proposal_amount_by_kg
      booking_deadline
      service_charge_amount
      is_automatic_approval
      transport_company
      others_infos
      take_off_place
      recovery_place
      status
      ticket_url
      measure_unit
      volume
      arrival_date
      transportation_type
      transportation_condition
      weigth_available_info {
        weigth_available
        last_update_date
      }
      traveler_cancel_info {
        traveler_cancel_date
        send_mails_to_requesters
        comment
      }
      user_proposal_attributes {
        email_verified
        account_type
        account_type_details {
          registration_number
          company_name
          company_country_name
        }
        phone_number
        username
        name
        family_name
        given_name
        picture
        birthdate
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        email
        gender
        city_of_residence
        additional_infos
        description
        last_update_date
     }
      system_traveler_reimbursment_info {
        date
        reimbursment_date
        reimbursment_amount
        status
      }
    }
    errors {
      param
      msg
    }
  }
}
`
export const TRAVEL_PROPOSAL_PAST_QUERY = `query {
  getUserTravelProposal(travel_time: "past")  {
    status
    message
    travel_proposals {
      id_travel
      id_user_proposal
      registration_date
      departure_date
      departure_city
      arrival_city
      has_check_ticket
      max_weight_available
      min_weight_booking
      proposal_amount_by_kg
      booking_deadline
      service_charge_amount
      is_automatic_approval
      transport_company
      others_infos
      take_off_place
      recovery_place
      status
      ticket_url
      measure_unit
      volume
      arrival_date
      transportation_type
      transportation_condition
      weigth_available_info {
        weigth_available
        last_update_date
      }
      traveler_cancel_info {
        traveler_cancel_date
        send_mails_to_requesters
        comment
      }
      system_traveler_reimbursment_info {
        date
        reimbursment_date
        reimbursment_amount
        status
      }
    }
    errors {
      param
      msg
    }
  }
}
`

export const NEW_TRAVEL_QUERY = `mutation($travel_proposal: TravelerProposalInput!) {
  createTravelProposal(travel_proposal: $travel_proposal) {
    status
    message
    travel_proposal {
      id_travel
      measure_unit
      volume
      arrival_date
      transportation_type
      id_user_proposal
      registration_date
      departure_date
      departure_city
      arrival_city
      lng_departure_city
      lat_departure_city
      lng_arrival_city
      lat_arrival_city
      has_check_ticket
      max_weight_available
      min_weight_booking
      proposal_amount_by_kg
      booking_deadline
      service_charge_amount
      is_automatic_approval
      transport_company
      others_infos
      take_off_place
      recovery_place
      status
      ticket_url
      weigth_available_info {
        weigth_available
        last_update_date
      }
      traveler_cancel_info {
        traveler_cancel_date
        send_mails_to_requesters
        comment
      }
      system_traveler_reimbursment_info {
        date
        reimbursment_date
        reimbursment_amount
        status
      }
    }
    errors{
      param
      msg
    }
  }
}
`
export const UPDATE_TRAVEL_QUERY = `mutation($travel_proposal: TravelerProposalInput!) {
  updateTravelProposal(travel_proposal: $travel_proposal) {
    status
    message
    travel_proposal {
      id_travel
      id_user_proposal
      registration_date
      departure_date
      departure_city
      arrival_city
      lng_departure_city
      lat_departure_city
      lng_arrival_city
      lat_arrival_city
      has_check_ticket
      max_weight_available
      min_weight_booking
      proposal_amount_by_kg
      booking_deadline
      service_charge_amount
      is_automatic_approval
      transport_company
      others_infos
      take_off_place
      recovery_place
      status
      ticket_url
      measure_unit
      volume
      arrival_date
      transportation_type
      weigth_available_info {
        weigth_available
        last_update_date
      }
      traveler_cancel_info {
        traveler_cancel_date
        send_mails_to_requesters
        comment
      }
      system_traveler_reimbursment_info {
        date
        reimbursment_date
        reimbursment_amount
        status
      }
    }
    errors{
      param
      msg
    }
  }
}
`

export const DELETE_TRAVEL_QUERY = `mutation($id_travel: String!) {
  deleteTravelProposal(id_travel: $id_travel) {
    status
    message
    travel_proposal {
      id_travel
      id_user_proposal
      registration_date
      departure_date
      departure_city
      arrival_city
      has_check_ticket
      max_weight_available
      min_weight_booking
      proposal_amount_by_kg
      booking_deadline
      service_charge_amount
      is_automatic_approval
      transport_company
      others_infos
      take_off_place
      recovery_place
      status
      ticket_url
      weigth_available_info {
        weigth_available
        last_update_date
      }
      traveler_cancel_info {
        traveler_cancel_date
        send_mails_to_requesters
        comment
      }
      system_traveler_reimbursment_info {
        date
        reimbursment_date
        reimbursment_amount
        status
      }
    }
    errors{
      param
      msg
    }
  }
}
`

export const GET_TRAVEL_RESERVATION_LIST = `query($id_travel: String!) {
  getBookingsForUserTravelProposal(id_travel: $id_travel) {
    status
    message
    requester_proposals {
      id_proposal
      service_charge_amount
      id_user_requester
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
     package_received_delivered_infos {
      received_code
      received_code_setup_date
      received_code_validation_date
      delivered_code
      delivered_code_setup_date
      delivered_code_validation_date
    }
    }
    errors{
      param
      msg
    }
  }
}
`

export const REJECT_TRAVEL_PROPOSAL_QUERY = `mutation($id_travel: String!, $id_proposal: String!, $response_comment: String!) {
  rejectProposalTravel(id_travel: $id_travel, id_proposal: $id_proposal, response_comment: $response_comment){
   status
   message
   requester_proposal {
     id_proposal
     id_user_requester
     id_travel
     transaction_id
     transaction_amount
     transaction_date
     transaction_status
     registration_date
     weight_reserved
     estimated_amount_value
     town
     additional_infos
     request_status
     last_date_response
     response_comment
     receiver_infos {
       firstname
       lastname
       email
       contacts {
         contact_type
         contact_value
       }
     }
     traveler_evaluation {
      evaluation_date
      marks
      comment
    }
    requester_evaluation {
      evaluation_date
      marks
      comment
    }
     notifications  {
       msg_id
       msg_date
       msg_object
       msg_content
       type_notification
       status
       expeditor_id_user
       receiver_id_user
     }
     requester_cancel_info {
       requester_cancel_date
       comment
       reimbursment_date
       reimbursment_amount
     }
     user_requester_attributes {
       email_verified
       name
       family_name
       given_name
       picture
       birthdate
       email
       gender
       address{
         street_address
         locality
         region
         postal_code
         country
       }
       phone_number
       city_of_residence
       additional_infos
       description
       last_update_date
    }
    travel_infos {
      departure_date
      departure_city
      arrival_city
    }
   }
   errors{
     param
     msg
   }
  }
 }
`

export const ACCEPT_TRAVEL_PROPOSAL_QUERY = `mutation($id_travel: String!, $id_proposal: String!) {
  acceptProposalTravel(id_travel: $id_travel, id_proposal: $id_proposal) {
  status
  message
  requester_proposal {
    id_proposal
    id_user_requester
    id_travel
    transaction_id
    transaction_amount
    transaction_date
    transaction_status
    registration_date
    weight_reserved
    estimated_amount_value
    town
    additional_infos
    request_status
    last_date_response
    response_comment
    receiver_infos {
      firstname
      lastname
      email
      contacts {
        contact_type
        contact_value
      }
    }
    traveler_evaluation {
      evaluation_date
      marks
      comment
    }
    requester_evaluation {
      evaluation_date
      marks
      comment
    }
    notifications  {
      msg_id
      msg_date
      msg_object
      msg_content
      type_notification
      status
      expeditor_id_user
      receiver_id_user
    }
    requester_cancel_info {
      requester_cancel_date
      comment
      reimbursment_date
      reimbursment_amount
    }
    user_requester_attributes {
      email_verified
      name
      family_name
      given_name
      picture
      birthdate
      email
      gender
      address{
        street_address
        locality
        region
        postal_code
        country
      }
      phone_number
      city_of_residence
      additional_infos
      description
      last_update_date
   }
   travel_infos {
     departure_date
     departure_city
     arrival_city
   }
   package_received_delivered_infos {
    received_code
    received_code_setup_date
    received_code_validation_date
    delivered_code
    delivered_code_setup_date
    delivered_code_validation_date
  }
  }
  errors{
    param
    msg
  }
 }
}
`

export const SEND_MESSAGE_TO_TRAVEL_REQUESTER_QUERY = `mutation($notification: NotificationInput!) {
  notifiedProposalTravelByTraveler(notification: $notification) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
    }
    errors{
      param
      msg
    }
  }
}
`

export const SEND_MESSAGE_TO_TRAVEL_TRAVELER_QUERY= `mutation($notification: NotificationInput!) {
  notifiedProposalTravelByRequester(notification: $notification) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_user_proposal
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
     package_received_delivered_infos {
       received_code
       received_code_setup_date
       received_code_validation_date
       delivered_code
       delivered_code_setup_date
       delivered_code_validation_date
     }
    }
    errors{
      param
      msg
    }
  }
}
`




export const CANCEL_RESERVATION_QUERY = `mutation($id_proposal: String!, $requester_comment: String!) {
  requesterCancelProposalTravelByTraveler(id_proposal: $id_proposal, requester_comment: $requester_comment) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
     package_received_delivered_infos {
       received_code
       received_code_setup_date
       received_code_validation_date
       delivered_code
       delivered_code_setup_date
       delivered_code_validation_date
     }
    }
    errors{
      param
      msg
    }
  }
}
`
export const TRAVELER_DECRYPT_CODE = `
query($id_proposal: String!, $cipher_code: String!) {
  decryptReceivedCode(id_proposal: $id_proposal, cipher_code: $cipher_code) {
    status
    message
	decrypt_data{
	  KeyId
	  Plaintext
	  EncryptionAlgorithm
	}
    errors{
      param
      msg
    }
  }
}
`

export const VALIDATE_DELIVER_CODE_QUERY = `mutation($id_proposal : String!, $plaintext_code : String!) {
  validatedDeliveredCode(id_proposal: $id_proposal, plaintext_code: $plaintext_code) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
     package_received_delivered_infos {
     	received_code
     	received_code_setup_date
     	received_code_validation_date
     	delivered_code
     	delivered_code_setup_date
     	delivered_code_validation_date
     }
    }
    errors{
      param
      msg
    }
  }
}
`
export const TRAVELER_EVALUATION_QUERY = `mutation($id_proposal : String!, $marks: String!, $comment: String! ) {
  requesterProposalEvaluationByTraveler(id_proposal: $id_proposal, marks: $marks, comment: $comment) {
    status
    message
    requester_proposal {
      id_proposal
      id_user_requester
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
     }
     package_received_delivered_infos {
       received_code
       received_code_setup_date
       received_code_validation_date
       delivered_code
       delivered_code_setup_date
       delivered_code_validation_date
     }
    }
    errors{
      param
      msg
    }
  }
}
`

export const REPORT_USER_ABUSE_QUERY = `mutation($make_report_on_user_notification: MakeReportOnUserNotificationInput!) {
  makeReportOnUser(make_report_on_user_notification: $make_report_on_user_notification) {
    status
    message
    make_report_on_user_notification{
      report_id
      report_date
      report_object
      report_content
      type_notification
      status
      expeditor_id_user
    }
    errors{
      param
      msg
    }
  }
}
`

export const REPORT_TRAVEL_ABUSE_QUERY =`mutation($make_report_on_travel_notification: MakeReportOnTravelNotificationInput!) {
  makeReportOnTravel(make_report_on_travel_notification: $make_report_on_travel_notification) {
    status
    message
    make_report_on_travel_notification{
      report_id
      report_date
      report_object
      report_content
      type_notification
      status
      expeditor_id_user
    }
    errors{
      param
      msg
    }
  }
}
`