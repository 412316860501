import React, {Component, Fragment} from 'react';
import {Helmet} from 'react-helmet';
import user0 from './../../assets/img/user/user0.png';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Careers extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Carrières - Rejoignez une équipe fun et dynamique | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="careers">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/about" className="list-group-item list-group-item-action">A propos</a>
                                        <a href="/founders"
                                            className="list-group-item list-group-item-action">Cofondateurs</a>
                                        <a href="/details"
                                            className="list-group-item list-group-item-action">Coordonnées</a>
                                        <hr />
                                        <a href="/careers"
                                            className="list-group-item list-group-item-action active">Carrière</a>
                                        <a href="/press" className="list-group-item list-group-item-action">Presse</a>
                                        <a href="/contact"
                                            className="list-group-item list-group-item-action">Contact</a>
                                    </div>

                                </div>
                                <div className="col-sm-9">
                                    <div className="section">
                                        <div className="section-inner">
                                            <h1 className="f-heading">Carrière</h1>
                                            <p> Fondée en janvier 2019 et basée à Paris, MonKolis offre un service dans
                                            l’esprit de l’économie collaborative dont le but est de faire des
                                                économies au quotidien tout en contribuant au développement durable.</p>
                                            <p>Le transport de kolis à l'international n'aura jamais été aussi simple :
                                            nous mettons en relation des transporteurs et des expéditeurs qui
                                            veulent envoyer ou recevoir un kolis du pays. Grâce à notre service, le
                                            kolis de l'expéditeur arrive rapidement et en toute sureté tandis que le
                                            transporteur rentabilise son voyage et fait de belles rencontres. Tout
                                            le monde y gagne : les uns payent moins cher leur envoi et les autres
                                            remboursent une partie de leurs frais de transport ou augmentent leurs
                                                revenus, tout en rendant service.</p>
                                            <p>Vous trouverez ci-dessous les offres d'emploi disponibles pour rejoindre
                                            l'équipe MonKolis. Nous sommes à la recherche de talents, n'hésitez pas
                                                à nous tranmettre votre candidature à l'adresse <a
                                                    href="mailto:hello@monkolis.fr">hello@monkolis.fr</a> si vous
                                                souhaitez mettre vos compétences au service de notre mission.</p>

                                            <div className="media mar-bot15 mar-top20">
                                                <div className="media-left media-top">
                                                    <div className="photo">
                                                        <img src={user0} alt="MDN Logo"
                                                            className="media-object cover rounded" />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <h4 className="media-heading">Business developer</h4>
                                                    <p className="color-text2 txt-bold">Stagiaire</p>
                                                    <p>En collaboration directe avec les 3 fondateurs, le/la salarié(e)
                                                    aura pour missions principales :
                                                        <br /> Démarchage et acquisitions de nouveaux clients,
                                                        développement d’un réseau de partenaires, suivi client,
                                                        développement et animation de communautés, mise en place
                                                        d’actions de promotions, networking lors d’événements, réflexion
                                                        sur la stratégie et le business model...</p>
                                                    <a href="#" className="link color-blue"> Télécharger l'offre →</a>
                                                </div>
                                            </div>
                                            <div className="media mar-bot15 mar-top20">
                                                <div className="media-left media-top">
                                                    <div className="photo">
                                                        <img src={user0} alt="MDN Logo"
                                                            className="media-object cover rounded" />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <h4 className="media-heading">Chargé de communication</h4>
                                                    <p className="color-text2 txt-bold">Stagiaire / Alternant</p>
                                                    <p>En collaboration directe avec les 3 fondateurs, le/la salarié(e)
                                                        pour missions principales : <br /> Mise en place d’un plan de
                                                        communication global pour la startup; gestion de l’image de
                                                        marque, des relations presse, des réseaux sociaux; animation de
                                                        communautés; mise en place d’actions de promotions.</p>

                                                    <a href="#" className="link color-blue"> Télécharger l'offre →</a>
                                                </div>
                                            </div>
                                            <div className="media mar-bot15 mar-top20">
                                                <div className="media-left media-top">
                                                    <div className="photo">
                                                        <img src={user0} alt="MDN Logo"
                                                            className="media-object cover rounded" />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <h4 className="media-heading">Développeur full stack</h4>
                                                    <p className="color-text2 txt-bold">CDD</p>
                                                    <p className="">Prendre part à la stratégie de notre startup : quoi
                                                    développer, pourquoi, comment, et avec quelle priorité ? Nous
                                                    pensons notamment à un SSO, des API, un système de gamification,
                                                    une interface admin, un moyen d’exploiter au mieux les données
                                                    que nous collectons, concevoir et développer de nouvelles
                                                        fonctionnalités, et toute idée que tu auras !<br /> Apporter des
                                                        savoir-faires techniques qui permettront de faire évoluer notre
                                                        produit et l’entreprise.
                                                    </p>
                                                    <a href="#" className="link color-blue"> Télécharger l'offre →</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>

        );
    }
}

export default Careers;