import React, { Component } from 'react';
import config from "./config";
import ReactGA from 'react-ga';
import './App.css';
// import { Button, Navbar, Nav, Collapse } from 'reactstrap';
// import headerwrapurl from './assets/img/other/header.png';
// import Header from './components/header/header';
// import Footer from './components/footer/footer';
import RouterOutlet from './core/routeroutlet';
import CookieConsent, { Cookies } from "react-cookie-consent";

ReactGA.initialize('UA-173912597-2');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
      <div>
        <CookieConsent
          location="top"
          buttonText="J'accepte"
          cookieName="CookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ background: "#ffc72c", fontSize: "normal" }}
        >
          MonKolis utilise des cookies pour vous permettre de mieux naviguer sur notre site.{" "}
        </CookieConsent>
        <RouterOutlet />
      </div>
    );
  }
}

export default App;

// <div className="App">
      //   <header className="App-header">
      //     <img src={logo} className="App-logo" alt="logo" />
      //     <p>
      //       Edit <code>src/App.js</code> and save to reload.
      //       <Button color="danger">Danger!</Button>
      //       <Button color="primary">Primary!</Button>
      //       <Alert color="danger">Hey you!</Alert>
      //     </p>
      //     <a
      //       className="App-link"
      //       href="https://reactjs.org"
      //       target="_blank"
      //       rel="noopener noreferrer"
      //     >
      //       Learn React
      //     </a>
      //   </header>
      // </div>