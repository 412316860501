import React, { Component } from 'react';
import gallery10 from './../../assets/img/gallery/gallery10.jpg';
import gallery41 from './../../assets/img/gallery/gallery41.jpg';
import gallery12 from './../../assets/img/gallery/gallery12.jpg';
import gallery15 from './../../assets/img/gallery/gallery15.jpg';
import gallery17 from './../../assets/img/gallery/gallery17.jpg';
import gallery42 from './../../assets/img/gallery/gallery42.jpg';


import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';


import { SEARCHTRAVEL_QUERY,NOT_CONNECTED_SEARCHTRAVEL_QUERY } from './../../query/querytrajet';
import { SEARCHTRAVEL_URL} from './../../Url';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';


class ServiceBlockMillier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
        this.handleSearch = this.handleSearch.bind(this);
        
    }

   

    handleSearch(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "search_travel_proposal": {}
        })
        //this.searchTravel(SEARCHTRAVEL_QUERY, VAR)
        
        getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined'  ? this.searchTravel(SEARCHTRAVEL_QUERY, VAR) : this.searchTravel(SEARCHTRAVEL_QUERY, VAR)
    }

    searchTravel = async (query, variables) => {
        try {
            const response = await axios.post(SEARCHTRAVEL_URL, {
                query,
                variables
            });
            this.setState(() => ({
                isLoaded: true,
                items: response.data.data
            }));

            // Log the response so we can look at it in the console
            //console.log(response.data)
            //console.log(this.props)
            this.props.history.push({
                pathname: '../trips',
                state: { items: this.state.items }
            });

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            //console.log(error)
        }
    }

    render() {
        return (      
	    <section className="bg-color-gray align-left section-pad">
            <div className="container-fluid container-fluid-pad">
                <h2 className="align-left mar-bot30 txt-bold">Des milliers de kolis, voyages, lieux, rencontres...</h2>

                <div className="card-deck">
                    <div className="card">
                        <img className="card-img-top" src={gallery10} alt="Card cap"/>
                        <div className="card-body">
                        <h4 className="card-title">Abidjan <span className="fa fa-long-arrow-right color-text2"></span> Paris</h4>
                            {/*<p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>*/}
                        </div>
                        <div className="card-footer">
                        <small className="text-muted">à partir de <span className="color-green txt-bold h5">5,00 €</span> / kg</small>
                        <a className="link color-blue float-right" onClick={this.handleSearch}>Réserver</a>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src={gallery41} alt="Card cap"/>
                        <div className="card-body">
                        <h4 className="card-title">Bruxelles <span className="fa fa-long-arrow-right color-text2"></span> Brazzaville</h4>
                            {/*<p className="card-text">This card has supporting text below as a natural lead-in to additional content.</p>*/}
                        </div>
                        <div className="card-footer">
                        <small className="text-muted">à partir de <span className="color-green txt-bold h5">5,10 €</span> / kg</small>
                        <a className="link color-blue float-right" onClick={this.handleSearch}>Réserver</a>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src={gallery12} alt="Card cap" />
                        <div className="card-body">
                        <h4 className="card-title">Berlin <span className="fa fa-long-arrow-right color-text2"></span> Douala</h4>
                            {/*<p className="card-text">This card has even longer content than the first to show that equal height action.</p>*/}
                        </div>
                        <div className="card-footer">
                        <small className="text-muted">à partir de <span className="color-green txt-bold h5">5,80 €</span> / kg</small>
                        <a className="link color-blue float-right" onClick={this.handleSearch}>Réserver</a>
                        </div>
                    </div>
                </div>
                {/* <div className="clearfix hidden-xs" style={width:'100%', height:'50px'}>
                </div> */}
                    <div className="card-deck">
                        <div className="card">
                            <img className="card-img-top" src={gallery15} alt="Card cap"/>
                            <div className="card-body">
                            <h4 className="card-title">Cotonou <span className="fa fa-long-arrow-right color-text2"></span> Paris</h4>
                                {/*<p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>*/}
                            </div>
                            <div className="card-footer">
                            <small className="text-muted">à partir de <span className="color-green txt-bold h5">4,20 €</span> / kg</small>
                            <a className="link color-blue float-right" onClick={this.handleSearch}>Réserver</a>
                            </div>
                        </div>
                        <div className="card">
                            <img className="card-img-top" src={gallery42} alt="Card cap"/>
                            <div className="card-body">
                            <h4 className="card-title">Bordeaux <span className="fa fa-long-arrow-right color-text2"></span> Kigali</h4>
                                {/*<p className="card-text">This card has supporting text below as a natural lead-in to additional content.</p>*/}
                            </div>
                            <div className="card-footer">
                            <small className="text-muted">à partir de <span className="color-green txt-bold h5">6,30 €</span> / kg</small>
                            <a className="link color-blue float-right" onClick={this.handleSearch}>Réserver</a>
                            </div>
                        </div>
                        <div className="card">
                            <img className="card-img-top" src={gallery17} alt="Card cap"/>
                            <div className="card-body">
                            <h4 className="card-title">Yaoundé <span className="fa fa-long-arrow-right color-text2"></span> Montréal</h4>
                                {/*} <p className="card-text">This card has even longer content than the first to show that equal height action.</p>*/}
                            </div>
                            <div className="card-footer">
                            <small className="text-muted">à partir de <span className="color-green txt-bold h5">6,80 €</span> / kg</small>
                            <a className="link color-blue float-right" onClick={this.handleSearch}>Réserver</a>
                            </div>
                        </div>
                </div>
            </div>
        </section>
          );
    }
  }
  
  export default withRouter(ServiceBlockMillier);
