import axios from 'axios';
import React, {Component, Fragment} from 'react';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import {Helmet} from 'react-helmet';
//import './../../assets/css/index.css';
import './../../assets/css/login.css';
import './../../assets/css/modal.css';
import {withRouter} from 'react-router-dom';
import {CONNEXION_QUERY} from '../../query/queryauthent';
import {CONNEXION_URL, SECURE_COOKIES} from '../../Url';
import Amplify, {Auth} from 'aws-amplify';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import {CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, CognitoUserSession} from 'amazon-cognito-identity-js';
//import awsconfig from '../../aws-exports';
//Amplify.configure(awsconfig);
Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        identityPoolRegion: 'eu-west-1',
        identityPoodId: 'eu-west-1:3cb5ff3c-a4ed-4856-aba6-9328d0c6afe7',
        userPoolId: 'eu-west-1_86RptyH5c',
        userPoolWebClientId: '49qp7c8hrmqv1ptpfrnmg71g8k',
        oauth: {
            domain: 'monkolis-prd.auth.eu-west-1.amazoncognito.com',
            redirectSignIn: 'https://www.monkolis.com/',
            redirectSignOut: 'https://www.monkolis.com/logout',
            scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            responseType: 'token'
        }
    }
});

class Connexion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            mdp: '',
            msgAlertBody: '',
            msgAlertHeader: '',
            alertVariant: 'danger',
            showAlert: false,
            items: [],
            isLoading: true,
            user: null,
            customState: null,
            userSession: null,

            isPasswordShown: false,
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMDPChange = this.handleMDPChange.bind(this);
        this.handleMaintainSession = this.handleMaintainSession.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }


    togglePasswordVisibily = () => {
        const {isPasswordShown} = this.state;
        this.setState({isPasswordShown: !isPasswordShown})

    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }
    
    /* componentDidMount() {
        Hub.listen("auth",({ payload: { event, data } }) =>  {
            switch (event) {
                case "signIn":
                    this.setState({ user: data});
                    console.log(this.state.user)
                    console.log(this.state.user.attributes)
                    //Auth.currentSession().then(user => console.log(user.idToken.jwtToken))
                    Auth.currentSession().then(user => {
                        console.log(this.state.user)
                        sessionStorage.setItem('idToken', user.idToken.jwtToken);
                        sessionStorage.setItem('accessToken', user.accessToken.jwtToken);
                        sessionStorage.setItem('refreshToken', user.refreshToken.jwtToken);
                        sessionStorage.setItem('email', user.idToken.payload.email);
                        sessionStorage.setItem('userConfirmed', user.idToken.payload.email_verified)
                        sessionStorage.setItem('userSub', user.idToken.payload.sub)
                        sessionStorage.setItem('provider', user.idToken.payload.identities[0].providerName)
                    })
                    sessionStorage.setItem('userName', this.state.user.username);
                    sessionStorage.setItem('street_address', "")
                    sessionStorage.setItem('locality', "")
                    sessionStorage.setItem('region', "")
                    sessionStorage.setItem('postal_code', "")
                    sessionStorage.setItem('country', "")


                    // sessionStorage.setItem('name', this.state.user.CognitoUser.user.name)
                    // sessionStorage.setItem('family_name', this.state.user.CognitoUser.user.family_name)
                    // sessionStorage.setItem('given_name', this.state.user.CognitoUser.user.given_name)
                    // sessionStorage.setItem('phone_number', this.state.user.CognitoUser.user.phone_number)
                    // sessionStorage.setItem('photo', this.state.user.CognitoUser.user.picture)

                    break;
                case "signOut":
                    console.log(this.state.user)
                    this.setState({ user: null });
                    break;
                case "customOAuthState":
                    this.setState({ customState: data });
            }
        });

        Auth.currentAuthenticatedUser()
            .then(user => this.setState({ user }))
            .catch(() => console.log("Not signed in"));

    } */

    handleMaintainSession(event) {

    }


    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            this.setState(() => ({
                validated: true
            }));
            var VAR = JSON.stringify({
                "username": this.state.email,
                "password": this.state.mdp
            })
            this.getConnexion(CONNEXION_QUERY, VAR)
        }

    }

    handleCloseAlert(event) {
        this.setState({showAlert: false});
    }


    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }


    handleMDPChange(event) {
        this.setState({mdp: event.target.value});
    }


    getConnexion = async (query, variables) => {
        try {
            const response = await axios.post(CONNEXION_URL, {
                query,
                variables
            });
            // Log the response so we can look at it in the console


            // Set the data to the state
            this.setState(() => ({
                isLoaded: true,
                items: response.data.data
            }));
            console.log(response.data.data)
            if (this.state.items.loginUser.status === 200) {
                Cookies.set('idToken', this.state.items.loginUser.authentificated_user.idTtoken, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('accessToken', this.state.items.loginUser.authentificated_user.accessToken, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('userName', this.state.items.loginUser.user.given_name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('user_name', this.state.items.loginUser.user.username, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                


                Cookies.set('userSub', this.state.items.loginUser.user.userSub, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('userConfirmed', this.state.items.loginUser.user.userConfirmed, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('name', this.state.items.loginUser.user.name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('family_name', this.state.items.loginUser.user.family_name, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('email', this.state.items.loginUser.user.email, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('gender', this.state.items.loginUser.user.gender, { path: '/', secure: SECURE_COOKIES, expires: 1 });
                Cookies.set('given_name', this.state.items.loginUser.user.given_name, { path: '/', secure: SECURE_COOKIES, expires: 1 });


                Cookies.set('account_type', this.state.items.loginUser.user.account_type === null || this.state.items.loginUser.user.account_type === undefined || this.state.items.loginUser.user.account_type === 'null' || this.state.items.loginUser.user.account_type === 'undefined'?'': this.state.items.loginUser.user.account_type,{ path: '/', secure: true, expires: 1 } )
				if(this.state.items.loginUser.user.account_type !=="Particulier" && this.state.items.loginUser.user.account_type !== null && this.state.items.loginUser.user.account_type !== undefined && this.state.items.loginUser.user.account_type !== 'null' && this.state.items.loginUser.user.account_type !== 'undefined'  && this.state.items.loginUser.user.account_type !== ''){
					Cookies.set('registration_number', this.state.items.loginUser.user.account_type_details.registration_number ? this.state.items.loginUser.user.account_type_details.registration_number : '', { path: '/', secure: SECURE_COOKIES, expires: 1 })
					Cookies.set('company_name', this.state.items.loginUser.user.account_type_details.company_name ? this.state.items.loginUser.user.account_type_details.company_name : '', { path: '/', secure: SECURE_COOKIES, expires: 1 })
					Cookies.set('company_country_name', this.state.items.loginUser.user.account_type_details.company_country_name ? this.state.items.loginUser.user.account_type_details.company_country_name: '', { path: '/', secure: SECURE_COOKIES, expires: 1 })
				}

                if (this.state.items.loginUser.user.address !== null) {
                    Cookies.set('street_address', this.state.items.loginUser.user.address.street_address, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                    Cookies.set('locality', this.state.items.loginUser.user.address.locality, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                    Cookies.set('region', this.state.items.loginUser.user.address.region, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                    Cookies.set('postal_code', this.state.items.loginUser.user.address.postal_code, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                    Cookies.set('country', this.state.items.loginUser.user.address.country, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                } else {
                    Cookies.set('street_address', "", { path: '/', secure: SECURE_COOKIES, expires: 1  });
                    Cookies.set('locality', "", { path: '/', secure: SECURE_COOKIES, expires: 1  });
                    Cookies.set('region', "", { path: '/', secure: SECURE_COOKIES, expires: 1  });
                    Cookies.set('postal_code', "", { path: '/', secure: SECURE_COOKIES, expires: 1  });
                    Cookies.set('country', "", { path: '/', secure: SECURE_COOKIES, expires: 1  });
                }
                Cookies.set('phone_number', this.state.items.loginUser.user.phone_number, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                Cookies.set('id_card_check_status', this.state.items.loginUser.user.id_card_check_status===""||this.state.items.loginUser.user.id_card_check_status===null || this.state.items.loginUser.user.id_card_check_status===undefined ? "false" : this.state.items.loginUser.user.id_card_check_status , { path: '/', secure: SECURE_COOKIES, expires: 1  });
                Cookies.set('city_of_residence', this.state.items.loginUser.user.city_of_residence, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                Cookies.set('description', this.state.items.loginUser.user.description, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                Cookies.set('birthdate', this.state.items.loginUser.user.birthdate, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                Cookies.set('additional_infos', this.state.items.loginUser.user.additional_infos, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                Cookies.set('contrib_cwf_balance', this.state.items.loginUser.user.contrib_cwf_balance, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                Cookies.set('photo', this.state.items.loginUser.user.picture, { path: '/', secure: SECURE_COOKIES, expires: 1  });
                
                this.props.history.push({
                    //pathname: '/dashboard/mydashboard',
                    pathname: '/',
                    state: {items: this.state.items}
                });
            } else if (this.state.items.loginUser.status === 400 && this.state.items.loginUser.message.match('UserNotConfirmedException')) {
                this.props.history.push({
                    pathname: '/confirmusercode',
                });
            } else {
                //alert('Email et/ou mot de passe incorrect ! Veuillez verifier les informations saisies')
                this.setState(() => ({
                    showAlert: true,
                    msgAlertBody: 'Email et/ou mot de passe incorrect ! Veuillez verifier les informations saisies',
                    msgAlertHeader: 'Echec Connexion',

                }));
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({error}))
        }
    }

    // PAGE TITLE

    render() {
        const {isPasswordShown} = this.state;
        const {user} = this.state;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Connexion sécurisée | Monkolis</title>
                    <meta name="robots" content="index,follow"/>
                </Helmet>

                <div className="login">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">

                                <div className="col-lg-6 col-md-8 col-sm-8 col-12 d-center">
                                    <div className="section-login">
                                        <div className="section-inner">
                                            <h3 className="heading">Connexion</h3>
                                            {this.state.showAlert ?
                                                <Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert}
                                                       dismissible>
                                                    <Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
                                                    <p>{this.state.msgAlertBody} </p>
                                                </Alert> : ''}
                                            <button className="btn btn-block btn-social btn-facebook"
                                                    onClick={() => Auth.federatedSignIn({provider: 'Facebook'})}><i
                                                className="fa fa-facebook"></i> Connexion avec Facebook
                                            </button>
                                            <button className="btn btn-block btn-social btn-google"
                                                    onClick={() => Auth.federatedSignIn({provider: 'Google'})}><i
                                                className="fa fa-google"></i> Connexion avec Google
                                            </button>
                                            <p className="mar-top20 txt-bold">OU ALORS</p>


                                            <Form className="well" validated={this.state.validated}
                                                  onSubmit={this.handleSubmit}>

                                                {/* <div className="form-group has-feedback">
											<input type="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleEmailChange} />
											<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
										</div> */}

                                                <Form.Group controlId="validationCustom01">
                                                    <Form.Control
                                                        required
                                                        type="email"
                                                        placeholder="Email"
                                                        defaultValue={this.state.email}
                                                        onChange={this.handleEmailChange}
                                                    />
                                                    <span
                                                        className="glyphicon glyphicon-envelope form-control-feedback"></span>
                                                    {/* 	<Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                </Form.Group>

                                                <Form.Group controlId="validationCustom01">

                                                    <Form.Control
                                                        required
                                                        type={(isPasswordShown) ? "text" : "password"}
                                                        placeholder="Mot de passe"
                                                        defaultValue={this.state.mdp}
                                                        onChange={this.handleMDPChange}
                                                    />
                                                    <span className="viewpass"><i
                                                        className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"}`}
                                                        onClick={this.togglePasswordVisibily}></i></span>

                                                    <span
                                                        className="glyphicon glyphicon-lock form-control-feedback"></span>
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                </Form.Group>


                                                {/* <div className="form-group has-feedback mar-bot0">
											<input type="password" className="form-control" placeholder="Mot de passe" value={this.state.mdp} onChange={this.handleMDPChange} />
											<span className="glyphicon glyphicon-lock form-control-feedback"></span>
										</div> */}

                                                <a href="/password-lost" className="mar-bot20 mar-top5 link color-blue">Mot
                                                    de passe Oublié?</a>


                                                <div className="form-group mar-top20">
                                                    <input type="checkbox" id="session-active"
                                                           className="magic-checkbox"
                                                           onChange={this.handleMaintainSession}/>
                                                    <label htmlFor="session-active" className="txt-light">Garder ma
                                                        session active</label>
                                                </div>

                                                <input type="submit" className="btn btn-primary btn-block"
                                                       value="Connexion"/>
                                            </Form>

                                            <p className="mar-top20">Je n'ai pas encore de compte, <a href="/register"
                                                                                                      className="link color-blue">je
                                                m'inscris </a></p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>

        );
    }
}

export default withRouter(Connexion);
