
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import IMG_OOPS from '../../assets/img/other/oops.png'

class Error500 extends Component {
	constructor(props) {
		super(props);
		this.state = {

        };
    }

	render() {
		return (
            <div>
            <div class="container-fluid container-fluid-pad container-fluid-br">
                <div class="">
                    <nav class="navbar navbar-light navbar-expand-sm">
                    </nav>

                    <div class="row pad-top40 pad-bot40">
                        <div class="col-md-5">
                            <img src={IMG_OOPS} alt="MDN Logo" class="cover" />

                        </div>

                        <div class="col-md-7 pad-top20 ">

                            <h1 class="heading color-green oops">Oops !</h1>
                            <h1 class="mar-bot15">500 Error (Server Error).</h1>
                            <h2 class="txt-light mar-bot30">Sorry, that page seems to be taking a break!</h2>

                            <h5 class="txt-light mar-bot15">1.  You can browse to our <a href="/" class="link color-blue">home page</a> and take your own break there :)</h5>
                            <h5 class="txt-light mar-bot15">2.  Send us a <a href="/contact" class="link color-blue">feedback</a> with what you expected to see on this page :(</h5>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        );
}
}
export default withRouter(Error500);