
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios,{AxiosResponse} from 'axios';
import { BASE_URL } from './Url';

import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import './assets/css/main.css';
import './assets/css/annex.css';
import './assets/css/crow.css';
import './assets/css/multiple.css';
import './assets/css/modal.css';
import './assets/fa/fa.css';
import './assets/css/card.css'; 
import './assets/css/responsive.css';

import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

axios.defaults.baseURL=BASE_URL;
axios.interceptors.response.use(undefined, error =>{
     const {status,data,config} =error.response;

     if(status === 401){
        
        
        localStorage.clear();
        sessionStorage.clear();
        ////////remove all cookies//////////
        Cookies.remove('idToken', { path: '/' });
        Cookies.remove('accessToken', { path: '/' });
        Cookies.remove('refreshToken', { path: '/' });
        Cookies.remove('userName', { path: '/' });
        Cookies.remove('user_name', { path: '/' });
        Cookies.remove('userSub', { path: '/' });
        Cookies.remove('userConfirmed', { path: '/' });
        Cookies.remove('name', { path: '/' });
        Cookies.remove('family_name', { path: '/' });
        Cookies.remove('email', { path: '/' });
        Cookies.remove('gender', { path: '/' });
        Cookies.remove('given_name', { path: '/' });
        Cookies.remove('street_address', { path: '/' });
        Cookies.remove('locality', { path: '/' });
        Cookies.remove('region', { path: '/' });
        Cookies.remove('postal_code', { path: '/' });
        Cookies.remove('country', { path: '/' });
        Cookies.remove('phone_number', { path: '/' });
        Cookies.remove('city_of_residence', { path: '/' });
        Cookies.remove('description', { path: '/' });
        Cookies.remove('birthdate', { path: '/' });
        Cookies.remove('additional_infos', { path: '/' });
        Cookies.remove('contrib_cwf_balance', { path: '/' });
        Cookies.remove('photo', { path: '/' });
        Cookies.remove('account_type', { path: '/' });
        Cookies.remove('counter', { path: '/' }); 
        ////////////////////////////////////

      
        alert("Votre session a expirée, vous devez vous reconnecter")
        // this.props.history.push({
        //     pathname: '/logout'
        // });
        this.context.router.history.push("/logout")
     }
})



ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
