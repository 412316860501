import React, { Component } from 'react';
import axios from 'axios';
import { CLOSE_ACCOUNT } from './../../../query/queryprofil'
import { USER_INFO_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import Moment from 'moment';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class MyFermeturedecompte extends Component {
	constructor(props) {
		super(props);
		this.state = {
			closureReason: '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCloseReasonchange = this.handleCloseReasonchange.bind(this)
		this.handleMAJProfile = this.handleMAJProfile.bind(this);
		this.handleMAJNotif = this.handleMAJNotif.bind(this);
	}

	handleMAJNotif(){
		this.props.history.push({
            pathname: '/dashboard/myprofile',
            state: { pages: 'notif' }
        });
	}

	handleMAJProfile(){
		this.props.history.push({
            pathname: '/dashboard/myprofile',
            state: { pages: 'profil' }
        });
	}

	componentWillMount() {
		
		this.idToken = getCookieConsentValue('idToken');
		this.accessToken = getCookieConsentValue('accessToken')
		// this.idToken = sessionStorage.getItem('idToken');
		// this.accessToken = sessionStorage.getItem('accessToken')
	}
 

	handleSubmit(event){
		event.preventDefault();
		var VAR = JSON.stringify({
			"closure_reason" : this.state.closureReason
		})
		this.closeaccount(CLOSE_ACCOUNT,VAR)
	}

	handleCloseReasonchange(event){
		this.setState({
			closureReason : event.target.value
		})
	}

	closeaccount = async(query, variables) => {
		try {
            const response = await axios.post(USER_INFO_URL,
                { query, variables },
                {
                    'headers':
                    {
						'Authorization': this.idToken,
						'AccessToken': this.accessToken
                    }
                }
            );
            // Set the data to the state
			if (response.data.data.deleteUser.status === 200){
				alert('Compte fermée')
				window.location.reload(false);
			}else{
				alert(response.data.data.deleteUser.message)
			}
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('La fermerture du compte à échoué');
        }
	}

	render() {
		
		return (	
		<div className="section-inner">
		<h3 className="heading">Fermeture de compte</h3>
		<div className="content">

			<p>

			Il suffit souvent d'un peu d'aide plutôt que de fermer son compte :<br/>
			Pour changer votre numéro de téléphone ou votre adresse e-mail : <a href="" onClick={this.handleMAJProfile} className="link color-blue"> mettez à jour votre profil</a>.<br/>
			Si vous rencontrez un problème technique : <a href="/contact" className="link color-blue"> contactez-nous</a>.<br/>
			Pour ne plus recevoir d'e-mails :  <a href="" onClick={this.handleMAJNotif} className="link color-blue"> gérez vos notifications</a>.<br/>
			Si vous avez reçu un avis négatif, n'oubliez pas qu'il peut être insignifiant si vous avez plus d'avis positif.
			</p>
			<p>Si vous souhaitez vraiment fermer votre compte, nous en sommes désolés. Afin que nous puissions continuer d'améliorer notre service, nous vous demandons simplement de nous donner la raison de votre décision.</p>

			<form className="" onSubmit={this.handleSubmit}>											                        	
			 <div className="form-group">
				<label for="GoTextarea1" className="txt-bold">Pourquoi voulez-vous partir?</label>
				<textarea className="form-control" id="GoTextarea1" rows="2" minLength="1" maxLength="255" value={this.state.closureReason} onChange={this.handleCloseReasonchange}></textarea>
			  </div>
			  <input type="submit" className="btn btn-danger" data-toggle="modal" data-target="#modal-close-account" value="Fermeture de mon compte"/>

			</form>																		
		</div>
	</div>
	);
}
}
export default withRouter(MyFermeturedecompte);