export const PAYE_CROWFUNDING_QUERY =`mutation($contribution: ContributionInput!) {
    contributeToCrowFundingCampaign(contribution: $contribution) {
      status
      message
      contrib_cwf_balance
      contrib {
        id
        id_campaign
        transaction_id
        transaction_date
        registration_date
        transaction_amount
        transaction_status
        remark
        mark
        id_user
        user_contributor_attributes {
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
            street_address
            locality
            region
            postal_code
            country
          }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
      }
      errors{
        param
        msg
      }
    }
  }
`
export const CONTRIBUTION_CAMPAIN_INFOS_QUERY = `query {
    getDetailsAboutLastActiveCrowFundingCampaignInfos {
      status
      message
      crow_funding_campaign {
        id
        name
        registration_date
        start_date
        end_date
        max_amount
        description
        status
        number_of_days_left
      }
      contributions {
        id
        id_campaign
        transaction_id
        transaction_date
        registration_date
        transaction_amount
        transaction_status
        remark
        mark
        id_user
        is_remain_anonymous_amount
        is_remain_anonymous_name
        user_contributor_attributes {
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
            street_address
            locality
            region
            postal_code
            country
          }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
      }
      stats {
        number_of_contributors
        total_amount_contributions
        contribution_percentage
      }
      errors {
        param
        msg
      }
    }
  }
`
export const GET_USER_PARTICIPATION_QUERY = `query {
  getUserGainsAndRequestersDiscounts {
    status
    message
    gains_discounts{
      registration_date
      details
      type
      amount
    }
    errors{
      param
      msg
    }
  }
}
`


export const CONTACT_MONKOLIS_QUERY = `mutation($msg: MsgInput!) {
  sendMessageByContact(msg: $msg) {
    status
    message
    data {
      MessageId
    }
    errors{
      param
      msg
    }
  }
}
`