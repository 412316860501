/* eslint-disable */
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import Moment from 'moment';
import { withRouter, Redirect } from 'react-router-dom';
import Searchform from '../../components/searchform/searchform';
import { GET_OTHER_USER_DASHBOARD_QUERY, GET_TRVAEL_INFO_BY_RS_QUERY, GET_PREFERENCE_QUERY } from './../../query/queryprofil';
import { REPORT_USER_ABUSE_QUERY, REPORT_TRAVEL_ABUSE_QUERY } from './../../query/travelquery';
import { WRITE_TO_TRAVELER_QUERY } from '../../query/queryexpeditions';
import { TRAVEL_ACTIONS_URL, NOTIFICATION_URL, REPORT_TRAVEL_QUERY_URL, OPEN_DATA_URL, MESSAGE_EXPEDITIONS_URL, GET_TRAVEL_INFO_BY_RS_URL, USER_INFO_URL } from '../../Url';
import PHOTO from '../../assets/img/user/user0.png';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/search.css';
import './../../assets/css/modal.css';
import StarRatings from 'react-star-ratings';
import Modal from 'react-bootstrap/Modal';
import { ThemeConsumer } from 'styled-components';
import { FacebookShareButton, FacebookIcon } from "react-share";
import { Helmet } from "react-helmet";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import 'moment/min/locales';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import _ from 'lodash';
import { Rating } from '@material-ui/lab';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-173912597-2');
ReactGA.pageview(window.location.pathname + window.location.search);
class Traveldetails extends Component {
    constructor(props) {
        super(props);
        if (localStorage.getItem('tripdetail') !== null) {
            var tripdetail = localStorage.getItem('tripdetail')
            let decodde = new Buffer(tripdetail, 'base64');
            this.details = JSON.parse(decodde.toString('utf-8'));
            this.user_proposal_user_name = this.details.user_proposal_attributes.username
            //this.user_proposal_user_name = this.details.user_proposal_attributes.email
        }
        // if (this.props.location.state === null || this.props.location.state === undefined || this.props.location.state === 'null' || this.props.location.state === 'undefined' || this.props.location.state.pages ==='online_view'){

        // }else{
        //     if (localStorage.getItem('tripdetail') !== null ) {
        //         var tripdetail = localStorage.getItem('tripdetail')
        //         let decodde = new Buffer(tripdetail, 'base64');
        //         this.details = JSON.parse(decodde.toString('utf-8'));
        //         this.user_proposal_user_name = this.details.user_proposal_attributes.username
        //         //this.user_proposal_user_name = this.details.user_proposal_attributes.email
        //     }
        // }

        if (this.details !== undefined) {
            this.state = {
                id_travel: this.details.id_travel,
                email: this.details.email,
                arrival_city: this.details.arrival_city,
                departure_city: this.details.departure_city,
                departure_date: this.details.departure_date,
                arrival_date: this.details.arrival_date,
                booking_deadline: this.details.booking_deadline,
                /* departure_date: Moment(this.details.departure_date).format('LLLL'),
                arrival_date: Moment(this.details.arrival_date).format('LLLL'),
                booking_deadline: Moment(this.details.booking_deadline).format('LLLL'), */
                transport_company: this.details.transport_company,
                recovery_place: this.details.recovery_place,
                take_off_place: this.details.take_off_place,
                has_check_ticket: this.details.has_check_ticket,
                is_automatic_approval: this.details.is_automatic_approval,
                transportation_type: this.details.transportation_type,
                user_proposal_given_name: this.details.user_proposal_attributes.given_name,
                user_proposal_family_name: this.details.user_proposal_attributes.family_name,
                user_proposal_name: this.details.user_proposal_attributes.name,
                registration_date: this.details.registration_date,
                //registration_date: Moment(this.details.registration_date).format('LLLL'),
                picture: this.details.user_proposal_attributes.picture,
                max_weight_available: this.details.max_weight_available,
                weigth_available_info_weigth_available: this.details.weigth_available_info.weigth_available,
                proposal_amount_by_kg: this.details.proposal_amount_by_kg,
                min_weight_booking: this.details.min_weight_booking,
                measure_unit: this.details.measure_unit === undefined ? 'Unité' : this.details.measure_unit,
                travel_description: this.details.others_infos,
                service_charge_amount: this.details.service_charge_amount,
                account_type: this.details.user_proposal_attributes.account_type,
                company_name: this.details.user_proposal_attributes.account_type_details === null || this.details.user_proposal_attributes.account_type_details === undefined || this.details.user_proposal_attributes.account_type_details === 'null' || this.details.user_proposal_attributes.account_type_details === 'undefined' ? '' : this.details.user_proposal_attributes.account_type_details.company_name,
                among_reserved: 0,
                cgu_accepted: true,
                error: null,
                isLoaded: false,
                user_proposal_sub: this.details.id_user_proposal,
                showSendToModal: false,
                descriptionRS: '',
                type_info: '',
                specific_info: '',
                travel_id_depart: '',
                travel_id_return: '',

                //user_proposal_username: this.details.user_proposal_attributes.email,
                user_proposal_username: this.details.user_proposal_attributes.username,
                items_others_dashboard: {},

                locality: '',
                description: '',
                id_card_verified: '',
                phone_number_verified: '',
                email_verified: '',
                user_create_date: '',
                iat: '',
                total_of_travel: '',
                total_of_requester: '',
                mark5: '',
                mark4: '',
                mark3: '',
                mark2: '',
                mark1: '',
                evaluations: [],
                level: '',
                progress_percent: '',
                user_attributes: this.details.user_proposal_attributes,
                commentary: '',
                object: '',
                commentaryTravel: '',
                objetTravel: '',
                showReportModal: false,
                showReportTravelModal: false,
                showIdentiyCardVerified: false,
                tmpIdSub: '',
                copied: false,
                user_data: '',
                //Preferences
                enveloppes: true,
                sacsPleins: true,
                paquets: true,
                liquides: true,
                nourriture: true,
                stateAvis: 0,
                transportation_condition: "",
            };
        } else {
            this.state = {
                id_travel: '',
                email: '',
                arrival_city: '',
                departure_city: '',
                departure_date: '',
                arrival_date: '',
                booking_deadline: '',
                transport_company: '',
                recovery_place: '',
                take_off_place: '',
                has_check_ticket: '',
                is_automatic_approval: '',
                transportation_type: '',
                user_proposal_given_name: '',
                user_proposal_family_name: '',
                user_proposal_name: '',
                registration_date: '',
                picture: '',
                max_weight_available: '',
                weigth_available_info_weigth_available: '',
                proposal_amount_by_kg: '',
                min_weight_booking: '',
                measure_unit: '',
                travel_description: '',
                service_charge_amount: '',
                among_reserved: 0,
                cgu_accepted: true,
                error: null,
                isLoaded: false,
                user_proposal_sub: '',
                showSendToModal: '',
                descriptionRS: '',
                account_type: '',
                company_name: '',
                type_info: '',
                specific_info: '',
                travel_id_depart: '',
                travel_id_return: '',

                //user_proposal_username: this.details.user_proposal_attributes.email,
                user_proposal_username: '',
                items_others_dashboard: {},

                locality: '',
                description: '',
                id_card_verified: '',
                phone_number_verified: '',
                email_verified: '',
                user_create_date: '',
                iat: '',
                total_of_travel: '',
                total_of_requester: '',
                mark5: '',
                mark4: '',
                mark3: '',
                mark2: '',
                mark1: '',
                evaluations: [],
                level: '',
                progress_percent: '',
                user_attributes: '',
                commentary: '',
                object: '',
                commentaryTravel: '',
                objetTravel: '',
                showReportModal: false,
                showReportTravelModal: false,
                showIdentiyCardVerified: false,
                tmpIdSub: '',
                copied: false,
                user_data: '',
                //Preferences
                enveloppes: true,
                sacsPleins: true,
                paquets: true,
                liquides: true,
                nourriture: true,
                stateAvis: 0,
                transportation_condition: "",
            };
        }

        this.handleCloseReportModal = this.handleCloseReportModal.bind(this)
        this.handletTermsClick = this.handletTermsClick.bind(this);
        this.handleAmongReservedChange = this.handleAmongReservedChange.bind(this);
        this.handleAcceptCGU = this.handleAcceptCGU.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.handleCloseWritoModal = this.handleCloseWritoModal.bind(this);
        this.handleCommentary_change = this.handleCommentary_change.bind(this);
        this.handleObjectChange = this.handleObjectChange.bind(this);
        this.handleWriteToClick = this.handleWriteToClick.bind(this);
        this.handleReportAbuseUserClick = this.handleReportAbuseUserClick.bind(this);
        this.handleReportAbuseSubmit = this.handleReportAbuseSubmit.bind(this);

        //report travel
        this.handleReportTravelAbuseSubmit = this.handleReportTravelAbuseSubmit.bind(this);
        this.handleCommentaryTravelchange = this.handleCommentaryTravelchange.bind(this);
        this.handleObjectTravelChange = this.handleObjectTravelChange.bind(this);
        this.handleCloseReportTravelModal = this.handleCloseReportTravelModal.bind(this)
        this.handleReportAbuseTravelClick = this.handleReportAbuseTravelClick.bind(this)
        this.handleTravelBackClick = this.handleTravelBackClick.bind(this);

        //check Id card verified
        this.handleCloseCardVerifiedModal = this.handleCloseCardVerifiedModal.bind(this)
        this.handleAcceptCardNotVerified = this.handleAcceptCardNotVerified.bind(this)
    }

    handleTravelBackClick = id_travel => () => {
        this.props.history.push({
            pathname: '../traveldetails/' + id_travel
        });
        window.location.reload(false);
    };

    getTravelInfofromFB = async (query, variables) => {
        try {
            const response = await axios.post(GET_TRAVEL_INFO_BY_RS_URL, {
                query,
                variables
            });

            if (response.data.data.getTravelProposalInfoAlone.status === 200) {
                var tmp = JSON.stringify(response.data.data.getTravelProposalInfoAlone.travel_proposal)
                let buff = new Buffer(tmp);
                let base64data = buff.toString('base64');
                localStorage.setItem('tripdetail', base64data)

                this.setState(() => ({
                    arrival_city: response.data.data.getTravelProposalInfoAlone.travel_proposal.arrival_city,
                    arrival_date: response.data.data.getTravelProposalInfoAlone.travel_proposal.arrival_date,
                    departure_date: response.data.data.getTravelProposalInfoAlone.travel_proposal.departure_date,
                    booking_deadline: response.data.data.getTravelProposalInfoAlone.travel_proposal.booking_deadline,
                    departure_city: response.data.data.getTravelProposalInfoAlone.travel_proposal.departure_city,
                    proposal_amount_by_kg: response.data.data.getTravelProposalInfoAlone.travel_proposal.proposal_amount_by_kg,
                    recovery_place: response.data.data.getTravelProposalInfoAlone.travel_proposal.recovery_place,
                    registration_date: response.data.data.getTravelProposalInfoAlone.travel_proposal.registration_date,
                    has_check_ticket: response.data.data.getTravelProposalInfoAlone.travel_proposal.has_check_ticket,
                    take_off_place: response.data.data.getTravelProposalInfoAlone.travel_proposal.take_off_place,
                    family_name: response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.family_name,
                    given_name: response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.given_name,
                    account_type: response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type === null || response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type === undefined || response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type === 'null' || response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type === 'undefined' ? '' : response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type,
                    company_name: response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type_details === null || response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type_details === undefined || response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type_details === 'null' || response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type_details === 'undefined' ? '' : response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.account_type_details.company_name,
                    user_proposal_given_name: response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.given_name,
                    user_proposal_name: response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.name,
                    user_proposal_family_name: response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.family_name,
                    picture: response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.picture,
                    transport_company: response.data.data.getTravelProposalInfoAlone.travel_proposal.transport_company,
                    others_infos: response.data.data.getTravelProposalInfoAlone.travel_proposal.others_infos,
                    measure_unit: response.data.data.getTravelProposalInfoAlone.travel_proposal.measure_unit,
                    service_charge_amount: response.data.data.getTravelProposalInfoAlone.travel_proposal.service_charge_amount,
                    weigth_available_info_weigth_available: response.data.data.getTravelProposalInfoAlone.travel_proposal.weigth_available_info.weigth_available,
                    min_weight_booking: response.data.data.getTravelProposalInfoAlone.travel_proposal.min_weight_booking,
                    max_weight_available: response.data.data.getTravelProposalInfoAlone.travel_proposal.max_weight_available,
                    user_proposal_sub: response.data.data.getTravelProposalInfoAlone.travel_proposal.id_user_proposal,
                    user_proposal_username: response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.username,
                    transportation_condition: response.data.data.getTravelProposalInfoAlone.travel_proposal.transportation_condition,
                    descriptionRS: response.data.data.getTravelProposalInfoAlone.travel_proposal.departure_city + "---" + response.data.data.getTravelProposalInfoAlone.travel_proposal.arrival_city,
                    type_info: response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === null || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === undefined || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "null" || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "undefined" || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "" ? '' : response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info.type_info,
                    specific_info: response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === null || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === undefined || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "null" || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "undefined" || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "" ? '' : response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info.specific_info,
                    travel_id_depart: response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === null || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === undefined || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "null" || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "undefined" || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "" ? '' : response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info.travel_id_depart,
                    travel_id_return: response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === null || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === undefined || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "null" || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "undefined" || response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info === "" ? '' : response.data.data.getTravelProposalInfoAlone.travel_proposal.travel_type_info.travel_id_return,

                }));

                var VAR = JSON.stringify({
                    "userSub": response.data.data.getTravelProposalInfoAlone.travel_proposal.id_user_proposal,
                    "username": response.data.data.getTravelProposalInfoAlone.travel_proposal.user_proposal_attributes.username,

                });

                this.getOtherUserDashbordInfo(GET_OTHER_USER_DASHBOARD_QUERY, VAR)

            } else if (response.data.data.getTravelProposalInfoAlone.status === 400) {
                this.props.history.push({
                    pathname: '/error-404'
                });
            }



        } catch (error) {
            // If there's an error, set the error to the state

            this.setState(() => ({ error }))
        }
    }
    ///Id Card not verified notification

    handleAcceptCardNotVerified() {
        event.preventDefault();
        this.setState({
            showIdentiyCardVerified: !this.state.showIdentiyCardVerified,

        })
        localStorage.setItem('among_reserved', this.state.among_reserved);

        if (getCookieConsentValue('idToken') !== undefined) {
            this.props.history.push({
                pathname: '../payment'
            });
        }
        else {
            this.props.history.push({
                pathname: '../connexion'
            });
        }
    }

    handleCloseCardVerifiedModal() {
        this.setState(
            { showIdentiyCardVerified: false }
        )
    }


    //Id Card not verified notification


    handleReportAbuseUserClick(event, idSub) {
        event.preventDefault();
        this.setState({
            showReportModal: !this.state.showReportModal,
            tmpIdSub: idSub
        }
        )
    }



    handleReportAbuseUserClick(event, idSub) {
        event.preventDefault();
        this.setState({
            showReportModal: !this.state.showReportModal,
            tmpIdSub: idSub
        }
        )
    }

    handleReportAbuseTravelClick(event, idSub) {
        event.preventDefault();
        this.setState({
            showReportTravelModal: !this.state.showReportTravelModal,
            tmpIdSub: idSub
        }
        )
    }




    handleCloseReportModal() {
        this.setState(
            { showReportModal: false }
        )
    }

    handleCloseReportTravelModal() {
        this.setState(
            { showReportTravelModal: false }
        )
    }

    handleCloseWritoModal() {
        this.setState(
            { showSendToModal: false }
        )
    }

    handleObjectChange(event) {
        this.setState({
            object: event.target.value
        })
    }

    handleCommentary_change(event) {
        this.setState({ commentary: event.target.value })
    }

    handleCommentaryTravelchange(event) {
        this.setState({ commentaryTravel: event.target.value })
    }

    handleObjectTravelChange(event) {
        this.setState({ objetTravel: event.target.value })
    }
    componentWillMount() /*componentDidMount()*/ {
        window.scrollTo(0, 0);

        this.idToken = getCookieConsentValue('idToken')
        this.current_user_sub = getCookieConsentValue('userSub')
        //this.current_user_sub = sessionStorage.getItem('userSub')

        // if (this.details !== undefined) {
        //     var VAR = JSON.stringify({
        //         "userSub": this.state.user_proposal_sub,
        //         "username": this.state.user_proposal_username,

        //     });
        //     this.getOtherUserDashbordInfo(GET_OTHER_USER_DASHBOARD_QUERY, VAR)
        // }
        var VAR = JSON.stringify({ "id_travel": this.props.match.params.id_travel });
        this.getTravelInfofromFB(GET_TRVAEL_INFO_BY_RS_QUERY, VAR)
        //this.getPreferences(GET_PREFERENCE_QUERY, this.idToken)

    }



    getOtherUserDashbordInfo = async (query, variables) => {
        try {
            const response = await axios.post(OPEN_DATA_URL,
                { query, variables },
                // {
                //     'headers':
                //     {
                //         'Authorization': this.idToken
                //     }
                // }
            );
            // Set the data to the state



            if (response.data.data.getOtherUserDashboard.status === 200) {
                var tmp = JSON.stringify(response.data.data.getOtherUserDashboard.user_dashboard)
                let buff = new Buffer(tmp);
                let base64data = buff.toString('base64');
                localStorage.setItem('user_dahsboard_details', base64data)

                tmp = JSON.stringify(this.state.user_attributes)
                buff = new Buffer(tmp);
                base64data = buff.toString('base64');
                localStorage.setItem('user_attributes', base64data)

                this.setState(() => ({
                    locality: response.data.data.getOtherUserDashboard.user_dashboard.address !== null ? response.data.data.getOtherUserDashboard.user_dashboard.address.locality : '',
                    description: response.data.data.getOtherUserDashboard.user_dashboard.description !== null ? response.data.data.getOtherUserDashboard.user_dashboard.description : '',
                    id_card_verified: response.data.data.getOtherUserDashboard.user_dashboard.verifications.id_card_verified,
                    phone_number_verified: response.data.data.getOtherUserDashboard.user_dashboard.verifications.phone_number_verified,
                    email_verified: response.data.data.getOtherUserDashboard.user_dashboard.verifications.email_verified,
                    user_create_date: response.data.data.getOtherUserDashboard.user_dashboard.activities.user_create_date,
                    iat: response.data.data.getOtherUserDashboard.user_dashboard.activities.iat,
                    total_of_travel: response.data.data.getOtherUserDashboard.user_dashboard.activities.total_of_travel,
                    total_of_requester: response.data.data.getOtherUserDashboard.user_dashboard.activities.total_of_requester,
                    evaluations: response.data.data.getOtherUserDashboard.user_dashboard.evaluations_receive,
                    mark5: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '5') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '5').nbre : '0',
                    mark4: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '4') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '4').nbre : '0',
                    mark3: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '3') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '3').nbre : '0',
                    mark2: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '2') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '2').nbre : '0',
                    mark1: response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '1') !== undefined ? response.data.data.getOtherUserDashboard.user_dashboard.resume_evaluation_receive.find(item => item.marks === '1').nbre : '0',
                    progress_percent: response.data.data.getOtherUserDashboard.user_dashboard.activities.positifs_evaluation_receive_part,
                    level: response.data.data.getOtherUserDashboard.user_dashboard.activities.level_of_experience,
                    items_others_dashboard: response.data.data.getOtherUserDashboard.user_dashboard,

                    user_data: response.data.data.getOtherUserDashboard.user_dashboard,
                }));
                if (response.data.data.getOtherUserDashboard.user_dashboard.preferences === null || response.data.data.getOtherUserDashboard.user_dashboard.preferences === undefined || response.data.data.getOtherUserDashboard.user_dashboard.preferences === "null" || response.data.data.getOtherUserDashboard.user_dashboard.preferences === "undefined") {
                    this.setState({
                        enveloppes: true,
                        sacsPleins: true,
                        paquets: true,
                        liquides: true,
                        nourriture: true,
                    })
                } else {
                    this.setState({
                        enveloppes: response.data.data.getOtherUserDashboard.user_dashboard.preferences[0].pref_value === "Y" ? true : false,
                        sacsPleins: response.data.data.getOtherUserDashboard.user_dashboard.preferences[1].pref_value === "Y" ? true : false,
                        paquets: response.data.data.getOtherUserDashboard.user_dashboard.preferences[2].pref_value === "Y" ? true : false,
                        liquides: response.data.data.getOtherUserDashboard.user_dashboard.preferences[4].pref_value === "Y" ? true : false,
                        nourriture: response.data.data.getOtherUserDashboard.user_dashboard.preferences[3].pref_value === "Y" ? true : false,
                    })
                }

                /* this.state.sumNbre = _.sumBy(this.state.user_data.resume_evaluation_receive, 'nbre'),
                this.state.sumMarks = _.sum(_.map(this.state.user_data.resume_evaluation_receive,  v => Number(v.marks) * v.nbre))

                { this.state.sumNbre !== 0 ? this.state.stateAvis = (parseFloat(this.state.sumMarks / this.state.sumNbre)) : this.state.stateAvis = 0 } */

            }

        } catch (error) {
            // If there's an error, set the error to the state

            console.log(error)
            this.setState(() => ({ error }))
        }
    }

    handleShowWriteToModal(event, valueIdProposal, valueName) {
        event.preventDefault();
        this.setState({
            showSendToModal: !this.state.showSendToModal,
            tmp: valueIdProposal,
            nameTmp: valueName
        }
        )
    }

    handlePayment(event) {
        event.preventDefault();
        if (this.state.id_card_verified === 'true') {

            localStorage.setItem('among_reserved', this.state.among_reserved);

            this.setState({
                showIdentiyCardVerified: false
            })

            if (getCookieConsentValue('idToken') !== undefined) {
                this.props.history.push({
                    pathname: '../payment'
                });
            }
            else {
                this.props.history.push({
                    pathname: '../connexion'
                });
            }
        } else {
            this.setState({
                showIdentiyCardVerified: true
            })
            
        }


    }

    handleAcceptCGU() {
        this.setState({ cgu_accepted: !this.state.cgu_accepted })
    }

    handleAmongReservedChange(event) {
        this.setState({ among_reserved: event.target.value })
    }

    handletTermsClick(event) {
        event.preventDefault();
        window.open("/terms", '_blank')
    }

    handleWriteToClick(event) {
        event.preventDefault();
        if (this.state.commentary !== "") {
            var VAR = JSON.stringify({
                "notification": {
                    "id_proposal": this.state.tmp,
                    "msg_object": this.state.object,
                    "msg_content": this.state.commentary,
                    "type_notification": "MSG-WEB"
                }

            });
            this.writeToTravelerRequest(WRITE_TO_TRAVELER_QUERY, VAR)
        }

    }

    writeToTravelerRequest = async (query, variables) => {
        try {
            const response = await axios.post(TRAVEL_ACTIONS_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.notifiedProposalTravelByRequester.status === 200) {
                alert('Votre message à bien été envoyé au transporteur')
                window.location.reload(false);
            } else {
                alert('Echec lors de l\'envoi de votre message')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleReportAbuseSubmit(event) {
        event.preventDefault();
        if (this.state.commentary !== "") {
            var VAR = JSON.stringify({
                "make_report_on_user_notification": {
                    "report_object": this.state.object,
                    "report_content": this.state.commentary,
                    "type_notification": "MSG-WEB",
                    "receiver_username": this.user_proposal_user_name,
                    "receiver_id_user": this.state.tmpIdSub
                }

            });
            this.reportAbuseUser(REPORT_USER_ABUSE_QUERY, VAR)
        }

    }

    handleReportTravelAbuseSubmit(event) {
        event.preventDefault();
        if (this.state.commentaryTravel !== "") {
            var VAR = JSON.stringify({
                "make_report_on_travel_notification": {
                    "id_travel": this.state.id_travel,
                    "report_object": this.state.objetTravel,
                    "report_content": this.state.commentaryTravel,
                    "type_notification": "MSG-WEB",
                    "receiver_username": this.user_proposal_user_name,
                    "receiver_id_user": this.state.tmpIdSub
                }

            });
            this.reportAbuseTravel(REPORT_TRAVEL_ABUSE_QUERY, VAR)
        }

    }


    reportAbuseUser = async (query, variables) => {
        try {
            const response = await axios.post(NOTIFICATION_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            // Set the data to the state
            if (response.data.data.makeReportOnUser.status === 200) {
                alert('Votre message à bien été envoyé au administrateur')
                //window.location.reload(false);
            } else {
                alert('Echec lors de l\'envoi de votre message')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            alert('error in sending')
        }
    }


    reportAbuseTravel = async (query, variables) => {
        try {
            const response = await axios.post(REPORT_TRAVEL_QUERY_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            // Set the data to the state
            if (response.data.data.makeReportOnTravel.status === 200) {
                alert('Votre message à bien été envoyé au administrateur')
                window.location.reload(false);
            } else {
                alert('Echec lors de l\'envoi de votre message')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            alert('error in sending')
        }
    }


    /* getPreferences= async(query,idToken) => {
        try {
            const response = await axios.post(USER_INFO_URL,
                { query },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );

            // Log the response so we can look at it in the console
            // Set the data to the state
            
            console.log("test",response.data.data)
            if (response.data.data.getUserExtraParameters.status === 200){
                this.setState({
                    preferences: response.data.data.getUserExtraParameters.user_extra_parameters.preferences
                });
            }
            if (this.state.preferences.length !==0){
                this.setState({ 
                    enveloppes: this.state.preferences[0].pref_value ==="Y" ? true : false,
                    sacsPleins: this.state.preferences[1].pref_value ==="Y" ? true : false,
                    paquets: this.state.preferences[2].pref_value ==="Y" ? true : false,
                    liquides: this.state.preferences[4].pref_value ==="Y" ? true : false,
                    nourriture: this.state.preferences[3].pref_value ==="Y" ? true : false,
                });
            }
        } catch (error) {
            console.log(error)
        }
    }*/


    handleUserProfileClick = value => () => {
        this.props.history.push({
            pathname: '../user',

            data: this.state.items_others_dashboard,
            data_user_attributes: this.state.user_attributes
        });

    };


    render() {
        moment.locale('fr');
        let url = 'http://www.monkolis.com/traveldetails/' + this.state.id_travel

        let quote = this.props.quote !== undefined ? this.props.quote : "";
        let title = this.props.title !== undefined ? this.props.title : "Envoyez vos Kolis en toute sécurité";
        //let image = props.image !== undefined ? props.image : "https://storage.googleapis.com/cmperstribe_storage_usha/Banner/IMG_3640.JPG";


        let hashtag = "#monkolis";


        this.state.sumNbre = _.sumBy(this.state.user_data.resume_evaluation_receive, 'nbre'),
            this.state.sumMarks = _.sum(_.map(this.state.user_data.resume_evaluation_receive, v => Number(v.marks) * v.nbre))

        { this.state.sumNbre !== 0 ? this.state.stateAvis = (parseFloat(this.state.sumMarks / this.state.sumNbre)) : this.state.stateAvis = 0 }


        return (
            <Fragment>
                <div className="traveldetails">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Trajet : {this.state.id_travel} | {this.state.departure_city} {this.state.arrival_city} | MonKolis</title>
                        <meta name="robots" content="index,follow" />
                    </Helmet>
                    {/* <Helmet>
                        <title>{title}</title>
                        <meta charset="utf-8" />
                        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                        <meta name="csrf_token" content="" />
                        <meta property="type" content="website" />
                        {/* <meta property="url" content={url} /> */}
                    {/* <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                        <meta name="robots" content="noindex, follow" />
                        <meta name="msapplication-TileColor" content="#ffffff" />
                        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
                        <meta name="theme-color" content="#ffffff" />
                        <meta name="_token" content="" />
                        <meta name="robots" content="noindex, follow" />
                        <meta property="title" content={title} />
                        <meta property="quote" content={quote} />
                        <meta name="description" content={this.state.descriptionRS} />
                        <meta property="og:locale" content="en_US" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={title} />
                        <meta property="og:quote" content={quote} />
                        <meta property="og:hashtag" content={hashtag} /> */}
                    {/* <meta property="og:url" content={url} /> */}
                    {/* <meta property="og:description" content={this.state.descriptionRS} />
                    </Helmet>  */}

                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="pad-top10">
                                <div className="formulaire">
                                    <Searchform />
                                </div>

                                <div className="titres">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <h3 className="travel-depature mar-top0 row">
                                                    <span className="txt-bold">{this.state.departure_city}</span>&nbsp; &nbsp;
                                                    <i className="fa fa-exchange color-text2 color-blue"></i> &nbsp; &nbsp;
                                                    <span className="txt-bold"> {this.state.arrival_city}</span>

                                                    {this.state.transportation_condition !== null || this.state.transportation_condition !== undefined || this.state.transportation_condition !== "null" || this.state.transportation_condition !== "undefined" || this.state.transportation_condition !== "" ? this.state.transportation_condition === "Fret" ?
                                                        <span class="color-green d-none d-sm-inline"><i class="fa fa-ticket color-yellow"></i>&nbsp; Fret</span>
                                                        : <span className="color-green"> {this.state.has_check_ticket == "Y" ?
                                                            <i className="fa fa-ticket color-green"> Billet
                                                                vérifié </i> : /* this.state.has_check_ticket === "E" ? "Billet en cours de vérification" : */ ''}</span> : ""}
                                                </h3>
                                            </div>

                                            <div className="col-md-5">
                                                <p className="align-right">{"Trajet publié le " + Moment(this.state.registration_date).format('DD/MM/YYYY')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {/* debut information du voyage  */}
                                    <div className="col-md-8">
                                        <div className="describe section">
                                            <div className="section-inner">
                                                <div className="row mar-bot15">
                                                    <div className="col-lg-4">
                                                        <p className="mar-bot0 color-text3 txt-bold">Date de départ</p>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <p className="mar-bot0 txt-bold"><i className="fa fa-calendar "></i><span>&nbsp; {Moment(this.state.departure_date).format('DD/MM/YYYY')}</span></p>
                                                    </div>
                                                </div>
                                                <div className="row mar-bot15">
                                                    <div className="col-lg-4">
                                                        <p className="mar-bot0 color-text3">Fin des réservations</p>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <p className="mar-bot0"><i className="fa fa-clock-o color-orange"></i><span>&nbsp; {Moment(this.state.booking_deadline).format('DD/MM/YYYY LT')}</span></p>
                                                    </div>
                                                </div>
                                                <div className="row mar-bot15">
                                                    <div className="col-sm-4">
                                                        <p className="mar-bot0 color-text3">Moyen de transport</p>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        {this.state.transportation_type === null ?
                                                            <span className="fa fa-1x fa-plane icon"></span> :
                                                            null
                                                        }
                                                        {
                                                            this.state.transportation_type === "Avion" ?

                                                                <span className="fa fa-1x fa-plane icon"></span> :
                                                                null
                                                        }
                                                        {this.state.transportation_type === "Bâteau" ?
                                                            <span className="fa fa-1x fa-ship icon"></span> :
                                                            null
                                                        }
                                                        {this.state.transportation_type === "Voiture" ?
                                                            <span className="fa fa-1x fa-car icon"></span> :
                                                            null
                                                        }
                                                        {this.state.transportation_type === "Train" ?
                                                            <span className="fa fa-1x fa-train icon"></span> :
                                                            null
                                                        }
                                                        {
                                                            this.state.transportation_condition !== null || this.state.transportation_condition !== undefined || this.state.transportation_condition !== "null" || this.state.transportation_condition !== "undefined" || this.state.transportation_condition !== "" ?
                                                                this.state.transportation_condition === "Fret" ?
                                                                    <span> Fret </span> : <span> Voyage personnel </span> : ""
                                                        }
                                                        <span className="mar-bot0"><span>par {this.state.transportation_type}  avec {this.state.transport_company}</span></span>
                                                    </div>
                                                </div>

                                                <div className="row mar-bot15">
                                                    <div className="col-lg-4">
                                                        <p className="mar-bot0 color-text3 txt-bold">Date d'arrivée</p>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <p className="mar-bot0"><i className="fa fa-calendar"></i><span className="txt-bold">&nbsp; {Moment(this.state.arrival_date).format('LL')}</span></p>
                                                    </div>
                                                </div>

                                                <div className="row mar-bot15">
                                                    <div className="col-lg-4">
                                                        <p className="mar-bot0 color-text3">Lieu de dépôt</p>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <p className="mar-bot0"><i className="fa fa-map-marker color-green"></i><span>&nbsp; {this.state.take_off_place}</span></p>
                                                    </div>
                                                </div>
                                                <div className="row mar-bot15">
                                                    <div className="col-lg-4">
                                                        <p className="mar-bot0 color-text3">Lieu de livraison</p>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <p className="mar-bot0"><i className="fa fa-map-marker  color-blue"></i><span>&nbsp; {this.state.recovery_place}</span></p>
                                                    </div>
                                                </div>
                                                <div className="row mar-bot20">
                                                    <div className="col-lg-4">
                                                        <p className="mar-bot0 color-text3">Validation des réservations</p>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <p className="mar-bot0"><span>{this.state.is_automatic_approval == "Y" ? <i className="fa fa-flash color-yellow"> La confirmation de votre réservation est automatique</i> : 'La confirmation de votre expédition sera soumise à validation du voyageur'} </span></p>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.type_info === "one-way" || this.state.type_info === '' ? '' :
                                                        this.state.specific_info === "return" || this.state.specific_info === '' ? '' :
                                                            <div class="row mar-bot20">
                                                                <div class="col-sm-4">
                                                                    <p class="mar-bot0 color-text3 txt-bold">Trajet retour</p>
                                                                </div>
                                                                <div class="col-sm-8">
                                                                    <p class="mar-bot0 ">Je fais également le transport retour sur ce trajet {/* le <span class="txt-bold">&nbsp; mercredi 12 février 2018</span> */}</p>

                                                                    <a onClick={this.handleTravelBackClick(this.state.travel_id_return)} class="link color-blue"><i class="fa fa-long-arrow-right"></i>Voir les détails de mon trajet retour</a>
                                                                </div>
                                                            </div>
                                                }

                                                {/* <div className="row mar-bot20">
                                                {/* <div className="col-lg-4">
                                                    <p className="mar-bot0 color-text3">Copier le lien du trajet</p>
                                                </div>
                                                <div className="col-lg-8">
                                                     <CopyToClipboard text={url} onCopy={() => this.setState({ copied: true })} ><span className="fa fa-copy color-green"></span></CopyToClipboard>

                                                    this.state.copied ? <span style={{ color: 'red' }}>Copied.</span> : null
                                                </div>
                                            </div> */}


                                                {/* <div className="row mar-bot20">
                                                <div className="col-lg-4">
                                                    <p className="mar-bot0 color-text3 txt-bold">Voyage retour</p>
                                                </div>
                                                <div className="col-lg-8">
                                                    <p className="mar-bot0 "><i />Je fais également le voyage retour sur ce trajet le <span className="txt-bold"></span></p>

                                                    <a href="#" className="link color-blue"><i className="fa fa-long-arrow-right"></i>Voir mon retour</a>


                                                </div>
                                            </div>  */}


                                                <div className="row mar-bo30 mar-top10">
                                                    <div className="col-lg-4">
                                                        <p className="mar-bot0 color-text3">Préférences</p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="mar-bot15 txt-underline">Je prends</p>
                                                        {this.state.enveloppes === true ? <p className="mar-bot10"><i className="fa fa-1x fa-envelope color-blue"></i><span>&nbsp; Enveloppes</span></p> : ''}
                                                        {this.state.sacsPleins === true ? <p className="mar-bot10"><i className="fa fa-1x fa-suitcase color-blue"></i><span>&nbsp; Sacs pleins</span></p> : ''}
                                                        {this.state.paquets === true ? <p className="mar-bot0"><i className="fa fa-1x fa-cube color-blue"></i><span>&nbsp; Paquets</span></p> : ''}
                                                        {this.state.liquides === true ? <p className="mar-bot10"><i className="fa fa-1x fa-tint color-blue"></i><span>&nbsp; Liquides</span></p> : ''}
                                                        {this.state.nourriture === true ? <p className="mar-bot10"><i className="fa fa-1x fa-cutlery color-blue"></i><span>&nbsp; Nourriture</span></p> : ''}
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <p className="mar-bot15 txt-underline">Je ne prends pas</p>
                                                        {this.state.enveloppes === false ? <p className="mar-bot10"><i className="fa fa-1x fa-envelope color-red"></i><span>&nbsp; Enveloppes</span></p> : ''}
                                                        {this.state.sacsPleins === false ? <p className="mar-bot10"><i className="fa fa-1x fa-suitcase color-red"></i><span>&nbsp; Sacs pleins</span></p> : ''}
                                                        {this.state.paquets === false ? <p className="mar-bot0"><i className="fa fa-1x fa-cube color-red"></i><span>&nbsp; Paquets</span></p> : ''}
                                                        {this.state.liquides === false ? <p className="mar-bot10"><i className="fa fa-1x fa-tint color-red"></i><span>&nbsp; Liquides</span></p> : ''}
                                                        {this.state.nourriture === false ? <p className="mar-bot10"><i className="fa fa-1x fa-cutlery color-red"></i><span>&nbsp; Nourriture</span></p> : ''}
                                                    </div>
                                                </div>

                                                <div className="media mar-top20">
                                                    <div className="media-left ">
                                                        <div className="photo">
                                                            {this.state.picture === null || this.state.picture === 'null' ?
                                                                <img src={PHOTO} alt="Photo" className="media-object cover rounded" /> :
                                                                <img src={this.state.picture} alt="Photo" className="media-object cover rounded" />}
                                                        </div>
                                                        {

                                                            this.state.account_type === null || this.state.account_type === undefined || this.state.account_type === 'null' || this.state.account_type === 'undefined' || this.state.account_type === "Particulier" || this.state.account_type === "" ? '' :
                                                                <div>
                                                                    <span class="badge badge-danger">Pro.</span>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="media-body pad-left10">
                                                        {
                                                            this.state.account_type === null || this.state.account_type === undefined || this.state.account_type === 'null' || this.state.account_type === 'undefined' || this.state.account_type === "Particulier" || this.state.account_type === "" ?
                                                                <a onClick={this.handleUserProfileClick()} className="link color-blue"> <h6>{this.state.user_proposal_given_name === undefined || this.state.user_proposal_given_name === null || this.state.user_proposal_given_name === 'null' || this.state.user_proposal_given_name === 'undefined' || this.state.user_proposal_given_name === '' ? this.state.user_proposal_name : this.state.user_proposal_given_name}</h6></a>
                                                                :
                                                                <a onClick={this.handleUserProfileClick()} className="media-heading txt-bold">{this.state.company_name === null || this.state.company_name === undefined || this.state.company_name === 'undefined' || this.state.company_name === 'null' || this.state.company_name === '' ? this.state.name : this.state.company_name}  </a>
                                                        }

                                                        <p className="mar-bot10">{this.state.travel_description === '' ? '' : this.state.travel_description}</p>
                                                        {/*  <a type="btn" className="btn btn-theme2" href="#" data-toggle="modal" data-target="#modal-write" /*onClick={(e) => { this.handleShowWriteToModal(e, this.state.user_proposal_sub, this.state.user_proposal_given_name) }} >Ecrire au voyageur</a>  */}

                                                        <div className="modal fade" id="modal-write" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" >

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mar-top40 mar-bot0">
                                                    {
                                                        getCookieConsentValue('idToken') === null || getCookieConsentValue('idToken') === undefined || getCookieConsentValue('idToken') === 'null' || getCookieConsentValue('idToken') === 'undefined' ? '' : <a href="" className="link color-red" data-toggle="modal" data-target="#modal-signal-user" onClick={(e) => { this.handleReportAbuseTravelClick(e, this.state.user_proposal_sub) }}>Signaler ce trajet</a>
                                                    }

                                                    {/* <a href="#" className="link color-red" data-toggle="modal" data-target="#modal-signal-trip">Signaler ce trajet</a> */}
                                                    <div className="float-right">
                                                        <FacebookShareButton
                                                            url={url}
                                                            quote={this.state.departure_city + " ------> " + this.state.arrival_city + "\n" + "" + this.state.weigth_available_info_weigth_available + " " + this.state.measure_unit + " restant(s), depart prévu le " + Moment(this.state.departure_date).format('DD/MM/YYYY') + "   au prix de " + this.state.service_charge_amount + "€/" + this.state.measure_unit}
                                                            hashtag={hashtag}
                                                            description={this.state.descriptionRS}

                                                        >
                                                            <a type="btn" className="btn btn-facebook btn-block" ><i className="fa fa-facebook-square"></i> Partager sur facebook</a>
                                                        </FacebookShareButton> </div>

                                                    {/*  <div className="float-right"><FacebookShareButton url={url} hashtag="" className="Share">
                                                    <FacebookIcon size={36} /></FacebookShareButton></div> */}
                                                </div>
                                                <div className="modal fade" id="modal-signal-trip" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" >
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    {/* fin information du voyage  */}





                                    {/* DEbut information du Voyageur  */}
                                    <div className="col-md-4">
                                        <div className="paybox section">
                                            <div className="payhead">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <p className="align-left"><span className="color-text2">Par {this.state.measure_unit}</span></p>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h2 className="align-right"><span className=""> {parseFloat(this.state.service_charge_amount).toFixed(2)}</span>€</h2>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="paybody">
                                                <div className="progress mar-bot0 mar-top0 ">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-color-green" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: this.state.weigth_available_info_weigth_available === 'null' || this.state.weigth_available_info_weigth_available === undefined || 100 - (this.state.weigth_available_info_weigth_available * 100 / this.state.max_weight_available) < 20 ? "10%" : 100 - (this.state.weigth_available_info_weigth_available * 100 / this.state.max_weight_available) + "%" }}>
                                                        {this.state.weigth_available_info_weigth_available === 'null' ? "0%" : (100 - parseInt(this.state.weigth_available_info_weigth_available * 100 / this.state.max_weight_available))}%
                                                    </div>
                                                </div>
                                                <h5 className="mar-bot0 mar-top10 align-right"><span className="txt-bold">{this.state.weigth_available_info_weigth_available === 'null' ? 0 : this.state.weigth_available_info_weigth_available} </span><span className="color-text2 txt-bold"> {this.state.measure_unit}(s) restant(s)</span></h5>
                                            </div>
                                            <div className="payfoot">

                                                <p className="mar-bot0">Le minimum à reserver est de <span className="txt-bold">{parseInt(this.state.min_weight_booking)} </span><span className="txt-bold"> {this.state.measure_unit}(s)</span> </p>

                                                <form className="mar-top0 mar-bot10" onSubmit={this.handlePayment}>

                                                    <div className="form-group has-feedback">
                                                        <input type="number" className="form-control" placeholder={"Combien de " + this.state.measure_unit + " ?"} min={this.state.min_weight_booking} max={this.state.weigth_available_info_weigth_available} value={this.state.among_reserved} onChange={this.handleAmongReservedChange} />
                                                    </div>
                                                    {
                                                        this.state.user_proposal_sub === this.current_user_sub ?
                                                            <div className="mar-top10">
                                                                <input type="submit" className="btn btn-primary btn-lg btn-block" value="Réserver" /* disabled={true} */ />
                                                            </div>
                                                            :
                                                            <div>
                                                                <div>
                                                                    {/* this.state.id_card_verified === 'false' || this.state.id_card_verified === false || this.state.id_card_verified === '' || this.state.id_card_verified === null || this.state.id_card_verified === undefined || this.state.id_card_verified === 'null'  */
                                                                    this.state.id_card_verified === 'true'?
                                                                        '':<i className="color-red">Ce transporteur n'a pas fait vérifié sa pièce d'identité.</i> 
                                                                    }
                                                                </div>
                                                                <div className="mar-bot10 mar-top20">
                                                                    <input id="checkbox5" type="checkbox" className="magic-checkbox" onChange={this.handleAcceptCGU} />
                                                                    <label for="checkbox5">J'accepte les <a href="" className="link color-blue" onClick={this.handletTermsClick}>CGU</a>	</label>
                                                                </div>
                                                                <div className="mar-top10">
                                                                    <input type="submit" className="btn btn-primary btn-lg btn-block" value="Réserver" disabled={this.state.cgu_accepted} />
                                                                </div>
                                                            </div>
                                                    }


                                                </form>
                                            </div>
                                        </div>
                                        <div className="card mar-bot30 mar-top20">
                                            <div className="card-header">
                                                <h3 className="card-title">Transporteur</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="media mar-bot5">
                                                    <div className="media-left media-top">
                                                        <div className="photo">
                                                            {this.state.picture === null || this.state.picture === 'null' ?
                                                                <img src={PHOTO} alt="Photo" className="media-object cover rounded" /> :
                                                                <img src={this.state.picture} alt="Photo" className="media-object cover rounded" />}
                                                        </div>

                                                    </div>
                                                    <div className="media-body pad-left10">
                                                        {
                                                            this.state.account_type === null || this.state.account_type === undefined || this.state.account_type === 'null' || this.state.account_type === 'undefined' || this.state.account_type === "Particulier" || this.state.account_type === "" ?
                                                                <a onClick={this.handleUserProfileClick()} className="link color-blue"> <h5 className="media-heading">{this.state.user_proposal_given_name === undefined || this.state.user_proposal_given_name === null || this.state.user_proposal_given_name === 'null' || this.state.user_proposal_given_name === 'undefined' || this.state.user_proposal_given_name === '' ? this.state.user_proposal_name : this.state.user_proposal_given_name}</h5></a>
                                                                :
                                                                <a onClick={this.handleUserProfileClick()} className="media-heading txt-bold">{this.state.company_name === null || this.state.company_name === undefined || this.state.company_name === 'undefined' || this.state.company_name === 'null' || this.state.company_name === '' ? this.state.name : this.state.company_name}  </a>
                                                        }

                                                        <p className="color-text2">{this.state.locality}</p>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.account_type === null || this.state.account_type === undefined || this.state.account_type === 'null' || this.state.account_type === 'undefined' || this.state.account_type === "Particulier" || this.state.account_type === "" ? '' :
                                                        <div>
                                                            <span class="badge badge-danger">Professionnel</span>
                                                        </div>
                                                }

                                                <p class="mar-bot0">
                                                    <Rating
                                                        name="hover-feedback"
                                                        value={this.state.stateAvis}
                                                        precision={0.5}
                                                        readOnly
                                                    />

                                                    {/*  <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                <i class="fa fa-star-half-o color-yellow" aria-hidden="true" ></i> */}

                                                </p>
                                                <p><i>- {this.state.evaluations.length} avis</i></p>
                                                <hr />

                                                <ul class="fa-ul txt-bold color-green">
                                                    {this.state.id_card_verified === 'true' ?
                                                        <li><i className="fa-li fa fa-check-square-o"></i>Pièce d'identité</li> :
                                                        <li className="color-red"><i class="fa-li fa fa-close"></i>Pièce d'identité</li>

                                                    }

                                                    {this.state.phone_number_verified !== 'true' ?
                                                        <li className="color-red"><i class="fa-li fa fa-close"></i>Téléphone</li> :
                                                        <li><i className="fa-li fa fa-check-square-o"></i>Téléphone</li>
                                                    }

                                                    {this.state.email_verified !== 'true' ?
                                                        <li className="color-red"><i class="fa-li fa fa-close"></i>Email</li> :
                                                        <li><i className="fa-li fa fa-check-square-o"></i>Email</li>
                                                    }

                                                    {/* {this.state.ad ==='false' ?
                                            <li className="color-red"><i className="fa-li fa fa-close"></i><p>Adresse</p></li>:
                                            <li><i className="fa-li fa fa-check-square-o"></i><p>Adresse</p></li>
                                        } */}
                                                    {/* <li className="color-facebook"><i className="fa-li fa fa-facebook-square"></i><p className="color-text2">1640 Amis</p></li> */}


                                                </ul>
                                                <hr />

                                                <p className="mar-bot5">Membre depuis : {Moment(this.state.user_create_date).format('DD/MM/YYYY')} </p>
                                                <p className="mar-bot5">Nombre d'expéditions: {this.state.total_of_requester}</p>
                                                <p className="mar-bot5">Nombre de trajets: {this.state.total_of_travel}</p>

                                                <hr />

                                                <h3 className="mar-top10 mar-bot10 ">Avis reçus</h3>

                                                <div className="testimonials">

                                                    {this.state.evaluations.map((value, index) => {
                                                        return <div className="item" key={index} >

                                                            {
                                                                value.evaluation !== null ? <div>
                                                                    <div class="quote">
                                                                        {/* <StarRatings
                                                                    rating={value.evaluation.marks}
                                                                    starRatedColor="red"
                                                                    numberOfStars={5}
                                                                    name='rating'
                                                                    starDimension='20px'
                                                                    starSpacing='0,5px'
                                                                /> */}

                                                                        <Rating
                                                                            name="hover-feedback"
                                                                            value={value.evaluation.marks}
                                                                            precision={0.5}
                                                                            readOnly
                                                                        />

                                                                        <p>

                                                                            {value.evaluation.comment}</p>
                                                                    </div>
                                                                    <p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}, {Moment(value.evaluation.evaluation_date).format('MMM-YYYY')}</span></p>
                                                                </div>
                                                                    : <div>
                                                                        Vous n'avez pas laissé de commentaire
                                                                        <p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}</span></p>
                                                                    </div>
                                                            }

                                                        </div>
                                                    })}
                                                </div>

                                                {/* <a href="/user" /* onClick={this.handleUserProfileClick()  className="link color-blue"><i className="fa fa-long-arrow-right"></i> Voir le profil du voyageur</a> */}
                                                <a onClick={this.handleUserProfileClick()} className="link color-blue"><i className="fa fa-long-arrow-right"></i> Voir le profil du transporteur</a>


                                                <div className="mar-top40 mar-bot0">
                                                    {

                                                        this.user_proposal_user_name !== new getCookieConsentValue('email') ?
                                                            <a href="" className="link color-red" data-toggle="modal" data-target="#modal-signal-user" onClick={(e) => { this.handleReportAbuseUserClick(e, this.state.user_proposal_sub) }}>Signaler ce membre</a> :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* Fin information du Voyageur */}

                                    {/* <div className="modal fade" id="modal-signal-user" tabIndex="-1" role="dialog"
                                aria-labelledby="basicModal" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Signaler ce membre</h5>
                                            <button type="button" className="close" data-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form action="">
                                                <div className="form-group">
                                                    <label for="recipient-name" className="col-form-label">Objet</label>
                                                    <input className="form-control" id="exampleFormControlTextarea1" value={this.state.object} onChange={this.handleObjectChange} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlTextarea1">Laisser votre
                                                            message </label>
                                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Pourquoi voulez-vous signaler ce membre ?" onChange={this.handleCommentary_change}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-primary"
                                                data-dismiss="modal" onClick={this.handleCloseReportModal}>Annuler
                                                </button>
                                            <button type="button" className="btn btn-primary" onClick={(e) => { this.handleReportAbuseSubmit(e) }}>Valider </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}



                                    <Modal show={this.state.showReportModal} onHide={this.handleCloseReportModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Signaler ce membre!</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="form-group">
                                                <span class="">Décrire la raison.</span>
                                                <br />
                                                <br />
                                                <label for="exampleFormControlTextarea1">Objet du message</label>
                                                <input type="text" className="form-control" id="travelFirm" placeholder="merci de renseigner l'objet du message" value={this.state.object} onChange={this.handleObjectChange} required="true" />
                                                <label for="exampleFormControlTextarea1">Laisser un message</label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentary} onChange={this.handleCommentary_change}></textarea>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-outline-primary" onClick={this.handleCloseReportModal}>Annuler </button>
                                            <button className="btn btn-primary" onClick={(e) => { this.handleReportAbuseSubmit(e) }}> Valider </button>
                                        </Modal.Footer>

                                    </Modal>

                                    <Modal show={this.state.showReportTravelModal} onHide={this.handleCloseReportTravelModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Signaler ce Trajet</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="form-group">
                                                <span class="">Merci de préciser vos raison svp</span>
                                                <br />
                                                <br />
                                                <label for="exampleFormControlTextarea1">Objet du message</label>
                                                <input type="text" className="form-control" id="travelFirm" placeholder="merci de renseigner l'objet du message" value={this.state.objetTravel} onChange={this.handleObjectTravelChange} required="true" />
                                                <label for="exampleFormControlTextarea1">Laisser un message</label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.commentaryTravel} onChange={this.handleCommentaryTravelchange}></textarea>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-outline-primary" onClick={this.handleCloseReportTravelModal}>Annuler </button>
                                            <button className="btn btn-primary" onClick={(e) => { this.handleReportTravelAbuseSubmit(e) }}> Valider </button>
                                        </Modal.Footer>

                                    </Modal>

                                    <Modal show={this.state.showIdentiyCardVerified} onHide={this.handleCloseCardVerifiedModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title><p className="color-red">Alerte</p></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="form-group">
                                                <span class="">Ce transporteur n'a pas fait vérifié sa pièce d'identité, souhaitez-vous continuer?</span>
                                                <br />
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-outline-primary" onClick={this.handleCloseCardVerifiedModal}>Abandonner</button>
                                            <button className="btn btn-primary" onClick={this.handleAcceptCardNotVerified}>Continuer</button>
                                        </Modal.Footer>

                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        );
        //}
    }
}
export default withRouter(Traveldetails);