import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/login.css';
import './../../assets/css/modal.css';
import { withRouter } from 'react-router-dom'
import { PASSWORDLOST_QUERY } from '../../query/queryauthent';
import { PASSWORDLOST_URL } from '../../Url';

class PasswordLost extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			error: null,
			validated: false,
			msgAlertBody: '',
			msgAlertHeader: '',
			alertVariant: 'danger',
			showAlert: false,
			isLoaded: false,
			items: []
		};

		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCloseAlert = this.handleCloseAlert.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		else {
			this.setState(() => ({
				validated: true
			}));

			var VAR = JSON.stringify({
				"username": this.state.email
			})
			this.passwordLost(PASSWORDLOST_QUERY, VAR)

		}

	}

	handleCloseAlert(event) {
		this.setState({ showAlert: false });
	}

	handleEmailChange(event) {
		this.setState({ email: event.target.value });
	}

	passwordLost = async (query, variables) => {
		try {
			const response = await axios.post(PASSWORDLOST_URL, {
				query,
				variables
			});
			this.setState(() => ({
				isLoaded: true,
				items: response.data.data
			}));
			if (this.state.items.forgottenPasswordUser.status === 400) {
				if (this.state.items.forgottenPasswordUser.message === "Bad Request") {

					this.setState(() => ({
						showAlert: true,
						msgAlertBody: 'Verifiez svp le mail que vous avez entré',
						msgAlertHeader: 'Error',

					}));
				}
			    if (this.state.items.forgottenPasswordUser.message === "UserNotFoundException|UserNotFoundException|Username/client id combination not found.") {

					this.setState(() => ({
						showAlert: true,
						msgAlertBody: 'Utilisateur introuvable',
						msgAlertHeader: 'Echec',

					}));
				}

				if (this.state.items.forgottenPasswordUser.message === "InvalidParameterException|InvalidParameterException|Cannot reset password for the user as there is no registered/verified email or phone_number") {

					this.setState(() => ({
						showAlert: true,
						msgAlertBody: 'Email pas encore vérifié par l utilisateur, merci de verifier votre email ou contacter le service MonKolis svp',
						msgAlertHeader: 'Echec',

					}));
				}

			}
			else {
				if (this.state.items.forgottenPasswordUser.status === 200) {
					this.props.history.push('../passwordchange');
				}
			}

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	render() {
		return (
			<Fragment>

				<Helmet>
					<title>Mot de passe oublié | Monkolis</title>
					<meta name="robots" content="noindex,follow" />
				</Helmet>

				<div className="passwordlost">
					<div className="main-box_content">
						<div className="container-fluid container-fluid-pad container-fluid-br">
							<div className="row pad-top20 pad-bot20">

								<div className="col-lg-6 col-md-8 col-sm-8 col-12 d-center">
									<div className="section">
										<div className="section-inner">
											<h3 className="heading">Demander un nouveau mot de passe</h3>

											{this.state.showAlert ?
												<Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert} dismissible>
													<Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
													<p>{this.state.msgAlertBody} </p>
												</Alert> : ''}

											<p>Saisissez votre e-mail. Nous vous enverrons un lien qui vous permettra de changer de mot de passe </p>
											<Form className="well" validated={this.state.validated} onSubmit={this.handleSubmit}>

												<Form.Group controlId="validationCustom01">
													<Form.Control
														required
														type="email"
														placeholder="Email"
														defaultValue={this.state.email}
														onChange={this.handleEmailChange}
													/>
													<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
												</Form.Group>


												<input type="submit" className="btn btn-primary btn-block" value="Validation" />

											</Form>


										</div>
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>
			</Fragment>
		);
	}
}
export default withRouter(PasswordLost);