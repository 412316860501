import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Iframe from 'react-iframe';

class VideoTuto extends Component {
    constructor(props) {
        super(props);

    }


    render() {
        return (
            <Fragment>
                <div className="how">
                    <div className="section">
                        <div className="section-inner">
                            <h3 className="heading">Accédez à tous nos tutos</h3>
                            <div className="card-deck">
                                <div className="card">
                                    <div className="card-header embed-responsive embed-responsive-16by9">
                                        <Iframe url="https://www.youtube.com/embed/TpR3DNDjyUU"
                                            className="embed-responsive-item"
                                            allow="fullscreen"
                                        />
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title">Je transporte, comment faire pour créer mon trajet ?</h6>
                                        <p className="card-text"><small className="text-muted"><span className="fa fa-clock-o"></span> Durée : 4 minute 06</small></p>

                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header embed-responsive embed-responsive-16by9">
                                        <Iframe url="https://www.youtube.com/embed/YDaaluW76gQ"
                                            className="embed-responsive-item"
                                            allow="fullscreen"
                                        />
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title">Je souhaite expédier un colis. Comment trouver des transporteurs ?</h6>
                                        <p className="card-text"><small className="text-muted"><span className="fa fa-clock-o"></span> Durée : 2 minute 48</small></p>

                                    </div>
                                </div>


                            </div>

                            <div className="card-deck">
                                <div className="card">
                                    <div className="card-header embed-responsive embed-responsive-16by9">
                                        <Iframe url="https://www.youtube.com/embed/wGdnsObxXRQ"
                                            className="embed-responsive-item"
                                            allow="fullscreen"
                                        />
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title">Comment créer un compte et faire valider mon profil ?</h6>
                                        <p className="card-text"><small className="text-muted"><span className="fa fa-clock-o"></span> Durée : 2 minute 33</small></p>

                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header embed-responsive embed-responsive-16by9">
                                        <Iframe url="https://www.youtube.com/embed/hFyR_MFkdp8"
                                            className="embed-responsive-item"
                                            allow="fullscreen"
                                        />
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title">Code de dépôt & Code de livraison ? qu'est-ce que c'est ?</h6>
                                        <p className="card-text"><small className="text-muted"><span className="fa fa-clock-o"></span> Durée : 2 minute 29</small></p>

                                    </div>
                                </div>
                            </div>

                            <div className="card-deck">
                            <div className="card">
                                    <div className="card-header embed-responsive embed-responsive-16by9">
                                        <Iframe url="https://www.youtube.com/embed/1Xk1jKZGIEA"
                                            className="embed-responsive-item"
                                            allow="fullscreen"
                                        />
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title">Comment partager son trajet sur Facebook ?</h6>
                                        <p className="card-text"><small className="text-muted"><span className="fa fa-clock-o"></span> Durée : 1 minute 13</small></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}
export default withRouter(VideoTuto);