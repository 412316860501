export const MODIFY_PICTURE_QUERY = `mutation($file: File!) {
    updatePictureUser(file: $file) {
      status
      operation
      message
      file_path_details {
        ETag
        VersionId
        Location
        key
        Key
        Bucket
      }
      errors{
        param
        msg
      }
    }
  }
  `

export const UPDATE_USER_INFORMATION_QUERY = `mutation($user_attributes: [UserAttributesInput]!) {
  updateInfosUser(user_attributes: $user_attributes) {
    status
    operation
    message
    errors{
      param
      msg
    }
  }
}
`

export const CLOSE_ACCOUNT = `mutation($closure_reason: String!) {
  deleteUser(closure_reason: $closure_reason) {
    status
    operation
    message
    errors{
      param
      msg
    }
  }
}
  `
export const GET_PREFERENCE_QUERY = `query {
    getUserExtraParameters {
      status
      message
      user_extra_parameters{
        id
        preferences{
          pref_name
          pref_type
          pref_value
        }
      }
      errors{
        param
        msg
      }
    }
  }
  `

export const CHANGE_USER_PASSWORD_QUERY = `mutation($old_password: String!, $proposed_password: String!) {
  changePasswordUser(old_password:$old_password, proposed_password:$proposed_password ) {
    status
    operation
    message
    errors{
      param
      msg
    }
  }
}
`

export const UPDATE_PREFERENCE_QUERY = `mutation($user_extra_parameters: UserExtraParametersInput!) {
    updateUserExtraParameters(user_extra_parameters: $user_extra_parameters) {
      status
      message
      user_extra_parameters{
        id
        preferences{
          pref_name
          pref_type
          pref_value
        }
      }
      errors{
        param
        msg
      }
    }
  }
  `

export const GET_BANK_ACCOUNT_QUERY = `query {
    getUserExtraParameters {
      status
      message
      user_extra_parameters{
        id
        banks_details{
          bank_name
          iban
          bic_or_swift
          titulaire
          pays_domiciliation
          status
          last_update_date
        }
      }
      errors{
        param
        msg
      }
    }
  }
  `

export const UPDATE_BANK_INFOS_QUERY = `mutation($user_extra_parameters: UserExtraParametersInput!) {
  updateUserExtraParameters(user_extra_parameters: $user_extra_parameters) {
    status
    message
    user_extra_parameters{
      id
      banks_details{
        bank_name
        iban
        bic_or_swift
        titulaire
        pays_domiciliation
        status
        last_update_date
      }
    }
    errors{
      param
      msg
    }
  }
}
`
export const GET_EVALUATION_DATA_QUERY = `query {
  getEvaluationsSendReceivedAndWaitingByUser {
   status
   message
   evaluations_send_receive_waiting {
     evaluations_waiting{
       id_proposal
       user_role
       registration_date
       weight_reserved
       user_attributes{
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
             street_address
             locality
             region
             postal_code
             country
          }
          account_type
          account_type_details {
              registration_number
              company_name
              company_country_name
          }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
       travel_infos{
        departure_date
        departure_city
        arrival_city
       }
       evaluation{
        evaluation_date
        marks
        comment
       }
     }
     evaluations_send{
       id_proposal
       user_role
       registration_date
       weight_reserved
       user_attributes{
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
             street_address
             locality
             region
             postal_code
             country
          }
          account_type
          account_type_details {
              registration_number
              company_name
              company_country_name
          }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
       travel_infos{
        departure_date
        departure_city
        arrival_city
       }
       evaluation{
        evaluation_date
        marks
        comment
       }
     }
     evaluations_receive{
       id_proposal
       user_role
       registration_date
       weight_reserved
       user_attributes{
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
             street_address
             locality
             region
             postal_code
             country
          }
          account_type
          account_type_details {
              registration_number
              company_name
              company_country_name
          }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
       travel_infos{
        departure_date
        departure_city
        arrival_city
       }
       evaluation{
        evaluation_date
        marks
        comment
       }
     }
   }
   errors{
     param
     msg
   }
  }
}
`
export const GET_DASHBOARD_INFOS_QUERY = `query {
  getUserDashboard {
    status
    message
    user_dashboard {
      description
      address {
        street_address
        locality
        region
        postal_code
        country
      }
      verifications {
        id_card_verified
        phone_number_verified
        email_verified
      }
      activities {
        user_create_date
        user_last_modified_date
        seniority_months
        nbre_evaluation_receive
        nbre_positifs_evaluation_receive
        positifs_evaluation_receive_part
        level_of_experience
        iat
        exp
        total_of_travel
        total_of_requester
      }
      resume_evaluation_receive {
        marks
        nbre
      }
      evaluations_receive {
        id_proposal
        user_role
        registration_date
        weight_reserved
        user_attributes {
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address {
            street_address
            locality
            region
            postal_code
            country
          }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
        }
        travel_infos {
          departure_date
          departure_city
          arrival_city
        }
        evaluation {
          evaluation_date
          marks
          comment
        }
      }
    }
    errors {
      param
      msg
    }
  }
}
`

export const GET_VERIFICATIONS_INFOS_QUERY = `query {
  getUserDashboard {
    status
    message
    user_dashboard {
      verifications {
        id_card_verified
        phone_number_verified
        email_verified
      }
    }
    errors {
      param
      msg
    }
  }
}
`

export const AVIS_RECU_QUERY = `query {
  getEvaluationsSendReceivedAndWaitingByUser {
   status
   message
   evaluations_send_receive_waiting {
     evaluations_waiting{
       id_proposal
       user_role
       registration_date
       weight_reserved
       user_attributes{
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
             street_address
             locality
             region
             postal_code
             country
          }
          account_type
          account_type_details {
            registration_number
            company_name
            company_country_name
           }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
       travel_infos{
        departure_date
        departure_city
        arrival_city
       }
       evaluation{
        evaluation_date
        marks
        comment
       }
     }
     evaluations_send{
       id_proposal
       user_role
       registration_date
       weight_reserved
       user_attributes{
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
             street_address
             locality
             region
             postal_code
             country
          }
          account_type
          account_type_details {
            registration_number
            company_name
            company_country_name
           }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
       travel_infos{
        departure_date
        departure_city
        arrival_city
       }
       evaluation{
        evaluation_date
        marks
        comment
       }
     }
     evaluations_receive{
       id_proposal
       user_role
       registration_date
       weight_reserved
       user_attributes{
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address{
             street_address
             locality
             region
             postal_code
             country
          }
          account_type
          account_type_details {
            registration_number
            company_name
            company_country_name
           }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
       }
       travel_infos{
        departure_date
        departure_city
        arrival_city
       }
       evaluation{
        evaluation_date
        marks
        comment
       }
     }
   }
   errors{
     param
     msg
   }
  }
}
`


export const MESSAGES_VOYAGES_QUERY = `query {
  getRequesterProposalsForUserTraveler {
  status
  message
  requester_proposals {
    id_proposal
    id_user_requester
    id_user_proposal
    id_travel
    transaction_id
    transaction_amount
    transaction_date
    transaction_status
    registration_date
    weight_reserved
    estimated_amount_value
    town
    additional_infos
    request_status
    last_date_response
    response_comment
    receiver_infos {
      firstname
      lastname
      email
      contacts {
        contact_type
        contact_value
      }
    }
    traveler_evaluation {
      evaluation_date
      marks
      comment
    }
    requester_evaluation {
      evaluation_date
      marks
      comment
    }
    notifications  {
      msg_id
      msg_date
      msg_object
      msg_content
      type_notification
      status
      expeditor_id_user
      receiver_id_user
    }
    requester_cancel_info {
      requester_cancel_date
      comment
      reimbursment_date
      reimbursment_amount
    }
    user_requester_attributes {
      email_verified
      name
      family_name
      given_name
      picture
      birthdate
      email
      gender
      address{
        street_address
        locality
        region
        postal_code
        country
      }
      account_type
      account_type_details {
          registration_number
          company_name
          company_country_name
      }
      phone_number
      city_of_residence
      additional_infos
      description
      last_update_date
   }
   user_traveler_attributes {
      email_verified
      name
      family_name
      given_name
      picture
      birthdate
      email
      gender
      address{
        street_address
        locality
        region
        postal_code
        country
      }
      account_type
      account_type_details {
          registration_number
          company_name
          company_country_name
      }
      phone_number
      city_of_residence
      additional_infos
      description
      last_update_date
   }
   travel_infos {
     departure_date
     departure_city
     arrival_city
   }
   package_received_delivered_infos {
     received_code
     received_code_setup_date
     received_code_validation_date
     delivered_code
     delivered_code_setup_date
     delivered_code_validation_date
   }
  }
  errors{
    param
    msg
  }
 }
}
`


export const MESSAGES_EXPEDITION_QUERY = `query {
  getRequesterProposalsForUserRequester {
  status
  message
  requester_proposals {
    id_proposal
    id_user_requester
    id_user_proposal
    id_travel
    transaction_id
    transaction_amount
    transaction_date
    transaction_status
    registration_date
    weight_reserved
    estimated_amount_value
    town
    additional_infos
    request_status
    last_date_response
    response_comment
    receiver_infos {
      firstname
      lastname
      email
      contacts {
        contact_type
        contact_value
      }
    }
    traveler_evaluation {
      evaluation_date
      marks
      comment
    }
    requester_evaluation {
      evaluation_date
      marks
      comment
    }
    notifications  {
      msg_id
      msg_date
      msg_object
      msg_content
      type_notification
      status
      expeditor_id_user
      receiver_id_user
    }
    requester_cancel_info {
      requester_cancel_date
      comment
      reimbursment_date
      reimbursment_amount
    }
    user_requester_attributes {
      email_verified
      account_type
      account_type_details {
          registration_number
          company_name
          company_country_name
      }
      name
      family_name
      given_name
      picture
      birthdate
      email
      gender
      address{
        street_address
        locality
        region
        postal_code
        country
      }
      phone_number
      city_of_residence
      additional_infos
      description
      last_update_date
   }
   user_traveler_attributes {
      email_verified
      name
      family_name
      given_name
      picture
      birthdate
      email
      gender
      address{
        street_address
        locality
        region
        postal_code
        country
      }
      phone_number
      account_type
      account_type_details {
          registration_number
          company_name
          company_country_name
      }
      city_of_residence
      additional_infos
      description
      last_update_date
   }
   travel_infos {
     departure_date
     departure_city
     arrival_city
   }
   package_received_delivered_infos {
     received_code
     received_code_setup_date
     received_code_validation_date
     delivered_code
     delivered_code_setup_date
     delivered_code_validation_date
   }
  }
  errors{
    param
    msg
  }
 }
}
`


export const GET_CODEVALIDATION_QUERY = `query($attribute_name: String!) {
  getUserAttributeVerificationCode(attribute_name: $attribute_name){
    status
    message
    codeDeliveryDetails{
      Destination
      DeliveryMedium
      AttributeName
    }
    errors{
      param
      msg
    }
  }
}
`


export const VERIFY_NUMBER_QUERY = `mutation($attribute_name: String! ,$code: String!) {
  verifyUserAttribute(attribute_name: $attribute_name, code: $code){
    status
    message
    operation
    message
    errors{
      param
      msg
    }
  }
}
`

export const UPLOAD_IDENTITY_QUERY = `mutation($file: File!) {
  uploadIdCardUser(file: $file) {
    status
    operation
    message
    file_path_details {
      ETag
      VersionId
      Location
      key
      Key
      Bucket
    }
    errors{
      param
      msg
    }
  }
}`

export const GET_OTHER_USER_DASHBOARD_QUERY = `query($username: String!,$userSub: String!) {
  getOtherUserDashboard(username:$username, userSub:$userSub) {
    status
    message
    user_dashboard {
      description
      address {
        street_address
        locality
        region
        postal_code
        country
      }
      verifications {
        id_card_verified
        phone_number_verified
        email_verified
      }
      activities {
        user_create_date
        user_last_modified_date
        seniority_months
        nbre_evaluation_receive
        nbre_positifs_evaluation_receive
        positifs_evaluation_receive_part
        level_of_experience
        iat
        exp
        total_of_travel
        total_of_requester
      }
      preferences {
        pref_name
        pref_type
        pref_value
      }
      resume_evaluation_receive {
        marks
        nbre
      }
      evaluations_receive {
        id_proposal
        user_role
        registration_date
        weight_reserved
        user_attributes {
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          email
          gender
          address {
            street_address
            locality
            region
            postal_code
            country
          }
          phone_number
          city_of_residence
          additional_infos
          description
          last_update_date
        }
        travel_infos {
          departure_date
          departure_city
          arrival_city
        }
        evaluation {
          evaluation_date
          marks
          comment
        }
      }
    }
    errors {
      param
      msg
    }
  }
}
`



export const GET_MONEY_TRANSFERT_HISTORY_QUERY = `query {
  getUserMoneyTransferHistory {
    status
    message
    user_money_transfer_history {
      id_travel
      travel_infos {
        departure_date
        departure_city
        arrival_city
        measure_unit
      }
      total_weight_reserved
      total_service_charge_amount
      total_estimated_amount_value
      total_contrib_cwf_discount
    }
    errors {
      param
      msg
    }
  }
}
`


export const GET_PAYEMENT_MADE_QUERY = `query {
  getUserRequestersProposals(booking_time: "", limit: 10) {
    status
    message
    requester_proposals {
      id_proposal
      id_user_requester
      id_user_proposal
      id_travel
      transaction_id
      transaction_amount
      transaction_date
      transaction_status
      registration_date
      weight_reserved
      estimated_amount_value
      service_charge_amount
      town
      additional_infos
      request_status
      last_date_response
      response_comment
      receiver_infos {
        firstname
        lastname
        email
        contacts {
          contact_type
          contact_value
        }
      }
      traveler_evaluation {
        evaluation_date
        marks
        comment
      }
      requester_evaluation {
        evaluation_date
        marks
        comment
      }
      notifications  {
        msg_id
        msg_date
        msg_object
        msg_content
        type_notification
        status
        expeditor_id_user
        receiver_id_user
      }
      requester_cancel_info {
        requester_cancel_date
        comment
        reimbursment_date
        reimbursment_amount
      }
      user_requester_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     user_traveler_attributes {
        email_verified
        name
        family_name
        given_name
        picture
        birthdate
        email
        gender
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        phone_number
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_infos {
       departure_date
       departure_city
       arrival_city
       measure_unit
     }
     package_received_delivered_infos {
       received_code
       received_code_setup_date
       received_code_validation_date
       delivered_code
       delivered_code_setup_date
       delivered_code_validation_date
     }
    }
    errors{
      param
      msg
    }
  }
}
`

export const GET_AVAILABLE_MONEY_QUERY = `query {
  getUserAvailablesMoney {
    status
    message
    user_availables_money {
      id_travel
      travel_infos {
        departure_date
        departure_city
        arrival_city
        measure_unit
      }
      total_weight_reserved
      total_service_charge_amount
      total_estimated_amount_value
      total_contrib_cwf_discount
    }
    errors {
      param
      msg
    }
  }
}
`


export const GET_USER_NOTIFICATIONS_PREFERENCES_QUERY = `query {
  getUserExtraParameters {
    status
    message
    user_extra_parameters{
      id
      preferences_notifications {
        messages {
          pref_name
          pref_type
          pref_value
        }
        reminder_promos_tips {
          pref_name
          pref_type
          pref_value
        }
        politics_and_community {
          pref_name
          pref_type
          pref_value
        }
      }
    }
    errors{
      param
      msg
    }
  }
}
`
export const MODIFY_USER_NOTIFCATIONS_PREFERENCES_QUERY = `mutation($user_extra_parameters: UserExtraParametersInput!) {
  updateUserExtraParameters(user_extra_parameters: $user_extra_parameters) {
    status
    message
    user_extra_parameters{
      id
      preferences_notifications {
        messages {
          pref_name
          pref_type
          pref_value
        }
        reminder_promos_tips {
          pref_name
          pref_type
          pref_value
        }
        politics_and_community {
          pref_name
          pref_type
          pref_value
        }
      }
    }
    errors{
      param
      msg
    }
  }
}
`


export const GET_TRVAEL_INFO_BY_RS_QUERY = `query($id_travel: String!) {
  getTravelProposalInfoAlone(id_travel: $id_travel) {
    status
    message
    travel_proposal {
      id_travel
      id_user_proposal
      registration_date
      departure_date
      arrival_date
      departure_city
      arrival_city
      has_check_ticket
      max_weight_available
      min_weight_booking
      proposal_amount_by_kg
      measure_unit
      volume
      booking_deadline
      service_charge_amount
      is_automatic_approval
      transport_company
      transportation_type
      transportation_condition
      others_infos
      take_off_place
      recovery_place
      status
      ticket_url
      weigth_available_info {
        weigth_available
        last_update_date
      }
      traveler_cancel_info {
        traveler_cancel_date
        send_mails_to_requesters
        comment
      }
      system_traveler_reimbursment_info {
        date
        reimbursment_date
        reimbursment_amount
        status
      }
      user_proposal_attributes {
        email_verified
        account_type
        account_type_details {
          registration_number
          company_name
          company_country_name
        }
        username
        phone_number
        name
        family_name
        given_name
        picture
        birthdate
        address{
          street_address
          locality
          region
          postal_code
          country
        }
        email
        gender
        city_of_residence
        additional_infos
        description
        last_update_date
     }
     travel_type_info {
        type_info
        specific_info
        travel_id_depart
        travel_id_return
     }
    }
    errors{
      param
      msg
    }
  }
}`
