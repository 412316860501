
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { GET_PAYEMENT_MADE_QUERY } from  '../../../query/queryprofil';
import { GET_PAYEMENTS_MADE_URL } from '../../../Url';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Moment from "moment";
import DataTable from 'react-data-table-component';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';


class MyPaymentMade extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			datas: [],

		};
	}
	componentDidMount() {
		
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
        this.getPayementsMade(GET_PAYEMENT_MADE_QUERY)
	}
	
	getType(request_status){
		if(request_status  ==='VALIDATED' || request_status ==='PACKAGE_RECEIVED' || request_status  ==='DELIVERED'){
			return 'Achat expédition'
		}
		else if (request_status ==='CANCELED_BY_REQUESTER'){
			return 'Expédition annulée'
		}
		else{
			return ''
		}
	}

	getPayementsMade = async (query) => {
        try {
            const response = await axios.post(GET_PAYEMENTS_MADE_URL,
                { query },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            this.setState(() => ({
                isLoaded: true,
                items: response.data.data.getUserRequestersProposals.requester_proposals
			}));
			
		

			const tmp = [];
			response.data.data.getUserRequestersProposals.requester_proposals.forEach(element => {
                tmp.push({
                    transaction_date: Moment(element.transaction_date).format('DD/MM/YYYY'),
					details : element.travel_infos.departure_city+"--->"+element.travel_infos.arrival_city+"("+Moment(element.travel_infos.departure_date).format('DD/MM/YYYY')+")",
					type: this.getType(element.request_status),
					estimated_amount_value : element.estimated_amount_value === "0" ?  0 : element.estimated_amount_value,
                });
			});
			this.setState({
				datas: tmp,
			})

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }


	render() {
        if (this.state.items.length === 0) {
            return (
                <div className="col-sm-15">
                    <aside className="aside section testimonials">
                        <div className="section-inner">
                            <h3 className="heading">Paiements effectués</h3>
                            <div className="content">
								<p>	Pour la phase de lancement de la plateforme MonKolis, le règlement des transactions s'effectue en direct entre l'expéditeur et le transporteur.</p>
								{/*} <p className="mar-bot30">Vous retrouvez ici tous vos paiements effectués sur MonKolis (réservations acceptées automatiquement ou manuellement) ainsi que vos remboursements en cours ou effectués </p>
                                <div className="testimonials"></div>
                                <div>VOTRE HISTORIQUE EST VIDE</div>*/}
                            </div>
                        </div>
                    </aside>
                </div >
            )
		}
		else{
			const columns = [
				{
					name: 'Date',
					selector: 'transaction_date',
					sortable: true,
				},
				{
					name: 'Details',
					selector: 'details',
					sortable: true,
				},  
				{
					name: 'Type',
					selector: 'type',
					sortable: true,
				},  
				{
					name: 'Montant',
					selector: 'estimated_amount_value',
					sortable: true,
				},
			];
		return (<div>
			<div class="col-sm-12">

				<div class="aside section">
					<div class="section-inner">
						<h3 class="heading">Paiements effectués</h3>
						<p>	Pour la phase de lancement de la plateforme MonKolis, le règlement des transactions s'effectue en direct entre l'expéditeur et le transporteur.</p>

						<div class="table-responsive-sm">
						<DataTable
								title="Liste des expéditions"
								columns={columns}
								data={this.state.datas}
								pagination
								selectableRowsComponent
						
							/>

						</div>
					
				
					</div>
				</div>



			</div>
		</div>);
	}
}
}
export default withRouter(MyPaymentMade);