import axios from 'axios';
import { TRAVEL_PROPOSAL_QUERY, DELETE_TRAVEL_QUERY } from '../../../query/travelquery';
import { TRAVEL_URL } from '../../../Url';
import { TRAVEL_ACTIONS_URL, SECURE_COOKIES } from '../../../Url';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import { GET_TRAVEL_RESERVATION_LIST} from '../../../query/travelquery';
import Moment from "moment"
import UpdateTrip from "./updatetrip"
import ReactPaginate from 'react-paginate';
import ListReservation from './listreservation'
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import 'moment/min/locales'

import { FacebookShareButton, FacebookIcon } from "react-share";
import { Helmet } from "react-helmet";
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import React, { Component, Fragment } from 'react';
class MyTripsInProgress extends Component {
    constructor(props) {
        super(props);
        this.nbeElementOfPage =3
        this.offsetInitial = 0
        this.state = {
            isLoaded: false,
            travels: [],
            deleteData:[], 
            renderUpdate: false,
            renderListReservation: false,
            stateUpdate: [],
            pageCount: 0,
            offset: this.offsetInitial,
			perPage: this.nbeElementOfPage,
			currentPage: 0,
            data: [],
            varDelete: {},
            showAlert: false,
            showTrueFalse: false,
        };
        this.handleCancellationRequest = this.handleCancellationRequest.bind(this);
        this.handleModifyClick = this.handleModifyClick.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleReservationsClick = this.handleReservationsClick.bind(this);
        this.handleOnlineViewClick = this.handleOnlineViewClick.bind(this);
        this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    componentWillMount(){
        
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken')
        this.getProposalTravel(TRAVEL_URL, this.idToken)
    }
    
    handleClose(event) {
        this.setState({ showTrueFalse: false });
    }

    handleCloseAlert(event) {
        this.setState({ showAlert: false });
        window.location.reload(false);
	}

    handleDuplicateClick(event, value){
        this.props.history.push({
            pathname: '/dashboard/mytrips',
            state: { 
                pages: 'new_travel',
                type: 'duplicate',
                duplicate_data: value
            }
        });
        window.location.reload(false);
    }

    handleOnlineViewClick(event, value){
        var tmp = JSON.stringify(value)
		let buff = new Buffer(tmp);
		let base64data = buff.toString('base64');
		localStorage.setItem('tripdetail', base64data)
		this.props.history.push({
			pathname: '../traveldetails/'+value.id_travel
		});
       /*  this.props.history.push({
            pathname: '/traveldetails/'+id_travel,
        state: { 
            pages: 'online_view',
        }
        }); */
    }

    handleCancellationRequest(event, id_travel){
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_travel": id_travel
        });
        this.setState({
            showTrueFalse : true,
            varDelete : VAR
        })
        //window.confirm('Voulez-vous supprimer ce trajet ?')? this.cancelTravelRequest(DELETE_TRAVEL_QUERY, VAR) : window.close()
    }

    cancelTravelRequest = async(query, variables)=>{
        try {
			const response = await axios.post(TRAVEL_URL,
				{ query, variables },
				{
					'headers':
					{
						'Authorization': this.idToken
					}
				}
			);
			// Set the data to the state


			this.setState(() => ({
                isLoaded: true,
				deleteData: response.data.data
			}));
            if(this.state.deleteData.deleteTravelProposal.status===200){
                //alert('Votre trajet a bien été supprimé')
                this.setState(() => ({
                    showAlert: true,
                    showTrueFalse: false,
                    msgAlertBody: 'Trajet supprimée',
                    msgAlertHeader: 'Suppression de trajet',
                    alertVariant: 'success'
    
                }));
            }
            else if(this.state.deleteData.deleteTravelProposal.status===400){
                if(this.state.deleteData.deleteTravelProposal.message==="Travel proposal is used, you cannot delete"){
                    //alert("Trajet déja en cours de réservation,impossible de supprimer")
                    this.setState(() => ({
                        showAlert: true,
                        msgAlertBody: 'La suppression de votre alerte à échoué',
                        msgAlertHeader: 'Suppression de trajet',
                        alertVariant: 'danger'
        
                    }));
                }
            }
            
            //window.location.reload(false);
		} catch (error) {
			// If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in the deletation')
		}
    }

    getProposalTravel = async(url,idToken) =>{
        try {
           const response = await axios.post(url, 
               {"query": TRAVEL_PROPOSAL_QUERY}, 
               {'headers': 
                   {
                       'Authorization': idToken
                   }
               }
           );
           // Set the data to the state
    
            this.setState(() => ({
             isLoaded: true,
             travels: response.data.data.getUserTravelProposal.travel_proposals,
             pageCount: Math.ceil(response.data.data.getUserTravelProposal.travel_proposals.length /this.nbeElementOfPage),
             data: response.data.data.getUserTravelProposal.travel_proposals.length > this.nbeElementOfPage ? response.data.data.getUserTravelProposal.travel_proposals.slice(this.offsetInitial, this.nbeElementOfPage): response.data.data.getUserTravelProposal.travel_proposals,
           }));
           
         } catch (error) {
           // If there's an error, set the error to the state
           this.setState(() => ({ error }))
         }
   }




   getTravelerListReservation = async (query, variables) => {
    try {
        const response = await axios.post(TRAVEL_ACTIONS_URL,
            { query, variables },
            {
                'headers':
                {
                    'Authorization': this.idToken
                }
            }
        );
        
        this.data = response.data.data.getBookingsForUserTravelProposal.requester_proposals
        var liste = this.data.filter(item => item.request_status === 'INITIATED' || item.request_status === 'VALIDATED' || item.request_status === 'PACKAGE_RECEIVED' || item.request_status === 'DELIVERED')
        
        Cookies.set('nbr_res', liste.length, { path: '/', secure: SECURE_COOKIES, expires: 1 });
        //sessionStorage.setItem('nbr_res', liste.length)
       
        this.setState({
            renderUpdate: true 
        })
        
    } catch (error) {
        // If there's an error, set the error to the state
        this.setState(() => ({ error }))
    }
}

   handleModifyClick(event, value){
    event.preventDefault();
    
    var VAR = JSON.stringify({
        "id_travel": value.id_travel
    })
    this.getTravelerListReservation(GET_TRAVEL_RESERVATION_LIST, VAR);
    if(this.state.renderUpdate === true) {
        this.setState({
            stateUpdate: value
        })
    }else{
        this.setState({
            stateUpdate: value
        })
       

    }
   }

   handleReservationsClick(event, value){
    event.preventDefault();
    this.setState({
        renderListReservation: true, 
        stateUpdate: value
    })
   }

   loadHistoricData() {
    const elements = this.state.travels;
    

    this.setState({
        data: elements.slice(this.state.offset, this.state.offset + this.state.perPage),
        pageCount: Math.ceil(elements.length / this.state.perPage)
    });

}

   handlePageClick = data => {
        let selected = data.selected;
        let offset = selected * this.state.perPage;

        this.setState({currentPage: selected, offset: offset }, () => {
            this.loadHistoricData();
        });
    };


    render() {
        moment.locale('fr');
        let quote = this.props.quote !== undefined ? this.props.quote : "";
        let title = this.props.title !== undefined ? this.props.title : "Envoyez vos Kolis en toute sécurité";
        let description = this.state.departure_city+"---"+this.state.arrival_city;

        let hashtag = "#monkolis";
        
        if(this.state.travels === [] ){
                return(<p>VOUS N'AVEZ PAS DE TRAJET EN COURS </p>)
            }else {
                 
        if(this.state.renderUpdate){
            return(
                <UpdateTrip props={this.state.stateUpdate}/>
            )      
        }else if (this.state.renderListReservation){
            return(
                <ListReservation props={this.state.stateUpdate}/>
            ) 
        }
        else{        
        return(
            <Fragment>
                <Helmet>
                    <title>Mes trajets | MonKolis</title>
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="robots" content="noindex,follow" />
                    <meta name="csrf_token" content="" />
                    <meta property="type" content="website" />

                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="_token" content="" />
                    <meta property="title" content={title} />
                    <meta property="quote" content={quote} />
                    <meta name="description" content={description} />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    <meta property="og:quote" content={quote} />
                    <meta property="og:hashtag" content={hashtag} />

                    <meta property="og:description" content={description} />
                </Helmet>


                <div className="travels">
                    <div className="section mar-bot20">
                        <div className="section-inner">
                            <h3 className="heading">Mes trajets en cours</h3>
                            <p>
                                <span className="txt-bold">Important</span> : Une fois la livraison effectuée, n'oubliez pas d'entrer les codes de livraison qui vous seront communiqués par les expéditeurs pour recevoir votre argent.
                            </p>
                        </div>
                    </div>
                    {this.state.data.map((value, index) => {
                        return <div key={index}>
                            <div className="travels-content">
                                <div className="card mytravel mar-bot20">
                                    <div className="card-header">
                                        <div className="card-header_content">
                                            <div className="card-header_title">
                                                <h3 className="card-title">
                                                    <span>{value.departure_city}</span>&nbsp;
                                        <i className="fa fa-long-arrow-right color-text2"></i>&nbsp;
                                        <span>{value.arrival_city}</span>
                                                </h3>
                                                <h4 className="card-subtitle"> <span className="txt-bold">Id du trajet: </span>{value.id_travel}</h4>
                                            </div>
                                            <div className="card-header_cta">
                                                <FacebookShareButton
                                                    url={'http://www.monkolis.com/traveldetails/' + value.id_travel}
                                                    quote={value.departure_city + " ------> " + value.arrival_city + "\n" + "" + value.weigth_available_info.weigth_available + " " + value.measure_unit + " restant(s), depart prévu le " + Moment(value.departure_date).format('DD/MM/YYYY') + "   au prix de " + value.service_charge_amount + "€/" + value.measure_unit}
                                                    hashtag={hashtag}
                                                    description={description}
                                                >
                                                    <a type="btn" className="btn btn-facebook btn-block" ><i className="fa fa-facebook-square"></i> Partager sur facebook</a>
                                                </FacebookShareButton>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6 criteria">
                                                <p><span className="fa fa-1x fa-calendar" title="Date de voyage"></span>  {Moment(value.departure_date).format('DD MMM, YYYY')}</p>
                                                <p><span className="fa fa-1x fa-clock-o color-orange" title="Date limite de réservation"></span>  {Moment(value.booking_deadline).format('DD/MM/YYYY HH:MM')}</p>
                                                {value.transportation_type === null ?
                                                    <p><span className="fa fa-1x fa-plane"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                    null
                                                }
                                                {value.transportation_type === "Avion" ?
                                                    <p><span className="fa fa-1x fa-plane"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                    null
                                                }
                                                {value.transportation_type === "Bâteau" ?
                                                    <p><span className="fa fa-1x fa-ship"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                    null
                                                }
                                                {value.transportation_type === "Voiture" ?
                                                    <p><span className="fa fa-1x fa-car"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                    null
                                                }
                                                {value.transportation_type === "Train" ?
                                                    <p><span className="fa fa-1x fa-train"></span> {value.transportation_condition === null || value.transportation_condition === undefined || value.transportation_condition === "null" || value.transportation_condition === "undefined" || value.transportation_condition === "" ? "" : value.transportation_condition + " avec "} {value.transport_company}</p> :
                                                    null
                                                }
                                                <p>  { value.has_check_ticket == "Y" ?
                                                    <i className="fa fa-ticket color-yellow"> Billet
                                                    vérifié </i> : value.has_check_ticket === "E" ? <span className="fa fa-1x fa-ticket color-green"> "Billet en cours de vérification" </span> : ''}
                                                    {/* <span className="fa fa-1x fa-ticket color-yellow"></span>  {(value.has_check_ticket == 'Y') ? "Billet vérifié" : "Billet non vérifié"}  */}</p>





                                            </div>
                                            <div className="col-md-6 comments">
                                                <p><span className="fa fa-1x fa-tags color-orange"></span>  {value.max_weight_available}kgs - {value.service_charge_amount}€ / kg - Min {value.min_weight_booking}kg</p>
                                                <p><span className="fa fa-1x fa-flash color-yellow"></span> {(value.is_automatic_approval == 'Y') ? 'Acceptation automatique' : 'Acceptation manuelle'}</p>
                                                <p><span className="fa fa-map-marker color-green"></span> {value.recovery_place}</p>
                                                <p><span className="fa fa-map-marker color-blue"></span> {value.take_off_place}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="trips-features">
                                            <div className="progress mar-bot0 mar-top0 ">
                                                {/* <div className="progress-bar progress-bar-striped progress-bar-animated bg-color-green" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: value.weigth_available_info.weigth_available === 'null' || value.weigth_available_info.weigth_available ===undefined || 100-(value.weigth_available_info.weigth_available * 100 / value.max_weight_available) < 20 ? "10%" : 100-(value.weigth_available_info.weigth_available * 100 / value.max_weight_available) + "%" }}>
                                        {value.weigth_available_info.weigth_available === value.max_weight_available ? "0 " : (parseInt(value.weigth_available_info.weigth_available))} {value.measure_unit}(s)
                                    </div> */}
                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-color-green" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: value.max_weight_available - value.weigth_available_info.weigth_available === 0 || value.max_weight_available - value.weigth_available_info.weigth_available === undefined || value.max_weight_available - value.weigth_available_info.weigth_available === null || value.max_weight_available - value.weigth_available_info.weigth_available === "undefined" || value.max_weight_available - value.weigth_available_info.weigth_available === "null" || 100 - ((value.max_weight_available - value.weigth_available_info.weigth_available) * 100 / value.max_weight_available) < 20 ? "23%" : 100 - ((value.max_weight_available - value.weigth_available_info.weigth_available) * 100 / value.max_weight_available) + "%" }}>
                                                    {value.max_weight_available - value.weigth_available_info.weigth_available === value.max_weight_available ? "0 " : (parseInt(value.max_weight_available - value.weigth_available_info.weigth_available))} {value.measure_unit}(s)  reservés sur    {value.max_weight_available} {value.measure_unit}(s)
                                    </div>
                                            </div>
                                            <div className="features_button">
                                                <button type="button" href="" className="btn btn-primary btn-sm" onClick={(e) => { this.handleOnlineViewClick(e, value) }}> <i className="fa fa-1x fa-home"></i> Voir en Ligne </button>
                                                <button type="button" href="" className="btn btn-info btn-sm" onClick={(e) => { this.handleDuplicateClick(e, value) }}> <i className="fa fa-1x fa-copy"></i> Dupliquer </button>
                                                <button type="button" href="" className="btn btn-yellow btn-sm" onClick={(e) => { this.handleModifyClick(e, value) }}> <i className="fa fa-1x fa-cog"></i> Modifier </button>

                                                <button type="button" href="" className="btn btn-danger btn-sm" data-toggle="modal" data-target="#modal-delete-trip" onClick={(e) => { this.handleCancellationRequest(e, value.id_travel) }}> <i className="fa fa-1x fa-trash"></i> Annuler </button>
                                                <button type="button" href="" className="btn btn-success btn-sm" onClick={(e) => { this.handleReservationsClick(e, value) }}> <i className="fa fa-1x fa-spinner fa-spin"></i> Réservations </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal show={this.state.showTrueFalse} onHide={this.handleClose} centered>
							<Modal.Header closeButton>
								<Modal.Title>Voulez-vous annuler ce trajet ?</Modal.Title>
							</Modal.Header>

							<Modal.Footer>
								<button className="btn btn-outline-primary" onClick={this.handleClose}>
									Non
                    			</button>
								<button className="btn btn-outline-primary"  onClick={()=>this.cancelTravelRequest(DELETE_TRAVEL_QUERY, this.state.varDelete)}>
									Oui
                    			</button>
							</Modal.Footer>
						</Modal>

                        <Modal show={this.state.showAlert} onHide={this.handleCloseAlert} centered>
							<Modal.Header closeButton>
								<Modal.Title>{this.state.msgAlertHeader}</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<p>{this.state.msgAlertBody}</p>
							</Modal.Body>

							<Modal.Footer>
								<button className="btn btn-outline-primary" onClick={this.handleCloseAlert}>
									OK
                    			</button>
							</Modal.Footer>
						</Modal>
                        </div>

                    })}
                    <ReactPaginate
                        previousLabel={'«'}
                        nextLabel={'»'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={10}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination float-right'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                        disabledClassName={'desabled'}
                    />
                </div>
            </Fragment>
            );
        }
        }
    }
}
export default withRouter(MyTripsInProgress);
