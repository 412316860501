import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/annex.css';
import './../../assets/css/modal.css';

class Work extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Comment ça marche sur monkolis ? | Monkolis</title>
                    <meta name="robots" content="index,follow" />
                </Helmet>

                <div className="hworks">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div className="row pad-top20 pad-bot20">
                                <div className="col-sm-3 mar-bot20">

                                    <div className="list-group">
                                        <a href="/work" className="list-group-item list-group-item-action active">Comment
                                            ça marche?</a>
                                        <a href="/trust" className="list-group-item list-group-item-action">Gages de
                                            Confiance</a>
                                        <a href="/level" className="list-group-item list-group-item-action">Niveau
                                            d'expérience</a>
                                        <a href="/opinions" className="list-group-item list-group-item-action">Les
                                            avis</a>
                                    </div>

                                </div>
                                <div className="col-md-9">
                                    <div className="how">
                                        <div className="section">
                                            <div className="section-inner">
                                                <h3 className="heading">Comment ça marche?</h3>
                                                <p> Fondée en janvier 2019 et basée à Paris, MonKolis offre un service
                                                dans l’esprit de l’économie collaborative dont le but est de faire
                                                des économies au quotidien tout en contribuant au développement
                                                    durable.</p>
                                                <p> Le transport de kolis à l'international n'aura jamais été aussi
                                                simple : nous mettons en relation des transporteurs et des
                                                expéditeurs qui veulent envoyer ou recevoir un kolis du pays. Grâce
                                                à notre service, le kolis de l'expéditeur arrive rapidement et en
                                                toute sureté tandis que le transporteur rentabilise son voyage et
                                                fait de belles rencontres. Tout le monde y gagne : les uns payent
                                                moins cher leur envoi et les autres remboursent une partie de leurs
                                                frais de transport ou augmentent leurs revenus, tout en rendant
                                                    service.</p>

                                                <div className="row">
                                                    <div className="col-md-6 exp">
                                                        <h6 className="color-text2 txt-bold">Expéditeurs</h6>

                                                        <div className="howbox-head">
                                                            <h6 className="color-white mar-top0 mar-bot0">Besoin
                                                            d'envoyer un kolis au pays ? Expédiez sur MonKolis
                                                                !</h6>
                                                        </div>


                                                        <div className="howbox">
                                                            <h6>1. Cherchez un trajet</h6>
                                                            <hr />
                                                            <p className="action">Créez <a href="/register"
                                                                className="link color-blue">votre
                                                                compte</a>.<br />
                                                                Entrez la ville de départ, la ville d'arrivée ainsi que
                                                                la date souhaitée pour le transfert du kolis.
                                                            </p>

                                                            <p className="color-text2 expli"><span
                                                                className="fa fa-1x fa-sliders"></span>
                                                                Utilisez nos filtres (prix, moyens de transport, etc)
                                                                pour affiner votre recherche.
                                                            </p>

                                                        </div>

                                                        <div className="howbox">
                                                            <h6>2. Réservez chez un transporteur </h6>
                                                            <hr />
                                                            <p className="action">Sélectionnez le trajet d'un
                                                            transporteur et confirmez votre expédition en payant
                                                            directement depuis notre site.
                                                            </p>

                                                            <p className="color-text2 expli"><span
                                                                className="fa fa-1x fa-id-badge"></span>
                                                                N'hésitez pas à consulter les avis reçus par les
                                                                transporteurs pour faire votre choix.
                                                            </p>

                                                        </div>

                                                        <div className="howbox">
                                                            <h6 className="">3. Rencontrez le transporteur </h6>
                                                            <hr className="exp" />
                                                            <p className="action">Retrouvez le transporteur pour lui
                                                            remettre votre kolis. Examinez ensemble le contenu du
                                                                kolis.</p>

                                                            <p className="color-text2 expli"><span
                                                                className="fa fa-1x fa-id-badge"></span>
                                                                Le transporteur doit vous donner le code de dépôt que
                                                                vous validez dans votre compte pour attester que vous
                                                                lui avez bien remis votre kolis.
                                                            </p>

                                                        </div>

                                                        <div className="howbox">
                                                            <h6 className="">4. Récupérez le colis à destination</h6>
                                                            <hr />
                                                            <p className="action">Une fois à destination, le
                                                            destinataire du kolis peut contacter le transporteur
                                                                afin de récupérer le kolis.</p>

                                                            <p className="color-text2 expli"><span
                                                                className="fa fa-1x fa-id-badge"></span>
                                                                Le destinataire donne au transporteur le code de
                                                                livraison qui atteste que le kolis lui a bien été livré.
                                                            </p>

                                                        </div>

                                                        <div className="howbox">
                                                            <h6 className="">5. Evaluez votre expédition </h6>
                                                            <hr />
                                                            <p className="action">Vous évaluez le transporteur à la fin
                                                            de l'expédition. Et n'oubliez pas de rappeler au
                                                            transporteur de vous évaluer, cela vous permettra d'être
                                                                accepté par de nouveaux transporteurs.</p>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6 voy">
                                                        <h6 className="color-text2 txt-bold">Transporteurs</h6>

                                                        <div className="howbox-head">
                                                            <h6 className="color-white mar-top0 mar-bot0">Particuelirs
                                                            ou professionnels ? Gagnez de l'argent sur
                                                                MonKolis!</h6>
                                                        </div>

                                                        <div className="howbox">
                                                            <h6 className="">1. Proposez votre trajet </h6>
                                                            <hr />
                                                            <p className="action">Inscrivez-vous gratuitement sur le
                                                                site en créant <a href="/register"
                                                                    class="link color-blue">votre
                                                                    compte</a>, puis publiez une annonce de transport
                                                                (départ, arrivée, date, prix, préférences). </p>

                                                            <p className="color-text2 expli"><span
                                                                className="fa fa-1x fa-user-circle"></span>
                                                                Prenez soin de remplir votre profil et de faire <a
                                                                    href="/dashboard/profile/verifications"
                                                                    className="link color-blue">vérifier vos pièces</a>.
                                                                Vous recevrez plus de demandes d'expédition.</p>

                                                        </div>

                                                        <div className="howbox">
                                                            <h6>2. Recevez des demandes d'expédition </h6>
                                                            <hr />
                                                            <p className="action">Vous recevez des demandes d'expédition
                                                            en fonction de vos trajets. Acceptez les demandes et
                                                                organisez le dépôt des kolis avec les expéditeurs.</p>

                                                            <p className="color-text2 expli"><span
                                                                className="fa fa-1x fa-tasks"></span>
                                                                Consultez la liste des <a href="/tips">conseils de
                                                                    prévention</a>une fois l'expédition acceptée.
                                                            </p>

                                                        </div>

                                                        <div className="howbox">
                                                            <h6 className="">3. Récuperez le kolis </h6>
                                                            <hr />
                                                            <p className="action">Retrouvez l'expéditeur pour qu'il vous
                                                            remette son kolis. Examinez ensemble le contenu du
                                                                kolis.</p>

                                                            <p className="color-text2 expli"><span
                                                                className="fa fa-1x fa-id-badge"></span>
                                                                Remettez le code de dépôt à l'expéditeur afin qu'il
                                                                atteste que vous avez pris son kolis.
                                                            </p>

                                                        </div>

                                                        <div className="howbox">
                                                            <h6>4. Livrez le kolis </h6>
                                                            <hr />
                                                            <p className="action">Une fois à destination, remettez le
                                                            kolis au destinataire et récuperez le code de livraison
                                                                afin de valider la livraison.</p>

                                                            <p className="color-text2 expli"><span
                                                                className="fa fa-1x fa-qrcode"></span>
                                                                Nous vous recommandons vvement d'entrer le code de
                                                                livraison dans votre compte en présence du destinataire.
                                                            </p>

                                                        </div>

                                                        <div className="howbox">
                                                            <h6 className="">5. Evaluez votre expéditeur </h6>
                                                            <hr />
                                                            <p className="action">Vous évaluez l'expéditeur une fois la
                                                            livraison validée. Et n'oubliez pas de rappeler à
                                                            l'expéditeur de vous évaluer, cela vous permettra
                                                                d'attirer de nouveaux expéditeurs.</p>
                                                        </div>


                                                        <div className="howbox">
                                                            <h6 className="">6. Recevez vos gains </h6>
                                                            <hr />
                                                            <p className="action">Le paiement de vos frais de transport
                                                            est réalisée de façon automatique une fois toutes vos
                                                                livraisons validées.</p>

                                                            <p className="color-text2 expli"><span
                                                                className="fa fa-1x fa-mo"></span>
                                                                Vous êtes payé par virement bancaire sous 5 jours ouvrés
                                                                après la dernière livraison.
                                                            </p>

                                                        </div>


                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}

export default Work;