import './../../../assets/css/googleapi.css';
import './../../../assets/css/responsive.css';
import './../../../assets/css/multiple2.css';
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
// import './../../../assets/css/main.css';
import './../../../assets/css/multiple.css';
import './../../../assets/css/dashboard.css';
import './../../../assets/css/modal.css';
import { withRouter, Redirect } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { GET_ALERT_QUERY, ADD_ALERT_QUERY, DELETE_ALERT_QUERY } from './../../../query/queryalert';
import { SEARCHTRAVEL_QUERY } from './../../../query/querytrajet';
import { SEARCHTRAVEL_URL, USER_INFO_URL } from './../../../Url';
import Nav from 'react-bootstrap/Nav';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import PlacesAutocomplete from 'react-places-autocomplete';
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';

class MyAlert extends Component {
    constructor(props) {
        super(props);

        this.departure_city = this.props.location.state !== undefined ? this.props.location.state.departure_city : ''
        this.arrival_city = this.props.location.state !== undefined ? this.props.location.state.arrival_city : ''
        this.departure_date = this.props.location.state !== undefined ? this.props.location.state.departure_date : ''

        this.state = {
            error: [],
            renderAddingAlert: false,
            alerts: [],
            items: [],
            departure_date_period_start: '',
            departure_city: '',
            arrival_city: '',
            msgAlertBody: '',
            msgAlertHeader: '',
            alertVariant: 'danger',
            showAlert: false,
            showTrueFalse: false,
            varDelete: {},

        }

        this.handleAddAlertClick = this.handleAddAlertClick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleAddAlert = this.handleAddAlert.bind(this);
        this.renderAddAlert = this.renderAddAlert.bind(this);
        this.handle_departure_city_change = this.handle_departure_city_change.bind(this);
        this.handle_arrival_city_change = this.handle_arrival_city_change.bind(this);
        this.handle_departure_date_period_start_change = this.handle_departure_date_period_start_change.bind(this);
        this.handleAlertCancellation = this.handleAlertCancellation.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /* handle_departure_city_change(event) { this.setState({ departure_city: event.target.value }); }
    handle_arrival_city_change(event) { this.setState({ arrival_city: event.target.value }); } */
    handle_departure_date_period_start_change(event) { this.setState({ departure_date_period_start: event.target.value }); }
    handleClose(event) {
        this.setState({ showTrueFalse: false });
    }


    //Add google API

    handle_departure_city_change = departure_city => {
        this.setState({ departure_city });

    };

    handle_arrival_city_change = arrival_city => {
        this.setState({ arrival_city });

    };


    handleSelectDepartureCity = (departure_city, placeId, suggestion) => {
        
        this.setState({ departure_city: suggestion.description });

        // Do something with address and placeId and suggestion
    }

    handleCloseAlert(event) {
        this.setState({ showAlert: false });
        window.location.reload(false);
	}
    handleSelectArrivalCity = (handlearrivalCity, placeId, suggestion) => {
       
        this.setState({ arrival_city: suggestion.description });

        // Do something with address and placeId and suggestion
    }

    //End of Google API

    componentWillMount() {
        
        this.idToken = getCookieConsentValue('idToken');
        //this.idToken = sessionStorage.getItem('idToken');
        
        this.getAlert(GET_ALERT_QUERY, this.idToken);
    }

    handleAlertCancellation(event, value) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "user_extra_parameters": {
                "search_alerts": [{
                    "city_departure_name": value.city_departure_name,
                    "city_arrival_name": value.city_arrival_name,
                    "departure_date_start": value.departure_date_start
                }]
            }
        })
        this.setState({
            showTrueFalse : true,
            varDelete : VAR
        })
        //window.confirm('Voulez-vous supprimer cette alerte ?') ? this.cancelAlertRequest(DELETE_ALERT_QUERY, VAR) : window.close()
    }

    cancelAlertRequest = async (query, variables) => {
        try {
            const response = await axios.post(USER_INFO_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );

            // Log the response so we can look at it in the console
            // Set the data to the state
            this.setState(() => ({
                showAlert: true,
                showTrueFalse: false,
                msgAlertBody: 'Alerte supprimée',
                msgAlertHeader: 'Succès',
                alertVariant: 'success'

            }));
            //alert('Alerte supprimée')
            //window.location.reload(false);
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            this.setState(() => ({
                showAlert: true,
                msgAlertBody: 'La suppression de votre alerte à échoué',
                msgAlertHeader: 'Erreur',
                alertVariant: 'danger'

            }));
            //alert('La suppressionde votre alerte à échoué')
        }
    }

    getAlert = async (query, idToken) => {
        try {
            const response = await axios.post(USER_INFO_URL,
                { query },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );
            
            // Set the data to the state
            if (response.data.data.getUserExtraParameters.status === 200) {
                this.setState({
                    alerts: response.data.data.getUserExtraParameters.user_extra_parameters.search_alerts
                });
               
            }
         
        } catch (error) {
            //console.log("")
            console.log(error)
        }
    }

    handleAddAlertClick() {
        this.setState({ renderAddingAlert: !this.state.renderAddingAlert })
       
    }

    handleSearch(event, value) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "search_travel_proposal": {
                "departure_date_period_start": value.departure_date_start,
                "departure_city": value.city_departure_name,
                "arrival_city": value.city_arrival_name,
            }
        })
        this.searchTravel(SEARCHTRAVEL_QUERY, VAR, value)
    }

    searchTravel = async (query, variables, value) => {
        try {
            const response = await axios.post(SEARCHTRAVEL_URL, {
                query,
                variables
            });
            this.setState(() => ({
                isLoaded: true,
                items: response.data.data
            }));

            // Log the response so we can look at it in the console
           
            this.props.history.push({
                pathname: '../trips',
                state: {
                    items: this.state.items,
                    departure_city: value.city_departure_name,
                    arrival_city: value.city_arrival_name,
                    departure_date: value.departure_date_start
                }
            });

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            console.log(error)
        }
    }

    handleAddAlert(event) {
        event.preventDefault();

            if (this.state.departure_city !== "" && this.state.arrival_city!==""){
                if (this.state.departure_date_period_start === '') {
                    var VAR = JSON.stringify({
                        "user_extra_parameters": {
                            "search_alerts": {
                                "city_departure_name": this.state.departure_city,
                                "city_arrival_name": this.state.arrival_city
                            }
                        }
                    });
                } else {
                    var VAR = JSON.stringify({
                        "user_extra_parameters": {
                            "search_alerts": {
                                "city_departure_name": this.state.departure_city,
                                "city_arrival_name": this.state.arrival_city,
                                "departure_date_start": this.state.departure_date_period_start
                            }
                        }
                    });
                }
                this.addAlert(ADD_ALERT_QUERY, VAR)

            }
            else{
                this.setState(() => ({
                    showAlert: true,
                    msgAlertBody: 'Merci de renseigner les champs obligatoires(*)',
                    msgAlertHeader: 'Champs vides',
                    alertVariant: 'danger'
    
                }));
            }     
            
        
        
    }

    addAlert = async (query, variables) => {
        try {
            const response = await axios.post(USER_INFO_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
           
            this.setState(() => ({
                showAlert: true,
                msgAlertBody: 'Votre Alerte a été rajoutée avec succès',
                msgAlertHeader: 'Alerte ajoutée',
                alertVariant: 'success'

            }));
            //alert("Votre Alerte a été rajoutée avec succès")
            //window.location.reload(false);
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            console.log(error)

            this.setState(() => ({
                showAlert: true,
                msgAlertBody: 'La suppressionde votre alerte à échoué',
                msgAlertHeader: 'Error',
                alertVariant: 'danger'

            }));
            //alert('L\'ajout de votre Alerte a échoué')
        }
    }

    renderAddAlert() {
        return (
            <Fragment>
                <Helmet>
                    <title>Mes alertes | Monkolis</title>
                    <meta name="robots" content="noindex,follow" />
                </Helmet>

                <div className="myalerts">
                    <form onSubmit={this.handleAddAlert}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Ajouter une alerte</h3>
                                {/* {this.state.showAlert ?
                                    <Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert} dismissible>
                                        <Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
                                        <p>{this.state.msgAlertBody} </p>
                                    </Alert> : ''} */}

                                <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                            </div>
                            <div className="modal-body">

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label for="travelDepart">Départ *</label>
                                        <PlacesAutocomplete
                                            value={this.state.departure_city}
                                            onChange={this.handle_departure_city_change}
                                            onSelect={this.handleSelectDepartureCity}

                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: '  Ville Départ ...',
                                                            className: 'location-search-input',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        style,
                                                                    })}
                                                                >
                                                                    <i class="material-icons">location_on</i><span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {/*  <input type="text" className="form-control" id="travelDepart" placeholder="Ville départ" value={this.state.departure_city} onChange={this.handle_departure_city_change} required /> */}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="travelArrivee">Arrivée *</label>
                                        <PlacesAutocomplete
                                            value={this.state.arrival_city}
                                            onChange={this.handle_arrival_city_change}
                                            onSelect={this.handleSelectArrivalCity}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: '  Ville Arrivée ...',
                                                            className: 'location-search-input',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {

                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {/* <input type="text" className="form-control" id="travelArrivee" placeholder="Ville arrivée" value={this.state.arrival_city} onChange={this.handle_arrival_city_change} required />
 */}                                </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label for="travelDate">Date</label>
                                        <input type="date" className="form-control" id="travelDate" placeholder="Date" value={this.state.departure_date_period_start} onChange={this.handle_departure_date_period_start_change} />
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <a className="btn btn-outline-primary" data-dismiss="modal" href="" onClick={this.handleAddAlertClick}>Annuler</a>
                                <button type="submit" className="btn btn-primary" data-dismiss="modal" href="" >Confirmer</button>
                            </div>

                        </div>
                    </form>
                    <br />
                    <br />
                </div>
            </Fragment>
        );
    }

    render() {

        if (this.state.alerts === null || this.state.alerts.length === 0) {
            return (
                <Fragment>
                    <Helmet>
                        <title>Mes alertes | Monkolis</title>
                        <meta name="robots" content="noidenx,follow" />
                    </Helmet>

                    <div className="myalerts">
                        <div className="main-box_content">
                            <div className="container-fluid container-fluid-pad container-fluid-br">
                                {this.idToken === null ? <Redirect to="/connexion" /> :
                                    <div className="pad-top20 mar-bot20 d-sm-block" id="secondNav">
                                        <Nav variant="tabs" defaultActiveKey="/dashboard/myalerts">
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/mydashboard" href="/dashboard/mydashboard">Tableau de bord</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/mytrips" href="/dashboard/mytrips">Mes trajets</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/myexpeditions" href="/dashboard/myexpeditions">Mes expéditions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/mymessage" href="/dashboard/mymessage">Messages</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/myalerts" href="/dashboard/myalerts">Alertes</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/myprofile" href="/dashboard/myprofile">Profil</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link href="/dashboard/money" href="/dashboard/money">Paiements</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <br />
                                        <div className="row alerts ">
                                            <div className="col-sm-3 mar-bot20">
                                                <div className="list-group">
                                                    <a className="list-group-item list-group-item-action txt-white active">Alertes</a>
                                                </div>
                                            </div>
                                            <div className="col-sm-9">
                                                <div className="section">
                                                    <div className="section-inner">
                                                        <h3 className="heading">Pourquoi créer une alerte ?</h3>
                                                        <p>
                                                            <span className="txt-bold">C'est pratique</span> : Vous pouvez la paramétrer plusieurs jours à l'avance et être prévenu dès qu'une opportunité de voyage se présente.
                                            </p>
                                                    </div>
                                                </div>
                                                <div className="section">
                                                    <div className="section-inner">
                                                        <h4 className="mar-bot5">Mes alertes</h4>
                                                        <small className="form-text text-muted">Vous n'avez pas encore créé d'alerte.</small>
                                                        <small className="form-text text-muted">Vous recevrez un e-mail à chaque fois qu'un membre créera un trajet correspondant.</small>
                                                    </div>
                                                </div>


                                                <hr className="mar-top5" />
                                                <button type="button" className="btn btn-theme2 float-right mar-bot30 txt-white" data-toggle="modal" data-target="#add-delete-alert" onClick={this.handleAddAlertClick}>Ajouter une alerte</button>

                                                {this.state.renderAddingAlert === true ? this.renderAddAlert() : null}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <Modal show={this.state.showAlert} onHide={this.handleCloseAlert} centered>
							<Modal.Header closeButton>
								<Modal.Title>{this.state.msgAlertHeader}</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<p>{this.state.msgAlertBody}</p>
							</Modal.Body>

							<Modal.Footer>
								<button className="btn btn-outline-primary" onClick={this.handleCloseAlert}>
									OK
                    			</button>
							</Modal.Footer>
						</Modal>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className="myalerts">
                        <div className="main-box_content">
                            <div className="container-fluid container-fluid-pad container-fluid-br">
                                {this.idToken === null ? <Redirect to="/connexion" /> :
                                    <div className="pad-top20 mar-bot20 d-sm-block" id="secondNav">
                                        <Nav variant="tabs" defaultActiveKey="/dashboard/myalerts">
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/mydashboard" href="/dashboard/mydashboard">Tableau de bord</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/mytrips" href="/dashboard/mytrips">Mes trajets</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/myexpeditions" href="/dashboard/myexpeditions">Mes expéditions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/mymessage" href="/dashboard/mymessage">Messages</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/myalerts" href="/dashboard/myalerts">Alertes</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="/dashboard/myprofile" href="/dashboard/myprofile">Profil</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link href="/dashboard/money" href="/dashboard/money">Paiements</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <div className="row alerts ">
                                            <div className="col-sm-3 mar-bot20">
                                                <br />
                                                <div className="list-group">
                                                    <a className="list-group-item list-group-item-action txt-white active">Alertes</a>
                                                </div>
                                            </div>
                                            <div className="col-sm-9">

                                                <div className="section">
                                                    <div className="section-inner">
                                                        <h3 className="heading">Pourquoi créer une alerte ?</h3>
                                                        <p>

                                                            <span className="txt-bold">C'est pratique</span> : Vous pouvez la paramétrer plusieurs jours à l'avance et être prévenu dès qu'une opportunité de voyage se présente.
                                        </p>
                                                    </div>
                                                </div>
                                                <div className="section">
                                                    <div className="section-inner">
                                                        <h4 className="mar-bot5">Mes alertes</h4>
                                                        <small className="form-text text-muted">Vous recevrez un e-mail à chaque fois qu'un membre créera un trajet correspondant.</small>
                                                    </div>
                                                </div>

                                                <hr className="mar-top5" />
                                                {this.state.alerts.map((value, index) => {
                                                    return <div key={index}>


                                                        <div className="alert">
                                                            <div className="row">
                                                                <div className="col-lg-5">
                                                                    <h4 className="trajet mar-bot0">
                                                                        <span className="">{value.city_departure_name}</span>
                                                                        <i className="fa fa-long-arrow-right color-text2"></i>
                                                                        <span className=""> {value.city_arrival_name}</span>
                                                                    </h4>
                                                                </div>


                                                                <div className="col-lg-4">

                                                                    <div className="form-group">
                                                                        <label for="travelDateAlert" className="sr-only">Date de départ</label>
                                                                        <input type="date" className="form-control" id="travelDateAlert" value={value.departure_date_start} />
                                                                    </div>

                                                                </div>

                                                                <div className="col-lg-3">

                                                                    <div className="align-right">
                                                                        <a className="link color-blue" type="button" onClick={(e) => { this.handleSearch(e, value) }}><span className="fa fa-1x fa-search"></span><span>Voir les résultats</span></a>
                                                                        <br />
                                                                        <a className="link color-red" type="button" data-toggle="modal" data-target="#modal-delete-alert" onClick={(e) => { this.handleAlertCancellation(e, value) }}><span className="fa fa-1x fa-close"></span><span>Supprimer</span></a>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                                {this.state.renderAddingAlert === true ? this.renderAddAlert() : null}
                                                <button type="button" className="btn btn-theme2 float-right mar-bot30 txt-white " data-toggle="modal" data-target="#add-delete-alert" onClick={this.handleAddAlertClick}>Ajouter une alerte</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <Modal show={this.state.showAlert} onHide={this.handleCloseAlert} centered>
							<Modal.Header closeButton>
								<Modal.Title>{this.state.msgAlertHeader}</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<p>{this.state.msgAlertBody}</p>
							</Modal.Body>

							<Modal.Footer>
								<button className="btn btn-outline-primary" onClick={this.handleCloseAlert}>
									OK
                    			</button>
							</Modal.Footer>
						</Modal>
                        <Modal show={this.state.showTrueFalse} onHide={this.handleClose} centered>
							<Modal.Header closeButton>
								<Modal.Title>Voulez-vous supprimer cette alerte ?</Modal.Title>
							</Modal.Header>

							<Modal.Footer>
								<button className="btn btn-outline-primary" onClick={this.handleClose}>
									Non
                    			</button>
								<button className="btn btn-outline-primary"  onClick={()=>this.cancelAlertRequest(DELETE_ALERT_QUERY, this.state.varDelete)}>
									Oui
                    			</button>
							</Modal.Footer>
						</Modal>
                    </div>
                </Fragment>

            );
        }
    }
}

export default withRouter(MyAlert);
