
/* eslint-disable */

import user16 from '../../assets/img/user/user16.jpg'
import './../../assets/css/multiple.css';
import './../../assets/css/multiple2.css';
import './../../assets/css/search.css';
import './../../assets/css/modal.css';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import Moment from 'moment';
import { withRouter } from 'react-router-dom'
import DEFAULT_PHOTO from '../../assets/img/user/user0.png';
import StarRatings from 'react-star-ratings';
import ProgressBar from 'react-bootstrap/ProgressBar'

import { Rating } from '@material-ui/lab';





import _ from 'lodash';
class User extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

        var user_dahsboard_details = localStorage.getItem('user_dahsboard_details')
        let decodde = new Buffer(user_dahsboard_details, 'base64');
        var data = JSON.parse(decodde.toString('utf-8'));



        var user_attributes = localStorage.getItem('user_attributes')
        let decoddes = new Buffer(user_attributes, 'base64');
        var data_user_attributes = JSON.parse(decoddes.toString('utf-8'));

        var tripdetail = localStorage.getItem('tripdetail')
        let decod = new Buffer(tripdetail, 'base64');
        this.details = JSON.parse(decod.toString('utf-8'));

        this.state = {
            id_travel: this.details.id_travel,
            email: this.details.email,
            user_proposal_given_name: this.details.user_proposal_attributes.given_name,
            user_proposal_family_name: this.details.user_proposal_attributes.family_name,
            user_proposal_name: this.details.user_proposal_attributes.name,
            registration_date: Moment(this.details.registration_date).format('LLLL'),
            picture: this.details.user_proposal_attributes.picture,
            account_type: this.details.user_proposal_attributes.account_type,
            company_name: this.details.user_proposal_attributes.account_type_details === null || this.details.user_proposal_attributes.account_type_details === undefined || this.details.user_proposal_attributes.account_type_details === 'null' || this.details.user_proposal_attributes.account_type_details === 'undefined' ? '' : this.details.user_proposal_attributes.account_type_details.company_name,
            company_country_name: this.details.user_proposal_attributes.account_type_details === null || this.details.user_proposal_attributes.account_type_details === undefined || this.details.user_proposal_attributes.account_type_details === 'null' || this.details.user_proposal_attributes.account_type_details === 'undefined' ? '' : this.details.user_proposal_attributes.account_type_details.company_country_name,

            locality: data.address ? data.address.locality : '',
            description: data.description,
            id_card_verified: data.verifications.id_card_verified,
            phone_number_verified: data.verifications.phone_number_verified,
            email_verified: data.verifications.email_verified,
            user_create_date: data.activities.user_create_date,
            iat: data.activities.iat,
            total_of_travel: data.activities.total_of_travel,
            total_of_requester: data.activities.total_of_requester,
            evaluations: data.evaluations_receive,
            mark5: data.resume_evaluation_receive.find(item => item.marks === '5') !== undefined ? data.resume_evaluation_receive.find(item => item.marks === '5').nbre : '0',
            mark4: data.resume_evaluation_receive.find(item => item.marks === '4') !== undefined ? data.resume_evaluation_receive.find(item => item.marks === '4').nbre : '0',
            mark3: data.resume_evaluation_receive.find(item => item.marks === '3') !== undefined ? data.resume_evaluation_receive.find(item => item.marks === '3').nbre : '0',
            mark2: data.resume_evaluation_receive.find(item => item.marks === '2') !== undefined ? data.resume_evaluation_receive.find(item => item.marks === '2').nbre : '0',
            mark1: data.resume_evaluation_receive.find(item => item.marks === '1') !== undefined ? data.resume_evaluation_receive.find(item => item.marks === '1').nbre : '0',
            progress_percent: data.activities.positifs_evaluation_receive_part,
            level: data.activities.level_of_experience,
            user_attributes: data_user_attributes,
            user_data: data,
            stateAvis: 0,
            sumNbre: 0,
            sumMarks: 0,

        }


    }

    handleClick = value => () => {
        this.props.history.push({
            pathname: '../traveldetails/' + this.state.id_travel
        });

    };


    componentDidMount() {
        window.scrollTo(0, 0);
    }





    render() {
        this.state.sumNbre = _.sumBy(this.state.user_data.resume_evaluation_receive, 'nbre'),
            this.state.sumMarks = _.sum(_.map(this.state.user_data.resume_evaluation_receive, v => Number(v.marks) * v.nbre))

        { this.state.sumNbre !== 0 ? this.state.stateAvis = (parseFloat(this.state.sumMarks / this.state.sumNbre)) : this.state.stateAvis = 0 }

        return (
            <Fragment>
                <div className="user">
                    <div className="main-box_content">
                        <div className="container-fluid container-fluid-pad container-fluid-br">
                            <div>

                                <div className="mar-top10 mar-bot5">
                                    <a onClick={this.handleClick(this.state.id_travel)} className="link color-blue"><span className="fa fa-long-arrow-left"></span> Retour à la page précédente</a>
                                    {/* <a href="./traveldetails" className="link color-blue"><span className="fa fa-long-arrow-left"></span> Retour à la page précédente</a> */}
                                </div>
                                <div className="row dashboard">

                                    <div className="col-sm-4">
                                        {/* Debut >Dash1*/}
                                        <div className="about section">
                                            <div className="section-inner">
                                                <div className="media">
                                                    <div className="media-left media-top">
                                                        <div className="photo">
                                                            {this.state.picture === null || this.state.picture === 'null' ?
                                                                <img src={DEFAULT_PHOTO} alt="MDN Logo" className="cover rounded" /> :
                                                                <img src={this.state.picture} alt="MDN Logo" className="cover rounded" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="media-body pad-left10">
                                                        {
                                                            this.state.account_type === null || this.state.account_type === undefined || this.state.account_type === 'null' || this.state.account_type === 'undefined' || this.state.account_type === "Particulier" || this.state.account_type === "" ?
                                                                <h5 className="media-heading">{this.state.user_proposal_given_name === undefined || this.state.user_proposal_given_name === null || this.state.user_proposal_given_name === 'null' || this.state.user_proposal_given_name === 'undefined' || this.state.user_proposal_given_name === '' ? this.state.user_proposal_name : this.state.user_proposal_given_name}</h5>
                                                                :
                                                                <h5 className="media-heading">{this.state.company_name === null || this.state.company_name === undefined || this.state.company_name === 'undefined' || this.state.company_name === 'null' || this.state.company_name === '' ? this.state.name : this.state.company_name}  </h5>
                                                        }

                                                        <p className="color-text2">{this.state.company_country_name === null || this.state.company_country_name === undefined || this.state.company_country_name === 'undefined' || this.state.company_country_name === 'null' || this.state.company_country_name === '' ? this.state.locality : this.state.company_country_name} {/* , {Moment(this.birthdate).format('YYYY')} */}</p>
                                                    </div>
                                                </div>
                                                {

                                                    this.state.account_type === null || this.state.account_type === undefined || this.state.account_type === 'null' || this.state.account_type === 'undefined' || this.state.account_type === "Particulier" || this.state.account_type === "" ? '' :
                                                        <div>
                                                            <span class="badge badge-danger">Professionnel</span>
                                                        </div>
                                                }

                                                <hr />


                                                <h3 className="heading">Vérifications</h3>


                                                <div className="content">


                                                    <ul className="fa-ul txt-bold color-green">
                                                        {this.state.id_card_verified === 'true' ?
                                                            <li><i className="fa-li fa fa-check-square-o"></i>Pièce d'identité</li> :
                                                            <li className="color-red"><i className="fa-li fa fa-close"></i>Pièce d'identité</li>

                                                        }

                                                        {this.state.phone_number_verified !== 'true' ?
                                                            <li className="color-red"><i className="fa-li fa fa-close"></i>Téléphone</li> :
                                                            <li><i className="fa-li fa fa-check-square-o"></i>Téléphone</li>
                                                        }

                                                        {this.state.email_verified !== 'true' ?
                                                            <li className="color-red"><i className="fa-li fa fa-close"></i>Email</li> :
                                                            <li><i className="fa-li fa fa-check-square-o"></i>Email</li>
                                                        }

                                                    </ul>

                                                </div>

                                                <hr />

                                                <h3 className="heading">Activité</h3>
                                                <div className="content">
                                                    <p className="mar-bot5">Membre depuis : {Moment(this.state.user_create_date).format('YYYY. MMMM')} </p>
                                                    <p className="mar-bot5">Nombre d'expéditions: {this.state.total_of_requester}</p>
                                                    <p className="mar-bot5">Nombre de voyages: {this.state.total_of_travel}</p>




                                                    <p>Synthèse des avis :<i>- {this.state.evaluations.length} avis</i>
                                                        <Rating
                                                            name="hover-feedback"
                                                            value={this.state.stateAvis}
                                                            precision={0.5}
                                                            readOnly
                                                        />

                                                        {/*  { this.state.stateAvis>= 0 & this.state.stateAvis<= 1 ?<p> <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i> </p>:''}


                                            { this.state.stateAvis>= 1 & this.state.stateAvis<= 2 ?<p> <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i> </p>:''}

                                            { this.state.stateAvis>= 2 & this.state.stateAvis<= 3 ?<p> <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                            <i class="fa fa-star-o color-yellow" aria-hidden="true" ></i> </p>:''}
                                            */}
                                                    </p>


                                                </div>


                                                <div className="mar-top40 mar-bot0">
                                                    {/* <a href="#" className="link color-red" data-toggle="modal" data-target="#modal-signal-user">Signaler cet utilisateur</a> */}
                                                </div>

                                                <div className="modal fade" id="modal-signal-user" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" >

                                                </div>



                                            </div>
                                        </div>

                                        {/* Fin >Dash1*/}
                                    </div>

                                    <div className="col-sm-8">
                                        {/* Debut  Dash2*/}

                                        <div className="level section">

                                            <div className="section-inner">
                                                <h3 className="heading">Niveau d'expérience</h3>
                                                <div className="content">
                                                    <p className="intro">
                                                        Chaque membre dispose de son propre niveau d’Expérience, niveau qui évolue en fonction de son ancienneté et de son activité sur le site.
                                                        Les niveaux d’Expérience vous aident à choisir la personne idéale pour votre trajet avec Monkolis.
                                </p>
                                                    <ProgressBar animated now={this.state.progress_percent} label={this.state.level} />
                                                </div>
                                            </div>
                                        </div>


                                        <aside className="info aside section">
                                            <div className="section-inner">
                                                <h3 className="heading">Synthèse des avis</h3>
                                                <div className="content">
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <p className="align-right">Très bien</p>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>

                                                            <i> {this.state.mark5} avis</i>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <p className="align-right">Bien</p>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i> {this.state.mark4} avis</i>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <p className="align-right">Moyen</p>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i> {this.state.mark3} avis</i>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <p className="align-right">Déçu</p>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i> {this.state.mark2} avis</i>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <p className="align-right">Très déçu</p>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <i className="fa fa-star color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i className="fa fa-star-o color-yellow" aria-hidden="true" ></i>
                                                            <i> {this.state.mark1} avis</i>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </aside>


                                        <aside className="testimonials aside section">
                                            <div className="section-inner">
                                                <h3 className="heading">Derniers avis reçus</h3>
                                                <div className="content">
                                                    <div className="testimonials">
                                                        {/* Debut   avis*/}

                                                        {this.state.evaluations.slice(0, 3).map((value, index) => {
                                                            return <div className="item" key={index} >

                                                                {value.evaluation !== null ? <div>
                                                                    <div className="quote">
                                                                        {/*  <StarRatings
                                                                    rating={value.evaluation.marks}
                                                                    starRatedColor="red"
                                                                    numberOfStars={5}
                                                                    name='rating'
                                                                    starDimension='20px'
                                                                    starSpacing='0,5px'
                                                                /> */}

                                                                        <Rating
                                                                            name="hover-feedback"
                                                                            value={value.evaluation.marks}
                                                                            precision={0.5}
                                                                            readOnly
                                                                        />

                                                                        <p>

                                                                            {value.evaluation.comment}</p>
                                                                    </div>
                                                                    <p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}, {Moment(value.evaluation.evaluation_date).format('MMM-YYYY')}</span></p>
                                                                </div>
                                                                    : <div>
                                                                        Vous n'avez pas laissé de commentaire
                                                            <p className="source"><span className="name txt-bold">{value.user_attributes.given_name}</span> <span className="date">{value.user_role === 'TRAVELER' ? 'Voyageur' : 'Expéditeur'}</span></p>
                                                                    </div>
                                                                }

                                                            </div>
                                                        })}


                                                    </div>

                                                    {/*  <a href="#" className="link color-blue"><span className="fa fa-long-arrow-right"></span> Voir tous les avis</a> */}

                                                </div>
                                            </div>
                                        </aside>




                                        {/* Fin  Dash2*/}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}
export default withRouter(User);
